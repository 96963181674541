import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

class DialogHeader extends Component {
  render() {
    const { t, title, onClose } = this.props;

    return (
      <AppBar position="sticky">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
            {title}
          </Typography>
          <Tooltip title={t("labels.CLOSE")}>
            <IconButton color="inherit" aria-label="Close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    );
  }
}

DialogHeader.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withTranslation()(DialogHeader);
