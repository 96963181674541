import { useState, useEffect, useContext, memo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ReactDiffViewer from "react-diff-viewer";
import { ThemeContext } from "styled-components";

// Hooks
import { useFileContentFromUrl } from "features/Common/hooks";
import { useAnalytics } from "features/Analytics";

// Icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ClosedCaptionIcon from "@material-ui/icons/ClosedCaption";

// Component
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import NationFlag from "components/NationFlag";
import AsyncComponentLoader from "components/AsyncComponentLoader";

// Utils
import Utils from "features/Common/utils";

// Style
import StyledComponent, {
  StyledSubtitle,
  NoneSelectedMessageStyled,
  StyledDiffPlacerHolder,
} from "./style";

const NoneSelectedMessage = () => {
  const { t } = useTranslation();
  return (
    <NoneSelectedMessageStyled>
      {t("text.SELECT_FILE")}
    </NoneSelectedMessageStyled>
  );
};

const EmptyDiffPlaceHolder = () => {
  const { t } = useTranslation();
  return (
    <StyledDiffPlacerHolder>
      <div className="column-left">
        <ClosedCaptionIcon />
        {t("labels.OLD_FILE")}
      </div>
      <div className="column-right">
        <ClosedCaptionIcon />
        {t("labels.NEW_FILE")}
      </div>
    </StyledDiffPlacerHolder>
  );
};

const SubtitleChip = ({ name, user, status, createdAt }) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <StyledSubtitle>
      <div className="column-left">
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body1">
          {moment.unix(createdAt).fromNow()}
        </Typography>
        <Typography variant="body1">{user?.email || user?.name}</Typography>
      </div>
      <div className="column-right">
        <Chip
          label={t(`labels.${status?.toUpperCase()}`)}
          style={{ background: theme.palette.status[status].main }}
        />
      </div>
    </StyledSubtitle>
  );
};

const FileDropdown = ({
  htmlId,
  label,
  subtitles,
  onBeforeChange,
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  // State
  const [selectedSubtitle, setSelectedSubtitle] = useState(value);

  const handleChange = (event) => {
    const { value: id } = event.target;
    const selectedSub = subtitles?.find((subtitle) => subtitle.id === id);
    setSelectedSubtitle(id);

    // Fire on before change directly.
    onBeforeChange();

    // Fire onChange on next render cycle. So that we could
    // show a loader before the react diff stalls while mounting.
    setTimeout(() => {
      onChange(selectedSub);
    });
  };

  return (
    <FormControl>
      <InputLabel id={htmlId}>{label}</InputLabel>
      <Select
        labelId={`${htmlId}-label`}
        id={htmlId}
        variant="outlined"
        value={selectedSubtitle || 0}
        onChange={handleChange}
        input={<Input id={`${htmlId}-select`} />}
        renderValue={(selectedId) => {
          if (!selectedId) return <NoneSelectedMessage />;
          const subtitle = subtitles?.find((sub) => sub.id === selectedId);
          const {
            name,
            status,
            created_at: createdAt,
            uploaded_by: user,
          } = subtitle;
          return (
            <SubtitleChip
              name={name}
              status={status}
              createdAt={createdAt}
              user={user}
            />
          );
        }}
      >
        {subtitles?.map(
          ({ id, name, created_at: createdAt, uploaded_by: user, status }) => (
            <MenuItem key={id} value={id}>
              <SubtitleChip
                name={name}
                createdAt={createdAt}
                user={user}
                status={status}
              />
            </MenuItem>
          )
        )}
        {!subtitles?.length && (
          <MenuItem disabled>{t("labels.COMPARE_NO_FILES")}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

const getSubtitleUrl = (subtitle) => {
  if (!subtitle) return null;
  const { paths = [] } = subtitle;
  const { path } = paths[0];
  return Utils.Fragments.getAssetStoragePath(path);
};

const FragmentSubtitleCompare = ({
  locale,
  fragmentName,
  subtitles,
  oldFileId,
  newFileId,
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  // State
  const [loading, setLoading] = useState(false);
  const [oldFilePath, setOldFilePath] = useState(null);
  const [newFilePath, setNewFilePath] = useState(null);

  const {
    content: oldFileContent,
    loading: oldContentLoading,
  } = useFileContentFromUrl(oldFilePath);
  const {
    content: newFileContent,
    loading: newContentLoading,
  } = useFileContentFromUrl(newFilePath);

  const onOldFileSelected = (subtitle) => {
    setLoading(false);
    const url = getSubtitleUrl(subtitle);
    setOldFilePath(url);
  };

  const onNewFileSelected = (subtitle) => {
    setLoading(false);
    const url = getSubtitleUrl(subtitle);
    setNewFilePath(url);
  };

  const onBeforeSelected = () => {
    setLoading(true);
  };

  // Show selected subtitle by id
  useEffect(() => {
    if (!subtitles) return;

    const oldSubtitle = subtitles.find((sub) => sub.id === oldFileId);
    const oldFileUrl = getSubtitleUrl(oldSubtitle);
    setOldFilePath(oldFileUrl);

    const newSubtitle = subtitles.find((sub) => sub.id === newFileId);
    const newFileUrl = getSubtitleUrl(newSubtitle);
    setNewFilePath(newFileUrl);

    track("subtitle.compare.files.changed", {
      oldFileId,
      oldFileUrl,
      newFileUrl,
      newFileId,
    });
  }, [oldFileId, newFileId, subtitles, track]);

  return (
    <StyledComponent>
      <header>
        <NationFlag countryCode={locale || ""} />
        <Typography variant="h2">{fragmentName}</Typography>
      </header>
      <nav>
        <div>
          <FileDropdown
            htmlId="fileOld"
            label={t("labels.COMPARE_FROM")}
            subtitles={subtitles}
            value={oldFileId}
            onBeforeChange={onBeforeSelected}
            onChange={onOldFileSelected}
          />
          {oldContentLoading && <CircularProgress size={24} />}
        </div>
        <ArrowForwardIcon />
        <div>
          <FileDropdown
            htmlId="fileNew"
            label={t("labels.COMPARE_TO")}
            subtitles={subtitles}
            value={newFileId}
            onBeforeChange={onBeforeSelected}
            onChange={onNewFileSelected}
          />
          {newContentLoading && <CircularProgress size={24} />}
        </div>
      </nav>

      {loading && <p>{t("text.LOADING")}</p>}
      <AsyncComponentLoader fallback={<p>{t("text.LOADING")}</p>}>
        <ReactDiffViewer
          oldValue={oldFileContent || ""}
          newValue={newFileContent || ""}
          splitView
        />
      </AsyncComponentLoader>
      {!oldFileContent && !newFileContent && <EmptyDiffPlaceHolder />}
    </StyledComponent>
  );
};

FragmentSubtitleCompare.defaultProps = {
  subtitles: [],
};

FragmentSubtitleCompare.propTypes = {
  fragmentName: PropTypes.string,
  locale: PropTypes.string,
  subtitles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  oldFileId: PropTypes.number,
  newFileId: PropTypes.number,
};

export default memo(FragmentSubtitleCompare);
