import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFragmentForm } from "features/Fragment/hooks";
import { getCurrentChannel } from "features/Channel/selectors";
import Utils from "features/Common/utils";

/**
 * Get available meta/options from the selected channel
 * form settings.
 *
 * @return {
 *  fieldId: [
 *     {
 *       default_value: 1
 *       id: 498
 *       locale: null
 *       name: "Betaald"
 *       rank: 0
 *       value: "premium"
 *    }
 *  ]
 * }
 */
export const usePossibleOptions = ({ fragment }) => {
  const { data: channel } = useSelector(getCurrentChannel);
  const form = useFragmentForm(channel, fragment);
  const { fields } = form || { fields: [] };

  return useMemo(
    () =>
      fields?.reduce(
        (result, { id, values }) => ({
          ...result,
          [id]: values,
        }),
        {}
      ),
    [fields]
  );
};

export const useNormalizedValues = (rawValues) =>
  useMemo(() => {
    // Convert array to object with id as keys
    const keySelector = (metaValue) => metaValue.id;
    const valueSelector = ({ multiple, values }) =>
      multiple ? values : values[0];
    const metaValues = Utils.Common.normalize(
      rawValues || [],
      keySelector,
      valueSelector
    );

    return metaValues;
  }, [rawValues]);

export const useNormalizedMetaInformation = (metaInformation) =>
  useMemo(() => {
    // Convert array to object with id as keys
    const keySelector = (metaValue) => metaValue.id;
    const metaValues = Utils.Common.normalize(
      metaInformation || [],
      keySelector
    );

    return metaValues;
  }, [metaInformation]);

/*
 * Merge metaInformation and new values
 *
 * - Contains only changed values
 * dirtyValues: { 1: [ { value: 'meta field value' }]}
 *
 * metaInformation: {
 *    1: {
 *      name: 'Meta field',
 *      multiple: true,
 *      required: true
 *      ...
 *      // Merge these values with dirtyValues
 *      values: [...]
 *    }
 * }
 */
export const mergeDirtyValues = ({ dirtyValues, meta }) => {
  const mergedDate = Object.entries(dirtyValues).reduce(
    (mergedObject, current) => {
      const [key, dirtyValue] = current;
      const metaInfo = meta[key];

      // Values is always an array also for single values
      let values = null;
      if (Array.isArray(dirtyValue)) {
        values = dirtyValue;
      } else if (dirtyValue && !Array.isArray(dirtyValue)) {
        values = [dirtyValue];
      }

      return {
        ...mergedObject,
        [key]: {
          ...metaInfo,
          values,
        },
      };
    },
    {}
  );

  return mergedDate;
};
