import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../../../components/Button/Button";
import { API_URL } from "features/Common/constants";
import Axios from "axios";
import { findById } from "features/Asset/Asset.actions";
import { AllInApi } from "features/Common/services/api.service";
import { getCurrentFragment } from "../selectors";
import { useDispatch, useSelector } from "react-redux";

const api = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const FragmentUpdateContracts = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: fragment } = useSelector(getCurrentFragment);

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);

    api
      .post(
        `${AllInApi.getApiChannelPath()}/asset/fragment/${
          fragment.id
        }/licensing/update`
      )
      .then(() => {
        dispatch(findById(fragment.asset.id));
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "black" }}>
          Are you sure?
        </DialogContentText>
        <DialogContentText style={{ color: "red" }}>
          Are you sure you want to update the contracts of this asset?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("text.NO")}
        </Button>
        <Button
          onClick={handleClick}
          color="primary"
          loading={loading}
          disabled={loading}
        >
          {t("text.YES")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(FragmentUpdateContracts);
