import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "@mantine/form";
import {
  first,
  get,
  isArray,
  isObject,
  isUndefined,
  map,
  pick,
  uniq,
  values,
} from "lodash";

// Material UI
import { IconButton, List, Tooltip } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import TwitterIcon from "@material-ui/icons/Twitter";
// import YouTubeIcon from "@material-ui/icons/YouTube";
// import FacebookIcon from "@material-ui/icons/Facebook";

// Components
import Button from "components/Button/Button";

// Redux
import {
  listTypes as listTypesApi,
  generate as generateApi,
  translate as translateApi,
  createPosts as createPostsApi,
  getPosts,
} from "./services";
import { getCurrentFragment } from "features/Fragment/selectors";
import { getCurrentAsset } from "features/Asset/selectors";
import { getCountries } from "features/Common/country.service";

import TwitterForm from "./Forms/TwitterForm";
// import YouTubeForm from "./Forms/YouTubeForm";
// import FacebookForm from "./Forms/FacebookForm";
import Sidebar from "./Sidebar";
import Timeline from "./Timeline/Timeline";
import Compose from "./Compose/Compose";

export const defaultList = [
  {
    name: "Twitter",
    type: "twitter",
    color: "#1da1f2",
    form: TwitterForm,
    icon: TwitterIcon,
  },
  // {
  //   name: "YouTube",
  //   type: "youtube",
  //   color: "#ff0000",
  //   form: YouTubeForm,
  //   icon: YouTubeIcon,
  // },
  // {
  //   name: "Facebook",
  //   type: "facebook",
  //   color: "#4267b2",
  //   form: FacebookForm,
  //   icon: FacebookIcon,
  // },
];

export const hasLocale = (list, country, language) =>
  list.some((item) =>
    item.countries.some(
      (listCountry) =>
        listCountry.code === country.code &&
        listCountry.languages.some(
          (listLanguage) => listLanguage.code === language.code
        )
    )
  );

const getKey = (type, language, country, voice) =>
  `${type.type}.locales.${language.code}-${country.code}.voices.${voice}`;

const FragmentSocialPost = ({ open, onClose }) => {
  const { t } = useTranslation();
  const form = useForm();

  const [listLoading, setListLoading] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  const [list, setList] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [timelineLoading, setTimelineLoading] = useState(true);

  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [pusherChannel, setPusherChannel] = useState(null);

  const [saving, setSaving] = useState(false);

  const [selectedType, setSelectedType] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  // Redux
  const channel = useSelector((state) => state.channel?.data) || {};
  const { data: asset } = useSelector(getCurrentAsset);
  const { data: fragment } = useSelector(getCurrentFragment);

  const isLoading = useCallback(
    (language, type, voice) =>
      language.countries.some(
        (country) =>
          get(
            form.values,
            `${getKey(type, language, country, voice)}.loading`
          ) === true
      ),
    [form.values]
  );

  useEffect(() => {
    let loading = false;
    let checked = 0;

    Object.values(form.values).forEach((type) =>
      Object.values(type.locales).forEach((locale) =>
        Object.values(locale.voices).forEach((voice) => {
          if (voice.loading === true) {
            loading = true;
          }
          if (voice.checked === true) {
            checked += 1;
          }
        })
      )
    );

    setListLoading(loading);
    setSelectedCount(checked);
  }, [form.values]);

  const setLoading = useCallback(
    (language, type, voice, loading = true) => {
      language.countries.forEach((country) => {
        const key = getKey(type, language, country, voice);

        form.setFieldValue(
          key,
          Object.assign({}, get(form.values, key), {
            loading: loading,
          })
        );
      });
    },
    [form]
  );

  const setResult = useCallback(
    (
      language,
      type,
      voice,
      data,
      loading = undefined,
      selectedOnComplete = false
    ) => {
      language.countries.forEach((country) => {
        const key = getKey(type, language, country, voice);

        const id = data?.id || null;
        const result = typeof data?.result === "object" ? data.result : {};
        const select = get(form.values, `${key}.selectedOnComplete`);

        form.setFieldValue(
          key,
          Object.assign({}, get(form.values, key), result, {
            id: id,
            loading: isUndefined(loading) ? !data?.id : !!loading,
            selectedOnComplete: selectedOnComplete,
            ...(select && { checked: true }),
          })
        );
      });
    },
    [form]
  );

  const updateTimeline = useCallback(
    (withLoading = true) => {
      withLoading && setTimelineLoading(true);

      getPosts(fragment.id)
        .then(({ data: { data } }) => {
          setTimeline(data);
        })
        .finally(() => {
          withLoading && setTimelineLoading(false);
        });
    },
    [fragment.id]
  );

  const generatePrompt = useCallback(
    (type, language, voice, revalidate = false, force = false) => {
      if (isLoading(language, type, voice) && force === false) {
        return;
      }

      setLoading(language, type, voice, true);

      generateApi(type.type, fragment.id, language.code, voice, revalidate)
        .then(({ data: { data } }) => {
          setResult(
            language,
            type,
            voice,
            data,
            revalidate === true ? true : undefined
          );
        })
        .catch(() => {
          setLoading(language, type, voice, false);
        });
    },
    [isLoading, setLoading, setResult, fragment.id]
  );

  const translatePrompt = useCallback(
    (content, type, language, voice) => {
      if (isLoading(language, type, voice)) {
        return;
      }

      setLoading(language, type, voice, true);

      translateApi(content.id, language.code, content)
        .then(({ data: { data } }) => {
          setResult(language, type, voice, data, true, true);
        })
        .catch(() => {
          setLoading(language, type, voice, false);
        });
    },
    [isLoading, setLoading, setResult]
  );

  const onSelect = (type, language) => {
    if (
      selectedType?.type === type?.type &&
      selectedLanguage?.code === language?.code
    ) {
      return;
    }

    if (type === null && language === null) {
      setSelectedType(null);
      setSelectedLanguage(null);
      updateTimeline();

      return;
    }

    if (!fragment?.id || !type?.type || !language?.code) {
      return;
    }

    setSelectedType(type);
    setSelectedLanguage(language);

    type.voices.forEach((voice) => {
      generatePrompt(type, language, voice, false);
    });
  };

  const editPrompt = () => {};

  const regenerate = (voice) => {
    if (!fragment?.id || !selectedLanguage?.code) {
      return;
    }

    if (voice) {
      generatePrompt(selectedType, selectedLanguage, voice, true);
    } else {
      selectedType.voices.forEach((voice) => {
        generatePrompt(selectedType, selectedLanguage, voice, true);
      });
    }
  };

  const translate = (content, voice, language) => {
    if (
      !fragment?.id ||
      !selectedLanguage?.code ||
      !content?.id ||
      !content?.checked
    ) {
      return;
    }

    if (language) {
      translatePrompt(content, selectedType, language, voice);
    } else {
      languages
        .filter((item) => item.code !== selectedLanguage?.code)
        .forEach((language) => {
          translatePrompt(content, selectedType, language, voice);
        });
    }
  };

  const handleClick = () => {
    setSaving(true);

    const formData = new FormData();

    const formValues = [];
    const formMedia = [];
    Object.values(form.values).forEach((type) => {
      Object.values(type.locales).forEach((locale) => {
        Object.values(locale.voices).forEach((voice) => {
          if (voice.checked === true) {
            const listType = list.find((item) => item.type === type.type);

            if (listType) {
              formValues.push({
                type: type.type,
                locale: locale.locale,
                id: voice.id,
                content: pick(voice, listType.params),
              });

              formMedia.push({
                type: type.type,
                locale: locale.locale,
                media: locale.media,
              });
            }
          }
        });
      });
    });

    formData.set("data", JSON.stringify(formValues));
    formMedia.forEach(({ type, locale, media }) => {
      formData.append(`media[${type}-${locale}]`, media);
    });

    createPostsApi(fragment.id, formData)
      .then(({ data: { created, errors } }) => {
        Object.values(form.values).forEach((type) => {
          Object.values(type.locales).forEach((locale) => {
            form.setFieldValue(
              `${type.type}.locales.${locale.locale}.errors`,
              []
            );
          });
        });

        if (errors.length) {
          errors.forEach((item) => {
            const key = `${item.type}.locales.${item.locale}.errors`;
            if (isArray(get(form.values, key))) {
              form.setFieldValue(key, item.errors);
            }
          });
        }

        if (created.length > 0) {
          created.forEach((item) => {
            const key = `${item.type}.locales.${item.locale}`;
            const voices = get(form.values, `${key}.voices`);

            form.setFieldValue(`${key}.media`, null);

            if (isObject(voices)) {
              Object.keys(voices).forEach((voice) => {
                form.setFieldValue(`${key}.voices.${voice}.checked`, false);
              });
            }
          });
        }

        if (errors.length === 0) {
          onSelect(null, null);
        } else {
          const error = first(errors);
          const type = list.find((item) => item.type === error.type);
          const language = languages.find(
            (item) => item.code === error.language
          );

          if (type && language) {
            onSelect(type, language);
          }

          if (created.length > 0) {
            updateTimeline(false);
          }
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const createdEvent = useCallback(
    (data) => {
      if (data?.type && data?.language && data?.voice) {
        const type = list.find((item) => item.type === data.type);
        const language = languages.find(
          (language) => language.code === data.language
        );

        generatePrompt(type, language, data.voice, false, true);
      }
    },
    [generatePrompt, languages, list]
  );

  const stateChangedEvent = useCallback(
    (data) => {
      if (data?.id && data?.state) {
        const newTimeline = timeline.map((item) => ({
          ...item,
          ...(item.id === data.id && { state: data.state }),
        }));

        setTimeline(newTimeline);

        if (data.state === "published") {
          updateTimeline(false);
        }
      }
    },
    [updateTimeline, timeline]
  );

  useEffect(() => {
    setPusherChannel(window.Echo.private(`channel.${channel.identifier}`));

    return () => {
      window.Echo.leaveChannel(`channel.${channel.identifier}`);
    };
  }, [channel.identifier]);

  useEffect(() => {
    if (pusherChannel) {
      pusherChannel.listen(`.openai.${fragment.id}.created`, (data) =>
        createdEvent(data)
      );
    }

    return () => {
      if (pusherChannel) {
        pusherChannel.stopListening(`.openai.${fragment.id}.created`);
      }
    };
  }, [pusherChannel, fragment.id, createdEvent]);

  useEffect(() => {
    if (pusherChannel) {
      pusherChannel.listen(`.openai.${fragment.id}.state.changed`, (data) =>
        stateChangedEvent(data)
      );
    }

    return () => {
      if (pusherChannel) {
        pusherChannel.stopListening(`.openai.${fragment.id}.state.changed`);
      }
    };
  }, [pusherChannel, fragment.id, stateChangedEvent]);

  useEffect(() => {
    getCountries().then(({ data }) => {
      setCountries(data);
    });
  }, []);

  useEffect(() => {
    updateTimeline();
  }, [updateTimeline]);

  useEffect(() => {
    if (asset.schedule && countries) {
      const countryIds = uniq(map(asset.schedule, "country.id"));
      const newCountries = [
        {
          id: null,
          name: "International",
          native_name: "International",
          code: "intl",
          languages: [
            {
              id: 1,
              name: "English",
              native_name: "English",
              code: "en",
            },
          ],
        },
        ...countries,
      ];
      const selectedCountries = newCountries.filter(
        (country) => countryIds.includes(country.id) || country.id === null
      );

      const selectedLanguages = {};

      selectedCountries.forEach((country) => {
        country.languages.forEach((language) => {
          if (!hasLocale(list, country, language)) {
            return;
          }

          if (!selectedLanguages[language.id]) {
            selectedLanguages[language.id] = {
              ...language,
              countries: [],
            };
          }

          selectedLanguages[language.id].countries.push(country);
        });
      });

      setLanguages(values(selectedLanguages));
    }
  }, [list, countries, asset.schedule]);

  useEffect(() => {
    if (languages.length) {
      const defaultValues = {};

      list.forEach((item) => {
        languages.forEach((language) => {
          language.countries
            .filter((country) => hasLocale(list, country, language))
            .forEach((country) => {
              const locale = `${language.code}-${country.code}`;

              if (!defaultValues[item.type]) {
                defaultValues[item.type] = {
                  type: item.type,
                  locales: {},
                };
              }

              if (!defaultValues[item.type].locales[locale]) {
                defaultValues[item.type].locales[locale] = {
                  media: null,
                  locale: locale,
                  errors: [],
                  voices: {},
                };
              }

              item.voices.forEach((voice) => {
                if (!defaultValues[item.type].locales[locale].voices[voice]) {
                  defaultValues[item.type].locales[locale].voices[voice] = {
                    selected: false,
                    checked: false,
                    loading: false,
                    ...item.params.reduce((ac, a) => ({ ...ac, [a]: "" }), {}),
                  };
                }
              });
            });
        });
      });

      form.setValues(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, languages]);

  useEffect(() => {
    listTypesApi(fragment.id).then(({ data }) => {
      const list = data
        .map((item) => {
          const defaults = defaultList.find(
            (defaultItem) => defaultItem.type === item.type
          );

          if (defaults) {
            const defaultCountries = [
              {
                name: "International",
                code: "intl",
                languages: [{ name: "English", code: "en" }],
              },
              ...item.countries,
            ];

            return {
              ...defaults,
              ...{
                countries: defaultCountries,
                countryCodes: defaultCountries.map((country) => country.code),
                params: item.fields,
                voices: item.voices,
              },
            };
          }

          return null;
        })
        .filter((item) => item);

      setList(list);
    });
  }, [fragment.id]);

  if (!countries.length) {
    return null;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            {t("text.SOCIAL_POSTS_FRAGMENT", { fragment: fragment.name })}
          </Grid>
          <Grid item>
            <Tooltip title="Close">
              <IconButton aria-label="close" onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        dividers
        style={{ backgroundColor: "#F3F6F9", display: "flex", padding: 0 }}
      >
        {list.length ? (
          <Grid container flex={1} spacing={4} style={{ margin: 0 }}>
            <Grid
              item
              style={{
                backgroundColor: "white",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <List component="nav" sx={{ padding: "8px" }}>
                <Sidebar
                  form={form}
                  list={list}
                  timeline={timeline}
                  languages={languages}
                  selectedType={selectedType}
                  selectedLanguage={selectedLanguage}
                  onSelect={onSelect}
                />
              </List>
            </Grid>

            <Grid
              item
              xs
              style={{
                position: "relative",
                maxHeight: "100%",
                overflow: "auto",
              }}
            >
              {selectedType && selectedLanguage ? (
                <Compose
                  list={list}
                  form={form}
                  regenerate={regenerate}
                  translate={translate}
                  editPrompt={editPrompt}
                  selectedType={selectedType}
                  selectedLanguage={selectedLanguage}
                />
              ) : (
                <Timeline timeline={timeline} loading={timelineLoading} />
              )}
            </Grid>
          </Grid>
        ) : (
          <Typography
            variant="body1"
            style={{
              margin: "16px 24px",
              color: "#aaa",
              textAlign: "center",
            }}
          >
            It is not possible to create social posts for this fragment.
          </Typography>
        )}
      </DialogContent>

      {selectedCount > 0 && (
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            autoFocus
            onClick={handleClick}
            color="primary"
            loading={saving}
            disabled={listLoading}
          >
            Publish {selectedCount} posts
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default memo(FragmentSocialPost);
