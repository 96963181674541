import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";

export default styled(AppBar)`
  .grow {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  .right-side {
    display: flex;
    align-items: center;
    margin-right: spacing(4);
  }

  .link {
    text-decoration: none;
    color: white;
    opacity: 0.7;
  }

  .channel-box {
    display: flex;
    align-items: center;
    height: 64;
    padding: 0 24px;
    margin-left: -24px;
    border-radius: 0;
  }
`;
