import { call, put, select } from "redux-saga/effects";

import * as jobActions from "./Job.actions";

export const getChannel = (state) => state.channel;
export const getJobs = (state) => state.job;

export function* find(jobApi, { searchParams }) {
  try {
    const channel = yield select(getChannel);
    const jobs = yield select(getJobs);

    const jobResponse = yield call(
      jobApi.getEncodedJobs,
      channel.selected,
      searchParams
    );

    if (jobResponse.status !== 200) {
      return yield put(jobActions.findFailure());
    }

    const allJobs = {
      ...jobResponse.data,
      ...(jobs.data !== null && {
        data: [...jobs.data.data, ...jobResponse.data.data],
      }),
    };

    return yield put(jobActions.findSuccess(allJobs));
  } catch (error) {
    yield put(jobActions.findFailure());
  }

  return false;
}

export function* reset() {
  const job = yield select(getJobs);
  if (!job.loading) return yield put(jobActions.reset());

  return false;
}
