import Proofreader from "./Proofreader";
import Subtitler from "./Subtitler";

const routes = [
  {
    key: "EXTERNAL_PROOFREAD",
    path: "/external/subtitle/proofread/:token/:fileId",
    component: Proofreader,
    exact: true,
    displayNav: false,
    auth: false,
  },
  {
    key: "EXTERNAL_SUBTITLE",
    path: "/external/subtitle/translate/:token",
    component: Subtitler,
    exact: true,
    displayNav: false,
    auth: false,
  },
];

export default routes;
