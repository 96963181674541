import { useCallback, useState } from "react";

/**
 * @param request should be a method which returns a promise.
 */
const useRequest = ({ request, params, errorMessage }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(() => {
    setLoading(true);
    setError(null);

    request(params)
      .then((response) => {
        setData(response?.data?.data || []);
      })
      .catch((requestError) => {
        setError(errorMessage || requestError?.message);
      })
      .finally(() => {
        setLoading(false);
      });

    // Note: We stringify the params otherwise the caller
    // should memo the params to prevent an endless loop.

    // eslint-disable-next-line
  }, [request, errorMessage, JSON.stringify(params)]);

  return [sendRequest, data, setData, loading, error];
};

export default useRequest;
