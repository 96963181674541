import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";

const StyledComponent = styled(ListItem)`
  padding: 0 !important;

  & a {
    color: ${({ theme }) => theme.palette.grey[900]};
    text-decoration: none;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 8px 12px;
    width: 100%;

    & .MuiSvgIcon-fontSizeSmall {
      font-size: 10px;
      margin-left: 4px;
    }

    & .no-title {
      display: flex;
      line-height: 1;
      flex-direction: column;
      font-size: 0.75rem;
      margin: 0 8px;

      & .fi {
        width: 1em;
        margin-left: 8px;
      }

      & > div {
        display: flex;
        flex-direction: row;
        color: ${({ theme }) => theme.palette.grey[400]};
      }
    }
  }

  & .index {
    position: relative;
    background: ${({ theme }) => theme.palette.grey[200]};
    color: ${({ theme }) => theme.palette.grey[600]};
    margin-right: 8px;
    font-size: 0.75rem;
    padding: 2px 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  & .scrollAnchor {
    position: absolute;
    top: -16px;
  }

  & a.active {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-weight: bold;
  }
`;

export default StyledComponent;
