import { useMemo, memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

// Components
import Button from "components/Button";
import { MdEditorWithControl } from "components/Form/MdEditor";
import Alert from "@material-ui/lab/Alert";

// Local Components
import SubtitleUpload from "./SubtitleUpload";

// Redux
import { uploadFile } from "../../reducer";

// style
import StyledForm from "./style";

const UploadForm = ({ token, assetName, fragmentName, language, loading }) => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const { success: uploadSuccess, uploading, error: uploadError } = useSelector(
    (state) => state.external.upload
  );

  // Form
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      description: yup.string().nullable(),
      files: yup.array().min(1),
    });
  }, []);

  const { control, formState, errors, handleSubmit, reset } = useForm({
    validationSchema,
    defaultValues: {
      short_description: "",
      description: "",
      files: [],
    },
  });

  // Listeners
  const onSubmit = (formData) => {
    const { files, short_description, description } = formData;
    dispatch(
      uploadFile({
        authToken: token,
        acceptedFiles: files,
        short_description,
        description,
      })
    );
  };

  // Clear form on reset
  useEffect(() => {
    if (uploadSuccess) {
      reset();
    }
  }, [uploadSuccess, reset]);

  // Invoke formState dirty values so that the proxy is
  // trigger/activated. Otherwise formState.isDirty doesn't work
  useEffect(() => {
    if (!formState) return;
    formState.dirtyFields.values();
    // eslint-disable-next-line
  }, []);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="files"
        as={
          <SubtitleUpload
            networkState={uploading ? "loading" : "idle"}
            language={{ code: language, name: language }}
            success={uploadSuccess}
            errors={errors.files?.message ? [errors.files?.message] : null}
            accept=".srt"
          />
        }
        control={control}
        onChange={([selected]) => {
          return selected?.acceptedFiles;
        }}
      />
      <MdEditorWithControl
        name="description"
        label={t("labels.MOVIE_DESCRIPTION")}
        error={errors.description?.message}
        control={control}
        style={{ height: "300px" }}
      />
      {uploadError && <Alert severity="error">{uploadError}</Alert>}
      <Button
        name="external.translator.upload"
        color="primary"
        type="submit"
        disableElevation
        disabled={!formState.dirty}
        trackName="external.translator.upload"
        trackDetails={{ assetName, fragmentName }}
        loading={loading}
      >
        {t("buttons.SEND_AND_UPLOAD")}
      </Button>
    </StyledForm>
  );
};

export default memo(UploadForm);
