import PropTypes from "prop-types";

const TextWithHighlight = ({ Element, highlight, fieldId, text }) => {
  const [highlightSelected] = highlight[fieldId] || [];
  return (
    <Element
      className="highlight"
      dangerouslySetInnerHTML={{
        __html: highlightSelected || text,
      }}
    />
  );
};

TextWithHighlight.defaultProps = {
  Element: "div",
  highlight: {},
};

TextWithHighlight.propTypes = {
  Element: PropTypes.string,
  highlight: PropTypes.shape({}),
  fieldId: PropTypes.string,
  text: PropTypes.string,
};

export default TextWithHighlight;
