import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

/* Components */
import Select from "./Select";

const SelectWithControl = (props) => {
  const { control } = props;
  return (
    <Controller
      as={Select}
      control={control}
      {...props}
      onChange={([e]) => e.target.value}
    />
  );
};

SelectWithControl.displayName = "SelectWithControl";
SelectWithControl.propTypes = {
  control: PropTypes.shape({}).isRequired,
};

export default SelectWithControl;
