import styled from "styled-components";

export default styled.button`
  padding: 4px 8px;
  border: none;
  background: none;
  color: ${({ theme }) => theme.palette.grey[400]};
  outline: 0;
  font-weight: bold;
  margin-top: 12px;
  transition: color ease-out 200ms;

  &.active {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  &.active:hover {
    text-decoration: underline;
  }
`;
