import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import ResizePanel from "../../../components/ResizablePanel";

export const StyledResizePanel = styled(ResizePanel)``;

export default styled(Grid)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.palette.grey[100]};
  transition: width ease-out 0.2s;
  border-right: 2px solid ${({ theme }) => theme.palette.grey[300]};
  flex-wrap: nowrap;

  & > header {
    padding: ${({ theme }) => `${theme.spacing()}px ${theme.spacing(2)}px`};
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    transition: all ease-out 0.2s;
    height: 38px;
    box-sizing: border-box;
    background: ${({ theme }) => theme.palette.grey[300]};
    border-radius: 4px;
    margin: 12px 12px 0 12px;

    & h6 {
      color: rgba(0, 0, 0, 0.9);
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }

    .MuiIconButton-root {
      padding: 6px;
    }
  }

  &{StyledResizePanel}.xs & {
    & > header {
      justify-content: center;

      & h6 {
        display: none;
      }
    }

    .text-container {
      display: none;
    }
    .left {
      flex: 1 0 auto;
      justify-content: center;
    }

    .right {
      display: none !important;
    }
  }

  .content {
    flex: 1;
    padding: 0;
    max-width: 100%;
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .list-item {
    padding: 0;
    width: 100%;
  }


  .link {
    display: flex;
    flex: 1;
    box-sizing: border-box;
    height: 48px;
    align-items: center;
    color: gray;
    text-decoration: none;
    padding: 8px 16px;
    margin: 0 16px 8px 16px;
    border-radius: 2px;
    justify-content: space-between;

    &:hover,
    &.active {
      background: ${({ theme }) => theme.palette.grey[300]};
    }

    &.archived {
      color: rgb(102, 60, 0);
      background-color: rgb(255, 244, 229);
    }

    & .text-container {
      margin-left: ${({ theme }) => theme.spacing(2)}px;
    }

    & .link-title {
      display: block;
      color: black;
      margin: 0 0 4px 0;

      & > span {
        text-overflow: ellipsis;
        line-height: 1;
        font-size: 0.875rem;
      }
    }

    & .duration {
      margin: 0;
      line-height: 1;
    }

    & .left {
      flex-shrink: 1;
    }

    & .left,
    & .right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .status-indicator:hover {
    transform: scale(1.5);
    transition: all 0.2s ease-in-out;
  }
`;
