import PropTypes from "prop-types";

import { useSelector } from "react-redux";

// Utils
import { usePersistentState, useQuery } from "features/Common/hooks";

// Components
import Header from "components/Header";
import ResizablePanel from "components/ResizablePanel";

// Styles
import StyledComponent from "./style";

const DEFAULT_WIDTH = 320;

const Layout = ({ children, drawer, routes }) => {
  const [sideBarWidth, setSidebarWidth] = usePersistentState(
    DEFAULT_WIDTH,
    "drawer.right"
  );

  const onDragStop = ({ size }) => {
    setSidebarWidth(size);
  };

  const channel = useSelector((state) => state.channel?.data);
  const query = useQuery();
  const panel = query.get("panel");

  return (
    <StyledComponent className={panel ? "drawer--open" : ""}>
      <section>
        <Header title={channel?.name} channel={channel} routes={routes} />
        <main>{children}</main>
      </section>
      {!!panel && (
        <ResizablePanel
          name={panel}
          direction="w"
          initialSize={sideBarWidth}
          minimumSize={340}
          maximumSize={1200}
          overFlowCorrection
          onDragStop={onDragStop}
        >
          {drawer}
        </ResizablePanel>
      )}
    </StyledComponent>
  );
};

Layout.defaultProps = {
  children: null,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  drawer: PropTypes.node,
};

export default Layout;
