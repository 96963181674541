import { memo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";

// Icons
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

// Components
import { AutoSizer, Column } from "react-virtualized";

// Styles
import { StyledCell, StyledHeaderCell, StyledTable } from "./styles";
import "react-virtualized/styles.css";

const Header = ({ label, headerHeight }) => {
  return (
    <StyledHeaderCell
      variant="head"
      component="div"
      style={{ height: headerHeight }}
    >
      {label}
    </StyledHeaderCell>
  );
};

const Cell = ({ cellData, parent }) => {
  const { rowHeight } = parent?.props;

  return (
    <StyledCell variant="body" component="div" style={{ height: rowHeight }}>
      {cellData}
    </StyledCell>
  );
};

const ActionCell = ({ cellData, parent }) => {
  const { rowHeight } = parent?.props;

  return (
    <StyledCell
      variant="body"
      component="div"
      style={{ height: rowHeight, justifyContent: "flex-end" }}
    >
      <KeyboardArrowRightIcon />
    </StyledCell>
  );
};

const getRowClassName = ({ index }) => {
  return classNames({
    odd: index % 2 === 0,
  });
};

const Table = ({ items, onRowClick }) => {
  const { t } = useTranslation();

  return (
    <AutoSizer>
      {({ width, height }) => (
        <StyledTable
          width={width}
          height={height}
          headerHeight={56}
          rowHeight={56}
          rowCount={items?.length || 0}
          rowGetter={({ index }) => items[index]}
          rowClassName={getRowClassName}
          onRowClick={onRowClick}
        >
          <Column
            label={t("labels.NAME")}
            dataKey="name"
            width={width - 120}
            headerRenderer={Header}
            cellRenderer={Cell}
          />
          <Column
            label="_"
            dataKey="identifier"
            width={120}
            headerRenderer={Header}
            cellRenderer={ActionCell}
          />
        </StyledTable>
      )}
    </AutoSizer>
  );
};

Table.defaultProps = {
  onRowClick: () => {},
};

Table.propTypes = {
  onRowClick: PropTypes.func,
};

export default memo(Table);
