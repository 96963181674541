const style = ({ spacing }) => ({
  column: {
    display: "flex",
    flexDirection: "column",
  },

  descriptionInput: {
    minHeight: 180,
    maxHeight: 180,
  },

  locked: {
    background: "#ffd218",
    padding: spacing() * 2,
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
  },
});

export default style;
