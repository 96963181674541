import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledComponent = styled.div`
  & .MuiButton-outlinedSizeSmall {
    font-size: 0.65rem;
  }

  & .hide .MuiButtonGroup-grouped {
    color: ${({ theme }) => theme.palette.error.dark};
    border-color: ${({ theme }) => theme.palette.error.main};
  }

  & .show .MuiButtonGroup-grouped {
    color: ${({ theme }) => theme.palette.success.dark};
    border-color: ${({ theme }) => theme.palette.success.main};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .MuiTypography-h5 {
    font-size: 0.875rem;
    font-weight: bold;
  }

  & .MuiTypography-body1 {
    font-size: 0.75rem;
    max-width: 320px;
    white-space: initial;
    color: ${({ theme }) => theme.palette.grey[600]};
  }
`;

export default StyledComponent;
