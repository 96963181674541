const style = {
  layoutContainer: {
    margin: 0,
    minHeight: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column",
  },
};

export default style;
