import { takeLatest, all } from "redux-saga/effects";
import * as JobTypes from "./Job.types";
import * as JobSagas from "./Job.sagas";
import * as jobApi from "./job.service";

import * as ChannelTypes from "../../Channel/Channel.types";

function* sagas() {
  return yield all([
    takeLatest(JobTypes.FIND, JobSagas.find, jobApi),
    takeLatest(ChannelTypes.SET_SELECTED, JobSagas.reset),
  ]);
}

export default sagas;
