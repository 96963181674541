import { createContext, useState } from "react";
import { useParams } from "react-router-dom";

const SeoDetailContext = createContext(undefined);

const SeoDetailProvider = ({ children }) => {
  const { seoEntryId } = useParams();
  const [entry, setEntry] = useState(null);
  const [linkedAsset, setLinkedAsset] = useState(null);
  const [linkedFragment, setLinkedFragment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [language, setLanguage] = useState("en");
  const [formLoading, setFormLoading] = useState(false);
  const [responseErrors, setResponseErrors] = useState({});
  const [contentTypes, setContentTypes] = useState([]);
  const [languages, setLanguages] = useState([
    { code: "en", country: "gb", errors: [] },
    { code: "nl", country: "nl", errors: [] },
    { code: "nb", country: "no", errors: [] },
    { code: "sv", country: "se", errors: [] },
    { code: "da", country: "dk", errors: [] },
    { code: "fi", country: "fi", errors: [] },
    { code: "pt", country: "pt", errors: [] },
  ]);

  return (
    <SeoDetailContext.Provider
      value={{
        seoEntryId,
        entry,
        setEntry,
        linkedAsset,
        setLinkedAsset,
        linkedFragment,
        setLinkedFragment,
        loading,
        setLoading,
        languages,
        setLanguages,
        publishLoading,
        setPublishLoading,
        language,
        setLanguage,
        formLoading,
        setFormLoading,
        responseErrors,
        setResponseErrors,
        contentTypes,
        setContentTypes,
      }}
    >
      {children}
    </SeoDetailContext.Provider>
  );
};

export { SeoDetailContext, SeoDetailProvider };
