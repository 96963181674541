import { AllInApi } from "../Common/services/api.service";

const api = AllInApi.getApi();

export const getAssets = (
  query = null,
  type = "video",
  page = 1,
  sortField = null,
  sortDirection = null
) =>
  api.get(`${AllInApi.getApiSearchPath()}/asset`, {
    params: {
      query,
      type,
      page,
      sort_field: sortField,
      sort_direction: sortDirection,
    },
  });

export const getChildAssets = (
  parentId,
  type = "video",
  page = 1,
  sortField = "index",
  sortDirection = "asc"
) =>
  api.get(`${AllInApi.getApiSearchPath()}/asset`, {
    params: {
      parent_id: parentId,
      type,
      page,
      sort_field: sortField,
      sort_direction: sortDirection,
    },
  });

export const getAssetById = (id) =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/${id}`);

export const getLabels = (id) =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/${id}/label`);

export const saveAsset = (data) => {
  if (data.id) {
    return api.put(`${AllInApi.getApiChannelPath()}/asset/${data.id}`, data);
  }

  return api.post(`${AllInApi.getApiChannelPath()}/asset`, data);
};

export const archiveAsset = (id) => {
  return api.post(`${AllInApi.getApiChannelPath()}/asset/${id}/archive`);
};

export const unArchiveAsset = (id) => {
  return api.post(`${AllInApi.getApiChannelPath()}/asset/${id}/unarchive`);
};

export const deleteAsset = (id) => {
  return api.delete(`${AllInApi.getApiChannelPath()}/asset/${id}`);
};

export const assetTranslateStats = () =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/fragment/translate/stats`);

export const changeCheck = (id, checks) =>
  api.post(`${AllInApi.getApiChannelPath()}/asset/${id}/check`, checks);

export const getAllStates = () => api.get(`${AllInApi.getApiPath()}/state`);

export const updateSchedule = (id, schedule) =>
  api.put(`${AllInApi.getApiChannelPath()}/asset/${id}/schedule`, schedule);

export const getcountryGroups = (assetId) =>
  api.get(`${AllInApi.getApiPath()}/asset/${assetId}/country/group`);

export const exportAsset = ({
  ids,
  type,
  all,
  fileType,
  exportTypes,
  filterPublish,
}) =>
  api.post(
    `${AllInApi.getApiChannelPath()}/tools/export/axinom`,
    {
      ids,
      type,
      all,
      file_type: fileType,
      filterPublish,
      export_types: exportTypes,
    },
    {
      headers: {
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
      responseType: "blob",
    }
  );

export const patchLabels = (id, fields) =>
  api.post(`${AllInApi.getApiChannelPath()}/asset/${id}/label`, {
    ...fields,
    // Laravel simulated patch
    _method: "PATCH",
  });

// TODO: make scrollable in the future. For now size 10000 covers all
// available video/series.
export const getSelectedSearchIds = async ({ queryObject, size = 10000 }) => {
  const query = { ...queryObject };

  // Delete unnecessary subqueries
  delete query.aggs;
  delete query.highlight;

  const params = {
    ...query,
    size,
    _source: false,
  };

  const results = await api.post(
    `${AllInApi.getApiSearchPath()}/assets/_search?scroll=30s`,
    params
  );
  return results?.data?.hits?.hits?.map(({ _id }) => Number(_id)) || [];
};
