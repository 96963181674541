import styled from "styled-components";

const StyledComponent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;

  & .MuiSvgIcon-root {
    margin-right: 8px;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: black;
    padding: 20px;
    border-radius: 4px;
    max-width: 350px;
  }
`;

export default StyledComponent;
