import styled from "styled-components";

export const StyledPreviewImage = styled.div`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  h4 {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.grey[700]};
    margin-left: ${({ theme }) => theme.spacing() / 2}px;
    width: ${({ width }) => width}px;
    word-wrap: break-word;
  }

  img {
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border-radius: 6px;
  }

  & .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border: 1px dashed ${({ theme }) => theme.palette.grey[500]};
    border-radius: 6px;
    flex-shrink: 0;

    & > .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.grey[500]};
      font-size: 1.5rem;
    }
  }
`;

const StyledComponent = styled.div`
  & a {
    text-decoration: none;
  }

  .MuiCard-root {
    box-shadow: none;
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.palette.grey[100]};
    }
  }

  .MuiCardContent-root {
    padding: ${({ theme }) => theme.spacing(2)}px;
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .MuiTypography-root {
    max-width: 800px;
  }

  .activeEdit {
    .MuiCard-root {
      background: ${({ theme }) => theme.palette.grey[100]};
    }

    .MuiIconButton-label {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export default StyledComponent;
