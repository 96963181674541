import styled from "styled-components";

export const StyledComponent = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  min-height: 40px;
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-bottom: 12px;

  & .MuiSvgIcon-root {
    font-size: 1rem;
    margin-right: 8px;
  }
`;

export default StyledComponent;
