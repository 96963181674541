import { useEffect, memo } from "react";
import { useLocation } from "react-router-dom";
import useEventListener from "@use-it/event-listener";

import { useAnalytics } from "./AnalyticsProvider";

const EventTracker = () => {
  const { track } = useAnalytics();
  const location = useLocation();

  const sendTracking = (action, event) => {
    const { trackName, trackDetails } = event.detail || {};
    if (!trackName) return;
    track(action, { name: trackName, ...trackDetails });
  };

  // Track components/Button clicks
  useEventListener("button-click", (event) => {
    sendTracking("Button clicked", event);
  });

  // Track components/DownloadLink clicks
  useEventListener("download-link-click", (event) => {
    sendTracking("Download link clicked", event);
  });

  // Track components/Checkbox changes
  useEventListener("checkbox-change", (event) => {
    sendTracking("Checkbox change", event);
  });

  // Track API logs
  useEventListener("api-log", (event) => {
    sendTracking("API log", event);
  });

  // Track route changes
  useEffect(() => {
    track("View Page", {
      path: `${location?.pathname}${location?.search}`,
      search: location?.search,
    });
  }, [location, track]);
  return null;
};

export default memo(EventTracker);
