import styled from "styled-components";

export const StyledSubtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  flex: 1;

  .column-right {
    padding-left: 12px;
  }

  .MuiTypography-h6 {
    font-size: 0.875rem;
    font-weight: bold;
  }

  .MuiTypography-body1 {
    font-size: 0.75rem;
    line-height: 1.3;
  }

  .MuiChip-root {
    border-radius: 4px;
    height: auto;
    padding: 4px 0;
    margin: 0 8px;
    font-size: 0.75rem;
    color: white;

    .MuiChip-label {
      padding: 0 4px;
    }
  }
`;

export const NoneSelectedMessageStyled = styled.div`
  padding: 8px 16px;
`;

export const StyledDiffPlacerHolder = styled.div`
  display: flex;
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.grey[100]};

  & > div {
    display: flex;
    flex: 1;
    padding: 32px 16px;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.palette.grey[500]};
  }

  & .column-left {
    border-right: 3px solid ${({ theme }) => theme.palette.grey[200]};
  }

  & .column-right {
    border-left: 3px solid ${({ theme }) => theme.palette.grey[200]};
  }
`;

const StyledComponent = styled.div`
  padding-bottom: 48px;

  header {
    display: flex;
    border: 1px solid ${({ theme }) => theme.palette.grey[100]};
    border-radius: 4px;
    padding: 8px 16px;
    margin-bottom: 16px;

    .fi {
      margin-right: 8px;
    }

    .MuiTypography-h2 {
      font-size: 1rem;
    }
  }

  nav {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  .MuiFormControl-root {
    margin-bottom: 12px;
  }

  .MuiSelect-root {
    border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  }
`;

export default StyledComponent;
