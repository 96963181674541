import { Component } from "react";
import { compose } from "redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Search from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

import styles from "./InboxOverviewView.styles";

class InboxOverviewHeader extends Component {
  render() {
    const { classes, t, handleSearch, inbox } = this.props;

    return (
      <div className={classNames(classes.topHeader)}>
        <div className={classNames(classes.titleHeader)}>
          {t("titles.INBOX")}
        </div>
        <div className={classNames(classes.totalsHeader)}>
          <div>
            <strong>{inbox.data ? inbox.data.length : 0}</strong>{" "}
            {t("text.FILES")}
          </div>
        </div>
        <div className={classNames(classes.searchHeader)}>
          <Paper
            component="form"
            className={classes.rootPaper}
            onSubmit={(event) => event.preventDefault()}
          >
            <div className={classes.searchContainer}>
              <InputBase
                autoFocus
                className={classes.input}
                onChange={(event) => handleSearch(event)}
                placeholder={`${t("labels.SEARCH")}...`}
              />
              <IconButton className={classes.iconButton} aria-label="search">
                <Search />
              </IconButton>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

InboxOverviewHeader.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default compose(
  withTranslation(),
  withStyles(styles)
)(InboxOverviewHeader);
