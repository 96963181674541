import styled from "styled-components";

const StyledComponent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export default StyledComponent;
