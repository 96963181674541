import { TimelineOutlined } from "@material-ui/icons";
import { Box, Divider, Grid, Typography } from "@mui/material";
import CircularProgressCenter from "components/CircularProgressCenter";
import TimelineItem from "./TimelineItem";

const Timeline = ({ timeline, loading }) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{ marginBottom: "16px", padding: "0 16px 0 16px" }}
      >
        <Grid item>
          <TimelineOutlined
            style={{
              color: "#999",
              display: "block",
              width: "28px",
              height: "28px",
            }}
          />
        </Grid>
        <Grid item xs>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography noWrap variant="h5">
                Timeline
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider />

      <Box sx={{ margin: "16px" }}>
        {loading && <CircularProgressCenter />}

        {timeline?.length > 0 ? (
          <>
            {timeline.map((item, index) => (
              <TimelineItem key={item.id} item={item} index={index} />
            ))}
          </>
        ) : (
          <Typography variant="body1" style={{ color: "#999" }}>
            First compose a social post...
          </Typography>
        )}
      </Box>
    </>
  );
};

export default Timeline;
