import styled from "styled-components";

export default styled.div`
  margin-left: 12px;

  .nav-link {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
  }

  .active {
    color: white;
  }
`;
