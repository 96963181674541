import { memo } from "react";
import PropTypes from "prop-types";

// Components
import TableCell from "@material-ui/core/TableCell";
import TextWithHighlight from "components/ElasticSearch/TextWithHighlight";

const LicenseCell = ({ licenses, highlight }) => (
  <TableCell component="td" className="cell--licenses">
    {licenses?.map(({ name }) => (
      <TextWithHighlight
        key={name}
        highlight={highlight}
        fieldId="licenses.name"
        text={name}
      />
    ))}
  </TableCell>
);

LicenseCell.propTypes = {
  licenses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  highlight: PropTypes.shape({}),
};

export default memo(LicenseCell);
