import { takeLatest, all } from "redux-saga/effects";
import * as ChannelTypes from "./Channel.types";
import * as channelApi from "./channel.service";
import * as ChannelSagas from "./Channel.sagas";

function* sagas() {
  return yield all([
    takeLatest(ChannelTypes.SET_SELECTED, ChannelSagas.setConfig, channelApi),
  ]);
}

export default sagas;
