import { forwardRef } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const SeoTextField = forwardRef(
  ({ errors, field, value, onChange, ...props }, ref) => {
    if (field.multiple) {
      return (
        <Autocomplete
          ref={ref}
          onChange={(e, data) => onChange(data)}
          multiple
          freeSolo
          variant="outlined"
          options={[]}
          value={Array.isArray(value) ? value : []}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={field.name}
              placeholder={field.name}
            />
          )}
        />
      );
    }

    return (
      <TextField
        {...props}
        onChange={onChange}
        value={value}
        label={field.name}
        variant="outlined"
        inputRef={ref}
        error={!!errors}
      />
    );
  }
);

export default SeoTextField;
