import InboxOverview from "./InboxOverview";

const routes = [
  {
    key: "INBOX",
    path: "/channel/:channelId/inbox/list",
    component: InboxOverview,
    exact: true,
    displayNav: true,
    auth: true,
  },
];

export default routes;
