import { Component } from "react";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

import ChannelListView from "./ChannelList";
import styles from "./style";

class ChannelOverview extends Component {
  render() {
    const { t, classes } = this.props;

    return (
      <div className={classes.layoutFill}>
        <Helmet>
          <title>
            {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -
            {t("titles.CHANNEL")}
          </title>
        </Helmet>
        <ChannelListView {...this.props} />
      </div>
    );
  }
}

ChannelOverview.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  channels: state.user.user.channels,
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(ChannelOverview);
