const namespace = "ASSET";

export const FIND = `${namespace}/FIND`;
export const FIND_MORE = `${namespace}/FIND_MORE`;
export const FIND_SUCCESS = `${namespace}/FIND_SUCCESS`;
export const FIND_FAILURE = `${namespace}/FIND_FAILURE`;
export const RESET = `${namespace}/RESET`;
export const SET_SELECTED_TYPE = `${namespace}/SET_SELECTED_TYPE`;
export const FIND_ASSET = `${namespace}/FIND_ASSET`;
export const FIND_ASSET_SUCCESS = `${namespace}/FIND_ASSET_SUCCESS`;
export const RESET_ASSET = `${namespace}/RESET_ASSET`;
export const SET_SORT = `${namespace}/SET_SORT`;
export const UPDATE_QUALITY_CHECKS = `${namespace}/UPDATE_QUALITY_CHECKS`;
export const FRAGMENT_CREATED = `${namespace}/FRAGMENT_CREATED`;
export const PATCH_LABELS = `${namespace}/PATCH_LABELS`;
export const PATCH_LABELS_SUCCESS = `${namespace}/PATCH_LABELS_SUCCESS`;
export const PATCH_LABELS_FAILED = `${namespace}/PATCH_ASSET_FAILED`;
