import styled from "styled-components";

export const StyledPreview = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: white;
  background: ${({ theme }) => theme.palette.success.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.error-state {
    background: ${({ theme }) => theme.palette.error.light};
    color: ${({ theme }) => theme.palette.error.contrastText};
  }

  h4 {
    font-size: 0.875rem;
    font-weight: 700;
  }

  h6 {
    font-size: 0.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover .delete-button {
    opacity: 1;
  }

  & > .delete-button {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: red;
    color: white;
    border: none;
    cursor: pointer;
    transform: translate(50%, -50%);
    outline: none;
    transition: opacity ease-out 0.2s;

    .MuiSvgIcon-root {
      color: white !important;
    }

    .MuiButtonBase-root:hover {
      background: none;
    }

    & .check-mark-root {
      display: none;
    }

    &.loading,
    &.error {
      display: none;
    }

    &.deleting,
    &.deleted {
      opacity: 1;

      & .check-mark-root {
        display: block;
      }
    }

    &.deleted {
      opacity: 0;
      transition: opacity ease-out 0.2s;
      transition-delay: 2s;
    }

    & .MuiSvgIcon-root {
      font-size: 1rem;
    }

    & .check-mark-root {
      top: 0;
    }
  }
`;

export const StyledUpload = styled.div`
  position: relative;
  margin: ${({ theme }) => theme.spacing(2)}px 0
    ${({ theme }) => theme.spacing(2)}px 0;

  header {
    display: flex;
    margin-bottom: 0 !important;
    justify-content: space-between;
    padding: 4px 0;

    .column-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .MuiTypography-h4 {
      font-size: 0.75rem;
      margin-left: ${({ theme }) => theme.spacing()}px;
      font-weight: 700;
    }
  }

  & .download-link {
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing(0.5)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    padding: 0 4px;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.grey[900]};

    & .MuiSvgIcon-root {
      font-size: 1.25rem;
    }

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }

  .drop-zone {
    position: relative;
    min-width: 300px;
    height: auto !important;
    min-height: 38px;

    .error {
      max-width: 340px;
    }

    .error ul {
      padding: 0 0 0 16px;
      margin: 0;
    }
  }
`;
