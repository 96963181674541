import { takeLatest, all } from "redux-saga/effects";
import * as InboxTypes from "./Inbox.types";
import * as InboxSagas from "./Inbox.sagas";
import * as inboxApi from "./services";

import * as ChannelTypes from "../Channel/Channel.types";

function* sagas() {
  return yield all([
    takeLatest(InboxTypes.FIND, InboxSagas.loadInbox, inboxApi),
    takeLatest(ChannelTypes.SET_SELECTED, InboxSagas.reset),
  ]);
}

export default sagas;
