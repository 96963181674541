// Icons
import DeleteIcon from "@material-ui/icons/Delete";

// Components
import CheckMark from "components/CheckMarkLoader";

// Style
import StyledComponent from "./ImagePreview.style";

const ImagePreview = ({ multiple, image, onDelete, fileState }) => {
  if (!image || multiple) {
    return null;
  }

  const onDeleteHandle = (e) => {
    e.stopPropagation();
    onDelete();
  };

  const stateMap = {
    deleting: "loading",
    deleted: "completed",
  };
  const state = stateMap[fileState];

  return (
    <StyledComponent>
      <img src={image} alt="preview" />
      {onDelete && (
        <button
          className={`delete-button ${fileState}`}
          type="button"
          onClick={onDeleteHandle}
        >
          <CheckMark
            className
            state={state}
            size={32}
            borderWidth={2}
            successColor="white"
            successBackground="red"
            successBorderColor="red"
            style={{ marginTop: "-2px" }}
          />
          <DeleteIcon />
        </button>
      )}
    </StyledComponent>
  );
};

export default ImagePreview;
