import { useRef, useEffect } from "react";

/**
 * Keep a reference to previous prop
 * values.
 */
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
