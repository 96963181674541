/* eslint-disable prefer-spread */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Grid } from "@material-ui/core";
import {
  AutoCompleteWithControl,
  TextField,
  CheckboxWithControl,
} from "components/Form";
import MUITextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Utils from "features/Common/utils";
import { Button } from "components";
import { getAssets, getChildAssets } from "../services";
import { FORM_MODES } from "./constants";

// Component
const IdentifierChangeDialog = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
      <DialogTitle>{t("labels.SURE_CHANGE_IDENTIFIER")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("text.SURE_CHANGE_IDENTIFIER")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          {t("buttons.OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const IdentifierInput = ({ mode }) => {
  const { errors, setValue, register } = useFormContext();
  const { t } = useTranslation();
  const [identifierDialogOpen, setIdentifierDialogOpen] = useState(false);
  const [
    identifierWarningAlreadyShowed,
    setIdentifierWarningAlreadyShowed,
  ] = useState(false);

  const showChangeIdentifierWarning = () => {
    // Don't prompt if new asset
    if (mode === FORM_MODES.new || identifierWarningAlreadyShowed) {
      return;
    }

    setIdentifierDialogOpen(true);
    setIdentifierWarningAlreadyShowed(true);
  };

  const onChangeHandler = (event) =>
    setValue("identifier", Utils.Assets.changeIdentifier(event.target.value));

  return (
    <>
      <TextField
        name="identifier"
        label={t("labels.IDENTIFIER")}
        className="inputMargin"
        variant="outlined"
        error={errors?.identifier?.message}
        onChange={onChangeHandler}
        ref={register}
        onFocus={showChangeIdentifierWarning}
        fullWidth
      />
      <IdentifierChangeDialog
        open={identifierDialogOpen}
        onClose={() => setIdentifierDialogOpen(false)}
      />
    </>
  );
};

const SeasonAndEpisode = () => {
  const { setValue, register, errors, watch } = useFormContext();
  const type = watch("type");
  const season = watch("season");
  const asset = watch("asset");
  const shouldHasParent = ["season", "episode"].indexOf(type) > -1;
  const [assets, setAssets] = useState([]);
  const [seasons, setSeasons] = useState(asset?.seasons || []);
  let assetsTimer = null;

  useEffect(() => {
    register("asset");
    register("season");
    register("index");
  }, [register]);

  const handleAssetChange = (event) => {
    const searchInput = event.target.value;

    clearTimeout(assetsTimer);

    assetsTimer = setTimeout(() => {
      getAssets(searchInput, "series").then((response) => {
        setAssets(response.data.data);
      });
    }, 300);
  };

  const setAsset = (newValue) => {
    if (!newValue) return;

    setValue("asset", newValue, true);
    getChildAssets(newValue.id, "season").then((response) => {
      setSeasons(response.data.data);

      if (response.data.data.length >= 1 && type !== "episode") {
        setValue(
          "index",
          Math.max.apply(
            Math,
            response.data.data.map((o) => o.index)
          ) + 1
        );
      } else if (type !== "episode") {
        setValue("index", 1);
      }
    });
  };

  const setSeason = (newValue) => {
    if (!newValue) return;

    setValue("season", newValue, true);
    getChildAssets(newValue.id, "episode").then((response) => {
      const indexValue = Math.max.apply(
        Math,
        response.data.data.map((o) => o.index)
      );

      setValue("index", indexValue || 1);
    });
  };

  if (!shouldHasParent) return null;

  return (
    <div style={{ display: "flex" }}>
      <Autocomplete
        name="asset"
        style={{ flex: 1 }}
        className="inputMargin"
        autoHighlight
        options={assets}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => setAsset(newValue)}
        defaultValue={asset?.id ? asset : null}
        renderInput={(params) => (
          <MUITextField
            {...params}
            autoFocus
            label="Serie"
            variant="outlined"
            onChange={handleAssetChange}
            fullWidth
          />
        )}
      />
      {asset && seasons && type === "episode" && (
        <Autocomplete
          name="season"
          style={{ flex: 1, marginLeft: 16 }}
          className="inputMargin"
          autoHighlight
          options={seasons}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => setSeason(newValue)}
          defaultValue={season?.id ? season : null}
          renderInput={(params) => (
            <MUITextField
              {...params}
              autoFocus
              label="Seizoen"
              variant="outlined"
              fullWidth
            />
          )}
        />
      )}
      {asset && (
        <TextField
          name="index"
          type="number"
          className="inputMargin"
          label="Index"
          placeholder="index"
          variant="outlined"
          style={{ marginLeft: 16 }}
          error={errors.index?.message}
          ref={register}
        />
      )}
    </div>
  );
};

const assetTypes = [
  {
    type: "labels.VIDEO",
    label: "labels.VIDEO",
    value: "video",
  },
  {
    type: "labels.SERIE",
    label: "labels.SERIE",
    value: "series",
  },
  {
    type: "labels.SERIE",
    label: "labels.SEASON",
    value: "season",
  },
  {
    type: "labels.SERIE",

    label: "labels.EPISODE",
    value: "episode",
  },
];

const qualities = [
  {
    label: "text.A_TITLE",
    value: "A",
  },
  {
    label: "text.B_TITLE",
    value: "B",
  },
  {
    label: "text.C_TITLE",
    value: "C",
  },
];

const AssetEditFormLeft = ({ mode, autoFocusField }) => {
  const { t } = useTranslation();
  const { control, errors, register, setValue } = useFormContext();

  const onChangeHandlerTitle = (event) => {
    if (mode === FORM_MODES.new) {
      setValue("identifier", Utils.Assets.changeIdentifier(event.target.value));
    }

    setValue("name", event.target.value);
  };

  return (
    <>
      <AutoCompleteWithControl
        name="type"
        className="inputMargin"
        getOptionLabel={(option) =>
          option.label
            ? t(option.label)
            : t(assetTypes.filter((item) => item.value === option)[0]?.label)
        }
        groupBy={(option) => t(option.type)}
        outputFormatter={(value) => value?.value || value}
        label={t("labels.TYPE")}
        variant="outlined"
        fullWidth
        options={assetTypes}
        control={control}
        error={errors.type?.message}
        getOptionSelected={(option, value) => option.value === value}
      />

      <SeasonAndEpisode />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="name"
            className="inputMargin"
            label={t("labels.TITLE")}
            autoFocus={autoFocusField === "title"}
            placeholder={`${t("text.TITLE_OF_THE_ASSET")}...`}
            variant="outlined"
            error={errors.name?.message}
            onChange={onChangeHandlerTitle}
            ref={register}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <IdentifierInput mode={mode} />
        </Grid>
      </Grid>
      <TextField
        name="notes"
        className="inputMargin"
        label={t("labels.NOTES")}
        placeholder={t("labels.NOTES")}
        variant="outlined"
        error={errors.notes?.message}
        ref={register}
        fullWidth
      />
      <AutoCompleteWithControl
        name="quality"
        className="inputMargin"
        getOptionLabel={(option) =>
          option.label
            ? t(option.label)
            : t(qualities.filter((item) => item.value === option)[0]?.label)
        }
        label={t("labels.QUALITY")}
        variant="outlined"
        fullWidth
        options={qualities}
        control={control}
        error={errors.quality?.message}
        outputFormatter={(value) => value?.value || value}
        getOptionSelected={(option, value) => option.value === value}
      />
      <div className="qualityCheck">
        <CheckboxWithControl
          name="quality_check"
          color="primary"
          label={t("labels.QUALITY_CHECK")}
          labelPlacement="end"
          control={control}
        />
        <CheckboxWithControl
          name="indexable"
          color="primary"
          label={t("labels.SEARCHABLE")}
          labelPlacement="end"
          control={control}
        />
      </div>
    </>
  );
};

export default memo(AssetEditFormLeft);
