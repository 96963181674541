import { Grid } from "@material-ui/core";

/* Components */
import Layout from "../../components/Layout";

/* Features */
import SeoDetail from "features/Seo/SeoDetail";

/* Provider */
import { SeoDetailProvider } from "features/Seo/SeoDetail/SeoDetailProvider";

const Page = ({ routes }) => (
  <Layout routes={routes}>
    <Grid container spacing={0} wrap="nowrap">
      <SeoDetailProvider>
        <SeoDetail />
      </SeoDetailProvider>
    </Grid>
  </Layout>
);

export default Page;
