import styled from "styled-components";

import AppBar from "@material-ui/core/AppBar";

export const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-root {
    background: white;
    box-shadow: none;
    color: ${({ theme }) => theme.palette.grey[900]};
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .MuiToolbar-root {
    flex: 1;
    justify-content: space-between;
  }
`;

export const StyledContent = styled.div`
  padding: 0 16px 16px 16px;
`;
