/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import { memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import moment from "moment";
import classNames from "classnames";

// Icons
import InfoIcon from "@material-ui/icons/Info";

// Components
import Typography from "@material-ui/core/Typography";
import NationFlag from "components/NationFlag";

// Style
import StyledComponent from "./style";

const SchedulePopover = ({ name, identifier, schedule, locale }) => {
  const { t } = useTranslation();

  if (!schedule) return null;
  const countries = Object.entries(schedule);
  return (
    <StyledComponent>
      <header>
        <InfoIcon fontSize="small" />
        <Typography variant="h5">{identifier}</Typography>
      </header>
      {countries &&
        countries.map(([countryCode, value]) => (
          <div
            className={classNames("country-info", {
              active: countryCode === locale,
            })}
            key={countryCode}
          >
            <header>
              <NationFlag countryCode={countryCode} />
              <Typography variant="h5">
                {name[countryCode] ||
                  t("text.MISSING_NAME", { language: countryCode })}
              </Typography>
            </header>
            <table className="table--dates">
              <thead>
                <tr>
                  <th>{t("labels.CONTRACT_START_DATE")}</th>
                  <th>{t("labels.CONTRACT_END_DATE")}</th>
                  <th>{t("labels.PUBLICATION_DATE")}</th>
                  <th>{t("labels.PUBLICATION_EXPIRATION_DATE")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {value.contract_start
                      ? moment(value.contract_start).format("DD-MM-YYYY")
                      : "-"}
                  </td>
                  <td>
                    {value.contract_end
                      ? moment(value.contract_end).format("DD-MM-YYYY")
                      : "-"}
                  </td>
                  <td>
                    {value.schedule_start
                      ? moment(value.schedule_start).format("DD-MM-YYYY")
                      : "-"}
                  </td>
                  <td>
                    {value.schedule_end
                      ? moment(value.schedule_end).format("DD-MM-YYYY")
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
    </StyledComponent>
  );
};

SchedulePopover.propTypes = {
  name: PropTypes.shape({}),
};

export default memo(SchedulePopover);
