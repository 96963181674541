import * as types from "./User.types";

export const loginSetError = (error) => ({
  type: types.SET_LOGIN_ERROR,
  error,
});

export const getUser = () => ({
  type: types.GET_USER,
});

export const setUser = (user) => ({
  type: types.SET_USER,
  user,
});

export const setUserLanguage = (language) => ({
  type: types.SET_USER_LANGUAGE,
  language,
});

export const setUserLanguageSuccess = (language, country) => ({
  type: types.SET_USER_LANGUAGE_SUCCESS,
  language,
  country,
});

export const setUserCountry = (country) => ({
  type: types.SET_USER_COUNTRY,
  country,
});

export const setUserCountrySuccess = (country, language) => ({
  type: types.SET_USER_COUNTRY_SUCCESS,
  country,
  language,
});

export const setUserTranslateLanguage = (language) => ({
  type: types.SET_USER_TRANSLATE_LANGUAGE,
  language,
});

export const setUserTranslateLanguageSuccess = (language) => ({
  type: types.SET_USER_TRANSLATE_LANGUAGE_SUCCESS,
  language,
});

export const logout = () => ({
  type: types.LOGOUT,
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});
