import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import axios from "axios";
import { parse } from "subtitle";
import { List } from "react-virtualized";

// Icons
import LockIcon from "@material-ui/icons/Lock";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";

// Component
import CircularProgressCenter from "components/CircularProgressCenter";
import Checkbox from "components/Form/Checkbox";
import Alert from "@material-ui/lab/Alert";
import Button from "components/Button";
import NationFlag from "components/NationFlag";

// hooks
import { useNormalizedSubtitleFiles } from "features/Fragment/hooks";
import { useAvailableLanguages } from "features/Translation/hooks";
import { usePersistentState } from "features/Common/hooks";

// Utils
import Utils from "features/Common/utils";
import { convertSecondsToHuman } from "../utils";

// Style
import StyledComponent, { StyledSelect, StyledMenuItem } from "./style";

const SubtitleError = ({ error }) => <Alert severity="error">{error}</Alert>;

const SubtitleViewer = ({
  videoMetaLoading,
  videoError,
  fragmentFiles,
  onClose,
  currentTimeMs,
  onTimeStampChange,
  onSubtitleChange,
  currentLanguage,
}) => {
  const listRef = useRef();
  const { t } = useTranslation();
  const subtitleFiles = useNormalizedSubtitleFiles(fragmentFiles);
  const [subtitleContent, setSubtitleContent] = useState();
  const [activeSubtitle, setActiveSubtitle] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const languages = useAvailableLanguages() || [];

  const [
    scrollToCurrentTimeEnabled,
    setScrollToCurrentTimeEnabled,
  ] = usePersistentState(true, "fragment.subtitle.viewer.scroll.enabled");

  // Load and parse subtitle file for current language.
  useEffect(() => {
    if (!activeSubtitle) return;

    const url = Utils.Fragments.getAssetStoragePath(
      activeSubtitle?.paths[0]?.path
    );

    setLoading(true);
    axios({
      url,
      method: "GET",
      responseType: "text", // important
    })
      .then((response) => {
        setLoading(false);
        const subtitleParsed = parse(response.data);
        setSubtitleContent(subtitleParsed);
      })
      .catch((networkError) => {
        setLoading(true);
        setError(t("error.SUBTITLE_PARSE_ERROR", { error: networkError }));
      });
  }, [activeSubtitle, t]);

  // Video language has changed. Therefore, show subtitle
  // for the new language.
  useEffect(() => {
    if (currentLanguage === activeSubtitle?.locale) return;
    const subtitle = subtitleFiles[currentLanguage]?.find(
      (sub) => sub.status === "active"
    );
    if (subtitle) setActiveSubtitle(subtitle);
  }, [currentLanguage, subtitleFiles, activeSubtitle]);

  // Set video error
  useEffect(() => {
    if (!videoError) return;
    setError(t("error.VIDEO_LOAD_ERROR"));
  }, [videoError, setError, t]);

  // Scroll list to current timestamp
  useEffect(() => {
    if (!scrollToCurrentTimeEnabled) return;
    if (!subtitleContent || !listRef?.current) return;

    const index = subtitleContent.findIndex(
      ({ start, end }) => start >= currentTimeMs || currentTimeMs <= end
    );

    listRef.current.scrollToRow(index % subtitleContent?.length);
  }, [
    currentTimeMs,
    activeSubtitle,
    listRef,
    scrollToCurrentTimeEnabled,
    subtitleContent,
  ]);

  const onSubtitleChangeHandler = (event) => {
    const { value } = event.target;

    const newActiveSubtitle = subtitleFiles[value]?.find(
      (sub) => sub.status === "active"
    );

    if (!newActiveSubtitle) return;

    setActiveSubtitle(newActiveSubtitle);
    onSubtitleChange({ subtitle: newActiveSubtitle });
  };

  const onScrollEnabledChanged = () => {
    setScrollToCurrentTimeEnabled(!scrollToCurrentTimeEnabled);
  };

  const rowRenderer = ({ key, index, style }) => {
    const { start, end, text } = subtitleContent[index];
    const active = currentTimeMs >= start && currentTimeMs <= end;

    return (
      <div
        key={key}
        style={style}
        className={active ? "subtitle active" : "subtitle"}
      >
        <div className="content">
          <div className="time-stamps">
            <Button
              variant="text"
              trackName="Subtitle start timestamp"
              trackDetails={{ start, subtitle: activeSubtitle }}
              onClick={() => onTimeStampChange({ timeStamp: start })}
            >
              {convertSecondsToHuman(start)}
            </Button>
            -
            <Button
              trackName="Subtitle end timestamp"
              trackDetails={{ end, subtitle: activeSubtitle }}
              variant="text"
              onClick={() => onTimeStampChange({ timeStamp: end })}
            >
              {convertSecondsToHuman(end)}
            </Button>
          </div>
          <span className="text">{text}</span>
        </div>
      </div>
    );
  };

  return (
    <StyledComponent>
      {(loading || videoMetaLoading) && !error && <CircularProgressCenter />}
      <StyledSelect
        value={activeSubtitle?.locale || ""}
        displayEmpty
        name="state"
        onChange={onSubtitleChangeHandler}
      >
        <StyledMenuItem value="" disabled>
          {t("text.SELECT_SUBTITLE_LANGUAGE")}
        </StyledMenuItem>
        {Object.values(subtitleFiles).map((subtitles) => {
          const subtitle = subtitles?.find((sub) => sub.status === "active");
          if (!subtitle) return null;

          const languageName =
            languages.find((lang) => lang.code === subtitle.locale)?.name ||
            subtitle.locale;
          return (
            <StyledMenuItem key={subtitle.locale} value={subtitle.locale}>
              <NationFlag countryCode={subtitle.locale} />
              <span>{languageName}</span>
            </StyledMenuItem>
          );
        })}
      </StyledSelect>

      {error && <SubtitleError error={error} />}
      {!error && (
        <>
          <div className="subtitles">
            <List
              ref={listRef}
              width={1}
              height={250}
              rowCount={subtitleContent?.length || 0}
              rowHeight={80}
              rowRenderer={rowRenderer}
              containerStyle={{
                width: "100%",
                maxWidth: "100%",
              }}
              style={{
                width: "100%",
              }}
            />
          </div>
          <Checkbox
            name="check"
            trackName="Scroll To CurrentTime enabled"
            className="scroll-sync-checkbox"
            color={scrollToCurrentTimeEnabled ? "primary" : "default"}
            checked={scrollToCurrentTimeEnabled}
            onChange={onScrollEnabledChanged}
            label="Scroll naar huidige tijd"
            icon={<LockOpenOutlinedIcon />}
            iconChecked={<LockIcon />}
          />
        </>
      )}
      <div className="action-buttons">
        <Button
          className="action-button"
          size="small"
          trackName="Subtitle Viewer Close"
          color="primary"
          onClick={onClose}
          variant="outlined"
        >
          {t("labels.CLOSE")}
        </Button>
      </div>
    </StyledComponent>
  );
};

SubtitleViewer.defaultProps = {
  onTimeStampChange: () => {},
  onSubtitleChange: () => {},
  onClose: () => {},
  videoMetaLoading: false,
};

SubtitleViewer.propTypes = {
  onTimeStampChange: PropTypes.func,
  onSubtitleChange: PropTypes.func,
  onClose: PropTypes.func,
  videoMetaLoading: PropTypes.bool,
};

export default SubtitleViewer;
