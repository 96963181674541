import styled from "styled-components";
import Button from "components/Button";

export default styled(Button)`
  & .MuiButton-root {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    padding: 4px 12px;

    & .column-left {
      display: flex;
      align-items: center;
      text-align: left;
    }

    & .MuiFormControlLabel-root {
      margin-right: 2px;
    }

    & .fi {
      margin-right: 6px;
    }
  }
`;
