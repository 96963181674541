import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

import * as yup from "yup";

// Hooks
import { useAuthentication } from "features/Auth/AuthProvider";

// UI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { Button } from "components";
import { TextFieldWithControl } from "components/Form/TextField";
import { CheckboxWithControl } from "components/Form/Checkbox";

// Assets
import dcLogo from "features/Common/img/logo.png";

// Style
import styles from "./Login.styles";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const defaultValues = {
  email: "",
  password: "",
  twoFactorCode: "",
  remember: false,
};

const Login = ({ location, classes }) => {
  const { t } = useTranslation();

  // State
  const {
    isAuthenticated,
    isAuthenticating,
    authenticationError,
    login,
    needTwoFactor,
  } = useAuthentication();

  // Redirect after login to referer
  const referer = location?.state?.referer || "/";

  // Form
  const { handleSubmit, errors, control, formState } = useForm({
    validationSchema,
    defaultValues,
  });

  const onLogin = (formData) => {
    const { email, password, remember, twoFactorCode } = formData;
    login(email, password, remember, twoFactorCode);
  };

  // Invoke formState dirty values so that the proxy is
  // trigger/activated. Otherwise formState.isDirty doesn't work
  useEffect(() => {
    if (!formState) return;
    formState.dirtyFields.values();
  }, [formState]);

  if (isAuthenticated) {
    return <Redirect to={referer} />;
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <title>
          {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -{" "}
          {t("titles.LOGIN")}
        </title>
      </Helmet>

      <Card className={classes.card}>
        <CardContent>
          <div
            className={`${classes.centerContainer} ${classes.logoContainer}`}
          >
            <img src={dcLogo} alt="DutchChannels Logo" width="250" />
          </div>

          <div css={classes.inputContainer}>
            <Typography color="textSecondary" />

            <div className={classes.centerContainer}>
              <Typography variant="h5" color="textSecondary">
                {t("titles.LOGIN")}
              </Typography>
            </div>

            <form onSubmit={handleSubmit(onLogin)} noValidate>
              <TextFieldWithControl
                className={classes.textField}
                autoFocus
                control={control}
                variant="outlined"
                required
                id="email"
                name="email"
                error={errors?.email?.message}
                label={t("labels.EMAIL_ADDRESS")}
                placeholder={t("labels.EMAIL_ADDRESS")}
                margin="normal"
                fullWidth
              />

              <FormControl fullWidth error={errors?.password?.message}>
                <TextFieldWithControl
                  variant="outlined"
                  required
                  id="password"
                  name="password"
                  control={control}
                  error={errors?.password?.message}
                  label={t("labels.PASSWORD")}
                  placeholder={t("labels.PASSWORD")}
                  margin="normal"
                  type="password"
                />
                <FormHelperText
                  id="component-error-text"
                  error={!!authenticationError}
                >
                  {authenticationError}
                </FormHelperText>
              </FormControl>

              {needTwoFactor && (
                <FormControl
                  fullWidth
                  error={errors?.password?.message}
                  style={{ marginTop: 16 }}
                >
                  <TextFieldWithControl
                    control={control}
                    variant="outlined"
                    required
                    id="twoFactorCode"
                    name="twoFactorCode"
                    error={errors?.twoFactorCode?.message}
                    label={t("labels.TWO_FACTOR_CODE")}
                    placeholder={t("labels.TWO_FACTOR_CODE")}
                    margin="normal"
                    fullWidth
                  />
                  <FormHelperText id="component-error-text">
                    {t("text.TWO_FACTOR_CHALLENGE")}
                  </FormHelperText>
                </FormControl>
              )}

              <div
                className={`${classes.centerContainer} ${classes.checkBoxContainer}`}
              >
                <CheckboxWithControl
                  name="remember"
                  label={t("labels.REMEMBER_ME")}
                  control={control}
                />
              </div>

              <Button
                size="large"
                type="submit"
                disabled={!formState.dirty}
                loading={isAuthenticating}
              >
                {t("buttons.LOGIN")}
              </Button>

              <div className={classes.passwordForgetContainer}>
                <Link
                  to="/forgot-password"
                  className={classes.passwordForgetLink}
                >
                  {t("labels.PASSWORD_FORGOT")}
                </Link>
              </div>
            </form>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Login);
