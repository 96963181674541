/* eslint-disable no-continue */
/* eslint-disable no-prototype-builtins */

export const flattenObject = (oldObj) => {
  const root = {};
  (function tree(obj, index) {
    const suffix = toString.call(obj) === "[object Array]" ? "]" : "";
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (!obj.hasOwnProperty(key)) continue;
      root[index + key + suffix] = obj[key];
      if (toString.call(obj[key]) === "[object Array]")
        tree(obj[key], `${index + key + suffix}[`);
      if (toString.call(obj[key]) === "[object Object]")
        tree(obj[key], `${index + key + suffix}.`);
    }
  })(oldObj, "");
  return root;
};

export const maxBy = (array, prop) => {
  if (!Array.isArray(array) || !array.length) return null;

  return array.reduce((l, e) => (e[prop] > l[prop] ? e : l));
};

export const normalize = (
  array,
  keySelector,
  valueSelector = (value) => value
) => {
  if (!Array.isArray(array)) {
    return array;
  }

  return array.reduce((current, data) => {
    const key = keySelector(data);
    return {
      ...current,
      [key]: valueSelector(data),
    };
  }, {});
};

export const sortBy = (key) =>
  // eslint-disable-next-line no-nested-ternary
  (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);

export const flagCode = (code) => {
  const codes = {
    en: "gb",
    nl: "nl",
    nb: "no",
    sv: "se",
  };

  if (codes[code]) return codes[code];

  return code;
};

/**
 * Convert "9:16" ratio string to rational number
 *
 * @param {string} aspectRatioString "9:16"
 * @return 9/16
 */
export const calculateRatio = (aspectRatioString) => {
  const defaultRatio = [1, 1];
  const parts = aspectRatioString?.split(":");
  const [width, height] = parts || defaultRatio;
  return width / height;
};

/**
 * Get file path by identifier
 *
 * paths input:
 * [
 *  { id: 1, identifier: 'raw', path: ... }
 *  { id: 2, identifier: 'thumbnail', path: ... }
 * ]
 *
 * @params {array} paths
 * @params {string} identifier thumbnail for example
 * @return path or null
 *
 */
export const getFilePathByIdentifier = (paths, identifier) =>
  paths?.find((path) => path.identifier === identifier);
