/* eslint-disable camelcase */
import { useCallback, useState, memo } from "react";
import { useTranslation } from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import { useJob } from "./JobProvider";

const TabPanel = ({
  children,
  value,
  index,
  streams,
  type,
  fileConfig,
  update,
  ...other
}) => {
  const { t } = useTranslation();

  const streamIsSelected = (stream, configIndex) => {
    if (!configIndex) return false;

    return configIndex.indexOf(stream.index) > -1;
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {type === "video" && (
            <List>
              {streams.map((stream) => (
                <ListItem key={stream.index}>
                  <Checkbox
                    color="primary"
                    onChange={(event) => {
                      update(stream, event.target.checked);
                    }}
                    checked={streamIsSelected(
                      stream,
                      fileConfig?.video_stream_index
                    )}
                  />
                  <div>
                    <p>
                      <strong>{stream.index}</strong>:{" "}
                      {stream.codec_long_name || stream.codec_name}
                    </p>
                    <p>
                      <strong>{stream.width || stream.coded_width}</strong> x{" "}
                      <strong>{stream.height || stream.coded_height}</strong> /{" "}
                      <strong>{stream.display_aspect_ratio}</strong>
                    </p>
                    <p>
                      <small>
                        {stream.pix_fmt && <span>{stream.pix_fmt}</span>}
                        {stream.tags && stream.tags.language && (
                          <span> /{stream.tags.language}</span>
                        )}
                      </small>
                    </p>
                  </div>
                </ListItem>
              ))}
            </List>
          )}

          {type === "audio" && (
            <>
              <List>
                {streams.map((stream) => (
                  <ListItem key={stream.index}>
                    <Checkbox
                      color="primary"
                      onChange={(event) => {
                        update(stream, event.target.checked);
                      }}
                      checked={streamIsSelected(
                        stream,
                        fileConfig?.audio_stream_index
                      )}
                    />
                    <div>
                      <p>
                        <strong>{stream.index}</strong>:{" "}
                        {stream.codec_long_name || stream.codec_name}
                      </p>
                      <p>
                        <small>
                          <span>
                            {t("labels.CHANNELS")}:{" "}
                            <strong>{stream.channels}</strong>
                            {stream.channel_layout &&
                              stream.channel_layout !== "unknown" && (
                                <span> /{stream.channel_layout}</span>
                              )}
                          </span>

                          {stream.tags && stream.tags.language && (
                            <span> /{stream.tags.language}</span>
                          )}
                        </small>
                      </p>
                    </div>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </>
      )}
    </div>
  );
};

const StreamSettings = () => {
  const { streams, config, setConfig } = useJob();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const videoStreams = streams.filter(
    (stream) => stream.codec_type === "video"
  );
  const audioStreams = streams.filter(
    (stream) => stream.codec_type === "audio"
  );

  const channels = audioStreams.reduce((prev, cur) => prev + cur.channels, 0);

  const updateVideoStreams = useCallback(
    (stream, selected) => {
      const newConfig = { ...config };

      if (selected) {
        newConfig.video_stream_index = [stream.index];
      } else if (config?.video_stream_index) {
        delete newConfig.video_stream_index;
      }

      setConfig(newConfig);
    },
    [setConfig, config]
  );

  const updateAudioStreams = useCallback(
    (stream, selected) => {
      const newConfig = { ...config };

      if (selected) {
        if (!config?.audio_stream_index)
          newConfig.audio_stream_index = [stream.index];
        else {
          newConfig.audio_stream_index = [
            ...config.audio_stream_index,
            stream.index,
          ];
        }
      } else if (!selected) {
        if (config?.audio_stream_index.length > 1) {
          newConfig.audio_stream_index = config?.audio_stream_index.filter(
            (index) => index !== stream.index
          );
        } else delete newConfig.audio_stream_index;
      }

      setConfig(newConfig);
    },
    [setConfig, config]
  );

  return (
    <>
      <AppBar position="static">
        <Tabs
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          variant="fullWidth"
        >
          <Tab label={`Video (${videoStreams.length})`} />
          <Tab label={`Audio (${audioStreams.length} / ${channels})`} />
        </Tabs>
      </AppBar>
      <TabPanel
        value={value}
        index={0}
        streams={videoStreams}
        type="video"
        fileConfig={config}
        update={updateVideoStreams}
      />
      <TabPanel
        value={value}
        index={1}
        streams={audioStreams}
        type="audio"
        fileConfig={config}
        update={updateAudioStreams}
      />
    </>
  );
};

export default memo(StreamSettings);
