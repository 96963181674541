import { memo } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import { Grid } from "@material-ui/core";
import AssetEditFormLeft from "./AssetEditFormLeft";

// Component
const AssetEditForm = ({ mode, autoFocusField }) => (
  <DialogContent>
    <Grid container spacing={4}>
      <Grid item xs>
        <AssetEditFormLeft mode={mode} autoFocusField={autoFocusField} />
      </Grid>

      {/* <Grid item xs={6}>
        <AssetEditFormRight />
      </Grid> */}
    </Grid>
  </DialogContent>
);

export default memo(AssetEditForm);
