import { INITIAL_STATE } from "./User.state";

export const loginSetError = (state, { error }) =>
  state.merge({
    loginError: error,
  });

export const setUser = (state, { user }) =>
  state.merge({
    user,
    loginError: null,
    userDataLoaded: true,
  });

export const setUserLanguage = (state) => state;
export const setUserLanguageSuccess = (state, { language, country }) =>
  state.merge({
    user: {
      ...state.user,
      default_language: language,
      default_country: country,
    },
  });

export const setUsercountry = (state) => state;
export const setUserCountrySuccess = (state, { country, language }) =>
  state.merge({
    user: {
      ...state.user,
      default_country: country,
      default_language: language,
    },
  });

export const setUserTranslateLanguage = (state) => state;
export const setUserTranslateLanguageSuccess = (state, { language }) =>
  state.merge({ user: { ...state.user, translate_language: language } });

export const logout = (state) => state;
export const logoutSuccess = (state) => state.set(INITIAL_STATE);
