import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Box,
  Alert,
} from "@mui/material";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import NationFlag from "components/NationFlag";
import { hasLocale } from "../FragmentSocialPost";
import { get } from "lodash";
import ComposeItem from "./ComposeItem";
import ComposeDropZone from "./ComposeDropZone";

const Compose = ({
  list,
  form,
  regenerate,
  translate,
  editPrompt,
  selectedType,
  selectedLanguage,
}) => {
  const [errors, setErrors] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const locale = `${selectedLanguage?.code}-${selectedCountry?.code}`;

  useEffect(() => {
    if (selectedLanguage?.countries?.length) {
      setSelectedCountry(selectedLanguage.countries[0]);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    setErrors(
      get(form.values, `${selectedType.type}.locales.${locale}.errors`, [])
    );
  }, [form.values, locale, selectedType.type]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{ marginBottom: "16px", padding: "0 16px 0 16px" }}
      >
        <Grid item>
          <selectedType.icon
            style={{
              color: selectedType.color,
              display: "block",
              width: "28px",
              height: "28px",
            }}
          />
        </Grid>
        <Grid item xs>
          <Grid container spacing={1} alignItems="baseline">
            <Grid item>
              <Typography noWrap variant="h5">
                {selectedType.name}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="body1"
                style={{
                  color: "#ccc",
                }}
              >
                -
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="body1"
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  color: "#999",
                  fontWeight: "bold",
                }}
              >
                {selectedLanguage?.code}-{selectedCountry?.code}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            exclusive
            onChange={(event, country) => setSelectedCountry(country)}
            aria-label="text alignment"
          >
            {selectedLanguage.countries
              .filter((country) => hasLocale(list, country, selectedLanguage))
              .map((country) => (
                <Tooltip key={country.id} title={country.name}>
                  <ToggleButton
                    selected={selectedCountry?.id === country.id}
                    value={country}
                    aria-label={country.name}
                    style={{
                      padding: 0,
                      height: "36px",
                      width: "36px",
                    }}
                  >
                    <NationFlag
                      countryCode={
                        country.code === "intl" ? "xx" : country.code
                      }
                    />
                  </ToggleButton>
                </Tooltip>
              ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup value={null}>
            <Tooltip title="Edit prompt">
              <ToggleButton
                style={{ padding: 0, height: "36px", width: "36px" }}
                aria-label="edit prompt"
                value={"edit prompt"}
                onClick={editPrompt}
              >
                <EditIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Regenerate prompts">
              <ToggleButton
                style={{ padding: 0, height: "36px", width: "36px" }}
                aria-label="regenerate prompts"
                value={"regenerate prompts"}
                onClick={() => regenerate()}
              >
                <RefreshIcon />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      <Divider />

      {errors.length > 0 && (
        <Box sx={{ margin: "8px" }}>
          {errors.map((error) => (
            <Alert key={error} severity="error" sx={{ marginTop: "2px" }}>
              {error}
            </Alert>
          ))}
        </Box>
      )}

      <Box sx={{ margin: "16px" }}>
        <Grid container direction="row" spacing={2}>
          <Grid item sm>
            <Typography noWrap variant="h5" style={{ height: "36px" }}>
              Content
            </Typography>

            <Box sx={{ marginTop: "24px" }}>
              {selectedType.voices.map((voice) => (
                <ComposeItem
                  key={voice}
                  form={form}
                  regenerate={regenerate}
                  translate={translate}
                  selectedType={selectedType}
                  selectedLanguage={selectedLanguage}
                  selectedCountry={selectedCountry}
                  locale={locale}
                  voice={voice}
                />
              ))}
            </Box>
          </Grid>

          <Grid item>
            <Divider orientation="vertical" />
          </Grid>

          <Grid item sm={3}>
            <ComposeDropZone
              form={form}
              selectedType={selectedType}
              selectedLanguage={selectedLanguage}
              selectedCountry={selectedCountry}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Compose;
