import { Component } from "react";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";

import Search from "@material-ui/icons/Search";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";

import Immutable from "seamless-immutable";

import CustomTable from "../../../components/CustomTable";
import SelectTranslateLanguage from "../TranslationForm/SelectTranslateLanguage";
import SelectContentLanguage from "../../../components/Header/SelectContentLanguage";
import CircularProgressCenter from "../../../components/CircularProgressCenter";

import { loadTranslatableFragments } from "../../Fragment/reducer";

import styles from "./TranslationOverview.styles";

class TranslationOverview extends Component {
  constructor(props) {
    super(props);

    const { loadTranslations } = this.props;
    loadTranslations();

    this.columns = [
      {
        dataKey: "name",
        label: "labels.FRAGMENT",
        disablePadding: true,
        width: 1,
        flexGrow: 1,
      },
      {
        dataKey: "asset",
        label: "labels.ASSET",
        disablePadding: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: null,
        sortFn: null,
      },
      {
        dataKey: "identifier",
        label: "labels.ID",
        disablePadding: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: null,
        sortFn: null,
      },
    ];
  }

  componentDidMount() {
    this.columns[1].cellContentRenderer = this.assetNameCellRenderer;
    this.columns[1].sortFn = this.sortAssetName;

    this.columns[2].cellContentRenderer = this.identifierCellRenderer;
    this.columns[2].sortFn = this.sortIdentifier;
  }

  componentDidUpdate(prevProps) {
    const { user, loadTranslations } = this.props;
    const { user: prevUser } = prevProps;

    if (
      user &&
      (user.default_language !== prevUser.default_language ||
        user.translate_language !== prevUser.translate_language)
    ) {
      loadTranslations();
    }
  }

  assetNameCellRenderer = ({ rowData }) => {
    const { asset } = rowData;

    return <div>{asset.name}</div>;
  };

  sortAssetName = (items, sortDirection) =>
    Immutable.asMutable(items).sort((a, b) => {
      const nameA = a.asset.name;
      const nameB = b.asset.name;

      if (nameA < nameB) {
        return sortDirection === "desc" ? 1 : -1;
      }
      if (nameA > nameB) {
        return sortDirection === "desc" ? -1 : 1;
      }

      return 0;
    });

  identifierCellRenderer = ({ rowData }) => {
    const { identifier, asset } = rowData;

    const name = identifier
      ? `${identifier} (${asset.identifier})`
      : asset.identifier;

    return <div>{name}</div>;
  };

  sortIdentifier = (items, sortDirection) =>
    Immutable.asMutable(items).sort((a, b) => {
      const nameA = a.identifier
        ? `${a.identifier} (${a.asset.identifier})`
        : a.asset.identifier;
      const nameB = b.identifier
        ? `${b.identifier} (${b.asset.identifier})`
        : b.asset.identifier;

      if (nameA < nameB) {
        return sortDirection === "desc" ? 1 : -1;
      }
      if (nameA > nameB) {
        return sortDirection === "desc" ? -1 : 1;
      }

      return 0;
    });

  onRowClick = ({ rowData }) => {
    const { history, match } = this.props;

    const path = "/channel/:channelId/asset/fragment/:fragmentId/translate"
      .replace(":id", match.params.id)
      .replace(":fragmentId", rowData.id);
    history.push(path);
  };

  renderTopHeader() {
    const { t, classes, translations } = this.props;

    return (
      <div className={classNames(classes.topHeader)}>
        <div className={classNames(classes.titleHeader)}>
          {t("titles.TRANSLATE")}
          <SelectTranslateLanguage />
          <ArrowRightAlt />
          <SelectContentLanguage />
        </div>
        <div className={classNames(classes.totalsHeader)}>
          <div>
            <strong>{translations.data ? translations.data.length : ""}</strong>{" "}
            {t("text.FRAGMENTS").toLowerCase()}
          </div>
        </div>
        <div className={classNames(classes.searchHeader)}>
          <Grid className={classNames(classes.searchHeader)}>
            <Grid item>
              <Search />
            </Grid>
            <Grid item>
              <Input
                className={classNames(classes.input)}
                placeholder={`${t("labels.SEARCH")}...`}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  render() {
    const { t, classes, channel, translations, ...other } = this.props;

    if (!translations || translations.loading) {
      return <CircularProgressCenter />;
    }

    return (
      <div className={classes.layoutFill}>
        <Helmet>
          <title>
            {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -{" "}
            {channel.data ? channel.data.name : ""} -{t("titles.TRANSLATE")}
          </title>
        </Helmet>

        {!translations.loading && this.renderTopHeader()}

        <div style={{ flex: "1 1 auto" }}>
          <CustomTable
            items={translations.data}
            columns={this.columns}
            onChange={this.onTableChange}
            onRowClick={this.onRowClick}
            {...other}
          />
        </div>
      </div>
    );
  }
}

TranslationOverview.propTypes = {
  loadTranslations: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  computedMatch: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  channel: PropTypes.shape({}).isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  channel: state.channel,
  translations: state.fragment.translatable,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadTranslations: () => dispatch(loadTranslatableFragments()),
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TranslationOverview);
