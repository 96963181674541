import { useMemo, useState, useEffect, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { findById } from "features/Asset/Asset.actions";
import {
  archiveFragmentFile,
  deleteFragmentFile,
  unArchiveFragmentFile,
} from "../services";
import { loadFragment } from "../reducer";

// Icons
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import MovieCreationOutlinedIcon from "@material-ui/icons/MovieCreationOutlined";
import MovieFilterIcon from "@material-ui/icons/MovieFilter";
import CropIcon from "@material-ui/icons/Crop";
import ForwardIcon from "@material-ui/icons/Forward";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import DownloadIcon from "@material-ui/icons/GetApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";

// Components
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "components/Form/Select";
import CircularProgressCenter from "components/CircularProgressCenter";
import Button from "components/Button";
import Checkbox from "components/Form/Checkbox";
import Track from "./Track";
import Controls from "./Controls";

// Style
import StyledComponent from "./style";

const usePercentage = (duration, start, end) =>
  useMemo(() => {
    const startPercentage = (start / duration || 0) * 100;
    if (end === null) {
      return [startPercentage];
    }

    const endPercentage = (end / duration || 0) * 100;
    return [startPercentage, endPercentage];
  }, [duration, start, end]);

const VideoEditor = ({
  fragment,
  videoFiles,
  videoDurationMs,
  seekTimeInfo,
  active,
  currentTimeMs,
  onActiveChanged,
  updating,
  videoMetaLoading,
  error,
  onSave,
  onCancel,
  onChange,
  onChangeCommitted,
  onUseCurrentTime,
  onSeekTimeChanged,
  onSettingChange,
  onSourceChange,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [innerActive, setActive] = useState(null);
  const [innerCurrentTimeMs, setCurrentTimeMs] = useState(currentTimeMs);

  const fadeIn = fragment.fade_in || 0;
  const fadeOut = fragment.fade_out || videoDurationMs;
  const fadeInFadeOutPercentage = usePercentage(
    videoDurationMs,
    fadeIn,
    fadeOut
  );

  const fadeInOutValues = useMemo(() => [fadeIn, fadeOut], [fadeIn, fadeOut]);

  const currentTimePercentage = useMemo(
    () => (innerCurrentTimeMs / videoDurationMs) * 100,
    [videoDurationMs, innerCurrentTimeMs]
  );

  const introIn = fragment.intro_in || 0;
  const introOut = fragment.intro_out || 0;
  const introInOutPercentage = usePercentage(
    videoDurationMs,
    introIn,
    introOut
  );

  const creditIn = fragment.credits_in || 0;
  const creditInOutPercentage = usePercentage(videoDurationMs, creditIn, null);

  const snapshotIn = fragment.snapshot_time || 0;
  const snapshotInPercentage = usePercentage(videoDurationMs, snapshotIn, null);

  const defaultTimes = useMemo(
    () => ({
      fade_out: videoDurationMs,
    }),
    [videoDurationMs]
  );

  useEffect(() => {
    setCurrentTimeMs(currentTimeMs);
  }, [currentTimeMs, setCurrentTimeMs]);

  // Active changed, update current time.
  useEffect(() => {
    setActive(active);

    const currentTime =
      fragment[active] !== null ? fragment[active] : defaultTimes[active];
    if (currentTime === null) return;

    const changes = {
      value: currentTime,
      type: active,
    };

    onChange(changes);
    setCurrentTimeMs(currentTime);
  }, [active, fragment, onChange, defaultTimes]);

  const onTrackButtonClick = useCallback(
    (e) => {
      const { name } = e.currentTarget;
      const newActive = name === innerActive ? null : name;
      setActive(newActive);
      onActiveChanged(newActive);

      if (newActive) {
        onChange({
          value: fragment[name],
          type: name,
        });
      }
    },
    [fragment, innerActive, onActiveChanged, onChange]
  );

  const onSettingsCheckBoxChange = (event) => {
    const { name, checked } = event.currentTarget;
    onSettingChange(name, checked);
  };

  const onVideoSourceChange = (event) => {
    const { value } = event.target;
    const videoFile = videoFiles.find((file) => file.id === value);
    onSourceChange(videoFile);
  };

  const deleteFileAction = async () => {
    await deleteFragmentFile(fragment.file.id);
    dispatch(findById(fragment.asset.id));
    dispatch(
      loadFragment({
        fragmentId: fragment.id,
        showLoader: false,
      })
    );
  };

  const archiveFileAction = async () => {
    await archiveFragmentFile(fragment.file.id);
    dispatch(findById(fragment.asset.id));
    dispatch(
      loadFragment({
        fragmentId: fragment.id,
        showLoader: false,
      })
    );
  };

  const unArchiveFileAction = async () => {
    await unArchiveFragmentFile(fragment.file.id);
    dispatch(findById(fragment.asset.id));
    dispatch(
      loadFragment({
        fragmentId: fragment.id,
        showLoader: false,
      })
    );
  };

  return (
    <StyledComponent>
      {videoMetaLoading && !error && <CircularProgressCenter />}
      <div className="settings">
        <div className="video-selector">
          <Select
            name="video"
            value={fragment?.file?.id}
            onChange={onVideoSourceChange}
          >
            <MenuItem value="" disabled>
              <em>{t("labels.NO_VIDEO_SOURCE_SELECTED")}</em>
            </MenuItem>
            {videoFiles?.map(({ id, name, archived }) => (
              <MenuItem key={id} value={id}>
                {archived ? <s>{name}</s> : name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Checkbox
          name="drm"
          color="primary"
          label={
            fragment?.drm ? t("labels.DRM_ENABLED") : t("labels.DRM_DISABLED")
          }
          checked={Boolean(fragment?.drm)}
          onChange={onSettingsCheckBoxChange}
          icon={<VerifiedUserOutlinedIcon />}
          iconChecked={<VerifiedUserIcon />}
        />
        <Checkbox
          name="trailer"
          color="primary"
          label={fragment?.trailer ? t("labels.TRAILER") : t("labels.VIDEO")}
          checked={Boolean(fragment?.trailer)}
          onChange={onSettingsCheckBoxChange}
          icon={<MovieCreationOutlinedIcon />}
          iconChecked={<MovieFilterIcon />}
        />
        <Checkbox
          name="downloadable"
          color="primary"
          label={
            fragment?.downloadable
              ? t("labels.OFFLINE_AVAILABLE")
              : t("labels.ONLY_ONLINE")
          }
          checked={Boolean(fragment?.downloadable)}
          onChange={onSettingsCheckBoxChange}
          icon={<DownloadIcon />}
          iconChecked={<DownloadIcon />}
        />
      </div>
      {error && <Alert severity="error">{t("error.VIDEO_LOAD_ERROR")}</Alert>}
      {!error && (
        <div>
          <Track
            id="fade"
            name={["fade_in", "fade_out"]}
            title={[t("labels.FADE_IN"), t("labels.FADE_OUT")]}
            icon={[<CropIcon key="fade" />, <CropIcon key="fade-out" />]}
            active={innerActive}
            currentTimePercentage={currentTimePercentage}
            duration={videoDurationMs}
            realValues={fadeInOutValues}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            onUseCurrentTime={onUseCurrentTime}
            onTrackButtonClick={onTrackButtonClick}
            value={fadeInFadeOutPercentage}
          />
          <Track
            id="intro"
            name={["intro_in", "intro_out"]}
            title={[t("labels.INTRO_IN"), t("labels.INTRO_OUT")]}
            icon={[
              <ForwardIcon key="intro" />,
              <ForwardIcon key="intro-out" />,
            ]}
            onTrackButtonClick={onTrackButtonClick}
            active={innerActive}
            currentTimePercentage={currentTimePercentage}
            duration={videoDurationMs}
            onUseCurrentTime={onUseCurrentTime}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            realValues={[introIn, introOut]}
            value={introInOutPercentage}
          />
          <Track
            id="credit"
            name={["credits_in"]}
            title={[t("labels.CREDITS_IN")]}
            icon={[<RemoveFromQueueIcon key="credit" />]}
            onTrackButtonClick={onTrackButtonClick}
            active={innerActive}
            currentTimePercentage={currentTimePercentage}
            duration={videoDurationMs}
            onUseCurrentTime={onUseCurrentTime}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            realValues={[creditIn]}
            value={creditInOutPercentage}
          />
          <Track
            id="snapshot"
            name={["snapshot_time"]}
            title={[t("labels.SNAPSHOT_TIME")]}
            icon={[<CameraAltIcon key="snapshot" />]}
            onTrackButtonClick={onTrackButtonClick}
            active={innerActive}
            currentTimePercentage={currentTimePercentage}
            duration={videoDurationMs}
            onUseCurrentTime={onUseCurrentTime}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            realValues={[snapshotIn]}
            value={snapshotInPercentage}
          />
          <Controls
            seekTimeInfo={seekTimeInfo}
            onSeekTimeChanged={onSeekTimeChanged}
            onChangeCommitted={onChangeCommitted}
            currentTimeMs={innerCurrentTimeMs}
            duration={videoDurationMs}
            active={innerActive}
          />
        </div>
      )}
      <div className="action-buttons">
        <Grid container direction="row" alignItems="center">
          <Grid item xs></Grid>
          <Grid item className="actions">
            <Button
              className="action-button"
              trackName="VideoEditor Cancel"
              trackDetails={{
                fragmentName: fragment?.name,
                fragmentId: fragment?.id,
              }}
              color="primary"
              variant="outlined"
              onClick={onCancel}
            >
              {t("buttons.CANCEL")}
            </Button>
            <Button
              className="action-button"
              color="primary"
              trackName="VideoEditor Save"
              trackDetails={{
                fragmentName: fragment?.name,
                fragmentId: fragment?.id,
              }}
              loading={updating}
              variant="contained"
              onClick={onSave}
            >
              {t("buttons.SAVE")}
            </Button>
          </Grid>
          <Grid item xs className="action-right-button">
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item>
                <div className="buttons">
                  {fragment?.file?.archived ? (
                    <Button
                      color="default"
                      aria-label={t("buttons.UNARCHIVE")}
                      className="archiveButton"
                      startIcon={<UnarchiveIcon />}
                      onClick={unArchiveFileAction}
                    >
                      {t("buttons.UNARCHIVE")}
                    </Button>
                  ) : (
                    <Button
                      dialog
                      dialogTitle={t("buttons.ARCHIVE")}
                      dialogDescription={t(
                        "text.ARCHIVE_FRAGMENT_CONFIRMATION",
                        {
                          fragment: fragment.file.name,
                        }
                      )}
                      dialogButtonText={t("buttons.ARCHIVE")}
                      dialogButtonColor="secondary"
                      dialogCallback={archiveFileAction}
                      color="default"
                      aria-label={t("buttons.ARCHIVE")}
                      className="archiveButton"
                      startIcon={<ArchiveIcon />}
                    >
                      {t("buttons.ARCHIVE")}
                    </Button>
                  )}
                  <Button
                    dialog
                    dialogTitle={t("buttons.DELETE_PERMANENTLY")}
                    dialogDescription={t("text.CONFIRM_DELETE_FILE")}
                    dialogButtonText={t("buttons.DELETE")}
                    dialogButtonColor="secondary"
                    dialogCallback={deleteFileAction}
                    color="secondary"
                    aria-label={t("buttons.DELETE")}
                    trackName="fragment.file.delete"
                    startIcon={<DeleteForeverIcon />}
                  >
                    {t("buttons.DELETE")}
                  </Button>
                </div>
                <Button
                  className="remoteFile"
                  color="default"
                  onClick={() =>
                    navigator.clipboard.writeText(fragment.file.remote_file)
                  }
                  startIcon={<FileCopyIcon />}
                >
                  {fragment.file.remote_file}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </StyledComponent>
  );
};

VideoEditor.defaultProps = {
  fragment: null,
  seekTimeInfo: {
    key: "1/25",
    value: 1 / 25,
  },
  active: null,
  currentTimeMs: 0,
  updating: false,
  videoMetaLoading: false,
  videoDurationMs: 0,
  error: null,

  onActiveChanged: () => {},
  onSave: () => {},
  onCancel: () => {},
  onChange: () => {},
  onChangeCommitted: () => {},
  onUseCurrentTime: () => {},
  onSeekTimeChanged: () => {},
  onSettingChange: () => {},
  onSourceChange: () => {},
};

VideoEditor.propTypes = {
  fragment: PropTypes.shape({}),
  seekTimeInfo: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.number,
  }),
  videoDurationMs: PropTypes.number,
  active: PropTypes.string,
  currentTimeMs: PropTypes.number,
  updating: PropTypes.bool,
  videoMetaLoading: PropTypes.bool,
  onActiveChanged: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  onUseCurrentTime: PropTypes.func,
  onSeekTimeChanged: PropTypes.func,
  onSettingChange: PropTypes.func,
  onSourceChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};

export default memo(VideoEditor);
