export const DEFAULT_DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm";

export const INVITATION_TYPE = {
  TRANSLATE: "subtitle_translate",
  PROOFREAD: "subtitle_proofread",
};

export const REVISION_STATUS = {
  active: "active",
  inactive: "inactive",
  pending: "pending",
  draft: "draft",
  approved: "approved",
  rejected: "rejected",
};
