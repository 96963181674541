import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";

export default styled(Dialog)`
  & .MuiDialog-paper {
    background: ${({ theme }) => theme.palette.grey[300]};
  }

  & .MuiTypography-h3 {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & .MuiTypography-caption,
  & .MuiFormLabel-root {
    font-weight: bold;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.palette.grey[800]};
  }

  & .MuiTypography-body1 {
    color: ${({ theme }) => theme.palette.grey[700]};
    font-size: 0.75rem;
    margin: 8px 0 4px 0;
  }

  & section {
    margin-bottom: 24px;
  }

  & .MuiFormGroup-root {
    margin-bottom: 16px;
  }

  & .icon {
    transform: scale(0.75);
  }

  & .icon--small {
    transform: scale(0.5);
  }

  & .MuiButton-root {
    padding: 0 12px;
  }

  & .MuiDialogActions-root .MuiButton-root {
    padding: 5px 12px;
  }

  & .MuiDialogActions-root .MuiButton-containedPrimary {
    padding: 0 12px;
  }

  & .xlsx .MuiButton-root {
    background-color: #1d6f42;
  }

  & .MuiButtonGroup-root {
    margin-top: 12px;
    margin-bottom: 16px;
  }

  & .xlsx {
    & .MuiLinearProgress-colorPrimary {
      background-color: #1d6f42;
    }

    & .MuiLinearProgress-barColorPrimary {
      background-color: #1f9655;
    }
  }
`;
