const style = {
  container: {
    padding: "10px 0 0 10px",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
};

export default style;
