import { takeLatest, all, call } from "redux-saga/effects";
import * as Sentry from "@sentry/browser";

// Types
import * as UserTypes from "features/Auth/User.types";
import * as ChannelTypes from "features/Channel/Channel.types";

function* setUser({ user }) {
  if (!user) return;
  const { id, email, name } = user;
  yield call(Sentry.setUser, { id, email, name });
}

function* setChannel({ channelId, channels }) {
  const channelName = channels?.find((channel) => channel.id === channelId)
    ?.name;
  if (!channelName) return;

  yield call(Sentry.setTag, "channel", channelName);
}

function* sagas() {
  return yield all([
    takeLatest(UserTypes.SET_USER, setUser),
    takeLatest(ChannelTypes.SET_SELECTED, setChannel),
  ]);
}

export default sagas;
