import styled from "styled-components";
import Menu from "@material-ui/core/Menu";

export default styled.div`
  .channel-box {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 24px;
    margin-left: -24px;
    border-radius: 0;
  }

  .logo-big {
    height: ${(props) => props.theme.spacing(3)}px;
    margin-right: ${(props) => props.theme.spacing()}px;
    // filter: brightness(0) invert(1);
  }
`;

export const StyledMenu = styled(Menu)`
  .link {
    color: "black";
    text-decoration: none;
  }

  .arrow-back-icon {
    width: ${(props) => props.theme.spacing(3)}px;
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }

  .logo-small {
    height: ${(props) => props.theme.spacing(3)}px;
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }

  .link {
    color: black;
    text-decoration: none;
  }
`;
