import { useEffect } from "react";
import { useAnalytics } from "features/Analytics";

const usePageAnalytics = ({ name, code, fragmentName, nativeName, email }) => {
  const { setIdentity, track, identify } = useAnalytics();

  useEffect(() => {
    track(name, { fragmentName, code, nativeName });
  }, [name, track, code, fragmentName, nativeName]);

  useEffect(() => {
    if (!email) return;
    identify(email);
    setIdentity({ email, name: email, type: "external" });
  }, [email, setIdentity, identify]);
};

export default usePageAnalytics;
