import styled from "styled-components";

const StyledComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, status }) =>
    theme.palette.status[status].main}!important;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  position: relative;
  margin-right: 4px;
  cursor: pointer;
  line-height: 0.9rem;
  color: ${({ theme, status }) =>
    theme.palette.status[status].contrastText}!important;
  font-weight: bold;
`;

export default StyledComponent;
