import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import pretty from "prettysize";
import _ from "lodash";

import styles from "./style";

const getResolution = (streams) => {
  let resolution = null;

  if (streams && _.isArray(streams)) {
    const videoStream = _.find(streams, { codec_type: "video" });

    if (videoStream) {
      resolution = `${videoStream.coded_width} x ${videoStream.coded_height}`;

      if (resolution === "0 x 0" && videoStream.width && videoStream.height) {
        resolution = `${videoStream.width} x ${videoStream.height}`;
      }
    }
  }

  return resolution;
};

const getJobStatus = (job, t) => {
  if (job.status === "in_progress" && job.progress) {
    return `${t(`status.jobs.${job.status.toUpperCase()}`)}: ${job.progress}`;
  }

  return t(`status.jobs.${job.status.toUpperCase()}`);
};

const renderDisplay = (value, type, t) => {
  switch (type) {
    case "file_size":
      return pretty(value);
    case "duration":
      return `${Math.round(value / 60)} min`;
    case "resolution":
      return getResolution(value);
    case "job_status":
      return getJobStatus(value, t);
    default:
      return value;
  }
};

const Label = ({ value, type, color = null, t, style, classes, children }) => {
  const renderValue = renderDisplay(value, type, t);
  let styleLabel;

  switch (color) {
    case "blue":
      styleLabel = classes.labelBlue;
      break;
    case "error":
      styleLabel = classes.labelError;
      break;
    case "postponed":
      styleLabel = classes.labelPostponed;
      break;
    case "ready":
      styleLabel = classes.labelReady;
      break;
    case "in_progress":
      styleLabel = classes.labelInProgress;
      break;
    case "success":
      styleLabel = classes.labelSuccess;
      break;
    default:
      styleLabel = classes.labelDefault;
  }

  if (renderValue) {
    return (
      <div style={style} className={`${styleLabel} ${classes.label}`}>
        {renderValue}
        {children}
      </div>
    );
  }

  return null;
};

Label.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  children: PropTypes.node,
  type: PropTypes.string,
  color: PropTypes.string,
  t: PropTypes.func,
};

export default compose(withStyles(styles), withTranslation())(Label);
