import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

/* Components */
import TextField from "./TextField";

const TextFieldWithControl = (props) => {
  const { control, onChange, outputFormatter } = props;
  return (
    <Controller
      as={TextField}
      control={control}
      {...props}
      onChange={([event]) => {
        const { value } = event.target;
        const output = outputFormatter(value);
        onChange(output);
        return output;
      }}
    />
  );
};

TextFieldWithControl.displayName = "TextFieldWithControl";

TextFieldWithControl.defaultProps = {
  onChange: () => null,
  outputFormatter: (value) => value,
};

TextFieldWithControl.propTypes = {
  control: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
  outputFormatter: PropTypes.func,
};

export default TextFieldWithControl;
