import { forwardRef } from "react";
import { TextField } from "@material-ui/core";

const SeoRichTextField = forwardRef(({ errors, field, ...props }, ref) => {
  return (
    <TextField
      {...props}
      label={field.name}
      variant="outlined"
      inputRef={ref}
      error={!!errors}
      multiline
      minRows={3}
    />
  );
});

export default SeoRichTextField;
