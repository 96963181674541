import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export default styled(Grid)`
  position: relative;
  height: 100%;
  background: ${({ theme }) => theme.palette.grey[200]};
  width: 100%;
  border-right: 2px solid ${({ theme }) => theme.palette.grey[300]};
  flex-wrap: nowrap;
  overflow: hidden;
  flex-flow: column !important;

  .filterGrid {
    padding: 10px;
  }

  .filterOptions {
    display: flex;
    flex-flow: column;
  }
`;
