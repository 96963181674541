import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { transformAll } from "@demvsystems/yup-ast";

const typeMapping = {
  textarea: "string",
  date: "mixed",
};

const resolveObject = ({ name, t, nullable, validationType, constrains }) => {
  const fieldConstrains = constrains.map(({ type, params }) => [type, params]);

  const requiredMessage = t("error.FIELD_REQUIRED", { name });
  const valueShape = {
    value: [[`yup.${validationType}`], ...fieldConstrains],
  };

  const schema = [
    ["yup.object"],
    ["yup.shape", valueShape, requiredMessage],
    nullable ? ["yup.nullable"] : ["yup.required"],
    ["yup.typeError", requiredMessage],
  ];

  return schema;
};

const resolveArray = ({ name, t, nullable, validationType, constrains }) => {
  const fieldConstrains = constrains.map(({ type, params }) => [type, params]);
  const subSchema = [
    ["yup.object"],
    [
      "yup.shape",
      {
        value: [[`yup.${validationType}`], ...fieldConstrains],
      },
    ],
  ];

  const schema = [
    ["yup.array"],
    ["yup.of", subSchema],
    nullable
      ? ["yup.nullable"]
      : ["yup.min", 1, t("error.MIN_ARRAY", { name })],
    ["yup.typeError", t("error.FIELD_REQUIRED", { name })],
  ];
  return schema;
};

const defaultRootObjectResolver = ({
  t,
  field,
  validationType,
  constrains,
}) => {
  const { multiple, required, name, type } = field;
  const nullable = !required;
  const resolveProperties = {
    name,
    t,
    nullable,
    validationType,
    constrains,
    type,
  };
  return multiple
    ? resolveArray(resolveProperties)
    : resolveObject(resolveProperties);
};

const defaultTypeResolver = (field) => {
  const { type } = field;
  const validationType = typeMapping[type] || "string";
  return validationType;
};

const defaultConstrainResolver = ({ field, t }) => {
  const { required, name } = field;
  const nullable = !required;

  const constrains = [];

  if (required) {
    constrains.push({
      type: "yup.required",
      params: t("error.FIELD_REQUIRED", { name }),
    });
  }
  if (nullable) {
    constrains.push({
      type: "yup.nullable",
      params: t("error.FIELD_REQUIRED", { name }),
    });
  }

  return constrains;
};

export const useDataToSchemaConverter = ({
  fields,
  rootObjectResolver = defaultRootObjectResolver,
  typeResolver = defaultTypeResolver,
  constrainsResolver = defaultConstrainResolver,
}) => {
  const { t } = useTranslation();
  return useMemo(() => {
    if (!fields) return [];

    const properties = {};
    fields.forEach((field) => {
      const { id } = field;

      const validationType = typeResolver(field);
      const constrains = constrainsResolver({ field, t });
      properties[id] = rootObjectResolver({
        t,
        field,
        validationType,
        constrains,
      });
    });

    const schema = [["yup.object"], ["yup.shape", properties]];

    const yupSchema = transformAll(schema);
    return yupSchema;
  }, [fields, rootObjectResolver, typeResolver, constrainsResolver, t]);
};
