import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { saveAs } from "file-saver";

import StyledComponent from "./style";

const DownloadLink = ({
  name,
  href,
  className,
  children,
  trackName,
  trackDetails,
  onClick,
  disabled,
}) => {
  // State
  const [downloading, setSetDownloading] = useState(false);
  const [progressState, setProgressState] = useState({
    loaded: 0,
    total: 0,
    progress: 0,
  });

  const dispatchTrackingClick = useCallback(() => {
    if (!trackName) return;
    const trackingEvent = new CustomEvent("download-link-click", {
      detail: {
        trackName,
        trackDetails,
      },
    });
    dispatchEvent(trackingEvent);
  }, [trackName, trackDetails]);

  const downloadFile = useCallback(() => {
    setSetDownloading(true);

    axios({
      url: href,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: (event) => {
        const { loaded, total } = event;
        setProgressState({
          loaded,
          total,
          progress: loaded / total,
        });
      },
    })
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(blob, name);
      })
      .finally(() => {
        setSetDownloading(false);
      });
  }, [href, name]);

  const onClickHandler = useCallback(
    (event) => {
      event.preventDefault();
      downloadFile(event);

      onClick(event);
      dispatchTrackingClick();
    },
    [onClick, dispatchTrackingClick, downloadFile]
  );

  return (
    <StyledComponent
      className={className}
      target="_blank"
      href={href}
      name={name}
      download
      onClick={onClickHandler}
      disabled={disabled}
    >
      {typeof children === "function"
        ? children({ downloading, ...progressState })
        : children}
    </StyledComponent>
  );
};

DownloadLink.defaultProps = {
  name: null,
  href: null,
  disabled: false,
  trackName: null,
  trackDetails: null,
  onClick: () => {},
  children: null,
};

DownloadLink.propTypes = {
  name: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  trackName: PropTypes.string,
  trackDetails: PropTypes.shape({}),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default DownloadLink;
