import { cloneElement } from "react";
import { compose } from "redux";
import { PropTypes } from "prop-types";
import { withTranslation } from "react-i18next";
import { HotKeys } from "react-hotkeys";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { Component } from "..";

import * as Constants from "../../features/Common/constants/hotkeys.constants";

import styles from "./style";

class AllInDialog extends Component {
  constructor(props) {
    super(props);

    this.initState({
      changed: false,
    });
  }

  onDialogSave = (data) => {
    // Undefined
    if (!data) return;

    const keys = Object.keys(data);
    const { model, onSave, onClose } = this.props;

    if (keys.length <= 0) {
      onClose();

      return;
    }

    const modelClone = { ...model };

    keys.forEach((key) => {
      if (typeof model[key] !== "undefined") {
        modelClone[key] = data[key];
      }
    });

    onSave(modelClone);
  };

  updateData = (event) => {
    const { data } = this.state;

    const { name } = event.target;
    const { value } = event.target;

    this.setState({ changed: true, data: { ...data, [name]: value } });
  };

  render() {
    const {
      t,
      title,
      open,
      model,
      onClose,
      onSave,
      classes,
      children,
    } = this.props;
    const { data, changed } = this.state;

    const handlers = {
      SAVE: (e) => {
        e.preventDefault();
        this.onDialogSave(data);
      },
    };

    return (
      <HotKeys keyMap={Constants.KeyMap.General} handlers={handlers}>
        <Dialog onClose={onClose} open={open}>
          <DialogTitle disableTypography className={classes.header}>
            <Typography variant="h6">{title}</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {cloneElement(children, {
              model,
              onChangeCallback: this.updateData,
            })}
          </DialogContent>
          <DialogActions>
            {onSave && (
              <Button
                variant="contained"
                color="primary"
                disabled={!changed}
                onClick={() => this.onDialogSave(data)}
              >
                {t("buttons.SAVE")}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </HotKeys>
    );
  }
}

AllInDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default compose(withTranslation(), withStyles(styles))(AllInDialog);
