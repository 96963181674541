import { takeLatest, all, call, put } from "redux-saga/effects";
import * as services from "../services";

import { actions } from "./reducer";

export function* loadLicensesSaga() {
  try {
    const result = yield call(services.getLicenses);
    yield put(actions.loadLicensesSuccess(result.data));
  } catch (error) {
    yield put(actions.loadLicensesFailed(error));
  }
}

function* sagas() {
  return yield all([takeLatest(actions.loadLicenses.type, loadLicensesSaga)]);
}

export default sagas;
