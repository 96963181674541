import { useRef, useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";

// Icons
import GetAppIcon from "@material-ui/icons/GetApp";

// Utils
import Utils from "features/Common/utils";

// Components
import { Typography } from "@material-ui/core";
import NationFlag from "components/NationFlag";
import DropZone from "components/DropZone";
import DownloadLink from "components/DownloadLink";

// Style
import { StyledUpload, StyledPreview } from "./SubtitleUpload.style";

const Preview = (props) => {
  const { file, name, errors } = props;
  if (!file) return null;

  return (
    <StyledPreview className={errors ? "error-state" : ""}>
      <Typography variant="h6">{name || file.path}</Typography>
    </StyledPreview>
  );
};

const Errors = ({ errors }) => {
  if (!errors) {
    return null;
  }

  const fileErrors = !Array.isArray(errors) ? Object.values(errors) : errors;

  return (
    <ul>
      {fileErrors?.map((value, index) => (
        <li key={index}>{value}</li>
      ))}
    </ul>
  );
};

// 50MB
const MAX_SIZE_IN_BYTES = 50000000;
const SubtitleUpload = ({
  id,
  typeId,
  fileId,
  language,
  errors,
  isPlaceHolder,
  networkState,
  accept,
  maxSize,
  file,
  name,
  onDropAccepted,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const dropZoneRef = useRef();

  const path = file?.paths[0]?.path;
  const url = Utils.Fragments.getAssetStoragePath(path);
  const previewFiles = path ? [{ preview: url }] : null;
  const locale = language?.code;

  useEffect(() => {
    if (!errors) {
      setError(null);
    } else {
      setError(<Errors errors={errors} />);
    }
  }, [errors]);

  const onDropAcceptedHandle = (acceptedFiles) => {
    const isNew = isPlaceHolder;
    onDropAccepted({
      id,
      typeId,
      fileId,
      acceptedFiles,
      isNew,
      type: "subtitle",
      locale,
    });
  };
  const onDeleteHandle = () => {
    onDelete({
      id,
      typeId,
      fileId,
      type: "subtitle",
      locale,
    });
  };

  return (
    <StyledUpload>
      <DropZone
        ref={dropZoneRef}
        className="drop-zone"
        defaultFiles={previewFiles}
        style={{ minWidth: 300 }}
        height={32}
        t={t}
        error={error}
        accept={accept}
        placeholderText={
          <>
            <NationFlag countryCode={language?.code || ""} />{" "}
            {t("placeholder.UPLOAD_SUBTITLE_REVISED")}
          </>
        }
        state={networkState}
        PreviewComponent={(props) => (
          <Preview
            {...props}
            language={language}
            fragmentId={id}
            name={name}
            errors={errors}
            locale={language?.code}
          />
        )}
        maxSize={maxSize || MAX_SIZE_IN_BYTES}
        onDropAccepted={onDropAcceptedHandle}
        onDelete={onDeleteHandle}
      />
      {path && url && (
        <DownloadLink
          className="download-link"
          href={url}
          name={name}
          trackName="Download subtitle file"
          trackDetails={{ url, fragmentId: id }}
        >
          {name}
          <GetAppIcon />
        </DownloadLink>
      )}
    </StyledUpload>
  );
};

export default memo(SubtitleUpload);
