import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as logActions from "./Log.actions";

import DialogHeader from "../../components/Dialog";

import styles from "./LogDialog.styles";

class LogDialog extends Component {
  componentDidUpdate(prevProps) {
    const { open, type, id, findLog, resetLog } = this.props;
    const { open: prevOpen } = prevProps;

    if (open && prevOpen !== open) {
      findLog({ content_id: id, type });
    }

    if (!open && prevOpen !== open) {
      resetLog();
    }
  }

  render() {
    const { t, classes, open, onClose, name, logs } = this.props;
    return (
      <Dialog
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={onClose}
        scroll="paper"
      >
        <DialogHeader
          title={name ? `${t("labels.LOG")}: ${name}` : t("labels.LOGS")}
          onClose={onClose}
        />

        <DialogContent className={classes.root}>
          {logs.loading && (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )}

          <List disablePadding>
            {logs.data.map((log) => (
              <ListItem divider className={classes.listItem} key={log.id}>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <strong>
                      {moment.unix(log.created_at).format("D MMMM YYYY")}
                    </strong>{" "}
                    {t("text.AT")}{" "}
                    <strong>
                      {moment.unix(log.created_at).format("HH:mm:ss")}
                    </strong>
                  </Grid>

                  <Grid item xs={6}>
                    <strong>
                      {
                        // eslint-disable-next-line no-underscore-dangle
                        log._action
                      }
                    </strong>{" "}
                    {t("text.BY")} <strong>{log.user.name}</strong>
                  </Grid>

                  <Grid item xs className={classes.ipAddress}>
                    {log.ip_address}
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    );
  }
}

LogDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  findLog: PropTypes.func.isRequired,
  resetLog: PropTypes.func.isRequired,
  logs: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  logs: state.log,
});

const mapDispatchToProps = (dispatch) => ({
  findLog: (searchParams) => dispatch(logActions.find(searchParams)),
  resetLog: () => dispatch(logActions.reset()),
});

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(LogDialog);
