import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgressCenter from "components/CircularProgressCenter";

import TwoFactorCode from "./TwoFactorCode";
import ContentPasswordConfirm from "./ContentPasswordConfirm";

import { confirmedPasswordStatus } from "../user.service";
import DisableTwoFactor from "./DisableTwoFactor";

const TwoFactorDialog = ({ onClose, open }) => {
  const { t } = useTranslation();
  const [passwordIsConfirm, setPasswordIsConfirm] = useState(null);

  const updateConfirmedPasswordStatusRequest = async () => {
    const {
      data: { confirmed },
    } = await confirmedPasswordStatus();

    setPasswordIsConfirm(confirmed);
  };

  const getOnclose = () => {
    if (passwordIsConfirm) {
      return onClose;
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (open) {
      updateConfirmedPasswordStatusRequest();
    }
  }, [open]);

  return (
    <DialogStyled
      onClose={getOnclose()}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle disableTypography className="dialogTitle">
        <Typography variant="h6">
          {" "}
          {t(
            open === "enable"
              ? "labels.ENABLE_TWO_FACTOR"
              : "labels.DISABLE_TWO_FACTOR"
          )}
        </Typography>
        {getOnclose() !== undefined && (
          <IconButton aria-label="close" onClick={getOnclose()}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      {passwordIsConfirm === null && (
        <MuiDialogContent dividers>
          <CircularProgressCenter type="inline" />
        </MuiDialogContent>
      )}
      {passwordIsConfirm === false && (
        <ContentPasswordConfirm setPasswordIsConfirm={setPasswordIsConfirm} />
      )}
      {passwordIsConfirm && open === "enable" && (
        <TwoFactorCode onClose={onClose} />
      )}
      {passwordIsConfirm && open === "disable" && (
        <DisableTwoFactor onClose={onClose} />
      )}
    </DialogStyled>
  );
};

const DialogStyled = styled(Dialog)`
  .dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export default TwoFactorDialog;
