import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import ResizePanel from "../../../components/ResizablePanel";

export const StyledResizePanel = styled(ResizePanel)``;

export default styled(Grid)`
  position: relative;
  height: 100%;
  background: ${({ theme }) => theme.palette.grey[200]};
  width: 100%;
  border-right: 2px solid ${({ theme }) => theme.palette.grey[300]};
  flex-wrap: nowrap;
  overflow: hidden;
  flex-flow: column !important;

  .series-bar {
    background: ${({ theme }) => theme.palette.grey[300]};
    border-radius: 4px;
    margin: 12px 12px 16px 12px;
  }

  .groups {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  & > header {
    background: lightgray;
    padding: ${({ theme }) => `${theme.spacing()}px ${theme.spacing(2)}px`};
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    box-sizing: border-box;

    & h6 {
      color: rgba(0, 0, 0, 0.9);
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .MuiListItem-root span {
    font-size: 13px;
  }

  & .content {
    max-width: 100%;
  }

  .MuiListItem-root {
    padding: 4px 16px;

    & strong {
      font-weight: bold;
      margin-right: 6px;
    }
  }

  .MuiListItemText-root {
    overflow: hidden;

    & span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
