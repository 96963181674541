import styled from "styled-components";

const StyledComponent = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 13px;
  margin-top: 4px;
  margin-left: 8px;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;

  & .placeholder {
    opacity: 0.75;
  }
`;

export default StyledComponent;
