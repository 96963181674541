import { takeLatest, all, put, call } from "redux-saga/effects";
import * as UserTypes from "../Auth/User.types";
import { findSuccess, findError } from "./country.reducers";
import { getCountryGroup } from "./country.service";

export function* loadCountryGroups() {
  const countryGroupsResponse = yield call(getCountryGroup);

  if (countryGroupsResponse.status !== 200) {
    yield put(findError());
  }

  yield put(findSuccess(countryGroupsResponse.data));

  return false;
}

function* sagas() {
  return yield all([takeLatest(UserTypes.SET_USER, loadCountryGroups)]);
}

export default sagas;
