import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Redux
import { actions } from "./reducer";

// Icons
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabsPanel from "components/Tabs/TabsPanel";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import CloseIcon from "@material-ui/icons/Close";

// Components
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CircularProgressCenter from "components/CircularProgressCenter";

// Hooks
import { useTabs } from "components/Tabs";

// Local Components
import Header from "./Header";
import Drawer from "./Drawer";
import Table from "./Table";
import InviteForm from "./InviteForm";
import LicenseForm from "./LicenseForm";

// Style
import StyledComponent, { DrawerStyled } from "./style";
import Suppliers, { SuppliersProvider } from "./Suppliers";

// Constants
export const TABS = {
  INFO: 0,
  INVITE: 1,
};

const Licenses = () => {
  const { t } = useTranslation();

  // State
  const [editOpen, setEditOpen] = useState(false);
  const [license, setLicense] = useState(null);
  const [tabIndex, onChangeTab] = useTabs({ onTabChange: null });

  // Redux
  const dispatch = useDispatch();
  const { ui, data } = useSelector((state) => state.licenses);

  // Redux State
  const { loading } = ui;

  useEffect(() => {
    dispatch(actions.loadLicenses());
  }, [dispatch]);

  const onRowClick = ({ rowData: license, index }) => {
    setEditOpen(true);
    setLicense(license);
  };

  if (loading) return <CircularProgressCenter />;
  return (
    <StyledComponent>
      <Header>
        <Header.Title>{t("titles.LICENSES")}</Header.Title>
        <Header.Content>{/* Place aside content over here */}</Header.Content>
      </Header>
      <Table items={data} onRowClick={onRowClick} />

      <Drawer open={editOpen} setOpen={setEditOpen}>
        <DrawerStyled>
          <Drawer.Header>
            <Typography variant="h6">{t("labels.LICENSE_EDIT")}</Typography>
            <Tooltip title={t("labels.CLOSE")}>
              <Drawer.CloseButton>
                <IconButton color="inherit" aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </Drawer.CloseButton>
            </Tooltip>
          </Drawer.Header>
          <Drawer.Content>
            <Tabs value={tabIndex} onChange={onChangeTab} aria-label="Tabs">
              <Tab label={t("labels.INFO")} icon={<InfoOutlinedIcon />} />
              <Tab
                label={t("labels.INVITE_SUPPLIER")}
                icon={<PeopleAltOutlinedIcon />}
              />
            </Tabs>
            <TabsPanel tabIndex={TABS.INFO} activeTabIndex={tabIndex}>
              <LicenseForm license={license} />
            </TabsPanel>
            <TabsPanel tabIndex={TABS.INVITE} activeTabIndex={tabIndex}>
              <SuppliersProvider>
                <InviteForm license={license} />
                <Suppliers license={license} />
              </SuppliersProvider>
            </TabsPanel>
          </Drawer.Content>
        </DrawerStyled>
      </Drawer>
    </StyledComponent>
  );
};

export default memo(Licenses);
