// TODO: use colors from server
export const FRAGMENT_STATUS_COLORS = {
  "afgerond: geen collectie": {
    main: "#9fdeaf",
    contrastText: "#0000008c",
  },
  "check mediamanager": {
    main: "#e8e78b",
    contrastText: "#0000008c",
  },
  "wordt ondertiteld": {
    main: "#bac4e8",
    contrastText: "#0000008c",
  },
  "klaar voor axinom": {
    main: "#b3f1ff",
    contrastText: "#0000008c",
  },
  "verzonden naar axinom": {
    main: "#9cffe4",
    contrastText: "#0000008c",
  },
  gemetadateerd: {
    main: "#ffd5cc",
    contrastText: "#0000008c",
  },
  afgerond: {
    main: "#b5dea1",
    contrastText: "#0000008c",
  },
  gearchiveerd: {
    main: "#b5dea1",
    contrastText: "#0000008c",
  },
};
