import PropTypes from "prop-types";
import classNames from "classnames";

import StyledComponent from "./style";

const CharacterCount = ({ length, maxLength, graceLength, className }) => {
  let shortDescriptionClass = "success";

  if (graceLength !== null && length > graceLength && length <= maxLength) {
    shortDescriptionClass = "warning";
  }
  if (length > maxLength) {
    shortDescriptionClass = "error";
  }

  return (
    <StyledComponent className={classNames([className, shortDescriptionClass])}>
      {length} /{maxLength}
    </StyledComponent>
  );
};

CharacterCount.defaultProps = {
  length: 0,
  graceLength: null,
};

CharacterCount.propTypes = {
  length: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  graceLength: PropTypes.number,
  className: PropTypes.string,
};

export default CharacterCount;
