const style = (theme) => ({
  header: {
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default style;
