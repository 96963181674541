import styled from "styled-components";

const StyledComponent = styled.div`
  background: white;

  .video-container {
    height: 300px;
    max-height: 300px;
    margin-top: 18px;

    > .MuiGrid-item {
      height: 100%;
      max-height: 100%;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .plyr {
      height: 100%;
    }
  }

  .skip-button {
    position: absolute;
    right: 24px;
    bottom: 24px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 12px;
    color: white;
    font-size: 0.875rem;
    background: none;
    outline: 0;
    border: 1px solid white;
    transition: bottom ease-in 0.2s;
  }

  .action-buttons {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
`;

export default StyledComponent;
