import { compose } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Tooltip from "@material-ui/core/Tooltip";

import PersonIcon from "@material-ui/icons/Person";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import StorageIcon from "@material-ui/icons/Storage";

import { withTranslation } from "react-i18next";
import styles from "./List.styles";

import { Component } from "../../../components";
import UserListContainer from "../Users";
// eslint-disable-next-line import/no-named-default
import { default as UserViewContainer } from "../Users/View";
import RoleViewContainer from "../Roles/View";
import RoleListContainer from "../Roles";
import EncoderListContainer from "../Encoders";
import EncoderViewContainer from "../Encoders/View";

class ListContainer extends Component {
  constructor(props) {
    super(props);

    this.menu = [
      {
        name: "titles.USER",
        setting: "users",
        icon: PersonIcon,
        component: UserListContainer,
        detailComponent: UserViewContainer,
      },
      {
        name: "titles.ROLES",
        setting: "role",
        icon: SupervisedUserCircleIcon,
        component: RoleListContainer,
        detailComponent: RoleViewContainer,
      },
      {
        name: "titles.ENCODER",
        setting: "encoders",
        icon: StorageIcon,
        component: EncoderListContainer,
        detailComponent: EncoderViewContainer,
      },
      // null,
      // ['Configuratie', 'settings/config', 'settings'],
      // ['Formulieren', 'settings/form', 'library_books'],
      // ['Video presets', 'settings/preset', 'video_library'],
      // ['Encoders', 'settings/client', 'storage'],
      // ['Asset quality checks', 'settings/check', 'check'],
      // null,
      // ['Licenties', 'settings/license', 'assignment_ind'],
      // ['Contracten', 'settings/contract', 'assignment']
    ];
  }

  componentDidMount() {
    const { history } = this.props;

    const setting = this.getCurrentSetting();

    if (!setting) {
      history.replace(`/settings/${this.menu[0].setting}`);
    }
  }

  onItemClick(menuItem) {
    const { history } = this.props;
    const { setting } = this.getCurrentSetting() || {};

    if (menuItem.setting === setting) {
      return;
    }

    history.push(`/settings/${menuItem.setting}`);
  }

  getCurrentSetting() {
    const { match } = this.props;

    const setting = this.menu.find(
      (item) => item.setting === match.params.setting
    );

    return setting;
  }

  updateBreadcrumbs() {
    Component.appendBreadcrumb(
      { to: "/settings", name: "titles.APP_SETTINGS" },
      true
    );

    const setting = this.getCurrentSetting();

    if (setting) {
      Component.appendBreadcrumb({
        to: `/settings/${setting.setting}`,
        name: setting.name,
      });
    }
  }

  renderMenu(selectedSetting) {
    const { t, classes } = this.props;

    this.updateBreadcrumbs();

    return (
      <List className={classes.list}>
        {this.menu.map((menuItem) => (
          <ListItem
            button
            key={menuItem.setting}
            onClick={() => this.onItemClick(menuItem)}
            className={
              selectedSetting && selectedSetting.setting === menuItem.setting
                ? classes.settingSelected
                : ""
            }
          >
            <Tooltip title={t(menuItem.name)}>
              <menuItem.icon className={classes.icon} />
            </Tooltip>
          </ListItem>
        ))}
      </List>
    );
  }

  render() {
    const { t, classes, match } = this.props;

    const propsClone = { ...this.props };
    delete propsClone.classes;

    const setting = this.menu.find(
      (item) => item.setting === match.params.setting
    );

    return (
      <div className={classes.container}>
        <Helmet>
          <title>
            {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -{" "}
            {t("titles.APP_SETTINGS")}
            {setting ? ` - ${t(setting.name)}` : ""}
          </title>
        </Helmet>
        <Grid container>
          <Grid item className={classes.grid}>
            {this.renderMenu(setting)}
          </Grid>

          {setting && (
            <Grid
              item
              xs
              style={{
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {match.params.id ? (
                <setting.detailComponent {...propsClone} />
              ) : (
                <setting.component {...propsClone} />
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

ListContainer.propTypes = {
  t: PropTypes.func.isRequired,
  channel: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  channel: state.channel,
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(ListContainer);
