import { memo, forwardRef } from "react";

import PropTypes from "prop-types";

/* Components */
import TextField from "@material-ui/core/TextField";
import MUIAutoComplete from "@material-ui/lab/Autocomplete";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";

/* Styles */
import { styled } from "@material-ui/core/styles";

const FormControlStyled = styled(FormControl)(() => ({
  minWidth: 120,
  width: "100%",
}));

const AutoCompleteStyled = styled(MUIAutoComplete)(() => ({
  minWidth: "300px",
}));

const AutoComplete = forwardRef((props, ref) => {
  const {
    name,
    label,
    variant,
    options,
    multiple,
    onChange,
    error,
    className,
    size,
    value,
    freeSolo,
    autoSelect,
    loading,
    getOptionLabel,
    getOptionSelected,
    renderOption,
    disableCloseOnSelect,
    limitTags,
    placeholder,
    groupBy,
    fullWidth,
    open,
    onOpen,
  } = props;

  return (
    <FormControlStyled className={className} variant={variant} error={!!error}>
      <AutoCompleteStyled
        ref={ref}
        name={name}
        open={open}
        multiple={multiple}
        options={options}
        size={size}
        freeSolo={freeSolo}
        limitTags={limitTags}
        value={value || []}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        autoSelect={autoSelect}
        onChange={onChange}
        onOpen={onOpen}
        loading={loading}
        groupBy={groupBy}
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="none"
            label={label}
            variant={variant}
            error={!!error}
            fullWidth={fullWidth}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              id: "",
              "aria-autocomplete": "none",
            }}
          />
        )}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControlStyled>
  );
});

AutoComplete.displayName = "TextField";
AutoComplete.defaultProps = {
  fullWidth: true,
  groupBy: null,
  renderOption: null,
  disableCloseOnSelect: false,

  validate: () => {},
  onChange: () => {},
  getOptionLabel: (option) => {
    if (Array.isArray(option)) {
      return "";
    }
    return option?.title || option || "";
  },
  getOptionSelected: () => [],
  freeSolo: false,
  autoSelect: false,
  placeholder: null,
  loading: false,
  onOpen: () => {},
};

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  renderOption: PropTypes.func,
  getOptionLabel: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  getOptionSelected: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  size: PropTypes.oneOf(["small"]),
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  freeSolo: PropTypes.bool,
  open: PropTypes.bool,
  error: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  autoSelect: PropTypes.bool,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  groupBy: PropTypes.func,
  fullWidth: PropTypes.bool,
  limitTags: PropTypes.number,
  placeholder: PropTypes.string,
  onOpen: PropTypes.func,
};

export default memo(AutoComplete);
