import { memo } from "react";
import PropTypes from "prop-types";

// Icons
import MovieCreationOutlinedIcon from "@material-ui/icons/MovieCreationOutlined";
import MovieFilterOutlinedIcon from "@material-ui/icons/MovieFilterOutlined";

// Components
import TableCell from "@material-ui/core/TableCell";
import TextWithHighlight from "components/ElasticSearch/TextWithHighlight";

const NameCell = ({ name, language, highlight, identifier, trailer }) => {
  const nameInCurrentLanguage = name[language];

  return (
    <TableCell className="cell--name" component="td" colSpan={3} scope="row">
      <div className="tree-lines" />
      <div>
        {trailer ? (
          <MovieFilterOutlinedIcon className="icon--movie" fontSize="small" />
        ) : (
          <MovieCreationOutlinedIcon className="icon--movie" fontSize="small" />
        )}
        <TextWithHighlight
          highlight={highlight}
          fieldId={`fragments.name.${language}`}
          text={nameInCurrentLanguage || identifier}
        />
      </div>
    </TableCell>
  );
};

NameCell.propTypes = {
  language: PropTypes.string,
  name: PropTypes.shape({}),
  identifier: PropTypes.string,
  schedule: PropTypes.shape({}),
  highlight: PropTypes.shape({}),
  trailer: PropTypes.bool,
};

export default memo(NameCell);
