import { Ability, AbilityBuilder } from "@casl/ability";
import store from "../../../redux";

let currentPermissions;

/* eslint no-underscore-dangle: 0 */
const subjectName = (item) =>
  !item || typeof item === "string" ? item : item.__type;
const ability = new Ability([], { subjectName });

const defineAbilitiesByPermissions = (permissions) => {
  const { can, rules } = new AbilityBuilder();

  permissions.forEach((permission) => {
    const [subject, action] = permission.name.split(".");
    can(action, subject);
  });

  ability.update(rules);
};

export const updatePermissions = (permissions) => {
  if (currentPermissions !== permissions) {
    currentPermissions = permissions;
    defineAbilitiesByPermissions(currentPermissions);
  }
};

store.subscribe(() => {
  const userState = store.getState().user.user;
  if (!userState) return;

  const { permissions } = userState;
  updatePermissions(permissions);
});

export default ability;
