import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { SearchBox } from "searchkit";

// Icons
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";

// Components
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CircularProgressCenter from "components/CircularProgressCenter";
import Menu from "@material-ui/core/Menu";

// Elastic Search Components
import FilterButton from "components/ElasticSearch/FilterButton";

// Style
import StyledHeader from "./style";

const SearchHeader = ({
  searchQueryFields,
  prefixQueryFields,
  onToggleFilters,
  showFiltersPanel,
  queryBuilder,
  selected,
  filterCount,
  searching,
  onOpenCreateDialog,
  renderMenuItems,
}) => {
  const { t } = useTranslation();

  // State
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // Listeners
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <StyledHeader>
      <div className="column" />
      <div className="column">
        <div className="searchWrapper">
          <SearchBox
            queryFields={searchQueryFields}
            queryBuilder={queryBuilder}
            autofocus
            searchOnChange
            searchThrottleTime={400}
            prefixQueryFields={prefixQueryFields}
            placeholder={`${t("labels.SEARCH")}...`}
          />
          {searching && <CircularProgressCenter type="inline" size={24} />}
        </div>
      </div>
      <div className="column">
        <IconButton
          aria-haspopup="false"
          onClick={onOpenCreateDialog}
          color="primary"
        >
          <AddIcon />
        </IconButton>
        <FilterButton
          onClick={onToggleFilters}
          active={showFiltersPanel}
          filterCount={filterCount}
        />
      </div>
      {selected?.length > 0 && (
        <div className="selected-bar">
          <div>
            <Typography color="inherit" variant="subtitle1" component="div">
              {t("labels.ASSET_SELECTED", { count: selected.length })}
            </Typography>
          </div>
          <div>
            <IconButton color="primary" onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="asset-overview-more-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleClose}
            >
              {renderMenuItems({ closeMenu: handleClose, setMenuAnchorEl })}
            </Menu>
          </div>
        </div>
      )}
    </StyledHeader>
  );
};

SearchHeader.defaultProps = {
  renderMenuItems: () => null,
  queryBuilder: () => null,
  onOpenCreateDialog: () => null,
  onToggleFilters: () => null,
};

SearchHeader.propTypes = {
  filterCount: PropTypes.number,
  prefixQueryFields: PropTypes.arrayOf(PropTypes.string),
  searchQueryFields: PropTypes.arrayOf(PropTypes.string),
  searching: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.number),
  showFiltersPanel: PropTypes.bool,
  renderMenuItems: PropTypes.func,
  queryBuilder: PropTypes.func,
  onOpenCreateDialog: PropTypes.func,
  onToggleFilters: PropTypes.func,
};

export default SearchHeader;
