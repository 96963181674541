import { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "../../../components";

import styles from "./InboxDialogFooter.styles";

class InboxDialogFooter extends Component {
  render() {
    const {
      t,
      classes,
      handleSubmit,
      isValid,
      values,
      updating,
      deleteItem,
    } = this.props;

    return (
      <DialogActions className={classes.dialogActions}>
        <div />
        <Button
          disabled={!isValid || !values.asset}
          loading={updating}
          type="submit"
          color="primary"
          onClick={handleSubmit}
        >
          {t("buttons.ADD")}
        </Button>
        <IconButton aria-label="delete" color="secondary" onClick={deleteItem}>
          <DeleteIcon />
        </IconButton>
      </DialogActions>
    );
  }
}

InboxDialogFooter.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
  isValid: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  withTranslation()
)(InboxDialogFooter);
