import { sortBy } from "features/Common/utils/common.utils";
import {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
} from "react";

// Services
import * as channelService from "../../services";

const SupplierContext = createContext({
  suppliers: [],
});

export const useSuppliers = () => {
  const context = useContext(SupplierContext);
  if (!context) {
    throw new Error("Please use component within SupplierProvider");
  }

  return context;
};

const SuppliersProvider = ({ children }) => {
  // State
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadSuppliers = useCallback((licenseId, inBackground = false) => {
    if (!inBackground) {
      setLoading(true);
    }
    setError(null);

    return channelService
      .getSuppliers(licenseId)
      .then(({ data }) => {
        const suppliersSorted = data?.data
          ?.sort(sortBy("created_at"))
          .reverse();
        setSuppliers(suppliersSorted);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const resetSuppliers = useCallback(() => {
    setSuppliers([]);
  }, []);

  const deleteSupplier = useCallback((supplierId, id) => {
    return channelService.revokeSupplier(supplierId, id);
  }, []);

  const value = useMemo(() => {
    return {
      loading,
      error,
      suppliers,
      loadSuppliers,
      resetSuppliers,
      deleteSupplier,
    };
  }, [
    loading,
    error,
    suppliers,
    loadSuppliers,
    resetSuppliers,
    deleteSupplier,
  ]);

  return (
    <SupplierContext.Provider value={value}>
      {children}
    </SupplierContext.Provider>
  );
};

export default SuppliersProvider;
