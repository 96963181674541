import {
  responsiveFontSizes,
  createTheme as createMuiTheme,
} from "@material-ui/core/styles";
import palette from "./palette";
import TextField from "./style/allin/TextField";
import Card from "./style/allin/Card";
import Dialog from "./style/allin/Dialog";

export const muiTheme = createMuiTheme({
  palette,
  typography: {
    fontSize: 13,
    fontFamily: [
      "Lato",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    ...TextField,
    ...Card,
    ...Dialog,
  },
});
export const theme = responsiveFontSizes(muiTheme);
