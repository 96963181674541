import createReducer from "../../redux/createReducer";
import * as types from "./Log.types";
import * as reducers from "./Log.reducers";
import { INITIAL_STATE } from "./Log.state";

export const reducer = createReducer(INITIAL_STATE, {
  [types.FIND]: reducers.find,
  [types.FIND_SUCCESS]: reducers.findSuccess,
  [types.FIND_FAILURE]: reducers.findFailure,
  [types.RESET]: reducers.reset,
});
