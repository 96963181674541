import { put } from "redux-saga/effects";

import { getAuthenticationStateFromStorage } from "features/Auth/Auth.utils";
import * as userActions from "features/Auth/User.actions";

export function* startUp() {
  const isAuthenticated = getAuthenticationStateFromStorage();
  if (isAuthenticated) {
    return yield put(userActions.getUser());
  }

  return false;
}
