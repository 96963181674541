import createReducer from "../../redux/createReducer";
import * as types from "./Language.types";
import * as reducers from "./Language.reducers";
import { INITIAL_STATE } from "./Language.state";

export const reducer = createReducer(INITIAL_STATE, {
  [types.FIND]: reducers.find,
  [types.FIND_SUCCESS]: reducers.findSuccess,
  [types.FIND_FAILURE]: reducers.findFailure,
});
