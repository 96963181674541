import { AllInApi } from "../Common/services/api.service";

const api = AllInApi.getApi();

export const inviteSupplier = (licenseId, email, dueDate) =>
  api.post(`${AllInApi.getApiChannelPath()}/license/${licenseId}/storage`, {
    email,
    due_date: dueDate,
  });

export const getSuppliers = (licenseId) =>
  api.get(`${AllInApi.getApiChannelPath()}/license/${licenseId}/storage/`);

export const getLicenses = () =>
  api.get(`${AllInApi.getApiChannelPath()}/license`);

export const revokeSupplier = (licenseId, id) =>
  api.delete(
    `${AllInApi.getApiChannelPath()}/license/${licenseId}/storage/${id}`
  );
