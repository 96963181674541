import styled from "styled-components";

export default styled.div`
  border-radius: 4px;
  margin-bottom: 2px;

  & .MuiChip-sizeSmall {
    border-radius: 4px;
    background: transparent;
    border: 1px solid #e0e0e0;
    margin: 4px;
    padding: 8px;
    word-break: break-word;
  }

  & .name {
    margin-right: 4px;
    font-weight: bold;
  }

  & .value {
    margin-right: 4px;
  }
`;
