import styled from "styled-components";

const StyledComponent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.palette.grey[700]};
    padding: 16px;

    .MuiSvgIcon-fontSizeLarge {
      font-size: 3rem;
      margin-bottom: 8px;
    }

    .MuiFab-root {
      margin-top: 8px;
    }
  }
`;

export default StyledComponent;
