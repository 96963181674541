import { useMemo, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles/index";
import { useSnackbar } from "notistack";
import { useAnalytics } from "features/Analytics";

// Redux
import * as channelActions from "features/Channel/Channel.actions";

// Hooks
import { useAuthentication } from "features/Auth/AuthProvider";

// Components
import PageSuspense from "components/PageSuspense";
import CircularProgressCenter from "components/CircularProgressCenter";

// Utils
import ability from "features/Auth/AccessControl/ability";

import { permissions } from "./constants";
import { AllInApi } from "./services/api.service";

import styles from "./PrivateRoute.styles";

const useUpdateSelectedChannel = ({ computedMatch }) => {
  const dispatch = useDispatch();
  const { params } = computedMatch || {};
  const { channelId } = params || {};
  const { isAuthenticated } = useAuthentication();

  const selectedChannelId = useSelector((state) => state.channel.selected);
  const channels = useSelector((state) => state.user?.user?.channels);

  useEffect(() => {
    if (!isAuthenticated) return;
    const channelIdInt = Number(channelId);
    if (channelIdInt === selectedChannelId) return;

    AllInApi.setChannelId(channelIdInt);
    dispatch(channelActions.setSelected(channelIdInt, channels));
  }, [channelId, selectedChannelId, channels, dispatch, isAuthenticated]);
};

const PrivateRoute = ({
  component: Page,
  user,
  type: routeType,
  key,
  displayNav,
  path,
  exact,
  computedMatch,
  routes,
  ...renderProps
}) => {
  const { isAuthenticated } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useAnalytics();

  // Redux
  const channel = useSelector((state) => state.channel);
  const channels = useSelector((state) => state.user?.user?.channels);
  const userLocale = useSelector((state) => state.user?.user?.locale);
  const userDataLoaded = useSelector((state) => state.user.userDataLoaded);
  const userPermissions = useSelector((state) => state.user?.user?.permissions);
  const assetSelectedType = useSelector((state) => state.asset.selectedType);

  // Update selected channel
  useUpdateSelectedChannel({ computedMatch });

  useEffect(() => {
    AllInApi.setLocale(userLocale);
  }, [userLocale]);

  // Determine whether the user has the right permissions
  const hasPermission = useMemo(() => {
    const { params } = computedMatch || {};
    const { type } = params || {};
    const subject = routeType === "ASSET" ? type.toUpperCase() : routeType;

    if (routeType === "CHANNEL") return true;

    return ability.can(permissions.actions.VIEW, permissions.subjects[subject]);
    // eslint-disable-next-line
  }, [routeType, assetSelectedType, userPermissions, computedMatch]);

  // Wait until user data and channel data has been loaded.
  const dataLoaded = useMemo(() => userDataLoaded && channel && channels, [
    userDataLoaded,
    channel,
    channels,
  ]);

  const renderPermitted = (props) => {
    if (!userPermissions) return <CircularProgressCenter />;

    if (!hasPermission) {
      track("Cannot access page", { routeType });

      enqueueSnackbar(`You cannot access this page: ${routeType}`, {
        variant: "error",
      });
    }

    return hasPermission ? (
      <PageSuspense
        Page={Page}
        fallback={<CircularProgressCenter />}
        {...props}
        {...renderProps}
        classes={null}
        dataLoaded={dataLoaded}
        match={computedMatch}
        routes={routes}
      />
    ) : null;
  };

  return (
    <Route
      render={(props) =>
        isAuthenticated ? (
          renderPermitted(props)
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: props.location } }}
          />
        )
      }
      type={routeType}
      key={key}
      displayNav={displayNav}
      exact={exact}
      path={path}
    />
  );
};

PrivateRoute.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  computedMatch: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  type: PropTypes.string.isRequired,
  key: PropTypes.string,
  displayNav: PropTypes.bool.isRequired,
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
};

export default memo(withStyles(styles)(PrivateRoute));
