/* eslint-disable react/display-name */
import { memo } from "react";

// Components
import SchedulePopover from "./SchedulePopover";
import QualityChecksPopover from "./QualityChecksPopover";
import SubtitleDetailsPopover from "./SubtitleDetailsPopover";

const POPOVER_CONTENT = {
  schedule: ({ schedule, name, identifier, locale }) => (
    <SchedulePopover
      schedule={schedule}
      name={name}
      identifier={identifier}
      locale={locale}
    />
  ),
  status: ({ checks, locale }) => (
    <QualityChecksPopover checks={checks} locale={locale} />
  ),
  subtitle: ({ files }) => <SubtitleDetailsPopover files={files} />,
};

const DetailPopoverFactory = ({ type, ...props }) =>
  POPOVER_CONTENT[type] ? POPOVER_CONTENT[type](props) : null;

export default memo(DetailPopoverFactory);
