import { useCallback } from "react";
import PropTypes from "prop-types";

// Components
import CheckBox from "components/Form/Checkbox";

// Styles
import StyledComponent from "./style";

// Constants
const DEFAULT_COLORS = { main: "gray", contrastText: "white" };

const FilterRefinementOptionWithColor = ({
  label,
  trackName,
  trackDetails,
  onClick,
  active,
  count,
  colors,
  itemKey,
}) => {
  const { main, contrastText } = colors[itemKey] || DEFAULT_COLORS;

  const dispatchTrackingClick = useCallback(() => {
    if (!trackName) return;
    const trackingEvent = new CustomEvent("button-click", {
      detail: {
        trackName,
        trackDetails: { trackName, ...trackDetails },
      },
    });
    dispatchEvent(trackingEvent);
  }, [trackName, trackDetails]);

  const onClickHandler = useCallback(
    (event) => {
      onClick(event);
      dispatchTrackingClick();
    },
    [onClick, dispatchTrackingClick]
  );

  return (
    <StyledComponent
      color="default"
      labelBackgroundColor={main}
      labelTextColor={contrastText}
      variant="text"
      onClick={onClickHandler}
    >
      <div className="column-left">
        <CheckBox name={label} checked={active} size="small" />
        <span className="label">{label}</span>
      </div>
      {count}
    </StyledComponent>
  );
};

FilterRefinementOptionWithColor.defaultProps = {
  active: false,
  onClick: () => null,
};

FilterRefinementOptionWithColor.propTypes = {
  label: PropTypes.string.isRequired,
  trackName: PropTypes.string,
  trackDetails: PropTypes.shape({}),
  colors: PropTypes.shape({}),
  active: PropTypes.bool,
  onClick: PropTypes.func,
  itemKey: PropTypes.string,
};

export default FilterRefinementOptionWithColor;
