import SeoOverview from "./SeoOverview";
import SeoDetail from "./SeoDetail";

const routes = [
  {
    key: "SEO",
    path: "/channel/:channelId/seo",
    component: SeoOverview,
    exact: true,
    displayNav: true,
    auth: true,
  },
  {
    key: "SEO_ENTRY",
    path: "/channel/:channelId/seo/:seoEntryId",
    component: SeoDetail,
    exact: true,
    displayNav: false,
    auth: true,
  },
];

export default routes;
