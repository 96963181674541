import withStyles from "@material-ui/core/styles/withStyles";
import {
  Paper,
  List,
  ListSubheader,
  Grid,
  ListItemText,
  ListItemIcon,
  ListItem,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Create";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { Component, Fragment } from "../../../../components";
import { getEncoder, saveEncoder } from "../encoder.service";
import AllInDialog from "../../../../components/Dialog/AllInDialog";
import EnconderEditForm from "../Form/Form";
import styles from "./View.styles";

class ViewContainer extends Component {
  constructor(props) {
    super(props);

    this.initState({
      encoder: {},
      editDialogOpen: false,
    });
  }

  componentDidMount() {
    this.preload(this.getEncoder());
  }

  getEncoder() {
    const { match } = this.props;

    return getEncoder(match.params.id).then((response) => {
      this.setState({
        encoder: response.data,
      });
    });
  }

  onEditEncoder = () => {
    this.setState({ editDialogOpen: true });
  };

  onEditEncoderClose = () => {
    this.setState({ editDialogOpen: false });
  };

  onEditEncoderSave = (updatedEncoder) => {
    saveEncoder(updatedEncoder).then((response) => {
      this.setState({
        editDialogOpen: false,
        encoder: response.data,
      });
    });
  };

  render() {
    const { t, classes } = this.props;
    const { loading, encoder, editDialogOpen } = this.state;

    if (loading) return this.loading();

    return (
      <Fragment fill>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <List
                subheader={
                  <ListSubheader component="div" className={classes.subHeader}>
                    {t("Gegevens van encoder")}
                  </ListSubheader>
                }
              />
              <ListItem>
                <ListItemIcon>
                  <span>{t("labels.ID")}</span>
                </ListItemIcon>
                <ListItemText primary={encoder.id} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <span>{t("labels.NAME")}</span>
                </ListItemIcon>
                <ListItemText primary={encoder.name} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <span>{t("labels.STATUS")}</span>
                </ListItemIcon>
                <ListItemText primary={encoder.status} />
              </ListItem>

              <Grid container direction="row" justify="space-between">
                <Button
                  onClick={this.onEditEncoder}
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                >
                  {t("buttons.CHANGE")}
                </Button>

                <AllInDialog
                  title={t("buttons.CHANGE_RESOURCE", {
                    resource: encoder.name,
                  })}
                  open={editDialogOpen}
                  model={encoder}
                  onClose={this.onEditEncoderClose}
                  onSave={this.onEditEncoderSave}
                >
                  <EnconderEditForm />
                </AllInDialog>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

ViewContainer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect()
)(ViewContainer);
