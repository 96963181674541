import { memo } from "react";
import PropTypes from "prop-types";

// Components
import Button from "@material-ui/core/Button";

// Assets
import dcLogoWhite from "features/Common/img/logo.png";

// Style
import StyledComponent from "./SelectChannel.style";

const SelectChannel = ({ channel }) => {
  const channelColor = channel?.color || "#1270b7";
  const logo = channel.icon_url || dcLogoWhite;

  return (
    <StyledComponent>
      <Button
        aria-haspopup="true"
        color="inherit"
        className="channel-box"
        style={{ background: channelColor }}
      >
        <img
          src={logo}
          alt={channel?.name || "DutchChannels"}
          className="logo-big"
        />
        {channel?.name || "CHANNELS"}
      </Button>
    </StyledComponent>
  );
};

SelectChannel.defaultProps = {
  channel: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default memo(SelectChannel);
