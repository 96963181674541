import styled from "styled-components";

const StyledComponent = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  & .no-suppliers {
    background: ${({ theme }) => theme.palette.grey[100]};
    color: ${({ theme }) => theme.palette.grey[500]};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 4px;
  }
`;

export const StyledSupplier = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.palette.grey[700]};
  display: flex;
  justify-content: space-between;

  & .MuiTypography-h5 {
    font-size: 0.875rem;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  & label {
    margin-right: 4px;
  }

  & .date {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.grey[500]};
  }

  & .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export default StyledComponent;
