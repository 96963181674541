import { memo } from "react";
import PropTypes from "prop-types";

// Components
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";

// Styles
import StyledTableRow from "./style";

const FragmentHeader = ({ translations }) => (
  <StyledTableRow>
    <TableCell className="cell--name" component="td" colSpan="3" scope="row">
      <div className="tree-lines" />
      <Typography variant="h6">{translations.fragment}</Typography>
    </TableCell>
    <TableCell className="cell--subtitles" scope="row" component="td">
      <Typography variant="h6">{translations.subtitles}</Typography>
    </TableCell>
    <TableCell className="cell-drm align--center" scope="row" component="td">
      <Typography variant="h6">{translations.images}</Typography>
    </TableCell>
    <TableCell className="cell--drm align--center" scope="row" component="td">
      <Typography variant="h6">{translations.drm}</Typography>
    </TableCell>
    <TableCell className="cell--fragment-status" component="td" scope="row">
      <Typography variant="h6">{translations.status}</Typography>
    </TableCell>
  </StyledTableRow>
);

FragmentHeader.defaultProps = {
  translations: {
    fragment: "Fragment",
    subtitles: "Subtitles",
    images: "Images",
    drm: "DRM",
    status: "Status",
  },
};

FragmentHeader.propTypes = {
  translations: PropTypes.shape({
    fragment: PropTypes.string,
    subtitles: PropTypes.string,
    images: PropTypes.string,
    drm: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default memo(FragmentHeader);
