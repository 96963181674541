import { takeLatest, all } from "redux-saga/effects";
import * as UserTypes from "./User.types";
import { AllInApi } from "../Common/services/api.service";
import * as userApi from "./user.service";
import * as UserSagas from "./User.sagas";

function* sagas() {
  return yield all([
    takeLatest(UserTypes.GET_USER, UserSagas.getUser, userApi),
    takeLatest(UserTypes.SET_USER_LANGUAGE, UserSagas.setLanguage, userApi),
    takeLatest(UserTypes.SET_USER_COUNTRY, UserSagas.setCountry, userApi),
    takeLatest(
      UserTypes.SET_USER_TRANSLATE_LANGUAGE,
      UserSagas.setTranslateLanguage,
      userApi
    ),
    takeLatest(UserTypes.LOGOUT, UserSagas.logout, AllInApi, userApi),
  ]);
}

export default sagas;
