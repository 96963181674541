import styled from "styled-components";

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 16px;

  & .MuiTextField-root {
    margin-bottom: 16px;
    background: white;
  }

  & .MuiIconButton-root {
    padding: 0;
  }
`;

export default FormStyled;
