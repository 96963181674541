import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: stretch;

  .Container,
  .ContainerHorizontal,
  .ContainerVertical {
    display: flex;
    align-items: stretch;
  }
  .ContainerHorizontal {
    flex-flow: row nowrap;
  }
  .ContainerVertical {
    flex-flow: column nowrap;
  }
  .ResizeContent {
    flex-grow: 1;
    align-self: stretch;
    display: flex;
  }
  .ResizeContentVertical {
    flex-flow: column;
  }
  .ResizeContentHorizontal {
    flex-flow: row;
  }

  .ResizeBarHorizontal {
    cursor: ew-resize;
    width: 20px;
    margin-left: -10px;
    margin-right: -10px;
    background: transparent;
    display: flex;
    z-index: 10;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .ResizeBarVertical {
    cursor: ns-resize;
    height: 20px;
    margin-top: -10px;
    margin-bottom: -10px;
    background: transparent;
    display: flex;
    z-index: 10;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .ResizeHandleHorizontal {
    cursor: ew-resize;
    width: 12px;
    height: 50px;
    background: white;
    border: 2px solid lightgray;
    opacity: 0;
    border-radius: 8px;
    text-align: center;
    z-index: 10;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: opacity ease-out 0.2s;
  }

  &:hover .ResizeHandleHorizontal {
    opacity: 1;
  }

  .ResizeHandleVertical {
    cursor: ns-resize;
    width: 50px;
    height: 12px;
    border-radius: 8px;
    background: white;
    border: 2px solid lightgray;
    z-index: 10;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .ResizeHandleHorizontal > span,
  .ResizeHandleVertical > span {
    display: inline-block;
    overflow: hidden;
    font-size: 12px;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: 1px;
    color: #b3b3b3;
    text-shadow: 1px 0 1px rgb(90, 90, 90);
  }
  .ResizeHandleHorizontal > span {
    line-height: 4px;
  }
  .ResizeHandleVertical > span {
    text-align: center;
    line-height: 12px;
    margin-top: -3px;
  }
  .ResizeHandleHorizontal > span::after {
    content: ". . . . . . . .";
  }
  .ResizeHandleVertical > span::after {
    content: "......";
  }
`;
