import { useState, useCallback } from "react";

// Services
import { getPreviouslyUsedEmailAddresses } from "../../services";

const usePreviouslyUsedEmailAddresses = () => {
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadEmailAddresses = useCallback(() => {
    if (emailAddresses?.length > 0) return;

    setLoading(true);
    getPreviouslyUsedEmailAddresses()
      .then((response) => {
        const emailList = response.data.data;
        setEmailAddresses(emailList);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [emailAddresses]);

  return [loadEmailAddresses, emailAddresses, loading, error];
};

export default usePreviouslyUsedEmailAddresses;
