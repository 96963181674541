import { INITIAL_STATE } from "./Job.state";

export const find = (state) =>
  state.merge({
    loading: true,
  });

export const findSuccess = (state, { data }) =>
  state.merge({
    data,
    loading: false,
  });

export const findFailure = (state) => state;

export const reset = () => INITIAL_STATE;
