import React, { useCallback, useEffect, useState } from "react";
import { get } from "lodash";
import {
  Grid,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import CircularProgressCenter from "components/CircularProgressCenter";
import RefreshIcon from "@material-ui/icons/Refresh";
import TranslateIcon from "@material-ui/icons/Translate";

const ComposeItem = ({
  form,
  regenerate,
  translate,
  selectedType,
  selectedLanguage,
  selectedCountry,
  locale,
  voice,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getKey = useCallback(
    () => `${selectedType?.type}.locales.${locale}.voices.${voice}`,
    [selectedType?.type, locale, voice]
  );

  useEffect(() => {
    setIsChecked(!!get(form.values, `${getKey()}.checked`));
  }, [getKey, form.values]);

  useEffect(() => {
    setIsLoading(!!get(form.values, `${getKey()}.loading`));
  }, [getKey, form.values]);

  const select = (voice) => {
    if (
      !selectedType?.type ||
      !selectedLanguage?.code ||
      !selectedCountry?.code ||
      !voice
    ) {
      return;
    }

    selectedType.voices.forEach((loopVoice) => {
      if (loopVoice === voice) {
        const key = `${getKey()}.checked`;
        const currentValue = get(form.values, key);
        if (typeof currentValue === "boolean") {
          form.setFieldValue(key, !currentValue);
        }
      } else {
        form.setFieldValue(
          `${selectedType?.type}.locales.${locale}.voices.${loopVoice}.checked`,
          false
        );
      }
    });
  };

  return (
    <Box
      sx={{ borderRadius: "4px" }}
      style={{
        margin: "0 -8px 8px -8px",
        padding: "8px 0 16px 0",
        position: "relative",
        backgroundColor: isChecked ? "#e9ecf7" : "white",
        borderWidth: 2,
        borderRadius: 4,
        borderColor: isChecked ? "#2196f3" : "#eeeeee",
        borderStyle: "dashed",
        transition: "border .24s ease-in-out",
      }}
    >
      {isLoading && <CircularProgressCenter />}

      <Grid
        container
        alignItems="center"
        style={{
          margin: "0 0 12px 0",
        }}
      >
        <Grid item>
          <Checkbox
            color="info"
            checked={isChecked}
            onChange={() => select(voice)}
          />
        </Grid>
        <Grid item xs>
          <Typography
            noWrap
            variant="body1"
            style={{
              fontWeight: "bold",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
            onClick={() => select(voice)}
          >
            {voice}
          </Typography>
        </Grid>
        {!isLoading && isChecked && get(form.values, `${getKey()}.id`) && (
          <Grid item>
            <Tooltip title="Translate prompt">
              <IconButton
                aria-label="Translate prompt"
                onClick={() => translate(get(form.values, getKey()), voice)}
              >
                <TranslateIcon style={{ height: "20px", width: "20px" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {!isLoading && (
          <Grid item>
            <Tooltip title="Regenerate prompt">
              <IconButton
                aria-label="Regenerate prompt"
                onClick={() => regenerate(voice)}
              >
                <RefreshIcon style={{ height: "20px", width: "20px" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <div style={{ margin: "0 8px" }}>
        <selectedType.form form={form} formKey={getKey()} />
      </div>
    </Box>
  );
};

export default ComposeItem;
