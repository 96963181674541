import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as languagesActions from "features/Translation/Language.actions";

export const useAvailableLanguages = () => {
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.language.data);

  // Load available languages
  useEffect(() => {
    if (languages) return;
    dispatch(languagesActions.find());
  }, [languages, dispatch]);

  return languages;
};
