import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// Components
import SplitButton, { SplitButtonPopover } from "components/SplitButton";

// Icons
import FindInPageIcon from "@material-ui/icons/FindInPage";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";

// Style
import StyledComponent, { StyledMenuItem } from "./style";
import { Typography } from "@material-ui/core";

const IndexableButton = ({ indexable, onClick }) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      {
        key: "show",
        title: t("labels.SEARCHABLE"),
        additionalData: {
          description: t("text.SEARCHABLE"),
        },
        startIcon: <FindInPageIcon fontSize="small" />,
        className: "show",
        value: true,
      },
      {
        key: "hide",
        title: t("labels.SEARCHABLE_OFF"),
        additionalData: {
          description: t("text.SEARCHABLE_OFF"),
        },
        startIcon: <FindInPageOutlinedIcon fontSize="small" />,
        className: "hide",
        value: false,
      },
    ],
    [t]
  );

  const selectedIndex = useMemo(
    () => options.findIndex((option) => option.value === indexable),
    [options, indexable]
  );

  // Listeners

  const onClickHandler = ({ option }) => {
    onClick({ indexable: option.value });
  };

  return (
    <StyledComponent>
      <SplitButton
        options={options}
        selectedIndex={selectedIndex}
        size="small"
        disableElevation
        onMenuItemClick={onClickHandler}
      >
        {({
          anchorRef,
          open,
          selectedIndex,
          handleClose,
          handleMenuItemClick,
        }) => {
          return (
            <SplitButtonPopover
              open={open}
              anchorRef={anchorRef}
              handleClose={handleClose}
            >
              {options.map((option, index) => (
                <StyledMenuItem
                  key={option.key}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  <Typography variant="h5">{option.title}</Typography>
                  <Typography variant="body1">
                    {option.additionalData.description}
                  </Typography>
                </StyledMenuItem>
              ))}
            </SplitButtonPopover>
          );
        }}
      </SplitButton>
    </StyledComponent>
  );
};

IndexableButton.defaultProps = {
  indexable: false,
  onClick: () => {},
};

IndexableButton.propTypes = {
  indexable: PropTypes.bool,
  onClick: PropTypes.func,
};

export default memo(IndexableButton);
