import { memo } from "react";
import PropTypes from "prop-types";

// Components
import TableCell from "@material-ui/core/TableCell";

// Local Components
import SubtitleStatus from "./SubtitleStatus";

const SubtitleCell = ({ subtitles, language, onSubtitleDetailsClick }) => (
  <TableCell className="cell--subtitles" scope="row" component="td">
    <div>
      <div className="subtitles">
        <SubtitleStatus
          onSubtitleDetailsClick={onSubtitleDetailsClick}
          files={subtitles}
          language={language}
        />
      </div>
    </div>
  </TableCell>
);

SubtitleCell.defaultProps = {
  onSubtitleDetailsClick: () => null,
};

SubtitleCell.propTypes = {
  subtitles: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.oneOf(["active", "inactive", "pending", "draft"]),
      type: PropTypes.shape({
        id: PropTypes.number,
        identifier: PropTypes.string,
        type: PropTypes.string,
      }),
    })
  ),
  language: PropTypes.string,
  onSubtitleDetailsClick: PropTypes.func,
};

export default memo(SubtitleCell);
