/* eslint-disable react/display-name */

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Selectors
import { getCurrentChannel } from "features/Channel/selectors";

// Actions
import { openFragmentCreateDialog } from "features/Fragment/reducer";

// Icons
import AddIcon from "@material-ui/icons/Add";
import InboxIcon from "@material-ui/icons/Inbox";

// Components
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";

// Style
import StyledComponent from "./style";

const FragmentNotFound = () => {
  const { t } = useTranslation();

  // Selectors
  const asset = useSelector((state) => state.asset.selected);
  const channel = useSelector(getCurrentChannel);

  const dispatch = useDispatch();
  const onAddClick = () => {
    dispatch(openFragmentCreateDialog());
  };

  if (!channel?.data?.checks || !asset || asset.loading) return null;
  return (
    <StyledComponent>
      <div className="content">
        <InboxIcon className="inbox-icon" fontSize="large" />
        <Typography variant="h5">
          {t("text.FRAGMENT_NOT_FOUND_HEADER")}
        </Typography>
        <Typography variant="body1">
          {t("text.FRAGMENT_NOT_FOUND_TEXT")}
        </Typography>
        <Fab
          color="primary"
          aria-label="new fragment"
          size="small"
          onClick={onAddClick}
        >
          <AddIcon />
        </Fab>
      </div>
    </StyledComponent>
  );
};

export default FragmentNotFound;
