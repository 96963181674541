import styled from "styled-components";

const StyledComponent = styled.div`
  & a {
    text-decoration: none;
  }

  .MuiCard-root {
    box-shadow: none;
    &:hover {
      background: ${({ theme }) => theme.palette.grey[100]};
    }
  }

  .MuiCardContent-root {
    padding: ${({ theme }) => theme.spacing(2)}px;
    padding-top: 0;
  }

  .MuiTypography-root {
    max-width: 800px;
  }

  .activeEdit {
    .MuiCard-root {
      background: ${({ theme }) => theme.palette.grey[100]};
    }

    .MuiIconButton-label {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & .labels {
    display: flex;
    flex-wrap: wrap;
  }
`;

export default StyledComponent;
