import Immutable from "seamless-immutable";

export const INITIAL_STATE = Immutable({
  data: null,
  loading: true,
  fragmentId: null,
  translate: {
    data: null,
    loading: true,
  },
});
