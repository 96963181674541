/* eslint-disable camelcase */
import { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Icons
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";

// Hooks
import { useAvailableLanguages } from "features/Translation/hooks";
import { useQuery } from "features/Common/hooks";

// Redux
import { getSubTitleTypes } from "features/File/selectors";
import { uploadFile } from "features/Fragment/reducer";

// Utils
import Utils from "features/Common/utils";

// Components
import Toolbar from "@material-ui/core/Toolbar";
import NationFlag from "components/NationFlag";
import AppBar from "@material-ui/core/AppBar";
import { Grid, Typography } from "@material-ui/core";
import DownloadLink from "components/DownloadLink";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import CircularProgressCenter from "components/CircularProgressCenter";
import RevisionTranslatorPanel from "features/Fragment/FragmentRevisionTranslatorPanel";

// Constants
import { TABS } from "features/Fragment/FragmentRevisionTranslatorPanel/RevisionTranslatorPanel";

// Local Components
import SubtitleUpload from "./SubtitleUpload";
import { useNormalizedSubtitleFiles } from "../hooks";

// Style
import StyledComponent, { StyledDrawer } from "./style";

const FragmentSubtitlesEdit = ({ fragment, asset, fragmentFiles }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Url params
  const query = useQuery();
  const queryLocale = query.get("locale");
  const queryFileId = query.get("file");
  const queryTab = query.get("tab");

  // Redux selectors
  const subtitleType = useSelector(getSubTitleTypes);
  const networkState = useSelector((state) => state.fragment?.networkState);
  const languages = useAvailableLanguages();

  // Action Panel
  const panelRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorInfo, setAnchorInfo] = useState(null);

  const open = Boolean(anchorEl);

  // Fragment
  const { identifier } = asset || { identifier: "" };
  const { id: fragmentId, name: fragmentName } = fragment;
  const fragmentNetworkState = networkState[fragmentId] || {};
  const videoUrl = Utils.Fragments.getAssetVideoPath(fragment?.file?.hash);
  const videoName = `${identifier}.mp4`;

  const subtitleFiles = useNormalizedSubtitleFiles(fragmentFiles);

  // Values
  const tabIndex = useMemo(() => {
    if (!queryTab) return null;
    return TABS[queryTab.toUpperCase()];
  }, [queryTab]);

  // Listeners

  const onMoreClick = (
    event,
    { language, fileId, defaultTab, defaultInvitationType, defaultFileId }
  ) => {
    setAnchorInfo({
      ...language,
      fileId,
      fragmentId,
      defaultTab,
      defaultInvitationType,
      defaultFileId,
    });
    setAnchorEl(event.currentTarget);
  };

  const onActionPanelClose = () => {
    setAnchorInfo(null);
    setAnchorEl(null);
  };

  const onDropAccepted = ({
    id,
    typeId,
    fileId,
    acceptedFiles,
    isNew,
    type,
    locale,
  }) => {
    dispatch(
      uploadFile({
        fragmentId: id,
        fileId,
        typeId,
        acceptedFiles,
        isNew,
        type,
        locale,
      })
    );
  };

  const onTabChange = (tabIndex) => {
    setAnchorInfo({
      ...anchorInfo,
      defaultTab: tabIndex,
    });
  };

  useEffect(() => {
    if (!queryLocale || !queryFileId || !panelRef) return;

    setAnchorEl(panelRef);
    setAnchorInfo({
      fragmentId,
      fragmentName,
      code: queryLocale,
      highlightFileId: queryFileId,
    });
  }, [
    queryLocale,
    queryFileId,
    panelRef,
    setAnchorEl,
    fragmentId,
    fragmentName,
  ]);

  if (!fragment) return null;
  if (!languages) return <CircularProgressCenter type="inline" />;

  return (
    <StyledComponent>
      <Grid container direction="column">
        <Grid item ref={panelRef}>
          <DownloadLink
            className="video-download"
            href={videoUrl}
            name={videoName}
            trackName="Subtitles video download button"
            trackDetails={{ videoUrl, fragment }}
          >
            {({ downloading, progress }) => (
              <>
                {videoName}
                {!downloading && <GetAppIcon size="small" />}
                {downloading && (
                  <div className="downloading">
                    <Typography variant="caption">downloading...</Typography>
                    <CircularProgress
                      size={24}
                      variant="static"
                      color="secondary"
                      value={progress * 100}
                    />
                  </div>
                )}
              </>
            )}
          </DownloadLink>
        </Grid>
        <Grid item>
          {languages.map((language) => {
            const { code } = language;

            // Network state
            const subtitleNetworkState = fragmentNetworkState.subtitle || {};
            const typeNetworkState = subtitleNetworkState[code] || {};
            const state = typeNetworkState?.state || "idle";
            const errors = typeNetworkState?.errors;

            // Actual subtitle data
            const subtitleFilesForCountry = subtitleFiles[code] || [];
            const [subtitle] = subtitleFilesForCountry;
            const isPlaceHolder = subtitle?.length === 0;
            const fileId = subtitle?.id;

            return (
              <SubtitleUpload
                id={fragmentId}
                typeId={subtitleType?.id}
                fileId={fileId}
                locale={code}
                key={language.id}
                language={language}
                networkState={state}
                accept={Utils.Fragments.formatMimes(subtitleType[0]?.mimes)}
                file={subtitle}
                status={subtitle?.status}
                revisions={subtitleFilesForCountry?.length}
                onDropAccepted={onDropAccepted}
                name={subtitle?.name}
                errors={errors}
                isPlaceHolder={isPlaceHolder}
                onMoreClick={({ e, tab, invitationType, fileId }) =>
                  onMoreClick(e, {
                    language,
                    fileId,
                    fragmentName,
                    defaultTab: tab,
                    defaultInvitationType: invitationType,
                    defaultFileId: fileId,
                  })
                }
                maxSize={subtitleType[0]?.max_filesize}
              />
            );
          })}
          <StyledDrawer anchor="right" open={open} onClose={onActionPanelClose}>
            <AppBar position="static">
              <Toolbar>
                <div className="left-column">
                  <NationFlag countryCode={anchorInfo?.code || ""} />
                  <Typography variant="h6">
                    {anchorInfo?.native_name || ""}
                  </Typography>
                </div>
                <Tooltip title={t("labels.CLOSE")}>
                  <IconButton
                    color="inherit"
                    aria-label="Close"
                    onClick={onActionPanelClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </AppBar>
            <RevisionTranslatorPanel
              fragmentId={anchorInfo?.fragmentId}
              highlightFileId={+anchorInfo?.highlightFileId}
              locale={anchorInfo?.code || ""}
              nativeName={anchorInfo?.native_name || ""}
              fragmentName={anchorInfo?.fragmentName}
              defaultTab={anchorInfo?.defaultTab || tabIndex}
              defaultInvitationType={anchorInfo?.defaultInvitationType}
              defaultFileId={anchorInfo?.defaultFileId}
              onTabChange={onTabChange}
              onMenuClick={({ e, tab, language, fileId, invitationType }) =>
                onMoreClick(e, {
                  fileId,
                  language: {
                    code: language,
                  },
                  fragmentName,
                  defaultTab: tab,
                  defaultInvitationType: invitationType,
                  defaultFileId: fileId,
                })
              }
            />
          </StyledDrawer>
        </Grid>
      </Grid>
    </StyledComponent>
  );
};

FragmentSubtitlesEdit.propTypes = {
  fragment: PropTypes.shape({}),
  asset: PropTypes.shape({}),
};

export default FragmentSubtitlesEdit;
