import grey from "@material-ui/core/colors/grey";

const style = {
  container: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  grid: {
    display: "flex",
    overflow: "auto",
    maxHeight: "100%",
  },
  list: {
    padding: 0,
    borderRight: `1px solid ${grey[300]}`,
    background: "#333",
  },
  settingSelected: {
    background: "#444",
  },
  link: {
    textDecoration: "none",
    color: "white",
    opacity: 0.8,
    display: "flex",
    flexGrow: 1,
  },
  icon: {
    width: "1.1em",
    height: "1.1em",
    color: "white",
  },
};

export default style;
