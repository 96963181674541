import grey from "@material-ui/core/colors/grey";

const style = {
  layoutFill: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    width: "100%",
    flexGrow: 1,
  },
  topHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 15px",
    backgroundColor: "rgba(0 , 0, 0, .02)",
    borderBottom: `1px solid ${grey[300]}`,
    minHeight: 59,
  },

  titleHeader: {
    display: "flex",
    width: "25%",
    alignItems: "center",
  },

  totalsHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
  },

  searchHeader: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "25%",
  },

  input: {
    width: 150,
    border: "none",
  },
};

export default style;
