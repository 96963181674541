import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

/* Components */
import AutoComplete from "./AutoComplete";

const AutoCompleteWithControl = (props) => {
  const { control, createOutputFormatter, outputFormatter, onChange } = props;

  const onClear = (event, value) => value;

  const onCreate = (event, value, newOption) => {
    const newValue = createOutputFormatter({ value, newOption });
    return newValue;
  };

  const onRemove = (event, value) => value;

  const onChangeHandler = (params) => {
    const [event, value, reason, newOption] = params;
    let output = value;
    if (reason === "create-option") {
      output = onCreate(event, value, newOption);
    }
    if (reason === "remove-option") {
      output = onRemove(event, value);
    }
    if (reason === "clear") {
      output = onClear(event, value);
    }

    output = outputFormatter(output);
    onChange(output);
    return output;
  };

  return (
    <Controller
      as={AutoComplete}
      control={control}
      {...props}
      onChange={onChangeHandler}
    />
  );
};

AutoCompleteWithControl.displayName = "AutoCompleteWithControl";

AutoCompleteWithControl.defaultProps = {
  createOutputFormatter: ({ value }) => value,
  outputFormatter: (value) => value,
  onChange: () => {},
};
AutoCompleteWithControl.propTypes = {
  control: PropTypes.shape({}).isRequired,
  createOutputFormatter: PropTypes.func,
  outputFormatter: PropTypes.func,
  onChange: PropTypes.func,
};

export default AutoCompleteWithControl;
