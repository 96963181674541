import { takeLatest, all, call } from "redux-saga/effects";

// Types
import { identify, setIdentity, track } from "features/Analytics";
import {
  uploadFile,
  uploadFileFailed,
  uploadFileSuccess,
  patchFragmentMetaValuesSuccess,
  patchFragmentMetaValues,
  patchFragmentMetaValuesFailed,
} from "features/Fragment/reducer";
import {
  uploadFile as uploadFileExternal,
  uploadFileSuccess as uploadFileExternalSuccess,
  uploadFileFailed as uploadFileExternalFailed,
} from "features/External/reducer";
import * as UserTypes from "features/Auth/User.types";

// ========= Authentication

export function* setUser({ user }) {
  if (!user) return;
  const { id, name, email, admin, locale, superadmin, hasTwoFactor } = user;

  // Analytics identify user
  yield call(identify, id);
  yield call(setIdentity, {
    id,
    name,
    email,
    admin,
    locale,
    superadmin,
    hasTwoFactor,
  });
}

// ========= File upload

export function* uploadFileSaga({ payload }) {
  yield call(track, "File Upload", payload);
}

export function* uploadFileSuccessSSaga({ payload }) {
  yield call(track, "File Upload Success", payload);
}

export function* uploadFileFailedSSaga({ payload }) {
  const { errors } = payload?.error?.response?.data || {};
  yield call(track, "File Upload failed", { error: JSON.stringify(errors) });
}

// ========= File upload

export function* uploadFileExternalSaga({ payload }) {
  yield call(track, "External Subtitle Upload", payload);
}

export function* uploadFileExternalSuccessSSaga({ payload }) {
  yield call(track, "External Subtitle Upload Success", payload);
}

export function* uploadFileExternalFailedSSaga({ payload }) {
  const { errors } = payload?.error?.response?.data || {};
  yield call(track, "External Subtitle Upload failed", {
    error: JSON.stringify(errors),
  });
}

// ========= Patch Fragment Values

export function* patchFragmentValuesSaga({ payload }) {
  yield call(track, "Patch fragment meta values", payload);
}

export function* patchFragmentValuesSuccessSaga({ payload }) {
  yield call(track, "Patch fragment meta values success", payload);
}

export function* patchFragmentValuesFailedSaga({ payload }) {
  const { errors } = payload?.error?.response?.data || {};
  yield call(track, "Patch fragment meta values failed", {
    error: JSON.stringify(errors),
  });
}

function* sagas() {
  return yield all([
    takeLatest(UserTypes.SET_USER, setUser),
    takeLatest(uploadFile.type, uploadFileSaga),
    takeLatest(uploadFileSuccess.type, uploadFileSuccessSSaga),
    takeLatest(uploadFileFailed.type, uploadFileFailedSSaga),
    takeLatest(uploadFileExternal.type, uploadFileExternalSaga),
    takeLatest(uploadFileExternalSuccess.type, uploadFileExternalSuccessSSaga),
    takeLatest(uploadFileExternalFailed.type, uploadFileExternalFailedSSaga),
    takeLatest(patchFragmentMetaValues.type, patchFragmentValuesSaga),
    takeLatest(
      patchFragmentMetaValuesSuccess.type,
      patchFragmentValuesSuccessSaga
    ),
    takeLatest(
      patchFragmentMetaValuesFailed.type,
      patchFragmentValuesFailedSaga
    ),
  ]);
}

export default sagas;
