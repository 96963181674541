import React from "react";
import { useTranslation } from "react-i18next";

import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { Button } from "components";

import { useAuthentication } from "../AuthProvider";
import { twoFactorAuthenticationDisable } from "../user.service";

const DisableTwoFactor = ({ onClose }) => {
  const { t } = useTranslation();
  const { reloadUser } = useAuthentication();

  const disableTwoFactor = async () => {
    await twoFactorAuthenticationDisable();
    reloadUser();
    onClose();
  };

  return (
    <>
      <MuiDialogContent dividers>
        <Typography gutterBottom>{t("text.TWO_FACTOR_DISABLE")}</Typography>
      </MuiDialogContent>
      <MuiDialogActions>
        <Button color="secondary" onClick={disableTwoFactor}>
          {t("labels.DISABLE_TWO_FACTOR")}
        </Button>
      </MuiDialogActions>
    </>
  );
};

export default DisableTwoFactor;
