import Utils from "../Common/utils";

import { INITIAL_STATE, ASSET } from "./Asset.state";

export const find = (state, { query }) =>
  state.merge({
    loading: true,
    query,
  });

export const findMore = (state) => state;

export const findSuccess = (state, { data }) =>
  state.merge({
    data: data.data,
    meta: data.meta,
    labels: data.labels,
    links: data.links,
    loading: false,
  });

export const findFailure = (state) => state;

export const reset = () => INITIAL_STATE;

export const setSelectedType = (state, { assetType }) =>
  state.merge({
    selectedType: assetType,
  });

export const findById = (state, { assetId }) =>
  state.merge({
    selected: {
      ...state.selected,
      id: assetId,
      loading: true,
    },
  });

export const findByIdSuccess = (state, { asset }) => {
  // Normalize checks
  const checksById = Utils.Common.normalize(asset?.checks, (check) => check.id);

  return state.merge({
    selected: {
      id: asset.id,
      data: {
        ...asset,
        checks: checksById,
      },
      loading: false,
    },
  });
};

export const resetAsset = (state) =>
  state.merge({
    selected: {
      id: null,
      data: ASSET,
      loading: true,
    },
  });

export const setSort = (state, { sortField, sortDirection }) =>
  state.merge({ sortField, sortDirection, loading: false });

export const updateQualityChecks = (state, { payload }) => {
  const { data } = payload;
  const { check } = data;
  const { id } = check;

  return state.merge({
    selected: {
      ...state.selected,
      data: {
        ...state.selected.data,
        checks: {
          ...state.selected.data.checks,
          [id]: check,
        },
      },
    },
  });
};

export const fragmentCreated = (state, { fragment }) =>
  state.merge({
    selected: {
      ...state.selected,
      data: {
        ...state.selected.data,
        fragments: [...state.selected.data.fragments, fragment],
      },
    },
  });

export const patchLabels = (state) => {
  return state.merge({
    networkState: {
      ...state.networkState,
      labels: "updating",
    },
  });
};

export const patchLabelsSuccess = (state, { payload }) => {
  return state.merge({
    networkState: {
      ...state.networkState,
      labels: "idle",
    },
    selected: {
      ...state.selected,
      data: {
        ...state.selected.data,
        labels: payload.labels,
      },
    },
  });
};

export const patchLabelsFailed = (state) => {
  return state.merge({
    networkState: {
      ...state.networkState,
      labels: "error",
    },
  });
};
