import * as types from "./Fragment.types";

export const find = (fragmentId) => ({ type: types.FIND, fragmentId });

export const findSuccess = (data) => ({ type: types.FIND_SUCCESS, data });

export const findFailure = () => ({ type: types.FIND_FAILURE });

export const reset = () => ({ type: types.RESET });

export const findTranslate = () => ({ type: types.FIND_TRANSLATE });

export const findTranslateSuccess = (data) => ({
  type: types.FIND_TRANSLATE_SUCCESS,
  data,
});

export const findTranslateFailure = () => ({
  type: types.FIND_TRANSLATE_FAILURE,
});

export const setFadeIn = (fadeInTime) => ({
  type: types.SET_FADE_IN,
  fadeInTime,
});

export const setFadeOut = (fadeOutTime) => ({
  type: types.SET_FADE_OUT,
  fadeOutTime,
});

export const setSnapshot = (snapshotTime) => ({
  type: types.SET_SNAPSHOT,
  snapshotTime,
});

export const setFile = (file) => ({
  type: types.SET_FILE,
  file,
});
