/* Components */
import Layout from "../../components/Layout";

/* Features */
import AppSettingsList from "../../features/AppSettings/List";

const Page = ({ routes, ...props }) => (
  <Layout routes={routes}>
    <AppSettingsList {...props} />
  </Layout>
);

export default Page;
