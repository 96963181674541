import { createContext, useState } from "react";

const SeoEntryContext = createContext(undefined);

const SeoEntryProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [searchParam, setsearchParam] = useState(" ");
  const [checkedFilters, setCheckedFilters] = useState([]);
  const [selectedStateFilterList, setSelectedStateFilterList] = useState([]);
  const [stateFilter, setStateFilter] = useState({
    published: false,
    changed: false,
    draft: false,
  });

  const setStateFilters = (filter) => {
    const stateFilterList = Object.entries(filter)
      .filter(([key, value]) => value)
      .map((item) => item[0]);
    setSelectedStateFilterList(stateFilterList);
    setStateFilter(filter);
  };

  return (
    <SeoEntryContext.Provider
      value={{
        loading,
        setLoading,
        searchParam,
        setsearchParam,
        checkedFilters,
        setCheckedFilters,
        stateFilter,
        setStateFilters,
        selectedStateFilterList,
        setSelectedStateFilterList,
      }}
    >
      {children}
    </SeoEntryContext.Provider>
  );
};

export { SeoEntryContext, SeoEntryProvider };
