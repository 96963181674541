import styled from "styled-components";

export default styled.div`
  width: 100%;
  .container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .detailScrollbar {
    overflow: auto;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }

  .stateButton {
    width: 205px;
    .MuiButtonGroup-fullWidth {
      height: 40px;
    }
  }

  form {
    padding: 20px;
    width: 100%;
  }

  form > div {
    width: 100%;
    margin-bottom: 24px;

    &.alert {
      padding-bottom: 2px;

      ul {
        list-style-type: "- ";
        padding: 0 0 0 12px;
        margin: 4px 0 0 0;
        font-size: 12px;
      }
    }

    .full-width {
      width: 100%;
    }
  }

  .error {
    color: red;
    text-align: right;
  }

  .sideBarButtons {
    text-align: center;
    margin: 16px 0 16px 0;

    button {
      padding: 12px 16px;
      border-right: 1px solid rgba(255, 255, 255, 0.3);

      > .MuiButton-label > span {
        background-size: cover;
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }
`;
