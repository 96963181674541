import { memo } from "react";
import PropTypes from "prop-types";

// Icons
import InfoIcon from "@material-ui/icons/Info";

// Components
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Label from "components/Label";

const CountryCell = ({
  countries,
  onMoreInfoClick,
  id,
  schedule,
  name,
  identifier,
}) => {
  const totalContractCountries = countries?.length;

  const onHandleMoreInfoClick = (event) => {
    onMoreInfoClick({
      event,
      id: `${id}-nl`,
      schedule,
      name,
      identifier,
    });
  };

  return (
    <TableCell component="td" className="cell--countries">
      <Label
        className="label"
        onClick={onHandleMoreInfoClick}
        value={totalContractCountries}
      >
        <IconButton
          color="inherit"
          size="small"
          aria-label="Close"
          onClick={onHandleMoreInfoClick}
        >
          <InfoIcon fontSize="small" />
        </IconButton>
      </Label>
    </TableCell>
  );
};

CountryCell.defaultProps = {
  onMoreInfoClick: () => null,
};

CountryCell.propTypes = {
  id: PropTypes.number,
  countries: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.shape({}),
  identifier: PropTypes.string,
  schedule: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  onMoreInfoClick: PropTypes.func,
};

export default memo(CountryCell);
