import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import * as yup from "yup";
import { addUser } from "../role.service";
import TextField from "../../../../components/Form/TextField";
import Button from "../../../../components/Button";

const AddUser = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const { handleSubmit, register, errors } = useForm({
    validationSchema,
  });

  const onSubmit = (data) => {
    addUser(data).then((response) => {
      history.push(`/settings/users/${response.data.data.id}`);
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexDirection: " column" }}
    >
      <TextField
        name="name"
        label={t("labels.NAME")}
        placeholder={t("labels.NAME")}
        ref={register}
      />
      <TextField
        name="email"
        label={t("labels.EMAIL_ADDRESS")}
        placeholder={t("labels.EMAIL_ADDRESS")}
        ref={register}
        error={errors.email?.message}
      />
      <div
        style={{
          paddingTop: "8px",
          paddingBotom: "4px",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Button className="submit-button" color="primary" type="submit">
          {t("buttons.ADD")}
        </Button>
      </div>
    </form>
  );
};

export default AddUser;
