import styled from "styled-components";
import { Dialog } from "@material-ui/core";

const StyledDialog = styled(Dialog)`
  form {
    background: ${({ theme }) => theme.palette.grey[100]};
  }

  .inputMargin {
    margin-top: 16px;
  }
  .qualityCheck {
    padding-left: 9px;
  }

  .dialogActions {
    padding: ${({ theme }) => `${theme.spacing()}px`};
    justify-content: center;
    margin-top: 18px;
  }

  .MuiInputBase-root {
    background: white;
  }
`;

export default StyledDialog;
