import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const useCurrentAssetUrl = () => {
  const { channelId, assetId, type } = useParams();

  const url = useMemo(() => {
    const path = `/channel/${channelId}/${type}/${assetId}`;
    return path;
  }, [channelId, assetId, type]);

  return url;
};
