import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import moment from "moment";

// Icons
import CloseIcon from "@material-ui/icons/Close";

// Components
import TextField from "components/Form/TextField";
import Button from "components/Button";
import IconButton from "@material-ui/core/IconButton";
import { DatePickerWithControl } from "components/Form/DatePicker";

// Local Hooks
import { useSuppliers } from "../Suppliers";

// Services
import * as channelService from "../../services";

// Style
import FormStyled from "./style";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const InviteForm = ({ license }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar() || {};
  const { loadSuppliers } = useSuppliers();

  // State
  const [loading, setLoading] = useState(false);

  // Form
  const {
    handleSubmit,
    register,
    watch,
    control,
    errors,
    formState,
    reset,
  } = useForm({
    validationSchema,
    defaultValues: {
      email: "",
      dueDate: moment().add(14, "days").unix(),
    },
  });

  const showSuccessNotification = () => {
    const message = t("text.SUPPLIER_INVITE_SUCCESS");

    enqueueSnackbar(message, {
      variant: "success",
      action: (key) => {
        return (
          <>
            <IconButton
              color="inherit"
              aria-label="Close"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon />
            </IconButton>
          </>
        );
      },
    });
  };

  const onSubmit = (data) => {
    const { email, dueDate } = data;
    setLoading(true);

    channelService
      .inviteSupplier(
        license.id,
        email,
        moment.unix(dueDate).format("DD-MM-YYYY")
      )
      .then(() => {
        reset({
          email: "",
          dueDate: moment().add(14, "days").unix(),
        });

        showSuccessNotification();
        loadSuppliers(license.id, true);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  // Invoke formState dirty values so that the proxy is
  // trigger/activated. Otherwise formState.isDirty doesn't work
  useEffect(() => {
    if (!formState) return;
    formState.dirtyFields.values();
  }, [formState]);

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="email"
        label={t("labels.EMAIL")}
        size="small"
        variant="outlined"
        className="inputField"
        autoFocus
        error={errors.email?.message}
        ref={register}
      />
      <DatePickerWithControl
        control={control}
        name="dueDate"
        timestamp
        label={t("labels.DEADLINE")}
        size="small"
        variant="outlined"
        error={errors.dueDate?.message}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disableElevation
        disabled={!formState.dirty}
        trackName="channelsettings.licenses.invite"
        trackDetails={{
          ...license,
          ...watch(),
        }}
        loading={loading}
      >
        {t("buttons.INVITE")}
      </Button>
    </FormStyled>
  );
};

InviteForm.propTypes = {};

export default InviteForm;
