import { useEffect, useState } from "react";
import axios from "axios";

const useFileContentFromUrl = (url) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!url) return;

    setLoading(true);
    setError(null);

    axios({
      url,
      method: "GET",
      // important
      responseType: "text",
    })
      .then((response) => {
        setContent(response?.data);
      })
      .catch((requestError) => {
        setError(requestError);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  return {
    loading,
    content,
    error,
  };
};

export default useFileContentFromUrl;
