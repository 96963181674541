import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";

const style = {
  label: {
    padding: "6px 10px;",
    fontSize: "12px",
    margin: "0 2px",
  },

  labelDefault: {
    backgroundColor: grey[200],
    color: grey[800],
  },

  labelBlue: {
    backgroundColor: blue[50],
    color: blue[800],
  },

  labelError: {
    backgroundColor: red[50],
    color: red[800],
  },

  labelPostponed: {
    backgroundColor: grey[50],
    color: grey[800],
  },

  labelReady: {
    backgroundColor: grey[200],
    color: green[800],
  },

  labelInProgress: {
    backgroundColor: grey[200],
    color: blue[800],
  },

  labelSuccess: {
    backgroundColor: grey[200],
    color: green[800],
  },
};

export default style;
