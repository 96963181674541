import styled from "styled-components";

const StyledComponent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > img {
    width: 100%;
    height: 100%;
  }

  &:hover .delete-button {
    opacity: 1;
  }

  & > .delete-button {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: red;
    color: white;
    border: none;
    cursor: pointer;
    transform: translate(50%, -50%);
    outline: none;
    transition: opacity ease-out 0.2s;

    & .check-mark-root {
      display: none;
    }

    &.loading,
    &.error {
      display: none;
    }

    &.deleting,
    &.deleted {
      opacity: 1;

      & .check-mark-root {
        display: block;
      }
    }

    &.deleted {
      opacity: 0;
      transition: opacity ease-out 0.2s;
      transition-delay: 2s;
    }

    & .MuiSvgIcon-root {
      font-size: 1rem;
    }

    & .check-mark-root {
      top: 0;
    }
  }
`;

export default StyledComponent;
