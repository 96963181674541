import PropTypes from "prop-types";

// Style
import StyledComponent from "./style";

const PosterLabel = ({
  text,
  backgroundColor,
  textColor,
  className,
  placeholder,
}) => {
  return (
    <StyledComponent
      className={className}
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      {text || <span className="placeholder">{placeholder}</span>}
    </StyledComponent>
  );
};

PosterLabel.defaultProps = {
  textColor: "white",
  backgroundColor: "black",
  placeholder: "Placeholder",
};

PosterLabel.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  placeholder: PropTypes.string,
};

export default PosterLabel;
