import styled from "styled-components";

// Components
import MenuItem from "@material-ui/core/MenuItem";

// Utils
import { darken } from "@material-ui/core/styles";
import Utils from "features/Common/utils";

const StyledComponent = styled.div`
  & .MuiButton-outlinedSizeSmall {
    font-size: 0.65rem;
  }

  & .MuiButtonGroup-grouped {
    background: ${({ color }) => color};
    color: ${({ color }) => Utils.Color.getContrastTextColor(color)};
    border-color: ${({ color }) => darken(color, 0.1)};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${({ statecolor }) => statecolor};
  }

  & .MuiTypography-h5 {
    font-size: 0.875rem;
    font-weight: bold;
  }

  & .MuiTypography-body1 {
    font-size: 0.75rem;
    max-width: 320px;
    white-space: initial;
    color: ${({ theme }) => theme.palette.grey[600]};
  }

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 12px;
    background: ${({ statecolor }) => statecolor};
    border: 1px solid white;
  }
`;

export default StyledComponent;
