import { combineReducers } from "redux";

import { startup } from "features/Common/redux/Startup.actions";

/* Reducers */
import { reducer as Startup } from "features/Common/redux/reducer";
import { reducer as UserAuth } from "features/Auth/reducer";
import { reducer as Channel } from "features/Channel/reducer";
import { reducer as Inbox } from "features/Inbox/reducer";
import { reducer as Asset } from "features/Asset/reducer";
import Fragment from "features/Fragment/reducer";
import File from "features/File/reducer";
import { reducer as Language } from "features/Translation/reducer";
import { reducer as Log } from "features/Log/reducer";
import { reducer as Job } from "features/ChannelSettings/Jobs/reducer";
import countryReducers from "features/Common/country.reducers";
import externalReducer from "features/External/reducer";
import licensesReducer from "features/ChannelSettings/Licenses/reducer";

import rootSaga from "sagas";
import configureStore from "./createStore";

const rootReducer = combineReducers({
  startup: Startup,
  user: UserAuth,
  channel: Channel,
  inbox: Inbox,
  asset: Asset,
  fragment: Fragment,
  file: File,
  language: Language,
  log: Log,
  job: Job,
  country: countryReducers,
  external: externalReducer,
  licenses: licensesReducer,
});

const store = configureStore(rootReducer, rootSaga);

store.dispatch(startup());

export default store;
