import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import { useAuthentication } from "../AuthProvider";
import {
  twoFactorAuthenticationEnable,
  twoFactorQrCode,
  twoFactorRecoveryCodes,
} from "../user.service";

const TwoFactorCode = ({ onClose }) => {
  const { t } = useTranslation();
  const { reloadUser } = useAuthentication();
  const [qrCode, setqrCode] = useState(null);
  const [recoveryCodes, setRecoveryCodes] = useState(null);

  useEffect(() => {
    const enableTwoFactor = async () => {
      await twoFactorAuthenticationEnable();

      const qrCodeRequest = await twoFactorQrCode();
      setqrCode(qrCodeRequest.data.svg);

      const recoveryCodesRequest = await twoFactorRecoveryCodes();
      setRecoveryCodes(recoveryCodesRequest.data);

      reloadUser();
    };

    enableTwoFactor();
  }, [reloadUser]);

  if (!qrCode || !recoveryCodes) return null;

  return (
    <>
      <MuiDialogContent dividers>
        <Typography gutterBottom>{t("text.TWO_FACTOR_ENABLE")}</Typography>

        <p
          dangerouslySetInnerHTML={{
            __html: qrCode,
          }}
        />

        <Typography gutterBottom>
          {t("text.TWO_FACTOR_RECOVERY_CODES")}
        </Typography>

        <RecoveryCodesBox>
          {recoveryCodes.map((code) => (
            <div key={code}>{code}</div>
          ))}
        </RecoveryCodesBox>
      </MuiDialogContent>
    </>
  );
};

const RecoveryCodesBox = styled.div`
  background-color: rgba(243, 244, 246);
  border-radius: 4px;
  padding: 1rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;

  > div {
    font-size: 15px;
  }

  > div:last-child {
    margin-bottom: 0px;
  }
`;

export default TwoFactorCode;
