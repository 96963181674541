/* eslint-disable camelcase */
import { useState, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";

// Utils
import Utils from "features/Common/utils";

// Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ReceiptIcon from "@material-ui/icons/Receipt";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";

// Components
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import Fade from "@material-ui/core/Fade";
import DownloadLink from "components/DownloadLink";
import Fab from "components/Fab";
import MenuItem from "components/MenuItem";

// Style
import StyledComponent, { StyledMenu } from "./style";

// Constants
import { REVISION_STATUS } from "../constants";

// eslint-disable-next-line no-unused-vars
const CanViewMenuItem = forwardRef(({ status, when = [], children }, ref) => {
  if (!when.includes(status)) return null;
  return <>{children}</>;
});

CanViewMenuItem.displayName = "CanViewMenuItem";

const Revision = ({
  id,
  fragmentId,
  highlightFileId,
  name,
  createDate,
  user,
  path,
  status,
  locale,
  onMenuItemClick,
}) => {
  const { t } = useTranslation();

  const createDateFormatted = moment
    .unix(createDate)
    .format("DD MMMM YYYY HH:mm:ss");

  // State
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [markingAsDraft, setMarkAsDraft] = useState(false);

  const [menuRef, setMenuRef] = useState(null);
  const open = Boolean(menuRef);

  const downloadUrl = useMemo(
    () => Utils.Fragments.getAssetStoragePath(path?.path),
    [path]
  );

  // Listeners
  const onMoreClick = (event) => {
    setMenuRef(event.currentTarget);
  };

  const onMenuClose = () => {
    setMenuRef(null);
  };

  const shouldHightLight = id === Number(highlightFileId);

  return (
    <StyledComponent
      status={status}
      className={`${status} ${shouldHightLight ? "highlight" : null}`}
    >
      {shouldHightLight && (
        <div className="review">{t("labels.PLEASE_REVIEW")}</div>
      )}
      <div className="column-left">
        <div>
          <DownloadLink
            className="download-link"
            href={downloadUrl}
            name={name}
            trackName="Download subtitle file"
            trackDetails={{ fragmentId, name, path }}
          >
            <Typography variant="h5">{name}</Typography>
            <GetAppIcon />
          </DownloadLink>
          <section className="details">
            <div className="sub-detail">
              <Typography variant="h6">{createDateFormatted}</Typography>
            </div>
            <div className="divider">/</div>
            <div className="sub-detail">
              <Typography variant="h6">{user?.email || user?.name}</Typography>
            </div>
          </section>
        </div>
      </div>
      <div className="column-right">
        <Chip
          variant="outlined"
          size="small"
          label={t(`labels.${status?.toUpperCase()}`)}
        />
        <Fab
          trackName="revision.button.more.menu"
          trackDetails={{ id, fragmentId, name }}
          aria-label="more options"
          onClick={onMoreClick}
        >
          <ExpandMoreIcon />
        </Fab>
        <StyledMenu
          id="fade-menu"
          anchorEl={menuRef}
          keepMounted
          open={open}
          onClose={onMenuClose}
          TransitionComponent={Fade}
        >
          <CanViewMenuItem
            when={[
              REVISION_STATUS.pending,
              REVISION_STATUS.active,
              REVISION_STATUS.inactive,
              REVISION_STATUS.draft,
              REVISION_STATUS.approved,
              REVISION_STATUS.rejected,
            ]}
            status={status}
          >
            <MenuItem
              trackName="revision.menuitem.invite.proofreader"
              trackDetails={{ fragmentId, name, status }}
              onClick={(e) =>
                onMenuItemClick({
                  e,
                  key: "invite.proofreader",
                  id,
                  locale,
                  setMenuRef,
                })
              }
            >
              <FindInPageOutlinedIcon />
              <ListItemText primary="Invite Proofreader" />
            </MenuItem>
          </CanViewMenuItem>
          <MenuItem
            trackName="revision.menuitem.view.in.compare"
            trackDetails={{ fragmentId, name, status }}
            onClick={() =>
              onMenuItemClick({
                key: "view_in_compare",
                id,
                setMenuRef,
              })
            }
          >
            <VisibilityIcon />
            <ListItemText primary="Bekijk in vergelijker" />
          </MenuItem>

          <CanViewMenuItem
            when={[
              REVISION_STATUS.pending,
              REVISION_STATUS.inactive,
              REVISION_STATUS.draft,
              REVISION_STATUS.approved,
            ]}
            status={status}
          >
            <MenuItem
              trackName="revision.menuitem.approve"
              trackDetails={{ fragmentId, name, status }}
              onClick={() =>
                onMenuItemClick({
                  key: "approve",
                  id,
                  setLoading: setApproving,
                  setMenuRef,
                })
              }
            >
              {approving && <CircularProgress size={16} />}
              {!approving && <CheckIcon className="icon-green" />}
              <ListItemText
                primary={
                  status === REVISION_STATUS.pending
                    ? t("buttons.APPROVE")
                    : t("buttons.ACTIVATE")
                }
              />
            </MenuItem>
          </CanViewMenuItem>

          <CanViewMenuItem
            when={[
              REVISION_STATUS.pending,
              REVISION_STATUS.active,
              REVISION_STATUS.draft,
              REVISION_STATUS.approved,
            ]}
            status={status}
          >
            <MenuItem
              trackName="revision.menuitem.reject"
              trackDetails={{ fragmentId, name, status }}
              onClick={() =>
                onMenuItemClick({
                  key: "reject",
                  id,
                  setLoading: setRejecting,
                  setMenuRef,
                })
              }
            >
              {rejecting && <CircularProgress size={16} />}
              {!rejecting && <ClearIcon className="icon-red" />}
              <ListItemText
                primary={
                  status === REVISION_STATUS.pending
                    ? t("buttons.REJECT")
                    : t("buttons.DEACTIVATE")
                }
              />
            </MenuItem>
          </CanViewMenuItem>

          <CanViewMenuItem
            when={[
              REVISION_STATUS.pending,
              REVISION_STATUS.inactive,
              REVISION_STATUS.active,
              REVISION_STATUS.approved,
              REVISION_STATUS.rejected,
            ]}
            status={status}
          >
            <MenuItem
              trackName="revision.menuitem.draft"
              trackDetails={{ fragmentId, name, status }}
              onClick={() =>
                onMenuItemClick({
                  key: "draft",
                  id,
                  setLoading: setMarkAsDraft,
                  setMenuRef,
                })
              }
            >
              {markingAsDraft && <CircularProgress size={16} />}
              {!markingAsDraft && <ReceiptIcon className="icon-orange" />}
              <ListItemText primary={t("buttons.DRAFT")} />
            </MenuItem>
          </CanViewMenuItem>
          <CanViewMenuItem
            when={[
              REVISION_STATUS.pending,
              REVISION_STATUS.active,
              REVISION_STATUS.inactive,
              REVISION_STATUS.draft,
              REVISION_STATUS.approved,
              REVISION_STATUS.rejected,
            ]}
            status={status}
          >
            <MenuItem
              trackName="revision.menuitem.delete"
              trackDetails={{ fragmentId, name, status }}
              onClick={() =>
                onMenuItemClick({
                  key: "delete",
                  id,
                  setLoading: setDeleting,
                  setMenuRef,
                })
              }
            >
              {deleting && <CircularProgress size={16} />}
              {!deleting && <DeleteForeverIcon className="icon-red" />}
              <ListItemText
                primary={t("buttons.DELETE_PERMANENTLY")}
                style={{ color: "red" }}
              />
            </MenuItem>
          </CanViewMenuItem>
        </StyledMenu>
      </div>
    </StyledComponent>
  );
};

Revision.defaultProps = {
  onMenuItemClick: () => {},
};

Revision.propTypes = {
  id: PropTypes.number.isRequired,
  onMenuItemClick: PropTypes.func,
};

export default Revision;
