import { useSelector } from "react-redux";

// Components
import LayoutPlain from "components/LayoutPlain";
import ExternalSubtitler from "features/External/Subtitler";

const Page = () => {
  // redux
  const { channel } = useSelector((state) => state.external);

  return (
    <LayoutPlain channel={channel}>
      <ExternalSubtitler />
    </LayoutPlain>
  );
};

export default Page;
