import { AllInApi } from "features/Common/services/api.service";
import * as Constants from "features/Common/constants";

const api = AllInApi.getApi();

export const getExternalSubtitle = (token) =>
  api.get(`${AllInApi.getApiPath()}/external/subtitle`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadFile = async ({
  authToken,
  acceptedFiles,
  fileId,
  short_description,
  description,
}) => {
  await api.get(Constants.API_AUTH_CSRF_ENDPOINT);
  const url = `${AllInApi.getApiPath()}/external/subtitle`;
  const formData = new FormData();
  formData.append("file", acceptedFiles[0]);

  // Add currently active file_id so that the api could
  // make this file inactive.
  if (fileId) {
    formData.append("file_id", fileId);
  }

  if (short_description) {
    formData.append("short_description", short_description);
  }

  if (description) {
    formData.append("description", description);
  }

  // Create new file
  return api.post(url, formData, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const approveFile = async ({ authToken, file, status }) => {
  await api.get(Constants.API_AUTH_CSRF_ENDPOINT);
  const url = `${AllInApi.getApiPath()}/external/subtitle`;

  // Approve file
  return api.put(
    url,
    {
      file: Number(file),
      status,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};
