import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { updateState as updateStateRequest } from "./redux/fragment.service";
import * as assetActions from "../Asset/Asset.actions";
import {
  getFragment,
  deleteFragment as deleteFragmentRequest,
  getFragmentMetaValues,
  getFragmentLabels as getFragmentLabelsRequest,
  getFragmentFiles,
  getTranslatableFragments,
  uploadFile as uploadFileRequest,
  deleteFile as deleteFileRequest,
  patchFragment as patchFragmentRequest,
  patchFragmentMetaValues as patchFragmentMetaValuesRequest,
  patchFragmentLabels as patchFragmentLabelsRequest,
} from "./services";

import {
  loadFragment,
  loadFragmentSuccess,
  loadFragmentFailed,
  deleteFragment,
  deleteFragmentSuccess,
  deleteFragmentFailed,
  loadTranslatableFragments,
  loadTranslatableFragmentsSuccess,
  loadTranslatableFragmentsFailed,
  patchFragment,
  patchFragmentSuccess,
  patchFragmentFailed,
  patchFragmentField,
  patchFragmentFieldSuccess,
  patchFragmentFieldFailed,
  patchFragmentMetaValues,
  patchFragmentMetaValuesSuccess,
  patchFragmentMetaValuesFailed,
  patchFragmentLabels,
  patchFragmentLabelsSuccess,
  patchFragmentLabelsFailed,
  uploadFile,
  uploadFileSuccess,
  uploadFileFailed,
  deleteFile,
  deleteFileSuccess,
  deleteFileFailed,
  updateState,
  updateStateFailed,
} from "./reducer";

export function* loadFragmentSaga({ payload }) {
  try {
    const { fragmentId } = payload;
    const [
      fragmentResponse,
      filesResponse,
      metaResponse,
      labelsResponse,
    ] = yield all([
      call(getFragment, fragmentId),
      call(getFragmentFiles, { fragmentId, status: null }),
      call(getFragmentMetaValues, fragmentId),
      call(getFragmentLabelsRequest, fragmentId),
    ]);

    yield put(
      loadFragmentSuccess({
        fragment: fragmentResponse.data,
        files: filesResponse.data?.data,
        meta: metaResponse?.data?.data || [],
        labels: labelsResponse?.data?.data || [],
      })
    );
  } catch (error) {
    yield put(loadFragmentFailed(error));
  }
}

export function* deleteFragmentSaga({ payload }) {
  try {
    const { fragmentId, history, parentAssetUrl, assetId } = payload;

    yield call(deleteFragmentRequest, fragmentId);
    yield put(deleteFragmentSuccess());
    yield put(assetActions.findById(Number(assetId), history));

    history.push(parentAssetUrl);
  } catch (error) {
    yield put(deleteFragmentFailed({ error }));
  }
}

export function* loadTranslatableFragmentsSaga() {
  try {
    const result = yield call(getTranslatableFragments);
    yield put(loadTranslatableFragmentsSuccess(result.data));
  } catch (error) {
    yield put(loadTranslatableFragmentsFailed(error));
  }
}

export function* uploadFileSaga({ payload }) {
  const {
    fragmentId,
    fileId,
    typeId,
    acceptedFiles,
    isNew,
    type,
    locale,
  } = payload;
  try {
    const result = yield call(uploadFileRequest, {
      fragmentId,
      typeId,
      type,
      fileId,
      acceptedFiles,
      isNew,
      locale,
    });
    yield put(
      uploadFileSuccess({
        fragmentId,
        typeId,
        type,
        locale,
        fileId,
        newFile: result?.data?.data,
      })
    );
  } catch (error) {
    yield put(
      uploadFileFailed({
        fragmentId,
        typeId,
        fileId,
        error,
        type,
        locale,
      })
    );
  }
}

export function* deleteImageSaga({ payload }) {
  const { fragmentId, fileId, typeId, type, locale } = payload;
  try {
    yield call(deleteFileRequest, {
      fragmentId,
      fileId,
      type,
      locale,
    });
    yield put(
      deleteFileSuccess({
        fragmentId,
        fileId,
        typeId,
        type,
        locale,
      })
    );
  } catch (error) {
    yield put(
      deleteFileFailed({
        fragmentId,
        typeId,
        fileId,
        error,
        type,
        locale,
      })
    );
  }
}

export function* updateStateSaga({ payload }) {
  const globalState = yield select();
  const asset = globalState?.asset?.selected?.data || {};
  const { channelId, fragmentId, state, previousState } = payload;

  try {
    const response = yield call(updateStateRequest, channelId, fragmentId, {
      state,
    });

    // Update asset.fragment
    const newFragmentIndex = asset.fragments.findIndex(
      (f) => f.id === +fragmentId
    );
    const newFragments = [...asset.fragments];
    newFragments[newFragmentIndex] = response.data;

    const newAsset = {
      ...asset,
      fragments: newFragments,
    };

    // Update asset
    yield put(assetActions.findByIdSuccess(newAsset));
  } catch (error) {
    // Revert changes
    yield put(updateStateFailed({ state: previousState }));
  }
}

export function* patchFragmentFieldSaga({ payload }) {
  const { patchFieldKey, id, fields, onComplete } = payload;

  try {
    const result = yield call(patchFragmentRequest, id, fields);
    yield put(patchFragmentFieldSuccess({ patchFieldKey, ...result.data }));
    if (onComplete) {
      yield call(onComplete);
    }
  } catch (error) {
    yield put(patchFragmentFieldFailed({ patchFieldKey, error }));
  }
}

export function* patchFragmentSaga({ payload }) {
  try {
    const { id, fields, onComplete } = payload;
    const result = yield call(patchFragmentRequest, id, fields);
    yield put(patchFragmentSuccess(result.data));
    if (onComplete) {
      yield call(onComplete);
    }
  } catch (error) {
    yield put(patchFragmentFailed(error));
  }
}

export function* patchFragmentMetaValuesSaga({ payload }) {
  try {
    const { id, fields } = payload;
    const result = yield call(patchFragmentMetaValuesRequest, id, fields);
    yield put(patchFragmentMetaValuesSuccess(result?.data?.data));
  } catch (error) {
    yield put(patchFragmentMetaValuesFailed(error));
  }
}

export function* patchFragmentLabelsSaga({ payload }) {
  try {
    const { id, labels } = payload;
    const result = yield call(patchFragmentLabelsRequest, id, {
      labels: labels || [],
    });
    yield put(patchFragmentLabelsSuccess({ labels: result?.data?.data }));
  } catch (error) {
    yield put(patchFragmentLabelsFailed(error));
  }
}

function* sagas() {
  return yield all([
    takeLatest(loadFragment.type, loadFragmentSaga),
    takeLatest(deleteFragment.type, deleteFragmentSaga),
    takeLatest(loadTranslatableFragments.type, loadTranslatableFragmentsSaga),
    takeLatest(loadFragment.type, loadFragmentSaga),
    takeLatest(uploadFile.type, uploadFileSaga),
    takeLatest(deleteFile.type, deleteImageSaga),
    takeLatest(updateState.type, updateStateSaga),
    takeLatest(patchFragment.type, patchFragmentSaga),
    takeLatest(patchFragmentField.type, patchFragmentFieldSaga),
    takeLatest(patchFragmentMetaValues.type, patchFragmentMetaValuesSaga),
    takeLatest(patchFragmentLabels.type, patchFragmentLabelsSaga),
  ]);
}

export default sagas;
