import styled from "styled-components";

const StyledComponent = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  margin-bottom: -${({ theme }) => theme.spacing(1.5)}px;
  border-top: 2px solid #404040;
  background: #2f2f2f;
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px`};

  .overlay {
    background: #000000db;
  }

  .action-right-button {
    display: flex;
    flex-direction: column;

    .buttons {
      display: flex;

      & > div {
        flex: 1;
      }
    }

    .remoteFile {
      margin-top: ${({ theme }) => theme.spacing(1.5)}px;

      .MuiButton-label {
        text-transform: lowercase;
      }
    }

    .archiveButton {
      margin-right: ${({ theme }) => theme.spacing(1.5)}px;
    }
  }

  .settings {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    color: white;
    padding: 0 100px;
    margin-bottom: 8px;

    .MuiCheckbox-colorPrimary {
      color: white;
    }

    .MuiCheckbox-colorPrimary.Mui-checked {
      color: ${({ theme }) => theme.palette.primary.main}!important;
    }

    .MuiFormControl-root {
      margin-right: 4px;
    }

    .video-selector {
      margin-right: 16px;
    }

    label + .MuiInput-formControl {
      margin-top: 0;
    }

    .MuiSelect-icon,
    .MuiInputBase-root {
      color: white;
    }

    .MuiInput-underline:before {
      border-bottom-color: white;
    }
  }

  .action-buttons {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    margin: 24px 0 12px 0;

    .actions {
      display: flex;
      flex-direction: row;
    }

    .action-button {
      margin: 0 4px;

      & .Mui-disabled {
        color: white;
      }
    }
  }

  .MuiLinearProgress-root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;
export default StyledComponent;
