import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Redux
import { patchFragmentLabels } from "features/Fragment/reducer";

// Hooks
import { useCurrentFragmentUrl } from "features/Fragment/hooks";

// Components
import LabelsForm from "components/LabelsForm";

// Style
import StyledComponent from "./style";

const FragmentLabelsEdit = ({ fragment }) => {
  const { id, name, labels } = fragment;

  const dispatch = useDispatch();

  // Redux
  const networkState = useSelector(
    (state) => state.fragment?.networkState?.labels
  );

  // Routing
  const closeUrl = useCurrentFragmentUrl({ fragmentId: id });
  const history = useHistory();

  // Form
  const trackDetails = useMemo(
    () => ({
      fragmentId: id,
      fragmentName: name,
    }),
    [id, name]
  );

  const onSubmit = (data) => {
    const { labels } = data;
    dispatch(
      patchFragmentLabels({
        id,
        labels,
      })
    );
  };

  const onCancel = () => {
    history.push(closeUrl);
  };

  if (!fragment) return null;
  return (
    <StyledComponent>
      <LabelsForm
        trackDetails={trackDetails}
        onSubmit={onSubmit}
        onCancel={onCancel}
        labels={labels}
        loading={networkState === "updating"}
      />
    </StyledComponent>
  );
};

FragmentLabelsEdit.propTypes = {};

export default FragmentLabelsEdit;
