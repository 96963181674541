import {
  useCallback,
  useEffect,
  cloneElement,
  createContext,
  useContext,
  forwardRef,
  useRef,
  isValidElement,
  memo,
  Children,
} from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

// Utils
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

// Components
// import Checkbox from 'components/Form/Checkbox';
import { AutoCompleteWithControl } from "components/Form/AutoComplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";

// Style
import { StyledList, StyledOption } from "./style";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

OuterElementType.displayName = "OuterElementType";

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <StyledList ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </StyledList>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const SelectWithAutoComplete = ({
  id,
  name,
  options,
  values,
  appendable,
  multiple,
  label,
  errors,
}) => {
  const { control } = useFormContext();

  const createOutputFormatter = useCallback(({ value, newOption }) => {
    const newOptionValue = newOption?.option;

    // Weird autocomplete bug, remove duplicates.
    const index = value.findIndex((item) => item === newOptionValue);
    if (index > -1) {
      value.splice(index, 1);
    }

    return [...value, { name: newOptionValue, value: newOptionValue }];
  }, []);

  return (
    <AutoCompleteWithControl
      id={`auto-complete-${id}`}
      name={`${id}`}
      label={label || name}
      value={values}
      freeSolo={Boolean(appendable)}
      options={options}
      ListboxComponent={ListboxComponent}
      limitTags={3}
      disableListWrap
      disableCloseOnSelect={multiple}
      variant="outlined"
      className="inputField"
      multiple={Boolean(multiple)}
      control={control}
      error={errors}
      placeholder={appendable ? "toevoegen" : "zoeken"}
      createOutputFormatter={createOutputFormatter}
      getOptionLabel={(option) => option?.name || option?.value || ""}
      getOptionSelected={(option, value) => option?.id === value?.id}
      renderOption={(option, props) => {
        // const { inputValue, selected, id: optionId } = props;
        const { inputValue } = props;
        const value = option?.name || option?.value;
        const matches = match(value, inputValue);
        const parts = parse(value, matches);
        return (
          <StyledOption>
            {/* <Checkbox
              name={`${optionId}`}
              color="primary"
              style={{ marginRight: 8 }}
              checked={selected}
            /> */}
            {parts.map((part, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </StyledOption>
        );
      }}
    />
  );
};

export default memo(SelectWithAutoComplete);
