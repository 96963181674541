import grey from "@material-ui/core/colors/grey";
import styled from "styled-components";

export const StyledThumbs = styled.aside``;

export default styled.section`
  &:focus {
    border: 1px dashed blue;
    outline: none;
  }

  &.drag-over.drop-zone {
    & > div {
      border: 1px dashed ${({ theme }) => theme.palette.success.main};
      background: ${({ theme }) => theme.palette.success.dark};
    }

    & .drop-zone > div {
      opacity: 0.3;
    }

    & .text {
      color: white !important;
    }
  }

  & .drop-zone {
    position: relative;
    display: flex;
    background-color: ${grey[200]};
    border: 1px dashed ${grey[500]};
    cursor: pointer;
    border-radius: 4px;
    min-height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
    outline: none;

    & .MuiLinearProgress-root {
      background: transparent;
      position: absolute;
      height: 4px;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
    }

    &.disabled {
      opacity: 0.45;
    }

    & .check-mark-root {
      position: absolute;
      z-index: 9999;
    }
  }

  &.completed .text,
  &.loading .text {
    opacity: 0.25;
  }

  & .text {
    text-align: center;
    color: ${grey[700]};
    margin: 4px;
  }

  &.error > .drop-zone {
    border: 1px dashed red;
  }

  & .error {
    color: red;
    background: none !important;
    padding: 8px 4px;
  }

  & .error-box {
    margin-bottom: 12px;
    background: none;

    & h6 {
      font-size: 0.75rem;
      margin: 4px 0;
    }

    & p {
      margin: 0;
    }
  }
`;
