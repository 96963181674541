import { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

import Select from "@material-ui/core/Select";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";

import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ReplayIcon from "@material-ui/icons/Replay";

import styles from "./Jobs.styles";

const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

class ListHeaderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterAnchor: null,
      menuAnchor: null,
      filters: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { jobs } = this.props;
    const { jobs: prevJobs } = prevProps;

    if (!prevJobs && jobs && jobs.filter) this.setFilters(jobs.filter);
  }

  setFilters = (filters) => this.setState({ filters });

  handleFilterOpen = (event) =>
    this.setState({ filterAnchor: event.currentTarget });

  handleFilterClose = () => this.setState({ filterAnchor: null });

  handleMenuOpen = (event) =>
    this.setState({ menuAnchor: event.currentTarget });

  handleMenuClose = () => this.setState({ menuAnchor: null });

  renderFilter() {
    const { classes, t, setFilter, filterValues } = this.props;
    const { filterAnchor, filters } = this.state;

    return (
      <Popover
        open={Boolean(filterAnchor)}
        anchorEl={filterAnchor}
        onClose={this.handleFilterClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <Typography color="textPrimary" className={classes.typography}>
          {t("labels.FILTER_LIST")}
        </Typography>

        <Divider />

        {Object.keys(filters).length > 0 &&
          Object.keys(filters).map((key) => (
            <div key={key} className={classes.row}>
              <Typography color="textSecondary" className={classes.typography}>
                {t(`labels.${key.toUpperCase()}`)}
              </Typography>
              <FormControl className={classes.formControl}>
                <Select
                  multiple
                  value={filterValues[key] || []}
                  onChange={(event) => setFilter([key], event.target.value)}
                  displayEmpty
                  input={<Input name={key} id={key} />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return t("text.ALL");
                    }

                    return selected
                      .map((select) =>
                        key === "encode_version"
                          ? `${select}`
                          : `${t(
                              `status.jobs.${String(select).toUpperCase()}`
                            )}`
                      )
                      .join(", ");
                  }}
                  MenuProps={MenuProps}
                >
                  {filters[key].map((filter) => (
                    <MenuItem key={filter} value={filter}>
                      <Checkbox
                        checked={
                          filterValues[key] &&
                          filterValues[key].indexOf(filter) > -1
                        }
                      />
                      <ListItemText
                        primary={
                          key === "encode_version"
                            ? `${filter}`
                            : `${t(
                                `status.jobs.${String(filter).toUpperCase()}`
                              )}`
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ))}
      </Popover>
    );
  }

  renderMenu() {
    const { t, selected, restartJobs } = this.props;
    const { menuAnchor } = this.state;

    const open = Boolean(menuAnchor);

    return (
      <>
        <IconButton
          aria-haspopup="true"
          onClick={this.handleMenuOpen}
          disabled={selected.length === 0}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="menu"
          anchorEl={menuAnchor}
          open={open}
          onClose={this.handleMenuClose}
          {...MenuProps}
        >
          <MenuItem
            onClick={() => {
              this.handleMenuClose();
              restartJobs();
            }}
          >
            <ListItemIcon>
              <ReplayIcon />
            </ListItemIcon>
            {`${t("text.RESTART")} (${selected.length}) ${
              selected.length > 1
                ? t("titles.JOB").toLowerCase()
                : t("labels.JOB").toLowerCase()
            }`}
          </MenuItem>
        </Menu>
      </>
    );
  }

  render() {
    const { classes, t, hasActiveFilter, jobs } = this.props;

    return (
      <div className={classes.topHeader}>
        <div className={classes.title}>
          {t("titles.JOB")}{" "}
          {jobs && (
            <span>
              ({jobs.data.length} / {jobs.meta.total})
            </span>
          )}
        </div>

        <Tooltip title={t("labels.FILTER_LIST")}>
          <IconButton onClick={this.handleFilterOpen}>
            <FilterListIcon color={hasActiveFilter() ? "primary" : "action"} />
          </IconButton>
        </Tooltip>

        {this.renderFilter()}

        {this.renderMenu()}
      </div>
    );
  }
}

ListHeaderComponent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  hasActiveFilter: PropTypes.func.isRequired,
  filterValues: PropTypes.shape({}),
  jobs: PropTypes.shape({}),
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  restartJobs: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  withStyles(styles)
)(ListHeaderComponent);
