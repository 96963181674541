import * as Sentry from "@sentry/browser";

const sentryUrl = process.env.REACT_APP_SENTRY_URL;
const environment = process.env.NODE_ENV;

const sentrySetup = () => {
  if (!sentryUrl) {
    // eslint-disable-next-line no-console
    console.warn("Sentry url APP_SENTRY_URL not found.");

    return;
  }

  Sentry.init({ dsn: sentryUrl, environment });
};

export default sentrySetup;
