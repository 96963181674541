import { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

// Components
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/Button/Button";
import { CheckboxWithControl } from "components/Form/Checkbox";

// Services
import { ingestFragment } from "../services";
import { getCurrentFragment } from "../selectors";

const defaultValues = {
  image: false,
  movie: false,
  trailer: false,
};

const FragmentIngestDialog = ({ open, onClose }) => {
  const { data: fragment } = useSelector(getCurrentFragment);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control } = useForm({
    defaultValues,
  });

  const onSubmit = (formData) => {
    setLoading(true);
    ingestFragment(fragment.id, formData, loading)
      .then(() => {
        onClose();
        setLoading(false);
        enqueueSnackbar(t("text.INGEST_SUCCESS"), { variant: "success" });
      })
      .catch(() => {
        onClose();
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("text.WOULD_YOU")}
        {fragment.name}
        {t("text.INGEST")}?
      </DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText style={{ color: "black" }}>
            {t("text.INGEST_FRAGMENT_CONFIRMATION")}
            {fragment.name}
            {t("text.WOULD_INGEST")}?
          </DialogContentText>
          <DialogContentText style={{ color: "red" }}>
            <Alert severity="error">
              {t("text.ACTION_CANNOT_BE_UNDONE")}
              !
              <br />
              {t("text.ALL_DATA_WILL_BE_OVERWRITTEN")}!
            </Alert>
          </DialogContentText>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <CheckboxWithControl
              name="movie"
              control={control}
              color="primary"
              label={t("labels.ADD_VIDEO")}
              display="flex"
            />
            <CheckboxWithControl
              name="image"
              control={control}
              color="primary"
              label={t("labels.ADD_IMAGE")}
              display="flex"
            />
            <CheckboxWithControl
              name="trailer"
              control={control}
              color="primary"
              label={t("labels.ADD_TRAILER")}
              display="flex"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            trackName="Ingest Cancel"
            trackDetails={{
              fragmentId: fragment?.id,
              fragmentName: fragment?.name,
            }}
          >
            {t("text.NO")}
          </Button>
          <Button
            type="submit"
            color="primary"
            loading={loading}
            trackName="Ingest"
            trackDetails={{
              fragmentId: fragment?.id,
              fragmentName: fragment?.name,
            }}
          >
            {t("text.YES")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(FragmentIngestDialog);
