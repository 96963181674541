import styled from "styled-components";

export default styled.div`
  header {
    padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(2)}px`};
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-wrapper {
      display: flex;
      flex-direction: row;

      & .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.grey[800]};
        margin-right: ${({ theme }) => theme.spacing(1)}px;
      }
    }

    & h6 {
      color: ${({ theme }) => theme.palette.grey[800]};
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.025em;
    }

    .MuiIconButton-root {
      padding: 12px 4px;
      border-radius: 4px;
    }
  }

  .content {
    padding: ${({ theme }) =>
      `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`};
    margin-bottom: 8px;
  }
  .content.collapsed {
    display: none;
  }

  .actions {
    display: flex;
    flex-wrap: nowrap;
  }
`;
