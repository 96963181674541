/* eslint-disable camelcase */
import { useState, useMemo, memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// UI
import {
  Card,
  IconButton,
  CardContent,
  Typography,
  Chip,
  Grid,
} from "@material-ui/core";

// Icons
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";
import EditIcon from "@material-ui/icons/Edit";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import CropIcon from "@material-ui/icons/Crop";
import ForwardIcon from "@material-ui/icons/Forward";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import MovieCreationOutlinedIcon from "@material-ui/icons/MovieCreationOutlined";
import MovieFilterIcon from "@material-ui/icons/MovieFilter";
import ClosedCaptionIcon from "@material-ui/icons/ClosedCaption";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
// Component
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DownloadIcon from "@material-ui/icons/GetApp";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "components/Button";
import NationFlag from "components/NationFlag";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Can } from "features/Auth/AccessControl";
import { useJob } from "../FragmentJobs/JobProvider";
import ActiveJob from "../FragmentJobs/ActiveJob";

// Style
import StyledComponent, { StyledMenu } from "./style";

// Utils
import { convertSecondsToHuman } from "../utils";

const ActiveJobs = () => {
  const { jobs } = useJob();

  return jobs
    .filter((job) => job.status !== "success")
    .map((job) => <ActiveJob hideBg small key={job.id} job={job} />);
};

const VideoDetails = ({
  fragment,
  fragmentFiles,
  menuItems,
  videoDurationMs,
  onMenuItemClick,
  onEditCuePointsClick,
  onOpenSubtitleViewerClick,
  onEditClick,
  active,
  activeLocale,
}) => {
  const { t } = useTranslation();
  const [menuRef, setMenuRef] = useState(null);
  const open = Boolean(menuRef);
  const activeSubtitles =
    fragmentFiles?.filter(
      (file) => file.type.type === "subtitle" && file.status === "active"
    ) || [];

  const jobsFilter = useMemo(
    () => fragment.jobs.filter((job) => job.status !== "success"),
    [fragment]
  );

  // eslint-disable-next-line camelcase
  const fadeIn = fragment?.fade_in;
  // eslint-disable-next-line camelcase
  const fadeOut = fragment?.fade_out;

  const openActionMenu = (event) => {
    setMenuRef(event.currentTarget);
  };

  const handleClick = (key) => {
    setMenuRef(null);
    if (key) {
      onMenuItemClick(key);
    }
  };

  const onVideoDetailClick = (event) => {
    const { currentTarget } = event;
    onEditCuePointsClick(currentTarget?.id);
  };

  const onVideoTimeClick = (event) => {
    event.stopPropagation();
    const { currentTarget } = event;
    onEditCuePointsClick(currentTarget?.id);
  };

  const duration = useMemo(
    () => (fadeOut || videoDurationMs) - (fadeIn || 0),
    [fadeIn, fadeOut, videoDurationMs]
  );

  const timeStamps = useMemo(
    () => [
      {
        key: "duration",
        title: t("labels.DURATION"),
        icon: <AvTimerIcon />,
        values: {
          start: {
            key: "duration_start",
            value: duration,
          },
        },
      },
      {
        key: "fade",
        title: t("labels.FADE"),
        icon: <CropIcon />,
        values: {
          start: {
            key: "fade_in",
            value: fragment.fade_in,
          },
          end: {
            key: "fade_out",
            value: fragment.fade_out,
          },
        },
      },
      {
        key: "intro",
        title: t("labels.INTRO"),
        icon: <ForwardIcon />,
        values: {
          start: {
            key: "intro_in",
            value: fragment.intro_in,
          },
          end: {
            key: "intro_out",
            value: fragment.intro_out,
          },
        },
      },
      {
        key: "credits",
        title: t("labels.CREDITS"),
        icon: <RemoveFromQueueIcon />,
        values: {
          start: {
            key: "credits_in",
            value: fragment.credits_in,
          },
        },
      },
      {
        key: "snapshot",
        title: t("labels.SNAPSHOT"),
        icon: <CameraAltIcon />,
        values: {
          start: {
            key: "snapshot_start",
            value: fragment.snapshot_time,
          },
        },
      },
    ],
    [duration, fragment, t]
  );

  return (
    <StyledComponent>
      <Card>
        <header>
          <div />
          <aside>
            <IconButton
              size="small"
              aria-label="edit button"
              onClick={onEditClick}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              aria-label="more settings"
              onClick={openActionMenu}
            >
              <MoreHorizIcon />
            </IconButton>
          </aside>
        </header>

        {menuItems && (
          <StyledMenu
            id="fade-menu"
            anchorEl={menuRef}
            keepMounted
            open={open}
            onClose={() => handleClick()}
            TransitionComponent={Fade}
          >
            {menuItems.map(({ key, icon, title, can }) => {
              let menu = (
                <MenuItem onClick={() => handleClick(key)} key={key}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={t(title)} />
                </MenuItem>
              );

              if (key === "archive") {
                if (fragment.archived) {
                  menu = (
                    <MenuItem
                      onClick={() => handleClick("unarchive")}
                      key={key}
                    >
                      <ListItemIcon>
                        <UnarchiveIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("buttons.UNARCHIVE")} />
                    </MenuItem>
                  );
                } else {
                  menu = (
                    <MenuItem onClick={() => handleClick("archive")} key={key}>
                      <ListItemIcon>
                        <ArchiveIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("buttons.ARCHIVE")} />
                    </MenuItem>
                  );
                }
              }

              if (can) {
                return (
                  <Can I={can.actions} an={can.subject} key={key}>
                    {menu}
                  </Can>
                );
              }

              return menu;
            })}
          </StyledMenu>
        )}
        <CardContent>
          <div className="container">
            <div className="column-left">
              <Grid container>
                <Grid item md={jobsFilter.length > 0 ? 6 : 12} sm={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        {timeStamps?.map(({ key, icon, title, values }) => (
                          <TableRow
                            key={key}
                            id={key}
                            onClick={onVideoDetailClick}
                          >
                            <TableCell
                              className="label-cell"
                              component="td"
                              scope="row"
                            >
                              <div>
                                {icon}
                                <Typography variant="h6">{title}</Typography>
                              </div>
                            </TableCell>
                            {values.start && (
                              <TableCell
                                key={values.start.key}
                                component="td"
                                style={{ width: "55px" }}
                                scope="row"
                              >
                                <Chip
                                  size="small"
                                  name={values.start.key}
                                  id={values.start.key}
                                  onClick={onVideoTimeClick}
                                  color={
                                    active === values.start.key
                                      ? "primary"
                                      : "default"
                                  }
                                  label={convertSecondsToHuman(
                                    values.start.value || 0,
                                    true
                                  )}
                                />
                              </TableCell>
                            )}
                            {values.end && (
                              <>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  style={{
                                    padding: "0",
                                    width: "12px",
                                    textAlign: "center",
                                  }}
                                >
                                  -
                                </TableCell>
                                <TableCell
                                  key={values.end.key}
                                  component="td"
                                  scope="row"
                                >
                                  <Chip
                                    size="small"
                                    name={values.end.key}
                                    id={values.end.key}
                                    onClick={onVideoTimeClick}
                                    color={
                                      active === values.end.key
                                        ? "primary"
                                        : "default"
                                    }
                                    label={convertSecondsToHuman(
                                      values.end.value || 0,
                                      true
                                    )}
                                  />
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {jobsFilter.length > 0 && (
                  <Grid item md={6} sm={12}>
                    <div>
                      <ActiveJobs />
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
            <div className="column-right">
              <section className="settings">
                <header>
                  <ClosedCaptionIcon />
                  <Typography variant="h5">{t("labels.CAPTIONS")}</Typography>
                </header>
                <div style={{ display: "flex" }}>
                  {activeSubtitles?.map(({ id, locale, status }) => (
                    <Button
                      trackName="VideoDetails subtitle"
                      trackDetails={{
                        fragment,
                        locale,
                      }}
                      className={status}
                      key={id}
                      variant="text"
                      color={locale === activeLocale ? "primary" : "default"}
                      onClick={() => onOpenSubtitleViewerClick(locale)}
                    >
                      <div>
                        <NationFlag countryCode={locale} />
                        {locale}
                      </div>
                    </Button>
                  ))}
                  {!activeSubtitles?.length && (
                    <Typography className="no-captions">
                      {t("labels.NO_CAPTIONS")}
                    </Typography>
                  )}
                </div>
              </section>
              <section className="settings badges">
                <header>
                  <AvTimerIcon />
                  <Typography variant="h5">
                    {t("labels.VIDEO_SETTINGS")}
                  </Typography>
                </header>
                <Chip
                  size="small"
                  name="drm"
                  onClick={onVideoDetailClick}
                  color={fragment?.drm ? "primary" : "default"}
                  icon={
                    fragment?.drm ? (
                      <VerifiedUserIcon className="active-icon" />
                    ) : (
                      <VerifiedUserOutlinedIcon />
                    )
                  }
                  label={
                    fragment?.drm
                      ? t("labels.DRM_ENABLED")
                      : t("labels.DRM_DISABLED")
                  }
                />
                <Chip
                  size="small"
                  color={fragment?.trailer ? "primary" : "default"}
                  name="trailer"
                  onClick={onVideoDetailClick}
                  icon={
                    fragment?.trailer ? (
                      <MovieFilterIcon className="active-icon" />
                    ) : (
                      <MovieCreationOutlinedIcon
                        fontSize="small"
                        style={{ fontSize: "1.55rem" }}
                      />
                    )
                  }
                  label={
                    fragment?.trailer ? t("labels.TRAILER") : t("labels.VIDEO")
                  }
                />
                <Chip
                  size="small"
                  color={fragment?.downloadable ? "primary" : "default"}
                  name="downloadable"
                  onClick={onVideoDetailClick}
                  icon={
                    fragment?.downloadable ? (
                      <DownloadIcon className="active-icon" />
                    ) : (
                      <DownloadIcon
                        fontSize="small"
                        style={{ fontSize: "1.55rem" }}
                      />
                    )
                  }
                  label={
                    fragment?.downloadable
                      ? t("labels.OFFLINE_AVAILABLE")
                      : t("labels.ONLY_ONLINE")
                  }
                />
              </section>
            </div>
          </div>
        </CardContent>
      </Card>
    </StyledComponent>
  );
};

VideoDetails.defaultProps = {
  menuItems: [],
  fragmentFiles: [],
  videoDurationMs: 0,
  onMenuItemClick: () => {},
  onEditCuePointsClick: () => {},
  onEditClick: () => {},
  onOpenSubtitleViewerClick: () => {},
};

VideoDetails.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string,
      icon: PropTypes.node,
    })
  ),
  fragmentFiles: PropTypes.arrayOf(PropTypes.shape({})),
  videoDurationMs: PropTypes.number,
  active: PropTypes.string,
  onMenuItemClick: PropTypes.func,
  onEditCuePointsClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onOpenSubtitleViewerClick: PropTypes.func,
};

export default memo(VideoDetails);
