import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const RedirectOldAllin = (props) => {
  const history = useHistory();

  useEffect(() => {
    window.open("https:/old.allin.tools" + history.location.pathname, "_blank");
    history.push("/channel/" + props.channel.data.id + "/settings/job");
  });
  return (
    <div>
      <h1>Linking to old.allin</h1>
    </div>
  );
};

export default RedirectOldAllin;
