import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

// Icons
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";

// Components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Checkbox } from "components/Form";

import SidebarGroup from "../../AssetDetailSidebarGroup";

// Redux
import { updateQualityChecks } from "../../Asset.actions";

// Styles
import StyledComponent, { StyledMenu } from "./style";

const CHECK_STATES = {
  absent: {
    index: 0,
    type: null,
    checked: false,
  },
  checked: {
    index: 1,
    type: "checked",
    checked: true,
  },
  inapplicable: {
    index: 2,
    type: "inapplicable",
    checked: false,
    indeterminate: true,
  },
};

const Check = ({ id, name, initialType, onChange }) => {
  const { t } = useTranslation();
  const [state, setState] = useState(
    CHECK_STATES[initialType] || CHECK_STATES.absent
  );

  const [menuAnchor, setMenuAnchor] = useState(null);

  useEffect(() => {
    const newState = CHECK_STATES[initialType] || CHECK_STATES.absent;
    setState(newState);
  }, [initialType, setState]);

  const updateState = (newState) => {
    setState(newState);
    onChange(id, newState.type);
  };

  const onCheckBoxChange = () => {
    const { index } = state;
    const checkStates = Object.values(CHECK_STATES);

    let newStateIndex = index + 1;
    newStateIndex %= checkStates.length;

    const newState = checkStates.find((s) => s.index === newStateIndex);
    updateState(newState);
  };

  const onMenuItemClick = (type) => {
    const newState = CHECK_STATES[type || "absent"];
    updateState(newState);
    setMenuAnchor(null);
  };

  const onMenuOpen = (e) => {
    setMenuAnchor(e.currentTarget);
  };

  const onMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <ListItem>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            color="default"
            checked={state.checked}
            onChange={onCheckBoxChange}
            indeterminate={state.indeterminate}
          />
        }
        label={name}
      />
      <ExpandMoreIcon className="menuIcon" onClick={onMenuOpen} />
      <StyledMenu
        id={id}
        anchorEl={menuAnchor}
        open={menuAnchor !== null}
        onClose={onMenuClose}
      >
        <Typography className="menuHeader" variant="caption" gutterBottom>
          {name}
        </Typography>
        <MenuItem
          selected={state.type === "checked"}
          value="checked"
          onClick={() => onMenuItemClick("checked")}
        >
          {t("labels.PRESENT")}
        </MenuItem>
        <MenuItem
          selected={state.type === null}
          onClick={() => onMenuItemClick(null)}
        >
          {t("labels.ABSENT")}
        </MenuItem>
        <MenuItem
          selected={state.type === "inapplicable"}
          value="inapplicable"
          onClick={() => onMenuItemClick("inapplicable")}
        >
          {t("labels.INAPPLICABLE")}
        </MenuItem>
      </StyledMenu>
    </ListItem>
  );
};

const headerIcon = <AssignmentTurnedInOutlinedIcon />;
const QualityChecks = ({ channel, asset }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const title = t("labels.QUALITY_CHECK");
  const { checks: channelChecks } = channel?.data;
  const { checks: assetChecks } = asset;
  const qualityCheck = asset.quality_check;

  const channelCheckArray = Object.values(channelChecks);

  const onChange = (id, type) => {
    const check = {
      ...channelChecks[id],
      type,
    };

    dispatch(
      updateQualityChecks(asset?.id, {
        id: asset?.id,
        check,
      })
    );
  };

  if (!qualityCheck) return null;
  return (
    <SidebarGroup name="quality-checks" title={title} headerIcon={headerIcon}>
      <StyledComponent>
        {assetChecks && (
          <List>
            {channelCheckArray.map(({ id, name }) => {
              const assetCheck = assetChecks[id];
              const type = assetCheck?.type;
              return (
                <Check
                  key={id}
                  id={id}
                  name={name}
                  initialType={type}
                  onChange={onChange}
                />
              );
            })}
          </List>
        )}
      </StyledComponent>
    </SidebarGroup>
  );
};

export default QualityChecks;
