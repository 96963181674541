const BAR_HEIGHT = 22;

const style = ({ palette }) => ({
  bar: {
    height: BAR_HEIGHT,
    width: "100%",
    backgroundColor: "#66a7dc",
    borderRadius: 4,
    position: "relative",
  },

  done: {
    backgroundColor: palette.primary.main,
    borderRadius: 4,
    height: BAR_HEIGHT,
    textAlign: "center",
    position: "relative",
  },

  text: {
    margin: "0px auto",
    marginTop: `-${BAR_HEIGHT}px`,
    width: "100%",
    height: BAR_HEIGHT,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontWeight: "bold",
  },
});

export default style;
