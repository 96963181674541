/* Components */
import Layout from "../../components/Layout";

/* Features */
import TranslationForm from "../../features/Translation/TranslationForm";

const Page = ({ routes, ...props }) => (
  <Layout routes={routes}>
    <TranslationForm {...props} />
  </Layout>
);

export default Page;
