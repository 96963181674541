import styled from "styled-components";
import TableCell from "@material-ui/core/TableCell";
import { Table } from "react-virtualized";

export const StyledTable = styled(Table)`
  & .ReactVirtualized__Grid {
    outline: none;
  }

  & .ReactVirtualized__Table__headerColumn {
    margin-right: 0;
  }

  & .ReactVirtualized__Table__row {
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.palette.grey[100]};
    }

    &.odd {
      background-color: ${({ theme }) => theme.palette.grey[100]};

      &:hover {
        background-color: ${({ theme }) => theme.palette.grey[200]};
      }
    }
  }

  .ReactVirtualized__Table__rowColumn {
    display: flex;
    overflow: visible !important;
    box-sizing: border-box;
    align-items: center;
    margin-right: 0;
  }
`;

export const StyledHeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    display: flex;
    flex: 1;
    font-weight: bold;
    box-sizing: border-box;
  }
`;

export const StyledCell = styled(TableCell)`
  &.MuiTableCell-root {
    display: flex;
    flex: 1;
    box-sizing: border-box;
  }
`;
