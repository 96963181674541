import { forwardRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MomentUtils from "@date-io/moment";

/* Components */
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const DatePicker = forwardRef(
  (
    {
      className,
      error,
      required,
      disabled,
      variant,
      format,
      value,
      onChange,
      label,
      id,
      maxDate,
      minDate,
      views,
      name,
      timestamp,
      fullWidth,
      inputFormatter,
      size,
    },
    ref
  ) => {
    const inputValue = inputFormatter(value) || null;
    return (
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <FormControl
          className={className}
          error={!!error}
          disabled={disabled}
          required={required}
          variant={variant}
          size="small"
          fullWidth={fullWidth}
        >
          <KeyboardDatePicker
            name={name}
            inputVariant={variant}
            format={format}
            id={id}
            label={label}
            size={size}
            value={
              timestamp && inputValue ? moment.unix(inputValue) : inputValue
            }
            ref={ref}
            onChange={onChange}
            disabled={disabled}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            maxDate={timestamp && maxDate ? moment.unix(maxDate) : maxDate}
            minDate={timestamp && minDate ? moment.unix(minDate) : minDate}
            views={views}
            error={!!error}
            autoOk
            variant="inline"
          />
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </MuiPickersUtilsProvider>
    );
  }
);

DatePicker.displayName = "DatePicker";
DatePicker.defaultProps = {
  format: "DD-MM-YYYY",
  id: "date-picker-inline",
  onChange: () => null,
  inputFormatter: (value) => value,
  label: "Date",
  timestamp: false,
  fullWidth: false,
  size: null,
};

DatePicker.propTypes = {
  format: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  onChange: PropTypes.func,
  views: PropTypes.oneOf(["year", "date", "month"]),
  timestamp: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputFormatter: PropTypes.func,
  size: PropTypes.string,
};

export default DatePicker;
