import { AllInApi } from "../Common/services/api.service";

const api = AllInApi.getApi();

export const getFragment = (fragmentId) =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}`);

export const getFragmentMetaValues = (fragmentId) =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/value`);

export const getFragmentLabels = (fragmentId) =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/label`);

export const deleteFragment = (fragmentId) =>
  api.delete(`${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}`);

export const getFragmentFiles = ({
  fragmentId,
  status = ["active", "draft"],
  type,
  locale,
}) =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/file`, {
    params: {
      status,
      type,
      locale,
    },
  });

export const getPreviouslyUsedEmailAddresses = () =>
  api.get(`${AllInApi.getApiPath()}/token/email`);

export const getNextFragment = () =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/fragment/next`);

export const getTranslatableFragments = () =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/fragment/translate`);

export const lockFragment = (fragmentId) =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/lock`);

export const getFileRevisions = ({ fragmentId, locale }) =>
  api.get(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/token`,
    {
      params: { type: "subtitle", locale },
    }
  );

export const changeRevisionStatus = async ({
  fragmentId,
  fileId,
  locale,
  status,
}) => {
  await api.put(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/file/${fileId}`,
    {
      status,
      _method: "PATCH",
    }
  );

  // Get updated list of revisions
  return getFragmentFiles({
    fragmentId,
    locale,
    type: "subtitle",
    status: null,
  });
};

export const getTranslators = ({ fragmentId, locale, type }) =>
  api.get(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/token`,
    {
      params: { type, locale },
    }
  );

export const inviteTranslator = async ({
  fragmentId,
  type,
  locale,
  fileId,
  email,
  message,
  dueDate,
}) => {
  await api.post(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/token`,
    {
      email,
      due_date: dueDate,
      ...(fileId && { file: fileId }),
      type,
      locale,
      message,
    }
  );

  // Get updated list of translators
  return getTranslators({ fragmentId, locale, type });
};

export const revokeTranslatorLink = async ({
  fragmentId,
  id,
  locale,
  type,
}) => {
  await api.delete(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/token/${id}`,
    {
      locale,
      type,
    }
  );

  // Get updated list of translators
  return getTranslators({ fragmentId, locale, type });
};

export const createFragment = (fields) =>
  api.post(`${AllInApi.getApiChannelPath()}/asset/fragment/create`, {
    ...fields,
  });

export const patchFragment = (fragmentId, fields) =>
  api.post(`${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}`, {
    ...fields,
    // Laravel simulated patch
    _method: "PATCH",
  });

export const patchFragmentLabels = (fragmentId, fields) =>
  api.post(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/label`,
    {
      ...fields,
      // Laravel simulated patch
      _method: "PATCH",
    }
  );

export const patchFragmentMetaValues = (fragmentId, fields) =>
  api.post(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/value`,
    {
      ...fields,
      // Laravel simulated patch
      _method: "PATCH",
    }
  );

export const unlockFragment = (fragmentId) =>
  api.get(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/unlock`
  );
export const ingestFragment = (fragmentId, ingestData) =>
  api.post(
    `${AllInApi.getApiChannelPath()}/tools/axinom/ingest/${fragmentId}`,
    ingestData
  );

export const getPurge = (fragmentId, type) =>
  api.get(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/purge`,
    {
      params: { type },
    }
  );

export const purge = (fragmentId, type) =>
  api.post(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/purge`,
    { type }
  );

export const uploadFile = ({ fragmentId, typeId, acceptedFiles, locale }) => {
  const url = `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/file`;
  const formData = new FormData();
  formData.append("file", acceptedFiles[0]);
  formData.append("type[id]", typeId);

  if (locale) {
    formData.append("locale", locale);
  }

  // Create new file
  return api.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteFile = ({ fragmentId, fileId }) => {
  const url = `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/file/${fileId}`;
  return api.delete(url);
};

export const deleteRevision = async ({ fragmentId, fileId, locale }) => {
  await deleteFile({ fragmentId, fileId });

  // Get updated list of revisions
  return getFragmentFiles({
    fragmentId,
    locale,
    type: "subtitle",
    status: null,
  });
};

export const getLogs = (searchParams) =>
  api.get(
    `${AllInApi.getApiPath()}/log?multiple=${JSON.stringify(
      searchParams
    )}&withObject=1`
  );

export const getFragmentJobs = (fragmentId, fileId) =>
  api.get(`${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/job`, {
    params: {
      "files.id": fileId,
    },
  });

export const saveFile = (file) =>
  api.put(`${AllInApi.getApiChannelPath()}/asset/file/${file.id}`, file);

export const changePriority = (job) =>
  api.post(`${AllInApi.getApiChannelPath()}/job/${job.id}/priority`, job);

export const deleteJob = (job) =>
  api.delete(`${AllInApi.getApiChannelPath()}/job/${job.id}`);

export const startJob = (fragmentId, body) =>
  api.post(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${fragmentId}/job`,
    body
  );

export const archiveFragment = (id) => {
  return api.post(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${id}/archive`
  );
};

export const unArchiveFragment = (id) => {
  return api.post(
    `${AllInApi.getApiChannelPath()}/asset/fragment/${id}/unarchive`
  );
};

export const deleteFragmentFile = (fileId) => {
  return api.delete(`${AllInApi.getApiChannelPath()}/asset/file/${fileId}`);
};

export const archiveFragmentFile = (fileId) => {
  return api.post(
    `${AllInApi.getApiChannelPath()}/asset/file/${fileId}/archive`
  );
};

export const unArchiveFragmentFile = (fileId) => {
  return api.post(
    `${AllInApi.getApiChannelPath()}/asset/file/${fileId}/unarchive`
  );
};
