import PropTypes from "prop-types";

// Utils
import Utils from "features/Common/utils";

// Components
import Toolbar from "@material-ui/core/Toolbar";
import SelectChannel from "./SelectChannel";

/* Styles */
import AppBarStyled from "./HeaderBasic.style";

const DEFAULT_COLOR = "#1270b7";

const HeaderBasic = ({ channel }) => {
  // Channel appearance
  const channelColor = channel?.color || DEFAULT_COLOR;
  const channelColorDark = Utils.Channels.shadeColor(channelColor, -40);

  if (!channel?.color) return null;
  return (
    <AppBarStyled
      position="sticky"
      style={{ backgroundColor: channelColorDark }}
    >
      <Toolbar>
        <div className="grow">
          <SelectChannel channel={channel} />
        </div>
      </Toolbar>
    </AppBarStyled>
  );
};

HeaderBasic.defaultProps = {
  channel: null,
};

HeaderBasic.propTypes = {
  channel: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default HeaderBasic;
