import { memo } from "react";
import PropTypes from "prop-types";

// Icons
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";

// Components
import TableCell from "@material-ui/core/TableCell";

const DRMCell = ({ drm }) => (
  <TableCell className="cell--drm align--center" scope="row" component="td">
    <div>
      {drm ? (
        <VerifiedUserIcon className="color--green" fontSize="small" />
      ) : (
        <VerifiedUserOutlinedIcon className="color--red" fontSize="small" />
      )}
    </div>
  </TableCell>
);

DRMCell.propTypes = {
  drm: PropTypes.bool,
};

export default memo(DRMCell);
