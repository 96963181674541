import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import moment from "moment";

/* Components */
import DatePicker from "./DatePicker";

const DatePickerWithControl = (props) => {
  const { control, timestamp, onChange, outputFormatter } = props;
  return (
    <Controller
      as={DatePicker}
      control={control}
      {...props}
      onChange={([date]) => {
        let value = timestamp && moment.isMoment(date) ? date.unix() : date;
        value = outputFormatter(value);
        onChange(value);
        return value;
      }}
    />
  );
};

DatePickerWithControl.displayName = "DatePickerWithControl";

DatePickerWithControl.defaultProps = {
  onChange: () => null,
  outputFormatter: (value) => value,
};

DatePickerWithControl.propTypes = {
  control: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
  outputFormatter: PropTypes.func,
};

export default DatePickerWithControl;
