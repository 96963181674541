import { useContext } from "react";
import { SeoDetailContext } from "../SeoDetail/SeoDetailProvider";

export function useSeoDetail() {
  const context = useContext(SeoDetailContext);

  if (context === undefined) {
    console.log("no context");
  }

  return context;
}
