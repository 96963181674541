import { useRef, useState, useMemo, useEffect, Children } from "react";
import PropTypes from "prop-types";

import { DraggableCore } from "react-draggable";
import debounce from "lodash.debounce";
import $ from "cash-dom";
import classNames from "classnames";

import StyledComponent from "./style";

/**
 * Source: https://github.com/bjgrosse/react-resize-panel
 *
 * Improved and extended version of react-resize-panel
 * - Converted to functional component
 * - Add onDrag / onDrag stop callback function through props
 * - Max size / min size
 * - Initial size
 */
const ResizePanel = ({
  name,
  direction,
  children,
  containerClass,
  borderClass,
  style,
  handleClass,
  overFlowCorrection,
  onDrag,
  onDragStop,
  initialSize,
  maximumSize,
  minimumSize,
}) => {
  const contentRef = useRef();
  const wrapperRef = useRef();

  const [initialValidation, setInitialValidation] = useState(true);
  const [size, setSize] = useState(0);
  const [sizeClass, setSizeClass] = useState("");

  const isHorizontal = useMemo(() => direction === "w" || direction === "e", [
    direction,
  ]);

  const validateSize = debounce(() => {
    if (!contentRef?.current || !wrapperRef?.current) return;
    const content = contentRef.current;
    const wrapper = wrapperRef.current;
    const actualContent = content?.children[0];
    const containerParent = wrapper.parentElement;

    // Or if our size doesn't equal the actual content size, then we
    // must have pushed past the min size of the content, so resize back
    // let minSize = isHorizontal ? $(actualContent).outerWidth(true) : $(actualContent).outerHeight(true);
    let minSize = isHorizontal
      ? actualContent.scrollWidth
      : actualContent.scrollHeight;

    const margins = isHorizontal
      ? $(actualContent).outerWidth(true) - $(actualContent).outerWidth()
      : $(actualContent).outerHeight(true) - $(actualContent).outerHeight();
    minSize += margins;

    if (!overFlowCorrection) {
      return;
    }

    if (size !== minSize) {
      setSize(minSize);
    }

    // If our resizing has left the parent container's content overflowing
    // then we need to shrink back down to fit
    if (overFlowCorrection) {
      const overflow = isHorizontal
        ? containerParent.scrollWidth - containerParent.clientWidth
        : containerParent.scrollHeight - containerParent.clientHeight;

      if (overflow) {
        const newSize = isHorizontal
          ? actualContent.clientWidth - overflow
          : actualContent.clientHeight - overflow;
        setSize(newSize);
      }
    }
  }, 100);

  useEffect(() => {
    const validateListener = () => {
      validateSize();
    };

    window.addEventListener("resize", validateListener);

    return () => {
      window.removeEventListener("resize", validateListener);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    validateSize();
    // eslint-disable-next-line
  }, [name]);

  // Initial size validation
  useEffect(() => {
    if (!initialValidation) return;
    const content = contentRef.current;
    const actualContent = content.children[0];
    const intrinsicContentSize = isHorizontal
      ? $(actualContent).outerWidth(true)
      : $(actualContent).outerHeight(true);

    // Initialize the size value based on the content's current size
    if (initialSize !== 0) {
      setSize(initialSize);
    } else {
      setSize(intrinsicContentSize);
    }

    // Prevent validation loop
    setInitialValidation(false);
    validateSize();
  }, [
    setSize,
    isHorizontal,
    initialSize,
    initialValidation,
    validateSize,
    setInitialValidation,
  ]);

  // Add size classes to component. So that, the user
  // could adjust it's style based on the style class.
  useEffect(() => {
    if (size > 1100) {
      setSizeClass("xl");
    } else if (size > 900) {
      setSizeClass("l");
    } else if (size > 700) {
      setSizeClass("m");
    } else if (size > 300) {
      setSizeClass("s");
    } else {
      setSizeClass("xs");
    }
  }, [size]);

  const onDragHandle = (e, ui) => {
    const factor = direction === "e" || direction === "s" ? -1 : 1;

    // modify the size based on the drag delta
    const delta = isHorizontal ? ui.deltaX : ui.deltaY;
    let newSize = size - delta * factor;

    // Handle exceeds maxSize
    if (maximumSize !== 0 && newSize > maximumSize) {
      return;
    }
    // // Handle exceeds minSize
    if (minimumSize !== 0 && newSize < minimumSize) {
      newSize = minimumSize;
    }

    setSize(Math.max(10, newSize));
    onDrag({ size: newSize });
  };

  const onStopHandle = () => {
    validateSize();
    onDragStop({ size });
  };

  const containerClassName = classNames([containerClass, sizeClass], {
    ContainerHorizontal: isHorizontal,
    ContainerVertical: !isHorizontal,
  });

  const containerStyle = { ...style } || {};
  if (size !== 0) {
    containerStyle.flexGrow = 0;
    containerStyle[isHorizontal ? "width" : "height"] = "auto";
  }

  const handleClasses =
    handleClass ||
    classNames({
      ResizeHandleHorizontal: isHorizontal,
      ResizeHandleVertical: !isHorizontal,
    });

  const resizeBarClasses =
    borderClass ||
    classNames({
      ResizeBarHorizontal: isHorizontal,
      ResizeBarVertical: !isHorizontal,
    });

  const contentStyle = isHorizontal
    ? { width: `${size}px` }
    : { height: `${size}px` };

  const contentClassName = classNames("ResizeContent", {
    ResizeContentHorizontal: isHorizontal,
    ResizeContentVertical: !isHorizontal,
  });

  const content = [
    <div
      key="content"
      ref={contentRef}
      className={contentClassName}
      style={contentStyle}
    >
      {Children.only(children)}
    </div>,
  ];

  const handle = (
    <DraggableCore key="handle" onDrag={onDragHandle} onStop={onStopHandle}>
      <div className={resizeBarClasses}>
        <div className={handleClasses}>
          <span />
        </div>
      </div>
    </DraggableCore>
  );

  // Insert the handle at the beginning of the content if our direction is west or north
  if (direction === "w" || direction === "n") {
    content.unshift(handle);
  } else {
    content.push(handle);
  }

  return (
    <StyledComponent
      ref={wrapperRef}
      className={containerClassName}
      style={containerStyle}
    >
      {content}
    </StyledComponent>
  );
};

ResizePanel.defaultProps = {
  overFlowCorrection: true,
  containerClass: "",
  initialSize: 10,
  maximumSize: 0,
  minimumSize: 0,
  onDrag: () => {},
  onDragStop: () => {},
};

ResizePanel.propTypes = {
  direction: PropTypes.oneOf(["e", "n", "s", "w"]),
  containerClass: PropTypes.string,
  overFlowCorrection: PropTypes.bool,
  onDrag: PropTypes.func,
  onDragStop: PropTypes.func,
  initialSize: PropTypes.number,
  maximumSize: PropTypes.number,
  minimumSize: PropTypes.number,
};

export default ResizePanel;
