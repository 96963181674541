import { forwardRef } from "react";
import PropTypes from "prop-types";

/* Components */
import MUITextField from "@material-ui/core/TextField";

/* Styles */
import { styled } from "@material-ui/core/styles";

const TextFieldStyled = styled(MUITextField)(() => ({}));

const TextField = forwardRef(
  (
    {
      label,
      error,
      name,
      variant,
      value,
      placeholder,
      className,
      color,
      autoFocus,
      required,
      multiline,
      disabled,
      fullWidth,
      onChange,
      onFocus,
      type,
      style,
      size,
      startAdornment,
      endAdornment,
      inputFormatter,
      shrink,
      defaultValue,
      onBlur,
    },
    ref
  ) => (
    <TextFieldStyled
      data-testid={name}
      name={name}
      required={required}
      error={Boolean(error)}
      color={color}
      label={label}
      autoFocus={autoFocus}
      multiline={multiline}
      className={className}
      InputProps={{
        startAdornment,
        endAdornment,
      }}
      disabled={disabled}
      variant={variant}
      inputRef={ref}
      value={inputFormatter(value)}
      placeholder={placeholder}
      helperText={error}
      fullWidth={fullWidth}
      onChange={onChange}
      onFocus={onFocus}
      type={type}
      style={style}
      size={size}
      InputLabelProps={{
        ...(shrink && { shrink }),
      }}
      defaultValue={defaultValue}
      onBlur={onBlur}
    />
  )
);

TextField.displayName = "TextField";
TextField.defaultProps = {
  color: "primary",
  autoFocus: false,
  onChange: () => {},
  onFocus: () => {},
  inputFormatter: (value) => value,
  variant: "outlined",
  shrink: false,
};

TextField.propTypes = {
  value: PropTypes.string,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.oneOf(["default", "primary", "secondary"]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(["filled", "outlined"]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string,
  inputFormatter: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  shrink: PropTypes.bool,
};

export default TextField;
