import { memo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// Icons
import ArchiveIcon from "@material-ui/icons/Archive";
import SelectAllIcon from "@material-ui/icons/SelectAll";

// Components
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const MoreMenu = ({ onExportClick, deselectAll, closeMenu }) => {
  const { t } = useTranslation();

  const onExportHandle = () => {
    onExportClick(closeMenu);
  };

  const onDeselectAllHandle = () => {
    deselectAll(closeMenu);
  };

  return (
    <>
      <MenuItem onClick={onExportHandle}>
        <ListItemIcon>
          <ArchiveIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t("text.EXPORT_AXINOM_ASSETS")} />
      </MenuItem>
      <MenuItem onClick={onDeselectAllHandle}>
        <ListItemIcon>
          <SelectAllIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t("text.DESELECT_ALL")} />
      </MenuItem>
    </>
  );
};

MoreMenu.propTypes = {
  onExportClick: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default memo(MoreMenu);
