import PropTypes from "prop-types";

// Styles
import StyledButton from "./style";

const ResetFilterButton = ({ hasFilters, translate, resetFilters }) => (
  <StyledButton
    type="button"
    onClick={resetFilters}
    className={hasFilters ? "active" : "inactive"}
  >
    <div>{translate("reset.clear_all")}</div>
  </StyledButton>
);

ResetFilterButton.propTypes = {
  hasFilters: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default ResetFilterButton;
