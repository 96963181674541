import _ from "lodash";

import { PureComponent } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import CustomTableComponent from "./CustomTableView";

class CustomTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items,
      sort: "",
      sortDirection: "asc",
      selected: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { items, useItemsProps } = nextProps;
    const { items: stateItems } = prevState;

    if (!useItemsProps && items.length !== stateItems.length) return { items };

    return {};
  }

  handleSort = ({ dataKey, sortFn }) => {
    const { sort, sortDirection, items } = this.state;
    let direction = "asc";

    if (sort === dataKey && sortDirection === "asc") {
      direction = "desc";
    }

    let sortedItems = [];
    if (sortFn != null) {
      sortedItems = sortFn(items, direction);
    } else {
      sortedItems = _.orderBy(items, [dataKey], [direction]);
    }

    this.setState({
      sort: dataKey,
      sortDirection: direction,
      items: sortedItems,
    });
  };

  handleSelectAllClick = (event) => {
    const { onChange } = this.props;
    const { items } = this.state;

    if (event.target.checked) {
      const selectedItems = items.map((n) => n.id);
      this.setState({ selected: selectedItems });
      onChange({ selected: selectedItems });
      return;
    }

    this.setState({ selected: [] });
    onChange({ selected: [] });
  };

  handleSelect = (event, id) => {
    const { onChange } = this.props;
    const { selected } = this.state;

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
    onChange({ selected: newSelected });
  };

  isSelected = (id) => {
    const { selected } = this.state;

    if (Array.isArray(selected)) {
      return selected.indexOf(id) !== -1;
    }

    return false;
  };

  render() {
    const { selected, sort, sortDirection, items } = this.state;
    const { items: itemsProps, useItemsProps } = this.props;

    return (
      <CustomTableComponent
        {...this.props}
        items={useItemsProps ? itemsProps : items}
        numSelected={selected ? selected.length : 0}
        isSelected={this.isSelected}
        handleSelect={this.handleSelect}
        handleSelectAllClick={this.handleSelectAllClick}
        handleSort={this.handleSort}
        sort={sort}
        sortDirection={sortDirection}
      />
    );
  }
}

CustomTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      disablePadding: PropTypes.bool,
      rightAlign: PropTypes.bool,
      flexGrow: PropTypes.number,
      sortFn: PropTypes.func,
      cellContentRenderer: PropTypes.func,
    })
  ).isRequired,
  showCheckbox: PropTypes.bool,
  onRowClick: PropTypes.func,
  onChange: PropTypes.func,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  t: PropTypes.func.isRequired,
};

export default compose(withTranslation())(CustomTable);
