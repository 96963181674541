/* eslint-disable camelcase */
import { useMemo, useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink, useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Components
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Button from "components/Button";
import ListNav from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import NationFlag from "components/NationFlag";

// Local Components
import AssetEdit from "../AssetEdit";
import NavMenuLink from "./NavMenuLink";

// Hooks
import { useSeriesNavigation, useScrollToActiveNavLink } from "./hooks";

// Redux
import { ASSET } from "../Asset.state";

// Style
import StyledComponent, {
  StyledSeriesSelector,
  StyledPopOver,
  StyledNextPrevPopOver,
} from "./style";

// Constants
import { FORM_MODES } from "../AssetEdit/constants";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const BreadCrumbLink = ({ name, channelId, label, itemId }) => {
  const classes = useStyles();

  if (!itemId) return null;
  return (
    <NavLink
      color="inherit"
      to={`/channel/${channelId}/series/${itemId}`}
      className={classes.link}
    >
      {itemId ? label : `${name}`}
    </NavLink>
  );
};

const AddButton = ({ id, name, to, toIdentifier, type, newIndex, onClick }) => {
  const { t } = useTranslation();
  return (
    <ListItem
      key={id}
      button
      className={`add-button ${type}`}
      onClick={onClick}
    >
      <div>
        <Typography variant="h6">
          {name} <strong>{newIndex}</strong> {t("buttons.ADD")}
        </Typography>
        <Typography variant="caption">
          {t("buttons.TO")} {to || toIdentifier}
        </Typography>
      </div>
      <AddIcon fontSize="small" />
    </ListItem>
  );
};

const formatIndex = (index) => (index < 10 ? `0${+index}` : +index);

export const SeriesSelector = ({ series, channelId, locale, onAddClick }) => {
  const { t } = useTranslation();
  const { assetId } = useParams();

  const { seasons } = series;
  const [anchorEl, setAnchorEl] = useState(null);
  const [nextPrevAnchorEl, setNextPrevAnchorEl] = useState(null);

  const {
    hasPreviousEpisode,
    previousEpisode,
    hasNextEpisode,
    nextEpisode,
  } = useSeriesNavigation(series, assetId);

  const [allRefs] = useScrollToActiveNavLink(
    series,
    assetId,
    Boolean(anchorEl)
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const nextPrevOpen = Boolean(
    nextPrevAnchorEl && nextPrevAnchorEl?.episode && nextPrevAnchorEl?.season
  );

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const onNextPrevMouseEnter = (event, episode, type) => {
    event.stopPropagation();

    // Get corresponding season
    const { parent_id: parentId = -1 } = episode || {};
    const season = series?.seasons?.find(
      ({ id: seasonId }) => seasonId === parentId
    );

    setNextPrevAnchorEl({
      target: event.currentTarget,
      episode,
      season,
      type,
    });
  };

  const onNextPrevMouseLeave = () => {
    setNextPrevAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLinkClick = () => {
    setAnchorEl(null);
  };

  // Update next/prev popover info on active series change
  useEffect(() => {
    setNextPrevAnchorEl((nextPrevAnchor) => {
      if (!nextPrevAnchor) return null;

      const episode =
        nextPrevAnchor.type === "previous" ? previousEpisode : nextEpisode;

      const { parent_id: parentId = -1 } = episode || {};
      const season = series?.seasons?.find(
        ({ id: seasonId }) => seasonId === parentId
      );

      return {
        ...nextPrevAnchor,
        episode,
        season,
      };
    });
  }, [series, previousEpisode, nextEpisode]);

  return (
    <StyledSeriesSelector>
      <Button
        name="previous"
        className="previous"
        color="default"
        aria-label="previous"
        trackName="series.previous.button"
        trackDetails={{
          previousEpisode,
        }}
        disabled={!hasPreviousEpisode}
        onMouseEnter={(event) =>
          onNextPrevMouseEnter(event, previousEpisode, "previous")
        }
        onMouseLeave={onNextPrevMouseLeave}
      >
        <Link to={`/channel/${channelId}/series/${previousEpisode?.id}`}>
          <ExpandMoreIcon />
        </Link>
      </Button>
      <Button id={id} color="default" onClick={handleClick}>
        <ExpandMoreIcon />
      </Button>
      <Button
        name="next"
        className="next"
        color="default"
        trackName="series.next.button"
        trackDetails={{
          nextEpisode,
        }}
        disabled={!hasNextEpisode}
        onMouseEnter={(event) =>
          onNextPrevMouseEnter(event, nextEpisode, "next")
        }
        onMouseLeave={onNextPrevMouseLeave}
      >
        <Link to={`/channel/${channelId}/series/${nextEpisode?.id}`}>
          <ExpandMoreIcon />
        </Link>
      </Button>
      <StyledNextPrevPopOver
        id="mouse-over-popover"
        open={nextPrevOpen}
        anchorEl={nextPrevAnchorEl?.target}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {nextPrevAnchorEl?.season && (
          <span className="index">
            {t("labels.SEASON").substring(0, 1)}
            {formatIndex(nextPrevAnchorEl?.season?.index)}
          </span>
        )}

        {nextPrevAnchorEl?.episode && (
          <span className="index">
            {t("labels.EPISODE").substring(0, 1)}
            {formatIndex(nextPrevAnchorEl?.episode?.index)}
          </span>
        )}

        {nextPrevAnchorEl?.episode?.name}
      </StyledNextPrevPopOver>
      <StyledPopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ListNav component="nav" aria-label="">
          <NavMenuLink
            key="series-home-link"
            id={series.id}
            leftView={
              <HomeIcon
                fontSize="small"
                style={{ fontSize: "1rem", marginLeft: 0, marginRight: 12 }}
              />
            }
            name={series.name}
            index={series.index}
            channelId={channelId}
            identifier={series.identifier}
            locale={locale}
            onClick={onLinkClick}
          />
          {seasons?.map((season) => (
            <Fragment key={`season-root-${season.id}`}>
              <NavMenuLink
                key={`season-${season.id}`}
                id={season.id}
                leftView={
                  <span ref={allRefs[season.id]} className="index">
                    {t("labels.SEASON").substring(0, 1)}
                    {formatIndex(season.index)}
                  </span>
                }
                name={season.name}
                channelId={channelId}
                identifier={season.identifier}
                locale={locale}
                onClick={onLinkClick}
              />
              <nav>
                {season.episodes &&
                  [...season.episodes]
                    ?.sort((a, b) => a.index - b.index)
                    ?.map((episode) => (
                      <NavMenuLink
                        key={`episode-${episode.id}`}
                        id={episode.id}
                        leftView={
                          <span className="index">
                            {t("labels.EPISODE").substring(0, 1)}
                            {formatIndex(episode.index)}
                            <div
                              className="scrollAnchor"
                              ref={allRefs[episode.id]}
                            />
                          </span>
                        }
                        name={episode.name}
                        index={episode.index}
                        channelId={channelId}
                        locale={locale}
                        onClick={onLinkClick}
                        identifier={episode.identifier}
                      />
                    ))}
                <AddButton
                  key={`episode-add-${season.id}`}
                  name={t("labels.EPISODE")}
                  type="episode"
                  to={season.name}
                  newIndex={(season?.episodes?.length || 0) + 1}
                  toIdentifier={season.identifier}
                  onClick={(event) =>
                    onAddClick({
                      event,
                      series,
                      season,
                      type: "episode",
                    })
                  }
                />
              </nav>
            </Fragment>
          ))}
          <AddButton
            key="season-add"
            name={t("labels.SEASON")}
            type="season"
            to={series.name}
            newIndex={(series?.seasons?.length || 0) + 1}
            toIdentifier={series.identifier}
            onClick={(event) => onAddClick({ event, series, type: "season" })}
          />
        </ListNav>
      </StyledPopOver>
    </StyledSeriesSelector>
  );
};

const DEFAULT_LANGUAGE = { code: "nl" };

const SeriesSidebar = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  // State
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newAsset, setNewAsset] = useState({ ...ASSET });

  // Redux
  const channel = useSelector((state) => state.channel);
  const { code } =
    useSelector((state) => state.user?.user?.default_language) ||
    DEFAULT_LANGUAGE;
  const { series, licenses, contracts, quality_check } = useSelector(
    (state) => state.asset.selected?.data
  );

  const homeUrl = `/channel/${channel?.selected}/series/${series?.id}`;

  const activeSeason = useMemo(
    () => series?.seasons?.find((season) => season.active),
    [series]
  );

  const activeEpisode = useMemo(
    () => activeSeason?.episodes?.find((episode) => episode.active),
    [activeSeason]
  );

  // Listeners

  const onCloseDialog = () => {
    setCreateDialogOpen(false);
  };

  const onAddAssetClick = ({ series: activeSeries, season, type }) => {
    const newSeries = {
      ...ASSET,
      contracts,
      licenses,
      quality_check,
      asset: {
        ...activeSeries,
      },
      type,
    };

    if (type === "episode") {
      newSeries.season = {
        ...season,
      };
      newSeries.index = (season?.episodes?.length || 0) + 1;
      newSeries.quality = season?.quality;
    }

    if (type === "season") {
      newSeries.index = (series?.seasons?.length || 0) + 1;
      newSeries.quality = series?.quality;
    }

    setNewAsset(newSeries);
    setCreateDialogOpen(true);
  };

  if (!series) return null;
  return (
    <StyledComponent className="series-bar">
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink color="inherit" to={homeUrl} className={classes.link}>
          <HomeIcon className={classes.icon} />
          {series?.name}
          {!series?.name && (
            <div className="no-title">
              <div>
                {t("labels.NO_TITLE_IN_CURRENT_LANGUAGE")}
                <NationFlag countryCode={code} />
              </div>
              <Typography variant="caption">{series?.identifier}</Typography>
            </div>
          )}
        </NavLink>
        {activeSeason && (
          <BreadCrumbLink
            label={`${t("labels.SEASON").substring(0, 1)}${formatIndex(
              activeSeason?.index
            )}`}
            channelId={channel?.selected}
            name="Season"
            itemId={activeSeason?.id}
            options={series?.seasons}
          />
        )}
        {activeEpisode && (
          <BreadCrumbLink
            label={`${t("labels.EPISODE").substring(0, 1)}${formatIndex(
              activeEpisode.index
            )}`}
            name="Episode"
            channelId={channel?.selected}
            itemId={activeEpisode.id}
          />
        )}
      </Breadcrumbs>
      <SeriesSelector
        locale={code}
        series={series}
        channelId={channel?.selected}
        onAddClick={onAddAssetClick}
      />
      <AssetEdit
        asset={newAsset}
        open={createDialogOpen}
        onClose={onCloseDialog}
        autoFocusField="title"
        mode={FORM_MODES.new}
      />
    </StyledComponent>
  );
};

export default SeriesSidebar;
