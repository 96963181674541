import styled from "styled-components";
import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";

const StyledComponent = styled(ListItem)`
  display: flex;
  justify-content: space-between;

  &.MuiListItem-root {
    padding: 8px 12px;
    background: white;
    border-radius: 4px;
    margin-bottom: 8px;
    border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  }

  header {
    display: flex;
    color: black;
    text-decoration: none;
    align-items: center;
    margin-bottom: 4px;

    & .MuiTypography-h5 {
      font-size: 0.875rem;
      font-weight: 700;
      margin-right: 4px;
    }

    & .MuiSvgIcon-root {
      font-size: 1rem;
    }

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.primary.main};

      .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  .details {
    display: flex;
    margin-bottom: 4px;

    .divider {
      margin: 0 4px;
      opacity: 0.6;
      line-height: 1;
    }
  }

  .sub-detail {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.grey[600]};

    & .MuiSvgIcon-root {
      font-size: 0.75rem;
      margin-right: 4px;
      color: ${({ theme }) => theme.palette.grey[600]};
    }
  }

  .MuiTypography-h5 {
    font-size: 0.875rem;
  }

  .MuiTypography-h6 {
    font-size: 0.75rem;
    line-height: 1;
  }

  .column-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .MuiChip-root {
    border-radius: 4px;
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  .MuiFab-root {
    width: 32px;
    height: 32px;
    background: none;
    box-shadow: none;
    color: white;
  }

  &.draft .MuiChip-root {
    background: #c000ff;
    color: white;
    border-color: #c000ff;
  }

  &.revoked .MuiChip-root {
    background: #f95252;
    color: white;
    border-color: #f95252;
  }

  &.active .MuiChip-root {
    background: ${({ theme }) => theme.palette.success.main};
    color: white;
    border-color: ${({ theme }) => theme.palette.success.main};
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiSvgIcon-root {
    margin-right: 12px;
  }

  .MuiCircularProgress-root {
    margin-right: 12px;
  }

  .icon-green {
    color: ${({ theme }) => theme.palette.success.main};
  }
  .icon-red {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export default StyledComponent;
