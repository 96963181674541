import { compose } from "redux";

import withStyles from "@material-ui/core/styles/withStyles";
import { TextField } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { Component, Fragment } from "../../../../components";
import styles from "./Form.styles";

class UserForm extends Component {
  render() {
    const { t, model, onChangeCallback } = this.props;

    return (
      <Fragment>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={t("labels.NAME")}
          name="name"
          type="text"
          defaultValue={model.name}
          onChange={onChangeCallback}
          fullWidth
        />
        <TextField
          margin="dense"
          id="email"
          name="email"
          label={t("labels.EMAIL")}
          type="email"
          defaultValue={model.email}
          onChange={onChangeCallback}
          fullWidth
        />
      </Fragment>
    );
  }
}

UserForm.defaultProps = {};

UserForm.propTypes = {};

export default compose(withTranslation(), withStyles(styles))(UserForm);
