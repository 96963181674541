/* eslint-disable camelcase */
import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import InboxDialogView from "./InboxDialogView";
import InboxDialogFooter from "./InboxDialogFooter";
import TimeLine from "../../../components/TimeLine";
import * as logActions from "../../Log/Log.actions";
import { updateInbox, deleteInbox } from "../services";

class AddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updating: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { open, resetLog, findLog, item } = this.props;
    const { open: prevOpen } = prevProps;

    if (open && prevOpen !== open) {
      findLog({ content_id: item.id, type: "asset_files", withObject: 1 });
    }

    if (!open && prevOpen !== open) {
      resetLog();
    }
  }

  handleSubmit(values) {
    const {
      asset,
      season,
      episode,
      fragment,
      fragment_title,
      fragment_identifier,
      fragment_trailer,
    } = values;
    const { item, enqueueSnackbar, onClose } = this.props;

    let updateAsset = asset;

    if (season) {
      updateAsset = season;
    }

    if (episode) {
      updateAsset = episode;
    }

    const updateData = {
      file: { id: item.id },
      asset: { id: updateAsset.id },
    };

    if (!fragment.id) {
      updateData.fragment = {
        name: fragment_title,
        identifier: fragment_identifier,
        trailer: fragment_trailer,
        full_identifier: false,
      };
    } else {
      updateData.fragment = {
        id: fragment.id,
      };
    }

    this.setState({ updating: true });

    updateInbox(updateData)
      .then(() => {
        onClose(true);
        enqueueSnackbar("Videobestand doorgezet", {
          variant: "success",
        });
      })
      .finally(() => this.setState({ updating: false }));
  }

  deleteItem = () => {
    const { onClose, enqueueSnackbar, item } = this.props;

    deleteInbox(item.id).then(() => {
      onClose(true);
      enqueueSnackbar("Videobestand verwijderd", {
        variant: "success",
      });
    });
  };

  validationSchema() {
    const { t } = this.props;

    return Yup.object().shape({
      asset: Yup.mixed().required(t("labels.FIELD_IS_REQUIRED")),
      // season: Yup.mixed().when("asset", {
      //   is: (asset) => asset && asset.type === "series",
      //   then: Yup.mixed().required(`${t("labels.FIELD_IS_REQUIRED")}`),
      // }),
      fragment_title: Yup.mixed().when("fragment", {
        is: (fragment) => fragment && fragment.id === null,
        then: Yup.mixed().required(`${t("labels.FIELD_IS_REQUIRED")}`),
      }),
    });
  }

  render() {
    const { item, onClose, open, logs } = this.props;
    const { updating } = this.state;

    if (!item) return null;

    return (
      <Formik
        initialValues={{
          fragment: null,
          fragment_identifier: null,
          fragment_title: null,
          fragment_trailer: false,
        }}
        enableReinitialize
        validationSchema={this.validationSchema()}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => this.handleSubmit(values)}
        render={({ values, errors, setFieldValue, handleSubmit, isValid }) => (
          <Dialog
            aria-labelledby="simple-dialog-title"
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={() => onClose(false)}
          >
            <AppBar position="sticky">
              <Toolbar>
                <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
                  Videobestand doorzetten
                </Typography>
                <IconButton
                  color="inherit"
                  aria-label="Close"
                  onClick={() => onClose()}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Grid container>
              <Grid item xs={8}>
                <InboxDialogView
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={4}>
                <TimeLine loading={logs.loading} logs={logs.data} />
              </Grid>
            </Grid>
            <InboxDialogFooter
              values={values}
              handleSubmit={handleSubmit}
              isValid={isValid}
              updating={updating}
              deleteItem={this.deleteItem}
            />
          </Dialog>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  logs: state.log,
  channel: state.channel,
});

const mapDispatchToProps = (dispatch) => ({
  findLog: (searchParams) => dispatch(logActions.find(searchParams)),
  resetLog: () => dispatch(logActions.reset()),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(withSnackbar(AddDialog));
