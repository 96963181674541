/* eslint-disable react/display-name */
import { useMemo, memo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

// Icons
import EditIcon from "@material-ui/icons/Edit";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";

// Component
import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Chip,
  Typography,
} from "@material-ui/core";
import RatingStars from "@material-ui/lab/Rating";

// Hooks
import { useQuery } from "features/Common/hooks";
import { useCurrentFragmentUrl } from "../hooks";

// Style
import StyledComponent from "./style";

const NoValue = () => {
  const { t } = useTranslation();
  return (
    <p className="no-value">
      <ReportProblemRoundedIcon />
      {t("labels.NO_VALUE")}
    </p>
  );
};

const NotImplemented = ({ name }) => (
  <h2>
    Not implemented
    {name}
  </h2>
);

const Select = ({ name, values = [] }) => {
  const valuesArray = Array.isArray(values) ? values : [values];
  return (
    <div className="element">
      <Typography variant="h6">{name}</Typography>
      {valuesArray.map(({ id, name: valueName, value }) => (
        <Chip key={id} label={valueName || value} />
      ))}
      {!valuesArray.length && <NoValue />}
    </div>
  );
};

const Date = ({ name, values = {} }) => {
  const { value } = values;
  return (
    <div className="element">
      <Typography variant="h6">{name}</Typography>
      <div className="date-content">
        {value && (
          <>
            <CalendarTodayIcon />
            <span>{moment.unix(value).format("DD-MM-YYYY")}</span>
          </>
        )}
        {!value && <NoValue />}
      </div>
    </div>
  );
};

const Input = ({ name, values = {} }) => {
  const { value } = values;
  return (
    <div className="element">
      <Typography variant="h6">{name}</Typography>
      <div>{value}</div>
      {!value && <NoValue />}
    </div>
  );
};

const TextArea = ({ name, values = {} }) => {
  const { value } = values;
  return (
    <div className="element">
      <Typography variant="h6">{name}</Typography>
      <p>{value}</p>
      {!value && <NoValue />}
    </div>
  );
};

const MAX_STARS = 5;
const Rating = ({ name, values = {} }) => {
  const { value = 0 } = values;
  const rating = MAX_STARS * (value / 100);
  return (
    <div className="element">
      <Typography variant="h6">{name}</Typography>
      <div>
        <RatingStars name="read-only" value={rating} readOnly />
      </div>
      {!value && <NoValue />}
    </div>
  );
};

const fieldMap = {
  select: Select,
  date: Date,
  input: Input,
  textarea: TextArea,
  rating: Rating,
  notImplemented: () => <NotImplemented />,
};

const PANEL_NAME = "meta";
const FragmentMeta = ({ fragment }) => {
  const { id: fragmentId, meta } = fragment;

  const { t } = useTranslation();
  const url = useCurrentFragmentUrl({ fragmentId });
  const panelUrl = `${url}?panel=${PANEL_NAME}`;

  // Current Page
  const query = useQuery();
  const panel = query.get("panel");
  const isActive = () => panel === PANEL_NAME;

  const notFilledCount = useMemo(
    () =>
      meta?.filter((field) => {
        const { values } = field;
        return values?.length > 0;
      })?.length || 0,
    [meta]
  );

  return (
    <StyledComponent>
      <NavLink to={panelUrl} activeClassName="activeEdit" isActive={isActive}>
        <Card>
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <EditIcon />
              </IconButton>
            }
            title={t("labels.META")}
            subheader={`${notFilledCount} / ${meta.length} ${t(
              "labels.META_FIELDS"
            )}`}
          />
          <CardContent>
            {meta.map(({ id, identifier, name, type, multiple, values }) => {
              const elementType = identifier === "rating" ? "rating" : type;
              const Element = fieldMap[elementType] || fieldMap.notImplemented;
              return (
                <Element
                  key={id}
                  name={name}
                  values={multiple ? values : values[0]}
                />
              );
            })}
          </CardContent>
        </Card>
      </NavLink>
    </StyledComponent>
  );
};

FragmentMeta.defaultProps = {
  fragment: null,
};

FragmentMeta.propTypes = {
  fragment: PropTypes.shape({}),
};

export default memo(FragmentMeta);
