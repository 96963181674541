import AppSettingsList from "./AppSettingsList";

const routes = [
  {
    key: "APP_SETTINGS",
    path: "/settings",
    component: AppSettingsList,
    exact: true,
    displayNav: false,
    auth: true,
  },
  {
    key: "APP_SETTINGS_SCREENS",
    path: "/settings/:setting",
    component: AppSettingsList,
    exact: true,
    displayNav: false,
    auth: true,
  },
  {
    key: "APP_SETTINGS_SCREENS_DETAIL",
    path: "/settings/:setting/:id",
    component: AppSettingsList,
    exact: true,
    displayNav: false,
    auth: true,
  },
];

export default routes;
