import StyledComponent from "./styles";
import React, { useEffect, useState } from "react";
import { Checkbox, Grid } from "@material-ui/core";
import MenuItem from "components/MenuItem";
import { getCountries } from "features/Common/country.service";
import { Button } from "components";
import { useForm } from "react-hook-form";
import { TextFieldWithControl } from "components/Form/TextField";
import { useSnackbar } from "notistack";
import { addCollection } from "./collection.service";
import Header from "../Licenses/Header";
import { AutoCompleteWithControl, SelectWithControl } from "components/Form";

const CollectionContainer = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, reset, errors } = useForm();
  const [list, setList] = useState([]);

  const textFieldOnChange = (val) => {
    control.setValue("id", val);
  };

  // submit form
  const onSubmit = (data) => {
    let completeCountries = [];
    for (const country in data.nations) {
      completeCountries.push(data.nations[country].name);
    }

    const finalData = {
      title: data.name,
      id: data.id,
      countries: completeCountries,
      type: data.type,
    };

    reset();

    addCollection(props.channel.data.id, finalData).then((res) => {
      enqueueSnackbar("Collectie " + res.data.title + " aangemaakt", {
        variant: "success",
      });
      reset();
    });
  };

  useEffect(() => {
    getCountries().then((res) => {
      setList(res.data);
    });
  }, []);

  return (
    <StyledComponent>
      <Header>
        <Header.Title>Collectie</Header.Title>
      </Header>
      <form className="collectionForm" onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={2} className="mainGrid">
          <Grid item>
            <TextFieldWithControl
              control={control}
              onChange={(val) => {
                textFieldOnChange(val);
              }}
              label="Name"
              name="name"
              defaultValue=""
              className="test"
            />
          </Grid>
          <Grid item>
            <TextFieldWithControl
              control={control}
              label="Id"
              name="id"
              defaultValue=""
              className="test"
            />
          </Grid>
          <Grid item>
            <AutoCompleteWithControl
              name="nations"
              multiple
              className="test"
              label="nations"
              variant="outlined"
              defaultValue=""
              options={list || []}
              control={control}
              error={errors.licenses?.message}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    color="primary"
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
            />
          </Grid>
          <Grid item>
            <SelectWithControl
              control={control}
              name="type"
              defaultValue="Manual"
              variant="outlined"
              className="test"
            >
              <MenuItem key={"Manual"} value={"Manual"}>
                Manual
              </MenuItem>
              <MenuItem key={"Automatic"} value={"Automatic"}>
                Automatic
              </MenuItem>
            </SelectWithControl>
          </Grid>
          <Grid item>
            <Button size="large" type="submit">
              submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </StyledComponent>
  );
};

export default CollectionContainer;
