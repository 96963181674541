import FragmentDetail from "features/Fragment/FragmentDetail";
import FragmentNotFound from "features/Fragment/FragmentNotFound";
import AssetHome from "./AssetHome";
import AssetDetail from "./AssetDetail";

const routes = [
  {
    key: "VIDEO",
    path: "/channel/:channelId/:type(video)",
    component: AssetHome,
    exact: true,
    displayNav: true,
    auth: true,
  },
  {
    key: "SERIES",
    path: "/channel/:channelId/:type(series)",
    component: AssetHome,
    exact: true,
    displayNav: true,
    auth: true,
  },
  {
    key: "ASSET",
    path: "/channel/:channelId/:type(series|video)/:assetId",
    component: AssetDetail,
    exact: false,
    displayNav: false,
    auth: true,
    subRoutes: [
      {
        key: "FRAGMENT",
        path: "/fragment/:fragmentId",
        component: FragmentDetail,
        exact: true,
        displayNav: false,
        auth: true,
      },
      {
        key: "FRAGMENT_NOT_FOUND",
        path: "*",
        component: FragmentNotFound,
        exact: false,
        displayNav: false,
        auth: true,
      },
    ],
  },
];

export default routes;
