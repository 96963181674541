import { Component } from "react";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import moment from "moment";

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import axios from "axios";

import * as Yup from "yup";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Lock from "@material-ui/icons/Lock";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import Utils from "../../Common/utils";
import CircularProgressCenter from "../../../components/CircularProgressCenter";
import TranslateProgressBar from "./TranslateProgressBar";
import styles from "./TranslationForm.styles";

import {
  loadFragment,
  loadTranslatableFragments,
} from "../../Fragment/reducer";
import {
  getFragment as getFragmentWithLang,
  saveFragment,
  lockFragment,
  unlockFragment,
  nextFragment,
} from "../../Fragment/redux/fragment.service";

import TranslationFormView from "./TranslationFormView";
import SelectTranslateLanguage from "./SelectTranslateLanguage";
import SelectContentLanguage from "../../../components/Header/SelectContentLanguage";

class TranslationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sourceFragment: {
        loading: true,
        isFetching: false,
      },
      translatableFields: [],
      formIsValid: false,
      isSetup: true,
      isPosting: false,
    };
  }

  componentDidMount() {
    const { match, getTargetFragment, loadTranslations } = this.props;
    const { fragmentId } = match.params;

    loadTranslations();

    if (fragmentId !== null) {
      getTargetFragment(fragmentId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      channel,
      match,
      sourceLanguage,
      user,
      getTargetFragment,
    } = this.props;
    const { fragmentId } = match.params;
    const { isSetup } = this.state;

    const check =
      user.translate_language.code !== prevProps.user.translate_language.code ||
      sourceLanguage.code !== prevProps.sourceLanguage.code ||
      prevProps.match.params.fragmentId !== fragmentId ||
      isSetup;

    if (prevProps.match.params.fragmentId !== fragmentId) {
      getTargetFragment(fragmentId);
    }

    if (
      channel.selected &&
      channel.data &&
      channel.data.forms &&
      fragmentId &&
      sourceLanguage.code &&
      check
    ) {
      this.setTranslatableFields();
    }
  }

  componentWillUnmount() {
    this.unLockFragment();
  }

  setTranslatableFields = () => {
    const { channel, match, sourceLanguage } = this.props;
    const { fragmentId } = match.params;

    if (channel.data && channel.data.forms) {
      const translatableFields = Utils.Channels.getTranslatableFields(
        channel.data,
        "Video"
      );

      this.setState({
        isSetup: false,
      });

      getFragmentWithLang(
        channel.selected,
        fragmentId,
        sourceLanguage.code
      ).then((response) => {
        if (response.status === 200) {
          this.setState({
            sourceFragment: { data: response.data },
            translatableFields,
          });

          if (!response.data.locked) this.LockFragment();
        }
      });
    }
  };

  onChangeIsValid = (isValid) => {
    const { formIsValid } = this.state;
    if (formIsValid !== isValid) this.setState({ formIsValid: isValid });
  };

  saveTargetFragment = (fragment, next = false) => {
    const { channel, enqueueSnackbar, t, history } = this.props;

    this.setState({ isPosting: next ? "save-next" : "save" });

    return saveFragment(channel.selected, fragment).then((response) => {
      this.setState({ isPosting: false });
      if (response.status === 200) {
        enqueueSnackbar(t("text.FRAGMENT_SAVE"), {
          variant: "success",
        });

        if (next) {
          // eslint-disable-next-line consistent-return
          return nextFragment(channel.selected).then((responseNext) => {
            if (responseNext.data.id) {
              const path = "/channel/:channelId/asset/fragment/:fragmentId/translate"
                .replace(":id", channel.selected)
                .replace(":fragmentId", responseNext.data.id);

              this.setState({
                isPosting: false,
                isSetup: true,
                sourceFragment: {
                  loading: true,
                  isFetching: false,
                },
              });
              this.unLockFragment();
              history.push(path);
            } else {
              this.setState({ isPosting: false });
              return enqueueSnackbar(t("text.FRAGMENT_ALL_TRANSLATIONS_DONE"), {
                variant: "success",
              });
            }
          });
        }
      }
      return this.setState({ isPosting: false });
    });
  };

  nextFragment = () => {
    const { channel, history } = this.props;

    return nextFragment(channel.selected).then((responseNext) => {
      if (responseNext.data.id) {
        const path = "/channel/:channelId/asset/fragment/:fragmentId/translate"
          .replace(":id", channel.selected)
          .replace(":fragmentId", responseNext.data.id);

        this.setState({
          isPosting: false,
          isSetup: true,
          sourceFragment: {
            loading: true,
            isFetching: false,
          },
        });
        this.unLockFragment();
        history.push(path);
      }
    });
  };

  translateTarget = (valueToTranslate) => {
    const { sourceLanguage, user } = this.props;

    const api = axios.create({
      baseURL: "https://www.googleapis.com/language/translate/",
      headers: { Accept: "application/vnd.github.v3+json" },
    });

    return api.get(
      `v2?key=${process.env.REACT_APP_GOOGLE_TRANSLATE_API}&q=${encodeURI(
        valueToTranslate
      )}&source=${sourceLanguage.code}&target=${user.default_language.code}`
    );
  };

  targetFragment() {
    const { targetFragment } = this.props;
    const { sourceFragment } = this.state;

    if (sourceFragment.data.asset.name) {
      return {
        ...targetFragment.data,
        name: sourceFragment.data.name,
        asset: {
          ...targetFragment.data.asset,
          name: sourceFragment.data.asset.name,
        },
      };
    }

    return targetFragment.data;
  }

  LockFragment() {
    const { channel, match } = this.props;
    const { fragmentId } = match.params;

    lockFragment(channel.selected, fragmentId).then(() => {
      this.setTranslatableFields();
    });
  }

  unLockFragment(reload = false) {
    const { channel, match } = this.props;
    const { fragmentId } = match.params;

    unlockFragment(channel.selected, fragmentId).then(() => {
      if (reload) {
        this.LockFragment();
      }
    });
  }

  validationSchema() {
    const { t } = this.props;
    const { translatableFields } = this.state;

    const translatableFieldsValidationObject = {};

    translatableFields.forEach((field) => {
      let validation =
        field.type === "select" || field.type === "date"
          ? Yup.mixed().nullable()
          : Yup.string().nullable();

      if (field.required === 1) {
        validation = validation.required(t("labels.FIELD_IS_REQUIRED"));
      }

      if (field.type === "select") {
        translatableFieldsValidationObject[field.id] = validation;
      } else {
        translatableFieldsValidationObject[field.id] = Yup.object().shape({
          value: validation,
        });
      }
    });

    const validationObject = Yup.object().shape({
      name: Yup.string().nullable().required(t("labels.FIELD_IS_REQUIRED")),
      description: Yup.string()
        .nullable()
        .required(t("labels.FIELD_IS_REQUIRED")),
      short_description: Yup.string()
        .nullable()
        .required(t("labels.FIELD_IS_REQUIRED")),
      values: Yup.object().shape(translatableFieldsValidationObject),
    });

    return validationObject;
  }

  render() {
    const {
      t,
      classes,
      channel,
      targetFragment,
      translations,
      user,
    } = this.props;
    const {
      translatableFields,
      sourceFragment,
      isPosting,
      isSetup,
    } = this.state;

    if (
      targetFragment.loading ||
      translations.loading ||
      !sourceFragment.data ||
      isSetup ||
      !channel.data ||
      !channel.data.forms
    )
      return <CircularProgressCenter />;

    return (
      <div>
        <Helmet>
          <title>
            {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -{" "}
            {channel.data.name} -{t("titles.TRANSLATE")}
          </title>
        </Helmet>

        <Grid container spacing={4} style={{ padding: 24, maxWidth: "100%" }}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                {t("titles.TRANSLATE")}
                <Typography variant="subtitle1" display="inline">
                  {sourceFragment.data.name}
                </Typography>
              </div>
              {sourceFragment.data.locked &&
              user.id === sourceFragment.data.locked_by.id ? (
                <Tooltip title={t("text.LOCKED_BY_YOU")}>
                  <Lock />
                </Tooltip>
              ) : null}
            </div>
          </Grid>

          <Grid item xs={12}>
            <TranslateProgressBar />
          </Grid>

          {sourceFragment.data.locked &&
          user.id !== sourceFragment.data.locked_by.id ? (
            <Grid item xs={12}>
              <div className={classes.locked}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Lock />
                  <div
                    style={{
                      marginLeft: 20,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="subtitle1">
                      {sourceFragment.data.locked_by.name}
                    </Typography>
                    {moment
                      .unix(sourceFragment.data.locked_at)
                      .format("D MMMM YYYY HH:mm:ss")}
                  </div>
                </div>
                {user.superadmin ? (
                  <Button
                    variant="outlined"
                    onClick={() => this.unLockFragment(true)}
                  >
                    {t("buttons.UNLOCK")}
                  </Button>
                ) : null}
              </div>
            </Grid>
          ) : null}

          <Grid item xs className={classNames(classes.column)}>
            <Card>
              <CardContent>
                <SelectTranslateLanguage />
                {!sourceFragment.loading && (
                  <Formik
                    initialValues={sourceFragment.data}
                    enableReinitialize
                    render={({ values, errors }) => (
                      <>
                        <TranslationFormView
                          {...this.props}
                          fragment={values}
                          translatableFields={translatableFields}
                          errors={errors}
                          disabled
                        />
                      </>
                    )}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs className={classNames(classes.column)}>
            <Card>
              <CardContent>
                <SelectContentLanguage disabled />
                {!targetFragment.loading && (
                  <Formik
                    initialValues={this.targetFragment()}
                    enableReinitialize
                    validationSchema={this.validationSchema()}
                    render={({ values, errors, setFieldValue, isValid }) => (
                      <>
                        <TranslationFormView
                          {...this.props}
                          fragment={values}
                          translatableFields={translatableFields}
                          errors={errors}
                          disabled={
                            sourceFragment.data.locked &&
                            user.id !== sourceFragment.data.locked_by.id
                          }
                          setFieldValue={setFieldValue}
                          translateTarget={this.translateTarget}
                          sourceFragment={sourceFragment.data}
                        />
                        <CardActions
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                          }}
                        >
                          <Button
                            disabled={!isValid || isPosting !== false}
                            variant="contained"
                            type="submit"
                            onClick={() => this.saveTargetFragment(values)}
                            style={{ width: "100%" }}
                          >
                            {t("buttons.SAVE")}
                            {isPosting === "save" ? (
                              <CircularProgress
                                style={{
                                  height: 20,
                                  width: 20,
                                  marginLeft: 10,
                                }}
                              />
                            ) : null}
                          </Button>
                          {!isValid ? (
                            <Button
                              variant="contained"
                              type="submit"
                              color="primary"
                              onClick={() => this.nextFragment()}
                              style={{ width: "100%" }}
                            >
                              {t("buttons.NEXT")}
                            </Button>
                          ) : (
                            <Button
                              disabled={!isValid || isPosting !== false}
                              variant="contained"
                              type="submit"
                              color="primary"
                              onClick={() =>
                                this.saveTargetFragment(values, true)
                              }
                              style={{ width: "100%" }}
                            >
                              {t("buttons.SAVE_AND_NEXT")}
                              {isPosting === "save-next" ? (
                                <CircularProgress
                                  style={{
                                    height: 20,
                                    width: 20,
                                    marginLeft: 10,
                                  }}
                                />
                              ) : null}
                            </Button>
                          )}
                        </CardActions>
                      </>
                    )}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TranslationForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  channel: PropTypes.shape({}).isRequired,
  channels: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  loadTranslations: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  targetFragment: PropTypes.shape({}).isRequired,
  sourceLanguage: PropTypes.oneOfType([PropTypes.object]).isRequired,
  getTargetFragment: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = (state) => ({
  channel: state.channel,
  channels: state.user?.user?.channels,
  translations: state.fragment?.translatable,
  targetFragment: state.fragment?.selected,
  sourceLanguage: state.user.user.translate_language,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadTranslations: () => dispatch(loadTranslatableFragments()),
  getTargetFragment: (fragmentId) => dispatch(loadFragment({ fragmentId })),
});

const composeContainer = compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TranslationForm);

export default withSnackbar(composeContainer);
