import styled from "styled-components";

const StyledComponent = styled.div`
  position: relative;
  background: none;
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  margin-bottom: -${({ theme }) => theme.spacing(1.5)}px;
  padding: ${({ theme }) => `${theme.spacing(2)}px 0`};
  color: white;
  outline: none;

  .MuiInput-underline:before {
    border-bottom-color: white;
  }

  .subtitles {
    max-height: 300px;
    margin-bottom: 0;

    .subtitle {
      padding-bottom: 8px;
      border-radius: 4px;

      &.active .content .text {
        color: ${({ theme }) => theme.palette.primary.main};
      }

      .MuiInputBase-root {
        color: ${({ theme }) => theme.palette.grey[600]};
      }

      .content {
        border-radius: 4px;
        padding-bottom: 16px;
        color: ${({ theme }) => theme.palette.grey[900]};
      }
    }

    .time-stamps {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.palette.grey[900]};
    }

    .MuiButton-label {
      color: ${({ theme }) => theme.palette.grey[900]};
      font-weight: normal;

      &:hover {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: underline;
      }
    }

    .text {
      font-weight: 700;
      padding: 0 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  & .MuiIconButton-label .MuiSvgIcon-root {
    color: white;
  }

  & .MuiIconButton-colorPrimary .MuiIconButton-label .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & .MuiFormControlLabel-root {
    margin-top: 16px;

    & .MuiFormControlLabel-label {
      font-size: 0.875rem;
    }
  }

  .scroll-sync-checkbox {
    .MuiFormControlLabel-root {
      margin-top: 8px;
    }
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.grey[600]};
    }
  }
`;

export default StyledComponent;
