import Axios from "axios";
import { API_URL } from "features/Common/constants";
import { AllInApi } from "features/Common/services/api.service";

const api = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const listTypes = (fragment) =>
  api.get(`${AllInApi.getApiChannelPath()}/open-ai/socials/${fragment}`);

export const generate = (type, fragment, language, voice, regenerate = false) =>
  api.post(
    `${AllInApi.getApiChannelPath()}/open-ai/socials/${type}/${fragment}`,
    {
      language: language,
      voice: voice,
      regenerate: regenerate ? 1 : 0,
    }
  );

export const translate = (fragment, language, content) =>
  api.post(
    `${AllInApi.getApiChannelPath()}/open-ai/socials/translate/${fragment}`,
    {
      language: language,
      content: content,
    }
  );

export const getPosts = (fragment) =>
  api.get(`${AllInApi.getApiChannelPath()}/open-ai/post/${fragment}`);

export const createPosts = (fragment, data) =>
  api.post(`${AllInApi.getApiChannelPath()}/open-ai/post/${fragment}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
