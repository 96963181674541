import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

/* Components */
import MdEditor from "./MdEditor";

const MDEditorWithControl = (props) => {
  const { control, outputFormatter } = props;
  return (
    <Controller
      as={MdEditor}
      control={control}
      {...props}
      onChange={(values) => outputFormatter(values?.[0]?.text)}
    />
  );
};

MDEditorWithControl.displayName = "MDEditorWithControl";

MDEditorWithControl.defaultProps = {
  outputFormatter: (value) => value,
};
MDEditorWithControl.propTypes = {
  control: PropTypes.shape({}).isRequired,
  outputFormatter: PropTypes.func,
};

export default MDEditorWithControl;
