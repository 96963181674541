/* eslint-disable camelcase */
import { useEffect, useState, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import moment from "moment";

// Utils
import { useAnalytics } from "features/Analytics";

// Components
import Alert from "@material-ui/lab/Alert";
import Button from "components/Button";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import { AutoCompleteWithControl } from "components/Form/AutoComplete";
import { DatePickerWithControl } from "components/Form/DatePicker";
import { MdEditorWithControl } from "components/Form/MdEditor";
import { SelectWithControl } from "components/Form/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Style
import StyledForm, { StyledMenuItem, StyledRevision } from "./style";

// Services
import { inviteTranslator } from "../../services";

// Hooks
import usePreviouslyUsedEmailAddresses from "./usePreviouslyUsedEmailAddresses";

// Constants
import { DEFAULT_DATE_TIME_FORMAT, INVITATION_TYPE } from "../constants";

const InvitationForm = ({
  assetName,
  fragmentId,
  localeCode,
  revisions,
  defaultType,
  defaultFileId,
  onInvitationComplete,
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  // State
  const [inviting, setInviting] = useState(false);
  const [error, setError] = useState(null);

  // Hooks
  const [
    loadEmailAddresses,
    emailAddresses,
    emailAddressesLoading,
    emailAddressesError,
  ] = usePreviouslyUsedEmailAddresses();

  // Form
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required(),
        ...(defaultType === INVITATION_TYPE.PROOFREAD && {
          fileId: yup.number().required(),
        }),
        message: yup.string().required(),
      }),
    [defaultType]
  );

  const {
    handleSubmit,
    control,
    errors,
    watch,
    reset,
    getValues,
    formState,
  } = useForm({
    validationSchema,
    defaultValues: {
      email: "",
      fileId: defaultFileId,
      dueDate: moment().add(14, "days").unix(),
      type: defaultType || INVITATION_TYPE.TRANSLATE,
      message: t("text.INVITE_TRANSLATOR_MESSAGE", {
        assetName,
        language: localeCode,
      }),
    },
  });

  const type = getValues("type");

  // Invoke formState dirty values so that the proxy is
  // trigger/activated. Otherwise formState.isDirty doesn't work
  useEffect(() => {
    if (!formState) return;
    formState.dirtyFields.values();
  }, [formState]);

  useEffect(() => {
    if (defaultType == null) return;

    const messageTranslateKey =
      defaultType === INVITATION_TYPE.TRANSLATE
        ? "text.INVITE_TRANSLATOR_MESSAGE"
        : "text.INVITE_PROOF_READER_MESSAGE";

    reset({
      ...getValues(),
      type: defaultType,
      message: t(messageTranslateKey, {
        assetName,
        language: localeCode,
      }),
    });
  }, [defaultType, assetName, localeCode, t, reset, getValues]);

  useEffect(() => {
    if (defaultFileId == null) return;
    reset({
      ...getValues(),
      fileId: defaultFileId,
    });
  }, [defaultFileId, reset, getValues]);

  // Listeners
  const onEmailAddressOpen = () => {
    loadEmailAddresses();
  };

  const onSubmit = ({ email, message, dueDate, fileId }) => {
    setInviting(true);
    setError(null);

    inviteTranslator({
      fragmentId,
      locale: localeCode,
      ...(fileId && { fileId }),
      email,
      type: defaultType,
      message,
      dueDate: moment.unix(dueDate).format(DEFAULT_DATE_TIME_FORMAT),
    })
      .then((response) => {
        reset();
        onInvitationComplete({
          invitees: response?.data?.data || [],
          type,
        });
        track("subtitle.invite.success", {
          fragmentId,
          localeCode,
          type,
          email,
        });
      })
      .catch(() => {
        setError(
          type === INVITATION_TYPE.TRANSLATE
            ? t("error.TRANSLATOR_INVITE")
            : t("error.PROOFREADER_INVITE")
        );

        track("subtitle.invite.failed", {
          fragmentId,
          localeCode,
          type,
          email,
        });
      })
      .finally(() => {
        setInviting(false);
      });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
      {type === INVITATION_TYPE.PROOFREAD && (
        <SelectWithControl
          name="fileId"
          label={t("labels.SUBTITLE_FILE")}
          control={control}
          variant="outlined"
          size="small"
          error={errors?.fileId?.message}
        >
          {revisions?.length > 0 && (
            <MenuItem value={-1} disabled>
              <em>{t("labels.SELECT_SUBTITLE_FILE")}</em>
            </MenuItem>
          )}
          {!revisions?.length && (
            <MenuItem value={-1} disabled>
              <em>{t("labels.NO_SUBTITLE_FILES")}</em>
            </MenuItem>
          )}
          {revisions?.map(
            ({
              id,
              name,
              created_at: createDate,
              status,
              uploaded_by: user,
            }) => (
              <StyledMenuItem key={id} value={id}>
                <StyledRevision status={status} className={status}>
                  <div className="left-column">
                    <Typography variant="h5">{name}</Typography>
                    <section className="details">
                      <div className="sub-detail">
                        <Typography variant="h6">
                          {moment
                            .unix(createDate)
                            .format("DD MMMM YYYY HH:mm:ss")}
                        </Typography>
                      </div>
                      <div className="divider">/</div>
                      <div className="sub-detail">
                        <Typography variant="h6">
                          {user?.email || user?.name}
                        </Typography>
                      </div>
                    </section>
                  </div>
                  <div className="right-column">
                    <Chip
                      variant="outlined"
                      size="small"
                      label={t(`labels.${status?.toUpperCase()}`)}
                    />
                  </div>
                </StyledRevision>
              </StyledMenuItem>
            )
          )}
        </SelectWithControl>
      )}

      <AutoCompleteWithControl
        name="email"
        label={t("labels.EMAIL_ADDRESS")}
        size="small"
        variant="outlined"
        className="inputField"
        freeSolo
        options={!emailAddressesError ? emailAddresses : []}
        loading={emailAddressesLoading}
        getOptionSelected={(option, value) => option === value}
        control={control}
        onOpen={onEmailAddressOpen}
        autoSelect
        error={errors.email?.message}
      />

      <MdEditorWithControl
        id="message"
        name="message"
        error={errors.message?.message}
        control={control}
        style={{ height: "180px" }}
      />

      <DatePickerWithControl
        control={control}
        name="dueDate"
        timestamp
        label={t("labels.DEADLINE")}
        size="small"
        variant="outlined"
        error={errors.dueDate?.message}
      />

      {error && <Alert severity="error">{error}</Alert>}
      <Button
        type="submit"
        color="primary"
        className="submit-button"
        trackName="subtitles.invite"
        trackDetails={{
          type,
          fragmentId,
          email: watch().email,
        }}
        disableElevation
        loading={inviting}
      >
        {t("buttons.INVITE")}
      </Button>
    </StyledForm>
  );
};

export default memo(InvitationForm);
