import { useEffect, useState } from "react";
import axios from "axios";

// Utils
import { parse } from "subtitle";

const useFetchSubtitle = ({ url }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [subtitleContent, setSubtitleContent] = useState(null);

  // Side effects
  useEffect(() => {
    if (!url) return;
    setLoading(true);
    axios({
      url,
      method: "GET",
      responseType: "text", // important
    })
      .then((response) => {
        setLoading(false);
        const subtitleParsed = parse(response.data);
        setSubtitleContent(subtitleParsed);
      })
      .catch((networkError) => {
        setLoading(false);
        setError(networkError);
      });
  }, [url]);

  return [loading, error, subtitleContent];
};

export default useFetchSubtitle;
