import { takeLatest, all, call, put } from "redux-saga/effects";

import * as UserTypes from "features/Auth/User.types";

import { getFileTypes } from "./services";
import { loadFiles, loadFilesSuccess, loadFilesFailed } from "./reducer";

export function* startUpAfterLogin() {
  yield put(loadFiles());
}

export function* loadFilesSaga({ payload }) {
  try {
    const result = yield call(getFileTypes, payload);
    yield put(loadFilesSuccess(result.data));
  } catch (error) {
    yield put(loadFilesFailed(error));
  }
}

function* sagas() {
  return yield all([
    takeLatest(UserTypes.GET_USER, startUpAfterLogin),
    takeLatest(loadFiles.type, loadFilesSaga),
  ]);
}

export default sagas;
