import { useState, useEffect, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";

import {
  Paper,
  List,
  ListSubheader,
  Grid,
  ListItemText,
  ListItemIcon,
  ListItem,
  Button,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/Create";
import CircularProgressCenter from "../../../../components/CircularProgressCenter/CircularProgressCenter";

import { getRole, getPermissions, saveRole } from "../../Users/role.service";
import AllInDialog from "../../../../components/Dialog/AllInDialog";
import RoleForm from "../Form/Form";

const ViewContainer = ({ match }) => {
  const [role, setRole] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const { t } = useTranslation();
  const [dialogDisplay, setDialogDisplay] = useState(false);
  const [loading, setLoading] = useState(true);

  const getRolesAndPermissions = useCallback(() => {
    Promise.all([getRole(match.params.id), getPermissions(match.params.id)])
      .then((responses) => {
        const [roleResponse, permissionsResponse] = responses;
        setLoading(false);
        setRole(roleResponse.data.data);
        setCheckedPermissions(roleResponse.data.data.permissions);
        setPermissions(permissionsResponse.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [match.params.id, setRole, setCheckedPermissions]);
  useEffect(() => {
    getRolesAndPermissions();
  }, [getRolesAndPermissions]);

  if (loading) return <CircularProgressCenter />;
  if (!role) return null;

  const onEditRoleSave = (updatedRole) => {
    saveRole(updatedRole).then((response) => {
      setRole(response.data.data);
      setCheckedPermissions(response.data.data.permissions);
    });
  };

  const handleToggle = (value) => () => {
    const currentIndex = checkedPermissions.findIndex(
      (item) => item.id === value.id
    );
    const newChecked = [...checkedPermissions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    saveRole({
      ...role,
      permissions: newChecked,
    }).then((response) => {
      setCheckedPermissions(response.data.data.permissions);
    });

    setCheckedPermissions(newChecked);
  };

  return (
    <Grid container style={{ overflow: "auto" }}>
      <Grid item xs={6} sm={6}>
        <Paper>
          <div style={{ paddingBottom: "10px", paddingLeft: "10px" }}>
            <List
              subheader={
                <ListSubheader component="div" className="subheader">
                  {t("labels.DATA_FROM")}
                  {t("labels.ROLE")}
                </ListSubheader>
              }
            />
            <ListItem>
              <ListItemIcon>
                <span>{t("labels.NAME")}</span>
              </ListItemIcon>
              <ListItemText
                style={{ marginLeft: "25px" }}
                primary={role.name}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <span>{t("labels.SLUG")}</span>
              </ListItemIcon>
              <ListItemText
                style={{ marginLeft: "25px" }}
                primary={role.slug}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <span>{t("labels.DESCRIPTION")}</span>
              </ListItemIcon>
              <ListItemText
                style={{ marginLeft: "5px" }}
                primary={role.description}
              />
            </ListItem>

            <Grid container direction="row" justify="space-between">
              <Button
                onClick={() => setDialogDisplay(true)}
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
              >
                {t("buttons.CHANGE")}
              </Button>

              <AllInDialog
                title={t("buttons.CHANGE_RESOURCE", {
                  resource: role.name,
                })}
                open={dialogDisplay}
                model={role}
                onClose={() => setDialogDisplay(false)}
                onSave={onEditRoleSave}
              >
                <RoleForm />
              </AllInDialog>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Paper>
          <div>
            <List
              subheader={
                <ListSubheader component="div" className="subheader">
                  {t("labels.DATA_FROM")}
                  {t("labels.PERMISSIONS")}
                </ListSubheader>
              }
              className="checkList"
            >
              {permissions.map((permission) => {
                const labelId = `checkbox-list-label-${permission.id}`;
                const checked =
                  checkedPermissions.findIndex(
                    (item) => item.id === permission.id
                  ) !== -1;

                return (
                  <ListItem
                    key={permission.id}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(permission)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked}
                        tabIndex={-1}
                        disableRipple
                        color="primary"
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={permission.name} />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default memo(ViewContainer);
