import { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// Icons
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

// Components
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import Label from "components/Label";
import TextWithHighlight from "components/ElasticSearch/TextWithHighlight";
import Tooltip from "@material-ui/core/Tooltip";

const getWarningMessage = ({
  isAvailableInCountry,
  nameInCurrentLanguage,
  locale,
  language,
  translations,
}) => {
  if (isAvailableInCountry && !nameInCurrentLanguage) {
    return translations.missingName(language);
  }
  if (!isAvailableInCountry) {
    return translations.contractNotAvailable(locale);
  }
  return null;
};

const NameCell = ({
  quality,
  schedule,
  name,
  locale,
  language,
  highlight,
  identifier,
  translations,
}) => {
  const isAvailableInCountry = Boolean(schedule[locale]);
  const nameInCurrentLanguage = name[language];
  const warningMessage = getWarningMessage({
    isAvailableInCountry,
    nameInCurrentLanguage,
    locale,
    language,
    translations,
  });
  const cellClassNames = classNames("cell--name", {
    "not-available": !isAvailableInCountry,
  });

  return (
    <TableCell className={cellClassNames} component="td" scope="row">
      <div>
        <Typography variant="h6">
          {nameInCurrentLanguage && (
            <TextWithHighlight
              highlight={highlight}
              fieldId={`name.${language}`}
              text={nameInCurrentLanguage}
            />
          )}
          {!nameInCurrentLanguage && (
            <TextWithHighlight
              highlight={highlight}
              fieldId="identifier"
              text={identifier}
            />
          )}
        </Typography>
        <Label value={quality || "-"} style={{ marginLeft: 16 }} />
        {warningMessage && (
          <Tooltip title={warningMessage}>
            <ReportProblemOutlinedIcon
              className="icon--not-available"
              size={10}
            />
          </Tooltip>
        )}
      </div>
    </TableCell>
  );
};

NameCell.defaultProps = {
  translations: {
    missingName: (language) => `Missing name in ${language}`,
    contractNotAvailable: (locale) => `Contract not available in ${locale}`,
  },
};

NameCell.propTypes = {
  locale: PropTypes.string,
  language: PropTypes.string,
  name: PropTypes.shape({}),
  identifier: PropTypes.string,
  quality: PropTypes.string,
  schedule: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  highlight: PropTypes.shape({}),
  translations: PropTypes.shape({
    missingName: PropTypes.func,
    contractNotAvailable: PropTypes.func,
  }),
};

export default memo(NameCell);
