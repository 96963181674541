import createReducer from "../../redux/createReducer";
import * as types from "./User.types";
import * as reducers from "./User.reducers";
import { INITIAL_STATE } from "./User.state";

export const reducer = createReducer(INITIAL_STATE, {
  [types.SET_LOGIN_ERROR]: reducers.loginSetError,
  [types.SET_USER]: reducers.setUser,
  [types.SET_USER_LANGUAGE]: reducers.setUserLanguage,
  [types.SET_USER_LANGUAGE_SUCCESS]: reducers.setUserLanguageSuccess,
  [types.SET_USER_COUNTRY]: reducers.setUsercountry,
  [types.SET_USER_COUNTRY_SUCCESS]: reducers.setUserCountrySuccess,
  [types.SET_USER_TRANSLATE_LANGUAGE]: reducers.setUserTranslateLanguage,
  [types.SET_USER_TRANSLATE_LANGUAGE_SUCCESS]:
    reducers.setUserTranslateLanguageSuccess,
  [types.LOGOUT]: reducers.logout,
  [types.LOGOUT_SUCCESS]: reducers.logoutSuccess,
});
