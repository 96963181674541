import mixPanel from "mixpanel-browser";

const log = (type, name, props) => {
  if (process.env.NODE_ENV !== "development") return;
  // eslint-disable-next-line no-console
  console.log(type, name, props);
};

const hasToken = () => process.env.REACT_APP_ANALYTICS_TOKEN !== undefined;

export const init = () => {
  const apiKey = process.env.REACT_APP_ANALYTICS_TOKEN;
  if (!hasToken()) {
    // eslint-disable-next-line no-console
    console.warn("Analytics APP_ANALYTICS_TOKEN not found.");
    return;
  }
  mixPanel.init(apiKey);
};

export const track = (name, props) => {
  if (!hasToken()) return;
  try {
    log("TRACK: ", name, props);
    mixPanel.track(name, props);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

export const identify = (id) => {
  if (!hasToken()) return;
  try {
    log("IDENTIFY: ", id);
    mixPanel.identify(id);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

export const setIdentity = (userInfo) => {
  if (!hasToken()) return;
  try {
    log("SET_IDENTIFY: ", userInfo);
    mixPanel.people.set(userInfo);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};
