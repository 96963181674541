import grey from "@material-ui/core/colors/grey";

const style = {
  layoutFill: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    width: "100%",
    flexGrow: 1,
  },
  topHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 15,
    backgroundColor: "rgba(0 , 0, 0, .02)",
    borderBottom: `1px solid ${grey[300]}`,
    height: 56,
  },
  titleHeader: {
    display: "flex",
    width: "25%",
    fontWeight: "bold",
  },
};

export default style;
