import { useMemo, memo } from "react";
import PropTypes from "prop-types";

// Icons
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Components
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";

// Utils
import { convertSecondsToHuman } from "features/Fragment/utils";

const DurationCell = ({
  id,
  fragmentCount,
  fragmentDuration,
  isExpanded,
  onExpandRow,
  translations,
}) => {
  const fragmentDurationFormatted = useMemo(
    () => convertSecondsToHuman(fragmentDuration, true),
    [fragmentDuration]
  );

  return (
    <TableCell className="cell--duration">
      <div>
        <Typography variant="body1">
          {fragmentCount} /<strong>{fragmentDurationFormatted}</strong>{" "}
          <small>{translations.minutesAbbreviation}</small>
        </Typography>
        {Boolean(fragmentCount) && (
          <IconButton
            size="small"
            aria-label="expand row"
            onClick={(event) => onExpandRow(event, id)}
          >
            {isExpanded ? (
              <ExpandLessIcon size="small" />
            ) : (
              <ExpandMoreIcon size="small" />
            )}
          </IconButton>
        )}
      </div>
    </TableCell>
  );
};

DurationCell.defaultProps = {
  onExpandRow: () => null,
  translations: {
    minutesAbbreviation: "min",
  },
};

DurationCell.propTypes = {
  id: PropTypes.number,
  fragmentCount: PropTypes.number,
  fragmentDuration: PropTypes.number,
  isExpanded: PropTypes.bool,
  onExpandRow: PropTypes.func,
  translations: PropTypes.shape({
    minutesAbbreviation: PropTypes.string,
  }),
};

export default memo(DurationCell);
