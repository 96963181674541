import styled from "styled-components";

const StyledComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.background.colorDark};

  .card {
    width: 400px;
  }

  .logoContainer {
    padding: 30px 0;
  }

  .centerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  .inputContainer {
    margin-top: "15px";
  }

  form {
    width: 100%;
  }

  .MuiFormHelperText-root.Mui-error {
    text-align: center;
    font-size: 0.875rem;
  }
`;

export default StyledComponent;
