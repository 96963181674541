import { takeLatest, all } from "redux-saga/effects";
import * as AssetTypes from "./Asset.types";
import * as AssetSagas from "./Asset.sagas";
import * as assetApi from "./services";

import * as ChannelTypes from "../Channel/Channel.types";

function* sagas() {
  return yield all([
    takeLatest(ChannelTypes.SET_SELECTED, AssetSagas.reset),
    takeLatest(AssetTypes.FIND, AssetSagas.loadAssets, assetApi),
    takeLatest(AssetTypes.FIND_MORE, AssetSagas.loadMoreAssets, assetApi),
    takeLatest(AssetTypes.FIND_ASSET, AssetSagas.getAssetById, assetApi),
    takeLatest(
      AssetTypes.FIND_ASSET_SUCCESS,
      AssetSagas.findByIdSuccess,
      assetApi
    ),
    takeLatest(AssetTypes.SET_SORT, AssetSagas.loadAssets, assetApi),
    takeLatest(
      AssetTypes.UPDATE_QUALITY_CHECKS,
      AssetSagas.updateQualityChecks
    ),
    takeLatest(AssetTypes.PATCH_LABELS, AssetSagas.patchLabels),
  ]);
}

export default sagas;
