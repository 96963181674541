import { memo, useState } from "react";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Link, useRouteMatch } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import dcLogo from "../../features/Common/img/favicon-32x32.png";

import StyledComponent, { StyledMenu } from "./SelectChannel.style";

const SelectChannel = ({ disabled }) => {
  const [anchorElCountry, setAnchorElCountry] = useState(null);
  const { url, path } = useRouteMatch();

  const channel = useSelector((state) => state.channel?.data) || {};
  const channels = useSelector((state) => state.user?.user?.channels) || [];

  const channelColor = channel.color || "#15b1d7";
  const logo = channel.icon_url || dcLogo;

  const handleClick = (event) => {
    if (disabled) return;
    setAnchorElCountry(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElCountry(null);
  };

  const linkUrl = (item) => {
    if (
      path === "/channel/:channelId/:type(series|video)/:assetId" ||
      path ===
        "/channel/:channelId/:type(series|video)/:assetId/fragment/:fragmentId"
    )
      return `/channel/${item.id}/video`;

    const match = /^\/channel\/([0-9]+)\//i;
    if (match.test(path)) {
      return path.replace(/^\/channel\/([0-9]+)\//i, `/channel/${item.id}/`);
    }

    return `/channel/${item.id}/video`;
  };

  return (
    <StyledComponent>
      <Button
        aria-owns={anchorElCountry ? "menu-country" : undefined}
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
        className="channel-box"
        style={{ background: channelColor }}
      >
        <img
          src={logo}
          alt={channel?.name || "BeyondDutch"}
          className="logo-big"
          style={channel?.name ? { filter: "brightness(0) invert(1)" } : null}
        />

        {channel?.name || "CHANNELS"}
        <KeyboardArrowDownIcon style={{ opacity: 0.7, marginLeft: 8 }} />
      </Button>
      <StyledMenu
        id="menu-country"
        anchorEl={anchorElCountry}
        open={Boolean(anchorElCountry)}
        onClose={handleClose}
      >
        <Link to="/" className="link">
          <MenuItem onClick={handleClose} selected={url === "/"}>
            <div
              className="channel-overview-button"
              style={{
                display: "flex",
                paddingTop: 8,
                paddingBottom: 8,
                fontWeight: "bold",
              }}
            >
              <ArrowBackIcon className="arrow-back-icon" />
              Channel overzicht
            </div>
          </MenuItem>
        </Link>
        {channels.map((item) => (
          <Link to={linkUrl(item)} key={item.id} className="link">
            <MenuItem
              selected={channel.id === item.id}
              onClick={handleClose}
              className="link"
            >
              <div
                style={{
                  display: "flex",
                  paddingTop: 4,
                  paddingBottom: 4,
                  alignItems: "center",
                }}
              >
                <img
                  src={item.icon_url || dcLogo}
                  alt={item.name}
                  className="logo-small"
                />
                <div>{item.name}</div>
              </div>
            </MenuItem>
          </Link>
        ))}
      </StyledMenu>
    </StyledComponent>
  );
};

SelectChannel.defaultProps = {
  disabled: false,
};

export default memo(SelectChannel);
