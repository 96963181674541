import { AllInApi } from "../Common/services/api.service";
import * as Constants from "../Common/constants";
import { API_PATH } from "../Common/constants";

const api = AllInApi.getApi();

export const login = async (email, password, remember = false) => {
  await api.get(Constants.API_AUTH_CSRF_ENDPOINT);

  const body = {
    email,
    password,
    ...(remember && { remember }),
  };

  return api.post(Constants.API_AUTH_LOGIN_ENDPOINT, body);
};

export const logout = async () => {
  await api.get(Constants.API_AUTH_CSRF_ENDPOINT);
  return api.post(Constants.API_AUTH_LOGOUT_ENDPOINT);
};

export const getUser = (id) => {
  if (id) return api.get(Constants.API_ENDPOINT_USER.replace(":userId", id));

  return api.get(Constants.API_ENDPOINT_USER_CURRENT);
};

export const getUsers = () => api.get(Constants.API_ENDPOINT_USERS);

export const setLanguage = (language) =>
  api.post(Constants.API_ENDPOINT_USER_SET_LOCALE, {
    default_language: language,
  });

export const setCountry = (country) =>
  api.post(Constants.API_ENDPOINT_USER_SET_LOCALE, {
    default_country: country,
  });

export const setTranslateLanguage = (language) =>
  api.post(Constants.API_ENDPOINT_USER_SET_LOCALE, {
    translate_language: language,
  });

export const updateUser = (user) =>
  api.put(Constants.API_ENDPOINT_USER.replace(":userId", user.id), user, {
    crossDomain: true,
    headers: { A: "B" },
  });

export const deleteUser = (userId) =>
  api.delete(Constants.API_ENDPOINT_USER.replace(":userId", userId));

export const restoreUser = (userId) =>
  api.post(Constants.API_ENDPOINT_USER_RESTORE.replace(":userId", userId));

export const deleteChannel = (userId, channelId) =>
  api.delete(
    Constants.API_ENDPOINT_USERS_CHANNEL.replace(":userId", userId).replace(
      ":channelId",
      channelId
    )
  );

export const addChannel = (userId, channelId) =>
  api.post(
    Constants.API_ENDPOINT_USERS_CHANNEL.replace(":userId", userId).replace(
      ":channelId",
      channelId
    )
  );

export const deleteRole = (userId, roleId) =>
  api.delete(
    Constants.API_ENDPOINT_USERS_ROLE.replace(":userId", userId).replace(
      ":roleId",
      roleId
    )
  );

export const addRole = (userId, roleId) =>
  api.post(
    Constants.API_ENDPOINT_USERS_ROLE.replace(":userId", userId).replace(
      ":roleId",
      roleId
    )
  );

export const deleteCountry = (userId, countryId) =>
  api.delete(
    Constants.API_ENDPOINT_USERS_COUNTRY.replace(":userId", userId).replace(
      ":countryId",
      countryId
    )
  );

export const addCountry = (userId, countryId) =>
  api.post(
    Constants.API_ENDPOINT_USERS_COUNTRY.replace(":userId", userId).replace(
      ":countryId",
      countryId
    )
  );

export const validateActivation = (token) =>
  api.get(`${API_PATH}/users/activate/token/${token}`);

export const updateActivation = (user, data) =>
  api.post(`${API_PATH}/users/${user}/activate/token`, data);

export const passwordResetCreate = async (email) => {
  await api.get(Constants.API_AUTH_CSRF_ENDPOINT);
  return api.post("/forgot-password", { email });
};

export const passwordReset = async (body) => {
  await api.get(Constants.API_AUTH_CSRF_ENDPOINT);
  return api.post("/reset-password", body);
};

export const confirmedPasswordStatus = async () => {
  return api.get("user/confirmed-password-status");
};

export const confirmedPassword = async (password) => {
  return api.post("/user/confirm-password", { password });
};

export const twoFactorAuthenticationEnable = async () => {
  return api.post("/user/two-factor-authentication");
};

export const twoFactorAuthenticationDisable = async () => {
  return api.delete("/user/two-factor-authentication");
};

export const twoFactorQrCode = async () => {
  return api.get("/user/two-factor-qr-code");
};

export const twoFactorRecoveryCodes = async () => {
  return api.get("/user/two-factor-recovery-codes");
};

export const twoFactorChallenge = async (body) => {
  return api.post("/two-factor-challenge", body);
};
