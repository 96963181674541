import { forwardRef, Fragment, useEffect, useState } from "react";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { searchEntry } from "../SeoDetail/services";
import useDebounce from "../Hooks/useDebounce";

const SeoReferenceField = forwardRef(
  (
    { contenttypes: contentTypes, errors, field, language, value, onChange },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 200);

    useEffect(() => {
      if (!open) {
        setOptions([]);
        setLoading(false);
      } else {
        setLoading(true);

        (async () => {
          try {
            const filteredContentTypes = contentTypes.filter(
              (type) =>
                Array.isArray(field?.validation?.in) &&
                field.validation.in.includes(type.identifier)
            );

            const {
              data: { data: response },
            } = await searchEntry({
              query: debouncedSearchTerm,
              filters: { type: filteredContentTypes.map((type) => type.id) },
            });

            setOptions(response);
            setLoading(false);
          } catch (e) {
            setOptions([]);
            setLoading(false);
          }
        })();
      }
    }, [open, debouncedSearchTerm, field.validation.in, contentTypes]);

    return (
      <Autocomplete
        multiple
        value={value || []}
        loading={loading}
        options={options}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
        }}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(e, data) => onChange(data)}
        getOptionLabel={(option) => {
          if (typeof option?.content?.title === "object") {
            if (option?.content?.title?.[language]) {
              return option?.content?.title?.[language];
            }
            if (option?.content?.title?.["en"]) {
              return option?.content?.title?.["en"];
            }
            if (Object.values(option?.content?.title)?.[0]) {
              return Object.values(option?.content?.title)?.[0];
            }
          }

          return "";
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={ref}
            label={field.name}
            variant="outlined"
            fullWidth
            error={!!errors}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    );
  }
);

export default SeoReferenceField;
