import { AllInApi } from "features/Common/services/api.service";

const api = AllInApi.getApi();

export const PAGE_SIZE = 15;

export const getEntryList = ({ query, page, sortModel, filters }) => {
  return api.post(`${AllInApi.getApiChannelPath()}/seo/entry/search`, {
    query,
    filters,
    page,
    perPage: PAGE_SIZE,
    sortBy: sortModel ? sortModel[0]?.field : "title.nl",
    sortByDesc: sortModel && sortModel[0]?.sort === "desc",
  });
};

export const getListContentType = ({ id }) => {
  return api.get(`${AllInApi.getApiChannelPath()}/seo/content-type`, {
    id,
  });
};
