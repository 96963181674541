import { Component } from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles/index";
import PropTypes from "prop-types";

import { assetTranslateStats } from "../../Asset/services";
import styles from "./TranslateProgressBar.styles";

class TranslateProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      translated: 0,
      total: 0,
    };
  }

  componentDidMount() {
    assetTranslateStats().then((response) => this.setState(response.data));
  }

  render() {
    const { classes } = this.props;
    const { translated, total } = this.state;

    return (
      <div className={classes.bar}>
        <div
          className={classes.done}
          style={{ width: `${(translated / total) * 100}%` }}
        />
        <span className={classes.text}>
          {translated}/{total}
        </span>
      </div>
    );
  }
}

TranslateProgressBar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default compose(withStyles(styles))(TranslateProgressBar);
