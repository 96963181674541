import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Services
import { getAllStates } from "features/Asset/services";

// Redux
import { updateState } from "../reducer";

const useStateButton = ({ fragment, channel }) => {
  const dispatch = useDispatch();

  // State
  const [states, setStates] = useState([]);

  // Selectors
  const state = fragment?.state;
  const fragmentId = fragment?.id;
  const channelId = channel?.id;

  // Listeners
  const onStateChange = (state) => {
    dispatch(
      updateState({
        fragmentId,
        channelId,
        state,
        previousState: fragment.state,
      })
    );
  };

  // Data fetch
  useEffect(() => {
    getAllStates().then((states) => setStates(states.data));
  }, [setStates]);

  return [state, states, onStateChange];
};

export default useStateButton;
