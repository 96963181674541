import { useMemo, forwardRef, cloneElement, Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { FixedSizeList } from "react-window";

import { Field } from "formik";

import CustomTextField from "../Formik/TextField";
import CustomInlineDatePicker from "../Formik/InlineDatePicker";
import Utils from "../../features/Common/utils";

import styles from "./style";

function renderRow(props) {
  const { data, index, style } = props;

  return cloneElement(data[index], {
    style: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "block",
      ...style,
    },
  });
}

const ListboxComponent = forwardRef((props, ref) => {
  const { children } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;

  const outerElementType = useMemo(
    () => forwardRef((props2, ref2) => <div ref={ref2} {...props2} />),
    []
  );

  return (
    <div ref={ref}>
      <FixedSizeList
        style={{
          padding: 0,
          height: Math.min(8, itemCount) * itemSize,
          maxHeight: "auto",
        }}
        itemData={children}
        height={250}
        width="100%"
        outerElementType={outerElementType}
        innerElementType="ul"
        overscanCount={5}
        itemSize={itemSize}
        itemCount={itemCount}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

class FragmentGenericFields extends Component {
  render() {
    const { fields, errors, classes } = this.props;

    return (
      <fragment>
        {fields.map((field) => {
          switch (field.type) {
            case "select":
              return (
                <Field
                  id={`values[${field.id}]`}
                  name={`values[${field.id}]`}
                  variant="outlined"
                  key={field.id}
                  label={field.name}
                  options={[...field.values]}
                  errorText={
                    Utils.Common.flattenObject(errors)[`values[${field.id}]`]
                  }
                  required
                  component={Autocomplete}
                  style={{ marginTop: 30 }}
                  ListboxComponent={ListboxComponent}
                  multiple={!!field.multiple}
                  disableCloseOnSelect={!!field.multiple}
                  filterSelectedOptions={!!field.multiple}
                  fullWidth
                  autoHighlight
                  classes={classes}
                  getOptionLabel={(option) => option.name || option.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={field.name}
                      fullWidth
                    />
                  )}
                />
              );

            case "date":
              return (
                <Field
                  id={`values[${field.id}].value`}
                  name={`values[${field.id}].value`}
                  variant="outlined"
                  key={field.id}
                  component={CustomInlineDatePicker}
                  label={field.name}
                  errorText={
                    Utils.Common.flattenObject(errors)[
                      `values[${field.id}].value`
                    ]
                  }
                  required={Boolean(field.required)}
                  fullWidth
                />
              );

            case "textarea":
              return (
                <Field
                  id={`values[${field.id}].value`}
                  name={`values[${field.id}].value`}
                  variant="outlined"
                  key={field.id}
                  component={CustomTextField}
                  label={field.name}
                  errorText={
                    Utils.Common.flattenObject(errors)[
                      `values[${field.id}].value`
                    ]
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required={Boolean(field.required)}
                  fullWidth
                  multiline
                />
              );

            default:
              return (
                <Field
                  id={`values[${field.id}].value`}
                  name={`values[${field.id}].value`}
                  variant="outlined"
                  key={field.id}
                  component={CustomTextField}
                  label={field.name}
                  errorText={
                    Utils.Common.flattenObject(errors)[
                      `values[${field.id}].value`
                    ]
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required={Boolean(field.required)}
                  fullWidth
                />
              );
          }
        })}
      </fragment>
    );
  }
}

FragmentGenericFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errors: PropTypes.shape({}).isRequired,
};

export default compose(withStyles(styles))(FragmentGenericFields);
