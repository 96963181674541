import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

const style = {
  background: {
    color: "#F6F6F6",
    colorDark: "#555555",
    sidebar: grey[100],
    lightAccent: "rgba(0, 0, 0, 0.10)",
  },

  primary: {
    main: "#15b1d7",
    contrastText: "#fff",
  },

  error: {
    main: red[500],
  },

  success: {
    main: green[500],
  },
};

export default style;
