/* eslint-disable react/display-name */

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Hooks
import { useQuery } from "features/Common/hooks";

// Icons
import CloseIcon from "@material-ui/icons/Close";

// UI
import { Container } from "@material-ui/core";

import FragmentJobs from "features/Fragment/FragmentJobs";

// Style
import StyledComponent, { AppBarStyled } from "./style";

// Available edit panels
const panels = {
  jobs: {
    label: "menuItems.TASK",
    disablePadding: true,
    Panel: () => <FragmentJobs />,
  },
  default: {
    Panel: () => <span>Panel not defined</span>,
  },
};

const ChannelSettingsDrawer = () => {
  const { t } = useTranslation();

  const query = useQuery();
  const panel = query.get("panel") || "default";

  const panelData = panels[panel?.toLowerCase()] || {};
  const { label, Panel } = panelData;
  const closeUrl = "#";

  if (!panel) {
    return null;
  }

  return (
    <StyledComponent>
      <AppBarStyled position="relative" className="withTabBar">
        {t(label)}
        <Link to={closeUrl}>
          <CloseIcon />
        </Link>
      </AppBarStyled>
      <Container
        className={`fragmentDrawerContainer ${
          panelData.disablePadding && "disablePadding"
        }`}
        maxWidth={false}
      >
        {Panel && <Panel />}
      </Container>
    </StyledComponent>
  );
};

export default ChannelSettingsDrawer;
