import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";

const StyledComponent = styled.form`
  .submit-button {
    margin-top: 12px;

    & button {
      width: auto;
    }
  }

  .MuiFormControl-root {
    display: flex;
    margin-bottom: 8px;
  }

  .MuiOutlinedInput-input {
    padding: 10px 32px 10px 14px;
  }

  .MuiFormLabel-filled + .MuiInputBase-root {
    .MuiOutlinedInput-notchedOutline legend {
      width: 68px !important;
    }
  }

  .MuiInputLabel-outlined {
    transform: translate(12px, 12px) scale(1);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }

  .MuiInputBase-input,
  .MuiInputBase-root {
    background: white;
  }

  .MuiAlert-root {
    margin: 8px 0;
  }
`;

export const StyledRevision = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  .MuiTypography-h5 {
    font-size: 0.875rem;
    font-weight: bold;
    white-space: break-spaces;
    margin-right: 12px;
  }

  .details {
    display: flex;
    flex-wrap: wrap;

    .divider {
      margin: 0 4px;
      opacity: 0.6;
    }

    .MuiTypography-h6 {
      font-size: 0.75rem;
      line-height: 1;
    }
  }

  .sub-detail {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.grey[600]};

    & .MuiSvgIcon-root {
      font-size: 0.75rem;
      margin-right: 4px;
      color: ${({ theme }) => theme.palette.grey[600]};
    }
  }

  .MuiChip-root {
    border-radius: 4px;
  }

  & .MuiChip-root {
    background: ${({ theme, status }) => theme.palette.status[status].main};
    color: ${({ theme, status }) => theme.palette.status[status].contrastText};
    border-color: ${({ theme, status }) => theme.palette.status[status].main};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex: 1;
`;

export default StyledComponent;
