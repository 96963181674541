import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { defaultList } from "../FragmentSocialPost";
import { useEffect, useState } from "react";
import { first } from "lodash";
import * as Constants from "../../../Common/constants";
import moment from "moment";
import { OpenInNewOutlined } from "@material-ui/icons";

const itemColor = (state) => {
  switch (state) {
    case "draft":
      return "#e9c46a";
    case "pending":
    case "in_progress":
      return "#264653";
    case "published":
      return "#2a9d8f";
    case "failed":
      return "#e76f51";
    default:
      return "#e9ecf7";
  }
};

export const getMediaPath = (file) =>
  `${
    Constants.API_URL.endsWith(".test") ? Constants.API_URL : Constants.CDN_URL
  }/${Constants.PATH_STORAGE}/${file}`;

const TimelineItem = ({ item, index }) => {
  const [type, setType] = useState(null);
  const [color, setColor] = useState("#e9ecf7");

  useEffect(() => {
    setType(
      first(defaultList.filter((type) => type.type === item.type) || []) || null
    );

    setColor(itemColor(item.state));
  }, [item]);

  return (
    <Card
      sx={{
        marginTop: index === 0 ? 0 : 2,
        boxShadow: "none",
        padding: "0",
        position: "relative",
        backgroundColor: "white",
        borderWidth: 2,
        borderRadius: "4px",
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        transition: "border .24s ease-in-out",
      }}
    >
      <Grid container alignContent="center" direction="row">
        <Grid item>
          <CardMedia
            component={item.media_path?.endsWith(".mp4") ? "video" : "img"}
            sx={{ width: 151 }}
            src={getMediaPath(item.media_path)}
            alt={`Media for ${item.type} (${item.locale})`}
          />
        </Grid>
        <Grid item xs>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Grid
                container
                alignContent="center"
                direction="column"
                spacing={"12px"}
              >
                <Grid item>
                  <Grid container alignItems="center" spacing={"8px"}>
                    {type && (
                      <Grid item>
                        <type.icon
                          style={{
                            color: type.color,
                            display: "block",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs>
                      <Grid container spacing={"4px"} alignItems="baseline">
                        <Grid item>
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              textTransform: "capitalize",
                              fontWeight: "bold",
                            }}
                          >
                            {item.type}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography
                            variant="body2"
                            style={{
                              color: "#ccc",
                            }}
                          >
                            -
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography
                            variant="body2"
                            style={{
                              textTransform: "uppercase",
                              letterSpacing: "1px",
                              fontSize: "12px",
                              color: "#666",
                              fontWeight: "bold",
                            }}
                          >
                            {item.locale}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {item.state === "published" && item.link && (
                      <Grid item>
                        <Tooltip title="Open post">
                          <IconButton
                            aria-label="open"
                            href={item.link}
                            target="_blank"
                            style={{ padding: 0 }}
                          >
                            <OpenInNewOutlined />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography variant="body2" component="div">
                    {item.content?.tweet}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" spacing={"8px"}>
                    <Grid item>
                      <Chip
                        avatar={
                          ["pending", "in_progress"].includes(item.state) ? (
                            <CircularProgress
                              size={18}
                              thickness={4}
                              style={{
                                backgroundColor: "transparent",
                                color: "white",
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }
                        label={item.state ? item.state?.replace("_", " ") : ""}
                        size="small"
                        sx={{
                          color: "white",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          backgroundColor: color,
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{
                          color: "#666",
                        }}
                      >
                        &#x2022;
                      </Typography>
                    </Grid>

                    <Grid item xs>
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: "12px",
                          color: "#666",
                        }}
                      >
                        {item.published_at ? (
                          <span>
                            Published on{" "}
                            {moment
                              .unix(item.published_at)
                              .format("D MMMM YYYY, HH:mm:ss")}
                          </span>
                        ) : (
                          <span>
                            Created on{" "}
                            {moment
                              .unix(item.created_at)
                              .format("D MMMM YYYY, HH:mm:ss")}
                          </span>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TimelineItem;
