import styled from "styled-components";

const StyledControls = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px;
  color: white;

  .MuiButtonBase-root {
    color: white;
  }
  .MuiSvgIcon-root {
    color: white;
    font-size: 1.5rem;
  }

  .buttons {
    .MuiButton-text {
      padding: 0 8px;
    }

    .Mui-disabled {
      color: gray;
    }
  }

  .seek-times {
    & .MuiButtonBase-root {
      min-width: auto;
      color: ${({ theme }) => theme.palette.grey[500]};
      padding: 0px 8px;
      text-transform: lowercase;

      &.active {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  .time-controls {
    display: flex;
    align-items: center;
    margin-top: 4px;

    .progress {
      display: flex;
      align-items: center;
      margin-left: 16px;
      margin-right: 16px;
      min-width: 400px;
    }

    .MuiLinearProgress-root {
      position: relative;
      border-radius: 4px;
      margin: 0 16px;
      width: 100%;
      background-color: #5f5f5f;

      .MuiLinearProgress-bar {
        background: #a2a2a2;
      }
    }
  }
`;

export default StyledControls;
