import styled from "styled-components";

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & .MuiGrid-item {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
  .MuiTypography-root {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.grey[600]};
    font-size: 0.875rem;
  }
  .MuiTypography-h5 {
    color: ${({ theme }) => theme.palette.grey[700]};
    font-weight: 700;

    .MuiSvgIcon-root {
      margin-right: 2px;
    }
  }
  .MuiTypography-h6 {
    margin-bottom: 4px;
  }

  & .button-items {
    padding: 0;
  }

  & .add-button > button {
    background: none;
    border: 1px dashed ${({ theme }) => theme.palette.grey[500]};
    justify-content: flex-start;
    font-weight: bold;

    &:hover {
      color: white;
      background: ${({ theme }) => theme.palette.primary.main};
      border: 1px dashed ${({ theme }) => theme.palette.primary.main};
    }
  }

  & .add-button.prepend {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  & .add-button.append {
    margin-bottom: 24px;
    margin-top: 8px;
  }
`;

export default StyledComponent;
