import { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./Fragment.style";

class Fragment extends Component {
  render() {
    const { classes, fill, children } = this.props;

    if (fill) return <div className={classes.fill}>{children}</div>;

    return <>{children}</>;
  }
}

Fragment.defaultProps = {
  fill: false,
};

export default withStyles(styles)(Fragment);
