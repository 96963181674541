import { useEffect, memo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Hooks
import { usePersistentState } from "features/Common/hooks";

// Icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// Components
import { Helmet } from "react-helmet";
import CircularProgressCenter from "components/CircularProgressCenter";
import Fab from "components/Fab";

// Local Components
import AssetSidebar from "../AssetDetailAssetSidebar";
import FragmentSidebar from "../AssetDetailFragmentSidebar";

// Data
import { findById, resetAsset } from "../Asset.actions";
import { getCurrentChannel } from "../../Channel/selectors";

// Style
import StyledComponent from "./style";

const AssetContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  // State
  const [menuOpen, setMenuOpen] = usePersistentState(
    false,
    "asset.sidebar.open"
  );

  // Redux
  const channel = useSelector(getCurrentChannel);
  const asset = useSelector((state) => state.asset.selected);
  const defaultLanguage = useSelector(
    (state) => state.user.user.default_language
  );
  const translateLanguage = useSelector(
    (state) => state.user.user.translate_language
  );

  // Query
  const { assetId, type, panel } = params;
  const hasPanelOpen = !!panel;

  // Listeners
  const onToggleButtonClick = () => {
    setMenuOpen(!menuOpen);
  };

  // Load asset
  useEffect(() => {
    if (!assetId) {
      return;
    }
    dispatch(findById(Number(assetId), history));
  }, [assetId, dispatch, defaultLanguage, translateLanguage, history]);

  // Unmount and clean up
  useEffect(
    () => () => {
      dispatch(resetAsset());
    },
    [dispatch]
  );

  if (!channel?.data?.checks) {
    return <CircularProgressCenter />;
  }

  return (
    <StyledComponent className={menuOpen ? "menu-open" : null}>
      <Helmet>
        <title>
          {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -{" "}
          {channel.data ? channel.data.name : ""} -{t("labels.ASSET")} -{" "}
          {asset.data.name ? asset.data.name : ""}
        </title>
      </Helmet>
      <Fab
        aria-label="toggle sidebar"
        className="toggle-button"
        trackName="asset.sidebar.toggle"
        trackDetails={{
          asset: asset?.id,
          menuOpen,
        }}
        onClick={onToggleButtonClick}
      >
        <ArrowBackIosIcon fontSize="small" />
      </Fab>
      <AssetSidebar
        asset={asset.data}
        loading={asset.loading}
        channel={channel}
      />
      <FragmentSidebar
        collapsed={hasPanelOpen}
        assetType={type}
        channelId={channel?.data?.id}
        assetId={+asset?.id}
        loading={asset.loading}
        fragments={asset?.data?.fragments}
      />
    </StyledComponent>
  );
};

export default memo(AssetContainer);
