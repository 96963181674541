import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector } from "react-redux";

import { getPurge, purge } from "../services";

import { getCurrentFragment } from "../selectors";
import Checkbox from "../../../components/Form/Checkbox";
import Button from "../../../components/Button/Button";

const FragmentClearCache = ({ open, onClose }) => {
  const { data: fragment } = useSelector(getCurrentFragment);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [mediaUrl, setMediaUrl] = useState();
  const [resourcesUrl, setResourcesUrl] = useState();
  const [state, setState] = useState({
    media: false,
    resources: false,
  });
  const fragmentId = fragment?.id;
  const [loadingCacheUrl, setLoadingCacheUrl] = useState(false);

  const handleClick = () => {
    setLoading(true);
    const callApis = [];
    if (state.media) {
      callApis.push(purge(fragment.id, "media"));
    }
    if (state.resources) {
      callApis.push(purge(fragment.id, "resources"));
    }
    Promise.all(callApis)
      .then(() => {
        onClose();
        setLoading(false);
        enqueueSnackbar(t("text.CACHE_IS_EMPTY"), { variant: "success" });
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (!open) return;
    setLoadingCacheUrl(true);
    Promise.all([
      getPurge(fragmentId, "media"),
      getPurge(fragmentId, "resources"),
    ])
      .then((responses) => {
        setMediaUrl(responses[0].data.media_path);
        setResourcesUrl(responses[1].data.media_path);
        setLoadingCacheUrl(false);
      })
      .catch(() => {
        setLoadingCacheUrl(false);
      });
  }, [open, fragmentId]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("text.DELETE_CACHE_CONFIRMATION", { fragment: fragment.name })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "black" }}>
          {t("text.DELETE_CACHE_MEDIA_RESOURCES_CONFIRMATION", {
            fragment: fragment.name,
          })}
        </DialogContentText>
        <DialogContentText style={{ color: "red" }}>
          {t("text.SEVERAL_MINUTES_TO_EMPTY_CACHE")}
        </DialogContentText>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Checkbox
            name="media"
            color="primary"
            label="MEDIA"
            onChange={handleChange}
            display="flex"
          />
          <span>
            CacheURL: {loadingCacheUrl ? t("text.LOADING") : mediaUrl}
          </span>
          <Checkbox
            name="resources"
            color="primary"
            label="RESOURCES"
            onChange={handleChange}
            display="flex"
          />
          <span>
            CacheURL: {loadingCacheUrl ? t("text.LOADING") : resourcesUrl}
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          trackName="fragment.clear.cache.cancel"
          trackDetails={{ fragmentId }}
        >
          {t("text.NO")}
        </Button>
        <Button
          onClick={handleClick}
          color="primary"
          trackName="fragment.clear.cache.confirm"
          trackDetails={{
            fragmentId,
          }}
          loading={loading}
          disabled={loadingCacheUrl || (!state.media && !state.resources)}
        >
          {t("text.YES")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(FragmentClearCache);
