/* eslint-disable react/display-name */
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Hooks
import { useQuery } from "features/Common/hooks";

// Icons
import CloseIcon from "@material-ui/icons/Close";

// UI
import { Container } from "@material-ui/core";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getCurrentFragment } from "../selectors";
import { getCurrentAsset } from "../../Asset/selectors";
import { patchFragment } from "../reducer";

// Components
import CircularProgressCenter from "../../../components/CircularProgressCenter";
import FragmentMetaEdit from "../FragmentMetaEdit";
import FragmentInformationEdit from "../FragmentInformationEdit";
import FragmentImagesEdit from "../FragmentImagesEdit";
import FragmentLabelsEdit from "../FragmentLabelsEdit";
import FragmentJobs from "../FragmentJobs";

// Style
import StyledComponent, { AppBarStyled } from "./style";

// Utils
import { useCurrentFragmentUrl } from "../hooks";
import FragmentSubtitlesEdit from "../FragmentSubtitlesEdit";

// Available edit panels
const panels = {
  info: {
    label: "labels.CHANGE_INFO",
    Panel: ({ onSubmit, fragment, asset }) => (
      <FragmentInformationEdit
        onSubmit={onSubmit}
        fragment={fragment}
        asset={asset}
      />
    ),
  },
  meta: {
    label: "labels.CHANGE_INFO",
    Panel: ({ onSubmit, fragment }) => (
      <FragmentMetaEdit onSubmit={onSubmit} fragment={fragment} />
    ),
  },
  labels: {
    label: "labels.CHANGE_LABELS",
    Panel: ({ onSubmit, fragment }) => (
      <FragmentLabelsEdit onSubmit={onSubmit} fragment={fragment} />
    ),
  },
  images: {
    label: "labels.CHANGE_IMAGES",
    Panel: ({ onSubmit, fragment, fragmentFiles }) => (
      <FragmentImagesEdit
        onSubmit={onSubmit}
        fragment={fragment}
        fragmentFiles={fragmentFiles}
      />
    ),
  },
  subtitles: {
    label: "labels.CHANGE_SUBTITLES",
    Panel: ({ onSubmit, fragment, fragmentFiles, asset }) => (
      <FragmentSubtitlesEdit
        onSubmit={onSubmit}
        fragment={fragment}
        fragmentFiles={fragmentFiles}
        asset={asset}
      />
    ),
  },
  jobs: {
    label: "menuItems.TASK",
    disablePadding: true,
    Panel: () => <FragmentJobs />,
  },
  default: {
    Panel: () => <span>Panel not defined</span>,
  },
};

const FragmentDrawer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const query = useQuery();
  const panel = query.get("panel") || "default";

  const { data: fragment, files: fragmentFiles, loading } = useSelector(
    getCurrentFragment
  );
  const { data: asset } = useSelector(getCurrentAsset);

  const panelData = panels[panel?.toLowerCase()] || {};
  const { label, Panel } = panelData;
  const closeUrl = useCurrentFragmentUrl({ fragmentId: fragment?.id });

  const saveFragment = useCallback(
    (data) => {
      const fields = data;
      dispatch(
        patchFragment({
          id: fragment?.id,
          fields,
        })
      );
    },
    [fragment, dispatch]
  );

  if (!panel) {
    return null;
  }
  if (!fragment || loading) {
    return <CircularProgressCenter type="inline" />;
  }

  return (
    <StyledComponent>
      <AppBarStyled position="relative" className="withTabBar">
        {t(label)}
        <Link to={closeUrl}>
          <CloseIcon />
        </Link>
      </AppBarStyled>
      <Container
        className={`fragmentDrawerContainer ${
          panelData.disablePadding && "disablePadding"
        }`}
        maxWidth={false}
      >
        {Panel && (
          <Panel
            onSubmit={saveFragment}
            fragment={fragment}
            asset={asset}
            fragmentFiles={fragmentFiles}
          />
        )}
      </Container>
    </StyledComponent>
  );
};

export default FragmentDrawer;
