import { useState, memo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  Paper,
  Typography,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CheckIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";

import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Button } from "components";

import StyledDiv, { StyledMenu } from "./style";
import { actionToColor } from "../utils";
import { useJob } from "./JobProvider";

const DeleteConfirmDialog = ({ open, onClose, deleteJob }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
      <DialogTitle>{t("labels.SURE_DELETE_TASK")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("text.SURE_DELETE_TASK")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          {t("buttons.UNDO")}
        </Button>
        <Button
          autoFocus
          onClick={() => {
            deleteJob();
            onClose();
          }}
          color="primary"
        >
          {t("buttons.DELETE_TASK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ActiveJob = ({ job, hideBg, small }) => {
  const { changePriorityHandler, deleteJobHandler, startJob } = useJob();
  const { t } = useTranslation();
  const [menuRef, setMenuRef] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const openActionMenu = (event) => {
    setMenuRef(event.currentTarget);
  };

  const handleRestart = () => {
    setMenuRef(null);
    startJob(job.command.id, job?.encode ? job.encode.preset.id : null);
  };

  const handleChangePriority = () => {
    setMenuRef(null);
    changePriorityHandler({ ...job, priority: job.priority ? 0 : 1 });
  };

  const handleDeleteDialog = () => {
    setMenuRef(null);
    setShowDeleteDialog(true);
  };

  const handleClose = () => setMenuRef(null);

  return (
    <StyledDiv>
      <div className={`activeJobContainer ${!hideBg && "activeJobBg"}`}>
        <Paper className="paper" elevation={small ? 0 : 1}>
          <div className="content">
            <div className="left">
              {!small && (
                <Box display="flex" flexDirection="column" component="div">
                  <Chip
                    className="statusButton"
                    size="small"
                    style={{ backgroundColor: actionToColor(job.status) }}
                    label={job.status}
                  />
                  {Boolean(job.priority) && (
                    <Chip
                      className="statusButton prioButton"
                      size="small"
                      label="prio"
                      color="primary"
                    />
                  )}
                </Box>
              )}

              <div>
                <Typography variant={small ? "body2" : null}>
                  {job?.encode?.preset
                    ? `${job.command.name}: ${job.encode.preset.name}`
                    : job.command.name}{" "}
                  <small>#{job.id}</small>
                </Typography>
                {!small && (
                  <Typography variant="caption">
                    {`${moment
                      .unix(job.updated_at)
                      .format("DD MMM YYYY H:mm:ss")} ${t("text.BY")} ${
                      job?.logs?.pop()?.user?.name || "system"
                    }`}
                  </Typography>
                )}
              </div>
            </div>
            <div className="flex">
              {small && (
                <Box display="flex" flexDirection="row" component="div">
                  {Boolean(job.priority) && (
                    <Tooltip title="prio" aria-label="prio" arrow>
                      <FiberManualRecordIcon
                        className="status-indicator prioButtonColor"
                        style={{ fontSize: 12 }}
                        color="primary"
                      />
                    </Tooltip>
                  )}
                  <Tooltip title={job.status} aria-label={job.status} arrow>
                    <FiberManualRecordIcon
                      className="status-indicator"
                      style={{ fontSize: 12, color: actionToColor(job.status) }}
                    />
                  </Tooltip>
                </Box>
              )}
              <div className="flex">
                {["postponed", "error"].includes(job.status) && !small && (
                  <Button
                    name="fragment.jobs.restart"
                    color="primary"
                    type="submit"
                    trackName="Restart job"
                    trackDetails={{
                      jobId: job.id,
                    }}
                    size="small"
                    className="restartButton"
                    onClick={handleRestart}
                  >
                    Restart
                  </Button>
                )}
                {job.status === "in_progress" && !small && (
                  <Box position="relative" display="inline-flex">
                    <CircularProgress
                      variant="determinate"
                      value={job.progress}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {`${Math.round(job.progress)}%`}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </div>
              <IconButton
                size="small"
                className="moreSettings"
                aria-label="more settings"
                onClick={openActionMenu}
              >
                <MoreHorizIcon />
              </IconButton>
              <StyledMenu
                id="fade-menu"
                anchorEl={menuRef}
                keepMounted
                open={Boolean(menuRef)}
                TransitionComponent={Fade}
                onClose={handleClose}
              >
                <MenuItem onClick={handleChangePriority}>
                  <ListItemIcon>
                    {job.priority ? (
                      <CheckIcon color="primary" />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Prioriteit" />
                </MenuItem>
                {small && ["postponed", "error"].includes(job.status) && (
                  <MenuItem onClick={handleRestart}>
                    <ListItemIcon>
                      <RefreshIcon />
                    </ListItemIcon>
                    <ListItemText primary="Restart" />
                  </MenuItem>
                )}
                <MenuItem onClick={handleDeleteDialog}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Verwijder taak" />
                </MenuItem>
              </StyledMenu>
            </div>
          </div>
          {job.status === "in_progress" && small && (
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={job.progress} />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                  {`${Math.round(job.progress)}%`}
                </Typography>
              </Box>
            </Box>
          )}
        </Paper>
        <DeleteConfirmDialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          deleteJob={() => deleteJobHandler(job)}
        />
      </div>
    </StyledDiv>
  );
};

export default memo(ActiveJob);
