import styled from "styled-components";

const StyledComponent = styled.form`
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.grey[400]};
    font-weight: 500;
  }

  .MuiInputLabel-shrink {
    color: ${({ theme }) => theme.palette.grey[700]};
    font-weight: 700;
  }

  .MuiTypography-h5 {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 4px;
    margin-left: 12px;
  }

  .MuiAutocomplete-tag {
    background: ${({ theme }) => theme.palette.grey[600]};
    color: white;
    padding: 4px;
    border-radius: 4px;

    .MuiChip-deleteIcon {
      color: rgba(255, 255, 255, 0.85);
    }
  }

  span.MuiAutocomplete-tag {
    background: ${({ theme }) => theme.palette.primary.main};
    padding: 4px 8px;
  }

  .MuiInputBase-root {
    background: white;
  }

  .form-error {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const StyledList = styled.div`
  ul {
    width: auto !important;
  }
`;

export const StyledOption = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export default StyledComponent;
