import styled from "styled-components";
import TableRow from "@material-ui/core/TableRow";

export default styled(TableRow)`
  cursor: pointer;
  transition: background-color ease-out 300ms;

  &:hover {
    background: ${({ theme }) => theme.palette.grey[200]}!important;

    & .fi {
      border: 4px solid ${({ theme }) => theme.palette.grey[300]}!important;
    }

    & .cell--countries > div .MuiIconButton-root {
      opacity: 1;
    }

    & .cell--schedule-date > div .MuiIconButton-root {
      opacity: 0.4;
    }

    & .cell--name .icon--not-available {
      color: ${({ theme }) => theme.palette.warning.main}!important;
    }

    & .cell--name .MuiTypography-caption {
      display: inline-block;
      opacity: 0.5;
    }
  }

  /* ==== Row Selected ==== */

  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: rgba(245, 0, 87, 0.08) !important;
  }
  &.Mui-selected:nth-child(even),
  &.Mui-selected:nth-child(even).Mui-selected:hover {
    background-color: rgba(245, 0, 87, 0.12) !important;
  }

  /* ==== Custom Cell ==== */

  & .fi {
    border: 4px solid #efefef;
  }

  & .cell--name {
    min-width: 250px;

    &.not-available .MuiTypography-h6 {
      opacity: 0.5;
    }

    & > div {
      display: flex;
      align-items: center;
    }

    & .icon--not-available {
      margin-left: 8px;
      color: ${({ theme }) => theme.palette.warning.main};
    }

    &.not-available .icon--not-available {
      color: ${({ theme }) => theme.palette.grey[300]};
    }

    & .MuiTypography-caption {
      display: none;
      opacity: 0;
    }
  }

  & .cell--completed {
    text-align: center;
  }

  & .cell--schedule-date > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;

    & .MuiIconButton-root {
      padding: 0;
      opacity: 0;
      transition: opacity ease-out 0.2s;

      & .MuiSvgIcon-root {
        opacity: 0.4;
      }
    }
  }

  & .cell--countries {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      font-weight: bold;
    }

    & .MuiIconButton-root {
      margin-left: 4px;
      opacity: 0;
      transition: opacity ease-out 0.2s;
    }

    & .MuiSvgIcon-root {
      color: #bdbdbd;
    }
  }

  & .cell--duration {
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & .MuiTypography-root {
      font-size: 0.75rem;
      color: gray;
      margin-right: 2px;
    }
  }

  & .cell--checkbox {
    display: flex;
    justify-content: center;
    position: relative;

    & .tree-lines {
      display: none;
      position: absolute;
      border-left: 1px dotted ${({ theme }) => theme.palette.grey[500]};

      top: 50%;
      left: 32px;
      margin-top: 12px;
      bottom: 1px;
      transform: translateY(50);
    }
  }

  &.open .cell--checkbox .tree-lines {
    display: block;
  }
`;
