import React from "react";
import { Button as CustomButton } from "components";
import { useTranslation } from "react-i18next";
import { useSeoDetail } from "../../Hooks/useSeoDetail";

const SeoDetailSubmit = ({ onSubmit, isDirty }) => {
  const { formLoading } = useSeoDetail();
  const { t } = useTranslation();

  return (
    <>
      <div>
        <CustomButton
          disabled={!isDirty}
          onClick={onSubmit}
          loading={formLoading}
          style={{
            backgroundColor: isDirty ? "#1e8ea9" : undefined,
          }}
        >
          {t("buttons.SAVE")}
        </CustomButton>
      </div>
    </>
  );
};

export default SeoDetailSubmit;
