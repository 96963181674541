/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "./redux/Fragment.reducers";

const fragmentReducer = createSlice({
  name: "fragment",
  initialState: {
    loading: false,
    selected: {
      loading: false,
      deleting: false,
      deleteError: null,
      data: null,
    },
    networkState: {},
    translatable: {
      loading: false,
      data: [],
    },
    ui: {
      fragmentCreateDialogOpen: false,
    },
  },
  reducers: {
    loadFragment: reducers.loadFragment,
    deleteFragment: reducers.deleteFragment,
    deleteFragmentSuccess: reducers.deleteFragmentSuccess,
    deleteFragmentFailed: reducers.deleteFragmentFailed,
    loadFragmentSuccess: reducers.loadFragmentSuccess,
    loadFragmentFailed: reducers.loadFragmentFailed,
    resetFragment: reducers.resetFragment,
    loadTranslatableFragments: reducers.loadTranslatableFragments,
    loadTranslatableFragmentsSuccess: reducers.loadTranslatableFragmentsSuccess,
    loadTranslatableFragmentsFailed: reducers.loadTranslatableFragmentsFailed,
    uploadFile: reducers.uploadFile,
    uploadFileSuccess: reducers.uploadFileSuccess,
    uploadFileFailed: reducers.uploadFileFailed,
    deleteFile: reducers.deleteFile,
    deleteFileSuccess: reducers.deleteFileSuccess,
    deleteFileFailed: reducers.deleteFileFailed,
    updateSource: reducers.updateSource,
    updateState: reducers.updateState,
    updateStateFailed: reducers.updateStateFailed,
    updateVideoCuePoints: reducers.updateVideoCuePoints,
    patchFragment: reducers.patchFragment,
    patchFragmentSuccess: reducers.patchFragmentSuccess,
    patchFragmentFailed: reducers.patchFragmentFailed,
    patchFragmentField: reducers.patchFragmentField,
    patchFragmentFieldSuccess: reducers.patchFragmentFieldSuccess,
    patchFragmentFieldFailed: reducers.patchFragmentFieldFailed,
    patchFragmentLabels: reducers.patchFragmentLabels,
    patchFragmentLabelsSuccess: reducers.patchFragmentLabelsSuccess,
    patchFragmentLabelsFailed: reducers.patchFragmentLabelsFailed,
    patchFragmentMetaValues: reducers.patchFragmentMetaValues,
    patchFragmentMetaValuesSuccess: reducers.patchFragmentMetaValuesSuccess,
    patchFragmentMetaValuesFailed: reducers.patchFragmentMetaValuesFailed,
    createFragment: reducers.createFragment,
    openFragmentCreateDialog: reducers.openFragmentCreateDialog,
    closeFragmentCreateDialog: reducers.closeFragmentCreateDialog,
  },
});

const { actions, reducer } = fragmentReducer;

export const {
  loadFragment,
  loadFragmentSuccess,
  loadFragmentFailed,
  deleteFragment,
  deleteFragmentSuccess,
  deleteFragmentFailed,
  loadFragmentFilesSuccess,
  resetFragment,
  loadTranslatableFragments,
  loadTranslatableFragmentsSuccess,
  loadTranslatableFragmentsFailed,
  patchFragment,
  patchFragmentSuccess,
  patchFragmentFailed,
  patchFragmentField,
  patchFragmentFieldSuccess,
  patchFragmentFieldFailed,
  patchFragmentLabels,
  patchFragmentLabelsCancel,
  patchFragmentLabelsSuccess,
  patchFragmentLabelsFailed,
  patchFragmentMetaValues,
  patchFragmentMetaValuesSuccess,
  patchFragmentMetaValuesFailed,
  uploadFile,
  uploadFileSuccess,
  uploadFileFailed,
  deleteFile,
  deleteFileSuccess,
  deleteFileFailed,
  updateSource,
  updateState,
  updateStateFailed,
  updateVideoCuePoints,
  openFragmentCreateDialog,
  closeFragmentCreateDialog,
} = actions;
export default reducer;
