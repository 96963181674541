/* eslint-disable camelcase */
import { useMemo, createRef, useLayoutEffect } from "react";

/**
 * Navigate between next/prev episodes
 */
export const useSeriesNavigation = (series, assetId) =>
  useMemo(() => {
    const seasonActive = series?.seasons?.find(
      ({ id }) => id === Number(assetId)
    );

    // flatten episodes
    const allEpisodes = series?.seasons?.reduce(
      (result, { episodes }) => [...result, ...episodes],
      []
    );

    // Find active episode.
    // 1. Default season 1 episode 1 selected.
    // 2. If season 'root' selected' select first episode of season
    // 3. Otherwise, get index of active episode
    let activeEpisodeIndex = -1;
    if (seasonActive) {
      activeEpisodeIndex = allEpisodes.findIndex(
        ({ parent_id: parentId }) => parentId === seasonActive.id
      );
    } else {
      activeEpisodeIndex = allEpisodes.findIndex((episode) => episode.active);
    }

    // Navigation
    const previousEpisodeIndex = activeEpisodeIndex - 1;
    const hasPreviousEpisode = previousEpisodeIndex >= 0;
    const nextEpisodeIndex = seasonActive
      ? activeEpisodeIndex
      : activeEpisodeIndex + 1;
    const hasNextEpisode = nextEpisodeIndex < allEpisodes.length;

    const previousEpisode = hasPreviousEpisode
      ? allEpisodes[previousEpisodeIndex]
      : null;
    const nextEpisode = hasNextEpisode ? allEpisodes[nextEpisodeIndex] : null;

    return {
      nextEpisode,
      previousEpisode,
      hasPreviousEpisode,
      hasNextEpisode,
    };
  }, [series, assetId]);

// Scroll to active nav link
export const useScrollToActiveNavLink = (series, assetId, open) => {
  // Create a list of empty refs placeholders by season|episode ids. Which
  // will be populated later using <element ref={refs[season.id]}
  // eslint-disable-next-line
  const refs =
    useMemo(() => {
      if (!series?.seasons) return {};
      const allRefs = {};

      series.seasons.forEach((season) => {
        allRefs[season.id] = createRef();

        if (!season?.episodes) return;
        season.episodes.forEach((episode) => {
          allRefs[episode.id] = createRef();
        });
      });

      return allRefs;
    }, [series]) || {};

  // Scroll on next render cycle after all refs have been set.
  useLayoutEffect(() => {
    if (!assetId || !refs || !open) return;

    const activeElement = refs[Number(assetId)];
    setTimeout(() => {
      if (!activeElement?.current) return;
      activeElement.current.scrollIntoView(true);
    });
  }, [assetId, refs, open]);

  return [refs];
};
