import qs from "qs";
import { AllInApi } from "../../Common/services/api.service";
import * as Constants from "../../Common/constants";

const api = AllInApi.getApi();

export const getEncodedJobs = (channelId, searchParams) =>
  api.get(
    `${Constants.API_ENDPOINT_JOB_ENCODE.replace(
      ":id",
      channelId
    )}?${qs.stringify(searchParams)}`
  );

export const restartJob = (channelId, job) =>
  api.post(
    Constants.API_ENDPOINT_JOB_ID_RETRY.replace(":id", channelId).replace(
      ":jobId",
      job.id
    ),
    job
  );

export const restartJobs = (channelId, jobs) =>
  jobs.forEach((job) =>
    api.post(
      Constants.API_ENDPOINT_JOB_ID_RETRY.replace(":id", channelId).replace(
        ":jobId",
        job.id
      ),
      job
    )
  );
// api.post(
//   Constants.API_ENDPOINT_JOB_RETRY_BULK.replace(':id', channelId),
//   {jobIds}
// );

export const changePriority = (channelId, job) =>
  api.post(
    Constants.API_ENDPOINT_JOB_ID_PRIORITY.replace(":id", channelId).replace(
      ":jobId",
      job.id
    ),
    job
  );

export const uploadJob = (channelId, job) =>
  api.post(
    Constants.API_ENDPOINT_JOB_ID_UPLOAD.replace(":id", channelId).replace(
      ":jobId",
      job.id
    ),
    job
  );

export const createJob = (channelId, job) =>
  api.post(Constants.API_ENDPOINT_JOB.replace(":id", channelId), job);

export const deleteJob = (channelId, job) =>
  api.delete(
    Constants.API_ENDPOINT_JOB_ID.replace(":id", channelId).replace(
      ":jobId",
      job.id
    )
  );
