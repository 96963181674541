import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

class DeleteButton extends Component {
  renderUndo() {
    const { t, resource, onUndo } = this.props;

    return (
      <div>
        {t("text.UNDO_ACTION", {
          action: t("text.RESOURCE_DELETED", { resource }),
        })}
        <Button color="default" onClick={onUndo}>
          {t("buttons.UNDO")}
        </Button>
      </div>
    );
  }

  renderButton() {
    const { t, onClick } = this.props;

    return (
      <div>
        <Button
          onClick={onClick}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
        >
          {t("buttons.DELETE")}
        </Button>
      </div>
    );
  }

  render() {
    const { getUndoState } = this.props;

    if (getUndoState) {
      return this.renderUndo();
    }

    return this.renderButton();
  }
}

DeleteButton.defaultProps = {
  getUndoState: false,
};

DeleteButton.propTypes = {
  t: PropTypes.func.isRequired,
  resource: PropTypes.string,
  getUndoState: PropTypes.bool,
  onUndo: PropTypes.func,
};

export default compose(withTranslation(), connect())(DeleteButton);
