import { useRouteMatch } from "react-router-dom";

/* Style */
import StyledNavBar from "./style";
import NavBarItem from "./NavBarItem";

const NavBar = ({ channel, routes }) => {
  const url = useRouteMatch();
  const hasChannel = channel && url?.path !== "/";

  if (!hasChannel) return null;
  return (
    <StyledNavBar>
      {routes.map((route) => (
        <NavBarItem key={route.key} route={route} channel={channel} />
      ))}
    </StyledNavBar>
  );
};

NavBar.defaultProps = {
  routes: [],
};

export default NavBar;
