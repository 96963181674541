import React, { useState } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import { Button } from "components";
import { TextFieldWithControl } from "components/Form/TextField";

import { confirmedPassword } from "../user.service";

const validationSchema = yup.object().shape({
  password: yup.string().required(),
});

const ContentPasswordConfirm = ({ setPasswordIsConfirm }) => {
  const { t } = useTranslation();
  const [authenticationError, setAuthenticationError] = useState(null);
  const { handleSubmit, errors, control } = useForm({
    validationSchema,
    defaultValues: { password: "" },
  });

  const onPasswordConfirm = async (formData) => {
    const { password } = formData;
    const confirmRequest = confirmedPassword(password);

    confirmRequest
      .then(() => setPasswordIsConfirm(true))
      .catch((err) => {
        if (err?.response?.data.errors.password) {
          setAuthenticationError(err.response.data.errors.password[0]);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onPasswordConfirm)}>
      <MuiDialogContent dividers>
        <Typography gutterBottom>
          {t("text.TWO_FACTOR_PASSWORD_CONFIRM")}
        </Typography>
        <FormControl fullWidth error={errors?.password?.message}>
          <TextFieldWithControl
            variant="outlined"
            required
            id="password"
            name="password"
            control={control}
            error={errors?.password?.message}
            label={t("labels.PASSWORD")}
            placeholder={t("labels.PASSWORD")}
            margin="normal"
            type="password"
          />
          <FormHelperText
            id="component-error-text"
            error={!!authenticationError}
          >
            {authenticationError}
          </FormHelperText>
        </FormControl>
      </MuiDialogContent>
      <MuiDialogActions>
        <Button type="submit" color="primary">
          {t("buttons.CONFIRM_PASSWORD")}
        </Button>
      </MuiDialogActions>
    </form>
  );
};

export default ContentPasswordConfirm;
