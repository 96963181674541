import { useMemo, memo } from "react";

// Icons
import InfoIcon from "@material-ui/icons/Info";

// Components
import IconButton from "@material-ui/core/IconButton";

// Local Components
import StatusBadge from "../StatusBadge";

/**
 * Group subtitles files by status:count
 *
 * {
 *  active: 1,
 *  inactive: 3,
 *  pending: 4
 * }
 *
 */
const useSubtitleStateMap = (files) => {
  const stateMap = useMemo(() => {
    const subtitles = files?.filter((file) => file.type.type === "subtitle");

    const groupedStates = subtitles?.reduce((statusCount, subtitle) => {
      const { status } = subtitle;
      return {
        ...statusCount,
        [status]: (statusCount[status] || 0) + 1,
      };
    }, {});

    return groupedStates;
  }, [files]);

  return stateMap ? Object.entries(stateMap) : null;
};

const SubtitleStatus = ({ files, onSubtitleDetailsClick }) => {
  const stateMap = useSubtitleStateMap(files);

  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onSubtitleDetailsClick({ event, files });
  };

  return (
    <div className="subtitle-details">
      {stateMap?.map(([status, count]) => (
        <button
          className="status-button"
          key={status}
          type="button"
          onClick={onClick}
        >
          <StatusBadge status={status} count={count} />
          {!stateMap?.length && <StatusBadge status="inactive" count="-" />}
        </button>
      ))}
      <IconButton
        color="inherit"
        size="small"
        aria-label="Close"
        onClick={onClick}
      >
        <InfoIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

export default memo(SubtitleStatus);
