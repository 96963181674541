import styled from "styled-components";
import { Dialog } from "@material-ui/core";

const StyledComponent = styled.div``;

export const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    background: ${({ theme }) => theme.palette.grey[100]};
    padding: ${({ theme }) => `${theme.spacing(4)}px`};
  }

  .MuiInputBase-root {
    background: white;
  }

  .video-selector .MuiFormControl-root {
    width: 100%;

    .MuiFormLabel-root {
      font-size: 1.25rem;
      line-height: 0.3rem;
      color: ${({ theme }) => theme.palette.grey[600]};
    }

    label + .MuiInput-formControl {
      margin-top: 24px;
    }

    .MuiSelect-root {
      padding: 18.5px 14px;

      &:focus {
        background: white;
      }
    }
  }

  legend.MuiFormLabel-root {
    font-size: 0.875rem !important;
    color: ${({ theme }) => theme.palette.grey[600]};
    margin: ${({ theme }) => `0 0 ${theme.spacing()}px`} 0;
  }

  .drm-info {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.grey[600]};
    margin: ${({ theme }) => `${theme.spacing()}px`} 0;
  }

  .actions {
    display: flex;
    flex: 1;
    align-items: center;
    padding: ${({ theme }) => `${theme.spacing()}px`};
    justify-content: center;
    margin-top: 18px;

    .input {
      display: inline-block;
    }
  }
`;

export default StyledComponent;
