import styled from "styled-components";
import TableRow from "@material-ui/core/TableRow";

export default styled(TableRow)`
  cursor: pointer;
  transition: background-color ease-out 300ms;

  & .MuiTableCell-root {
    padding: 6px 8px;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.grey[200]}!important;

    & .cell--subtitles .MuiIconButton-root {
      opacity: 1;
    }
  }

  & .color--green {
    color: ${({ theme }) => theme.palette.success.main}!important;
  }

  & .color--red {
    color: ${({ theme }) => theme.palette.error.main}!important;
  }

  & .align--center {
    text-align: center;
  }

  /* ==== Tree Lines =====*/

  & .tree-lines {
    position: absolute;
    border-left: 1px dotted ${({ theme }) => theme.palette.grey[500]};

    top: 0;
    left: 32px;
    bottom: 1px;

    &::after {
      content: "";
      width: 24px;
      height: 2px;
      border-bottom: 1px dotted ${({ theme }) => theme.palette.grey[500]};
    }
  }

  &.last .tree-lines {
    bottom: 50%;

    &::after {
      position: absolute;
      bottom: 0;
    }
  }

  /* ==== Row Selected ==== */

  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: rgba(245, 0, 87, 0.08) !important;
  }
  &.Mui-selected:nth-child(even),
  &.Mui-selected:nth-child(even).Mui-selected:hover {
    background-color: rgba(245, 0, 87, 0.12) !important;
  }

  /* ==== Custom Cell ==== */

  & .cell--name {
    position: relative;

    & > div {
      display: flex;
      align-items: center;
    }
  }

  & .cell--subtitles .subtitles {
    display: flex;
  }

  & .cell--subtitles .subtitle-details {
    display: flex;
    align-items: center;
    padding: 4px 0;
    margin-right: 8px;
    order: -1;
    border: 0;
    background: 0;

    & > .status-button {
      border: 0;
      padding: 0;
      background: 0;
      outline: none;
    }

    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.grey[400]};
    }
  }

  & .cell--subtitles .MuiIconButton-root {
    opacity: 0;
    transition: opacity ease-out 0.2s;
  }

  & .cell--drm {
    width: 80px;
  }

  & .icon--movie {
    color: ${({ theme }) => theme.palette.grey[600]};
    margin: 0 16px 0 60px;
  }

  & .fi {
    margin-right: 4px;
  }

  /* === Elements === */
  .status-chip {
    display: flex;
    width: auto;
    justify-content: center;
    font-size: 10px;
    padding: 2px 4px;
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
    font-size: 0.55rem;
    color: #0000008c;
    text-transform: uppercase;
  }
`;
