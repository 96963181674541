import * as types from "./Asset.types";

export const find = (query = null) => ({ type: types.FIND, query });

export const findMore = (query = null) => ({ type: types.FIND_MORE, query });

export const findSuccess = (data) => ({ type: types.FIND_SUCCESS, data });

export const findFailure = () => ({ type: types.FIND_FAILURE });

export const updateQualityChecks = (assetId, data) => ({
  type: types.UPDATE_QUALITY_CHECKS,
  payload: { assetId, data },
});

export const reset = () => ({ type: types.RESET });

export const setSelectedType = (assetType) => ({
  type: types.SET_SELECTED_TYPE,
  assetType,
});

export const findById = (assetId, history) => ({
  type: types.FIND_ASSET,
  assetId,
  history,
});

export const findByIdSuccess = (asset, history) => ({
  type: types.FIND_ASSET_SUCCESS,
  asset,
  history,
});

export const resetAsset = () => ({ type: types.RESET_ASSET });

export const setSort = (sortField, sortDirection) => ({
  type: types.SET_SORT,
  sortField,
  sortDirection,
});

export const fragmentCreated = (fragment) => ({
  type: types.FRAGMENT_CREATED,
  fragment,
});

export const patchLabels = (payload) => ({ type: types.PATCH_LABELS, payload });

export const patchLabelsSuccess = (payload) => ({
  type: types.PATCH_LABELS_SUCCESS,
  payload,
});

export const patchLabelsFailed = () => ({ type: types.PATCH_LABELS_FAILED });
