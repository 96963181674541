import { takeLatest, all } from "redux-saga/effects";
import * as LogTypes from "./Log.types";
import * as LogSagas from "./Log.sagas";
import * as logApi from "./log.service";

function* sagas() {
  return yield all([takeLatest(LogTypes.FIND, LogSagas.find, logApi)]);
}

export default sagas;
