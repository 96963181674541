import { Component as ReactComponent } from "react";
import CircularProgressCenter from "./CircularProgressCenter/CircularProgressCenter";
import Fragment from "./Fragment";

class Component extends ReactComponent {
  static breadcrumbs = [];

  constructor(props) {
    super(props);

    this.initState({});
  }

  initState(state) {
    this.state = { loading: false, ...state };
  }

  preload(...promises) {
    Promise.all(promises).then(() => {
      this.setState({ loading: false });
    });
  }

  setLoading(value) {
    this.setState({ loading: !!value });
  }

  // eslint-disable-next-line class-methods-use-this
  loading() {
    return (
      <Fragment>
        <CircularProgressCenter />
      </Fragment>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getBreadcrumbs() {
    return Component.breadcrumbs;
  }

  static appendBreadcrumb(crumb, clear = false) {
    if (clear) Component.breadcrumbs = [];

    Component.breadcrumbs.push(crumb);
  }
}

export default Component;
