import styled from "styled-components";

export default styled.div`
  margin: 0;
  display: flex;
  flex-flow: row;
  height: 100%;
  max-height: 100%;

  section {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > header {
      height: 64px;
    }
  }

  main {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    flex: 1 1 0%;
    opacity: 1;
    overflow: hidden;
  }

  .MuiAppBar-root {
    transition: width ease-out 0.2s;
  }

  .cr-right {
    transition: right ease-out 0.2s;
  }

  .drawer {
    width: 0;
    flex-shrink: 0;
    height: 100%;
    transition: width ease-out 0.2s;
  }
`;
