import { useCallback, useEffect, useState, memo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useParams, Redirect } from "react-router-dom";

// Hooks
import { useAuthentication } from "features/Auth/AuthProvider";

// Component
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "components/Form/TextField/TextField";
import { FormHelperText } from "@material-ui/core";
import { Button } from "components";
import CircularProgressCenter from "components/CircularProgressCenter";

import dcLogo from "features/Common/img/logo.png";
import StyledComponent from "./style";
import { validateActivation, updateActivation } from "../user.service";

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  password: yup.string().min(6).required(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const Form = ({ defaultValues, onSubmit, requestLoading }) => {
  const { t } = useTranslation();

  const { handleSubmit, register, errors } = useForm({
    validationSchema,
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            name="name"
            label={t("labels.NAME")}
            variant="outlined"
            error={errors.name?.message}
            ref={register}
            fullWidth
          />
        </Grid>

        <Grid item>
          <TextField
            name="password"
            label={t("labels.PASSWORD")}
            variant="outlined"
            error={errors.password?.message}
            ref={register}
            type="password"
            fullWidth
          />
        </Grid>

        <Grid item>
          <TextField
            name="password_confirmation"
            label={t("labels.PASSWORD_CONFIRMATION")}
            variant="outlined"
            error={errors.password_confirmation?.message}
            ref={register}
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" loading={requestLoading}>
            {t("buttons.SAVE")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const ActivationToken = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const { isAuthenticated, login, authenticationError } = useAuthentication();

  // State
  const [values, setValues] = useState(null);
  const [error, setError] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);

  // Listeners
  const onSubmit = useCallback(
    (value) => {
      setRequestLoading(true);

      updateActivation(values.id, { ...value, token })
        .then(() => {
          login(values.email, value.password);
        })
        .catch(() => {
          setError(t("error.ACTIVATION_TOKEN"));
        })
        .finally(() => {
          setRequestLoading(false);
        });
    },
    [setRequestLoading, token, values, t, login]
  );

  useEffect(() => {
    validateActivation(token)
      .then((response) => {
        setValues(response.data);
      })
      .catch(() => {
        setError(t("error.ACTIVATION_TOKEN"));
        setRequestLoading(false);
      });
  }, [token, setValues, setRequestLoading, t]);

  useEffect(() => {
    if (authenticationError) {
      setError(t("error.PASSWORD_CHANGE"));
    } else {
      setError(null);
    }
  }, [authenticationError, setError, t]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <StyledComponent>
      <Helmet>
        <title>
          {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -
          {t("titles.ACTIVATION")}
        </title>
      </Helmet>

      <Card className="card">
        <CardContent>
          <div className="centerContainer logoContainer">
            <img src={dcLogo} alt="DutchChannels Logo" width="150" />
          </div>

          <div className="inputContainer">
            <Typography color="textSecondary" />

            <div className="centerContainer">
              <Typography variant="h5" color="textSecondary">
                {t("titles.ACTIVATION")}
              </Typography>
            </div>
          </div>

          {values && (
            <Form
              defaultValues={values}
              onSubmit={onSubmit}
              requestLoading={requestLoading}
            />
          )}
          {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
          {!error && !values && <CircularProgressCenter type="inline" />}
        </CardContent>
      </Card>
    </StyledComponent>
  );
};

export default memo(ActivationToken);
