import { useSelector, useDispatch } from "react-redux";

// Services
import { approveFile } from "../reducer";

const useApproveFileRequest = () => {
  // Redux
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.external.approve);

  const onApprove = async ({ token, fileId }) => {
    if (!token) return;

    dispatch(
      approveFile({
        authToken: token,
        file: fileId,
        status: "approved",
      })
    );
  };

  return [loading, error, onApprove];
};

export default useApproveFileRequest;
