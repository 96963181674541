import { useCallback, useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";

import {
  Grid,
  InputAdornment,
  Typography,
  IconButton,
  Tooltip,
  TextField as UITextField,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";
import { useForm } from "react-hook-form";
import TextField from "components/Form/TextField";
import { Button } from "components";
import { maxBy } from "../../Common/utils/common.utils";
import { useJob } from "./JobProvider";

const defaultFields = [
  "video_stream_index",
  "aspect_ratio",
  "letterbox_aspect_ratio",
  "audio_stream_index",
  "audio_channel_index",
  "audio_file",
  "audio_file_left",
  "audio_file_right",
  "audio_filter",
  "complex_filter",
];

const FileSettings = () => {
  const { config, fragment, setConfig } = useJob();
  const { t } = useTranslation();
  const [fields, setFields] = useState([
    {
      index: 0,
      name: "",
      value: "",
    },
  ]);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { ...config },
  });
  const onSubmit = (data) => {
    const updatedData = { ...data };

    fields.forEach((field) => {
      updatedData[field.name] = field.value;
    });

    if (config.audio_stream_index)
      updatedData.audio_stream_index = config.audio_stream_index;
    if (config.video_stream_index)
      updatedData.video_stream_index = config.video_stream_index;

    setConfig(updatedData);
  };

  const addField = () => {
    const newFields = fields;
    const maxField = maxBy(newFields, "index") || { index: -1 };

    newFields.push({
      index: maxField.index + 1,
      name: "",
      value: "",
    });

    setFields(newFields);
  };

  const changeField = (changedField) => {
    let newFields = fields;

    const maxField = maxBy(newFields, "index") || { index: 0 };
    if (changedField.index === maxField.index) addField();

    if (changedField.name || changedField.value) {
      newFields = newFields.map((field) =>
        field.index === changedField.index ? changedField : field
      );
    } else {
      newFields = newFields.filter(
        (field) => field.index !== changedField.index
      );
    }

    setFields(newFields);
  };

  useEffect(() => {
    let customFields = [];

    if (config) {
      customFields = Object.keys(config)
        .filter((key) => !defaultFields.includes(key))
        .map((key, index) => ({
          index,
          name: key,
          value: config[key],
        }));

      if (customFields.length > 0) {
        setFields(customFields);
      }
    }

    reset(config);
  }, [config, reset]);

  const renderHelpAdornment = useCallback(
    (field, value) => (
      <InputAdornment position="end">
        <Tooltip title={`${t("text.CHANGE_TO")}: ${value}`}>
          <IconButton aria-label={`${t("text.CHANGE_TO")}: ${value}`}>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    ),
    [t]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="fileSettings">
      <Grid container direction="column">
        <Typography variant="subtitle2">{t("labels.VIDEO")}</Typography>
        <TextField
          id="aspect_ratio"
          name="aspect_ratio"
          label={`${t("labels.VIDEO_ASPECT_RATIO")}...`}
          endAdornment={renderHelpAdornment("aspect_ratio", "4:3")}
          helperText={t("text.VIDEO_ASPECT_RATIO_HELPTEXT")}
          ref={register}
          shrink
        />
        <TextField
          id="letterbox_aspect_ratio"
          name="letterbox_aspect_ratio"
          label={`${t("labels.VIDEO_LETTERBOX_ASPECT_RATIO")}...`}
          endAdornment={renderHelpAdornment("letterbox_aspect_ratio", "16:9")}
          helperText={t("text.VIDEO_LETTERBOX_ASPECT_RATIO_HELPTEXT")}
          ref={register}
          shrink
        />
        <Typography variant="subtitle2">{t("labels.AUDIO")}</Typography>
        <TextField
          id="audio_channel_index"
          name="audio_channel_index"
          label={`${t("labels.AUDIO_CHANNEL_INDEX")}...`}
          isMulti
          options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          helperText={t("text.AUDIO_CHANNEL_INDEX_HELPTEXT")}
          ref={register}
          shrink
        />
        <TextField
          id="audio_file"
          name="audio_file"
          label={`${t("labels.AUDIO_FILE")}...`}
          endAdornment={renderHelpAdornment("audio_file", "audio_stereo.wav")}
          helperText={t("text.AUDIO_FILE_HELPTEXT")}
          ref={register}
          shrink
        />
        <TextField
          id="audio_file_left"
          name="audio_file_left"
          label={`${t("labels.AUDIO_FILE_LEFT")}...`}
          endAdornment={renderHelpAdornment(
            "audio_file_left",
            "audio_left.wav"
          )}
          helperText={t("text.AUDIO_FILE_LEFT_HELPTEXT")}
          ref={register}
          shrink
        />
        <TextField
          id="audio_file_right"
          name="audio_file_right"
          label={`${t("labels.AUDIO_FILE_RIGHT")}...`}
          endAdornment={renderHelpAdornment(
            "audio_file_right",
            "audio_right.wav"
          )}
          helperText={t("text.AUDIO_FILE_RIGHT_HELPTEXT")}
          ref={register}
          shrink
        />
        <TextField
          id="audio_filter"
          name="audio_filter"
          label={`${t("labels.AUDIO_FILTER")}...`}
          endAdornment={renderHelpAdornment(
            "audio_filter",
            "pan=stereo|FL=c0|FR=c1"
          )}
          helperText={t("text.AUDIO_FILTER_HELPTEXT")}
          ref={register}
          shrink
        />
        <Typography variant="subtitle2">{t("labels.OTHER")}</Typography>
        <TextField
          id="complex_filter"
          name="complex_filter"
          label={`${t("labels.COMPLEX_FILTER")}...`}
          endAdornment={renderHelpAdornment(
            "complex_filter",
            "[0:a:0][0:a:1] amerge=inputs=2[aout]"
          )}
          helperText={t("text.COMPLEX_FILTER_HELPTEXT")}
          ref={register}
          shrink
        />

        <TextField
          id="complex_filter"
          name="complex_filter"
          label={`${t("labels.COMPLEX_FILTER")}...`}
          endAdornment={renderHelpAdornment(
            "complex_filter",
            "[0:a:0][0:a:1] amerge=inputs=2[aout]"
          )}
          helperText={t("text.COMPLEX_FILTER_HELPTEXT")}
          ref={register}
          shrink
        />

        <Typography variant="subtitle2">{t("labels.OTHER_VALUES")}</Typography>
        {fields.map((field) => (
          <Grid key={field.index} container wrap="nowrap">
            <UITextField
              value={field.name}
              name={`other_${field.index}_name`}
              placeholder={`${t("labels.NAME")}...`}
              onChange={(event) =>
                changeField({ ...field, name: event.target.value })
              }
              fullWidth
              style={{ marginRight: 8 }}
              variant="outlined"
            />
            <UITextField
              value={field.value}
              variant="outlined"
              placeholder={`${t("labels.VALUE")}...`}
              onChange={(event) =>
                changeField({ ...field, value: event.target.value })
              }
              fullWidth
              style={{ marginLeft: 8 }}
            />
          </Grid>
        ))}

        <Button
          name="fragment.jobs.config.save"
          color="primary"
          type="submit"
          trackName="Fragment Jobs Config Save"
          trackDetails={{
            fragmentId: fragment.id,
            fragmentName: fragment.name,
            file: fragment?.file?.id,
          }}
        >
          {t("buttons.SAVE")}
        </Button>
      </Grid>
    </form>
  );
};

export default memo(FileSettings);
