export const DATE_MASK = [
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const DATE_FORMAT = "DD/MM/YYYY";
export const MILLISECONDS_MASK = [
  /\d/,
  /\d/,
  ":",
  /\d/,
  /\d/,
  ":",
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
];
