import styled from "styled-components";
import Slider from "@material-ui/core/Slider";

const StyledTrack = styled.div`
  position: relative;
  display: flex;
  margin: ${({ theme }) => `${theme.spacing(2)}px 0`};

  & .info {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;

    & > button {
      position: relative;
      background: none;
      width: 100px;
      height: 100%;
      border-radius: 4px;
      color: white;
      border: 2px solid transparent;
      cursor: pointer;
      outline: none;

      & .lock {
        position: absolute;
        top: 0;
        right: 0;
        background: ${({ theme }) => theme.palette.primary.main};
        filter: brightness(95%);
        border-radius: 4px;
        padding: 2px;
        transform: translate(50%, -50%);

        & .MuiSvgIcon-root {
          font-size: 0.875rem;
        }
      }

      &.active,
      &:hover {
        border: 2px solid ${({ theme }) => theme.palette.primary.main};
        background: ${({ theme }) => theme.palette.primary.main};
        color: white;
      }
    }

    & .MuiTypography-h6 {
      color: white;
      font-size: 0.75rem;
      text-align: center;
    }
  }

  & .track {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${({ theme }) => `0 ${theme.spacing(2)}px`};
    padding: 0;

    .time-indicator {
      position: absolute;
      pointer-events: none;
      top: -16px;
      left: 0;
      bottom: -16px;
      width: 2px;
      background: ${({ theme }) => theme.palette.secondary.main};
      z-index: 10;
      user-select: none;
    }

    .MuiSlider-root {
      transform: translateY(-50%);
    }

    & .input-container {
      display: flex;
      flex-wrap: nowrap;
    }

    & .input-area {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .text-right .MuiInputBase-input {
        text-align: right;
      }

      .MuiInputBase-input {
        color: white;
        padding: 0 0 6px 0;
        height: 0.75rem;
        font-size: 0.875rem;
      }

      .MuiInput-underline:before {
        border-bottom-color: #ffffff82;
      }
    }

    & .MuiIconButton-root {
      color: gray;
      margin: 0 8px;

      &:hover {
        color: white;
      }

      & .MuiSvgIcon-root {
        font-size: 1.25rem;
      }
    }
  }
`;

export const StyledSlider = styled(Slider)`
  --track-height: 24px;
  --stripe-thickness: 8px;

  --remove: #ff919126;
  --remove-dark: #e86f6f54;
  --keep: #06bd60;
  --keep-dark: #06c766;
  pointer-events: none;

  .MuiSlider-rail {
    pointer-events: none;
    height: var(--track-height);
    /* border-radius: 4px; */
    opacity: 1 !important;
    background: repeating-linear-gradient(
      -45deg,
      var(--remove),
      var(--remove) var(--stripe-thickness),
      var(--remove-dark) var(--stripe-thickness),
      var(--remove-dark) calc(var(--stripe-thickness) * 2)
    );
  }

  .MuiSlider-track {
    pointer-events: none;
    height: var(--track-height);
    /* border-radius: 4px; */
    background: repeating-linear-gradient(
      -45deg,
      var(--keep),
      var(--keep) var(--stripe-thickness),
      var(--keep-dark) var(--stripe-thickness),
      var(--keep-dark) calc(var(--stripe-thickness) * 2)
    );
  }

  .MuiSlider-thumb {
    pointer-events: all;
  }

  .MuiSlider-thumb::after {
    top: 12px;
    left: 5px;
    right: -15px;
    bottom: -17px;
    content: "";
    position: absolute;
    border-radius: 0;
    width: 2px;
    background: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiSlider-valueLabel {
    & > span {
      border-radius: 0;
      transform: rotate(0) translate(-26%, 12px);
      width: auto;
      padding: 0 8px;

      & > span {
        transform: rotate(0);
        width: auto;
      }
  }

  .MuiSlider-thumb::after {
    top: 11px;
    left: 5px;
    right: 0;
    bottom: -17px;
    content: '';
    position: absolute;
    border-radius: 0;
    width: 1px;
    background: ${({ theme }) => theme.palette.primary.main};
  }
`;

export default StyledTrack;
