import { INITIAL_STATE } from "./Log.state";

export const find = (state) =>
  state.merge({
    loading: true,
  });

export const findSuccess = (state, { data }) =>
  state.merge({
    loading: false,
    data,
  });

export const findFailure = (state) => state;

export const reset = () => INITIAL_STATE;
