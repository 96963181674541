import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SyncIcon from "@material-ui/icons/Sync";
import PhotoIcon from "@material-ui/icons/Photo";
import PublishIcon from "@material-ui/icons/Publish";
import StorageIcon from "@material-ui/icons/Storage";
import { useJob } from "./JobProvider";

const jobs = [
  {
    job: "status.jobs.SYNC",
    command_id: 1,
    icon: <SyncIcon />,
    option: {
      text: "status.jobs.SYNC",
      key: "sync",
      description: "text.jobs.SYNC_DESC",
    },
  },
  {
    job: "status.jobs.ENCODE",
    command_id: 2,
    icon: <StorageIcon />,
    preset: true,
    option: {
      text: "status.jobs.ENCODE",
      key: "encode",
      description: "text.jobs.ENCODE_DESC",
    },
  },
  {
    job: "labels.SNAPSHOT",
    command_id: 6,
    icon: <PhotoIcon />,
    option: {
      text: "labels.SNAPSHOT",
      key: "snapshot",
      description: "text.jobs.SNAPSHOT_DESC",
    },
  },
  {
    job: "status.jobs.UPLOAD",
    command_id: 5,
    preset: true,
    icon: <PublishIcon />,
    option: {
      text: "status.jobs.UPLOAD",
      key: "upload",
      description: "text.jobs.UPLOAD_DESC",
    },
  },
];

const StartJob = () => {
  const { t } = useTranslation();
  const { startJob } = useJob();
  const channelPreset =
    useSelector((state) => state.channel?.data?.presets) || [];

  const handleStartJob = (command, preset = null) => {
    startJob(command, preset ? preset.id : null);
  };

  return (
    <>
      {jobs.map((job) => (
        <List
          key={job.job}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              disableSticky
              component="div"
              id="nested-list-subheader"
            >
              {t(job.job)}
            </ListSubheader>
          }
        >
          {job.preset ? (
            channelPreset.map((preset) => (
              <ListItem
                button
                key={`${job.option.key}-${preset.id}`}
                onClick={() => handleStartJob(job.command_id, preset)}
              >
                <ListItemIcon>{job.icon}</ListItemIcon>
                <ListItemText
                  primary={`${t(job.option.text)}: ${preset.name}`}
                  secondary={t(job.option.description)}
                />
              </ListItem>
            ))
          ) : (
            <ListItem
              button
              key={job.option.key}
              onClick={() => handleStartJob(job.command_id)}
            >
              <ListItemIcon>{job.icon}</ListItemIcon>
              <ListItemText
                primary={t(job.option.text)}
                secondary={t(job.option.description)}
              />
            </ListItem>
          )}
        </List>
      ))}
    </>
  );
};

export default StartJob;
