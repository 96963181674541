import { compose } from "redux";

import withStyles from "@material-ui/core/styles/withStyles";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withTranslation } from "react-i18next";
import { Component, Fragment } from "../../../../components";
import styles from "./Form.styles";

class EncoderForm extends Component {
  render() {
    const { t, model, onChangeCallback, classes } = this.props;

    const selectStatus = ["enabled", "schedule", "disabled"];

    return (
      <Fragment>
        <TextField
          margin="dense"
          variant="outlined"
          id="name"
          name="name"
          label={t("labels.NAME")}
          type="text"
          defaultValue={model.name}
          onChange={onChangeCallback}
          fullWidth
        />
        <Autocomplete
          id="status"
          input={t("labels.STATUS")}
          value={model.status}
          options={selectStatus}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Status"
              variant="outlined"
              name="status"
              className={classes.statusChange}
              fullWidth
            />
          )}
          onChange={(event, newValue) =>
            onChangeCallback({ target: { name: "status", value: newValue } })
          }
        />
      </Fragment>
    );
  }
}

EncoderForm.defaultProps = {};

EncoderForm.propTypes = {};

export default compose(withTranslation(), withStyles(styles))(EncoderForm);
