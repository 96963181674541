/**
 * Get an object of changed form fields
 *
 * @param formData {
 *   1:  {  value: 'Movie' },
 *   99: [{ value: 'nl' }, { value: 'be' }]
 *   13: [{ value: 'Tom Hanks' }]
 *  } all available form values
 * @param dirtyFields [1, 99] names/ids of changed fields
 *
 * @return {
 *   1:  {  value: 'Movie' },
 *   99: [{ value: 'nl' }, { value: 'be' }]
 * } object with changed fields based on dirtyFields ids
 */
export const getDirtyValues = ({ formData, dirtyFields }) => {
  const dirtyFieldsArray = [...dirtyFields.values()];
  return Object.entries(formData).reduce((current, [key, value]) => {
    if (!dirtyFieldsArray.includes(key)) {
      return current;
    }
    return {
      ...current,
      [key]: value,
    };
  }, {});
};

export const objectToArray = (object) =>
  Object.keys(object).map((k) => object[k]);
