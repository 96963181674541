import { AllInApi } from "../../Common/services/api.service";
import * as Constants from "../../Common/constants";

const api = AllInApi.getApi();

export const addCollection = (channelId, body) =>
  api.post(
    Constants.API_ENDPOINT_ADD_COLLECTION.replace(":id", channelId),
    body
  );
