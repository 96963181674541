import grey from "@material-ui/core/colors/grey";

const style = {
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  topHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 0,
    height: 56,
    paddingLeft: 15,
    backgroundColor: "rgba(0 , 0, 0, .02)",
    borderBottom: `1px solid ${grey[300]}`,
  },
  titleHeader: {
    display: "flex",
    fontWeight: "bold",
  },
  addIcon: {
    paddingRight: "25px",
    hover: "hand",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  searchUser: {
    width: "22%",
    backgroundColor: "white",
  },
};

export default style;
