import { memo } from "react";
import PropTypes from "prop-types";

// Icons
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

// Components
import { Button, LinearProgress } from "@material-ui/core";

// Utils
import { convertSecondsToHuman } from "../utils";

// Style
import StyledControls from "./Controls.style";

const seekTimes = [
  { key: "1/60", value: 1 / 60, label: "1/60s" },
  { key: "1/25", value: 1 / 25, label: "1/25s" },
  { key: "1", value: 1, label: "1s" },
  { key: "10", value: 10, label: "10s" },
  { key: "30", value: 30, label: "30s" },
  { key: "60", value: 60, label: "1m" },
];

const Controls = ({
  onSeekTimeChanged,
  seekTimeInfo,
  currentTimeMs,
  duration,
  active,
  onChangeCommitted,
}) => {
  const progress = (currentTimeMs / duration) * 100;

  const onNext = () => {
    const { value } = seekTimeInfo;

    // currentTime to ms, seekTime to ms
    let newTime = currentTimeMs + value * 1000;
    if (newTime >= duration) {
      newTime = duration;
    }
    onChangeCommitted({
      type: active,
      value: newTime,
    });
  };

  const onPrev = () => {
    const { value } = seekTimeInfo;
    // currentTime to ms, seekTime to ms
    let newTime = currentTimeMs - value * 1000;
    if (newTime <= 0) {
      newTime = 0;
    }
    onChangeCommitted({
      type: active,
      value: newTime,
    });
  };

  return (
    <StyledControls>
      <div className="buttons">
        <Button onClick={onPrev}>
          <NavigateBeforeIcon />
          Prev
        </Button>
        <Button onClick={onNext}>
          Next
          <NavigateNextIcon />
        </Button>
      </div>

      <div className="seek-times">
        {seekTimes.map(({ key, value, label }) => (
          <Button
            className={seekTimeInfo?.key === key ? "active" : null}
            color="primary"
            key={key}
            onClick={() => onSeekTimeChanged({ key, value, label })}
          >
            {label}
          </Button>
        ))}
      </div>
      <div className="time-controls">
        <div className="progress">
          <span>{convertSecondsToHuman(currentTimeMs)}</span>
          <LinearProgress variant="determinate" value={progress} />
          <span>{convertSecondsToHuman(duration)}</span>
        </div>
      </div>
    </StyledControls>
  );
};

Controls.defaultProps = {
  seekTimeInfo: {
    key: "1/25",
    value: 1 / 25,
  },
  currentTimeMs: 0,
  duration: 0,
  active: null,
  onSeekTimeChanged: () => {},
  onChangeCommitted: () => {},
};

Controls.propTypes = {
  seekTimeInfo: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.number,
  }),
  currentTimeMs: PropTypes.number,
  duration: PropTypes.number,
  active: PropTypes.string,
  onSeekTimeChanged: PropTypes.func,
  onChangeCommitted: PropTypes.func,
};

export default memo(Controls);
