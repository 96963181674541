import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// Icons
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import EditIcon from "@material-ui/icons/Edit";
import LabelOffOutlinedIcon from "@material-ui/icons/LabelOffOutlined";

// Components
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import SidebarGroup from "features/Asset/AssetDetailSidebarGroup";
import DialogHeader from "components/Dialog/DialogHeader";
import PosterLabel from "components/PosterLabel";
import LabelsForm from "components/LabelsForm";

// Style
import StyledComponent from "./style";
import { patchLabels } from "features/Asset/Asset.actions";

const Labels = ({ labels, assetId, assetName }) => {
  const { t } = useTranslation();

  // State
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const networkState = useSelector(
    (state) => state.asset?.networkState?.labels
  );

  // Form
  const trackDetails = useMemo(
    () => ({
      assetId,
      assetName,
    }),
    [assetId, assetName]
  );

  const onSubmit = (data) => {
    const { labels } = data;
    dispatch(
      patchLabels({
        id: assetId,
        labels,
      })
    );
  };

  const onCancel = () => {
    setEditDialogOpen(false);
  };

  // Listeners
  const onEditDialogOpen = () => {
    setEditDialogOpen(true);
  };
  const onEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const MenuActions = () => (
    <>
      <IconButton
        className="button"
        aria-label="Edit"
        onClick={onEditDialogOpen}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <StyledComponent>
      <SidebarGroup
        name="information"
        title={t("labels.LABELS")}
        headerIcon={<LabelOutlinedIcon />}
        menuActions={<MenuActions />}
      >
        {labels?.map(({ id, text, textColor, backgroundColor }) => {
          return (
            <PosterLabel
              key={id}
              textColor={textColor}
              backgroundColor={backgroundColor}
              text={text}
            />
          );
        })}
        {!labels?.length && (
          <Typography className="no-labels" variant="body1">
            <LabelOffOutlinedIcon />
            {t("labels.NO_LABELS")}
          </Typography>
        )}
      </SidebarGroup>
      <Dialog maxWidth={"md"} onClose={onEditDialogClose} open={editDialogOpen}>
        <DialogHeader
          title={t("labels.CHANGE_LABELS")}
          onClose={onEditDialogClose}
        />
        <DialogContent>
          <LabelsForm
            trackDetails={trackDetails}
            onSubmit={onSubmit}
            onCancel={onCancel}
            labels={labels}
            loading={networkState === "updating"}
          />
        </DialogContent>
      </Dialog>
    </StyledComponent>
  );
};

export default Labels;
