import TranslationOverview from "./TranslationOverview";
import TranslationDetail from "./TranslationDetail";

const routes = [
  {
    key: "TRANSLATE",
    path: "/channel/:channelId/asset/fragment/translate/list",
    component: TranslationOverview,
    exact: true,
    displayNav: true,
    auth: true,
  },
  {
    key: "TRANSLATE_FRAGMENT",
    path: "/channel/:channelId/asset/fragment/:fragmentId/translate",
    component: TranslationDetail,
    exact: true,
    displayNav: false,
    auth: true,
  },
];

export default routes;
