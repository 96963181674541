import PropTypes from "prop-types";

/* Components */
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MUISelect from "@material-ui/core/Select";

/* Styles */
import { styled } from "@material-ui/core/styles";

const FormControlStyled = styled(FormControl)(() => ({
  minWidth: 120,
}));

const SelectStyled = styled(MUISelect)(() => ({}));

const Select = ({
  name,
  label,
  children,
  className,
  error,
  description,
  variant,
  onChange,
  value,
}) => {
  const labelId = `input-label-${name}`;

  return (
    <FormControlStyled className={className} variant={variant} error={!!error}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <SelectStyled
        labelId={labelId}
        value={value || ""}
        name={name}
        onChange={onChange}
      >
        {children}
      </SelectStyled>
      {description && <FormHelperText>{description}</FormHelperText>}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControlStyled>
  );
};

Select.displayName = "Select";
Select.defaultProps = {
  onChange: () => null,
};
Select.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.shape({}),
  ]),
  variant: PropTypes.oneOf(["filled", "outlined"]),
  className: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.node,
};

export default Select;
