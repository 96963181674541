import { forwardRef, useCallback } from "react";
import PropTypes from "prop-types";

/* Components */
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MUICheckbox from "@material-ui/core/Checkbox";

/* Styles */
import { styled } from "@material-ui/core/styles";

const MUICheckboxStyled = styled(MUICheckbox)(() => ({}));

const Checkbox = forwardRef(
  (
    {
      name,
      checked,
      trackName,
      trackDetails,
      label,
      labelPlacement,
      color,
      className,
      error,
      required,
      indeterminate,
      disabled,
      icon,
      iconChecked,
      variant,
      onChange,
      onClick,
      size,
    },
    ref
  ) => {
    const dispatchTrackingClick = useCallback(
      (event) => {
        if (!trackName) return;
        const trackingEvent = new CustomEvent("checkbox-change", {
          detail: {
            trackName,
            trackDetails: { ...trackDetails, checked: event?.target?.checked },
          },
        });
        dispatchEvent(trackingEvent);
      },
      [trackName, trackDetails]
    );

    const onChangeHandler = useCallback(
      (event) => {
        onChange(event);
        dispatchTrackingClick(event);
      },
      [onChange, dispatchTrackingClick]
    );

    return (
      <FormControl
        className={className}
        error={!!error}
        disabled={disabled}
        required={required}
        variant={variant}
      >
        <FormControlLabel
          control={
            <MUICheckboxStyled
              data-testid={name}
              name={name}
              checked={checked}
              inputRef={ref}
              color={color}
              icon={icon}
              onClick={onClick}
              checkedIcon={iconChecked}
              disabled={disabled}
              size={size}
              onChange={onChangeHandler}
              indeterminate={indeterminate}
              inputProps={{ "aria-label": `${name} checkbox` }}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
);

Checkbox.displayName = "Checkbox";

Checkbox.defaultProps = {
  color: "primary",
  labelPlacement: "end",
  size: "medium",
  onChange: () => {},
  onClick: () => {},
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["top", "bottom", "start", "end"]),
  color: PropTypes.oneOf(["default", "primary", "secondary"]),
  required: PropTypes.bool,
  error: PropTypes.string,
  trackName: PropTypes.string,
  trackDetails: PropTypes.shape({}),
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  iconChecked: PropTypes.node,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default Checkbox;
