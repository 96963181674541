/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const fileReducer = createSlice({
  name: "file",
  initialState: {
    loading: false,
    all: [],
  },
  reducers: {
    loadFiles: (state) => {
      state.loading = true;
    },
    loadFilesSuccess: (state, { payload }) => {
      state.loading = false;
      state.all = payload.data;
    },
    loadFilesFailed: (state) => {
      state.loading = false;
    },
  },
});

const { actions, reducer } = fileReducer;

export const { loadFiles, loadFilesSuccess, loadFilesFailed } = actions;
export default reducer;
