import { compose } from "redux";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";
import { Component } from "../../../components";
import CustomTableContainer from "../../../components/CustomTable";
import { getRoles } from "../Users/role.service";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";

import styles from "./List.styles";

class RoleListContainer extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.initState({
      data: [],
    });

    this.columns = [
      {
        dataKey: "name",
        label: t("labels.NAME"),
        disablePadding: true,
        width: 1,
        flexGrow: 1,
      },
      {
        dataKey: "slug",
        label: t("labels.SLUG"),
        disablePadding: true,
        width: 1,
        flexGrow: 1,
      },
    ];
  }

  componentDidMount() {
    this.preload(this.getRoles());
  }

  getRoles() {
    getRoles().then((response) => {
      this.setState({
        data: response.data.data,
      });
    });
  }

  onRowClick = ({ rowData }) => {
    const { history } = this.props;
    history.push(`/settings/role/${rowData.id}`);
  };

  render() {
    const { t, classes } = this.props;
    const { loading, data } = this.state;

    if (loading) return this.loading();

    return (
      <div style={{ flex: "1 1 auto" }}>
        <Breadcrumbs />

        <div className={classes.topHeader}>
          <div className={classes.titleHeader}>{t("titles.ROLES")}</div>
        </div>

        <CustomTableContainer
          items={data}
          columns={this.columns}
          onRowClick={this.onRowClick}
        />
      </div>
    );
  }
}

RoleListContainer.propTypes = {};

export default compose(
  withStyles(styles),
  withTranslation(),
  connect()
)(RoleListContainer);
