import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Actions
import { openFragmentCreateDialog } from "features/Fragment/reducer";

// Icons
import AddIcon from "@material-ui/icons/Add";
import MovieCreationOutlinedIcon from "@material-ui/icons/MovieCreationOutlined";
import MovieFilterOutlinedIcon from "@material-ui/icons/MovieFilterOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

// Components
import { NavLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgressCenter from "components/CircularProgressCenter";
import { FragmentCreateDialog } from "features/Fragment/FragmentCreate";

// Utils
import { convertSecondsToHuman } from "../../Fragment/utils";
import { usePersistentState } from "../../Common/hooks";

// Style
import StyledGrid, { StyledResizePanel } from "./style";

const fragmentIcons = {
  0: <MovieCreationOutlinedIcon />,
  1: <MovieFilterOutlinedIcon />,
};

const Header = ({ t, fragments }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(openFragmentCreateDialog());
  };

  return (
    <header>
      <Typography variant="h6" className="title">
        {t("text.FRAGMENTS")} ({fragments.length})
      </Typography>
      <IconButton className="add-icon" onClick={onClick}>
        <AddIcon />
      </IconButton>
    </header>
  );
};

const Fragment = ({ channelId, assetId, assetType, fragment }) => {
  const url = `/channel/${channelId}/${assetType}/${assetId}/fragment/${fragment.id}`;
  const Icon = () => fragmentIcons[fragment.trailer] || null;
  const { state, archived } = fragment;

  return (
    <Tooltip title={fragment.name}>
      <ListItem disableGutters className="list-item">
        <NavLink
          to={url}
          className={`link ${archived && "archived"}`}
          activeClassName="active"
        >
          <div className="left">
            <Icon />
            <div className="text-container">
              <ListItemText className="link-title">
                {fragment.name}
              </ListItemText>
              <Typography variant="body2" noWrap className="duration">
                {convertSecondsToHuman(fragment.duration, true)}
              </Typography>
            </div>
          </div>
          <div className="right">
            {state && (
              <Tooltip title={state.name} aria-label={state.name} arrow>
                <FiberManualRecordIcon
                  className="status-indicator"
                  style={{ fontSize: 12, color: state.color }}
                />
              </Tooltip>
            )}
          </div>
        </NavLink>
      </ListItem>
    </Tooltip>
  );
};

const DEFAULT_WIDTH = 320;

const FragmentSidebar = ({
  fragments,
  channelId,
  assetId,
  assetType,
  loading,
}) => {
  const { t } = useTranslation();
  const [sideBarWidth, setSidebarWidth] = usePersistentState(
    DEFAULT_WIDTH,
    "fragment.sidebar.width"
  );

  const onDragStop = ({ size }) => {
    setSidebarWidth(size);
  };

  return (
    <StyledResizePanel
      name="fragment.sidebar"
      direction="e"
      initialSize={sideBarWidth}
      minimumSize={84}
      maximumSize={600}
      overFlowCorrection={false}
      onDragStop={onDragStop}
    >
      <StyledGrid>
        <Header t={t} fragments={fragments} />
        {loading && <CircularProgressCenter type="inline" />}
        {!loading && (
          <Grid item className="content">
            <List disablePadding>
              {fragments.map((fragment) => (
                <Fragment
                  key={fragment.id}
                  channelId={channelId}
                  assetId={assetId}
                  fragment={fragment}
                  assetType={assetType}
                />
              ))}
            </List>
          </Grid>
        )}
        <FragmentCreateDialog />
      </StyledGrid>
    </StyledResizePanel>
  );
};

FragmentSidebar.defaultProps = {
  fragments: [],
};

FragmentSidebar.propTypes = {
  fragments: PropTypes.arrayOf(PropTypes.shape({})),
  channelId: PropTypes.number,
  assetId: PropTypes.number,
  assetType: PropTypes.string,
};

export default FragmentSidebar;
