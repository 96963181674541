const style = {
  subHeader: {
    fontWeight: "bold",
  },
  secondaryAction: {
    visibility: "hidden",
  },
  listItem: {
    "&:hover $secondaryAction": {
      visibility: "inherit",
    },
  },
};

export default style;
