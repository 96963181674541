import { memo } from "react";
import PropTypes from "prop-types";

// Icons
import FilterListIcon from "@material-ui/icons/FilterList";

// Components
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

const FilterButton = ({ onClick, active, filterCount }) => (
  <IconButton
    aria-label="filter list"
    onClick={onClick}
    color={active ? "primary" : "default"}
  >
    <Badge badgeContent={filterCount} color="primary">
      <FilterListIcon />
    </Badge>
  </IconButton>
);

FilterButton.defaultProps = {
  onClick: () => null,
};

FilterButton.propTypes = {
  active: PropTypes.bool,
  filterCount: PropTypes.number,
  onClick: PropTypes.func,
};

export default memo(FilterButton);
