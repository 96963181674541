import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { getEntryList } from "./SeoEntryPage.service";
import SeoEntryPageList from "./SeoEntryPageList";
import StyledComponent from "./SeoEntryPage.styles";
import { useSeoEntry } from "../Hooks/useSeoEntry";
import useDebounce from "../Hooks/useDebounce";
import TextField from "../../../components/Form/TextField/TextField";
import { useSelector } from "react-redux";

const DEFAULT_LANGUAGE = { code: "nl" };
const SeoEntryPage = () => {
  const {
    setLoading,
    searchParam,
    setsearchParam,
    checkedFilters,
    selectedStateFilterList,
  } = useSeoEntry();
  const [openSeoEntryList, setOpenSeoEntryList] = useState(false);
  const [seoEntryList, setSeoEntryList] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const { t } = useTranslation();
  const debouncedSearch = useDebounce(searchParam, 500);
  const history = useHistory();
  const { channelId } = useParams();
  const { code } =
    useSelector((state) => state.user?.user?.default_language) ||
    DEFAULT_LANGUAGE;

  const onAddClick = () => {
    history.push(`/channel/${channelId}/seo/create`);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      getEntryList({
        query: debouncedSearch,
        page: 1,
        sortModel: [{ field: `title.${code}`, sort: "asc" }],
        filters: { type: checkedFilters, state: selectedStateFilterList },
      })
        .then((res) => {
          setSeoEntryList(res);
          setOpenSeoEntryList(true);
          setLoading(false);
        })
        .catch(() => {
          setOpenSeoEntryList(false);
          setLoading(false);
          setErrorMessage(true);
        });
    }
    if (debouncedSearch) fetchData();
  }, [
    checkedFilters,
    debouncedSearch,
    selectedStateFilterList,
    setLoading,
    code,
  ]);

  return (
    <StyledComponent>
      <div className="searchHeader">
        <Grid item xs={12} className="inputGrid">
          <TextField
            className="searchInput"
            name="Search"
            label={`${t("labels.SEARCH")}...`}
            variant="outlined"
            onChange={(e) => setsearchParam(e.target.value || " ")}
          />
        </Grid>
        <div className="addButton">
          <IconButton
            aria-haspopup="false"
            color="primary"
            onClick={onAddClick}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
      {openSeoEntryList && (
        <SeoEntryPageList
          seoEntryList={seoEntryList.data}
          query={debouncedSearch}
          errorMessage={errorMessage}
        />
      )}
    </StyledComponent>
  );
};
export default SeoEntryPage;
