import { AllInApi } from "../../Common/services/api.service";

const api = AllInApi.getApi();

export const getEncoders = () => api.get(`${AllInApi.getApiPath()}/job/client`);

export const getEncoder = (id) =>
  api.get(`${AllInApi.getApiPath()}/job/client/${id}`);

export const saveEncoder = (encoder) => {
  if (encoder.id) {
    return api.put(
      `${AllInApi.getApiPath()}/job/client/${encoder.id}`,
      encoder
    );
  }

  return api.post(`${AllInApi.getApiPath()}/job/client`, encoder);
};
