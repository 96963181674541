import styled from "styled-components";
import Menu from "@material-ui/core/Menu";

const StyledComponent = styled.div`
  padding: ${({ theme }) =>
    `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`};

  .MuiList-root {
    min-height: 32px;
  }

  .MuiPopover-paper {
    top: 0 !important;
  }

  & > section {
    margin-bottom: 24px;

    & > .MuiList-root {
      /* height: 250px; */
      overflow-y: auto;
    }
  }

  & .MuiTab-labelIcon {
    min-height: auto;
  }

  & .MuiTabs-root {
    margin-bottom: 24px;
    min-height: 36px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};

    .MuiTabs-indicator {
      background-color: ${({ theme }) => theme.palette.grey[700]};
    }
  }

  & .MuiTab-root {
    min-height: 36px;
    padding: 0px 8px;

    .MuiTab-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      & .MuiSvgIcon-root {
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
  }

  .MuiList-padding {
    padding: 0;
  }

  .translators {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .invitation-form {
    bottom: ${({ theme }) => `${theme.spacing(2)}px`};
    padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px`};
    background: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 4px;

    & > header {
      margin-bottom: 0;
    }

    .description {
      max-width: 480px;
      color: ${({ theme }) => theme.palette.grey[500]};
      margin: 4px 0 24px 0;

      & .fi {
        margin: 0 4px 0 4px;
      }
    }
  }

  .load-more {
    margin-top: 8px;

    .MuiButton-root {
      width: auto;
    }
  }

  .load-count {
    text-align: center;
    color: ${({ theme }) => theme.palette.grey[600]};
    font-size: 0.75rem;
    margin: 6px;
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiSvgIcon-root {
    margin-right: 12px;
  }

  .icon-green {
    color: ${({ theme }) => theme.palette.success.main};
  }
  .icon-red {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[600]};
  margin-bottom: 8px;
  justify-content: space-between;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiFab-root {
    background: none;
    box-shadow: none;
    height: 32px;
    width: 32px;
  }

  .MuiTypography-root {
    font-size: 1rem;
  }

  .MuiSvgIcon-root {
    margin-right: 4px;
    font-size: 1rem;
  }
`;

export default StyledComponent;
