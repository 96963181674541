import styled from "styled-components";
import Chip from "@material-ui/core/Chip";

const StyledComponent = styled(Chip)`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.65rem !important;
  border-radius: 4px !important;
  background: ${({ theme, status }) =>
    theme.palette.status[status].main}!important;
  color: ${({ theme, status }) =>
    theme.palette.status[status].contrastText}!important;
`;

export const StyledBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin: 0px 0 0 8px;
  line-height: 0.9rem;
  background: ${({ theme, status }) =>
    theme.palette.status[status].dark}!important;
  color: ${({ theme, status }) =>
    theme.palette.status[status].contrastText}!important;
`;

export default StyledComponent;
