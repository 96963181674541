import styled from "styled-components";

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;

  & .MuiFormControl-root {
    margin-bottom: 16px;
  }
`;

export default FormStyled;
