import { useState, Fragment, memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgressCenter from "components/CircularProgressCenter";

// Local Components
import AssetRow from "./AssetRow";
import AssetHead from "./AssetHead";
import FragmentHeader from "./FragmentHeader/FragmentHeader";
import FragmentRow, { FragmentRowPreview } from "./FragmentRow";
import DetailPopoverFactory from "../DetailPopovers/DetailPopoverFactory";

// Style
import StyledTableContainer from "./style";

const AssetTable = ({
  locale,
  language,
  hits,
  totalCount,
  selectedRows,
  onRowSelect,
  type,
  onRowClick,
  onSort,
  order,
  orderBy,
  dateType,
  fetchingSelectedIds,
  onDateTypeChange,
  onSelectAllClick,
}) => {
  const { t } = useTranslation();

  const [expandedRows, setExpandedRows] = useState({});
  const [popoverEl, setPopoverEl] = useState({ element: null, id: null });
  const open = Boolean(popoverEl.element);

  const onCountryClick = ({ event, id, schedule, name, identifier }) => {
    event.stopPropagation();

    setPopoverEl({
      element: event.currentTarget,
      id,
      schedule,
      name,
      identifier,
      type: "schedule",
    });
  };

  const onStatusClick = ({ event, checks }) => {
    setPopoverEl({
      element: event.currentTarget,
      checks,
      type: "status",
    });
  };

  const handleClose = () => {
    setPopoverEl({});
  };

  const onExpandRow = (event, assetId) => {
    event.preventDefault();
    event.stopPropagation();

    setExpandedRows({
      ...expandedRows,
      [assetId]: !expandedRows[assetId],
    });
  };

  const onRowSelectHandler = (event, index) => {
    event.preventDefault();
    event.stopPropagation();

    onRowSelect(event, index);
  };

  const onDateTypeChangeHandler = (event) => {
    onDateTypeChange(event.target.value);
  };

  const onHeaderSortHandler = (property) => (event) => {
    onSort({ event, property });
  };

  const onSubtitleDetailsClick = ({ event, files }) => {
    setPopoverEl({
      element: event.currentTarget,
      files,
      type: "subtitle",
    });
  };

  const scheduleKey = `schedule.${locale}.${dateType}`;
  const nameKey = `name.${language}.raw`;
  const licensesKey = "licenses.name.raw";
  const durationKey = "stats.fragment_duration";

  return (
    <StyledTableContainer>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              {fetchingSelectedIds ? (
                <CircularProgressCenter type="inline" size={16} />
              ) : (
                <Checkbox
                  indeterminate={
                    selectedRows?.length > 0 &&
                    selectedRows?.length < totalCount
                  }
                  checked={
                    totalCount > 0 && selectedRows?.length === totalCount
                  }
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": "select all desserts" }}
                />
              )}
            </TableCell>
            <AssetHead
              keySelector={nameKey}
              orderBy={orderBy}
              order={order}
              onSort={onHeaderSortHandler}
            >
              {type === "video" ? t("labels.VIDEO") : t("labels.SERIES")}
            </AssetHead>
            <AssetHead
              keySelector={licensesKey}
              orderBy={orderBy}
              order={order}
              onSort={onHeaderSortHandler}
            >
              {t("labels.LICENSE")}
            </AssetHead>
            <TableCell className="head--countries">
              {t("labels.COUNTRIES")}
            </TableCell>
            <AssetHead
              className="head--schedule-date"
              keySelector={scheduleKey}
              orderBy={orderBy}
              order={order}
              onSort={onHeaderSortHandler}
            >
              <Select
                labelId="date-select"
                id="date-select"
                value={dateType}
                onChange={onDateTypeChangeHandler}
              >
                <MenuItem value="contract_start">
                  {t("labels.CONTRACT_START_DATE")}
                </MenuItem>
                <MenuItem value="contract_end">
                  {t("labels.CONTRACT_END_DATE")}
                </MenuItem>
                <MenuItem value="schedule_start">
                  {t("labels.PUBLICATION_DATE")}
                </MenuItem>
                <MenuItem value="schedule_end">
                  {t("labels.PUBLICATION_EXPIRATION_DATE")}
                </MenuItem>
              </Select>
            </AssetHead>
            <TableCell className="head--completed">Completed</TableCell>
            <AssetHead
              className="head--expand"
              keySelector={durationKey}
              orderBy={orderBy}
              order={order}
              onSort={onHeaderSortHandler}
            >
              {t("labels.DURATION")}
            </AssetHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {hits.map(({ _source, highlight, inner_hits: innerHits }) => {
            const {
              _id,
              id,
              identifier,
              name,
              quality,
              quality_check: qualityCheck,
              checks,
              completed,
              licenses,
              schedule,
              countries,
              fragments,
              stats,
            } = _source;
            const isSelected = selectedRows.includes(id);
            const firstFragmentId =
              fragments && fragments[0] ? fragments[0]?.id : null;
            const isOpen = Boolean(
              expandedRows[id] || innerHits?.fragments?.hits?.hits?.length
            );

            const isExpanded = Boolean(expandedRows[id]);
            const hasFragments = fragments?.length > 0;
            const shouldShowFragmentHeader = isExpanded && hasFragments;

            return (
              <Fragment key={_id}>
                <AssetRow
                  key={id}
                  id={id}
                  identifier={identifier}
                  name={name}
                  locale={locale}
                  language={language}
                  type={type}
                  dateType={dateType}
                  quality={quality}
                  qualityCheck={qualityCheck}
                  schedule={schedule}
                  completed={completed}
                  highlight={highlight}
                  checks={checks}
                  licenses={licenses}
                  countries={countries}
                  stats={stats}
                  firstFragmentId={firstFragmentId}
                  isSelected={isSelected}
                  isOpen={isOpen}
                  isExpanded={expandedRows[id]}
                  onRowClick={onRowClick}
                  onRowSelect={onRowSelectHandler}
                  onCountryClick={onCountryClick}
                  onStatusClick={onStatusClick}
                  onExpandRow={onExpandRow}
                  onSubtitleDetailsClick={onSubtitleDetailsClick}
                />

                {shouldShowFragmentHeader && (
                  <FragmentHeader
                    key={`${id}_fragment_header`}
                    translations={{
                      fragment: t("labels.FRAGMENT"),
                      subtitles: t("labels.SUBTITLES"),
                      images: t("labels.IMAGES"),
                      drm: t("labels.DRM_ABBREVIATION"),
                      status: t("labels.STATUS"),
                    }}
                  />
                )}

                {innerHits?.fragments.hits?.hits.map(
                  (
                    { _source: fragment, highlight: fragmentHighlight },
                    index
                  ) => {
                    const last =
                      index === innerHits?.fragments.hits?.hits?.length - 1 &&
                      !expandedRows[id];
                    const showPreview = !isExpanded;

                    if (showPreview) {
                      return (
                        <FragmentRowPreview
                          key={fragment?.id}
                          fragment={fragment}
                          className={last ? "last" : ""}
                          highlight={fragmentHighlight}
                          assetId={id}
                          type={type}
                          locale={locale}
                          language={language}
                          onRowClick={onRowClick}
                        />
                      );
                    }

                    return (
                      <FragmentRow
                        key={fragment?.id}
                        fragment={fragment}
                        className={last ? "last" : ""}
                        highlight={fragmentHighlight}
                        assetId={id}
                        type={type}
                        locale={locale}
                        language={language}
                        onRowClick={onRowClick}
                        onSubtitleDetailsClick={onSubtitleDetailsClick}
                      />
                    );
                  }
                )}

                {isExpanded &&
                  fragments?.map((fragment, index) => {
                    const last = index === fragments.length - 1;
                    // Warning: this could slow down the ui.
                    const shownAsHint = innerHits?.fragments.hits?.hits.find(
                      ({ _source: hint }) => hint.id === fragment.id
                    );

                    if (shownAsHint) return null;
                    return (
                      <FragmentRow
                        key={fragment?.id}
                        fragment={fragment}
                        className={last ? "last" : ""}
                        assetId={id}
                        type={type}
                        locale={locale}
                        onRowClick={onRowClick}
                        language={language}
                        onSubtitleDetailsClick={onSubtitleDetailsClick}
                      />
                    );
                  })}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
      <Popover
        id={popoverEl.id}
        open={open}
        anchorEl={popoverEl.element}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DetailPopoverFactory
          type={popoverEl.type}
          locale={locale}
          {...popoverEl}
        />
      </Popover>
    </StyledTableContainer>
  );
};

AssetTable.propTypes = {
  locale: "nl",
  language: "nl",
  hits: [],
  totalCount: 0,
  dateType: "schedule_start",
  order: "asc",
  onRowClick: () => null,
  onSort: () => null,
  onRowSelect: () => null,
};

AssetTable.propTypes = {
  locale: PropTypes.string,
  language: PropTypes.string,
  hits: PropTypes.arrayOf(PropTypes.shape({})),
  fetchingSelectedIds: PropTypes.bool,
  totalCount: PropTypes.number,
  dateType: PropTypes.string,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  type: PropTypes.oneOf(["video", "series"]),
  onRowClick: PropTypes.func,
  onSort: PropTypes.func,
  onRowSelect: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default memo(AssetTable);
