import moment from "moment";
import * as Constants from "../Common/constants";

export const getAssetStoragePath = (file) =>
  `${Constants.CDN_URL}/${Constants.PATH_STORAGE}/${file}`;

export const getAssetVideoPath = (hash) =>
  `${Constants.CDN_URL}/${Constants.PATH_FILE_STORAGE}/${hash}.mp4`;

export const getAssetWebSubtitlePath = (path) =>
  `${Constants.CDN_URL}/${Constants.PATH_STORAGE}${path.substr(
    0,
    path.lastIndexOf(".")
  )}.vtt`;

function pad(number, size) {
  const numberLength = number.toString().length;

  let length = size;
  if (size < numberLength) {
    length = numberLength;
  }

  const s = `000000000${String(number)}`;
  return s.substr(s.length - length);
}

function makeDuration(duration, short) {
  if (!duration.isValid()) {
    return null;
  }

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const milliseconds = Math.round(duration.milliseconds());

  if (short) {
    return `${
      (hours ? `${hours}:` : "") + (hours ? pad(minutes, 2) : minutes)
    }:${pad(seconds, 2)}`;
  }

  return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(
    milliseconds,
    3
  )}`;
}

export const convertSecondsToHuman = (seconds, short) =>
  makeDuration(moment.duration(seconds), short);

export const convertHumanToSeconds = (value) => {
  if (!value || value.includes("_")) {
    return null;
  }
  if (
    !Number.isNaN(parseFloat(value)) &&
    !Number.isFinite(value) &&
    value >= 60
  ) {
    return value;
  }

  const duration = moment.duration(value);

  // @TODO: add validation to duration, when https://github.com/moment/moment/issues/1805 is fixed
  return duration.asMilliseconds();
};

export const getSubtitleTracks = (files) =>
  files
    ?.filter(
      (file) => file.type?.identifier === "subtitle" && file.status === "active"
    )
    .map((track) => ({
      ...track,
      src: getAssetStoragePath(
        track.paths.find((path) => path.identifier === "vtt")?.path
      ),
    })) || [];

/**
 * Format mime types so that we can use it as
 * a drop zone accept type.
 *
 * @param {*} mimes ['txt', 'srt', 'vtt]
 * @return single joined string prefixed with dot: '.txt, .srt, .vtt'
 */
export const formatMimes = (mimes) => {
  if (!mimes) return mimes;
  return mimes.map((mime) => `.${mime}`)?.join(",");
};

export const actionToColor = (action) => {
  switch (action) {
    case "uploaded":
      return "#4caf50";
    case "updated":
      return "#a7a45c";
    case "sync":
      return "#7196a7";
    case "ready":
      return "#649eb5";
    case "postponed":
      return "#bfc2c6";
    case "queued":
      return "#a76ea4";
    case "started":
      return "#a78101";
    case "in_progress":
      return "#017e9c";
    case "success":
      return "#009c3d";
    case "error":
      return "#9c0011";
    case "created":
      return "#1f1f1f";
    default:
      return null;
  }
};
