/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */

import { Component } from "react";
import { compose } from "redux";

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";
import moment from "moment";
import pretty from "prettysize";

import styles from "./TimeLine.style";

class Event extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTags: false,
      showConfig: false,
      showStreams: false,
      showMore: false,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  renderValue(key, value) {
    switch (key) {
      case "file_size":
        return pretty(value);
      case "size":
        return pretty(value);
      case "bit_rate":
        return `${pretty(value)}/s`;
      case "date":
        return moment.unix(value).format("DD-MM-YYYY H:mm:ss");
      case "duration":
        return `${Math.round(value / 60)} min`;
      default:
        return JSON.stringify(value).replace('"', "").replace('"', "");
    }
  }

  render() {
    const { t, classes, event } = this.props;
    const { showTags, showConfig, showStreams, showMore } = this.state;

    return (
      <div className={`${classes.entry} ${classes[`state_${event.action}`]}`}>
        <div className={`${classes.entryTitle} entryTitle`}>
          <h3 className={classes.titleH3}>
            {moment.unix(event.updated_at).format("DD MMM YYYY")}
          </h3>
          <p className={classes.titleP}>
            {moment.unix(event.updated_at).format("H:mm:ss")}
          </p>
        </div>

        <div className={classes.body}>
          <p className="actionTitle">
            {event.action}{" "}
            {event.user && (
              <span style={{ color: "#999" }}>
                <small>
                  {t("text.BY")} <strong>{event.user.name}</strong>
                </small>
              </span>
            )}
            {event.object && (
              <span style={{ color: "#999" }}>
                {!showMore && (
                  <ExpandMoreIcon
                    onClick={() => this.setState({ showMore: true })}
                    size="small"
                  />
                )}
                {showMore && (
                  <ExpandLessIcon
                    onClick={() => this.setState({ showMore: false })}
                    size="small"
                  />
                )}
              </span>
            )}
          </p>

          {event.object && showMore && (
            <ul>
              {Object.keys(event.object).map((key) => {
                if (
                  [
                    "format",
                    "streams",
                    "config",
                    "file",
                    "encode",
                    "sprite",
                  ].indexOf(key) > -1
                )
                  return null;

                return (
                  <li key={key}>
                    {key === "command" ? (
                      <code>{event.object[key]}</code>
                    ) : (
                      <span>
                        <strong>{key}</strong>:{" "}
                        {this.renderValue(key, event.object[key])}
                      </span>
                    )}
                  </li>
                );
              })}

              {event.object.encode && (
                <li>
                  <span>
                    <strong>{t("status.jobs.ENCODE")}</strong>
                  </span>
                  <ul>
                    {Object.keys(event.object.encode).map(
                      (key) =>
                        event.object.encode[key] && (
                          <li key={key}>
                            <strong>{key}</strong>:{" "}
                            {this.renderValue(key, event.object.encode[key])}
                          </li>
                        )
                    )}
                  </ul>
                </li>
              )}

              {event.object.sprite && (
                <li>
                  <span>
                    <strong>{t("labels.SPRITE")}</strong>
                  </span>
                  <ul>
                    {Object.keys(event.object.sprite).map(
                      (key) =>
                        event.object.sprite[key] && (
                          <li key={key}>
                            <strong>{key}</strong>:{" "}
                            {this.renderValue(key, event.object.sprite[key])}
                          </li>
                        )
                    )}
                  </ul>
                </li>
              )}

              {event.object.file && (
                <li>
                  <span>
                    <strong>{t("labels.FILE")}</strong>
                  </span>
                  <ul>
                    {Object.keys(event.object.file).map(
                      (key) =>
                        event.object.file[key] && (
                          <li key={key}>
                            <strong>{key}</strong>:{" "}
                            {this.renderValue(key, event.object.file[key])}
                          </li>
                        )
                    )}
                  </ul>
                </li>
              )}

              {event.object.format &&
                Object.keys(event.object.format).map(
                  (key) =>
                    event.object.format[key] && (
                      <li key={key}>
                        {key !== "tags" ? (
                          <span>
                            <strong>{key}</strong>:{" "}
                            {this.renderValue(key, event.object.format[key])}
                          </span>
                        ) : (
                          <>
                            <strong>{t("labels.TAGS")}</strong>{" "}
                            <Link
                              onClick={() =>
                                this.setState({ showTags: !showTags })
                              }
                            >
                              {showTags ? t("text.HIDE") : t("text.SHOW")}
                            </Link>
                            {showTags && (
                              <ul>
                                {Object.keys(event.object.format[key]).map(
                                  (key2) =>
                                    event.object.format[key][key2] && (
                                      <li>
                                        <strong>{key2}</strong>:{" "}
                                        {this.renderValue(
                                          key2,
                                          event.object.format[key][key2]
                                        )}
                                      </li>
                                    )
                                )}
                              </ul>
                            )}
                          </>
                        )}
                      </li>
                    )
                )}

              {event.object.config && (
                <li>
                  <strong>{t("labels.CONFIG")}</strong>{" "}
                  <Link
                    onClick={() => this.setState({ showConfig: !showConfig })}
                  >
                    {showConfig ? t("text.HIDE") : t("text.SHOW")}
                  </Link>
                  {showConfig && (
                    <ul>
                      {Object.keys(event.object.config).map(
                        (key) =>
                          event.object.config[key] && (
                            <li key={key}>
                              <span>
                                <strong>{key}</strong>:{" "}
                                {JSON.stringify(event.object.config[key])}
                              </span>
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </li>
              )}

              {event.object.streams && (
                <li>
                  <strong>{t("labels.STREAMS")}</strong>{" "}
                  <Link
                    onClick={() => this.setState({ showStreams: !showStreams })}
                  >
                    {showStreams ? t("text.HIDE") : t("text.SHOW")}
                  </Link>
                  {showStreams &&
                    event.object.streams.map(
                      (stream) =>
                        stream && (
                          <ul key={stream.index}>
                            {Object.keys(stream).map((streamKey) => (
                              <li key={stream.index + streamKey}>
                                <strong>{streamKey}</strong>:{" "}
                                {this.renderValue(streamKey, stream[streamKey])}
                              </li>
                            ))}
                          </ul>
                        )
                    )}
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default compose(withStyles(styles), withTranslation())(Event);
