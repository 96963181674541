import styled from "styled-components";

const StyledComponent = styled.div`
  ${({ theme }) => `
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;


    .seoList {
      display: none;
    }

    .entryPageGrid {
      display: flex;
      width: 100%;
      flex: 1 1 auto;
      overflow: hidden;
      border-top: 2px solid #e0e0e0;
    }
    .entryListScrollbar {
      width: 100%;
      overflow-y: scroll;
    }
    .MuiDataGrid-columnHeader {
      background-color: #f6f6f6;
    }
    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
      color: #616161;
    }
      .searchInput {
      width: 350px;
      background-color: #fff;
    }
    .searchHeader {
      display: flex;
      flex: 0 1 auto;
    }
    .addButton {
      padding: 5px;
      display: flex;
      background-color: #fafafa;;
    }
    .inputGrid {
      display: flex;
      padding: ${theme.spacing(2)}px;
      background: #fafafa;
      justify-content: center;
    }
    .errorMessage {
      text-align: center;
    }
  `}
`;

export default StyledComponent;
