import { takeLatest, all } from "redux-saga/effects";
import * as StartupTypes from "./redux/Startup.types";
import { AllInApi } from "./services/api.service";
import * as StartupSagas from "./Startup.sagas";

function* sagas() {
  return yield all([
    takeLatest(StartupTypes.STARTUP, StartupSagas.startUp, AllInApi),
  ]);
}

export default sagas;
