import { useCallback } from "react";
import PropTypes from "prop-types";

// Components
import CheckBox from "components/Form/Checkbox";

// Styles
import StyledComponent from "./style";

const FilterRefinementOption = ({
  label,
  trackName,
  trackDetails,
  onClick,
  active,
  count,
}) => {
  const dispatchTrackingClick = useCallback(() => {
    if (!trackName) return;
    const trackingEvent = new CustomEvent("button-click", {
      detail: {
        trackName,
        trackDetails: { trackName, ...trackDetails },
      },
    });
    dispatchEvent(trackingEvent);
  }, [trackName, trackDetails]);

  const onClickHandler = useCallback(
    (event) => {
      onClick(event);
      dispatchTrackingClick();
    },
    [onClick, dispatchTrackingClick]
  );

  return (
    <StyledComponent color="default" variant="text" onClick={onClickHandler}>
      <div className="column-left">
        <CheckBox name={label} checked={active} size="small" />
        {label}
      </div>
      {count}
    </StyledComponent>
  );
};

FilterRefinementOption.defaultProps = {
  active: false,
  onClick: () => null,
};

FilterRefinementOption.propTypes = {
  label: PropTypes.string.isRequired,
  trackName: PropTypes.string,
  trackDetails: PropTypes.shape({}),
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FilterRefinementOption;
