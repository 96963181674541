import { useRef, useState } from "react";
import PropTypes from "prop-types";

// Icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// Components
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

const SplitButton = ({
  options,
  placeholderOption,
  children,
  variant,
  selectedIndex,
  size,
  disableElevation,
  onMenuItemClick,
  onClick,
}) => {
  // State
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  // Props
  const { title, className, startIcon, endIcon } = options[selectedIndex]
    ? options[selectedIndex]
    : placeholderOption;

  // Listeners
  const handleClick = (event) => {
    onClick({ event, index: selectedIndex, option: options[selectedIndex] });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (event, index) => {
    setOpen(false);
    onMenuItemClick({ event, index, option: options[index] });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant={variant}
        color="primary"
        ref={anchorRef}
        size={size}
        className={className}
        aria-label="split button"
        disableElevation={disableElevation}
      >
        <Button
          size={size}
          startIcon={startIcon}
          endIcon={endIcon}
          onClick={handleClick}
        >
          {title}
        </Button>
        <Button size={size} color="primary" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      {children({
        anchorRef,
        open,
        selectedIndex,
        handleMenuItemClick,
        handleClose,
      })}
    </>
  );
};

SplitButton.defaultProps = {
  options: [
    {
      title: "Placeholder 1",
      startIcon: null,
      endIcon: null,
      className: "",
    },
  ],
  disableElevation: false,
  size: "medium",
  variant: "outlined",
  children: () => {},
  onClick: () => {},
  onMenuItemClick: () => {},
};

SplitButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      title: PropTypes.string.isRequired,
      startIcon: PropTypes.node,
      endIcon: PropTypes.node,
      className: PropTypes.string,
      additionalData: PropTypes.shape({}),
    })
  ),
  placeholderOption: PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
    title: PropTypes.string.isRequired,
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    className: PropTypes.string,
    additionalData: PropTypes.shape({}),
  }),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  disableElevation: PropTypes.bool,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  children: PropTypes.func,
  onClick: PropTypes.func,
  onMenuItemClick: PropTypes.func,
};

export default SplitButton;
