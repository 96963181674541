import { memo } from "react";
import { useTranslation } from "react-i18next";

// Components
import Alert from "@material-ui/lab/Alert";
import CircularProgressCenter from "components/CircularProgressCenter";
import List from "@material-ui/core/List";
import Button from "components/Button";

// Constants
import { INVITATION_TYPE } from "../constants";

// Local Components
import Proofreader from "./Proofreader";
import EmptyMessage from "../EmptyMessage";

const Proofreaders = ({
  fragmentId,
  locale,
  error,
  loading,
  proofreaders,
  loaded,
  total,
  loadMore,
  onMenuItemClick,
}) => {
  const { t } = useTranslation();

  return (
    <section className="translators">
      <List>
        {error && <Alert severity="error">{error}</Alert>}
        {!error && !proofreaders?.length && !loading && (
          <EmptyMessage message={t("text.NO_PROOFREADERS_INVITED")} />
        )}
        {loading && <CircularProgressCenter size={20} variant="inline" />}
        {!loading &&
          proofreaders?.map(
            ({
              id,
              email,
              created_at,
              expires_at,
              deleted_at,
              last_used_at,
              due_date,
            }) => (
              <Proofreader
                key={id}
                id={id}
                type={INVITATION_TYPE.PROOFREAD}
                email={email}
                fragmentId={fragmentId}
                createdAt={created_at}
                expiresAt={expires_at}
                deletedAt={deleted_at}
                lastUsedAt={last_used_at}
                dueDate={due_date}
                onMenuItemClick={onMenuItemClick}
              />
            )
          )}
      </List>
      {loaded < total && (
        <>
          <p className="load-count">
            {t("labels.PROOFREADERS_LOADED", {
              loaded,
              total,
            })}
          </p>
          <Button
            variant="outlined"
            className="load-more"
            size="small"
            trackName="fragment.subtitles.translators.loadmore"
            onClick={loadMore}
            trackDetails={{
              fragmentId,
              locale,
            }}
          >
            {t("buttons.LOAD_MORE")}
          </Button>
        </>
      )}
    </section>
  );
};

export default memo(Proofreaders);
