import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  getFragmentJobs,
  saveFile,
  changePriority,
  deleteJob,
  startJob as startJobRequest,
} from "../services";
import { getCurrentFragment } from "../selectors";

const JobContext = createContext();
export const useJob = () => useContext(JobContext);

const JobProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);
  const [jobsLoading, setJobsLoading] = useState(true);
  const [streams, setStreams] = useState([]);
  const [config, setConfig] = useState(null);
  const [timelineFilter, setTimelineFilter] = useState([]);
  const { data: fragment } = useSelector(getCurrentFragment);

  const fragmentId = fragment?.id;
  const fileId = fragment?.file?.id;

  const updateJob = (updatedJob) => {
    const indexOfJob = jobs.findIndex((job) => job.id === updatedJob.id);
    const newJobs = [...jobs];

    if (indexOfJob > -1) {
      if (updatedJob.logs) {
        newJobs[indexOfJob] = updatedJob;
      } else {
        newJobs[indexOfJob] = { ...updatedJob, logs: jobs[indexOfJob].logs };
      }
    } else {
      newJobs.push(updatedJob);
    }

    setJobs(newJobs);
  };

  const updateConfig = useCallback(
    (newConfig) => {
      setConfig(newConfig);

      saveFile({
        ...fragment.file,
        config: Object.keys(newConfig).length > 0 ? { ...newConfig } : {},
      }).then((response) => {
        setConfig(response.data.config);
      });
    },
    [fragment]
  );

  const changePriorityHandler = (job) => {
    changePriority(job).then((response) => {
      updateJob(response.data);
    });
  };

  const deleteJobHandler = (job) => {
    deleteJob(job).then(() =>
      setJobs([...jobs.filter((fJob) => fJob.id !== job.id)])
    );
  };

  const startJob = (commandId, presetId) => {
    startJobRequest(fragment.id, {
      command: {
        id: commandId,
      },
      ...(presetId && { preset: { id: presetId } }),
    }).then((response) => {
      response.data.forEach((item) => {
        updateJob(item);
      });
    });
  };

  const changeTimelineFilter = (commandId) => {
    if (timelineFilter.indexOf(commandId) > -1) {
      return setTimelineFilter([
        ...timelineFilter.filter((item) => item !== commandId),
      ]);
    }

    const newFilter = [...timelineFilter, commandId];
    return setTimelineFilter(newFilter);
  };

  useEffect(() => {
    if (!fileId || !fragmentId) return null;

    setJobsLoading(true);

    getFragmentJobs(fragmentId, fileId).then((response) => {
      setJobs(response.data.data.jobs);
      setConfig(response.data.data.file.config);
      setStreams(response.data.data.file.streams || []);
      setJobsLoading(false);
    });
  }, [fileId, fragmentId]);

  return (
    <JobContext.Provider
      value={{
        updateConfig,
        jobs,
        setJobs,
        jobsLoading,
        setJobsLoading,
        streams,
        setStreams,
        config,
        setConfig: updateConfig,
        changePriorityHandler,
        deleteJobHandler,
        fragment,
        startJob,
        timelineFilter,
        changeTimelineFilter,
      }}
    >
      {children}
    </JobContext.Provider>
  );
};

JobProvider.propTypes = {
  children: PropTypes.node,
};

export default JobProvider;
