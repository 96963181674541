/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import HistoryIcon from "@material-ui/icons/History";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LanguageIcon from "@material-ui/icons/Language";
import FindInPageIcon from "@material-ui/icons/FindInPage";

// Components
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import NationFlag from "components/NationFlag";
import Button from "components/Button";
import FragmentSubtitleCompare from "features/Fragment/FragmentSubtitleCompare";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TabsPanel from "components/Tabs/TabsPanel";

// Actions
import { loadFragment } from "features/Fragment/reducer";

// Constants
import { INVITATION_TYPE } from "features/Fragment/FragmentRevisionTranslatorPanel/constants";

// Hooks
import { useTabs } from "components/Tabs";
import usePaginatedDataSource from "./usePaginatedDataSource";

// Local Components
import Revisions from "./Revisions";
import Translators from "./Translators";
import Proofreaders from "./Proofreaders";
import InvitationForm from "./InvitationForm";

// Style
import StyledComponent, { StyledHeader } from "./style";

// Services
import {
  getTranslators,
  revokeTranslatorLink,
  inviteTranslator,
  getFragmentFiles,
  changeRevisionStatus,
  deleteRevision,
} from "../services";

const Header = ({ icon, title, children }) => (
  <StyledHeader>
    <div>
      {icon}
      <Typography variant="h6">{title}</Typography>
    </div>
    {children}
  </StyledHeader>
);

const PAGE_SIZE = 3;
export const TABS = {
  REVISION: 0,
  TRANSLATORS: 1,
  PROOFREADER: 2,
};

const RevisionTranslatorPanel = ({
  fragmentId,
  fragmentName,
  locale,
  nativeName,
  defaultTab,
  defaultFileId,
  highlightFileId,
  onMenuClick,
  onTabChange,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar() || {};
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux
  const assetIdentifier = useSelector(
    (state) => state.asset?.selected?.data?.identifier
  );

  // State
  const [compareDialogState, setCompareDialogState] = useState({
    open: false,
    compareFileId: null,
  });

  const [deleteFileDialogState, setDeleteFileDialogState] = useState({
    open: false,
    action: null,
  });

  const [tabIndex, onChangeTab, setTabIndex] = useTabs({ onTabChange });

  // Data Sources
  const {
    doRequest: loadRevisionsRequest,
    data: revisions,
    paginatedData: paginatedRevisions,
    loading: revisionsLoading,
    error: revisionsError,
    setData: updateRevisions,
    loadMore: loadMoreRevisions,
    total: revisionsTotal,
    loaded: revisionsLoaded,
  } = usePaginatedDataSource({
    request: getFragmentFiles,
    params: {
      fragmentId,
      status: null,
      type: "subtitle",
      locale,
    },
    errorMessage: t("error.REVISIONS"),
    pageSize: 10,
  });

  const {
    doRequest: loadTranslatorsRequest,
    paginatedData: translators,
    setData: updateTranslators,
    loading: translatorsLoading,
    error: translatorsError,
    loadMore: loadMoreTranslators,
    total: translatorsTotal,
    loaded: translatorsLoaded,
  } = usePaginatedDataSource({
    request: getTranslators,
    params: { fragmentId, type: INVITATION_TYPE.TRANSLATE, locale },
    errorMessage: t("error.TRANSLATORS"),
    pageSize: PAGE_SIZE,
  });

  const {
    doRequest: loadProofreadersRequest,
    paginatedData: proofreaders,
    setData: updateProofreaders,
    loading: proofreadersLoading,
    error: proofreadersError,
    loadMore: loadMoreProofreaders,
    total: proofreadersTotal,
    loaded: proofreadersLoaded,
  } = usePaginatedDataSource({
    request: getTranslators,
    params: { fragmentId, type: INVITATION_TYPE.PROOFREAD, locale },
    errorMessage: t("error.TRANSLATORS"),
    pageSize: PAGE_SIZE,
  });

  // Effects
  const activeSubtitle = useMemo(
    () => revisions?.find((revision) => revision.status === "active"),
    [revisions]
  );

  useEffect(() => {
    if (defaultTab == null) return;
    setTabIndex(defaultTab);
  }, [defaultTab, setTabIndex]);

  useEffect(() => {
    if (!fragmentId || !locale) return;
    loadTranslatorsRequest();
    loadProofreadersRequest();
    loadRevisionsRequest();
  }, [
    fragmentId,
    locale,
    loadTranslatorsRequest,
    loadRevisionsRequest,
    loadProofreadersRequest,
  ]);

  // Listeners
  const updateInviteesList = ({ invitees, type }) => {
    switch (type) {
      case INVITATION_TYPE.PROOFREAD:
        updateProofreaders(invitees);
        break;
      case INVITATION_TYPE.TRANSLATE:
        updateTranslators(invitees);
        break;
      default:
        break;
    }
  };

  const onInvitationComplete = ({ invitees, type }) => {
    updateInviteesList({ invitees, type });

    const message =
      type === INVITATION_TYPE.TRANSLATE
        ? t("text.TRANSLATOR_INVITE_SUCCESS")
        : t("text.PROOFREADER_INVITE_SUCCESS");

    enqueueSnackbar(message, {
      variant: "success",
      action: (key) => {
        return (
          <>
            <IconButton
              color="inherit"
              aria-label="Close"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon />
            </IconButton>
          </>
        );
      },
    });
  };

  // Revoke link for external translator
  const revokeLink = ({ id, type, setLoading, setMenuRef }) => {
    setLoading(true);
    revokeTranslatorLink({ fragmentId, locale, id, type })
      .then((response) => {
        updateInviteesList({ invitees: response?.data?.data || [], type });
        setMenuRef(null);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  // Invite external translator
  const invite = ({ email, message, type, setLoading, setMenuRef }) => {
    setLoading(true);
    inviteTranslator({
      fragmentId,
      locale,
      email,
      message,
      type,
      dueDate: moment().add(15, "days"),
    })
      .then((response) => {
        onInvitationComplete({ invitees: response?.data?.data || [], type });
        setMenuRef(null);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const changeSubtitleStatus = ({ id, setLoading, setMenuRef, status }) => {
    setLoading(true);

    changeRevisionStatus({
      fragmentId,
      fileId: id,
      locale,
      status,
    })
      .then((response) => {
        updateRevisions(response?.data?.data || []);
        dispatch(
          loadFragment({
            fragmentId,
            showLoader: false,
          })
        );
        setMenuRef(null);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  // Invite external translator
  const deleteRevisionPermanently = ({ id, setLoading, setMenuRef }) => {
    setLoading(true);
    deleteRevision({
      fragmentId,
      fileId: id,
      locale,
    })
      .then((response) => {
        setMenuRef(null);
        updateRevisions(response?.data?.data || []);
        dispatch(
          loadFragment({
            fragmentId,
            showLoader: false,
          })
        );
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const showDeleteDialog = ({ id, setLoading, setMenuRef }) => {
    setDeleteFileDialogState({
      open: true,
      action: () => {
        setDeleteFileDialogState({ open: false });
        deleteRevisionPermanently({ id, setLoading, setMenuRef });
      },
    });
  };

  const openCompareDialog = ({ id }) => {
    setCompareDialogState({
      open: true,
      compareFileId: id,
    });
  };

  const closeCompareDialog = () => {
    setCompareDialogState({
      open: false,
      compareFileId: null,
    });
  };

  const closeDeleteDialog = () => {
    setDeleteFileDialogState({
      open: false,
      action: null,
    });
  };

  const onMenuItemClick = ({
    e,
    key,
    id,
    type,
    locale,
    email,
    setLoading,
    setMenuRef,
  }) => {
    switch (key) {
      case "invite.proofreader":
        onMenuClick({
          e,
          fileId: id,
          invitationType: INVITATION_TYPE.PROOFREAD,
          tab: TABS.PROOFREADER,
          language: locale,
        });
        break;
      case "revoke":
        revokeLink({ id, setLoading, setMenuRef, type });
        break;
      case "invite":
        const message =
          type === INVITATION_TYPE.TRANSLATE
            ? t("text.INVITE_TRANSLATOR_MESSAGE")
            : t("text.INVITE_PROOF_READER_MESSAGE");
        invite({ email, setLoading, setMenuRef, type, message });
        break;
      case "delete":
        showDeleteDialog({ id, setLoading, setMenuRef });
        break;
      case "approve":
        changeSubtitleStatus({
          id,
          email,
          setLoading,
          setMenuRef,
          status: "active",
        });
        break;
      case "draft":
        changeSubtitleStatus({
          id,
          email,
          setLoading,
          setMenuRef,
          status: "draft",
        });
        break;
      case "reject":
        changeSubtitleStatus({
          id,
          email,
          setLoading,
          setMenuRef,
          status: "inactive",
        });
        break;
      case "view_in_compare":
        openCompareDialog({ id });
        setMenuRef(null);
        break;
      default:
        break;
    }
  };

  return (
    <StyledComponent>
      <Tabs
        value={tabIndex}
        onChange={onChangeTab}
        aria-label="simple tabs example"
      >
        <Tab label="Revisions" icon={<HistoryIcon />} />
        <Tab label="Translators" icon={<LanguageIcon />} />
        <Tab label="Proofreaders" icon={<FindInPageIcon />} />
      </Tabs>

      <TabsPanel tabIndex={TABS.REVISION} activeTabIndex={tabIndex}>
        <Revisions
          fragmentId={fragmentId}
          locale={locale}
          error={revisionsError}
          loading={revisionsLoading}
          paginatedData={paginatedRevisions}
          loaded={revisionsLoaded}
          total={revisionsTotal}
          loadMore={loadMoreRevisions}
          highlightFileId={highlightFileId}
          onMenuItemClick={onMenuItemClick}
        />
      </TabsPanel>
      <TabsPanel tabIndex={TABS.TRANSLATORS} activeTabIndex={tabIndex}>
        <Translators
          fragmentId={fragmentId}
          locale={locale}
          error={translatorsError}
          loading={translatorsLoading}
          translators={translators}
          loaded={translatorsLoaded}
          total={translatorsTotal}
          loadMore={loadMoreTranslators}
          onMenuItemClick={onMenuItemClick}
        />
        <section className="invitation-form">
          <Header
            icon={<MailOutlineIcon />}
            title={t("labels.INVITE_TRANSLATOR")}
          />
          <p className="description">
            {t("text.TRANSLATOR_INVITE_DESCRIPTION")}
            <NationFlag countryCode={locale} />
            <span>{nativeName}</span>
          </p>
          <InvitationForm
            assetName={assetIdentifier}
            fragmentId={fragmentId}
            localeCode={locale}
            translators={translators}
            revisions={revisions}
            defaultType={INVITATION_TYPE.TRANSLATE}
            defaultFileId={defaultFileId}
            onInvitationComplete={onInvitationComplete}
          />
        </section>
      </TabsPanel>
      <TabsPanel tabIndex={TABS.PROOFREADER} activeTabIndex={tabIndex}>
        <Proofreaders
          fragmentId={fragmentId}
          locale={locale}
          error={proofreadersError}
          loading={proofreadersLoading}
          proofreaders={proofreaders}
          loaded={proofreadersLoaded}
          total={proofreadersTotal}
          loadMore={loadMoreProofreaders}
          onMenuItemClick={onMenuItemClick}
        />
        <section className="invitation-form">
          <Header
            icon={<MailOutlineIcon />}
            title={t("labels.INVITE_PROOFREADER")}
          />
          <p className="description">
            {t("text.PROOFREADER_INVITE_DESCRIPTION")}
            <NationFlag countryCode={locale} />
            <span>{nativeName}</span>
          </p>
          <InvitationForm
            assetName={assetIdentifier}
            fragmentId={fragmentId}
            localeCode={locale}
            translators={translators}
            revisions={revisions}
            defaultType={INVITATION_TYPE.PROOFREAD}
            defaultFileId={defaultFileId}
            onInvitationComplete={onInvitationComplete}
          />
        </section>
      </TabsPanel>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={compareDialogState.open}
        onClose={closeCompareDialog}
        aria-labelledby="max-width-dialog-title"
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeCompareDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{t("labels.COMPARE_FILES")}</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Alert severity="warning" style={{ margin: "8px 0 24px 0" }}>
            {t("text.COMPARE_EXPERIMENTAL_FEATURE")}
          </Alert>
          <FragmentSubtitleCompare
            locale={locale}
            subtitles={revisions}
            nativeName={nativeName}
            oldFileId={activeSubtitle?.id}
            newFileId={compareDialogState.compareFileId}
            fragmentName={fragmentName}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteFileDialogState.open}
        onClose={closeDeleteDialog}
        aria-labelledby="Sure you want to delete dialog"
      >
        <DialogTitle style={{ cursor: "move" }}>
          {t("labels.DELETE_FILE")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("text.CONFIRM_DELETE_FILE")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            trackName="subtitle.revision.delete.cancel"
            autoFocus
            color="default"
            onClick={closeDeleteDialog}
          >
            {t("buttons.CANCEL")}
          </Button>
          <Button
            trackName="subtitle.revision.delete.confirm"
            onClick={deleteFileDialogState.action}
            color="primary"
          >
            {t("buttons.DELETE")}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledComponent>
  );
};

RevisionTranslatorPanel.propTypes = {
  fragmentId: PropTypes.number,
  fragmentName: PropTypes.string,
  locale: PropTypes.string,
  highlightFileId: PropTypes.number,
  nativeName: PropTypes.string,
  defaultTab: PropTypes.number,
};

export default RevisionTranslatorPanel;
