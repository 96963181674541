import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// Components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/Button/Button";

// Redux
import { getCurrentFragment } from "features/Fragment/selectors";
import { DialogContent } from "@material-ui/core";
import { archiveFragment } from "../services";
import { patchFragmentSuccess } from "../reducer";
import { findById } from "features/Asset/Asset.actions";

const FragmentArchive = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: fragment, deleting } = useSelector(getCurrentFragment);

  const fragmentId = fragment?.id;

  const handleClick = async () => {
    const response = await archiveFragment(fragmentId);
    dispatch(patchFragmentSuccess(response.data));
    dispatch(findById(fragment.asset.id));
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("buttons.ARCHIVE")}</DialogTitle>
      <DialogContent>
        {t("text.ARCHIVE_FRAGMENT_CONFIRMATION", {
          fragment: fragment?.name,
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="default"
          trackName="fragment.archive.cancel"
          trackDetails={{ fragmentId }}
        >
          {t("text.NO")}
        </Button>
        <Button
          onClick={handleClick}
          color="primary"
          trackName="fragment.archive.confirm"
          trackDetails={{
            fragmentId,
          }}
          loading={deleting}
        >
          {t("text.YES")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(FragmentArchive);
