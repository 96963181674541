import { Component } from "react";
import PropTypes from "prop-types";
import { InlineDatePicker } from "material-ui-pickers";
import moment from "moment";

import * as Constants from "../../../features/Common/constants";
import Utils from "../../../features/Common/utils";

class InlineDatePickerComponent extends Component {
  render() {
    const {
      field: { name, value = null, onBlur },
      form: { touched, errors, setFieldValue, setFieldError },
      label,
      required,
      helperText,
      errorText,
      ...props
    } = this.props;

    const error =
      Boolean(Utils.Common.flattenObject(errors)[name]) &&
      Utils.Common.flattenObject(touched)[name];

    let date = value;
    if (typeof value === "string" || typeof value === "number") {
      date = moment.unix(value);
    }

    return (
      <InlineDatePicker
        label={label}
        id={name}
        margin="normal"
        onlyCalendar
        allowKeyboardControl
        required={required}
        value={date}
        onChange={(newDate) => setFieldValue(name, newDate.unix())}
        // TODO: Disable keyboard for the time, because clearing input with keyboard doesn't unset the date:
        // https://github.com/dmtrKovalenko/material-ui-pickers/issues/947
        // keyboard
        clearable
        error={error}
        helperText={error ? errorText : helperText}
        onError={(_, fieldError) =>
          required ? setFieldError(name, fieldError) : required
        }
        onBlur={onBlur}
        format={Constants.DATE_FORMAT}
        mask={Constants.DATE_MASK}
        {...props}
      />
    );
  }
}

InlineDatePickerComponent.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({}),
    ]),
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
  }),
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  errorText: PropTypes.string,
};

export default InlineDatePickerComponent;
