import { routes as homeRoutes } from "./pages/Home";
import { routes as assetRoutes } from "./pages/Asset";
import { routes as inboxRoutes } from "./pages/Inbox";
import { routes as translationRoutes } from "./pages/Translation";
import { routes as seoRoutes } from "./pages/Seo";
import { routes as authRoutes } from "./pages/Auth";
import { routes as appSettingsRoutes } from "./pages/AppSettings";
import { routes as channelSettingsRoutes } from "./pages/ChannelSettings";
import { routes as externalRoutes } from "./pages/External";

export const routes = [
  ...homeRoutes,
  ...inboxRoutes,
  ...assetRoutes,
  ...translationRoutes,
  ...seoRoutes,
  ...authRoutes,
  ...appSettingsRoutes,
  ...channelSettingsRoutes,
  ...externalRoutes,
];
