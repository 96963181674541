const namespace = "FRAGMENT";

export const FIND = `${namespace}/FIND`;
export const FIND_SUCCESS = `${namespace}/FIND_SUCCESS`;
export const FIND_FAILURE = `${namespace}/FIND_FAILURE`;
export const RESET = `${namespace}/RESET`;

export const FIND_TRANSLATE = `${namespace}/FIND_TRANSLATE`;
export const FIND_TRANSLATE_SUCCESS = `${namespace}/FIND_TRANSLATE_SUCCESS`;
export const FIND_TRANSLATE_FAILURE = `${namespace}/FIND_TRANSLATE_FAILURE`;

export const SET_FADE_IN = `${namespace}/SET_FADE_IN`;
export const SET_FADE_OUT = `${namespace}/SET_FADE_OUT`;
export const SET_SNAPSHOT = `${namespace}/SET_SNAPSHOT`;
export const SET_FILE = `${namespace}/SET_FILE`;
