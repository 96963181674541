import { all } from "redux-saga/effects";

import analyticsSagas from "features/Analytics/sagas";
import inboxSagas from "features/Inbox/sagas";
import userSagas from "features/Auth/sagas";
import startUpSagas from "features/Common/Startup.sagas.index";
import assetSagas from "features/Asset/sagas";
import fragmentSagas from "features/Fragment/sagas";
import fileSagas from "features/File/sagas";
import languageSagas from "features/Translation/sagas";
import logSagas from "features/Log/sagas";
import jobSagas from "features/ChannelSettings/Jobs/sagas";
import licenseSagas from "features/ChannelSettings/Licenses/sagas";
import channelSagas from "features/Channel/sagas";
import countrySagas from "features/Common/country.sagas";
import errorLogging from "features/ErrorLogging/sagas";
import externalSagas from "features/External/sagas";

export default function* root() {
  yield all([
    startUpSagas(),
    analyticsSagas(),
    userSagas(),
    inboxSagas(),
    assetSagas(),
    fragmentSagas(),
    fileSagas(),
    languageSagas(),
    logSagas(),
    jobSagas(),
    channelSagas(),
    countrySagas(),
    errorLogging(),
    externalSagas(),
    licenseSagas(),
  ]);
}
