import { createContext, useContext } from "react";

const AnalyticsContext = createContext();
export const AnalyticsProvider = ({
  children,
  init,
  identify,
  setIdentity,
  track,
}) => (
  <AnalyticsContext.Provider
    value={{
      init,
      identify,
      setIdentity,
      track,
    }}
  >
    {children}
  </AnalyticsContext.Provider>
);

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error("useAnalytics must be used within a AnalyticsProvider");
  }
  return context;
};
