import { memo } from "react";

const PageSuspense = ({
  Page,
  renderProps,
  match,
  routes,
  fallback,
  dataLoaded,
  ...props
}) => {
  if (!dataLoaded) {
    return fallback;
  }
  return <Page {...props} {...renderProps} match={match} routes={routes} />;
};

export default memo(PageSuspense);
