const styles = (theme) => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    overflow: "visible !important",
  },
  tableRow: {
    cursor: "pointer",
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(0, 0, 0, .02)",
    },
    "&:nth-of-type(even):hover": {
      backgroundColor: "grey[200]",
    },
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
  noResults: {
    padding: 10,
  },
});

export default styles;
