import Axios from "axios";
import { API_URL } from "features/Common/constants";
import { AllInApi } from "features/Common/services/api.service";

const api = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const getSeoContentTypes = () =>
  api.get(`${AllInApi.getApiChannelPath()}/seo/content-type`);

export const getSeoContentType = (id) =>
  api.get(`${AllInApi.getApiChannelPath()}/seo/content-type/${id}`);

export const getSeoEntry = (id) =>
  api.get(`${AllInApi.getApiChannelPath()}/seo/entry/${id}`);

export const createSeoEntry = (data) =>
  api.post(`${AllInApi.getApiChannelPath()}/seo/entry`, data);

export const updateSeoEntry = (id, data) =>
  api.put(`${AllInApi.getApiChannelPath()}/seo/entry/${id}`, data);

export const publishSeoEntry = (id) =>
  api.post(`${AllInApi.getApiChannelPath()}/seo/entry/${id}/publish`);

export const unpublishSeoEntry = (id) =>
  api.post(`${AllInApi.getApiChannelPath()}/seo/entry/${id}/unpublish`);

export const searchEntry = ({ query, filters }) => {
  return api.post(`${AllInApi.getApiChannelPath()}/seo/entry/search`, {
    query,
    filters,
  });
};

export const searchAsset = ({
  query = null,
  type = "video,series",
  seriesId = null,
  page = 1,
  perPage = 15,
  sortField = null,
  sortDirection = null,
  withoutTrailer = false,
}) => {
  return api.get(`${AllInApi.getApiSearchPath()}/asset`, {
    params: {
      channel_id: AllInApi.getChannelId(),
      series_id: seriesId || undefined,
      query,
      type,
      page,
      per_page: perPage,
      sort_field: sortField,
      sort_direction: sortDirection,
      without_trailer: withoutTrailer,
    },
  });
};
