import * as types from "./Channel.types";

export const setSelected = (channelId, channels = []) => ({
  type: types.SET_SELECTED,
  channelId,
  channels,
});

export const setConfig = (config) => ({
  type: types.SET_CONFIG,
  config,
});
