import { useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { List } from "react-virtualized";

// Icons
import LockIcon from "@material-ui/icons/Lock";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";

// Component
import CircularProgressCenter from "components/CircularProgressCenter";
import Checkbox from "components/Form/Checkbox";
import Alert from "@material-ui/lab/Alert";
import Button from "components/Button";

// hooks
import { usePersistentState } from "features/Common/hooks";

// Utils
import { convertSecondsToHuman } from "features/Fragment/utils";

// Local hooks
import useScrollToCurrentTime from "./useScrollToCurrentTime";

// Style
import StyledComponent from "./style";

const SubtitleError = ({ error }) => <Alert severity="error">{error}</Alert>;

const SubtitleViewer = ({
  loading,
  content,
  error,
  currentTimeMs,
  onTimeStampChange,
}) => {
  const { t } = useTranslation();
  const listRef = useRef();

  // State
  const [enabled, setScrollToCurrentTimeEnabled] = usePersistentState(
    true,
    "fragment.subtitle.viewer.scroll.enabled"
  );

  // Hooks
  useScrollToCurrentTime({
    enabled: enabled,
    currentTimeMs,
    content,
    ref: listRef,
  });

  // Listeners
  const onScrollEnabledChanged = () => {
    setScrollToCurrentTimeEnabled(!enabled);
  };

  const rowRenderer = ({ key, index, style }) => {
    const { start, end, text } = content[index];
    const active = currentTimeMs >= start && currentTimeMs <= end;

    return (
      <div
        key={key}
        style={style}
        className={active ? "subtitle active" : "subtitle"}
      >
        <div className="content">
          <div className="time-stamps">
            <Button
              variant="text"
              trackName="Subtitle start timestamp"
              trackDetails={{ start }}
              onClick={() => onTimeStampChange({ timeStamp: start })}
            >
              {convertSecondsToHuman(start)}
            </Button>
            -
            <Button
              trackName="Subtitle end timestamp"
              trackDetails={{ end }}
              variant="text"
              onClick={() => onTimeStampChange({ timeStamp: end })}
            >
              {convertSecondsToHuman(end)}
            </Button>
          </div>
          <span className="text">{text}</span>
        </div>
      </div>
    );
  };

  return (
    <StyledComponent>
      {loading && !error && <CircularProgressCenter />}
      {error && <SubtitleError error={error} />}
      {!error && (
        <>
          <div className="subtitles">
            <List
              ref={listRef}
              width={1}
              height={300}
              rowCount={content?.length || 0}
              rowHeight={80}
              rowRenderer={rowRenderer}
              containerStyle={{
                width: "100%",
                maxWidth: "100%",
              }}
              style={{
                width: "100%",
              }}
            />
          </div>
          <Checkbox
            name="check"
            trackName="Scroll To CurrentTime enabled"
            className="scroll-sync-checkbox"
            color={enabled ? "primary" : "default"}
            checked={enabled}
            onChange={onScrollEnabledChanged}
            label={t("labels.SYNC_SCROLL")}
            icon={<LockOpenOutlinedIcon />}
            iconChecked={<LockIcon />}
          />
        </>
      )}
    </StyledComponent>
  );
};

SubtitleViewer.defaultProps = {
  onTimeStampChange: () => {},
};

SubtitleViewer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.shape({}),
  currentTimeMs: PropTypes.number,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      end: PropTypes.number,
      settings: PropTypes.shape({}),
      start: PropTypes.number,
      text: PropTypes.string,
    })
  ),
  onTimeStampChange: PropTypes.func,
};

export default SubtitleViewer;
