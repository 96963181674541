import { compose } from "redux";

import withStyles from "@material-ui/core/styles/withStyles";
import { TextField } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { Component, Fragment } from "../../../../components";
import styles from "./Form.styles";

class RoleForm extends Component {
  render() {
    const { t, model, onChangeCallback } = this.props;

    return (
      <Fragment>
        <TextField
          margin="dense"
          variant="outlined"
          id="name"
          name="name"
          label={t("labels.NAME")}
          type="text"
          defaultValue={model.name}
          onChange={onChangeCallback}
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          id="slug"
          name="slug"
          label={t("labels.SLUG")}
          type="text"
          defaultValue={model.slug}
          onChange={onChangeCallback}
          fullWidth
        />
        <TextField
          margin="dense"
          variant="outlined"
          id="description"
          name="description"
          label={t("labels.DESCRIPTION")}
          type="text"
          defaultValue={model.description}
          onChange={onChangeCallback}
          fullWidth
        />
      </Fragment>
    );
  }
}

RoleForm.defaultProps = {};

RoleForm.propTypes = {};

export default compose(withTranslation(), withStyles(styles))(RoleForm);
