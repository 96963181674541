import { memo } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

// Icons
import EditIcon from "@material-ui/icons/Edit";

// Hooks
import { useAvailableLanguages } from "features/Translation/hooks";

// Component
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import NationFlag from "components/NationFlag";
import StatusLabel from "components/StatusLabel";

// Hooks
import { useQuery } from "features/Common/hooks";

// Utils
import { useCurrentFragmentUrl, useNormalizedSubtitleFiles } from "../hooks";

// Style
import StyledComponent from "./style";

const PANEL_NAME = "subtitles";

/**
 * Group by state pending, active, draft,
 */
const groupByState = (subtitles) =>
  subtitles?.reduce(
    (allStates, sub) => ({
      ...allStates,
      [sub.status]: allStates[sub.status]?.length
        ? [...allStates[sub.status], sub]
        : [sub],
    }),
    {}
  ) || {};

const FragmentSubtitles = ({ fragment, fragmentFiles }) => {
  const { t } = useTranslation();

  const languages = useAvailableLanguages() || [];

  // Current Page
  const query = useQuery();
  const panel = query.get("panel");
  const isActive = () => panel === PANEL_NAME;

  // Panel
  const url = useCurrentFragmentUrl({ fragmentId: fragment?.id });
  const panelUrl = `${url}?panel=${PANEL_NAME}`;

  // Subtitles
  const subtitleFiles = useNormalizedSubtitleFiles(fragmentFiles);
  const uploadedSubtitles = Object.keys(subtitleFiles || {}).length;
  const allSubtitles = languages?.length || 0;

  return (
    <StyledComponent>
      <NavLink to={panelUrl} activeClassName="activeEdit" isActive={isActive}>
        <Card>
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <EditIcon />
              </IconButton>
            }
            title={t("labels.SUBTITLES")}
            subheader={`${uploadedSubtitles}  / ${allSubtitles} ${t(
              "labels.LANGUAGES"
            )}`}
          />
          <CardContent>
            <List>
              {languages.map((language) => {
                const { code, id } = language;

                // Actual subtitle data
                const subtitles = subtitleFiles[code];
                const { status } = subtitles || {};

                // Grouped by state, active, pending, draft..
                const groupedStates = groupByState(subtitles);
                const states = Object.entries(groupedStates) || [];

                const className = classNames({
                  [status]: true,
                  active: subtitles,
                });

                return (
                  <ListItem
                    key={`item-subtitle-${id}`}
                    className={className}
                    disableGutters
                  >
                    <ListItemAvatar>
                      <NationFlag countryCode={code} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        subtitles
                          ? t("labels.FILE_REVISIONS", {
                              count: subtitles?.length,
                            })
                          : t("text.NO_SUBTITLES_FOUND", {
                              locale: language.name,
                            })
                      }
                      secondary={subtitles?.path}
                    />

                    {states?.map(([subtitleStatus, revisions]) => (
                      <StatusLabel
                        key={subtitleStatus}
                        count={revisions?.length}
                        label={t(`labels.${subtitleStatus.toUpperCase()}`)}
                        status={subtitleStatus}
                      />
                    ))}
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </NavLink>
    </StyledComponent>
  );
};

export default memo(FragmentSubtitles);
