import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// Components
import SplitButton, { SplitButtonPopover } from "components/SplitButton";
import Typography from "@material-ui/core/Typography";

// Style
import StyledComponent, { StyledMenuItem } from "./style";

const NO_STATE_COLOR = "#e6e6e6";

const StateButton = ({ state, states, onChange }) => {
  const { t } = useTranslation();

  // State
  const options = useMemo(() => {
    const o = states.map(({ id, color, name }) => ({
      key: id,
      title: t(
        `states.${name
          .trim()
          .toUpperCase()
          .replace(/[^\w\s]/gi, "")
          .replaceAll(" ", "_")}`
      ),
      value: id,
      additionalData: {
        color,
      },
    }));

    return o || [];
  }, [t, states]);

  const selectedIndex = useMemo(() => {
    const index = options?.findIndex((option) => option.key === state?.id) || 0;
    return index;
  }, [options, state]);

  // Listeners

  const onClickHandler = ({ option }) => {
    onChange(states.find((state) => state.id === option.key));
  };

  return (
    <StyledComponent
      color={options[selectedIndex]?.additionalData?.color || NO_STATE_COLOR}
    >
      <SplitButton
        options={options}
        placeholderOption={{
          additionalData: { color: "#ffd5cc" },
          key: -1,
          title: t("text.NO_FRAGMENT_STATE"),
          value: -1,
        }}
        selectedIndex={selectedIndex}
        size="small"
        disableElevation
        onMenuItemClick={onClickHandler}
      >
        {({
          anchorRef,
          open,
          selectedIndex,
          handleClose,
          handleMenuItemClick,
        }) => {
          return (
            <SplitButtonPopover
              open={open}
              anchorRef={anchorRef}
              handleClose={handleClose}
            >
              <StyledMenuItem
                key={-1}
                selected={selectedIndex === -1}
                disabled
                statecolor={NO_STATE_COLOR}
              >
                <div className="circle" />
                <Typography variant="body2">
                  {t("text.NO_FRAGMENT_STATE")}
                </Typography>
              </StyledMenuItem>

              {options.map((option, index) => (
                <StyledMenuItem
                  key={option.key}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                  statecolor={option.additionalData.color}
                >
                  <div className="circle" />
                  <Typography variant="body2">{option.title}</Typography>
                </StyledMenuItem>
              ))}
            </SplitButtonPopover>
          );
        }}
      </SplitButton>
    </StyledComponent>
  );
};

StateButton.defaultProps = {
  onChange: () => {},
};

StateButton.propTypes = {
  state: PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default memo(StateButton);
