import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import NationFlag from "../NationFlag";
import * as userActions from "../../features/Auth/User.actions";
import StyledComponent from "./SelectLanguage.style";

class SelectContentLanguage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorElCountry: null,
    };
  }

  handleClick = (event) => {
    const { disabled } = this.props;
    if (disabled) return false;

    return this.setState({ anchorElCountry: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorElCountry: null });
  };

  changeSelectedCountry = (country) => {
    const { setUserCountry } = this.props;

    setUserCountry(country);
    this.handleClose();
  };

  changeSelectedLanguage = (language) => {
    const { setUserLanguage } = this.props;

    setUserLanguage(language);
    this.handleClose();
  };

  // eslint-disable-next-line class-methods-use-this
  renderFlagWithText(code, text = true) {
    if (!code) return null;

    return (
      <StyledComponent>
        <NationFlag countryCode={code} className={text ? "marginRight" : ""} />
        {text && code.toUpperCase()}
      </StyledComponent>
    );
  }

  render() {
    const { anchorElCountry } = this.state;
    const { user, disabled } = this.props;

    const selectedCountry =
      user && user.default_country ? user.default_country.code : null;
    const selectedLanguage =
      user && user.default_language ? user.default_language.code : null;

    return (
      <StyledComponent>
        <Button
          aria-owns={anchorElCountry ? "menu-country" : undefined}
          aria-haspopup="true"
          color="inherit"
          onClick={this.handleClick}
          disabled={disabled}
        >
          {this.renderFlagWithText(selectedCountry, false)}
        </Button>
        <Menu
          id="menu-country"
          anchorEl={anchorElCountry}
          open={Boolean(anchorElCountry)}
          onClose={this.handleClose}
        >
          <div style={{ display: "flex" }}>
            <div>
              <ListItemText style={{ paddingLeft: 16 }}>Land</ListItemText>
              <MenuItem onClick={() => this.handleClose()} selected>
                {this.renderFlagWithText(selectedCountry)}
              </MenuItem>
              {user.countries
                .filter((item) => item.code !== selectedCountry)
                .map((country) => (
                  <MenuItem
                    key={country.code}
                    onClick={() => this.changeSelectedCountry(country)}
                  >
                    {this.renderFlagWithText(country.code)}
                  </MenuItem>
                ))}
            </div>
            {selectedLanguage && (
              <div>
                <ListItemText style={{ paddingLeft: 16 }}>Taal</ListItemText>
                <MenuItem onClick={() => this.handleClose()} selected>
                  {this.renderFlagWithText(selectedLanguage)}
                </MenuItem>
                {user.default_country.languages
                  .filter((item) => item.code !== selectedLanguage)
                  .map((language) => (
                    <MenuItem
                      key={language.code}
                      onClick={() => this.changeSelectedLanguage(language)}
                    >
                      {this.renderFlagWithText(language.code)}
                    </MenuItem>
                  ))}
              </div>
            )}
          </div>
        </Menu>
      </StyledComponent>
    );
  }
}

SelectContentLanguage.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setUserLanguage: PropTypes.func,
  disabled: PropTypes.bool,
};

SelectContentLanguage.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUserLanguage: (language) =>
    dispatch(userActions.setUserLanguage(language)),
  setUserCountry: (country) => dispatch(userActions.setUserCountry(country)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectContentLanguage
);
