import styled from "styled-components";
import Menu from "@material-ui/core/Menu";

export const StyledMenu = styled(Menu)`
  .menuHeader {
    display: block;
    font-size: 13px;
    margin: ${({ theme }) => `0px ${theme.spacing(2)}px`};
    font-weight: 500;
    color: ${({ theme }) => theme.palette.grey[800]};
    padding: ${({ theme }) => theme.spacing()}px;
  }
`;

export default styled.div`
  .MuiListItem-root:hover {
    & .menuIcon {
      opacity: 1;
    }
  }
  .menuIcon {
    opacity: 0;
    transition: opacity ease-out 0.2s;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.grey[600]};
  }

  .MuiFormControlLabel-root {
    padding: ${({ theme }) => `0 0 0 ${theme.spacing(1)}px`};
    user-select: none;
  }
  .MuiCheckbox-root {
    color: ${({ theme }) => theme.palette.error.main};
    padding: ${({ theme }) =>
      `0 ${theme.spacing(0.5)}px 0 ${theme.spacing(0.5)}px`};
  }

  .Mui-checked {
    color: ${({ theme }) => theme.palette.success.main};
  }

  .MuiCheckbox-indeterminate {
    color: ${({ theme }) => theme.palette.grey[400]};
  }
`;
