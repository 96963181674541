import styled from "styled-components";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledMenuItem = styled(MenuItem)`
  .fi {
    margin-right: 16px;
  }
`;

export const StyledSelect = styled(Select)`
  color: white;
  margin-bottom: 4px;
  padding-left: 4px;
  padding-right: 32px;
  display: flex;
  flex: 0;

  .MuiSvgIcon-root {
    color: white;
    margin-left: 16px;
  }

  .fi {
    margin-right: 16px;
  }

  .MuiListItem-root {
    background: red !important;
  }
`;

const StyledComponent = styled.div`
  position: relative;
  background: #2f2f2f;
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  margin-bottom: -${({ theme }) => theme.spacing(1.5)}px;
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px`};
  color: white;

  .MuiInput-underline:before {
    border-bottom-color: white;
  }

  .MuiListItem-root {
    background: red;
  }

  .subtitles {
    max-height: 300px;

    .subtitle {
      padding-bottom: 8px;
      border-radius: 4px;

      &.active .content .text {
        color: ${({ theme }) => theme.palette.primary.main};
      }

      .MuiSelect-root,
      .MuiSelect-icon,
      .MuiInputBase-root {
        color: white;
      }

      .content {
        background: #292929;
        padding-bottom: 16px;
      }
    }

    .time-stamps {
      display: flex;
      align-items: center;
    }

    .MuiButton-label {
      color: white;
      font-weight: normal;

      &:hover {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: underline;
      }
    }

    .MuiSelect-root {
      color: white;
    }

    .text {
      font-weight: 700;
      padding: 0 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  & .MuiIconButton-label .MuiSvgIcon-root {
    color: white;
  }

  & .MuiIconButton-colorPrimary .MuiIconButton-label .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & .MuiFormControlLabel-root {
    margin-top: 16px;

    & .MuiFormControlLabel-label {
      font-size: 0.875rem;
    }
  }

  .action-buttons {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    margin: 24px 0 12px 0;

    .action-button {
      margin: 0 4px;

      & .Mui-disabled {
        color: white;
      }
    }
  }
`;

export default StyledComponent;
