import { useMemo, memo } from "react";
import PropTypes from "prop-types";

// Components
import TableCell from "@material-ui/core/TableCell";

// Fragment TableCells
import NameCell from "./NameCell";
import SubtitlesCell from "./SubtitlesCell";
import ImageCell from "./ImageCell";
import DRMCell from "./DRMCell";

// Styles
import StyledTableRow from "./style";

const FragmentRow = ({
  fragment,
  highlight = {},
  onRowClick,
  assetId,
  type,
  className,
  locale,
  language,
  onSubtitleDetailsClick,
}) => {
  const {
    id: fragmentId,
    identifier: fragmentIdentifier,
    name: fragmentName,
    drm,
    trailer,
    state,
    files,
  } = fragment;

  const subtitles = useMemo(
    () => files?.filter((file) => file.type.type === "subtitle"),
    [files]
  );

  const images = useMemo(
    () =>
      files?.filter(
        (file) => file.type.type === "image" && file.status === "active"
      ),
    [files]
  );

  const onHandleRowClick = (event) => {
    onRowClick({
      event,
      assetId,
      fragmentId,
      type,
    });
  };

  return (
    <StyledTableRow
      className={`row--fragment ${className}`}
      key={fragmentId}
      onClick={onHandleRowClick}
    >
      <NameCell
        name={fragmentName}
        locale={locale}
        language={language}
        highlight={highlight}
        identifier={fragmentIdentifier}
        trailer={trailer}
      />
      <SubtitlesCell
        onSubtitleDetailsClick={onSubtitleDetailsClick}
        subtitles={subtitles}
        language={language}
      />
      <ImageCell images={images} />
      <DRMCell drm={drm} />

      <TableCell className="cell--fragment-status" component="td" scope="row">
        <div>
          {!state && <span>-</span>}
          {state && (
            <div
              className="status-chip"
              style={{
                background: state.color,
              }}
            >
              {state.name}
            </div>
          )}
        </div>
      </TableCell>
    </StyledTableRow>
  );
};

FragmentRow.defaultProps = {
  onRowClick: () => null,
  highlight: {},
};

FragmentRow.propTypes = {
  assetId: PropTypes.number,
  className: PropTypes.string,
  fragment: PropTypes.shape({
    id: PropTypes.number,
    identifier: PropTypes.string,
    trailer: PropTypes.bool,
    name: PropTypes.shape({}),
    files: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  language: PropTypes.string,
  locale: PropTypes.string,
  onRowClick: PropTypes.func,
  type: PropTypes.oneOf(["video", "series"]),
  highlight: PropTypes.shape({}),
};

export default memo(FragmentRow);
