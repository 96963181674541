import styled from "styled-components";

const StyledComponent = styled.div`
  ${({ theme }) => `
      .header {
        padding: ${theme.spacing(1.5)}px ${theme.spacing(2)}px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: ${theme.spacing(3)}px;

        & h6 {
          color: rgba(0,0,0,.9);
          font-size: 14px;
          font-weight: 700;
        }

        & > button {
          padding: ${theme.spacing(1.5)}px;
          margin: -${theme.spacing(1.5)}px -${theme.spacing(
    1.5
  )}px -${theme.spacing(1.5)}px 0px
        }
      }

      .listItem {
        padding: ${theme.spacing() / 2}px ${theme.spacing(2)}px;
      }

      .listItemText {
        overflow: hidden;

        .fi {
          margin: 0 ${theme.spacing(2.5)}px 0 0;
        }

        & span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 13px;

          & strong {
            text-transform: uppercase;
          }
        }
      }

      .dialogActions {
        padding: ${theme.spacing()};
        background-color: rgba(0,0,0,.02);
        border-top: 1px solid ${theme.palette.divider};
        justify-content: center
      }
    `}
`;

export default StyledComponent;
