import styled from "styled-components";

export const StyledTitle = styled.h1`
  flex: 1;
  font-size: 0.825rem;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.grey[800]};
`;

export const StyledContent = styled.aside``;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  min-height: 45px;
`;

export default StyledHeader;
