/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  init as setupAnalytics,
  AnalyticsProvider,
  track,
  identify,
  setIdentity,
} from "features/Analytics";

// eslint-disable-next-line no-unused-vars
import i18n from "i18n/i18n";
import sentrySetup from "features/ErrorLogging/sentry.config";
import AuthProvider from "features/Auth/AuthProvider";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

// Components
import RootContainer from "features/Common/Root";

// Redux
import store from "./redux";
import { AllInApi } from "features/Common/services/api.service";

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_APP_KEY,
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTLS: true,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        AllInApi.getApi()
          .post(`broadcasting/auth`, {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => {
            callback(null, response.data);
          })
          .catch((error) => {
            callback(error);
          });
      },
    };
  },
});

// Development Utils
if (process.env.NODE_ENV === "development") {
  Pusher.logToConsole = true;
  // eslint-disable-next-line global-require
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: false,
  });
}

setupAnalytics();
sentrySetup();

class App extends Component {
  render() {
    const { t } = this.props;

    return (
      <div
        className="App"
        style={{
          margin: 0,
          width: "100%",
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <Helmet>
          <title>
            {t("titles.APP", { name: process.env.REACT_APP_TITLE })}
          </title>
        </Helmet>

        <AnalyticsProvider
          track={track}
          init={setupAnalytics}
          identify={identify}
          setIdentity={setIdentity}
        >
          <Provider store={store}>
            <AuthProvider>
              <RootContainer />
            </AuthProvider>
          </Provider>
        </AnalyticsProvider>
      </div>
    );
  }
}

App.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(App);
