import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import useEventListener from "@use-it/event-listener";

// Services
import { login, logout, twoFactorChallenge } from "features/Auth/user.service";

// Hooks
import usePersistentState from "features/Common/hooks/usePersistentState";

// Redux
import { getUser } from "features/Auth/User.actions";

// Constants
import { AUTHENTICATED } from "./Auth.utils";

// Authentication Context
const AuthContext = createContext();
export const useAuthentication = () => useContext(AuthContext);

const AuthProvider = ({ children, isAuthenticatedDefault }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [needTwoFactor, setNeedTwoFactor] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = usePersistentState(
    isAuthenticatedDefault,
    AUTHENTICATED
  );

  // Event Listeners

  useEventListener(
    "AuthenticationUpdated",
    (event) => {
      const { value } = event;
      setIsAuthenticated(value);
    },
    document
  );

  const reloadUser = () => {
    dispatch(getUser());
  };

  const onLogin = (email, password, remember = false, twoFactorCode = null) => {
    // Clean state
    setIsAuthenticating(true);
    setError(null);

    if (twoFactorCode) {
      return twoFactorChallenge({
        code: twoFactorCode,
        recovery_code: null,
      })
        .then(() => {
          setIsAuthenticated(true);
          setNeedTwoFactor(false);
          dispatch(getUser());
        })
        .catch(() => {
          onLogin(email, password, remember);
          setIsAuthenticated(false);
        })
        .finally(() => {
          setIsAuthenticating(false);
        });
    }

    login(email, password, remember)
      .then((response) => {
        if (response.data.two_factor) {
          setNeedTwoFactor(true);
        } else {
          // setDisplayEnableTwoFactorDialog("enable");
          setIsAuthenticated(true);
          dispatch(getUser());
        }
      })
      .catch(() => {
        setIsAuthenticated(false);
        setError(t("labels.LOGIN_INCORRECT"));
      })
      .finally(() => {
        setIsAuthenticating(false);
      });
  };

  const onLogout = () => {
    setIsAuthenticated(false);
    logout()
      .catch(() => {})
      .finally(() => {
        setIsAuthenticating(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAuthenticating,
        authenticationError: error,
        login: onLogin,
        logout: onLogout,
        needTwoFactor,
        reloadUser,
      }}
    >
      {children}
      {/* <TwoFactorDialog
        open={displayEnableTwoFactorDialog}
        onClose={() => setDisplayEnableTwoFactorDialog(false)}
      /> */}
    </AuthContext.Provider>
  );
};

AuthProvider.defaultProps = {
  isAuthenticatedDefault: false,
};

AuthProvider.propTypes = {
  children: PropTypes.node,
  isAuthenticatedDefault: PropTypes.bool,
};

export default AuthProvider;
