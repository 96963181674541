import { call, put } from "redux-saga/effects";
import _ from "lodash";

import * as inboxActions from "./Inbox.actions";

export const getChannel = (state) => state.channel;

export function* loadInbox(inboxApi, { query }) {
  try {
    const inboxResponse = yield call(inboxApi.getInbox, query);

    if (inboxResponse.status !== 200) {
      return yield put(inboxActions.findFailure());
    }

    const InboxOrded = _.orderBy(
      inboxResponse.data,
      (item) => {
        let order = 0;

        if (_.isObject(item.uploaded) && item.uploaded.date) {
          order = item.uploaded.date;
        }

        if (Array.isArray(item.jobs) && item.jobs.length) {
          _.forEach(item.jobs, (job) => {
            if (job.status !== "success") {
              order = 9999999999 + parseInt(job.id, 10);
            }
          });
        }

        return order;
      },
      "desc"
    );

    return yield put(inboxActions.findSuccess(InboxOrded));
  } catch (error) {
    yield put(inboxActions.findFailure());
  }

  return false;
}

export function* reset() {
  return yield put(inboxActions.reset());
}
