import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background: #2d2d2d;
  max-width: 100%;

  .fragmentDrawerContainer {
    display: flex;
    padding: ${({ theme }) => theme.spacing(4)}px;
    padding-bottom: 24px;
    flex: 1;
    overflow-y: scroll;
    background: #eeeeee;

    &.disablePadding {
      padding: 0;
    }
  }

  .MuiAppBar-root {
    z-index: 1000;
  }

  .MuiInputBase-input {
    background-color: white;
  }
`;

export const AppBarStyled = styled(AppBar)`
  height: 64px;
  background: ${({ theme }) => theme.palette.grey[800]};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;
  text-transform: uppercase;
  width: 100% !important;

  &.withTabBar {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  a {
    color: white;
  }
`;

export default StyledComponent;
