import { Grid } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";

/* Components */
import Layout from "components/Layout";
import RoutesSwitch from "components/RoutesSwitch";

/* Features */
import AssetDetail from "features/Asset/AssetDetail";
import FragmentDrawer from "features/Fragment/FragmentDrawer";
import JobProvider from "features/Fragment/FragmentJobs/JobProvider";

const Page = (props) => {
  const { routes, subRoutes } = props;

  const { path } = useRouteMatch();
  return (
    <JobProvider>
      <Layout drawer={<FragmentDrawer />} routes={routes}>
        <Grid container spacing={0} wrap="nowrap">
          <Grid>
            <AssetDetail />
          </Grid>
          <Grid item md style={{ overflow: "auto" }}>
            <RoutesSwitch routes={subRoutes} basePath={path} />
          </Grid>
        </Grid>
      </Layout>
    </JobProvider>
  );
};

export default Page;
