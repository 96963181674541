import { useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { useSnackbar } from "notistack";
import moment from "moment";

// Utils
import Utils from "features/Common/utils";
import { useAnalytics } from "features/Analytics";

// Icons
import MovieIcon from "@material-ui/icons/Movie";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SecurityIcon from "@material-ui/icons/Security";
import GetAppIcon from "@material-ui/icons/GetApp";

// Components
import Reward from "react-rewards";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DownloadLink from "components/DownloadLink";
import VideoPlayer from "components/VideoPlayer";
import NationFlag from "components/NationFlag";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import CircularProgress from "@material-ui/core/CircularProgress";
import CircularProgressCenter from "components/CircularProgressCenter";

// Local Components
import UploadForm from "./UploadForm/UploadForm";
import Subtitle from "../Subtitle";
import TokenExpired from "../TokenExpired";

// Local Hooks
import { useBreadcrumbs } from "../hooks";

// Redux
import { loadExternalSubtitle } from "../reducer";

// style
import StyledGrid, { StyledNoSubtitles } from "./style";

// Constants
const DEFAULT_DATE_TIME_FORMAT = "DD-MM-YYYY";

const NoSubtitleFiles = () => {
  const { t } = useTranslation();
  return (
    <StyledNoSubtitles>
      <ErrorOutlineIcon />
      {t("text.NO_SUBTITLES_UPLOADED")}
    </StyledNoSubtitles>
  );
};

const getVideoSource = (fileHash) => ({
  src: Utils.Fragments.getAssetVideoPath(fileHash),
  type: "video/mp4",
});

const ExternalSubtitle = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { setIdentity, track, identify } = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();

  // Refs
  const videoRef = useRef();
  const rewardRef = useRef();

  // Redux
  const dispatch = useDispatch();

  const { success: uploadSuccess, uploading, error: uploadError } = useSelector(
    (state) => state.external.upload
  );
  const { asset, fragment, tokenInfo, video, loading, error } = useSelector(
    (state) => state.external
  );
  const { email, expires_at: expiresAt, due_date: dueDate, language, logs } =
    tokenInfo || {};

  const { code, native_name: nativeName } = language || {};
  const { name: assetName, type: assetType, identifier: assetIdentifier } =
    asset || {};
  const { name: fragmentName, subtitles } = fragment || {};
  const videoName = `${assetIdentifier}.mp4`;

  const expiresAtFromNow = moment.unix(expiresAt).fromNow();
  const dueDateFromNow = moment.unix(dueDate).format(DEFAULT_DATE_TIME_FORMAT);
  const videoHash = video?.hash;

  const createdByUser = useMemo(
    () => logs?.find((log) => log.action === "created"),
    [logs]
  );

  const activeSubtitleFiles = useMemo(
    () => subtitles?.filter(({ locale }) => locale === code),
    [subtitles, code]
  );

  // Hooks
  const breadcrumbs = useBreadcrumbs({ asset });

  // Analytics

  useEffect(() => {
    track("view.external.subtitle", { fragmentName, code, nativeName });
  }, [track, code, fragmentName, nativeName]);

  useEffect(() => {
    if (!email) return;
    identify(email);
    setIdentity({ email, name: email, type: "external" });
  }, [email, setIdentity, identify]);

  const videoUrl = useMemo(() => Utils.Fragments.getAssetVideoPath(videoHash), [
    videoHash,
  ]);

  // Load external subtitle resources
  useEffect(() => {
    if (!token) return;
    dispatch(loadExternalSubtitle(token));
  }, [token, dispatch]);

  // Show confetti on upload success
  useEffect(() => {
    if (!uploadSuccess) return;
    rewardRef.current.rewardMe();
    enqueueSnackbar(t("labels.EXTERNAL_FILE_UPLOAD_SUCCESS"), {
      variant: "success",
    });
  }, [uploadSuccess, rewardRef, enqueueSnackbar, t]);

  // Punish on upload failed
  useEffect(() => {
    if (!uploadError) return;
    rewardRef.current.punishMe();
  }, [uploadError, rewardRef]);

  // Set video player source
  useEffect(() => {
    if (!videoHash) return;
    videoRef.current.setSource(getVideoSource(videoHash));
  }, [videoHash]);

  // Set subtitle track
  useEffect(() => {
    if (!activeSubtitleFiles) return;

    const tracks = activeSubtitleFiles.map((subtitleFile) => {
      const src = Utils.Fragments.getAssetStoragePath(
        subtitleFile.paths.find((path) => path.identifier === "vtt")?.path
      );

      return {
        locale: code,
        src,
      };
    });

    videoRef.current.setCaptionsConfig({
      active: true,
      language: code || "auto",
      update: false,
    });

    videoRef.current.setTracks(tracks);
  }, [activeSubtitleFiles, code]);

  if (error) return <TokenExpired />;
  if (loading) return <CircularProgressCenter />;
  return (
    <StyledGrid container maxwidth="sm" justify="center">
      <Grid item xs={12} md={6} className="column-left">
        <header className="welcome">
          <Typography variant="h5">
            {t("labels.WELCOME")} <strong>{email}</strong>
          </Typography>
          <Typography variant="body1">
            <Trans
              t={t}
              i18nKey="text.INVITATION_WELCOME_TEXT"
              values={{
                useName: createdByUser?.user?.name,
                nativeName,
              }}
            >
              <strong>{nativeName}</strong>
              <NationFlag countryCode={code || ""} />
            </Trans>
          </Typography>
          <section className="asset-info asset">
            <Chip size="small" label={assetType} />
            <div>
              <Typography variant="h6">{assetName}</Typography>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs.map(({ name, identifier }) => (
                  <Typography key={identifier} color="textPrimary">
                    {name}
                  </Typography>
                ))}
                <Typography color="textPrimary" className="active">
                  {fragmentName}
                </Typography>
              </Breadcrumbs>
            </div>
          </section>

          <section className="info">
            <Typography variant="body1">
              {t("labels.EXTERNAL_DEADLINE")}
              <strong>{dueDateFromNow}</strong>
            </Typography>
          </section>
        </header>

        <footer>
          <section className="asset-info">
            <Typography variant="body1">
              {t("labels.EXTERNAL_DOWNLOAD_VIDEO")}
            </Typography>
            <DownloadLink
              className="download-button"
              name={videoName}
              href={videoUrl}
              trackName="external.subtitle.download.video"
              trackDetails={{
                name: videoName,
                fragmentName,
                videoUrl,
                type: "external.subtitle.download.video",
              }}
            >
              {({ downloading, progress }) => (
                <>
                  <div className="link-info">
                    <MovieIcon size="small" />
                    <Typography variant="h6">{videoName}</Typography>
                  </div>
                  {!downloading && <GetAppIcon size="small" />}
                  {downloading && (
                    <div className="downloading">
                      <Typography variant="caption">downloading...</Typography>
                      <CircularProgress
                        size={24}
                        variant="static"
                        color="secondary"
                        value={progress * 100}
                      />
                    </div>
                  )}
                </>
              )}
            </DownloadLink>
          </section>

          <section className="subtitles">
            <Typography variant="body1">
              {t("labels.EXTERNAL_DOWNLOAD_SUBTITLES")}
            </Typography>
            {subtitles?.map(
              ({
                id,
                name: subtitleName,
                locale: subtitleLocale,
                created_at: createdAt,
                paths,
                status,
              }) => (
                <Subtitle
                  key={id}
                  name={subtitleName}
                  locale={subtitleLocale}
                  createdAt={createdAt}
                  path={paths?.find((path) => path.identifier === "srt")}
                  fragmentName={fragmentName}
                  status={status}
                />
              )
            )}
            {!subtitles?.length && <NoSubtitleFiles />}
          </section>

          <div className="expires-message">
            <SecurityIcon />
            <Typography variant="body1">
              {t("labels.EXTERNAL_DOWNLOAD_EXPIRES")} {expiresAtFromNow}
            </Typography>
          </div>
        </footer>
      </Grid>
      <Grid className="column-right" item xs={12} md={6} lg={5}>
        <section>
          <VideoPlayer ref={videoRef} />
          <Reward
            type="confetti"
            ref={rewardRef}
            config={{
              spread: 80,
            }}
          >
            <UploadForm
              token={token}
              language={code}
              uploading={uploading}
              assetName={assetName}
              fragmentName={fragmentName}
            />
          </Reward>
        </section>
      </Grid>
    </StyledGrid>
  );
};

export default ExternalSubtitle;
