/* eslint-disable camelcase */
import { useEffect, memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, FormContext, useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";

// Hooks
import { useCurrentFragmentUrl } from "features/Fragment/hooks";

// Redux
import { patchFragmentMetaValues } from "features/Fragment/reducer";

// Utils
import Utils from "features/Common/utils";

// Components
import { Grid } from "@material-ui/core";
import { Button } from "components";
import { TextFieldWithControl } from "components/Form/TextField";
import { DatePickerWithControl } from "components/Form/DatePicker";
import { MdEditorWithControl } from "components/Form/MdEditor";
import CircularProgressCenter from "components/CircularProgressCenter";
import SelectWithAutoComplete from "./SelectWithAutoComplete";
import CopyMeta from "./CopyMeta";

// Local hooks

import { useDataToSchemaConverter } from "./hooks.validation-schema";
import {
  usePossibleOptions,
  useNormalizedMetaInformation,
  useNormalizedValues,
  mergeDirtyValues,
} from "./hooks";

// Style
import StyledForm from "./style";

const NotImplemented = ({ name, type, options, values, defaultValue }) => (
  <div>
    <h2>{name}</h2>
    <span>{type}</span>
    <p>
      options:
      {JSON.stringify(options)}
    </p>
    <p>
      values:
      {JSON.stringify(values)}
    </p>
    <p>
      defaultValue:
      {JSON.stringify(defaultValue)}
    </p>
  </div>
);

const Select = ({
  id,
  name,
  options,
  required,
  values,
  appendable,
  multiple,
  errors,
}) => (
  <SelectWithAutoComplete
    id={id}
    name={name}
    label={required ? `${name}*` : name}
    values={values}
    appendable={appendable}
    multiple={multiple}
    options={options}
    errors={errors}
  />
);

const TextFieldInput = ({ id, name, required, errors }) => {
  const { control } = useFormContext();
  return (
    <TextFieldWithControl
      name={`${id}`}
      label={required ? `${name}*` : name}
      variant="outlined"
      className="inputField"
      inputFormatter={(value) => value?.value}
      outputFormatter={(value) => ({ id, value })}
      error={errors}
      control={control}
    />
  );
};

const DatePicker = ({ id, name, required, errors }) => {
  const { control } = useFormContext();
  return (
    <DatePickerWithControl
      control={control}
      name={`${id}`}
      timestamp
      label={required ? `${name}*` : name}
      inputFormatter={(value) => value?.value}
      outputFormatter={(value) => {
        if (!value) return null;
        return { id, value };
      }}
      variant="outlined"
      error={errors}
    />
  );
};

const TextArea = ({ id, name, values, required, errors }) => {
  const { control } = useFormContext();
  return (
    <MdEditorWithControl
      name={`${id}`}
      label={required ? `${name}*` : name}
      control={control}
      value={values}
      outputFormatter={(value) => ({ id, value })}
      inputFormatter={(value) => value?.value}
      style={{ height: "300px" }}
      error={errors}
    />
  );
};

const fieldMap = {
  select: Select,
  date: DatePicker,
  input: TextFieldInput,
  textarea: TextArea,
  notImplemented: NotImplemented,
};

const Form = ({
  fragmentId,
  fragmentName,
  meta,
  networkState,
  possibleOptions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);

  // Routing
  const closeUrl = useCurrentFragmentUrl({ fragmentId });
  const history = useHistory();

  // Normalized by id meta info
  const normalizedMetaInfo = useNormalizedMetaInformation(meta);
  // Normalized by id raw values without meta data like multiple, required,
  const normalizedValues = useNormalizedValues(meta);

  // Convert meta data fields to yup validation schema
  const validationSchema = useDataToSchemaConverter({ fields: meta });

  // Form handlers
  const defaultValues = normalizedValues;

  const formMethods = useForm({ validationSchema, defaultValues });

  const { handleSubmit, getValues, formState, errors, reset } = formMethods;
  const formValues = getValues() || {};

  // Listen for value changes and update the form
  // with new value data
  useEffect(() => {
    reset(normalizedValues);
  }, [normalizedValues, reset]);

  const setMeta = (metaValues) => {
    const keySelector = (metaValue) => metaValue.id;
    const valueSelector = ({ multiple, values }) =>
      multiple ? values : values[0];
    const data = Utils.Common.normalize(
      metaValues || [],
      keySelector,
      valueSelector
    );

    setIsCopied(true);
    reset(data);
  };

  const onSubmit = (formData) => {
    if (!isCopied) {
      const dirtyValues = Utils.Form.getDirtyValues({
        formData,
        dirtyFields: formState.dirtyFields,
      });

      // Merge meta data field with new values
      const body = mergeDirtyValues({
        dirtyValues,
        meta: normalizedMetaInfo,
      });

      return dispatch(
        patchFragmentMetaValues({
          id: fragmentId,
          fields: Utils.Form.objectToArray(body),
        })
      );
    }

    const body = mergeDirtyValues({
      dirtyValues: formData,
      meta: normalizedMetaInfo,
    });

    return dispatch(
      patchFragmentMetaValues({
        id: fragmentId,
        fields: Utils.Form.objectToArray(body),
      })
    );
  };

  // Invoke formState dirty values so that the proxy is
  // trigger/activated. Otherwise formState.isDirty doesn't work
  useEffect(() => {
    if (!formState) return;
    formState.dirtyFields.values();
  }, [formState]);

  return (
    <FormContext {...formMethods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <CopyMeta setMeta={setMeta} justify="flex-end" />
          </Grid>

          {meta.map((field) => {
            const {
              id,
              identifier,
              name,
              type,
              default_value,
              multiple,
              appendable,
              required,
              search,
            } = field;

            const Element = fieldMap[type] || fieldMap.notImplemented;
            const metaValue = formValues[id]?.value;
            const options = possibleOptions[id] || [];

            const fieldErrors =
              errors[id]?.value?.message || errors[id]?.message;

            return (
              <Grid item key={id}>
                <Element
                  key={id}
                  id={id}
                  t={t}
                  identifier={identifier}
                  name={name}
                  type={type}
                  options={options}
                  values={metaValue}
                  multiple={multiple}
                  appendable={appendable}
                  required={required}
                  search={search}
                  defaultValue={default_value}
                  errors={fieldErrors}
                  fullWidth
                />
              </Grid>
            );
          })}

          {Object.values(errors)?.length > 0 && (
            <Grid item>
              <span className="form-error">{t("error.FORM_ERROR")}</span>
            </Grid>
          )}

          <Grid item>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={6}>
                <Button
                  color="default"
                  trackName="Fragment Meta Edit Cancel"
                  trackDetails={{ fragmentId, fragmentName }}
                  onClick={() => history.push(closeUrl)}
                >
                  {t("buttons.CANCEL")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  trackName="Fragment Meta Edit Save"
                  trackDetails={{ fragmentId, fragmentName }}
                  loading={networkState === "updating"}
                  color="primary"
                  type="submit"
                  disabled={!formState.dirty && !isCopied}
                >
                  {t("buttons.SAVE")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledForm>
    </FormContext>
  );
};

const FragmentMetaEdit = ({ fragment }) => {
  const { t } = useTranslation();
  const { meta, id, name } = fragment;

  // Selectors
  const networkState = useSelector(
    (state) => state.fragment?.networkState?.meta
  );
  const possibleOptions = usePossibleOptions({ fragment });

  if (!meta) return <CircularProgressCenter type="inline" />;
  if (!meta?.length) return t("errors.FORM_EMPTY");

  return (
    <Form
      fragmentId={id}
      fragmentName={name}
      meta={meta}
      possibleOptions={possibleOptions}
      networkState={networkState}
    />
  );
};

FragmentMetaEdit.propTypes = {};

export default memo(FragmentMetaEdit);
