import { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

// Icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

// Components
import NationFlag from "components/NationFlag";

// Style
import StyledComponent from "./style";

const checkIds = [1, 2, 3, 4, 5];

const QualityChecksPopover = ({ checks, locale }) => {
  const { t } = useTranslation();

  if (!checks) return null;
  const checksPerCountry = Object.entries(checks);
  return (
    <StyledComponent>
      {checksPerCountry &&
        checksPerCountry.map(([countryCode, value]) => (
          <div
            className={classNames("country-info", {
              active: countryCode === locale,
            })}
            key={countryCode}
          >
            <header>
              <NationFlag countryCode={countryCode} />
            </header>
            <table className="table--dates">
              <thead>
                <tr>
                  <th>{t("labels.VIDEO")}</th>
                  <th>{t("labels.VIDEO_SUBTITLES_CHECK")}</th>
                  <th>{t("labels.VIDEO_META_CHECK")}</th>
                  <th>{t("labels.TRAILER")}</th>
                  <th>{t("labels.TRAILER_SUBTITLE_CHECK")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {checkIds?.map((checkId) => {
                    const status = value?.find((val) => val.id === checkId);

                    return (
                      <td key={checkIds} style={{ textAlign: "center" }}>
                        {status?.type === "checked" && (
                          <CheckCircleIcon
                            style={{ color: "green" }}
                            fontSize="small"
                          />
                        )}
                        {status?.type === "inapplicable" && (
                          <RemoveCircleIcon
                            style={{ color: "gray" }}
                            fontSize="small"
                          />
                        )}
                        {!status && (
                          <CancelIcon
                            style={{ color: "red" }}
                            fontSize="small"
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        ))}
    </StyledComponent>
  );
};

QualityChecksPopover.propTypes = {
  name: PropTypes.shape({}),
};

export default memo(QualityChecksPopover);
