import { useEffect } from "react";

const useScrollToCurrentTime = ({ enabled, ref, content, currentTimeMs }) => {
  // Scroll list to current timestamp
  useEffect(() => {
    if (!enabled) return;
    if (!content || !ref?.current) return;

    const index = content.findIndex(
      ({ start, end }) => start >= currentTimeMs || currentTimeMs <= end
    );

    ref.current.scrollToRow(index % content?.length);
  }, [currentTimeMs, ref, enabled, content]);
};

export default useScrollToCurrentTime;
