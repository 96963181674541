export const API_URL = process.env.REACT_APP_ALLIN_API_BASE_URL;
export const API_PATH = process.env.REACT_APP_ALLIN_API_PATH;

export const API_AUTH_CSRF_ENDPOINT = "/sanctum/csrf-cookie";
export const API_AUTH_LOGIN_ENDPOINT = "/login";
export const API_AUTH_LOGOUT_ENDPOINT = "/logout";
export const API_AUTH_AUTHENTICATED_NAME = "allin.auth.authenticated";

export const API_ENDPOINT_CHANNELS = `${API_PATH}/channel`;
export const API_ENDPOINT_CHANNEL_ID = `${API_PATH}/channel/:id`;

export const API_ENDPOINT_INBOX = `${API_PATH}/channel/:id/inbox`;

export const API_ENDPOINT_FILE_ID = `${API_PATH}/channel/:id/asset/file/:fileId`;

export const API_ENDPOINT_FRAGMENT = `${API_PATH}/channel/:id/asset/fragment/:fragment`;
export const API_ENDPOINT_FRAGMENT_TRANSLATE = `${API_PATH}/channel/:id/asset/fragment/translate`;
export const API_ENDPOINT_FRAGMENT_STATS = `${API_PATH}/channel/:id/asset/fragment/translate/stats`;
export const API_ENDPOINT_FRAGMENT_NEXT = `${API_PATH}/channel/:id/asset/fragment/next`;

export const API_ENDPOINT_FRAGMENT_LOCK = `${API_PATH}/channel/:id/asset/fragment/:fragment/lock`;
export const API_ENDPOINT_FRAGMENT_UNLOCK = `${API_PATH}/channel/:id/asset/fragment/:fragment/unlock`;
export const API_ENDPOINT_FRAGMENT_STATE = `${API_PATH}/channel/:id/asset/fragment/:fragment/state`;

export const API_ENDPOINT_USERS = `${API_PATH}/users`;
export const API_ENDPOINT_USERS_CHANNEL = `${API_PATH}/users/:userId/channel/:channelId`;
export const API_ENDPOINT_USERS_ROLE = `${API_PATH}/users/:userId/role/:roleId`;
export const API_ENDPOINT_USERS_COUNTRY = `${API_PATH}/users/:userId/country/:countryId`;

export const API_ENDPOINT_USER = `${API_PATH}/users/:userId`;
export const API_ENDPOINT_USER_RESTORE = `${API_PATH}/users/:userId/restore`;
export const API_ENDPOINT_USER_CURRENT = `${API_PATH}/user/current`;
export const API_ENDPOINT_USER_SET_LOCALE = `${API_PATH}/user/locale`;
export const API_ENDPOINT_LANGUAGE = `${API_PATH}/language`;
export const API_ENDPOINT_COUNTRY = `${API_PATH}/country`;

export const API_ENDPOINT_ROLES = `${API_PATH}/role`;
export const API_ENDPOINT_ROLE = `${API_PATH}/role/:roleId`;

export const API_ENDPOINT_ADD_COLLECTION = `${API_PATH}/channel/:id/tools/axinom/collection/create`;

export const API_ENDPOINT_LOG = `${API_PATH}/log`;

export const API_ENDPOINT_JOB_ENCODE = `${API_PATH}/channel/:id/job/encode`;
export const API_ENDPOINT_JOB_RETRY_BULK = `${API_PATH}/channel/:id/job/retry/bulk`;
export const API_ENDPOINT_JOB = `${API_PATH}/channel/:id/job`;
export const API_ENDPOINT_JOB_ID = `${API_PATH}/channel/:id/job/:jobId`;
export const API_ENDPOINT_JOB_ID_RETRY = `${API_PATH}/channel/:id/job/:jobId/retry`;
export const API_ENDPOINT_JOB_ID_PRIORITY = `${API_PATH}/channel/:id/job/:jobId/priority`;
export const API_ENDPOINT_JOB_ID_UPLOAD = `${API_PATH}/channel/:id/job/:jobId/upload`;

export const API_CLIENT_ID = process.env.REACT_APP_ALLIN_API_CLIENT_ID;
export const API_CLIENT_SECRET = process.env.REACT_APP_ALLIN_API_CLIENT_SECRET;
