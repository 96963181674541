import { useState, useMemo, useEffect, memo } from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray, Controller } from "react-hook-form";

// Icons
import AddIcon from "@material-ui/icons/Add";

// Components
import Grid from "@material-ui/core/Grid";
import Button from "components/Button";
import LabelEditor from "components/LabelEditor";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Style
import StyledForm from "./style";

const DEFAULT_LABEL = {
  textColor: "#ffffff",
  backgroundColor: "#000000",
};

const LabelsForm = ({
  trackDetails,
  labels,
  loading,
  showDeleteConfirmDialog,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  // State
  const [deleteConfirmIndex, setDeleteConfirmIndex] = useState(null);

  // Dialog
  const deleteDialogOpen = deleteConfirmIndex != null;

  // Form
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      labels: yup.array().of(
        yup.object().shape({
          text: yup
            .string()
            .required(t("error.FIELD_REQUIRED", { name: "Text" })),
          textColor: yup
            .string()
            .required(t("error.FIELD_REQUIRED", { name: "Text color" })),
          backgroundColor: yup
            .string()
            .required(t("error.FIELD_REQUIRED", { name: "Background color" })),
        })
      ),
    });
  }, [t]);

  const { control, handleSubmit, reset, formState, errors } = useForm({
    validationSchema,
    defaultValues: {
      labels,
    },
  });

  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: "labels",
  });

  useEffect(() => {
    reset({ labels });
  }, [reset, labels]);

  // Invoke formState dirty values so that the proxy is
  // trigger/activated. Otherwise formState.isDirty doesn't work
  useEffect(() => {
    if (!formState) return;
    formState.dirtyFields.values();
  }, [formState]);

  // Listeners
  const onDeleteHandler = (index) => {
    if (showDeleteConfirmDialog) {
      setDeleteConfirmIndex(index);
    } else {
      remove(index);
    }
  };

  const onCancelDelete = () => {
    setDeleteConfirmIndex(null);
  };

  const onConfirmDelete = () => {
    remove(deleteConfirmIndex);
    setDeleteConfirmIndex(null);
  };

  const prependLabel = () => {
    prepend(DEFAULT_LABEL);
  };

  const appendLabel = () => {
    append(DEFAULT_LABEL);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column">
        {fields?.length > 3 && (
          <Button
            variant="contained"
            color="default"
            disableElevation
            className="add-button prepend"
            trackName="fragments.labels.edit.add"
            trackDetails={trackDetails}
            startIcon={<AddIcon />}
            onClick={prependLabel}
          >
            {t("buttons.ADD_LABEL")}
          </Button>
        )}
        {fields.map((item, index) => {
          const error =
            errors?.labels && errors?.labels[index]
              ? errors?.labels[index]
              : null;

          return (
            <Controller
              key={item.id}
              name={`labels[${index}]`}
              control={control}
              defaultValues={item}
              onDelete={() => onDeleteHandler(index)}
              error={error}
              as={<LabelEditor />}
            />
          );
        })}
        <Button
          variant="contained"
          color="default"
          disableElevation
          className="add-button append"
          trackName="labels.edit.add"
          trackDetails={trackDetails}
          startIcon={<AddIcon />}
          onClick={appendLabel}
        >
          {t("buttons.ADD_LABEL")}
        </Button>

        <Grid item className="button-items">
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6}>
              <Button
                disableElevation
                color="default"
                trackName="labels.edit.cancel"
                trackDetails={trackDetails}
                onClick={onCancel}
              >
                {t("buttons.CANCEL")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disableElevation
                trackName="labels.edit.save"
                trackDetails={trackDetails}
                loading={loading}
                color="primary"
                type="submit"
                disabled={!formState.dirty}
              >
                {t("buttons.SAVE")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={deleteDialogOpen}
        onClose={onCancelDelete}
        aria-labelledby="labels-edit-dialog"
      >
        <DialogTitle style={{ cursor: "move" }} id="labels-edit-dialog-title">
          {t("labels.LABEL_DELETE")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("text.LABEL_DELETE")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onCancelDelete} color="default">
            {t("text.NO")}
          </Button>
          <Button onClick={onConfirmDelete} color="primary">
            {t("text.YES")}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledForm>
  );
};

LabelsForm.defaultValues = {
  onSubmit: () => {},
  onCancel: () => {},
  showDeleteConfirmDialog: false,
};

LabelsForm.propTypes = {
  trackDetails: PropTypes.shape({}),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
      textColor: PropTypes.string,
      backgroundColor: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  showDeleteConfirmDialog: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default memo(LabelsForm);
