import Utils from "../Common/utils";

export const setSelected = (state, { channelId, channels = [] }) => {
  let selectedChannel = null;
  channels.forEach((channel) => {
    if (channel.id === channelId) {
      selectedChannel = channel;
    }
  });

  return state.merge({
    selected: selectedChannel === null ? null : channelId,
    data:
      selectedChannel === null
        ? null
        : {
            ...state.data,
            ...selectedChannel,
          },
  });
};

export const setConfig = (state, { config }) => {
  // Normalize forms
  const formsById = Utils.Common.normalize(config?.forms, (data) => data.id);
  const checksById = Utils.Common.normalize(config?.checks, (data) => data.id);

  return state.merge({
    data: {
      ...state.data,
      ...config,
      forms: formsById,
      checks: checksById,
    },
  });
};
