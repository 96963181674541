import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Icons
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

// Hooks
import { useAnalytics } from "features/Analytics";

// Style
import StyledComponent from "./style";

const TokenExpired = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  useEffect(() => {
    track("external.subtitle.token.expired.or.invalid");
  }, [track]);

  return (
    <StyledComponent>
      <div>
        <ErrorOutlineIcon />
        {t("error.TOKEN_EXPIRED")}
      </div>
    </StyledComponent>
  );
};

export default memo(TokenExpired);
