import grey from "@material-ui/core/colors/grey";

const style = ({ spacing, palette }) => ({
  timelineHeader: {
    padding: spacing(),
    background: grey[50],
    borderLeft: `1px solid ${grey[300]}`,
    borderBottom: `1px solid ${grey[300]}`,
  },
  dialogActions: {
    padding: spacing(),
    backgroundColor: "rgba(0,0,0,.02)",
    borderTop: `1px solid ${palette.divider}`,
    justifyContent: "space-between",
  },
});

export default style;
