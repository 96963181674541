import styled from "styled-components";
import Button from "components/Button";

export default styled(Button)`
  & .MuiButton-root {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    padding: 4px 12px;

    & .column-left {
      display: flex;
      align-items: center;
      text-align: left;
    }

    & .MuiFormControlLabel-root {
      margin-right: 2px;
    }

    & .MuiTypography-body1 {
      margin-left: 8px;
    }

    & .label {
      background: ${({ labelBackgroundColor }) => labelBackgroundColor};
      color: ${({ labelTextColor }) => labelTextColor};
      padding: 2px 12px;
      border-radius: 4px;
      font-size: 0.65rem;
      font-weight: bold;
    }
  }
`;
