/* eslint-disable camelcase */
import { memo } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

// Components
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

// TableCells
import NameCell from "./NameCell";
import CountryCell from "./CountryCell";
import LicenseCell from "./LicenseCell";
import ScheduleCell from "./ScheduleCell";
import StatusCell from "./StatusCell";
import DurationCell from "./DurationCell";

// Styles
import StyledTableRow from "./style";

const AssetRow = ({
  id,
  identifier,
  name,
  dateType,
  highlight,
  quality,
  qualityCheck,
  checks,
  completed,
  licenses,
  schedule,
  countries,
  stats,
  isSelected,
  isOpen,
  firstFragmentId,
  type,
  locale,
  language,
  isExpanded,
  onRowClick,
  onRowSelect,
  onExpandRow,
  onStatusClick,
  onCountryClick,
}) => {
  const { t } = useTranslation();

  const onRowClickHandler = (event) => {
    onRowClick({
      event,
      assetId: id,
      type,
      fragmentId: firstFragmentId,
    });
  };

  const onSelectClickHandler = (event) => {
    event.stopPropagation();
  };

  const onRowSelectHandler = (event) => {
    onRowSelect(event, id);
  };

  return (
    <StyledTableRow
      className={isOpen ? "open" : "closed"}
      selected={isSelected}
      onClick={onRowClickHandler}
    >
      <TableCell className="cell--checkbox" padding="checkbox">
        <div className="tree-lines" />
        <Checkbox
          size="small"
          checked={isSelected}
          onClick={onSelectClickHandler}
          onChange={onRowSelectHandler}
        />
      </TableCell>
      <NameCell
        quality={quality}
        name={name}
        locale={locale}
        language={language}
        schedule={schedule}
        highlight={highlight}
        identifier={identifier}
        translations={{
          missingName: (missingLanguage) =>
            t("text.MISSING_NAME", { language: missingLanguage }),
          contractNotAvailable: (missingLocale) =>
            t("text.CONTRACT_NOT_AVAILABLE_IN_COUNTRY", {
              locale: missingLocale,
            }),
        }}
      />
      <LicenseCell licenses={licenses} highlight={highlight} />
      <CountryCell
        countries={countries}
        onMoreInfoClick={onCountryClick}
        id={id}
        schedule={schedule}
        name={name}
        identifier={identifier}
      />
      <ScheduleCell
        id={id}
        name={name}
        identifier={identifier}
        schedule={schedule}
        locale={locale}
        language={language}
        dateType={dateType}
        completed={completed}
        onMoreInfoClick={onCountryClick}
      />
      <StatusCell
        qualityCheck={qualityCheck}
        completed={completed}
        language={language}
        checks={checks}
        onMoreInfoClick={onStatusClick}
      />
      <DurationCell
        id={id}
        fragmentCount={stats?.fragment_count}
        fragmentDuration={stats?.fragment_duration}
        isExpanded={isExpanded}
        onExpandRow={onExpandRow}
        translations={{
          minutesAbbreviation: t("text.MIN").toLowerCase(),
        }}
      />
    </StyledTableRow>
  );
};

AssetRow.defaultProps = {
  onRowClick: () => null,
  onRowSelect: () => null,
  onExpandRow: () => null,
  onStatusClick: () => null,
  onCountryClick: () => null,
};

AssetRow.propTypes = {
  id: PropTypes.number,
  identifier: PropTypes.string,
  name: PropTypes.shape({}),
  dateType: PropTypes.string,
  highlight: PropTypes.shape({}),
  quality: PropTypes.string,
  qualityCheck: PropTypes.bool,
  checks: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  completed: PropTypes.arrayOf(PropTypes.string),
  licenses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  schedule: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  countries: PropTypes.arrayOf(PropTypes.string),
  stats: PropTypes.shape({
    fragment_count: PropTypes.number,
    fragment_duration: PropTypes.number,
  }),
  isSelected: PropTypes.bool,
  isOpen: PropTypes.bool,
  isExpanded: PropTypes.bool,
  firstFragmentId: PropTypes.number,
  type: PropTypes.oneOf(["video", "series"]),
  locale: PropTypes.string,
  language: PropTypes.string,
  onRowClick: PropTypes.func,
  onRowSelect: PropTypes.func,
  onExpandRow: PropTypes.func,
  onStatusClick: PropTypes.func,
  onCountryClick: PropTypes.func,
};

export default memo(AssetRow);
