import { useCallback } from "react";
import PropTypes from "prop-types";

// Components
import MuiMenuItem from "@material-ui/core/MenuItem";

const MenuItem = ({ trackName, trackDetails, onClick, ...props }) => {
  const dispatchTrackingClick = useCallback(() => {
    if (!trackName) return;
    const trackingEvent = new CustomEvent("button-click", {
      detail: {
        trackName,
        trackDetails: { trackName, ...trackDetails },
      },
    });
    dispatchEvent(trackingEvent);
  }, [trackName, trackDetails]);

  const onClickHandler = useCallback(
    (event) => {
      onClick(event);
      dispatchTrackingClick();
    },
    [onClick, dispatchTrackingClick]
  );

  return <MuiMenuItem {...props} onClick={onClickHandler} />;
};

MenuItem.defaultProps = {
  onClick: () => {},
};

MenuItem.propTypes = {
  trackName: PropTypes.string,
  trackDetails: PropTypes.shape({}),
  onClick: PropTypes.func,
};

export default MenuItem;
