import ChannelSettingsList from "./ChannelSettingsList";

const routes = [
  {
    key: "CHANNEL_SETTINGS",
    path: "/channel/:channelId/settings",
    component: ChannelSettingsList,
    exact: true,
    displayNav: true,
    auth: true,
  },
  {
    key: "CHANNEL_SETTINGS_SCREENS",
    path: "/channel/:channelId/settings/:setting",
    component: ChannelSettingsList,
    exact: true,
    displayNav: false,
    auth: true,
  },
];

export default routes;
