/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { sortBy } from "features/Common/utils/common.utils";

export const loadLicenses = (state, { payload }) => {
  state.ui.loading = true;
};

export const loadLicensesSuccess = (state, { payload }) => {
  state.ui.loading = false;
  state.data = payload.sort(sortBy("name"));
};

export const loadLicensesFailed = (state) => {
  state.ui.loading = false;
};

const fragmentReducer = createSlice({
  name: "fragment",
  initialState: {
    data: null,
    ui: {
      loading: false,
    },
  },
  reducers: {
    loadLicenses,
    loadLicensesSuccess,
    loadLicensesFailed,
  },
});

const { reducer } = fragmentReducer;

export const actions = fragmentReducer.actions;
export default reducer;
