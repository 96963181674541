import grey from "@material-ui/core/colors/grey";

const style = ({ palette, spacing }) => ({
  container: {
    backgroundColor: "#eee",
    overflowY: "auto",
    flex: 1,
    width: "100%",
    height: "100%",
  },

  padding: {
    padding: spacing(),
  },

  timeline: {
    position: "relative",
    minHeight: "100%",
    width: "100%",
    padding: 0,

    "&:before": {
      content: "''",
      position: "absolute",
      top: "0",
      left: "calc(25% + 16px)",
      bottom: "0",
      width: 3,
      background: grey[300],
    },
  },

  entry: {
    clear: "both",
    textAlign: "left",
    position: "relative",
    overflow: "hidden",
  },

  entryTitle: {
    margin: "1em 0",
    float: "left",
    width: "25%",
    paddingRight: 20,
    textAlign: "right",
    position: "relative",

    "&:before": {
      content: "''",
      position: "absolute",
      width: 10,
      height: 10,
      border: "4px solid darkslategray",
      backgroundColor: "#ffffff",
      borderRadius: "100%",
      top: "3px",
      right: "-6px",
      zIndex: "99",
    },

    "& h3": {
      margin: "3px 0 2px 0",
      fontSize: "13px",
      color: "rgba(0, 0, 0, .7)",
    },
    "& p": {
      margin: "0",
      fontSize: "12px",
      color: "rgba(0, 0, 0, .5)",
    },
  },

  body: {
    margin: "7px 0 1em 0",
    float: "right",
    width: "66%",

    "& p": {
      marginTop: 10,
      fontWeight: "bold",
    },

    "& ul": {
      color: "#696969",
      paddingLeft: "0",
      listStyleType: "none",

      "& li": {
        margin: "0 1em",
        listStylePosition: "inside",
        textIndent: "-1em",
        fontSize: "12px",
      },
    },
  },

  state_uploaded: {
    "& .actionTitle": {
      color: palette.success.main,
    },

    "& .entryTitle": {
      "&:before": {
        border: `4px solid ${palette.success.main}`,
      },
    },
  },

  state_updated: {
    "& .actionTitle": {
      color: "#a7a45c",
    },

    "& .entryTitle": {
      "&:before": {
        border: "4px solid #a7a45c",
      },
    },
  },

  state_sync: {
    "& .actionTitle": {
      color: "#7196a7",
    },

    "& .entryTitle": {
      "&:before": {
        border: "4px solid #7196a7",
      },
    },
  },

  state_ready: {
    "& .actionTitle": {
      color: "#649eb5",
    },

    "& .entryTitle": {
      "&:before": {
        border: "4px solid #649eb5",
      },
    },
  },

  state_postponed: {
    "& .actionTitle": {
      color: "#bfc2c6",
    },

    "& .entryTitle": {
      "&:before": {
        border: "4px solid #bfc2c6",
      },
    },
  },

  state_queued: {
    "& .actionTitle": {
      color: "#a76ea4",
    },

    "& .entryTitle": {
      "&:before": {
        border: "4px solid #a76ea4",
      },
    },
  },

  state_started: {
    "& .actionTitle": {
      color: "#a78101",
    },

    "& .entryTitle": {
      "&:before": {
        border: "4px solid #a78101",
      },
    },
  },

  state_in_progress: {
    "& .actionTitle": {
      color: "#017e9c",
    },

    "& .entryTitle": {
      "&:before": {
        border: "4px solid #017e9c",
      },
    },
  },

  state_success: {
    "& .actionTitle": {
      color: "#009c3d",
    },

    "& .entryTitle": {
      "&:before": {
        border: "4px solid #009c3d",
      },
    },
  },

  state_error: {
    "& .actionTitle": {
      color: "#9c0011",
    },

    "& .entryTitle": {
      "&:before": {
        border: "4px solid #9c0011",
      },
    },
  },

  state_created: {
    "& .actionTitle": {
      color: "#1f1f1f",
    },

    "& .entryTitle": {
      "&:before": {
        border: "4px solid #1f1f1f",
      },
    },
  },
});

export default style;
