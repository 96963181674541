import { forwardRef, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import MUIButton from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";

import StyledComponent from "./style";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Button = ({
  name,
  variant,
  color,
  size,
  type,
  className,
  style,
  loading,
  children,
  trackName,
  trackDetails,
  disableElevation,
  href,
  startIcon,
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled,
  dialog,
  dialogTitle,
  dialogDescription,
  dialogButtonText,
  dialogButtonColor,
  dialogCallback,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [dialogEventLoading, setDialogEventLoading] = useState(false);
  const dispatchTrackingClick = useCallback(() => {
    if (!trackName) return;
    const trackingEvent = new CustomEvent("button-click", {
      detail: {
        trackName,
        trackDetails: { trackName, ...trackDetails },
      },
    });
    dispatchEvent(trackingEvent);
  }, [trackName, trackDetails]);

  const onClickHandler = useCallback(
    (event) => {
      if (dialog) {
        if (open) {
          dispatchTrackingClick();
        }

        setOpen(!open);
      } else {
        onClick(event);
        dispatchTrackingClick();
      }
    },
    [onClick, dispatchTrackingClick, dialog, open]
  );

  const dialogCallBackFunction = useCallback(async () => {
    setDialogEventLoading(true);

    try {
      await dialogCallback();
      setDialogEventLoading(false);
      setOpen(false);
    } catch (error) {
      setDialogEventLoading(false);
    }
  }, [dialogCallback, setOpen]);

  return (
    <StyledComponent className={className}>
      {dialog && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {dialogDescription}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="default">
              {t("buttons.CANCEL")}
            </Button>
            <Button
              onClick={dialogCallBackFunction}
              color={dialogButtonColor}
              loading={dialogEventLoading}
            >
              {dialogButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <MUIButton
        data-testid={name}
        name={name}
        href={href}
        variant={variant}
        color={color}
        size={size}
        disableElevation={disableElevation}
        type={type}
        disabled={loading || disabled}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        startIcon={startIcon}
        className="button"
        style={style}
        onClick={onClickHandler}
      >
        {children}
      </MUIButton>
      {loading && (
        <LinearProgress variant="indeterminate" className="loading" />
      )}
    </StyledComponent>
  );
};

Button.defaultProps = {
  name: null,
  variant: "contained",
  color: "primary",
  dialogButtonColor: "primary",
  loading: false,
  disabled: false,
  disableElevation: false,
  onClick: () => {},
  onMouseLeave: () => {},
  onMouseEnter: () => {},
};

Button.propTypes = {
  name: PropTypes.string,
  variant: PropTypes.oneOf(["default", "contained", "outlined", "text"]),
  color: PropTypes.oneOf(["default", "primary", "secondary"]),
  dialogButtonColor: PropTypes.oneOf(["default", "primary", "secondary"]),
  loading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseEnter: PropTypes.func,
  disableElevation: PropTypes.bool,
  startIcon: PropTypes.node,
};

export default Button;
