import { useState } from "react";

const useTabs = ({ onTabChange }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const onChange = (_, newValue) => {
    setTabIndex(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  return [tabIndex, onChange, setTabIndex];
};

export default useTabs;
