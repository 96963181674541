import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import "flag-icons/css/flag-icons.min.css";
import * as userActions from "../../Auth/User.actions";
import * as languageActions from "../Language.actions";
import Utils from "../../Common/utils";

class SelectTranslateLanguage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };

    const { loadLanguages } = this.props;

    loadLanguages();
  }

  handleClick = (event) => {
    const { disabled } = this.props;
    if (disabled) return false;

    return this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  changeSelected = (language) => {
    const { setUserTranslatableLanguage } = this.props;
    setUserTranslatableLanguage(language);

    this.handleClose();
  };

  // eslint-disable-next-line class-methods-use-this
  renderFlagWithText(code) {
    return (
      <div>
        <span
          className={`fi fi-${Utils.Common.flagCode(code.toLocaleLowerCase())}`}
          style={{ marginRight: 8 }}
        />
        {code.toUpperCase()}
      </div>
    );
  }

  render() {
    const { anchorEl } = this.state;
    const { languages, user, disabled } = this.props;

    const selected = user ? user.translate_language.code : "en";

    if (!languages) return false;

    return (
      <div>
        <Button
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          color="inherit"
          onClick={this.handleClick}
          disabled={disabled}
        >
          {this.renderFlagWithText(selected)}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
            },
          }}
        >
          <MenuItem onClick={() => this.handleClose()} selected>
            {this.renderFlagWithText(selected)}
          </MenuItem>
          {languages
            .filter((item) => item.code !== selected)
            .map((language) => (
              <MenuItem
                key={language.code}
                onClick={() => this.changeSelected(language)}
              >
                {this.renderFlagWithText(language.code)}
              </MenuItem>
            ))}
        </Menu>
      </div>
    );
  }
}

SelectTranslateLanguage.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  languages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setUserTranslatableLanguage: PropTypes.func.isRequired,
  loadLanguages: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SelectTranslateLanguage.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  languages: state.language.data,
});

const mapDispatchToProps = (dispatch) => ({
  setUserTranslatableLanguage: (language) =>
    dispatch(userActions.setUserTranslateLanguage(language)),
  loadLanguages: () => dispatch(languageActions.find()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectTranslateLanguage
);
