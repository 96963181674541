import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";

import { loadFragment } from "features/Fragment/reducer";
import * as fragmentActions from "../../Fragment/redux/Fragment.actions";
import * as jobActions from "./Job.actions";

import { restartJob } from "./job.service";

import JobListComponent from "./ListComponent";
import JobListHeaderComponent from "./ListHeaderComponent";

import CircularProgressCenter from "../../../components/CircularProgressCenter";

import styles from "./Jobs.styles";

class JobListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableSelected: [],
      filter: null,
    };

    this.page = 1;

    this.columns = [
      {
        dataKey: "name",
        label: "labels.NAME",
        disablePadding: true,
        disableSort: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: null,
      },
      {
        dataKey: "license",
        label: "labels.LICENSE",
        disablePadding: true,
        disableSort: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: null,
      },
      {
        dataKey: "state",
        label: "labels.STATE",
        rightAlign: true,
        disablePadding: true,
        disableSort: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: null,
      },
    ];

    const { resetJobs, findJobs } = this.props;
    resetJobs();
    findJobs({
      page: 1,
      filter: {
        status: ["postponed", "in_progress", "error", "ready"],
      },
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { job } = nextProps;
    let { filter } = prevState;

    if (job.data && job.data.filter && !filter) {
      filter = {};
      Object.keys(job.data.filter).forEach((key) => {
        filter[key] = [];

        if (key === "status") {
          filter[key] = ["postponed", "in_progress", "error", "ready"];
        }
      });
      return { filter };
    }

    return {};
  }

  loadMoreRows = ({ stopIndex }) => {
    const { job, findJobs } = this.props;
    const { filter } = this.state;

    const page = Math.ceil(stopIndex / job.data.meta.per_page);

    if (page > this.page) {
      this.page = page;
      findJobs({
        page,
        filter,
      });
    }
  };

  setFilter = (key, value) => {
    const { resetJobs, findJobs } = this.props;
    let { filter } = this.state;

    filter = {
      ...filter,
      [key]: value,
    };

    this.setState({ filter });

    this.page = 1;
    resetJobs();
    findJobs({
      page: 1,
      filter,
    });
  };

  hasActiveFilter = () => {
    const { filter } = this.state;

    return filter
      ? !Object.values(filter).every((o) => o === "" || o.length === 0)
      : false;
  };

  restartJobs = () => {
    const { enqueueSnackbar, job, channel, resetJobs, findJobs } = this.props;
    const { tableSelected, filter } = this.state;

    const jobs = job.data.data.filter((j) => tableSelected.includes(j.id));

    jobs.forEach((jobToRestart) =>
      restartJob(channel.selected, jobToRestart).then((response) => {
        if (response.status !== 200) {
          Object.keys(response.data.errors).forEach((key) => {
            const jobError = `${jobToRestart.fragment.asset.name}: ${jobToRestart.fragment.name} - (${jobToRestart.command.name})`;
            enqueueSnackbar(`${jobError}: ${response.data.errors[key]}`, {
              variant: "error",
            });
          });
        }
      })
    );
    resetJobs();
    findJobs({
      page: 1,
      filter,
    });
  };

  onTableChange = (value) => {
    this.setState({
      tableSelected: [...value.selected],
    });
  };

  onRowClick = ({ rowData }) => {
    const { findFragment, history } = this.props;
    const { fragment } = rowData;
    const panelUrl = `${history.location.pathname}?panel=jobs`;

    findFragment(fragment.id);
    history.push(panelUrl);
  };

  render() {
    const { classes, job } = this.props;
    const { tableSelected, filter } = this.state;

    return (
      <div className={classes.root}>
        <JobListHeaderComponent
          setFilter={this.setFilter}
          hasActiveFilter={this.hasActiveFilter}
          filterValues={filter}
          selected={tableSelected}
          restartJobs={this.restartJobs}
          jobs={job.data}
        />

        {job.data !== null && (
          <JobListComponent
            jobs={job.data.data}
            columns={this.columns}
            onTableChange={this.onTableChange}
            onRowClick={this.onRowClick}
            loadMoreRows={this.loadMoreRows}
            remoteRowCount={job.data.meta.total}
          />
        )}

        {job.data === null && <CircularProgressCenter />}
      </div>
    );
  }
}

JobListContainer.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  resetJobs: PropTypes.func.isRequired,
  findJobs: PropTypes.func.isRequired,
  findFragment: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  job: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }).isRequired,
  channel: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  job: state.job,
  channel: state.channel,
});

const mapDispatchToProps = (dispatch) => ({
  findJobs: (searchParams) => dispatch(jobActions.find(searchParams)),
  resetJobs: () => dispatch(jobActions.reset()),
  findFragment: (id) => dispatch(loadFragment({ fragmentId: id })),
  resetFragment: () => dispatch(fragmentActions.reset()),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withSnackbar(JobListContainer));
