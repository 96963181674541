import palette from "../../Common/theme/palette";

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: palette.background.colorDark,
  },

  logoContainer: {
    padding: "30px 0",
  },

  centerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "15px",
  },

  divider: {
    marginBottom: "15px",
  },

  card: {
    maxWidth: 450,
  },

  inputContainer: {
    marginTop: "15px",
  },

  checkBoxContainer: {
    paddingBottom: "15px",
  },

  textField: {
    marginBottom: "16px",
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
    position: "relative",
  },

  button: {
    width: "30%",
  },

  loginLoading: {
    width: "30%",
    bottom: "0px",
    position: "absolute",
    borderRadius: "0 0 4px 4px",
  },

  passwordForgetContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
    position: "relative",
  },

  passwordForgetLink: {
    color: "black",
    textDecoration: "none",
  },
};

export default style;
