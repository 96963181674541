import Button from "@material-ui/core/Button";

import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Can } from "../../features/Auth/AccessControl";
import { permissions } from "../../features/Common/constants";

const NavBarItem = ({ route, channel }) => {
  const { t } = useTranslation();

  if (!route.displayNav) return null;

  const path = route.path
    .replace(":channelId", channel.id)
    .replace(":type(video)", "video")
    .replace(":type(series)", "series");

  const subject = permissions.subjects[route.key];

  return (
    <Can I={permissions.actions.VIEW} a={subject} key={route.key}>
      <NavLink to={path} className="nav-link" activeClassName="active">
        <Button key={route.key} color="inherit">
          {t(`titles.${route.key}`)}
        </Button>
      </NavLink>
    </Can>
  );
};

export default NavBarItem;
