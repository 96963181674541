import { memo } from "react";
import PropTypes from "prop-types";

/* Components */
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "features/Common/PrivateRoute";

const RoutesSwitch = ({ routes, basePath, children }) => (
  <Switch>
    {routes.map((route) => {
      const path = basePath ? `${basePath}${route.path}` : route.path;
      return route.auth ? (
        <PrivateRoute type={route.key} {...route} path={path} routes={routes} />
      ) : (
        <Route {...route} path={path} routes={routes} />
      );
    })}
    {children}
  </Switch>
);

RoutesSwitch.defaultProps = {
  basePath: null,
};

RoutesSwitch.propTypes = {
  basePath: PropTypes.string,
};

export default memo(RoutesSwitch);
