import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

// Components
import TextField from "components/Form/TextField";
import { CheckboxWithControl } from "components/Form/Checkbox";

// Style
import FormStyled from "./style";

const LicenseForm = ({ license }) => {
  const { t } = useTranslation();
  const { handleSubmit, register, errors, control, reset } = useForm({
    defaultValues: {
      name: null,
      identifier: null,
      contentOwner: null,
      contractRequired: false,
    },
  });

  const onSubmit = () => {};

  useEffect(() => {
    if (!license) return;

    reset({
      name: license.name,
      identifier: license.identifier,
      contentOwner: license.content_owner,
      contractRequired: license.contract_required,
    });
  }, [reset, license]);

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="name"
        label={t("labels.NAME")}
        variant="outlined"
        className="inputField"
        size="small"
        disabled
        error={errors.firstName?.message}
        ref={register}
      />
      <TextField
        name="identifier"
        label={t("labels.IDENTIFIER")}
        variant="outlined"
        className="inputField"
        size="small"
        disabled
        error={errors.firstName?.message}
        ref={register}
      />
      <TextField
        name="contentOwner"
        label={t("labels.CONTENT_OWNER")}
        variant="outlined"
        className="inputField"
        size="small"
        disabled
        error={errors.firstName?.message}
        ref={register}
      />
      <CheckboxWithControl
        name="contractRequired"
        color="primary"
        label={t("labels.CONTRACT_REQUIRED")}
        disabled
        error={errors?.contractRequired?.message}
        control={control}
      />
    </FormStyled>
  );
};

LicenseForm.propTypes = {};

export default LicenseForm;
