import { useState, useMemo } from "react";
import { useRequest } from "features/Common/hooks";

const PAGE_SIZE = 3;
const usePaginatedDataSource = ({
  request,
  errorMessage,
  pageSize,
  params,
}) => {
  // State
  const [doRequest, data, setData, loading, error] = useRequest({
    request,
    errorMessage,
    params,
  });
  const [limit, setLimit] = useState(pageSize);

  // Calc load more count
  const total = data?.length || 0;
  const loaded = limit;

  const loadMore = () => {
    const maxLength = data?.length || 0;
    let newLimit = limit + PAGE_SIZE;
    newLimit = newLimit < maxLength ? newLimit : maxLength;
    setLimit(newLimit);
  };

  const paginatedData =
    useMemo(() => {
      const maxLength = data?.length || 0;
      const newLimit = limit < maxLength ? limit : maxLength;
      const dataCopy = [...data];
      return dataCopy.splice(0, newLimit);
    }, [data, limit]) || [];

  return {
    doRequest,
    data,
    paginatedData,
    setData,
    loading,
    error,
    loadMore,
    total,
    loaded,
  };
};

export default usePaginatedDataSource;
