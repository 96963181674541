import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// Local Providers
import { useSuppliers } from "./SuppliersProvider";

// Local Components
import Supplier from "./Supplier";

// Style
import StyledComponent from "./style";
import CircularProgressCenter from "components/CircularProgressCenter";

const Suppliers = ({ license }) => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    suppliers,
    loadSuppliers,
    resetSuppliers,
  } = useSuppliers();

  // Derived  state
  const licenseId = license?.id;

  useEffect(() => {
    if (!licenseId) {
      return;
    }
    loadSuppliers(licenseId);

    return resetSuppliers;
  }, [licenseId, loadSuppliers, resetSuppliers]);

  if (loading) return <CircularProgressCenter variant="inline" size={24} />;
  if (error) return t("errors.COMMON");

  return (
    <StyledComponent>
      {suppliers?.map(
        ({ id, email, due_date, last_used_at, deleted_at, expires_at }) => {
          return (
            <Supplier
              key={id}
              id={id}
              licenseId={licenseId}
              email={email}
              dueDate={due_date}
              lastUsedAt={last_used_at}
              deletedAt={deleted_at}
              expiresAt={expires_at}
            />
          );
        }
      )}
      {!suppliers?.length && (
        <span className="no-suppliers">{t("text.NO_SUPPLIERS_INVITED")}</span>
      )}
    </StyledComponent>
  );
};

Suppliers.defaultProps = {
  licenseId: null,
};

Suppliers.propTypes = {
  licenseId: PropTypes.number,
};

export default Suppliers;
