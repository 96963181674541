import palette from "../../palette";

const style = {
  MuiInput: {
    underline: {
      "&:before": {
        borderBottom: `1px solid ${palette.background.lightAccent}`,
      },
    },
  },
};

export default style;
