/* Components */
import JobProvider from "features/Fragment/FragmentJobs/JobProvider";

import Layout from "../../components/Layout";
import ChannelSettingsDrawer from "./ChannelSettingsDrawer";

/* Features */
import ChannelSettings from "../../features/ChannelSettings/List";

const Page = ({ routes, ...props }) => (
  <JobProvider>
    <Layout routes={routes} drawer={<ChannelSettingsDrawer />}>
      <ChannelSettings {...props} />
    </Layout>
  </JobProvider>
);

export default Page;
