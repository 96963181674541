import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .button {
    width: 100%;
  }

  .loading {
    width: 100%;
    bottom: 0px;
    position: absolute;
    border-radius: 0 0 4px 4px;
  }
`;
