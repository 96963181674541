import PropTypes from "prop-types";

// Icons
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

// Components
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

// Hooks
import { usePersistentState } from "../../Common/hooks";

// Style
import StyledComponent from "./style";

const SidebarGroup = ({ name, title, headerIcon, children, menuActions }) => {
  const [expand, setExpand] = usePersistentState(true, `sidebar.group.${name}`);
  const onExpand = () => {
    setExpand(!expand);
  };
  return (
    <StyledComponent>
      <header>
        <div className="title-wrapper">
          {headerIcon}
          <Typography component="h6" className="title">
            {title}
          </Typography>
        </div>
        <div className="actions">
          {menuActions}
          <IconButton onClick={onExpand}>
            {expand ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>
        </div>
      </header>
      <div className={expand ? "content" : "content collapsed"}>{children}</div>
    </StyledComponent>
  );
};

SidebarGroup.defaultProps = {};

SidebarGroup.propTypes = {
  name: PropTypes.string.isRequired,
  menuActions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default SidebarGroup;
