import styled from "styled-components";

const StyledForm = styled.form`
  & .MuiFormControl-root > header {
    margin-bottom: 0;
  }

  & header .column-left {
    margin: 0;
  }

  & header .MuiTypography-h5,
  & header .MuiTypography-body1 {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 0.875rem !important;
    color: ${({ theme }) => theme.palette.grey[700]};
    font-weight: bold;
  }

  & .MuiButtonBase-root {
    margin-top: 12px;
  }

  & .MuiAlert-standardError {
    margin-top: 12px;
  }
`;

export default StyledForm;
