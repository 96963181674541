import PropTypes from "prop-types";
import Fade from "@material-ui/core/Fade";

const TabsPanel = ({
  tabIndex,
  activeTabIndex,
  children,
  element,
  ...props
}) => {
  const Element = element;

  if (tabIndex !== activeTabIndex) return null;
  return (
    <Fade in {...props}>
      <Element role="tabpanel">{children}</Element>
    </Fade>
  );
};

TabsPanel.defaultProps = {
  activeTabIndex: -1,
  element: "div",
};

TabsPanel.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  activeTabIndex: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  element: PropTypes.string,
};

export default TabsPanel;
