import styled from "styled-components";

const StyledComponent = styled.div`
  & a {
    text-decoration: none;
  }

  .MuiCard-root {
    box-shadow: none;
    &:hover {
      background: ${({ theme }) => theme.palette.grey[100]};
    }
  }

  .MuiCardContent-root {
    padding: ${({ theme }) => theme.spacing(2)}px;
    padding-top: 0;
  }

  .MuiTypography-root {
    max-width: 800px;
  }

  .activeEdit {
    .MuiCard-root {
      background: ${({ theme }) => theme.palette.grey[100]};
    }

    .MuiIconButton-label {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .no-value {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.grey[400]};

    & .MuiSvgIcon-root {
      margin: 0 ${({ theme }) => theme.spacing(0.5)}px;
    }
  }

  .element {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;

    & h6 {
      font-weight: 700;
      line-height: 1.6;
      text-transform: uppercase;
      font-size: 0.875rem !important;
      margin: 7px;
      color: ${({ theme }) => theme.palette.grey[800]};
    }

    .MuiChip-root {
      margin-right: ${({ theme }) => theme.spacing(0.5)}px;
      margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
    }

    .date-content {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.palette.grey[700]};
      & .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.grey[400]};
        margin-left: ${({ theme }) => theme.spacing(0.5)}px;
        margin-right: ${({ theme }) => theme.spacing(1)}px;
      }
    }
  }
`;

export default StyledComponent;
