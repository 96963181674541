import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/Button/Button";
import Alert from "@material-ui/lab/Alert";

// Services
import { deleteFragment } from "features/Fragment/reducer";

// Hooks
import { useCurrentAssetUrl } from "features/Asset/hooks";

// Redux
import { getCurrentFragment } from "features/Fragment/selectors";
import { getCurrentAsset } from "features/Asset/selectors";
import { DialogContent } from "@material-ui/core";

const FragmentDelete = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const parentAssetUrl = useCurrentAssetUrl();

  // Redux
  const { data: asset } = useSelector(getCurrentAsset);
  const {
    data: fragment,
    deleting,
    deleteError,
  } = useSelector(getCurrentFragment);

  const fragmentId = fragment?.id;
  const assetId = asset?.id;

  const handleClick = () => {
    dispatch(
      deleteFragment({
        fragmentId,
        assetId,
        parentAssetUrl,
        history,
      })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("text.DELETE_FRAGMENT_CONFIRMATION", { fragment: fragment.name })}
      </DialogTitle>
      <DialogContent>
        {deleteError && (
          <Alert severity="error">{t("error.FRAGMENT_DELETE")}</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="default"
          trackName="fragment.delete.cancel"
          trackDetails={{ fragmentId }}
        >
          {t("text.NO")}
        </Button>
        <Button
          onClick={handleClick}
          color="primary"
          trackName="fragment.delete.confirm"
          trackDetails={{
            fragmentId,
          }}
          loading={deleting}
        >
          {t("text.YES")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(FragmentDelete);
