import axios from "axios";
import { setAuthenticationStateInStorage } from "features/Auth/Auth.utils";
import SnackbarUtils from "../utils/snackbar.utils";
import { API_URL, API_PATH } from "../constants";

class AllIn {
  constructor() {
    this.authenticated = false;
    this.channelId = null;
    this.locale = null;

    this.api = axios.create({
      baseURL: API_URL,
      withCredentials: true,
    });

    this.api.interceptors.request.use((httpConfig) => {
      if (
        this.channelId &&
        httpConfig.url.startsWith(this.getApiSearchPath())
      ) {
        Object.assign(httpConfig, {
          params: {
            channel_id: this.channelId,
            ...httpConfig.params,
          },
        });
      } else {
        // TODO
        // Object.assign(httpConfig, {
        //   params: {
        //     locale: this.locale,
        //     ...httpConfig.params,
        //   },
        // });
      }

      return httpConfig;
    });

    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const { status, config, data } = error.response;
          const { url, method } = config;
          const { message, errors } = data;

          const logEventData = {
            "Response Status": status,
            "Api Url": url,
            "Api Method": method,
            "Api Message": message,
          };

          switch (status) {
            case 401:
              setAuthenticationStateInStorage(false);
              break;
            case 403:
              this.logEvent("Cannot access api url", logEventData);
              SnackbarUtils.error("You do not have access to this page.");
              break;
            case 422:
              if (errors) {
                Object.keys(errors).forEach((key) =>
                  SnackbarUtils.error(errors[key][0])
                );
              } else {
                SnackbarUtils.error(message);
              }
              break;
            case 500:
              this.logEvent("Server error", logEventData);
              SnackbarUtils.error("Server error.");
              break;
            default:
              break;
          }
        } else if (error.request) {
          SnackbarUtils.error("Empty response.");
        } else {
          SnackbarUtils.error("Unknown error.");
        }

        return Promise.reject(error);
      }
    );
  }

  setChannelId = (channelId) => {
    this.channelId = channelId;
  };

  getChannelId = () => this.channelId;

  setLocale = (locale) => {
    this.locale = locale;
  };

  getLocale = () => this.locale;

  getApi = () => this.api;

  getApiPath = () => `${API_PATH}`;

  getApiChannelPath = () => `${API_PATH}/channel/${this.getChannelId()}`;

  getApiSearchPath = () => `${API_PATH}/search`;

  logEvent = (name, details) => {
    const trackingEvent = new CustomEvent("api-log", {
      detail: {
        trackName: name,
        trackDetails: details,
      },
    });

    dispatchEvent(trackingEvent);
  };
}

export default AllIn;
