import Login from "./Login";
import ActivationToken from "./ActivationToken";
import ForgetPassword from "./ForgetPassword";

const routes = [
  {
    key: "LOGIN",
    path: "/login",
    component: Login,
    exact: true,
    displayNav: false,
    auth: false,
  },
  {
    key: "ActivationToken",
    path: "/activation/:token",
    component: ActivationToken,
    exact: true,
    displayNav: false,
    auth: false,
  },
  {
    key: "ForgetPassword",
    path: "/forgot-password",
    component: ForgetPassword,
    exact: true,
    displayNav: false,
    auth: false,
  },
  {
    key: "ForgetPasswordToken",
    path: "/reset-password/:token",
    component: ForgetPassword,
    exact: true,
    displayNav: false,
    auth: false,
  },
];

export default routes;
