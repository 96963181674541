import styled from "styled-components";
import { Dialog } from "@material-ui/core";

const StyledDialog = styled(Dialog)`
  ${({ theme }) => `
      .header {
        padding: ${theme.spacing(1.5)}px ${theme.spacing(2)}px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        justify-content: space-between;
        & h6 {
          color: rgba(0,0,0,.9);
          font-size: 14px;
          font-weight: 700;
        }
      }

      .form-box {
        display: flex;

        .form-item {
          display: flex;
          flex-direction: column;
          flex: 1;

          &:last-child {
            border-left: 1px solid rgba(0, 0, 0, 0.12);
          }
        }

        .border {
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
      }

      .listItemText .content {
        display: flex;
        align-items: center;

        > div {
          display: flex;
          width: 100%;
        }

        .fi, strong {
          margin-right: ${theme.spacing(2)}px;
        }

        .inputField {
          margin-right: ${theme.spacing(2)}px;
        }
      }

      .dialogActions {
        padding: ${theme.spacing()};
        background-color: rgba(0,0,0,.02);
        justify-content: center
        max-width: 600px;
      }

      .MuiDialog-paperWidthSm {
        max-width: 1000px;
    }
    `}
`;

export default StyledDialog;
