import PropTypes from "prop-types";

// Components
import Chip from "@material-ui/core/Chip";

// Styles
import StyledComponent from "./style";

const Label = ({ name, values }) => (
  <>
    <span className="name">{name}:</span>
    {values.map((value) => (
      <span className="value" key={value}>
        {value},
      </span>
    ))}
  </>
);

const GroupedSelectedFilters = (props) => {
  const { filters } = props;
  const [first] = filters;

  return (
    <StyledComponent>
      <Chip
        size="small"
        label={
          <Label
            name={first?.name}
            values={filters?.map(({ value }) => value)}
          />
        }
      />
    </StyledComponent>
  );
};

GroupedSelectedFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string,
      remove: PropTypes.func,
    })
  ),
};

export default GroupedSelectedFilters;
