import { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import { Form, Field } from "formik";

import { getChildAssets } from "../../Asset/services";
import { Fragment } from "../../../components";
import * as assetActions from "../../Asset/Asset.actions";
import styles from "./styles";

class InboxDialogView extends Component {
  constructor(props) {
    super(props);

    const { loadAssets, setSelectedAssetType } = this.props;

    setSelectedAssetType("video,series");
    loadAssets();

    this.state = {
      seasons: [],
      episodes: [],
      fragments: [],
    };
  }

  componentWillUnmount() {
    clearTimeout(this.assetsTimer);
  }

  setAsset(newValue) {
    const { setFieldValue } = this.props;

    if (newValue) {
      getChildAssets(newValue.id, "season").then((response) => {
        if ((newValue.channel.id, newValue.id)) {
          this.setState({
            seasons: response.data,
          });

          setFieldValue("asset", newValue);
          setFieldValue("season", null);
          setFieldValue("episode", null);
          this.setCurrentFragment(newValue);
        }
      });
    } else {
      setFieldValue("asset", null);
      this.setCurrentFragment(null);
    }
  }

  setSeason(newValue) {
    const { setFieldValue, values } = this.props;

    if (newValue) {
      getChildAssets(newValue.id, "episode").then((response) => {
        if (newValue.id) {
          this.setState({
            episodes: response.data,
          });
          setFieldValue("season", newValue);
          setFieldValue("episode", null);
          this.setCurrentFragment(newValue);
        }
      });
    } else {
      setFieldValue("season", null);
      setFieldValue("episode", null);
      this.setCurrentFragment(values.asset);
    }
  }

  setEpisode(newValue) {
    const { setFieldValue, values } = this.props;

    if (newValue && newValue.id) {
      setFieldValue("episode", newValue);
      this.setCurrentFragment(newValue);
    } else {
      this.setCurrentFragment(values.season);
      setFieldValue("episode", null);
    }
  }

  setCurrentFragment(asset) {
    const { setFieldValue } = this.props;

    if (asset) {
      this.setState({ fragments: asset.fragments });
      setFieldValue(
        "fragment",
        asset.fragments.length > 0
          ? asset.fragments[0]
          : { id: null, name: "Aanmaken" }
      );
    } else {
      this.setState({ fragments: [] });
      setFieldValue("fragment", null);
    }
  }

  handleAssetChange = (event) => {
    const { loadAssets } = this.props;
    const searchInput = event.target.value;

    clearTimeout(this.assetsTimer);

    this.assetsTimer = setTimeout(() => {
      loadAssets(searchInput);
    }, 300);
  };

  handleChangeTrailer = (event) => {
    const { setFieldValue } = this.props;

    setFieldValue("fragment_trailer", event.target.checked);

    if (event.target.checked) {
      setFieldValue("fragment_title", "Trailer");
      setFieldValue("fragment_identifier", "trailer");
    } else {
      setFieldValue("fragment_title", "");
      setFieldValue("fragment_identifier", "");
    }
  };

  render() {
    const { assets, values, setFieldValue, errors } = this.props;
    const { fragments, seasons, episodes } = this.state;

    let type = values.asset ? values.asset.type : null;
    let assetSize = 12;

    if (values.asset && values.asset.type && values.asset.type === "series") {
      assetSize = 6;
    }

    if (values.asset && values.season) {
      assetSize = 4;
    }

    if (values.season) {
      type = "season";
    }

    if (values.episode) {
      type = "episode";
    }

    return (
      <DialogContent>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={assetSize}>
              <Autocomplete
                id="asset"
                autoHighlight
                loading={assets.loading}
                options={assets.data || []}
                getOptionLabel={(option) => option?.name || option?.identifier}
                onChange={(event, newValue) => this.setAsset(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    label="Video/Serie"
                    variant="outlined"
                    fullWidth
                    onChange={this.handleAssetChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {assets.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            {values.asset &&
              values.asset.type &&
              values.asset.type === "series" && (
                <Grid item xs={values.season ? 4 : 6}>
                  <Field
                    autoHighlight
                    component={Autocomplete}
                    getOptionLabel={(option) => option.name}
                    id="season"
                    name="Seizoen"
                    options={seasons.data}
                    value={values.serie}
                    onChange={(event, newValue) => this.setSeason(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Seizoen"
                        style={{ marginBottom: 16 }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              )}

            {values.asset && values.season && (
              <Grid item xs={4}>
                <Field
                  autoHighlight
                  component={Autocomplete}
                  getOptionLabel={(option) => `${option.index}: ${option.name}`}
                  id="episodes"
                  name="Episodes"
                  value={values.episode}
                  options={episodes.data}
                  onChange={(event, newValue) => this.setEpisode(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Episodes"
                      style={{ marginBottom: 16 }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}

            {values.asset && (
              <Fragment>
                <Grid item xs={12}>
                  <Field
                    component={Autocomplete}
                    id="type"
                    name="type"
                    options={["video", "serie", "season", "episode"]}
                    disabled
                    value={type}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        style={{ marginBottom: 16 }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={Autocomplete}
                    id="fragment"
                    name="fragment"
                    options={[{ id: null, name: "Aanmaken" }, ...fragments]}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) =>
                      setFieldValue(
                        "fragment",
                        newValue || { id: null, name: "Aanmaken" }
                      )
                    }
                    value={values.fragment}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fragment"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                {values.fragment && values.fragment.id === null && (
                  <Fragment>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        error={_.includes(_.keys(errors), "fragment_title")}
                        fullWidth
                        required
                        id="title"
                        label="Fragment titel"
                        margin="normal"
                        value={values.fragment_title || ""}
                        onChange={(event) =>
                          setFieldValue("fragment_title", event.target.value)
                        }
                      />
                      <FormHelperText
                        error={_.includes(_.keys(errors), "fragment_title")}
                      >
                        {_.includes(_.keys(errors), "fragment_title")
                          ? errors.fragment_title
                          : ""}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        error={_.includes(
                          _.keys(errors),
                          "fragment_identifier"
                        )}
                        fullWidth
                        required
                        id="title"
                        label="Fragment identifier"
                        margin="normal"
                        value={values.fragment_identifier || ""}
                        onChange={(event) =>
                          setFieldValue(
                            "fragment_identifier",
                            event.target.value
                          )
                        }
                      />
                      <FormHelperText
                        error={_.includes(
                          _.keys(errors),
                          "fragment_identifier"
                        )}
                      >
                        {_.includes(_.keys(errors), "fragment_title")
                          ? errors.fragment_title
                          : ""}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={values.fragment_trailer || false}
                              color="primary"
                              onChange={this.handleChangeTrailer}
                            />
                          }
                          label="Trailer"
                        />
                      </FormGroup>
                    </Grid>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Grid>
        </Form>
      </DialogContent>
    );
  }
}

const mapStateToProps = (state) => ({
  assets: state.asset,
  channel: state.channel,
});

const mapDispatchToProps = (dispatch) => ({
  loadAssets: (type) => dispatch(assetActions.find(type)),
  setSelectedAssetType: (type) => dispatch(assetActions.setSelectedType(type)),
});

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(InboxDialogView);
