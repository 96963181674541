import moment from "moment";
import * as Constants from "../Common/constants";

const TODAY = moment().set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});

export const changeIdentifier = (name, length) => {
  const maxLength = length || Constants.IDENTIFIER_MAX_LENGTH;

  if (name) {
    return name
      .toLowerCase()
      .replace(/[^\w- ]+/g, "")
      .replace(/ +/g, "-")
      .substring(0, maxLength);
  }

  return null;
};

export const publishDateColor = (
  publishDate,
  assetCompleted,
  currentDateTime = TODAY
) => {
  if (!moment.isMoment(currentDateTime))
    throw new Error("current time should be a moment object");
  if (!publishDate || !moment.isMoment(publishDate) || !publishDate.isValid())
    return null;

  if (publishDate.isBefore(currentDateTime) && !assetCompleted) return "error";
  if (publishDate.isSameOrAfter(currentDateTime)) return "blue";
  return null;
};
