import styled from "styled-components";

const StyledComponent = styled.section`
  display: flex;
  box-sizing: border-box;
  border: 1px dashed ${({ theme }) => theme.palette.grey[300]};
  flex-direction: column;
  padding: 12px;
  border-radius: 4px;
  margin: 4px 0;
  background: ${({ backgroundColor }) => backgroundColor};
  align-items: flex-start;

  & header {
    display: flex;
    align-content: space-between;
    flex: 1;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }

  & .poster-label {
    margin-left: 0;
  }

  & .editor {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
  }

  & .color-buttons {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
  }

  & .color-circle-button {
    display: flex;
    align-items: center;
    border: 0;
    background: 0;
    cursor: pointer;
    outline: none;
    margin-right: 16px;
    padding-left: 0;

    &.hasError {
      & h3 {
        color: ${({ theme }) => theme.palette.error.main};
      }
    }

    &:focus {
      & h3 {
        font-weight: bold;
        color: ${({ theme }) => theme.palette.primary.main};
      }

      & > div {
        border-color: ${({ theme }) => theme.palette.primary.main};
      }
    }

    & > div {
      margin: 0 8px 0 0;
    }
  }

  & .color-errors {
    padding-left: 24px;
    color: ${({ theme }) => theme.palette.error.main};

    & li > p {
      color: ${({ theme }) => theme.palette.error.main};
      font-size: 0.75rem;
    }
  }
`;

export default StyledComponent;
