import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";

// Style
import StyledComponent from "./style";

const CircularProgressCenter = ({ type, size }) => (
  <StyledComponent className={type}>
    <CircularProgress size={size} />
  </StyledComponent>
);

CircularProgressCenter.defaultProps = {
  type: "overlay",
  size: 40,
};

CircularProgressCenter.propTypes = {
  type: PropTypes.oneOf(["overlay", "inline"]),
  size: PropTypes.number,
};

export default CircularProgressCenter;
