import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Icons
import ImportExportIcon from "@material-ui/icons/ImportExport";
import HomeIcon from "@material-ui/icons/Home";

// Components
import ListNav from "@material-ui/core/List";
import NavMenuLink from "./NavMenuLink";
import { Button } from "components";

// Services
import { getFragmentMetaValues } from "../services";
import { getAssetById } from "features/Asset/services";

// Style
import StyledComponent, {
  StyledPopOver,
} from "features/Asset/AssetDetailSeriesSidebar/style";
import { useSnackbar } from "notistack";

const formatIndex = (index) => (index < 10 ? `0${+index}` : +index);

const DEFAULT_LANGUAGE = { code: "nl" };

const MetaSelector = ({ series, locale, channelId, setMeta }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const { seasons } = series;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onItemClick = async (id) => {
    setAnchorEl(null);
    setLoading(true);

    let fragmentId = null;

    // Get Fragments
    const { data: assetData } = await getAssetById(id);

    if (assetData.fragments.length > 0) {
      const filterdFragment = assetData.fragments.filter(
        (fragment) => fragment.trailer === 0
      );

      if (filterdFragment.length > 0) fragmentId = filterdFragment[0].id;
    }

    if (fragmentId) {
      const { data: metaFields } = await getFragmentMetaValues(fragmentId);

      setMeta(metaFields.data);
    } else {
      enqueueSnackbar(t("text.NO_FRAGMENT_FOUND"), {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleOpen = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button
        trackName="Fragment Copy meta"
        variant="contained"
        color="default"
        startIcon={<ImportExportIcon />}
        onClick={handleOpen}
        loading={loading}
      >
        Copy meta
      </Button>
      <StyledPopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ListNav component="nav" aria-label="">
          <NavMenuLink
            key="series-home-link"
            id={series.id}
            leftView={
              <HomeIcon
                fontSize="small"
                style={{ fontSize: "1rem", marginLeft: 0, marginRight: 12 }}
              />
            }
            name={series.name}
            index={series.index}
            channelId={channelId}
            identifier={series.identifier}
            locale={locale}
            onClick={() => onItemClick(series.id)}
          />
          {seasons?.map((season) => (
            <Fragment key={`season-root-${season.id}`}>
              <NavMenuLink
                key={`season-${season.id}`}
                id={season.id}
                leftView={
                  <span className="index">
                    {t("labels.SEASON").substring(0, 1)}
                    {formatIndex(season.index)}
                  </span>
                }
                name={season.name}
                channelId={channelId}
                identifier={season.identifier}
                locale={locale}
                onClick={() => onItemClick(season.id)}
              />
              <nav>
                {season.episodes?.map((episode) => (
                  <NavMenuLink
                    key={`episode-${episode.id}`}
                    id={episode.id}
                    leftView={
                      <span className="index">
                        {t("labels.EPISODE").substring(0, 1)}
                        {formatIndex(episode.index)}
                        <div className="scrollAnchor" />
                      </span>
                    }
                    name={episode.name}
                    index={episode.index}
                    channelId={channelId}
                    locale={locale}
                    onClick={() => onItemClick(episode.id)}
                    identifier={episode.identifier}
                  />
                ))}
              </nav>
            </Fragment>
          ))}
        </ListNav>
      </StyledPopOver>
    </Fragment>
  );
};

const CopyMeta = ({ setMeta }) => {
  // Redux
  const { series } = useSelector((state) => state.asset.selected?.data);
  const channel = useSelector((state) => state.channel);
  const { code } =
    useSelector((state) => state.user?.user?.default_language) ||
    DEFAULT_LANGUAGE;
  if (!series) return null;

  return (
    <StyledComponent className="copyButton">
      <MetaSelector
        series={series}
        locale={code}
        setMeta={setMeta}
        channelId={channel?.selected}
      />
    </StyledComponent>
  );
};

export default CopyMeta;
