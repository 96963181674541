import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

/* Components */
import Checkbox from "./Checkbox";

const CheckboxWithControl = (props) => {
  const { control } = props;
  return (
    <Controller
      as={Checkbox}
      control={control}
      {...props}
      onChange={([event]) => {
        const { checked } = event.currentTarget;
        return checked;
      }}
    />
  );
};

CheckboxWithControl.displayName = "CheckboxWithControl";
CheckboxWithControl.propTypes = {
  control: PropTypes.shape({}).isRequired,
};

export default CheckboxWithControl;
