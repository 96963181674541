/* Components */
import Layout from "../../components/Layout";

/* Features */
import InboxOverview from "../../features/Inbox/InboxOverview";

const Page = ({ routes, ...props }) => (
  <Layout routes={routes}>
    <InboxOverview {...props} />
  </Layout>
);

export default Page;
