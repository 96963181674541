import PropTypes from "prop-types";

// Icons
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

// Components
import Button from "components/Button";

// Styles
import StyledComponent from "./style";

const ErrorsDisplay = (props) => {
  const { errorLabel, resetSearchFn, tryAgainLabel } = props;

  const onResetClick = () => {
    resetSearchFn();
  };

  return (
    <StyledComponent data-qa="no-hits">
      <div className="container">
        <ErrorOutlineIcon />
        {errorLabel}
        <Button
          color="default"
          variant="outlined"
          trackName="assetoverview.search.error.retryButton"
          onClick={onResetClick}
        >
          {tryAgainLabel}
        </Button>
      </div>
    </StyledComponent>
  );
};

ErrorsDisplay.propTypes = {
  errorLabel: PropTypes.string.isRequired,
  resetSearchFn: PropTypes.func.isRequired,
  tryAgainLabel: PropTypes.string.isRequired,
};

export default ErrorsDisplay;
