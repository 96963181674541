import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const StyledNoSubtitles = styled.div`
  display: flex;
  flex: 1;
  padding: 8px 16px;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.palette.grey[300]};
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.grey[600]};

  & .MuiSvgIcon-root {
    margin-right: 8px;
  }
`;

const StyledComponent = styled(Grid)`
  video {
    height: 360px;
  }

  .MuiGrid-item {
    background: white;
    padding: 32px;

    &.column-left {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.palette.grey[200]};
      border-radius: 4px 0 0 4px;
    }

    &.column-right {
      padding: 32px;
      border-radius: 4px 0 0 4px;

      & .download-button {
        margin-top: 24px;
      }
    }
  }

  @media (min-width: 960px) {
    .column-left {
      max-width: 420px;
    }
    .column-left,
    .column-right {
      margin: 20px 0;
    }
  }

  header {
    margin-bottom: 32px;

    .MuiTypography-h5 {
      font-size: 1rem;
    }

    .MuiTypography-body1 {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.palette.grey[700]};
    }
  }

  header.welcome > .MuiTypography-body1 {
    margin-top: 12px;
  }

  .welcome > .asset-info {
    background: white;
  }

  .asset {
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 24px 16px 16px 16px;
    box-sizing: border-box;
    margin-bottom: 64px;
    position: relative;

    .MuiChip-root {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
    }
  }

  .info {
    margin: 24px 0;
    color: ${({ theme }) => theme.palette.grey[700]};

    .MuiTypography-root {
      font-size: 0.875rem;
    }
  }

  .asset-download {
    display: block;
    margin-bottom: 24px;
  }
  .asset-info {
    display: block;
    margin-bottom: 24px;
    margin-top: 32px;
  }

  .asset-download,
  .asset-info {
    & > .MuiTypography-body1 {
      color: ${({ theme }) => theme.palette.grey[700]};
      font-size: 0.875rem;
      margin-bottom: 8px;
    }

    .MuiBreadcrumbs-root .MuiTypography-root {
      font-size: 0.75rem;
    }

    .MuiTypography-h6 {
      line-height: 0.8;
    }

    & .header {
      display: flex;
      flex-direction: column;
    }

    .MuiTypography-root.active {
      font-weight: bold;
    }

    .MuiChip-root {
      border-radius: 4px;
      margin-bottom: 8px;
    }
  }

  .subtitles {
    & > .MuiTypography-body1 {
      color: ${({ theme }) => theme.palette.grey[700]};
      font-size: 0.875rem;
      margin-bottom: 8px;
    }
  }

  .MuiFormControlLabel-label,
  .MuiCheckbox-root {
    color: ${({ theme }) => theme.palette.grey[700]};

    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.grey[700]};
    }
  }

  .download-button {
    background: white;
    border: 1px dashed ${({ theme }) => theme.palette.grey[300]};
    border-radius: 4px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    color: black;
    text-decoration: none;

    & .downloading {
      display: flex;
      align-items: center;
    }

    .details p {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.palette.grey[500]};
    }

    .details .created {
      display: flex;
      flex-direction: row;
    }

    & .fi,
    & .MuiSvgIcon-root {
      margin-right: 16px;
    }

    & .MuiTypography-root {
      font-size: 0.875rem;
      line-height: unset;
      word-break: break-word;
    }

    & > .link-info {
      display: flex;
      align-items: center;
    }

    .download-icon {
      margin-right: 0;
      margin-left: 8px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }

  .button {
    width: auto;
  }

  .btn-approved > .button {
    font-weight: bold;
    width: 100%;
    background: ${({ theme }) => theme.palette.status.approved.main};
  }

  footer {
    margin-top: 24px;
  }

  .expires-message {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.error.main};
    margin-top: 32px;

    .MuiSvgIcon-root {
      font-size: 1rem;
      margin-right: 4px;
    }
  }
`;

export default StyledComponent;
