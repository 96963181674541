const style = {
  layoutFill: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    width: "100%",
    flexGrow: 1,
  },
};

export default style;
