import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getEntryList, PAGE_SIZE } from "./SeoEntryPage.service";
import SeoEntryPageSidebar from "./SeoEntryPageSidebar";
import { useSeoEntry } from "../Hooks/useSeoEntry";
import { Chip } from "@material-ui/core";
import { STATE_COLORS } from "./constants";

const DEFAULT_LANGUAGE = { code: "nl" };
const SeoEntryPageList = ({ seoEntryList, query, errorMessage }) => {
  const { loading, setLoading, checkedFilters, selectedStateFilterList } =
    useSeoEntry();
  const [rows, setRows] = useState(seoEntryList.data);
  const [rowCountState, setRowCountState] = useState(seoEntryList.meta.total);
  const { t } = useTranslation();
  const history = useHistory();
  const { channelId } = useParams();
  const [page, setPage] = useState(seoEntryList.meta.current_page - 1);
  const { code } =
    useSelector((state) => state.user?.user?.default_language) ||
    DEFAULT_LANGUAGE;
  const [sorting, setSorting] = useState([
    { field: `title.${code}`, sort: "asc" },
  ]);

  const columns = [
    {
      field: `title.${code}`,
      headerName: "Title",
      sortable: query === " ",
      flex: 1,
    },
    {
      field: "type.name",
      headerName: "Content Type",
      sortable: query === " ",
      flex: 1,
    },
    {
      field: "state",
      headerName: "State",
      sortable: query === " ",
      flex: 1,
      renderCell: (item) => (
        <Chip
          label={item.value}
          style={{
            backgroundColor: STATE_COLORS[item.value].main,
            color: STATE_COLORS[item.value].contrastText,
            textTransform: "uppercase",
            padding: "4px 0",
            fontSize: "10px",
            fontWeight: "bold",
            borderRadius: "4px",
            height: "auto",
            width: "auto",
            size: "small",
          }}
        />
      ),
    },
  ];

  const arrangeData = useCallback(
    (data) => {
      return data.map(
        ({ id, content: { title }, contentType: { name }, state }) => {
          return {
            id,
            [`title.${code}`]: title[code],
            "type.name": name,
            state,
          };
        }
      );
    },
    [code]
  );

  const handleSortModelChange = useCallback(
    (sortModel) => {
      if (!Array.isArray(sortModel) || sortModel.length === 0) {
        return;
      }

      setLoading(true);
      setSorting(sortModel);
      getEntryList({
        query: query,
        page: page + 1,
        sortModel,
        filters: {
          type: checkedFilters,
          state: selectedStateFilterList,
        },
      }).then((res) => {
        const list = arrangeData(res.data.data);
        setRows(list);
        setLoading(false);
      });
    },
    [
      arrangeData,
      checkedFilters,
      page,
      query,
      selectedStateFilterList,
      setLoading,
    ]
  );

  useEffect(() => {
    const list = arrangeData(seoEntryList.data);

    setRows(list);
    setRowCountState(seoEntryList.meta.total);
  }, [arrangeData, code, seoEntryList, setRows]);

  if (!rows) return null;

  const handlePageChange = (newPage) => {
    setLoading(true);
    getEntryList({
      query: query || "",
      page: newPage + 1,
      sortModel: sorting,
      filters: { type: checkedFilters, state: selectedStateFilterList },
    }).then((res) => {
      const list = arrangeData(res.data.data);
      setRows(list);
      setLoading(false);
    });

    setPage(newPage);
  };

  const onRowClick = (id) => {
    history.push(`/channel/${channelId}/seo/${id.id}`);
  };

  return (
    <div className="entryPageGrid">
      <SeoEntryPageSidebar seoEntryList={seoEntryList} />
      <div className="entryListScrollbar">
        <DataGrid
          initialState={{
            sorting: {
              sortModel: sorting,
            },
          }}
          style={{ border: "none" }}
          autoHeight
          loading={loading}
          rowCount={rowCountState}
          rows={rows}
          page={page}
          columns={columns}
          disableExtendRowFullWidth
          onRowClick={onRowClick}
          paginationMode="server"
          sortingMode="server"
          pagination
          components={{
            NoRowsOverlay: () => (
              <div className="errorMessage">{t("text.NOT_FOUND")}</div>
            ),
            ErrorOverlay: () => (
              <div className="errorMessage">{t("text.AN_ERROR_OCCURED")}</div>
            ),
          }}
          onSortModelChange={handleSortModelChange}
          error={errorMessage}
          pageSize={PAGE_SIZE}
          rowsPerPageOptions={[PAGE_SIZE]}
          onPageChange={(newPage) => handlePageChange(newPage)}
        />
      </div>
    </div>
  );
};

export default SeoEntryPageList;
