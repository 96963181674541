import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import moment from "moment";

import translationsNlNl from "./locales/nl_NL.json";
import translationsEnGb from "./locales/en_GB.json";
import translationsNbNo from "./locales/nb_NO.json";
import translationsSvSE from "./locales/sv_SE.json";

const resources = {
  nl: { translation: translationsNlNl },
  en: { translation: translationsEnGb },
  nb: { translation: translationsNbNo },
  sv: { translation: translationsSvSE },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "nl",
  interpolation: {
    escapeValue: false,
  },
});

// change moment language on i18next languageChange event
i18n.on("languageChanged", (lng) => moment.locale(lng));

// set initial moment language
moment.locale(i18n.lng);

export default i18n;
