import React from "react";
import { useSnackbar } from "notistack";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import { usePersistentState } from "features/Common/hooks";
import SeoDetailLanguages from "./SeoDetailLanguages";
import SeoDetailPublishButton from "./SeoDetailPublishButton";
import SeoDetailSubmit from "./SeoDetailSubmit";
import { publishSeoEntry, unpublishSeoEntry } from "../services";
import { useSeoDetail } from "../../Hooks/useSeoDetail";

import StyledGrid from "../../SeoEntryPage/SeoEntryPageSidebar.style";
import ResizePanel from "components/ResizablePanel/ResizablePanel";

const DEFAULT_WIDTH = 235;
const SeoDetailSideBar = ({ onSubmit, isDirty }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [sideBarWidth, setSidebarWidth] = usePersistentState(
    DEFAULT_WIDTH,
    "seo_detail_sidebar"
  );
  const { entry, setEntry, setPublishLoading, seoEntryId } = useSeoDetail();

  const onDragStop = ({ size }) => {
    setSidebarWidth(size);
  };

  const publish = async () => {
    setPublishLoading(true);

    try {
      const {
        data: { data: response },
      } = await publishSeoEntry(seoEntryId);

      setEntry(response);
    } catch (e) {
      if (e?.response?.data?.message) {
        enqueueSnackbar(e?.response?.data?.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Error.", {
          variant: "error",
        });
      }
    }

    setPublishLoading(false);
  };

  const unpublish = async () => {
    setPublishLoading(true);

    try {
      const {
        data: { data: response },
      } = await unpublishSeoEntry(seoEntryId);

      setEntry(response);
    } catch (e) {
      if (e?.response?.data?.message) {
        enqueueSnackbar(e?.response?.data?.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Error.", {
          variant: "error",
        });
      }
    }

    setPublishLoading(false);
  };

  return (
    <ResizePanel
      direction="e"
      onDragStop={onDragStop}
      initialSize={sideBarWidth}
      minimumSize={235}
      maximumSize={500}
      overFlowCorrection={false}
    >
      <StyledGrid>
        <div className="filterGrid">
          <FormControl component="fieldset">
            <FormGroup>
              <SeoDetailLanguages isDirty={isDirty} />
              <SeoDetailPublishButton
                entry={entry}
                isDirty={isDirty}
                publish={publish}
                unpublish={unpublish}
              />
              {/* <SeoDetailSnapshot isDirty={isDirty} /> */}
              <SeoDetailSubmit onSubmit={onSubmit} isDirty={isDirty} />
            </FormGroup>
          </FormControl>
        </div>
      </StyledGrid>
    </ResizePanel>
  );
};

export default SeoDetailSideBar;
