import PropTypes from "prop-types";

// Components
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const AssetHead = ({
  children,
  className,
  keySelector,
  orderBy,
  order,
  onSort,
}) => (
  <TableCell className={className}>
    <TableSortLabel
      active={orderBy === keySelector}
      direction={orderBy === keySelector ? order : "asc"}
      onClick={onSort(keySelector)}
    >
      {children}
    </TableSortLabel>
  </TableCell>
);

AssetHead.defaultProps = {
  onSort: () => null,
};

AssetHead.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  keySelector: PropTypes.string,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  onSort: PropTypes.func,
};

export default AssetHead;
