import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { PropTypes } from "prop-types";

// Components
import { useDropzone } from "react-dropzone";
import CheckMark from "components/CheckMarkLoader";
import ImagePreview from "./ImagePreview";

// Style
import StyledSection, { StyledThumbs } from "./style";

const Thumbs = ({ files, multiple }) => {
  if (!files || !files.length || !multiple) {
    return null;
  }

  return (
    <StyledThumbs>
      {files?.map((file) => (
        <div key={file.name}>
          <div>
            <img
              src={file.preview}
              alt="preview thumb"
              width={100}
              height={100}
            />
          </div>
        </div>
      ))}
    </StyledThumbs>
  );
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const DropZone = forwardRef((props, ref) => {
  const {
    t,
    accept,
    disabled,
    maxSize,
    multiple = false,
    defaultFiles,
    state,
    placeholderText,
    onDropAccepted,
    onDropRejected,
    onDelete,
    error,
    className,
    width,
    height,
    checkMarkConfig,
    PreviewComponent,
    children,
  } = props;
  const [files, setFiles] = useState(defaultFiles || []);
  const [dragOver, setDragOver] = useState(false);
  const [fileError, setFileError] = useState();
  const [fileState, setFileState] = useState(state);

  const getErrorMessage = (fileRejection) => {
    const { size } = fileRejection;
    const sizeFormatted = formatBytes(size, 2);
    const maxSizeFormatted = formatBytes(maxSize, 2);

    if (size > maxSize) {
      return t("error.IMAGE_SIZE", {
        size: sizeFormatted,
        maxSize: maxSizeFormatted,
      });
    }
    return t("error.IMAGE_UPLOAD");
  };

  const onDropRejectedHandle = (fileRejections) => {
    const errors = fileRejections?.map((fileRejection) => {
      const errorMessage = getErrorMessage(fileRejection);
      return (
        <div className="error-box" key={fileRejection.name}>
          <h6>{fileRejection.name}</h6>
          <p>{errorMessage}</p>
        </div>
      );
    });

    setDragOver(false);
    setFileError(errors);
    onDropRejected(fileRejections);
  };

  const onDropAcceptedHandle = (acceptedFiles) => {
    const previewFiles = acceptedFiles.map((file) => ({
      ...file,
      preview: URL.createObjectURL(file),
    }));

    setDragOver(false);
    setFiles(previewFiles);
    onDropAccepted(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...props,
    accept,
    disabled,
    maxSize,
    multiple,
    onDropRejected: onDropRejectedHandle,
    onDropAccepted: onDropAcceptedHandle,
    onDragOver: () => setDragOver(true),
    onDragLeave: () => setDragOver(false),
  });

  useEffect(
    () =>
      // Cleanup: Make sure to revoke the data uris to avoid memory leaks
      () => {
        files.forEach((file) => URL.revokeObjectURL(file.preview));
      },
    [files]
  );

  // Update error state
  useEffect(() => {
    setFileError(error);
    if (error) {
      setFileState("error");
    }
  }, [error, setFileError, setFileState]);

  // Update dropZone state
  useEffect(() => {
    setFileState(state);
  }, [state, setFileState]);

  useEffect(() => {
    setFiles(defaultFiles || []);
  }, [defaultFiles, setFiles]);

  // Expose markAsComplete to outside world using ref
  useImperativeHandle(ref, () => ({
    markAsComplete: () => {
      setFileState("completed");
    },
  }));

  return (
    <StyledSection
      className={`${className} ${fileState} ${dragOver ? "drag-over" : ""}`}
    >
      <div
        {...getRootProps()}
        className={`${disabled ? "drop-zone disabled" : "drop-zone"}`}
        style={{ width, height }}
      >
        <CheckMark state={fileState} {...checkMarkConfig} />
        {PreviewComponent && (
          <PreviewComponent
            file={files[0]}
            onDelete={onDelete}
            multiple={multiple}
            fileState={fileState}
          />
        )}
        {!PreviewComponent && (
          <ImagePreview
            image={files[0]?.preview}
            onDelete={onDelete}
            multiple={multiple}
            fileState={fileState}
          />
        )}
        {children}
        <input {...getInputProps()} />
        <span className="text">{placeholderText}</span>
      </div>
      <Thumbs files={files} multiple={multiple} />
      {fileError && <div className="error">{fileError}</div>}
    </StyledSection>
  );
});

DropZone.displayName = "DropZone";
DropZone.defaultProps = {
  placeholderText: "",
  onDropAccepted: () => {},
  onDropRejected: () => {},
  onDelete: null,
  error: null,
  t: (key) => key,
  className: "",
  state: "idle",
  defaultFiles: [],
  PreviewComponent: null,
  checkMarkConfig: {
    size: 40,
    borderColor: "#0003",
    borderWidth: 2,
    checkThickness: 3,
    backgroundColor: "transparent",
    successColor: "green",
    successBackground: "darkgreen",
    successBorderColor: "darkgreen",
  },
};

DropZone.propTypes = {
  placeholderText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  accept: PropTypes.oneOfType([
    PropTypes.oneOf(["audio/*", "image/*", "video/*"]),
    PropTypes.string,
  ]),
  t: PropTypes.func,
  checkMarkConfig: PropTypes.shape({
    size: PropTypes.number,
    borderColor: PropTypes.string,
    borderWidth: PropTypes.number,
    checkThickness: PropTypes.number,
    backgroundColor: PropTypes.string,
    successColor: PropTypes.string,
    successBackground: PropTypes.string,
    successBorderColor: PropTypes.string,
  }),
  PreviewComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  state: PropTypes.oneOf([
    "idle",
    "loading",
    "completed",
    "deleting",
    "deleted",
    "error",
  ]),
  disabled: PropTypes.bool,
  maxSize: PropTypes.number.isRequired,
  multiple: PropTypes.bool,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  defaultFiles: PropTypes.arrayOf(
    PropTypes.shape({
      preview: PropTypes.string,
    })
  ),
};

export default DropZone;
