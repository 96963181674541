/* eslint-disable react/display-name */
import { memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

// Icons
import EditIcon from "@material-ui/icons/Edit";

// Component
import PosterLabel from "components/PosterLabel";
import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Typography,
} from "@material-ui/core";

// Hooks
import { useQuery } from "features/Common/hooks";
import { useCurrentFragmentUrl } from "../hooks";

// Style
import StyledComponent from "./style";

const PANEL_NAME = "labels";

const FragmentLabels = ({ fragment }) => {
  const { id: fragmentId, labels } = fragment;

  const { t } = useTranslation();
  const url = useCurrentFragmentUrl({ fragmentId });
  const panelUrl = `${url}?panel=${PANEL_NAME}`;

  // Current Page
  const query = useQuery();
  const panel = query.get("panel");
  const isActive = () => panel === PANEL_NAME;

  return (
    <StyledComponent>
      <NavLink to={panelUrl} activeClassName="activeEdit" isActive={isActive}>
        <Card>
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <EditIcon />
              </IconButton>
            }
            title={t("labels.LABELS")}
          />
          <CardContent>
            <div className="labels">
              {labels?.map(({ id, text, textColor, backgroundColor }) => {
                return (
                  <PosterLabel
                    key={id}
                    textColor={textColor}
                    backgroundColor={backgroundColor}
                    text={text}
                  />
                );
              })}
            </div>
            {!labels?.length && (
              <Typography variant="body1">{t("labels.NO_LABELS")}</Typography>
            )}
          </CardContent>
        </Card>
      </NavLink>
    </StyledComponent>
  );
};

FragmentLabels.defaultProps = {
  fragment: null,
};

FragmentLabels.propTypes = {
  fragment: PropTypes.shape({}),
};

export default memo(FragmentLabels);
