import createReducer from "../../redux/createReducer";
import * as types from "./Asset.types";
import * as reducers from "./Asset.reducers";
import { INITIAL_STATE } from "./Asset.state";

export const reducer = createReducer(INITIAL_STATE, {
  [types.FIND]: reducers.find,
  [types.FIND_MORE]: reducers.findMore,
  [types.FIND_SUCCESS]: reducers.findSuccess,
  [types.FIND_FAILURE]: reducers.findFailure,
  [types.FRAGMENT_CREATED]: reducers.fragmentCreated,
  [types.RESET]: reducers.reset,
  [types.SET_SELECTED_TYPE]: reducers.setSelectedType,
  [types.FIND_ASSET]: reducers.findById,
  [types.FIND_ASSET_SUCCESS]: reducers.findByIdSuccess,
  [types.RESET_ASSET]: reducers.resetAsset,
  [types.SET_SORT]: reducers.setSort,
  [types.UPDATE_QUALITY_CHECKS]: reducers.updateQualityChecks,
  [types.PATCH_LABELS]: reducers.patchLabels,
  [types.PATCH_LABELS_SUCCESS]: reducers.patchLabelsSuccess,
  [types.PATCH_LABELS_FAILED]: reducers.patchLabelsFailed,
});
