import { memo } from "react";
import { useTranslation } from "react-i18next";

// Components
import Alert from "@material-ui/lab/Alert";
import CircularProgressCenter from "components/CircularProgressCenter";
import List from "@material-ui/core/List";
import Button from "components/Button";

// Local Components
import Revision from "../Revision";
import EmptyMessage from "../EmptyMessage";

const Revisions = ({
  fragmentId,
  locale,
  error,
  loading,
  paginatedData,
  loaded,
  total,
  loadMore,
  highlightFileId,
  onMenuItemClick,
}) => {
  const { t } = useTranslation();

  return (
    <section className="revisions">
      <List>
        {error && <Alert severity="error">{error}</Alert>}
        {!error && !paginatedData?.length && !loading && (
          <EmptyMessage message={t("text.NO_REVISIONS")} />
        )}
        {loading && <CircularProgressCenter size={20} variant="inline" />}
        {!loading &&
          paginatedData?.map(
            ({
              id,
              name,
              file,
              paths,
              created_at: createDate,
              uploaded_by: uploadedBy,
              status,
              locale,
            }) => (
              <Revision
                key={id}
                id={id}
                fragmentId={fragmentId}
                highlightFileId={highlightFileId}
                name={name}
                locale={locale}
                file={file}
                path={paths[0]}
                createDate={createDate}
                user={uploadedBy}
                status={status}
                onMenuItemClick={onMenuItemClick}
              />
            )
          )}
      </List>
      {loaded < total && (
        <>
          <p className="load-count">
            {t("labels.REVISIONS_LOADED", {
              loaded,
              total,
            })}
          </p>
          <Button
            variant="outlined"
            className="load-more"
            size="small"
            trackName="fragment.subtitles.revisions.loadmore"
            onClick={loadMore}
            trackDetails={{
              fragmentId,
              locale,
            }}
          >
            {t("buttons.LOAD_MORE")}
          </Button>
        </>
      )}
    </section>
  );
};

export default memo(Revisions);
