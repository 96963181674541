import { useContext } from "react";
import { SeoEntryContext } from "../SeoEntryPage/SeoEntryProvider";

export function useSeoEntry() {
  const context = useContext(SeoEntryContext);

  if (context === undefined) {
    console.log("no context");
  }

  return context;
}
