import { call, put, all, select } from "redux-saga/effects";

import * as assetApi2 from "./services";
import * as assetActions from "./Asset.actions";

export const getChannel = (state) => state.channel;
export const getAssetId = (state) => state.asset.selected.id;
export const getAsset = (state) => state.asset;

export function* loadAssets(assetApi) {
  try {
    const asset = yield select(getAsset);

    const assetsResponse = yield call(
      assetApi.getAssets,
      asset.query || "",
      asset.selectedType,
      1,
      asset.sortField,
      asset.sortDirection
    );

    if (!assetsResponse.status === 200) {
      return yield put(assetActions.findFailure());
    }

    const assets = assetsResponse.data;

    return yield put(assetActions.findSuccess(assets));
  } catch (error) {
    yield put(assetActions.findFailure());
  }

  return false;
}

export function* loadMoreAssets(assetApi) {
  try {
    const asset = yield select(getAsset);

    const assetsResponse = yield call(
      assetApi.getAssets,
      asset.query || "",
      asset.selectedType,
      asset.meta.current_page + 1,
      asset.sortField,
      asset.sortDirection
    );

    if (!assetsResponse.status === 200) {
      return yield put(assetActions.findFailure());
    }

    const allAssets = {
      ...assetsResponse.data,
      ...(asset.data !== null && {
        data: [...asset.data, ...assetsResponse.data.data],
      }),
    };

    return yield put(assetActions.findSuccess(allAssets));
  } catch (error) {
    yield put(assetActions.findFailure());
  }

  return false;
}

export function* getAssetById(assetApi, { assetId, history }) {
  const [assetsResponse, labelsResponse] = yield all([
    call(assetApi.getAssetById, assetId, history),
    call(assetApi.getLabels, assetId),
  ]);

  const data = {
    ...assetsResponse.data,
    labels: labelsResponse.data.data,
  };

  return yield put(assetActions.findByIdSuccess(data, history));
}

/**
 * Redirect to first fragment if episode or season
 */
export function* findByIdSuccess(assetApi, { history }) {
  try {
    const asset = yield select(getAsset);

    // No fragments found
    if (!asset.selected.data.fragments.length) {
      return false;
    }
    // Can't redirect if history is empty
    if (!history) {
      return false;
    }
    // Already redirected to fragment
    if (history.location.pathname.includes("/fragment/")) {
      return false;
    }

    const [firstFragment] = asset.selected.data.fragments;
    const firstFragmentUrl = `${history.location.pathname}/fragment/${firstFragment.id}`;
    history.replace(firstFragmentUrl);
    return true;
  } catch {
    return false;
  }
}

export function* patchLabels({ payload }) {
  try {
    const { id, labels } = payload;
    const result = yield call(assetApi2.patchLabels, id, {
      labels: labels || [],
    });
    yield put(assetActions.patchLabelsSuccess({ labels: result?.data?.data }));
  } catch (error) {
    yield put(assetActions.patchLabelsFailed(error));
  }
}

export function* reset() {
  const asset = yield select(getAsset);
  if (!asset.loading) return yield put(assetActions.reset());

  return false;
}

export function* updateQualityChecks({ payload }) {
  const { assetId } = payload;
  const state = yield select();
  const checks = state?.asset?.selected?.data?.checks;

  if (!checks) return;

  yield call(assetApi2.changeCheck, assetId, {
    id: assetId,
    quality_check: true,
    checks,
  });
}
