import { memo } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import MarkdownIt from "markdown-it";

// Icons
import EditIcon from "@material-ui/icons/Edit";

// Component
import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Typography,
} from "@material-ui/core";

// Hooks
import { useQuery } from "features/Common/hooks";
import { useCurrentFragmentUrl } from "../hooks";

// Style
import StyledComponent from "./style";

const mdParser = new MarkdownIt();
const PANEL_NAME = "info";

const FragmentInformation = ({ fragment, action }) => {
  const identifier =
    fragment.asset.identifier +
    (fragment.identifier ? `-${fragment.identifier}` : "");

  const { name, description, id } = fragment;
  const shortDescription = fragment.short_description;

  const url = useCurrentFragmentUrl({ fragmentId: id });
  const panelUrl = `${url}?panel=${PANEL_NAME}`;

  // Current Page
  const query = useQuery();
  const panel = query.get("panel");
  const isActive = () => panel === PANEL_NAME;

  return (
    <StyledComponent>
      <NavLink to={panelUrl} activeClassName="activeEdit" isActive={isActive}>
        <Card>
          <CardHeader
            action={
              <IconButton onClick={action} aria-label="settings">
                <EditIcon />
              </IconButton>
            }
            title={name}
            subheader={identifier}
          />
          {shortDescription && (
            <CardContent className="short-description">
              <Typography
                variant="body2"
                color="textSecondary"
                dangerouslySetInnerHTML={{
                  __html: mdParser.render(shortDescription),
                }}
              />
            </CardContent>
          )}
          {description && (
            <CardContent>
              <Typography
                variant="body2"
                color="textSecondary"
                dangerouslySetInnerHTML={{
                  __html: mdParser.render(description),
                }}
              />
            </CardContent>
          )}
        </Card>
      </NavLink>
    </StyledComponent>
  );
};

FragmentInformation.defaultProps = {
  action: () => null,
};

FragmentInformation.propTypes = {
  action: PropTypes.func,
};

export default memo(FragmentInformation);
