import { Component } from "react";
import PropTypes from "prop-types";

import { compose } from "redux";

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import Event from "./Event";

import styles from "./TimeLine.style";

class Timeline extends Component {
  renderEvents = (logs) =>
    logs.map((event) => <Event key={event.id} event={event} {...this.props} />);

  render() {
    const { logs, jobs, loading } = this.props;
    const { classes } = this.props;

    let allLogs = logs;

    if (jobs) {
      allLogs = jobs.reduce((result, job) => [...result, ...job.logs], []);
    }

    if (loading) {
      return (
        <div
          className={classes.container}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className={classes.container}>
        {allLogs && allLogs.length > 0 && (
          <div className={classes.timeline}>{this.renderEvents(allLogs)}</div>
        )}
      </div>
    );
  }
}

Timeline.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
};

export default compose(withStyles(styles), withTranslation())(Timeline);
