import * as React from "react";

function SvgJson(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <path d="M18 23l3 3 10-10L21 6l-3 3 7 7zM14 9l-3-3L1 16l10 10 3-3-7-7z" />
    </svg>
  );
}

export default SvgJson;
