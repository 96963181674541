import { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import classNames from "classnames";

// Components
import NationFlag from "components/NationFlag";
import StatusLabel from "components/StatusLabel";
import Typography from "@material-ui/core/Typography";

// Style
import StyledComponent from "./style";

const SubtitleDetailsPopover = ({ files }) => {
  const { t } = useTranslation();

  const subtitleStatusMapping = useMemo(() => {
    const subtitles =
      files?.filter((file) => file.type.type === "subtitle") || [];

    // nl: { active: 1 }
    const mapping = subtitles.reduce((result, subtitle) => {
      const { locale, status } = subtitle;
      return {
        ...result,
        [locale]: {
          ...result[locale],
          [status]: {
            name: status,
            count: (result[status] || 0) + 1,
          },
        },
      };
    }, {});

    return Object.entries(mapping);
  }, [files]);

  return (
    <StyledComponent>
      {subtitleStatusMapping?.length === 0 && (
        <Typography variant="body1">{t("text.NO_SUBTITLES")}</Typography>
      )}
      {subtitleStatusMapping &&
        subtitleStatusMapping.map(([countryCode, states]) => (
          <div className={classNames("country-info")} key={countryCode}>
            <header>
              <NationFlag countryCode={countryCode} />
            </header>
            <div>
              {Object.values(states)?.map(({ name: status, count }) => (
                <StatusLabel
                  key={status}
                  status={status}
                  label={t(`labels.${status?.toUpperCase()}`)}
                  count={count}
                />
              ))}
            </div>
          </div>
        ))}
    </StyledComponent>
  );
};

SubtitleDetailsPopover.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(SubtitleDetailsPopover);
