import { memo } from "react";
import PropTypes from "prop-types";

// Icons
import NotInterestedIcon from "@material-ui/icons/NotInterested";

// Style
import StyledComponent from "./style";

const EmptyMessage = ({ message }) => (
  <StyledComponent>
    <NotInterestedIcon />
    {message}
  </StyledComponent>
);

EmptyMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default memo(EmptyMessage);
