import PropTypes from "prop-types";

// Style
import StyledChip, { StyledBadge } from "./style";

const Badge = ({ count, status }) => {
  if (!count) return null;
  return <StyledBadge status={status}>{count}</StyledBadge>;
};

const StatusLabel = ({ status, label, count }) => (
  <StyledChip
    status={status}
    avatar={<Badge status={status} count={count} />}
    size="small"
    label={label}
  />
);

StatusLabel.defaultProps = {
  count: null,
};

StatusLabel.propTypes = {
  count: PropTypes.number,
  label: PropTypes.string,
  status: PropTypes.oneOf([
    "active",
    "inactive",
    "pending",
    "draft",
    "approved",
    "rejected",
  ]).isRequired,
};

export default StatusLabel;
