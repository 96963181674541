import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

// Icons
import LaunchIcon from "@material-ui/icons/Launch";

// Components
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LogDialog from "../../Log/LogDialog";

// Styles
import StyledComponent from "./style";

const nameInitials = (name) => {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  return initials;
};

const Logs = ({ asset }) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  if (!asset) {
    return null;
  }

  const { log } = asset;

  const onDialogOpen = () => {
    setDialogOpen(true);
  };
  const onDialogClose = () => {
    setDialogOpen(false);
  };

  if (!log) return null;

  return (
    <StyledComponent>
      <Tooltip
        title={`${t("text.LAST_MODIFIED_BY")} ${log.user.name}
          ${t("text.AT")} ${moment
          .unix(log.created_at)
          .format("D MMMM YYYY HH:mm:ss")}`}
      >
        <div>
          <header>
            <Typography variant="h6">{t("text.LAST_MODIFIED_BY")}</Typography>
            <IconButton onClick={onDialogOpen}>
              <LaunchIcon fontSize="small" />
            </IconButton>
          </header>
          <main>
            <Avatar>{nameInitials(log.user.name)}</Avatar>
            <div className="details">
              <Typography variant="h6">{log.user.name}</Typography>
              <span>
                {moment.unix(log.created_at).format("D MMMM YYYY HH:mm:ss")}
              </span>
            </div>
          </main>
        </div>
      </Tooltip>

      <LogDialog
        open={dialogOpen}
        onClose={onDialogClose}
        type="assets"
        id={asset.id}
        name={asset.name}
      />
    </StyledComponent>
  );
};

export default Logs;
