const namespace = "USER";

export const SET_LOGIN_ERROR = `${namespace}/SET_LOGIN_ERROR`;
export const GET_USER = `${namespace}/GET_USER`;
export const SET_USER = `${namespace}/SET_USER`;
export const SET_USER_LANGUAGE = `${namespace}/SET_USER_LANGUAGE`;
export const SET_USER_LANGUAGE_SUCCESS = `${namespace}/SET_USER_LANGUAGE_SUCCESS`;
export const SET_USER_COUNTRY = `${namespace}/SET_USER_COUNTRY`;
export const SET_USER_COUNTRY_SUCCESS = `${namespace}/SET_USER_COUNTRY_SUCCESS`;
export const SET_USER_TRANSLATE_LANGUAGE = `${namespace}/SET_USER_TRANSLATE_LANGUAGE`;
export const SET_USER_TRANSLATE_LANGUAGE_SUCCESS = `${namespace}/SET_USER_TRANSLATE_LANGUAGE_SUCCESS`;
export const LOGOUT = `${namespace}/LOGOUT`;
export const LOGOUT_SUCCESS = `${namespace}/LOGOUT_SUCCESS`;
