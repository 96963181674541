import { memo } from "react";
import PropTypes from "prop-types";

// Icons
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";

// Components
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";

const StatusCell = ({
  qualityCheck,
  checks,
  completed,
  language,
  onMoreInfoClick,
}) => {
  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onMoreInfoClick({ event, checks });
  };

  if (!qualityCheck) {
    return (
      <TableCell className="cell--completed" onClick={onClick}>
        <Typography variant="body1">-</Typography>
      </TableCell>
    );
  }
  return (
    <TableCell className="cell--completed">
      {qualityCheck && completed.includes(language) ? (
        <CheckCircleOutlineOutlinedIcon
          style={{ color: "green" }}
          fontSize="small"
          onClick={onClick}
        />
      ) : (
        <CancelOutlinedIcon
          style={{ color: "red" }}
          fontSize="small"
          onClick={onClick}
        />
      )}
    </TableCell>
  );
};

StatusCell.defaultProps = {
  onMoreInfoClick: () => null,
};

StatusCell.propTypes = {
  qualityCheck: PropTypes.bool,
  checks: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  completed: PropTypes.arrayOf(PropTypes.string),
  language: PropTypes.string,
  onMoreInfoClick: PropTypes.func,
};

export default memo(StatusCell);
