import { memo } from "react";
import PropTypes from "prop-types";

// Fragment Table Cell
import NameCell from "./NameCell";

// Styles
import StyledTableRow from "./style";

const FragmentRowPreview = ({
  fragment,
  highlight,
  onRowClick,
  assetId,
  type,
  className,
  locale,
  language,
}) => {
  const {
    id: fragmentId,
    identifier: fragmentIdentifier,
    name: fragmentName,
    trailer,
  } = fragment;

  const onHandleRowClick = (event) => {
    onRowClick({
      event,
      assetId,
      fragmentId,
      type,
    });
  };

  return (
    <StyledTableRow
      className={`row--fragment ${className}`}
      key={fragmentId}
      onClick={onHandleRowClick}
    >
      <NameCell
        name={fragmentName}
        locale={locale}
        language={language}
        highlight={highlight}
        identifier={fragmentIdentifier}
        trailer={trailer}
      />
    </StyledTableRow>
  );
};

FragmentRowPreview.defaultProps = {
  onRowClick: () => null,
  highlight: {},
};

FragmentRowPreview.propTypes = {
  assetId: PropTypes.number,
  className: PropTypes.string,
  fragment: PropTypes.shape({
    id: PropTypes.number,
    identifier: PropTypes.string,
    trailer: PropTypes.bool,
    name: PropTypes.shape({}),
  }),
  language: PropTypes.string,
  locale: PropTypes.string,
  onRowClick: PropTypes.func,
  type: PropTypes.oneOf(["video", "series"]),
  highlight: PropTypes.shape({}),
};

export default memo(FragmentRowPreview);
