import styled from "styled-components";
import Menu from "@material-ui/core/Menu";

export const StyledMenu = styled(Menu)`
  .MuiListItemIcon-root {
    min-width: 32px;
  }
`;

const StyledComponent = styled.div`
  margin-top: 12px;

  .MuiCard-root {
    box-shadow: none;
    background: ${({ theme }) => theme.palette.grey[100]};
  }

  header {
    display: flex;
    padding: ${({ theme }) =>
      `${theme.spacing()}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`};
    flex: 1;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.grey[400]};
        margin-right: 4px;

        &.active-icon {
          color: white;
        }
      }
    }

    h3 {
      font-size: 1.5rem;
      margin: ${({ theme }) =>
        `${theme.spacing()}px ${theme.spacing(2)}px 0 0`};
    }
  }

  .MuiCardHeader-root {
    padding-bottom: 0;
  }

  .MuiCardContent-root {
    display: flex;
    flex-wrap: wrap;
    padding: ${({ theme }) => `0 ${theme.spacing(3)}px`};
    margin: ${({ theme }) =>
      `${theme.spacing()}px -4px ${theme.spacing(3)}px -4px`};
    align-items: flex-start;
    flex-direction: column;

    & > p {
      display: flex;
      align-items: center;
      margin-right: ${({ theme }) => theme.spacing(2)}px;
      color: ${({ theme }) => theme.palette.grey[600]};
      white-space: nowrap;
    }

    & svg {
      font-size: 1rem;
      margin-right: ${({ theme }) => theme.spacing() / 2}px;
    }

    & strong {
      margin-left: ${({ theme }) => theme.spacing() / 2}px;
    }

    .MuiTouchRipple-root {
      text-transform: none;
    }

    .detail-buttons {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 0;

      .MuiButton-root {
        padding: ${({ theme }) =>
          `${theme.spacing(0.5)}px ${theme.spacing()}px`};
      }
    }
  }

  .container {
    display: flex;
    flex: 1;
    width: 100%;
    flex-wrap: wrap;
  }

  .column-left {
    flex: 1;
    min-width: 300px;
    padding: 4px;
  }

  .column-right {
    padding: 12px;
  }

  & .MuiTableContainer-root.MuiPaper-root {
    background: none;
    box-shadow: none;
    /* border: 1px solid ${({ theme }) => theme.palette.grey[300]}; */
    background: ${({ theme }) => theme.palette.grey[200]};
    padding: 8px 8px;
    box-sizing: border-box;
  }

  & .MuiTableRow-root:hover .MuiTableCell-root {
    background: ${({ theme }) => theme.palette.grey[100]};
    cursor: pointer;
  }

  & .MuiTableCell-root {
    padding: 4px 12px;
    border-bottom: none;

    & .MuiChip-sizeSmall {
      display: flex;
      background: ${({ theme }) => theme.palette.grey[300]};
      border-radius: 4px;
      width: 65px;

      &:hover {
        background: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }
    }

    & .MuiChip-colorPrimary {
      background: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.textColor};
    }
  }

  & .label-cell {
    width: 1%;
    white-space: nowrap;

    & > div {
      display: flex;
      align-items: center;
    }

    & .MuiTypography-h6 {
      font-weight: bold;
      font-size: 0.75rem;
      margin-left: 4px;
    }
  }

  .settings {
    display: block;
    width: auto;

    & header {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      padding: 0;
      height: auto;
      margin-bottom: 12px;
    }

    & header .MuiTypography-h5 {
      font-size: 0.875rem;
    }

    & .active .MuiButton-root {
      border: 1px solid ${({ theme }) => theme.palette.status.active.main};
    }

    & .MuiButton-root {
      display: flex;
      justify-content: space-between;
      background: ${({ theme }) => theme.palette.grey[300]};
      margin-bottom: 4px;
      margin-right: 6px;
      width: auto;
      padding: 2px 8px;

      & .fi {
        margin-right: 8px;
        border: 1px solid white;
        border-radius: 2px;
      }
    }

    & .MuiButton-root:hover,
    & .MuiButton-textPrimary {
      background: ${({ theme }) => theme.palette.status.active.main}!important;
      color: ${({ theme }) =>
        theme.palette.status.active.contrastText}!important;
    }

    & .no-captions {
      color: ${({ theme }) => theme.palette.grey[400]};
      margin-bottom: 12px;
    }
  }

  & .badges {
    margin: ${({ theme }) => `${theme.spacing()}px -4px`};

    .MuiChip-root {
      margin: 4px;
      border-radius: 4px;

      .MuiSvgIcon-root {
        margin-right: 0;
        margin-left: 8px;
      }

      .MuiChip-label {
        margin-right: 4px;
      }
    }
  }

  & .MuiButton-label {
    text-transform: lowercase;
  }

  & .subtitles {
    display: flex;
    margin-left: 8px;

    .MuiButton-root {
      min-width: 0;
      padding: 6px 4px;
    }
  }
`;

export default StyledComponent;
