import { forwardRef, Fragment, useEffect, useState } from "react";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { searchAsset } from "../SeoDetail/services";
import useDebounce from "../Hooks/useDebounce";
import { useSeoDetail } from "../Hooks/useSeoDetail";

const SeoLocalReferenceField = forwardRef(
  ({ errors, field, value, contenttype: contentType, onChange }, ref) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 200);
    const { linkedAsset } = useSeoDetail();

    useEffect(() => {
      if (
        contentType.identifier === "series" &&
        field.identifier === "linked_fragment" &&
        !linkedAsset?.id
      ) {
        return;
      }

      if (!open) {
        setOptions([]);
        setLoading(false);
      } else {
        setLoading(true);

        (async () => {
          try {
            const {
              data: { data: response },
            } = await searchAsset({
              query: debouncedSearchTerm,
              type:
                contentType.identifier === "series"
                  ? field.identifier === "linked_asset"
                    ? "series"
                    : "series,season,episode"
                  : "video",
              seriesId:
                contentType.identifier === "series" &&
                field.identifier === "linked_fragment"
                  ? linkedAsset?.id
                  : null,
              withoutTrailer: true,
            });

            if (field.identifier === "linked_asset") {
              setOptions(response);
            } else if (field.identifier === "linked_fragment") {
              setOptions(response.map((asset) => asset.fragments).flat());
            }

            setLoading(false);
          } catch (e) {
            setOptions([]);
            setLoading(false);
          }
        })();
      }
    }, [open, debouncedSearchTerm, linkedAsset, field.identifier, contentType]);

    return (
      <Autocomplete
        value={value || null}
        loading={loading}
        options={options}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
        }}
        onChange={(e, data) => onChange(data)}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => {
          return option?.name || "";
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={ref}
            label={field.name}
            variant="outlined"
            fullWidth
            error={!!errors}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    );
  }
);

export default SeoLocalReferenceField;
