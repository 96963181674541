import { useEffect, useRef, useMemo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { useSnackbar } from "notistack";
import moment from "moment";

// Utils
import Utils from "features/Common/utils";

// Icons
import CheckIcon from "@material-ui/icons/Check";
import MovieIcon from "@material-ui/icons/Movie";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SecurityIcon from "@material-ui/icons/Security";
import GetAppIcon from "@material-ui/icons/GetApp";

// Components
import Alert from "@material-ui/lab/Alert";
import Reward from "react-rewards";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Button from "components/Button";
import Typography from "@material-ui/core/Typography";
import DownloadLink from "components/DownloadLink";
import VideoPlayer from "components/VideoPlayer";
import NationFlag from "components/NationFlag";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import CircularProgress from "@material-ui/core/CircularProgress";
import CircularProgressCenter from "components/CircularProgressCenter";
import SubtitleViewer from "components/SubtitleViewer";

// Hooks
import { useFetchSubtitle } from "features/Common/hooks";

// Local Hooks
import {
  useApproveFileRequest,
  usePageAnalytics,
  useBreadcrumbs,
} from "../hooks";

// Local Components
import SubtitleUpload from "./SubtitleUpload";
import Subtitle from "../Subtitle";
import TokenExpired from "../TokenExpired";

// Redux
import { loadExternalSubtitle, uploadFile } from "../reducer";

// style
import StyledGrid, { StyledNoSubtitles } from "./style";

// Constants
const DEFAULT_DATE_TIME_FORMAT = "DD-MM-YYYY";

const NoSubtitleFiles = () => {
  const { t } = useTranslation();
  return (
    <StyledNoSubtitles>
      <ErrorOutlineIcon />
      {t("text.NO_SUBTITLES_UPLOADED")}
    </StyledNoSubtitles>
  );
};

const getVideoSource = (fileHash) => ({
  src: Utils.Fragments.getAssetVideoPath(fileHash),
  type: "video/mp4",
});

const Proofreader = () => {
  const { token, fileId } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [videoCurrentTimeMs, setVideoCurrentTimeMs] = useState(0);

  // Refs
  const videoRef = useRef();
  const rewardRef = useRef();

  // Redux
  const dispatch = useDispatch();

  const { success: uploadSuccess, uploading, error: uploadError } = useSelector(
    (state) => state.external.upload
  );
  const {
    asset,
    fragment,
    tokenInfo,
    video,
    loading,
    error,
    uploaded,
  } = useSelector((state) => state.external);

  const { id: uploadedId } = uploaded || {};
  const { email, expires_at: expiresAt, due_date: dueDate, language, logs } =
    tokenInfo || {};

  const { code, native_name: nativeName } = language || {};
  const { name: assetName, type: assetType, identifier: assetIdentifier } =
    asset || {};
  const { name: fragmentName, subtitles } = fragment || {};
  const videoName = `${assetIdentifier}.mp4`;

  const expiresAtFromNow = moment.unix(expiresAt).fromNow();
  const dueDateFromNow = moment.unix(dueDate).format(DEFAULT_DATE_TIME_FORMAT);
  const videoHash = video?.hash;

  const createdByUser = useMemo(
    () => logs?.find((log) => log.action === "created"),
    [logs]
  );

  const subtitlesCurrentCountry = useMemo(() => {
    return subtitles?.filter((sub) => sub.locale === code);
  }, [subtitles, code]);

  // Use uploaded file id otherwise take
  // the file id from the url
  const activeFileId = useMemo(() => {
    return uploadedId || fileId;
  }, [fileId, uploadedId]);

  const activeSubtitleFile = useMemo(
    () => subtitles?.find(({ id }) => id === Number(activeFileId)),
    [subtitles, activeFileId]
  );

  const activeSubtitleUrl =
    activeSubtitleFile &&
    Utils.Fragments.getAssetStoragePath(activeSubtitleFile?.paths[0]?.path);

  // Hooks

  usePageAnalytics({
    name: "view.external.proofreader",
    code,
    fragmentName,
    nativeName,
    email,
  });

  const breadcrumbs = useBreadcrumbs({ asset });

  const [subtitleLoading, subtitleError, subtitleContent] = useFetchSubtitle({
    url: activeSubtitleUrl,
  });

  const [approving, approveError, onApprove] = useApproveFileRequest();

  // Listeners
  const onSubtitleTimeChange = useCallback(
    ({ timeStamp }) => {
      if (!videoRef?.current) return;
      videoRef.current.setCurrentTime(timeStamp / 1000);
    },
    [videoRef]
  );

  const onTimeUpdate = useCallback(() => {
    setVideoCurrentTimeMs(videoRef?.current?.getCurrentTime() * 1000);
  }, [setVideoCurrentTimeMs]);

  const onFileDropAccepted = ({ acceptedFiles }) => {
    dispatch(uploadFile({ authToken: token, acceptedFiles, fileId }));
  };

  const videoUrl = useMemo(() => Utils.Fragments.getAssetVideoPath(videoHash), [
    videoHash,
  ]);

  // Load external subtitle resources
  useEffect(() => {
    if (!token) return;
    dispatch(loadExternalSubtitle(token));
  }, [token, dispatch]);

  // Show confetti on upload success
  useEffect(() => {
    if (!uploadSuccess) return;
    rewardRef.current.rewardMe();
    enqueueSnackbar(t("labels.EXTERNAL_FILE_UPLOAD_SUCCESS"), {
      variant: "success",
    });
  }, [uploadSuccess, rewardRef, enqueueSnackbar, t]);

  // Punish on upload failed
  useEffect(() => {
    if (!uploadError) return;
    rewardRef.current.punishMe();
  }, [uploadError, rewardRef]);

  // Set video player source
  useEffect(() => {
    if (!videoHash) return;
    videoRef.current.setSource(getVideoSource(videoHash));
  }, [videoHash]);

  // Set subtitle track
  useEffect(() => {
    if (!activeSubtitleFile) return;

    const locale = activeSubtitleFile?.locale;
    const tracks = [
      {
        locale,
        src: Utils.Fragments.getAssetStoragePath(
          activeSubtitleFile.paths.find((path) => path.identifier === "vtt")
            ?.path
        ),
      },
    ];

    videoRef.current.setCaptionsConfig({
      active: true,
      language: locale || "auto",
      update: false,
    });

    videoRef.current.setTracks(tracks);
  }, [activeSubtitleFile]);

  if (error) return <TokenExpired />;
  if (loading) return <CircularProgressCenter />;
  return (
    <StyledGrid container maxwidth="sm" justify="center">
      <Grid item xs={12} md={6} className="column-left">
        <header className="welcome">
          <Typography variant="h5">
            {t("labels.WELCOME")} <strong>{email}</strong>
          </Typography>
          <Typography variant="body1">
            <Trans
              t={t}
              i18nKey="text.PROOFREAD_INVITATION_WELCOME_TEXT"
              values={{
                useName: createdByUser?.user?.name,
                nativeName,
              }}
            >
              <strong>{nativeName}</strong>
              <NationFlag countryCode={code || ""} />
            </Trans>
          </Typography>

          <section className="asset-info asset">
            <Chip size="small" label={assetType} />
            <div>
              <Typography variant="h6">{assetName}</Typography>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs.map(({ name, identifier }) => (
                  <Typography key={identifier} color="textPrimary">
                    {name}
                  </Typography>
                ))}
                <Typography color="textPrimary" className="active">
                  {fragmentName}
                </Typography>
              </Breadcrumbs>
            </div>
          </section>

          <section className="info">
            <Typography variant="body1">
              {t("labels.PROOFREAD_EXTERNAL_DEADLINE")}
              <strong>{dueDateFromNow}</strong>
            </Typography>
          </section>
        </header>

        <footer>
          <section className="asset-download">
            <Typography variant="body1">
              {t("labels.EXTERNAL_DOWNLOAD_VIDEO")}
            </Typography>
            <DownloadLink
              className="download-button"
              name={videoName}
              href={videoUrl}
              trackName="external.subtitle.download.video"
              trackDetails={{
                name: videoName,
                fragmentName,
                videoUrl,
                type: "external.subtitle.download.video",
              }}
            >
              {({ downloading, progress }) => (
                <>
                  <div className="link-info">
                    <MovieIcon size="small" />
                    <Typography variant="h6">{videoName}</Typography>
                  </div>
                  {!downloading && <GetAppIcon size="small" />}
                  {downloading && (
                    <div className="downloading">
                      <Typography variant="caption">
                        {t("labels.DOWNLOADING")}...
                      </Typography>
                      <CircularProgress
                        size={24}
                        variant="static"
                        color="secondary"
                        value={progress * 100}
                      />
                    </div>
                  )}
                </>
              )}
            </DownloadLink>
          </section>

          <section className="subtitles">
            <Typography variant="body1">
              {t("labels.EXTERNAL_DOWNLOAD_SUBTITLES")}
            </Typography>
            {subtitlesCurrentCountry?.map(
              ({
                id,
                name: subtitleName,
                locale: subtitleLocale,
                created_at: createdAt,
                paths,
                status,
              }) => (
                <Subtitle
                  key={id}
                  name={subtitleName}
                  locale={subtitleLocale}
                  createdAt={createdAt}
                  flag={
                    Number(activeFileId) === id
                      ? t("labels.PROOFREADING_REQUIRED")
                      : null
                  }
                  path={paths?.find((path) => path.identifier === "srt")}
                  fragmentName={fragmentName}
                  status={status}
                />
              )
            )}
            {!subtitles?.length && <NoSubtitleFiles />}
          </section>

          <div className="expires-message">
            <SecurityIcon />
            <Typography variant="body1">
              {t("labels.EXTERNAL_DOWNLOAD_EXPIRES")} {expiresAtFromNow}
            </Typography>
          </div>
        </footer>
      </Grid>
      <Grid item xs={12} md={6} lg={5} className="column-right">
        <VideoPlayer
          ref={videoRef}
          language={code}
          onTimeUpdate={onTimeUpdate}
        />
        {activeSubtitleFile && (
          <Subtitle
            name={activeSubtitleFile.name}
            locale={activeSubtitleFile.locale}
            createdAt={activeSubtitleFile.created_at}
            path={activeSubtitleFile.paths.find(
              (path) => path.identifier === "srt"
            )}
            fragmentName={fragmentName}
            status={activeSubtitleFile.status}
          />
        )}
        <SubtitleViewer
          loading={subtitleLoading}
          error={subtitleError}
          content={subtitleContent}
          onTimeStampChange={onSubtitleTimeChange}
          currentTimeMs={videoCurrentTimeMs}
        />
        <Reward
          type="confetti"
          ref={rewardRef}
          config={{
            spread: 80,
          }}
        >
          <SubtitleUpload
            networkState={uploading ? "loading" : "idle"}
            language={{ code, name: code }}
            success={uploadSuccess}
            errors={uploadError ? [uploadError] : null}
            accept=".srt"
            onDropAccepted={onFileDropAccepted}
          />
        </Reward>
        {approveError && <Alert severity="error">{approveError}</Alert>}
        {activeSubtitleFile?.status !== "approved" && (
          <Button
            className="btn-approved"
            name="approve"
            color="primary"
            size="small"
            trackName="proofread.approve"
            trackDetails={{
              fragmentName,
              assetName,
              fileId,
            }}
            variant="contained"
            loading={approving}
            disabledElevation
            onClick={(e) => onApprove({ e, token: token, fileId })}
          >
            {t("buttons.APPROVE")}
          </Button>
        )}
        {activeSubtitleFile?.status === "approved" && (
          <Typography className="approved-message" variant="h3">
            <CheckIcon />
            {t("labels.APPROVED")}
          </Typography>
        )}
      </Grid>
    </StyledGrid>
  );
};

export default Proofreader;
