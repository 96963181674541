import React from "react";
import { TextField } from "@mui/material";
import { Grid } from "@material-ui/core";

const TwitterForm = ({ form, formKey }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          {...form.getInputProps(`${formKey}.tweet`)}
          placeholder="Tweet..."
          multiline
          fullWidth
          label="Tweet"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TwitterForm;
