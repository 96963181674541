import styled from "styled-components";

// Components
import MuiDrawer from "@material-ui/core/Drawer";

export const DrawerStyled = styled(MuiDrawer)`
  & .MuiDrawer-paper {
    min-width: 100%;
    @media (min-width: 640px) {
      min-width: 640px;
    }
  }

  & .MuiTabs-root {
    margin-bottom: 24px;
    min-height: 36px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};

    .MuiTabs-indicator {
      background-color: ${({ theme }) => theme.palette.grey[700]};
    }
  }

  & .MuiTab-root {
    min-height: 36px;
    padding: 0px 8px;

    .MuiTab-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      & .MuiSvgIcon-root {
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
  }
`;

export const StyledSupplier = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.palette.grey[700]};

  & .MuiTypography-h5 {
    font-size: 0.875rem;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  & label {
    margin-right: 4px;
  }
`;

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  flex-grow: 1;
`;

export default StyledComponent;
