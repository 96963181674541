import styled from "styled-components";

const StyledComponent = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background: ${({ backgroundColor }) => backgroundColor};
  border: 2px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 6px;

  &.hasError {
    border-color: ${({ theme }) => theme.palette.error.main};
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export default StyledComponent;
