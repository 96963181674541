import styled from "styled-components";
import Popover from "@material-ui/core/Popover";

const StyledComponent = styled.div`
  display: flex;
  margin-top: 8px;
  padding: ${({ theme }) => `${theme.spacing()}px ${theme.spacing(2)}px`};

  &.copyButton {
    margin-top: 0;
    padding: 0;
    float: right;
  }

  .MuiBreadcrumbs-root {
    display: flex;
    flex: 1;

    & a {
      position: relative;
      color: ${({ theme }) => theme.palette.grey[900]};
      text-decoration: none;
      display: flex;
      align-items: center;

      & .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.grey[900]};
        margin-right: 8px;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: ${({ theme }) => theme.palette.primary.main};
        transform: scaleX(0);
        transition: transform 0.1s;
      }

      &.active:before {
        transform: scaleX(1);
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 0.3s;
      }
      &:hover:before {
        transform: scaleX(1);
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 0.3s;
      }

      & .MuiBreadcrumbs-separator {
        color: red;
      }
    }

    & .no-title {
      display: flex;
      line-height: 1;
      flex-direction: column;

      & .fi {
        width: 1em;
        margin-left: 4px;
      }

      & > div {
        display: flex;
        flex-direction: row;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.palette.grey[700]};
      }
    }
  }
`;

export const StyledSeriesSelector = styled.div`
  display: flex;
  align-items: center;

  & .previous .MuiSvgIcon-root,
  & .next .MuiSvgIcon-root {
    pointer-events: none;
  }

  & .previous .MuiSvgIcon-root {
    transform: rotateZ(90deg);
  }

  & .next .MuiSvgIcon-root {
    transform: rotateZ(-90deg);
  }

  .MuiButton-root {
    padding: 0;
    min-width: 0;
    line-height: 1;
    margin-left: 4px;
    color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: none;

    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &.Mui-disabled {
      background: none;
      color: ${({ theme }) => theme.palette.grey[500]};

      .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.grey[500]};
      }
    }
  }
`;

export const StyledNextPrevPopOver = styled(Popover)`
  pointer-events: none;

  & .MuiPaper-root {
    display: flex;
    padding: 8px 12px;
  }

  & .index {
    position: relative;
    background: ${({ theme }) => theme.palette.grey[200]};
    color: ${({ theme }) => theme.palette.grey[600]};
    margin-right: 4px;
    font-size: 0.75rem;
    padding: 2px 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
`;

export const StyledPopOver = styled(Popover)`
  & nav {
    padding: 16px 8px;

    & > nav {
      padding: 0 16px;
    }
  }

  & .MuiList-root > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.MuiListItem-gutters {
      padding: 8px 12px;
    }
  }

  & .add-button {
    border: 1px dashed ${({ theme }) => theme.palette.grey[200]};
    border-radius: 4px;
    display: flex;
    flex-direction: row !important;
    width: auto;
    justify-content: space-between;
    align-items: center !important;
    margin-bottom: 8px;

    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.primary.main};
      margin-left: 24px;
    }

    & .MuiTypography-h6 {
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1;
    }
  }
`;

export default StyledComponent;
