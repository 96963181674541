import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";

export const StyledDrawer = styled(Drawer)`
  .MuiAppBar-root {
    background: white;
    box-shadow: none;
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  .fi {
    margin-right: 12px;
  }

  .MuiDrawer-paper {
    width: 100%;
  }

  @media (min-width: 680px) {
    .MuiDrawer-paper {
      width: 680px;
    }
  }

  .MuiToolbar-root {
    display: flex;
    justify-content: space-between;

    & .left-column {
      display: flex;
    }
  }
`;

const StyledComponent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .video-download {
    font-size: 0.875rem;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.grey[900]};
    margin: 0 0 ${({ theme }) => theme.spacing(4)}px 0;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing()}px
      ${({ theme }) => theme.spacing()}px;
    border-radius: 4px;

    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.grey[900]};
    }

    :hover {
      background: ${({ theme }) => theme.palette.primary.main};
      color: white;

      .MuiSvgIcon-root {
        color: white;
      }
    }

    & .downloading {
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledPreview = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: white;
  background: ${({ theme, status }) =>
    theme.palette.status[status].light}!important;
  color: ${({ theme, status }) =>
    theme.palette.status[status].contrastText}!important;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 8px 16px;
  z-index: 1;

  & .MuiChip-root {
    margin-right: 8px;
    border-radius: 4px;
    background: ${({ theme, status }) =>
      theme.palette.status[status].dark}!important;
    color: ${({ theme, status }) =>
      theme.palette.status[status].contrastText}!important;
    border-color: ${({ theme, status }) =>
      theme.palette.status[status].dark}!important;
    cursor: pointer;
  }

  h4 {
    font-size: 0.875rem;
    font-weight: 700;
  }

  h6 {
    font-size: 0.75rem;
    word-break: break-all;
  }

  &:hover .delete-button {
    opacity: 1;
  }

  & > .delete-button {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: red;
    color: white;
    border: none;
    cursor: pointer;
    transform: translate(50%, -50%);
    outline: none;
    transition: opacity ease-out 0.2s;

    .MuiSvgIcon-root {
      color: white !important;
    }

    .MuiButtonBase-root:hover {
      background: none;
    }

    & .check-mark-root {
      display: none;
    }

    &.loading,
    &.error {
      display: none;
    }

    &.deleting,
    &.deleted {
      opacity: 1;

      & .check-mark-root {
        display: block;
      }
    }

    &.deleted {
      opacity: 0;
      transition: opacity ease-out 0.2s;
      transition-delay: 2s;
    }

    & .MuiSvgIcon-root {
      font-size: 1rem;
    }

    & .check-mark-root {
      top: 0;
    }
  }
`;

export const StyledUpload = styled.div`
  position: relative;
  margin: 0 0 ${({ theme }) => theme.spacing(2)}px 0;
  background: white;
  padding: 8px 16px 16px 16px;
  border-radius: 4px;

  header {
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
    justify-content: space-between;

    .column-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .MuiTypography-h4 {
      font-size: 0.75rem;
      margin-left: ${({ theme }) => theme.spacing()}px;
      font-weight: 700;
    }

    & .MuiFab-sizeSmall {
      width: 32px;
      height: 32px;
      background: none;
      box-shadow: none;
    }
  }

  & .check-mark-root {
    --success: ${({ theme }) => theme.palette.warning.main};
    --success-background: ${({ theme }) => theme.palette.warning.dark};
    --success-border-color: ${({ theme }) => theme.palette.warning.dark};
  }

  & .download-link {
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing(0.5)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    padding: 0 4px;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.grey[900]};

    & .MuiSvgIcon-root {
      font-size: 1.25rem;
    }

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }

  .action-buttons {
    margin-top: 8px;

    .MuiButton-root {
      padding: 2px 16px;
    }

    .MuiButton-root.Mui-disabled .MuiButton-label {
      color: ${({ theme }) => theme.palette.grey[300]};
    }

    .MuiButton-root .MuiButton-label {
      justify-content: flex-start;
      color: ${({ theme }) => theme.palette.grey[800]};
    }
  }

  .drop-zone {
    position: relative;
    min-width: 0;

    &:focus {
      border: 1px dashed ${({ theme }) => theme.palette.primary.main};
    }

    .error {
      max-width: 340px;
    }

    .error ul {
      padding: 0 0 0 16px;
      margin: 0;
    }
  }
`;

export default StyledComponent;
