import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Grid, TextField, MenuItem } from "@material-ui/core";

import {
  createSeoEntry,
  getSeoContentType,
  getSeoContentTypes,
  getSeoEntry,
  updateSeoEntry,
} from "./services";

// Components
import CircularProgressCenter from "components/CircularProgressCenter";
import SeoDetailSidebar from "../SeoDetail/SeoDetailSidebar/SeoDetailSideBar";

// Style
import StyledComponent from "./style";

import {
  SeoTextField,
  SeoRichTextField,
  SeoReferenceField,
  SeoLocalReferenceField,
} from "../SeoFields";
import { useSeoDetail } from "../Hooks/useSeoDetail";

import SeoDetailError from "./SeoDetailSidebar/SeoDetailError";
import { useHistory, useParams } from "react-router-dom";

const validationObject = (field) => {
  const rules = {};
  return rules;
};

const SeoFieldSlice = (field) => {
  switch (field.type.identifier) {
    case "text":
      return SeoTextField;
    case "rich_text":
      return SeoRichTextField;
    case "reference":
      return SeoReferenceField;
    case "local_reference":
      return SeoLocalReferenceField;
    default:
      return null;
  }
};

const SeoField = ({
  language,
  field,
  control,
  errors,
  contenttype: contentType,
  contenttypes: contentTypes,
  responseErrors,
}) => {
  const As = SeoFieldSlice(field);

  if (!As) {
    return null;
  }

  const identifier = field.localized
    ? `${field.identifier}.${language}`
    : field.identifier;

  const mappedErrors = [...(responseErrors?.[identifier] || [])];
  if (errors?.[field.identifier]?.message) {
    mappedErrors.push(errors?.[field.identifier]?.message);
  }

  return (
    <>
      <Controller
        className="full-width"
        defaultValue=""
        control={control}
        as={As}
        rules={validationObject(field)}
        name={identifier}
        field={field}
        language={language}
        contenttype={contentType}
        contenttypes={contentTypes}
        errors={errors?.[field.identifier] || responseErrors?.[identifier]}
      />
      {mappedErrors?.map((error) => (
        <div className="error" key={error}>
          {error.replace(`.${language}`, "")}
        </div>
      ))}
    </>
  );
};

const SeoDetail = () => {
  const history = useHistory();
  const { channelId } = useParams();
  const {
    loading,
    setLoading,
    languages,
    setLanguages,
    entry,
    setEntry,
    seoEntryId,
    language,
    setFormLoading,
    responseErrors,
    setResponseErrors,
    contentTypes,
    setContentTypes,
    setLinkedAsset,
    setLinkedFragment,
  } = useSeoDetail();

  const {
    control,
    getValues,
    setValue,
    watch,
    reset,
    handleSubmit,
    errors,
    formState: { isSubmitted, dirtyFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {},
    shouldUnregister: false,
  });

  const [contentType, setContentType] = useState(undefined);
  const [submitFailed, setSubmitFailed] = useState(false);

  const watchFields = watch(["linked_asset", "linked_fragment"]);

  useEffect(() => {
    setLinkedAsset(watchFields?.linked_asset || null);
    setLinkedFragment(watchFields?.linked_fragment || null);

    if (watchFields?.linked_fragment?.name) {
      if (!getValues("slug")) {
        setValue(
          "slug",
          watchFields.linked_fragment.name
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, "")
            .replace(/[\s_-]+/g, "-")
            .replace(/^-+|-+$/g, "")
        );
      }

      if (!getValues(`title.${language}`)) {
        setValue(`title.${language}`, watchFields.linked_fragment.name);
      }
    }
  }, [
    setLinkedAsset,
    setLinkedFragment,
    getValues,
    setValue,
    language,
    watchFields?.linked_asset,
    watchFields?.linked_fragment,
  ]);

  const onSubmit = async (data) => {
    setFormLoading(true);

    const content = typeof entry.content === "object" ? entry.content : {};

    Object.keys(data).forEach((slug) => {
      const field = contentType?.fields?.find(
        (field) => field.identifier === slug
      );

      if (field) {
        const value = data[slug] || (field.multiple ? [] : null);

        if (field.localized) {
          if (typeof content?.[slug] !== "object") {
            content[slug] = {};
          }

          content[slug][language] = value?.[language] || null;
        } else {
          content[slug] = value;
        }
      }
    });

    const newContent = {};

    Object.keys(content).forEach((slug) => {
      const field = contentType?.fields?.find(
        ({ identifier }) => identifier === slug
      );

      if (field) {
        if (field.localized) {
          languages.forEach(({ code }) => {
            const value =
              field.identifier === "title" &&
              seoEntryId === "create" &&
              content?.[slug]?.[language]
                ? content?.[slug]?.[language]
                : content?.[slug]?.[code];

            if (field.multiple && Array.isArray(value) && value.length > 0) {
              if (!newContent[slug]) {
                newContent[slug] = {};
              }

              newContent[slug][code] = value;
            } else if (!field.multiple && value) {
              if (!newContent[slug]) {
                newContent[slug] = {};
              }

              newContent[slug][code] = value;
            }
          });
        } else {
          const value = content?.[slug];

          if (field.multiple && Array.isArray(value) && value.length > 0) {
            newContent[slug] = value;
          } else if (!field.multiple && value) {
            newContent[slug] = value;
          }
        }
      }
    });

    try {
      if (seoEntryId === "create") {
        const {
          data: { data: response },
        } = await createSeoEntry({
          content: newContent,
          contentType: contentType,
        });

        history.push(`/channel/${channelId}/seo/${response.id}`);

        setEntry(response);
        setSubmitFailed(false);
        setResponseErrors(response?.errors || {});
      } else {
        const {
          data: { data: response },
        } = await updateSeoEntry(seoEntryId, {
          content: newContent,
          contentType: contentType,
        });

        setEntry(response);
        setSubmitFailed(false);
        setResponseErrors(response?.errors || {});
      }
    } catch (e) {
      if (
        e.response?.status === 422 &&
        typeof e.response?.data?.errors === "object"
      ) {
        setSubmitFailed(true);
        setResponseErrors(e.response?.data?.errors);
      }
      setFormLoading(false);
    }
    setFormLoading(false);
  };

  const getEntry = useCallback(async () => {
    if (seoEntryId === "create") {
      setLoading(false);
      setEntry({});
    } else {
      const {
        data: { data: response },
      } = await getSeoEntry(seoEntryId);
      setLoading(false);
      setEntry(response);
      setResponseErrors(response?.errors || {});
    }
  }, [seoEntryId, setEntry, setLoading, setResponseErrors]);

  const getContentTypes = useCallback(async () => {
    const {
      data: { data: response },
    } = await getSeoContentTypes();
    setContentTypes(response);
  }, [setContentTypes]);

  const setDefaultContentType = useCallback(async (event) => {
    const {
      data: { data: contentTypeResponse },
    } = await getSeoContentType(event.target.value);
    setContentType(contentTypeResponse);
  }, []);

  useEffect(() => {
    getEntry();
  }, [getEntry]);

  useEffect(() => {
    getContentTypes();
  }, [getContentTypes]);

  useEffect(() => {
    if (seoEntryId === "create") {
      return;
    }

    const values = {};

    if (contentType?.fields) {
      contentType.fields.forEach((field) => {
        if (field.localized) {
          values[field.identifier] = {};
          languages.forEach(({ code }) => {
            values[field.identifier][code] =
              entry?.content?.[field.identifier]?.[code] ||
              (field.multiple ? [] : "");
          });
        } else {
          values[field.identifier] =
            entry?.content?.[field.identifier] || (field.multiple ? [] : "");
        }
      });

      reset(values);
    }
  }, [reset, seoEntryId, contentType, languages, entry?.content]);

  useEffect(() => {
    setContentType(entry?.contentType);
  }, [entry?.contentType]);

  useEffect(() => {
    let newLanguages = languages.map((lang) => {
      lang.errors = [];
      return lang;
    });

    if (typeof responseErrors === "object") {
      Object.keys(responseErrors).forEach((key) => {
        if (key === "content") return;

        // eslint-disable-next-line no-unused-vars
        const [name, lang] = key.split(".");

        if (lang) {
          const index = languages.findIndex(
            (language) => language.code === lang
          );

          if (lang && languages[index]) {
            languages[index].errors = [
              ...newLanguages[index]?.errors,
              ...responseErrors[key],
            ];
          }
        } else {
          newLanguages = newLanguages.map((lang) => {
            lang.errors = [...lang.errors, ...responseErrors[key]];
            return lang;
          });
        }
      });
    }

    setLanguages(newLanguages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseErrors]);

  if (loading || !entry) {
    return <CircularProgressCenter />;
  }

  return (
    <StyledComponent>
      <Grid className="container">
        <SeoDetailSidebar
          onSubmit={handleSubmit(onSubmit)}
          isDirty={dirtyFields.size > 0}
        />

        <div className="detailScrollbar">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <SeoDetailError isSubmitted={isSubmitted} failed={submitFailed} />
            {Array.isArray(contentTypes) &&
              contentTypes.length > 0 &&
              (contentType?.id || seoEntryId === "create") && (
                <TextField
                  select
                  value={contentType?.id || ""}
                  label="Type"
                  variant="outlined"
                  disabled={seoEntryId !== "create"}
                  onChange={(event) => setDefaultContentType(event)}
                >
                  {contentTypes.map((identifier) => (
                    <MenuItem key={identifier.id} value={identifier.id}>
                      {identifier.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            {contentType?.fields.map((field) => (
              <div key={field.id}>
                <SeoField
                  language={language}
                  field={field}
                  control={control}
                  errors={errors}
                  responseErrors={responseErrors}
                  contenttype={contentType}
                  contenttypes={contentTypes}
                />
              </div>
            ))}
          </form>
        </div>
      </Grid>
    </StyledComponent>
  );
};

export default SeoDetail;
