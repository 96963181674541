import * as React from "react";

function SvgExcel(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <path
        fill={props.fill}
        d="M31.404 4.136h-10.72V6.12h3.16v3.139h-3.16v1h3.16v3.143h-3.16v1.028h3.16v2.972h-3.16v1.191h3.16v2.979h-3.16v1.191h3.16v2.996h-3.16v2.185h10.72c.169-.051.311-.251.424-.597.113-.349.172-.633.172-.848V4.5c0-.171-.059-.273-.172-.309a1.456 1.456 0 00-.424-.053zm-1.391 21.619H24.87v-2.993h5.143v2.996zm0-4.184H24.87v-2.98h5.143zm0-4.171H24.87v-2.959h5.143v2.961zm0-4H24.87v-3.139h5.143v3.14zm0-4.159H24.87v-3.12h5.143v3.14zM0 3.641v24.801l18.88 3.265V.291L0 3.651zm11.191 18.762c-.072-.195-.411-1.021-1.011-2.484-.599-1.461-.96-2.312-1.065-2.555h-.033l-2.025 4.82-2.707-.183 3.211-6-2.94-6 2.76-.145 1.824 4.695h.036l2.06-4.908 2.852-.18-3.396 6.493 3.5 6.624-3.065-.18z"
      />
    </svg>
  );
}

export default SvgExcel;
