import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { closeFragmentCreateDialog } from "features/Fragment/reducer";

// Components
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import FragmentCreate from "./FragmentCreate";

// Style
import { StyledDialog } from "./style";

const FragmentCreateDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector(
    (state) => state.fragment.ui.fragmentCreateDialogOpen
  );

  const onClose = useCallback(() => {
    dispatch(closeFragmentCreateDialog());
  }, [dispatch]);

  return (
    <StyledDialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <AppBar position="sticky">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6" color="inherit">
            {t("labels.FRAGMENT_CREATE")}
          </Typography>
          <Tooltip title="close">
            <IconButton color="inherit" aria-label="Close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FragmentCreate />
      </DialogContent>
    </StyledDialog>
  );
};

export default FragmentCreateDialog;
