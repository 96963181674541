import styled from "styled-components";

export default styled.aside`
  position: relative;
  display: flex;
  flex: 1;
  /* overflow: hidden; */
  height: 100%;
  background: white;

  .drawer--open & {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1000;
    transform: translateX(calc(-100% + 16px));
    transition: transform 0.2s ease-out;
    box-shadow: 1px 1px 13px 0px #0000003b;

    .toggle-button {
      display: block;
    }

    &:hover {
      transform: translateX(0);
    }
  }

  &.menu-open {
    position: relative;
    transform: translateX(0) !important;
    box-shadow: none;
  }

  &.menu-open .toggle-button .MuiSvgIcon-root {
    transform: translateX(2px) rotate(0);
  }

  .toggle-button {
    display: none;
    position: absolute;
    top: 32px;
    right: 0;
    z-index: 1000;
    transform: translateX(50%);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    min-height: auto;
    color: white;
    background: white;
    border: 1px solid ${({ theme }) => theme.palette.grey[200]};
    color: ${({ theme }) => theme.palette.grey[800]};
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
      rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    transition: opacity 0.2s ease-out;
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.palette.secondary.main};
      color: ${({ theme }) => theme.palette.secondary.contrastText};
    }

    & .MuiSvgIcon-root {
      font-size: 1rem;
      width: 10px;
      height: 10px;
      transform: translateX(-2px) rotate(180deg);
    }
  }
`;
