import styled from "styled-components";

const StyledComponent = styled.div`
  & a {
    text-decoration: none;
  }

  .MuiCard-root {
    box-shadow: none;

    &:hover {
      background: ${({ theme }) => theme.palette.grey[100]};
    }
  }

  .MuiCardContent-root {
    padding: ${({ theme }) => theme.spacing(2)}px;
    padding-top: 0;
  }

  .activeEdit {
    .MuiCard-root {
      background: ${({ theme }) => theme.palette.grey[100]};
    }

    .MuiIconButton-label {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .MuiChip-root {
    border-radius: 4px;
  }

  & .draft .MuiChip-root {
    background: ${({ theme }) => theme.palette.warning.main};
    color: white;
    border-color: ${({ theme }) => theme.palette.warning.main};
  }

  & .active .MuiChip-root {
    background: ${({ theme }) => theme.palette.success.main};
    color: white;
    border-color: ${({ theme }) => theme.palette.success.main};
  }

  .MuiListItem-root,
  .MuiList-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiListItem-root {
    border: 1px dashed ${({ theme }) => theme.palette.grey[300]};
    color: ${({ theme }) => theme.palette.grey[500]};
    border-radius: 4px;
    padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
    margin-bottom: ${({ theme }) => `${theme.spacing()}px`};

    &.active {
      border: 1px solid ${({ theme }) => theme.palette.grey[300]};
      color: ${({ theme }) => theme.palette.grey[900]};
    }
  }

  .MuiChip-root {
    margin: 4px;
  }

  .MuiListItemAvatar-root {
    min-width: 40px;
  }
`;

export default StyledComponent;
