import { compose } from "redux";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import { PropTypes } from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AutoComplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import { withTranslation } from "react-i18next";
import { Component, Fragment } from "../..";
import styles from "./style";

class AutoCompleteList extends Component {
  constructor(props) {
    super(props);

    this.initState({
      value: null,
    });
  }

  internalOnAdd = (e, item) => {
    const { onAdd } = this.props;

    onAdd(item);

    this.setState({ value: null });
  };

  render() {
    const {
      t,
      classes,
      header,
      optionKey,
      options,
      selected,
      disabled,
    } = this.props;
    const { onDelete } = this.props;
    const { value } = this.state;

    return (
      <Fragment>
        <List
          subheader={
            <ListSubheader component="div" className={classes.subHeader}>
              {header}
            </ListSubheader>
          }
        >
          <AutoComplete
            disabled={disabled}
            onChange={this.internalOnAdd}
            options={options}
            value={value}
            getOptionLabel={(option) => option[optionKey]}
            renderOption={null}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t("buttons.ADD")}
                fullWidth
              />
            )}
          />

          {selected.map((item) => (
            <ListItem key={item.id} classes={{ container: classes.listItem }}>
              <ListItemText primary={item[optionKey]} />
              <ListItemSecondaryAction className={classes.secondaryAction}>
                <IconButton
                  disabled={disabled}
                  edge="end"
                  onClick={() => onDelete(item)}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Fragment>
    );
  }
}

AutoCompleteList.defaultProps = {
  disabled: true,
};

AutoCompleteList.propTypes = {
  header: PropTypes.string.isRequired,
  optionKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
};

export default compose(withTranslation(), withStyles(styles))(AutoCompleteList);
