import * as Assets from "../../Asset/asset.utils";
import * as Channels from "../../Channel/channels.utils";
import * as Common from "./common.utils";
import * as Color from "./color.utils";
import * as Form from "./form.utils";
import * as Fragments from "../../Fragment/utils";

const noop = () => {};

const Utils = {
  Assets,
  Channels,
  Common,
  Fragments,
  Form,
  Color,
  noop,
};

export default Utils;
