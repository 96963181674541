/* eslint-disable camelcase */
import { useState, useMemo, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";

// Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearIcon from "@material-ui/icons/Clear";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LinkIcon from "@material-ui/icons/Link";

// Components
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import Fade from "@material-ui/core/Fade";
import Fab from "components/Fab";
import MenuItem from "components/MenuItem";

// Style
import StyledComponent, { StyledMenu } from "./style";

// Constants
import { INVITATION_TYPE } from "../../constants";

const DEFAULT_DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm";
const STATUS = {
  active: "active",
  revoked: "revoked",
  expired: "expired",
};

// eslint-disable-next-line no-unused-vars
const CanViewMenuItem = forwardRef(({ status, when = [], children }, ref) => {
  if (!when.includes(status)) return null;
  return <>{children}</>;
});

CanViewMenuItem.displayName = "CanViewMenuItem";

const Detail = ({ title, tooltip, icon, show = true }) => {
  if (!show) return null;
  return (
    <Tooltip title={tooltip}>
      <section className="details">
        <div className="sub-detail">
          {icon}
          <Typography variant="h6">{title}</Typography>
        </div>
      </section>
    </Tooltip>
  );
};

const Proofreader = ({
  id,
  email,
  currentDate = moment().unix(),
  type,
  createdAt,
  expiresAt,
  deletedAt,
  dueDate,
  lastUsedAt,
  onMenuItemClick,
}) => {
  const { t } = useTranslation();

  // State
  const [revoking, setRevoking] = useState(false);
  const [menuRef, setMenuRef] = useState(null);
  const open = Boolean(menuRef);

  // Listeners
  const onMoreClick = (event) => {
    setMenuRef(event.currentTarget);
  };

  const onMenuClose = () => {
    setMenuRef(null);
  };

  const createdAtFormatted = moment
    .unix(createdAt)
    .format(DEFAULT_DATE_TIME_FORMAT);
  const expirationDateFormatted = moment
    .unix(expiresAt)
    .format(DEFAULT_DATE_TIME_FORMAT);
  const lastUsedFormatted = moment
    .unix(lastUsedAt)
    .format(DEFAULT_DATE_TIME_FORMAT);
  const deletedFormatted = moment
    .unix(deletedAt)
    .format(DEFAULT_DATE_TIME_FORMAT);
  const dueDateFormatted = moment
    .unix(dueDate)
    .format(DEFAULT_DATE_TIME_FORMAT);

  const expiresFromNow = moment.unix(expiresAt).fromNow();
  const lastUsedFromNow = moment.unix(lastUsedAt).fromNow();
  const deletedFromNow = moment.unix(deletedAt).fromNow();
  const dueDateFromNow = moment.unix(dueDate).fromNow();

  const status = useMemo(() => {
    if (deletedAt) return STATUS.revoked;
    if (currentDate > expiresAt) return STATUS.expired;
    return STATUS.active;
  }, [deletedAt, expiresAt, currentDate]);

  return (
    <StyledComponent className={status}>
      <div className="column-left">
        <header>
          <Typography variant="h5">{email}</Typography>
        </header>
        <Detail
          show={expiresAt != null}
          title={expiresFromNow}
          icon={<LinkIcon />}
          tooltip={t("labels.LINK_EXPIRATION", {
            start: createdAtFormatted,
            end: expirationDateFormatted,
          })}
        />
        <Detail
          title={lastUsedAt ? lastUsedFromNow : t("labels.NOT_SEEN")}
          icon={<VisibilityIcon />}
          tooltip={
            lastUsedAt
              ? t("labels.LAST_SEEN", { lastSeen: lastUsedFormatted })
              : t("labels.NOT_SEEN")
          }
        />
        <Detail
          show={dueDate != null}
          title={dueDateFromNow}
          icon={<EventAvailableIcon />}
          tooltip={t("labels.DUE_DATE", { dueDate: dueDateFormatted })}
        />

        <Detail
          show={deletedAt != null}
          title={deletedFromNow}
          icon={<DeleteForeverIcon />}
          tooltip={t("labels.DELETED_DATE", {
            deletedDate: deletedFormatted,
          })}
        />
      </div>
      <div className="column-right">
        <Chip
          variant="outlined"
          size="small"
          label={t(`labels.${status?.toUpperCase()}`)}
        />
        <Fab
          trackName="translator.invite.button.more.menu"
          trackDetails={{ id, email }}
          aria-label="more options"
          onClick={onMoreClick}
        >
          <ExpandMoreIcon />
        </Fab>
        <StyledMenu
          id="fade-menu"
          anchorEl={menuRef}
          keepMounted
          open={open}
          onClose={onMenuClose}
          TransitionComponent={Fade}
        >
          <CanViewMenuItem when={[STATUS.active]} status={status}>
            <MenuItem
              trackName="revision.menuitem.revoke"
              trackDetails={{ id, email }}
              onClick={() =>
                onMenuItemClick({
                  key: "revoke",
                  id,
                  setLoading: setRevoking,
                  setMenuRef,
                  type,
                })
              }
            >
              {revoking && <CircularProgress size={16} />}
              {!revoking && <ClearIcon className="icon-red" />}
              <ListItemText primary={t("buttons.REVOKE_INVITATION")} />
            </MenuItem>
          </CanViewMenuItem>
        </StyledMenu>
      </div>
    </StyledComponent>
  );
};

Proofreader.defaultProps = {
  onMenuItemClick: () => {},
};

Proofreader.propTypes = {
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  type: PropTypes.oneOf([INVITATION_TYPE.TRANSLATE, INVITATION_TYPE.PROOFREAD])
    .isRequired,
  currentDate: PropTypes.number,
  createdAt: PropTypes.number,
  expiresAt: PropTypes.number,
  deletedAt: PropTypes.number,
  dueDate: PropTypes.number,
  lastUsedAt: PropTypes.number,
  onMenuItemClick: PropTypes.func,
};

export default Proofreader;
