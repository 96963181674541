import React, { useState } from "react";
import NationFlag from "components/NationFlag";
import { useSeoDetail } from "../../Hooks/useSeoDetail";
import { Button, ButtonGroup, FormLabel } from "@material-ui/core";

const SeoDetailLanguages = ({ isDirty }) => {
  const { language, languages, setLanguage } = useSeoDetail();
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const handleLanguageClick = (lang) => {
    if (isDirty) {
      return;
    }

    setLanguage(lang.code);
    setSelectedLanguage(lang.code);
  };

  return (
    <>
      <FormLabel>Languages</FormLabel>
      <div className="sideBarButtons">
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          {languages.map((lang) => (
            <Button
              key={lang.code}
              onClick={() => handleLanguageClick(lang)}
              disabled={isDirty}
              style={{
                backgroundColor:
                  lang.errors.length > 0
                    ? selectedLanguage === lang.code
                      ? "#d90429"
                      : isDirty
                      ? undefined
                      : "#820219"
                    : selectedLanguage === lang.code
                    ? "#1e8ea9"
                    : isDirty
                    ? undefined
                    : "#114f5e",
              }}
            >
              <NationFlag
                countryCode={lang.country}
                style={{
                  opacity: selectedLanguage === lang.code || !isDirty ? 1 : 0.6,
                }}
              />
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </>
  );
};
export default SeoDetailLanguages;
