import { memo } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Icons
import InfoIcon from "@material-ui/icons/Info";

// Components
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Label from "components/Label";

// Utils
import { publishDateColor } from "features/Asset/asset.utils";

const DATE_FORMAT = "DD/MM/YYYY";
const ScheduleCell = ({
  id,
  countryCode,
  schedule,
  name,
  identifier,
  locale,
  language,
  dateType,
  completed,
  onMoreInfoClick,
}) => {
  const scheduleDateCurrentCountry = schedule[locale]?.[dateType];
  const dateLabel = scheduleDateCurrentCountry
    ? moment(scheduleDateCurrentCountry).format(DATE_FORMAT)
    : "-";

  const color = publishDateColor(
    moment(scheduleDateCurrentCountry || null),
    completed.includes(language)
  );

  const onHandleMoreInfoClick = (event) => {
    onMoreInfoClick({
      event,
      id: `${id}-${countryCode}`,
      schedule,
      name,
      identifier,
    });
  };

  return (
    <TableCell component="td" className="cell--schedule-date">
      <Label onClick={onHandleMoreInfoClick} value={dateLabel} color={color}>
        <IconButton
          color="inherit"
          size="small"
          aria-label="Close"
          onClick={onHandleMoreInfoClick}
        >
          <InfoIcon fontSize="small" />
        </IconButton>
      </Label>
    </TableCell>
  );
};

ScheduleCell.defaultProps = {
  onMoreInfoClick: () => null,
};

ScheduleCell.propTypes = {
  id: PropTypes.number,
  countryCode: PropTypes.string,
  completed: PropTypes.arrayOf(PropTypes.string),
  dateType: PropTypes.oneOf([
    "contract_start",
    "contract_end",
    "schedule_start",
    "schedule_end",
  ]),
  identifier: PropTypes.string,
  language: PropTypes.string,
  locale: PropTypes.string,
  name: PropTypes.shape({}),
  schedule: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  onMoreInfoClick: PropTypes.func,
};

export default memo(ScheduleCell);
