import styled from "styled-components";

const StyledComponent = styled.div`
  padding: 16px;

  & header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  & header .MuiTypography-h5 {
    font-size: 0.875rem;
    margin-left: 8px;
  }

  & header .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  .country-info {
    padding: 12px 16px;
    background: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 4px;
    margin-bottom: 8px;

    &.active header .MuiTypography-h5 {
      font-weight: bold;
    }
  }

  .country-info header {
    display: flex;

    & .fi {
      margin-right: 8px;
    }

    & .MuiTypography-h5 {
      font-size: 0.75rem;
    }
  }

  .country-info .table--dates {
    background: white;
    padding: 8px;
    border-radius: 4px;
    margin-top: 12px;

    & th,
    & td {
      padding: 4px 8px;
    }

    & th {
      font-weight: normal;
    }

    & td {
      font-weight: bold;
    }
  }
`;

export default StyledComponent;
