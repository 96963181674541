import { AllInApi } from "../../Common/services/api.service";
import * as Constants from "../../Common/constants";

const api = AllInApi.getApi();

export const getRoles = () => api.get(Constants.API_ENDPOINT_ROLES);

export const getRole = (id) =>
  api.get(Constants.API_ENDPOINT_ROLE.replace(":roleId", id));

export const getPermissions = () =>
  api.get(`${AllInApi.getApiPath()}/permission`);

export const saveRole = (role) =>
  api.put(`${AllInApi.getApiPath()}/role/${role.id}`, role);

export const addUser = (data) =>
  api.post(`${AllInApi.getApiPath()}/users`, data);

export const sendActivation = (userId) =>
  api.post(`${AllInApi.getApiPath()}/users/${userId}/activate/token/resend`);
