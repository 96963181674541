import styled from "styled-components";
import TableContainer from "@material-ui/core/TableContainer";

export default styled(TableContainer)`
  & .MuiTableCell-root {
    padding: 12px 8px;
    border: none;
  }

  & .MuiTableCell-head {
    font-weight: bold;
  }

  & .MuiTableSortLabel-root:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & .MuiTableCell-root,
  & .MuiTableSortLabel-root,
  & .MuiTableSortLabel-root .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.grey[700]};
  }

  & .MuiTableSortLabel-active,
  & .MuiTableSortLabel-active .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  & .head--schedule-date {
    width: 80px;

    & .MuiSelect-root {
      animation: none;
      font-weight: bold;
      font-size: 0.8125rem;
      padding-bottom: 4px;
    }

    & .MuiInput-underline:before {
      display: none !important;
    }
  }

  & .head--completed,
  & .head--countries {
    text-align: center;
  }

  & .head--expand {
    width: 120px;
  }

  & .countryButton {
    padding: 0;
    min-width: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    background: none;
    margin-right: 4px;

    &:hover & > .fi {
      transform: scale(1.1);
    }
  }

  & .highlight > em {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;
    font-style: normal;
  }

  .MuiTypography-h6 {
    font-size: 0.75rem;
  }

  & .MuiTableCell-root.cell--fragment-status {
    padding-right: 32px;
  }
`;
