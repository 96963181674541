import {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  memo,
} from "react";
import PropTypes from "prop-types";

import plyr from "plyr";
import StyledVideo from "./style";

export const VideoPlayerPure = forwardRef((props, ref) => {
  const {
    controls,
    autoplay,
    onPlay,
    onTimeUpdate,
    onPause,
    onPlaying,
    onControlsHidden,
    onControlsShown,
    onLoadedMetaData,
    onLanguageChange,
    onError,
    onEnded,
    onSeeking,
    onCanPlay,
    debug,
    language,
    onSeeked,
  } = props;
  const playerRef = useRef();
  let sourceConfig = {};

  const setPlayerOptions = (options) => {
    const player = playerRef.current;
    sourceConfig = {
      ...sourceConfig,
      ...options,
    };
    player.source = sourceConfig;
    player.download = sourceConfig?.sources?.[0]?.src;
  };

  const setSource = (source) => {
    setPlayerOptions({
      type: "video",
      sources: [
        {
          src: source.src,
          type: source.type,
          size: 720,
        },
      ],
    });
  };

  const setCurrentTime = (currentTime) => {
    if (!playerRef) return;
    playerRef.current.currentTime = currentTime;
  };

  const setSeekTime = (seekTime) => {
    if (!playerRef) return;
    playerRef.current.config.seekTime = seekTime;
  };

  const setCaptionsConfig = (captions) => {
    playerRef.current.config.captions = captions;
  };

  const setTracks = (newTracks) => {
    const playerTracks = newTracks.map((track) => ({
      kind: "captions",
      label: track.locale,
      srclang: track.locale,
      src: track.src,
      default: track.locale === language,
    }));
    setPlayerOptions({ tracks: playerTracks });
  };

  const setActiveLanguage = (locale) => {
    playerRef.current.language = locale;
  };

  // Setup the video plyr on component did mount
  useEffect(() => {
    const [playerInstance] = plyr.setup("#js-plyr", {
      iconUrl: "/plyr/plyr.svg",
      blankVideo: "/plyr/blank.mp4",
      autoplay,
      controls,
      debug,
      // set to true because tracks are loaded from different
      // cors url
      crossorigin: true,
      seekTime: 1 / 25,
      keyboard: { focused: true, global: true },
    });
    playerRef.current = playerInstance;

    return () => {
      playerInstance.destroy();
    };
  }, [debug, autoplay, controls]);

  // Expose functions to other components using forward ref
  useImperativeHandle(ref, () => ({
    setSource,
    setTracks,
    setCurrentTime,
    setSeekTime,
    setActiveLanguage,
    setCaptionsConfig,
    getCurrentTime: () => playerRef?.current?.currentTime,
    getIsSeeking: () => playerRef?.current?.seeking,
    getIsPlaying: () => playerRef?.current?.playing,
    getDuration: () => playerRef?.current?.duration,
    getLanguage: () => playerRef?.current?.language,
  }));

  // Add listeners
  useEffect(() => {
    const seeked = () => {
      onSeeked(playerRef.current.currentTime);
    };

    const loadedMetaData = () => {
      // Make sure duration is available
      if (!playerRef.current.duration) return;
      onLoadedMetaData({ duration: playerRef.current.duration });
    };

    const languageChange = () => {
      onLanguageChange({ language: playerRef.current.language });
    };

    playerRef.current.on("play", onPlay);
    playerRef.current.on("playing", onPlaying);
    playerRef.current.on("pause", onPause);
    playerRef.current.on("ended", onEnded);
    playerRef.current.on("timeupdate", onTimeUpdate);
    playerRef.current.on("controlsshown", onControlsShown);
    playerRef.current.on("controlshidden", onControlsHidden);
    playerRef.current.on("error", onError);
    playerRef.current.on("seeking", onSeeking);
    playerRef.current.on("canplay", onCanPlay);
    playerRef.current.on("canplaythrough", onCanPlay);
    playerRef.current.on("seeked", seeked);
    playerRef.current.on("languagechange", languageChange);
    playerRef.current.on("loadedmetadata", loadedMetaData);

    return () => {
      playerRef.current.off("play", onPlay);
      playerRef.current.off("playing", onPlaying);
      playerRef.current.off("pause", onPause);
      playerRef.current.off("ended", onEnded);
      playerRef.current.off("timeupdate", onTimeUpdate);
      playerRef.current.off("controlsshown", onControlsShown);
      playerRef.current.off("controlshidden", onControlsHidden);
      playerRef.current.off("error", onError);
      playerRef.current.off("seeking", onSeeking);
      playerRef.current.off("canplay", onCanPlay);
      playerRef.current.off("canplaythrough", onCanPlay);
      playerRef.current.off("seeked", seeked);
      playerRef.current.off("languagechange", languageChange);
      playerRef.current.off("loadedmetadata", loadedMetaData);
    };
  }, [
    playerRef,
    onPlay,
    onPlaying,
    onSeeking,
    onCanPlay,
    onPause,
    onSeeked,
    onEnded,
    onTimeUpdate,
    onControlsHidden,
    onControlsShown,
    onLoadedMetaData,
    onLanguageChange,
    onError,
  ]);

  return <StyledVideo id="js-plyr" ref={ref} />;
});

VideoPlayerPure.defaultProps = {
  autoplay: false,
  controls: [
    "play-large",
    "play",
    "progress",
    "current-time",
    "duration",
    "mute",
    "volume",
    "captions",
    "settings",
    "pip",
    "airplay",
    "download",
    "fullscreen",
  ],
  debug: false,

  onPlay: () => {},
  onPause: () => {},
  onTimeUpdate: () => {},
  onSeeked: () => {},
  onSeeking: () => {},
  onCanPlay: () => {},
  onPlaying: () => {},
  onEnded: () => {},
  onControlsShown: () => {},
  onControlsHidden: () => {},
  onLoadedMetaData: () => {},
  onLanguageChange: () => {},
};

VideoPlayerPure.propTypes = {
  language: PropTypes.string,
  autoplay: PropTypes.bool,
  debug: PropTypes.bool,
  controls: PropTypes.arrayOf(
    PropTypes.oneOf([
      "play-large",
      "play",
      "progress",
      "current-time",
      "duration",
      "mute",
      "volume",
      "captions",
      "settings",
      "pip",
      "airplay",
      "download",
      "fullscreen",
    ])
  ),
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onSeeked: PropTypes.func,
  onSeeking: PropTypes.func,
  onCanPlay: PropTypes.func,
  onEnded: PropTypes.func,
  onPlaying: PropTypes.func,
  onControlsShown: PropTypes.func,
  onControlsHidden: PropTypes.func,
  onLoadedMetaData: PropTypes.func,
  onLanguageChange: PropTypes.func,
};

export default memo(VideoPlayerPure, () => false);
