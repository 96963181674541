import PropTypes from "prop-types";

// Icons
import SearchIcon from "@material-ui/icons/Search";

// Components
import Typography from "@material-ui/core/Typography";

// Style
import StyledComponent from "./style";

const NoHitsDisplay = ({ noResultsLabel }) => (
  <StyledComponent data-qa="no-hits">
    <SearchIcon fontSize="large" />
    <Typography variant="h6">{noResultsLabel}</Typography>
  </StyledComponent>
);

NoHitsDisplay.propTypes = {
  noResultsLabel: PropTypes.string,
};

export default NoHitsDisplay;
