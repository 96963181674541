import { useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";

//Icons
import DeleteIcon from "@material-ui/icons/Delete";

// Components
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

// Hooks
import { useSuppliers } from "./";

// Style
import { StyledSupplier } from "./style";

const Supplier = ({
  id,
  email,
  licenseId,
  dueDate,
  expiresAt,
  lastUsedAt,
  deletedAt,
}) => {
  const { t } = useTranslation();
  // State
  const [deleting, setDeleting] = useState();
  const { loadSuppliers, deleteSupplier } = useSuppliers();

  const dueDateFormatted = moment.unix(dueDate).format("DD MMMM YYYY");
  const lastUsedAtFormatted = moment
    .unix(lastUsedAt)
    .format("DD MMMM YYYY HH:mm:ss");
  const expiresAtFormatted = moment.unix(expiresAt).format("DD MMMM YYYY");
  const deletedAtFormatted = moment.unix(deletedAt).format("DD MMMM YYYY");

  const isDeleted = deletedAt !== null;

  const onDeleteSupplier = async () => {
    try {
      setDeleting(true);
      await deleteSupplier(licenseId, id);
      await loadSuppliers(licenseId, true);
    } catch (error) {}
    setDeleting(false);
  };

  return (
    <StyledSupplier
      className={classNames({
        deleted: isDeleted,
      })}
    >
      <div>
        <Typography variant="h5">{email}</Typography>

        <div className="date">
          <Typography variant="body">
            <label>{t("labels.DUE_DATE_SHORT")}:</label>
            {dueDateFormatted}
          </Typography>
        </div>
        {lastUsedAt && (
          <div className="date">
            <Typography variant="body">
              <label>{t("labels.LAST_USED_AT")}:</label>
              {lastUsedAtFormatted}
            </Typography>
          </div>
        )}
        <div className="date">
          <Typography variant="body">
            <label>{t("labels.EXPIRES_AT")}:</label>
            {expiresAtFormatted}
          </Typography>
        </div>

        {deletedAt && (
          <div className="date">
            <Typography variant="body">
              <label>{t("labels.DELETE_AT")}:</label>
              {deletedAtFormatted}
            </Typography>
          </div>
        )}
      </div>
      <div className="actions">
        {!deleting && (
          <IconButton onClick={onDeleteSupplier} loading={deleting}>
            <DeleteIcon />
          </IconButton>
        )}
        {deleting && <CircularProgress size={16} />}
      </div>
    </StyledSupplier>
  );
};

export default Supplier;
