import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Form, Field } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import IconTranslate from "@material-ui/icons/GTranslate";

import CustomTextField from "../../../components/Formik/TextField";
import FragmentGenericFields from "../../../components/FragmentGenericFields/FragmentGenericFields";

class TranslationFormView extends Component {
  renderTranslatableFields(fragment, errors) {
    const { translatableFields } = this.props;

    if (translatableFields === null) return false;

    return translatableFields.map((field) => (
      <FragmentGenericFields key={field.id} fields={field} errors={errors} />
    ));
  }

  render() {
    const {
      disabled,
      fragment,
      errors,
      classes,
      t,
      setFieldValue,
      translateTarget,
      sourceFragment,
    } = this.props;

    return (
      <Form>
        <Field
          id="asset.name"
          name="asset.name"
          component={CustomTextField}
          label={t("labels.ASSET_NAME")}
          placeholder={`${t("labels.ASSET_NAME")}...`}
          value={fragment.asset.name}
          required
          errorText={errors["asset.name"]}
          disabled={disabled}
          fullWidth
          InputProps={
            !disabled
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          translateTarget(sourceFragment.asset.name).then(
                            (response) => {
                              if (
                                response.data.data &&
                                response.data.data.translations
                              ) {
                                setFieldValue(
                                  "asset.name",
                                  response.data.data.translations[0]
                                    .translatedText
                                );
                              }
                            }
                          );
                        }}
                      >
                        <IconTranslate />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : null
          }
        />
        <Field
          id="name"
          name="name"
          component={CustomTextField}
          label={t("labels.NAME")}
          placeholder={`${t("labels.NAME")}...`}
          required
          value={fragment.name}
          errorText={errors.name}
          disabled={disabled}
          fullWidth
          InputProps={
            !disabled
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          translateTarget(sourceFragment.name).then(
                            (response) => {
                              if (
                                response.data.data &&
                                response.data.data.translations
                              ) {
                                setFieldValue(
                                  "name",
                                  response.data.data.translations[0]
                                    .translatedText
                                );
                              }
                            }
                          );
                        }}
                      >
                        <IconTranslate />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : null
          }
        />
        <Field
          id="description"
          name="description"
          component={CustomTextField}
          label={t("labels.DESCRIPTION")}
          placeholder={`${t("labels.DESCRIPTION")}...`}
          multiline
          value={fragment.description}
          errorText={errors.description}
          disabled={disabled}
          fullWidth
          InputProps={{
            classes: { input: classes.descriptionInput },
            endAdornment: !disabled ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    translateTarget(sourceFragment.description).then(
                      (response) => {
                        if (
                          response.data.data &&
                          response.data.data.translations
                        ) {
                          setFieldValue(
                            "description",
                            response.data.data.translations[0].translatedText
                          );
                        }
                      }
                    );
                  }}
                >
                  <IconTranslate />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
        <Field
          id="short_description"
          name="short_description"
          component={CustomTextField}
          label={t("labels.SHORT_DESCRIPTION")}
          placeholder={`${t("labels.SHORT_DESCRIPTION")}...`}
          helperText={`${
            fragment.short_description ? fragment.short_description.length : 0
          } / 250`}
          multiline
          value={fragment.short_description}
          errorText={errors.short_description}
          disabled={disabled}
          fullWidth
          InputProps={{
            maxLength: 250,
            classes: { input: classes.descriptionInput },
            endAdornment: !disabled ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    translateTarget(sourceFragment.short_description).then(
                      (response) => {
                        if (
                          response.data.data &&
                          response.data.data.translations
                        ) {
                          setFieldValue(
                            "short_description",
                            response.data.data.translations[0].translatedText
                          );
                        }
                      }
                    );
                  }}
                >
                  <IconTranslate />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />

        {this.renderTranslatableFields(fragment, errors, disabled)}
      </Form>
    );
  }
}

TranslationFormView.propTypes = {
  t: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  sourceFragment: PropTypes.shape({}),
  translateTarget: PropTypes.func,
  classes: PropTypes.shape({}).isRequired,
  translatableFields: PropTypes.arrayOf(PropTypes.shape({})),
  fragment: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  validationSchema: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default withTranslation()(TranslationFormView);
