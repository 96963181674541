import { useRef, useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

// Icons
import HistoryIcon from "@material-ui/icons/History";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";

// Utils
import Utils from "features/Common/utils";

// Components
import { Typography } from "@material-ui/core";
import NationFlag from "components/NationFlag";
import DropZone from "components/DropZone";
import Button from "components/Button";
import Fab from "components/Fab";
import StatusLabel from "components/StatusLabel";

// Constants
import { INVITATION_TYPE } from "features/Fragment/FragmentRevisionTranslatorPanel/constants";
import { TABS } from "features/Fragment/FragmentRevisionTranslatorPanel/RevisionTranslatorPanel";

// Style
import { StyledUpload, StyledPreview } from "./style";

const Preview = (props) => {
  const { t } = useTranslation();
  const { file, name, errors, status } = props;
  if (!file) return null;

  const className = classNames({
    "error-state": errors,
  });

  const allowedStatuses = [
    "active",
    "draft",
    "pending",
    "approved",
    "rejected",
  ];

  if (!status) return null;
  return (
    <StyledPreview status={status} className={className}>
      {allowedStatuses.includes(status) && (
        <StatusLabel
          label={t(`labels.${status.toUpperCase()}`)}
          status={status}
        />
      )}
      <Typography variant="h6">{name || file.path}</Typography>
    </StyledPreview>
  );
};

const Errors = ({ errors }) => {
  if (!errors) {
    return null;
  }

  const fileErrors = !Array.isArray(errors) ? Object.values(errors) : errors;

  return (
    <ul>
      {fileErrors?.map((value, index) => (
        <li key={index}>{value}</li>
      ))}
    </ul>
  );
};

// 50MB
const MAX_SIZE_IN_BYTES = 50000000;
const SubtitleUpload = ({
  id,
  typeId,
  fileId,
  language,
  errors,
  status,
  isPlaceHolder,
  networkState,
  revisions,
  accept,
  maxSize,
  file,
  name,
  onDropAccepted,
  onMoreClick,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const dropZoneRef = useRef();

  const path = file?.paths?.length ? file.paths[0].path : null;
  const url = Utils.Fragments.getAssetStoragePath(path);
  const previewFiles = path ? [{ preview: url }] : null;
  const locale = language?.code;

  useEffect(() => {
    if (!errors) {
      setError(null);
    } else {
      setError(<Errors errors={errors} />);
    }
  }, [errors]);

  const onDropAcceptedHandle = (acceptedFiles) => {
    const isNew = isPlaceHolder;
    onDropAccepted({
      id,
      typeId,
      fileId,
      acceptedFiles,
      isNew,
      type: "subtitle",
      locale,
    });
  };

  return (
    <StyledUpload>
      <header>
        <div className="column-left">
          <NationFlag countryCode={language.code} />
          <Typography variant="h4">{language.name}</Typography>
        </div>
        <div className="column-right">
          <Fab
            trackName="subtitle.edit.more.button"
            trackDetails={{
              language,
              id,
            }}
            id={`button-more-${locale}`}
            color="inherit"
            size="small"
            aria-label="add"
            onClick={(e) => onMoreClick({ e })}
          >
            <MoreHorizIcon />
          </Fab>
        </div>
      </header>

      <DropZone
        ref={dropZoneRef}
        className="drop-zone"
        defaultFiles={previewFiles}
        style={{ minWidth: 300 }}
        height={32}
        t={t}
        error={error}
        accept={accept}
        placeholderText={t("placeholder.UPLOAD_SUBTITLE")}
        state={networkState}
        PreviewComponent={(props) => (
          <Preview
            {...props}
            language={language}
            fragmentId={id}
            name={name}
            errors={errors}
            locale={language?.code}
            status={status}
          />
        )}
        maxSize={maxSize || MAX_SIZE_IN_BYTES}
        onDropAccepted={onDropAcceptedHandle}
      />
      <div className="action-buttons">
        <Button
          name="revision"
          color="primary"
          size="small"
          variant="text"
          startIcon={<HistoryIcon />}
          onClick={(e) => onMoreClick({ e, tab: TABS.REVISION })}
        >
          {revisions} {t("labels.FILE_REVISIONS", { count: revisions })}
        </Button>
        <Button
          name="invite.translator"
          color="primary"
          size="small"
          variant="text"
          startIcon={<LanguageOutlinedIcon />}
          onClick={(e) =>
            onMoreClick({
              e,
              tab: TABS.TRANSLATORS,
              invitationType: INVITATION_TYPE.TRANSLATE,
              fileId,
            })
          }
        >
          {t("buttons.INVITE_TRANSLATOR")}
        </Button>
        <Button
          name="invite.proofreader"
          color="primary"
          size="small"
          variant="text"
          disabled={revisions === 0}
          startIcon={<FindInPageOutlinedIcon />}
          onClick={(e) =>
            onMoreClick({
              e,
              tab: TABS.PROOFREADER,
              invitationType: INVITATION_TYPE.PROOFREAD,
              fileId,
            })
          }
        >
          {t("buttons.INVITE_PROOFREADER")}
        </Button>
      </div>
    </StyledUpload>
  );
};

export default memo(SubtitleUpload);
