import Home from "./Home";

const routes = [
  {
    key: "CHANNEL",
    path: "/",
    component: Home,
    exact: true,
    displayNav: false,
    auth: true,
  },
];

export default routes;
