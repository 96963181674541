/* Components */
import Layout from "../../components/Layout";
import SeoEntryPage from "features/Seo/SeoEntryPage/SeoEntryPage";
import { SeoEntryProvider } from "features/Seo/SeoEntryPage/SeoEntryProvider";

const Page = ({ routes, ...props }) => (
  <Layout routes={routes}>
    <SeoEntryProvider>
      <SeoEntryPage />
    </SeoEntryProvider>
  </Layout>
);

export default Page;
