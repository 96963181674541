import PropTypes from "prop-types";

// Style
import StyledHeader, { StyledTitle, StyledContent } from "./style";

export const Title = ({ children }) => {
  return <StyledTitle>{children}</StyledTitle>;
};

export const Content = ({ children }) => {
  return <StyledContent>{children}</StyledContent>;
};

const Header = ({ children }) => {
  return <StyledHeader>{children}</StyledHeader>;
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Header.Title = Title;
Header.Content = Content;

export default Header;
