import styled from "styled-components";

const StyledComponent = styled.div`
  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 12px;
  }

  & .no-labels {
    display: flex;
    justify-content: center;
    padding: 0 4px;
    color: ${({ theme }) => theme.palette.grey[600]};

    & .MuiSvgIcon-root {
      margin-right: 6px;
    }
  }
`;

export default StyledComponent;
