import { getItem, setItem } from "features/Common/hooks/usePersistentState";

// Constants
export const AUTHENTICATED = "authenticated";

export const getAuthenticationStateFromStorage = () => {
  const authenticated = getItem(AUTHENTICATED) === "true";
  return authenticated;
};

export const setAuthenticationStateInStorage = (authenticated) => {
  const event = new Event("AuthenticationUpdated");

  event.value = authenticated;
  event.key = "authenticated";

  document.dispatchEvent(event);

  setItem(AUTHENTICATED, authenticated);
};
