import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

// Icons
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import FolderSpecialOutlinedIcon from "@material-ui/icons/FolderSpecialOutlined";
import MovieCreationOutlinedIcon from "@material-ui/icons/MovieCreationOutlined";
import MovieIcon from "@material-ui/icons/Movie";
import { Excel, Json } from "components/Icons/";

// Components
import Alert from "@material-ui/lab/Alert";
import Button from "components/Button";
import MuiButton from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";

// Services
import { exportAsset } from "features/Asset/services";

// Local Components
import TabPanel from "./TabPanel";
import FormGroupWithTitle from "./FormGroupWithTitle";
import { getFileNameFromHeaders, readFile } from "./utils";

// Style
import StyledDialog from "./style";

const FORMAT = {
  AXINOM: "AXINOM",
  RAW: "RAW",
};

const TAB = {
  [FORMAT.AXINOM]: FORMAT.AXINOM,
  [FORMAT.RAW]: FORMAT.RAW,
};

const EXPORT_TYPES = {
  ALL: "all",
  ASSET: "asset",
  LICENSE: "license",
};

const FILE_TYPES = {
  JSON: "json",
  EXCEL: "xlsx",
};

const FILE_ICONS = {
  [FILE_TYPES.JSON]: <Json fill="white" className="icon--small" />,
  [FILE_TYPES.EXCEL]: <Excel fill="white" className="icon--small" />,
};

const FILE_COLORS = {
  [FILE_TYPES.JSON]: "#1270b7",
  [FILE_TYPES.EXCEL]: "#1d6f42",
};

const EXPORT_OPTIONS_GENERATORS = {
  [FORMAT.AXINOM]: ({
    selectedRows,
    type,
    fileType,
    filterPublish,
    exportType,
  }) => ({
    ids: selectedRows,
    type,
    all: false,
    fileType,
    filterPublish,
    exportTypes: exportType === "all" ? ["asset", "license"] : [exportType],
  }),

  [FORMAT.RAW]: ({ selectedRows, type, fileType }) => ({
    ids: selectedRows,
    type,
    all: true,
    fileType,
  }),
};

const StyledRadio = (props) => (
  <Radio disableRipple color="default" {...props} />
);

const RadioWithLabel = ({ value, icon, checkedIcon, label }) => (
  <FormControlLabel
    value={value}
    label={label}
    control={
      <StyledRadio color="primary" icon={icon} checkedIcon={checkedIcon} />
    }
  />
);

const ExportDialog = ({ title, open, onClose, type, selectedRows }) => {
  const { t } = useTranslation();

  // State
  const [isExporting, setIsExporting] = useState(false);
  const [tab, setTab] = useState(TAB.AXINOM);
  const [error, setError] = useState(null);

  // Form
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      filterPublish: false,
      exportType: EXPORT_TYPES.ALL,
      fileType: FILE_TYPES.EXCEL,
    },
  });

  const fileType = watch("fileType");

  useEffect(() => {
    setError(null);
  }, [open]);

  // Listeners
  const onTabClick = (event) => {
    const { name } = event.currentTarget;
    setTab(name);
  };

  const onSubmit = (data) => {
    const { exportType, filterPublish, fileType } = data;

    const optionGenerator = EXPORT_OPTIONS_GENERATORS[tab];
    const options = optionGenerator({
      selectedRows,
      type,
      fileType,
      filterPublish,
      exportType,
    });

    setIsExporting(true);
    setError(null);

    exportAsset(options)
      .then((response) => {
        const disposition = response.headers["content-disposition"];
        const filename = getFileNameFromHeaders(disposition);

        saveAs(response.data, filename);
        onClose();
      })
      .catch(async (error) => {
        if (error.response?.data) {
          const { data } = error.response;
          // Read file
          const file = await readFile(data);
          // Parse content and retrieve 'message'
          const { message } = JSON.parse(file);
          setError(message);
          return;
        }
        throw error;
      })
      .catch(() => {
        setError(t("error.FORM"));
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <section>
            <Typography variant="caption">
              {t(`labels.EXPORT_${type.toUpperCase()}_SELECTED`)}
            </Typography>
            <Typography variant="h3">{selectedRows?.length}</Typography>
          </section>

          <section>
            <FormControl component="fieldset">
              <FormGroupWithTitle
                title={t("labels.EXPORT_FORMAT_TITLE")}
                description={
                  <span>
                    <strong>Axinom: </strong>
                    {t("labels.EXPORT_FORMAT_AXINOM_DESCRIPTION")}
                    <br />
                    <strong>Raw: </strong>
                    {t("labels.EXPORT_FORMAT_RAW_DESCRIPTION")}
                  </span>
                }
              >
                <ButtonGroup
                  disableElevation
                  color="primary"
                  aria-label="Export data purpose"
                >
                  <MuiButton
                    name={TAB.AXINOM}
                    onClick={onTabClick}
                    color="primary"
                    variant={tab === TAB.AXINOM ? "contained" : "outlined"}
                  >
                    {t(`buttons.EXPORT_AXINOM`)}
                  </MuiButton>
                  <MuiButton
                    name={TAB.RAW}
                    onClick={onTabClick}
                    color="primary"
                    variant={tab === TAB.RAW ? "contained" : "outlined"}
                  >
                    {t(`buttons.EXPORT_RAW`)}
                  </MuiButton>
                </ButtonGroup>
              </FormGroupWithTitle>

              <TabPanel tabKey={TAB.AXINOM} active={tab}>
                <FormGroupWithTitle
                  title={t("labels.EXPORT_KIND_TITLE")}
                  description={t("labels.EXPORT_KIND_DESCRIPTION")}
                >
                  <Controller
                    name="exportType"
                    control={control}
                    as={
                      <RadioGroup
                        defaultValue="fragment"
                        aria-label="export-type"
                        row
                      >
                        <RadioWithLabel
                          value={EXPORT_TYPES.ALL}
                          label={t("labels.EXPORT_TYPES.ALL")}
                          icon={<FolderSpecialOutlinedIcon />}
                          checkedIcon={<FolderSpecialIcon />}
                        />

                        <RadioWithLabel
                          value={EXPORT_TYPES.ASSET}
                          label={t("labels.EXPORT_TYPES.ASSET")}
                          icon={<MovieCreationOutlinedIcon />}
                          checkedIcon={<MovieIcon />}
                          color="primary"
                        />

                        <RadioWithLabel
                          value={EXPORT_TYPES.LICENSE}
                          label={t("labels.EXPORT_TYPES.LICENSE")}
                          icon={<VerifiedUserOutlinedIcon />}
                          checkedIcon={<VerifiedUserIcon />}
                        />
                      </RadioGroup>
                    }
                  />
                </FormGroupWithTitle>

                <FormGroupWithTitle
                  title={t("labels.EXPORT_PUBLISHED_ONLY_TITLE")}
                  description={t("labels.EXPORT_PUBLISHED_ONLY_DESCRIPTION")}
                >
                  <FormControlLabel
                    control={
                      <Controller
                        as={<Switch color="primary" />}
                        control={control}
                        name="filterPublish"
                      />
                    }
                  />
                </FormGroupWithTitle>
              </TabPanel>

              <TabPanel tabKey={TAB.RAW} active={tab}>
                <FormGroupWithTitle
                  title={t("labels.EXPORT_KIND_TITLE")}
                  description={t("labels.EXPORT_KIND_DESCRIPTION")}
                >
                  <Controller
                    name="exportType"
                    as={
                      <RadioGroup
                        defaultValue="fragment"
                        aria-label="export-type"
                        row
                      >
                        <RadioWithLabel
                          value={EXPORT_TYPES.ALL}
                          label={t("labels.EXPORT_TYPES.ALL_META")}
                          icon={<FolderSpecialOutlinedIcon />}
                          checkedIcon={<FolderSpecialIcon />}
                        />
                      </RadioGroup>
                    }
                    control={control}
                  />
                </FormGroupWithTitle>
              </TabPanel>

              <FormGroupWithTitle title={t("labels.EXPORT_FILE_TYPE_TITLE")}>
                <Controller
                  name="fileType"
                  control={control}
                  as={
                    <RadioGroup
                      defaultValue="fragment"
                      aria-label="export-type"
                      row
                    >
                      <RadioWithLabel
                        label={t("labels.EXPORT_FILE_TYPES.EXCEL")}
                        value={FILE_TYPES.EXCEL}
                        icon={<Excel fill="gray" className="icon" />}
                        checkedIcon={
                          <Excel
                            fill={FILE_COLORS[FILE_TYPES.EXCEL]}
                            className="icon"
                          />
                        }
                      />

                      <RadioWithLabel
                        label={t("labels.EXPORT_FILE_TYPES.JSON")}
                        value={FILE_TYPES.JSON}
                        icon={<Json fill="gray" className="icon" />}
                        checkedIcon={
                          <Json
                            fill={FILE_COLORS[FILE_TYPES.JSON]}
                            className="icon"
                          />
                        }
                      />
                    </RadioGroup>
                  }
                />
              </FormGroupWithTitle>
            </FormControl>
            {error && <Alert severity="error">{error}</Alert>}
          </section>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="default">
            {t("buttons.CANCEL")}
          </Button>
          <Button
            className={fileType}
            type="submit"
            variant="contained"
            color="primary"
            startIcon={FILE_ICONS[fileType]}
            loading={isExporting}
          >
            {t("buttons.EXPORT")}
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};

ExportDialog.defaultProps = {
  open: false,
};

ExportDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  type: PropTypes.oneOf(["video", "series"]).isRequired,
};

export default memo(ExportDialog);
