import { useDispatch } from "react-redux";

// Redux
import { patchFragmentField } from "../reducer";

const useIndexableButton = ({ fragment }) => {
  const dispatch = useDispatch();

  // State
  const indexable = fragment?.indexable;

  // Listeners
  const onIndexableClick = ({ indexable }) => {
    dispatch(
      patchFragmentField({
        patchFieldKey: "indexable",
        id: fragment?.id,
        fields: { indexable },
      })
    );
  };

  return [indexable, onIndexableClick];
};

export default useIndexableButton;
