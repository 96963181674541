export const KeyMap = {
  General: {
    SAVE: ["enter", "ctrl+s", "command+s"],
  },
  AssetPreview: {
    PLAYER_SKIP_FRAME_LEFT: "left",
    PLAYER_SKIP_FRAME_RIGHT: "right",
    PLAYER_PAUSE: "space",
  },
};
