import { useMemo } from "react";
import { useParams } from "react-router-dom";

// Hooks
import { useQuery } from "features/Common/hooks";

export const useCurrentFragmentUrl = ({ withPanel, fragmentId } = {}) => {
  const { channelId, assetId, type } = useParams();
  const query = useQuery();
  const panel = query.get("panel");

  const url = useMemo(() => {
    let path = `/channel/${channelId}/${type}/${assetId}/fragment`;
    if (fragmentId) {
      path = `${path}/${fragmentId}`;
    }
    if (withPanel) return `${path}/${panel}`;
    return path;
  }, [channelId, fragmentId, assetId, type, panel, withPanel]);

  return url;
};

/**
 * Find subtitle file in files array and normalize by country
 * code.
 *
 * @param {*} files
 * [
 *  { name: 'portrait.png', type: { type: 'image' }},
 *  { name: 'sub.srt', locale: 'nl', type: { type: 'image' }}
 * ]
 * @return { nl: { name: 'sub.srt', locale: 'nl', type: { type: 'image' }} }
 */
export const useNormalizedSubtitleFiles = (files) =>
  useMemo(
    () =>
      files
        ?.filter((file) => file.type?.type === "subtitle")
        ?.reduce((subtitles, current) => {
          const { locale } = current;
          return {
            ...subtitles,
            [locale]: subtitles[locale]
              ? [...subtitles[locale], current]
              : [current],
          };
        }, {}) || {},
    [files]
  );

/**
 * Get form meta data from channel.
 */
export const useFragmentForm = (channel, fragment) =>
  useMemo(() => {
    if (!channel?.forms) return null;
    const { forms } = channel;
    const firstFormId = Object.keys(forms)[0];

    // Select first channel form when fragment has no
    // active form.
    const fragmentFormId = fragment?.form?.id;
    const id = fragmentFormId || firstFormId;

    return forms[id];
  }, [channel, fragment]);
