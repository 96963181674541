import { compose } from "redux";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import {
  withStyles,
  Button,
  DialogContent,
  Dialog,
  OutlinedInput,
  Icon,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { Component } from "../../../components";
import CustomTableContainer from "../../../components/CustomTable";
import { getUsers } from "../../Auth/user.service";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import AddUser from "./Form/AddUser";

import styles from "./List.styles";

class UserListContainer extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.initState({
      data: [],
      searchData: [],
      addUserDialogOpen: false,
    });

    this.columns = [
      {
        dataKey: "name",
        label: t("labels.NAME"),
        disablePadding: true,
        width: 1,
        flexGrow: 1,
      },
      {
        dataKey: "email",
        label: t("labels.EMAIL"),
        disablePadding: true,
        width: 1,
        flexGrow: 1,
      },
      {
        dataKey: "hasTwoFactor",
        label: t("labels.TWO_FACTOR"),
        disablePadding: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: ({ rowData }) =>
          this.renderTwoFactor.call(this, rowData),
      },
      {
        dataKey: "admin",
        label: t("roles.ADMIN"),
        disablePadding: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: ({ rowData }) =>
          this.renderAdmin.call(this, rowData),
      },
    ];
  }

  componentDidMount() {
    this.preload(this.getUsers());
  }

  getUsers() {
    getUsers().then((response) => {
      this.setState({
        data: response.data.data,
        searchData: response.data.data,
      });
    });
  }

  onAddUserDialog = () => {
    this.setState({ addUserDialogOpen: true });
  };

  onAddUserDialogClose = () => {
    this.setState({ addUserDialogOpen: false });
  };

  onRowClick = ({ rowData }) => {
    const { history } = this.props;
    return history.push(`/settings/users/${rowData.id}`);
  };

  renderAdmin(rowData) {
    const { admin, superadmin } = rowData;
    const { t } = this.props;

    let humanReadable = t("text.NO");

    if (superadmin) humanReadable = t("roles.SUPERADMIN");
    else if (admin) humanReadable = t("text.YES");

    return <div>{humanReadable}</div>;
  }

  renderTwoFactor(rowData) {
    const { hasTwoFactor } = rowData;
    const { t } = this.props;

    return <div>{hasTwoFactor ? t("text.YES") : t("text.NO")}</div>;
  }

  render() {
    const { t, classes } = this.props;
    const { loading, data } = this.state;
    const { addUserDialogOpen } = this.state;
    const { searchData } = this.state;

    const handleSearch = (e) => {
      const searchValue = e.target.value;
      const filtered = data.filter((d) =>
        d.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      this.setState({ searchData: filtered });
    };

    if (loading) return this.loading();

    return (
      <div className={classes.root}>
        <Breadcrumbs />
        <div className={classes.topHeader}>
          <div className={classes.titleHeader}>{t("titles.USER")}</div>
          <OutlinedInput
            variant="outlined"
            onChange={(value) => handleSearch(value)}
            placeholder={`${t("labels.SEARCH")}...`}
            className={classes.searchUser}
            endAdornment={
              <Icon edge="end">
                <SearchIcon color="gray" />
              </Icon>
            }
          />
          <div className={classes.addIcon}>
            <Button
              onClick={this.onAddUserDialog}
              variant="contained"
              color="primary"
              startIcon={<PersonAddIcon />}
            >
              {t("buttons.ADD_USER")}
            </Button>
          </div>
        </div>

        <div className={classes.table}>
          <CustomTableContainer
            items={searchData}
            columns={this.columns}
            onRowClick={this.onRowClick}
          />
        </div>

        <Dialog
          maxWidth="sm"
          fullWidth
          title={t("titles.ADD_USER")}
          open={addUserDialogOpen}
          onClose={this.onAddUserDialogClose}
        >
          <AppBar position="static">
            <Toolbar style={{ justifyContent: "space-between" }}>
              <Typography variant="h6" className={classes.title}>
                {t("titles.ADD_USER")}
              </Typography>
              <Tooltip title={t("labels.CLOSE")}>
                <IconButton
                  color="inherit"
                  aria-label="Close"
                  onClick={this.onAddUserDialogClose}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <AddUser />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

UserListContainer.propTypes = {};

export default compose(
  withStyles(styles),
  withTranslation(),
  connect()
)(UserListContainer);
