import { takeLatest, all, call, put } from "redux-saga/effects";

// Services
import {
  getExternalSubtitle as getExternalSubtitleRequest,
  uploadFile as uploadFileRequest,
  approveFile as approveFileRequest,
} from "./services";

// Actions
import {
  loadExternalSubtitle,
  loadExternalSubtitleSuccess,
  loadExternalSubtitleFailed,
  uploadFile,
  uploadFileSuccess,
  uploadFileFailed,
  approveFile,
  approveFileSuccess,
  approveFileFailed,
} from "./reducer";

// Sagas

export function* loadExternalSubtitleSaga({ payload: token }) {
  try {
    const result = yield call(getExternalSubtitleRequest, token);
    yield put(loadExternalSubtitleSuccess(result.data?.data));
  } catch (error) {
    yield put(loadExternalSubtitleFailed(error));
  }
}

export function* uploadFileSaga({ payload }) {
  try {
    const {
      authToken,
      acceptedFiles,
      short_description,
      description,
      fileId,
    } = payload;
    const result = yield call(uploadFileRequest, {
      authToken,
      acceptedFiles,
      fileId,
      short_description,
      description,
    });

    const data = {
      ...result.data?.data,
    };

    yield put(uploadFileSuccess(result.data?.data));
    yield put(loadExternalSubtitleSuccess(data));
  } catch (error) {
    yield put(uploadFileFailed({ errorMessage: "Something went wrong" }));
  }
}

export function* approveFileSaga({ payload }) {
  try {
    const { authToken, file, status } = payload;
    const result = yield call(approveFileRequest, { authToken, file, status });

    yield put(approveFileSuccess(result.data?.data));
    yield put(loadExternalSubtitleSuccess(result.data?.data));
  } catch (error) {
    yield put(approveFileFailed({ error }));
  }
}

function* sagas() {
  return yield all([
    takeLatest(loadExternalSubtitle.type, loadExternalSubtitleSaga),
    takeLatest(uploadFile.type, uploadFileSaga),
    takeLatest(approveFile.type, approveFileSaga),
  ]);
}

export default sagas;
