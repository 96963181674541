import React, { useState, useEffect } from "react";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import { usePersistentState } from "features/Common/hooks";
import { getListContentType } from "./SeoEntryPage.service";
import { useSeoEntry } from "../Hooks/useSeoEntry";

import StyledGrid from "./SeoEntryPageSidebar.style";

// Constants
import { STATE_COLORS } from "./constants";
import { Chip } from "@material-ui/core";
import ResizePanel from "components/ResizablePanel/ResizablePanel";

const DEFAULT_WIDTH = 120;
const SeoEntryPageSideBar = () => {
  const { t } = useTranslation();
  const [listContentType, setlistContentType] = useState([]);
  const [sideBarWidth, setSidebarWidth] = usePersistentState(
    DEFAULT_WIDTH,
    "seo_sidebar"
  );
  const { checkedFilters, setCheckedFilters, stateFilter, setStateFilters } =
    useSeoEntry();

  const handleChangeState = (event) => {
    setStateFilters({
      ...stateFilter,
      [event.target.name]: event.target.checked,
    });
  };

  const onDragStop = ({ size }) => {
    setSidebarWidth(size);
  };

  useEffect(() => {
    async function fetchData() {
      getListContentType({}).then((res) => {
        setlistContentType(res?.data?.data || []);
      });
    }
    fetchData();
  }, []);

  const handleChange = (id) => {
    const indexOption = checkedFilters.findIndex((value) => id === value);
    if (indexOption !== -1) {
      const splicedCheckedFilters = [...checkedFilters];
      splicedCheckedFilters.splice(indexOption, 1);
      setCheckedFilters(splicedCheckedFilters);
    } else {
      setCheckedFilters([...checkedFilters, id]);
    }
  };

  return (
    <ResizePanel
      direction="e"
      onDragStop={onDragStop}
      initialSize={sideBarWidth}
      minimumSize={120}
      maximumSize={300}
      overFlowCorrection={false}
    >
      <StyledGrid>
        <div className="filterGrid">
          <FormControl component="fieldset">
            <FormGroup>
              <FormLabel style={{ marginBottom: "8px" }}>
                {t("text.CONTENT_TYPE")}
              </FormLabel>
              {listContentType.map((contentType) => (
                <div className="" key={contentType.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={contentType.id}
                        checked={checkedFilters.find(
                          (value) => contentType.id === value.id
                        )}
                        onChange={() => handleChange(contentType.id)}
                        name={contentType.name}
                        color="primary"
                      />
                    }
                    label={contentType.name}
                  />
                </div>
              ))}
            </FormGroup>
            <FormGroup>
              <FormLabel
                style={{ marginTop: "16px", marginBottom: "8px" }}
                component="legend"
              >
                {t("text.STATE")}
              </FormLabel>
              <FormGroup>
                {Object.entries(stateFilter).map(([key, value]) => {
                  return (
                    <div className="state" key={key}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onChange={handleChangeState}
                            name={key}
                            color="primary"
                          />
                        }
                        label={
                          <Chip
                            label={key}
                            style={{
                              backgroundColor: STATE_COLORS[key].main,
                              color: STATE_COLORS[key].contrastText,
                              textTransform: "uppercase",
                              padding: "4px 0",
                              fontSize: "10px",
                              fontWeight: "bold",
                              borderRadius: "4px",
                              height: "auto",
                              width: "auto",
                              size: "small",
                            }}
                          />
                        }
                      />
                    </div>
                  );
                })}
              </FormGroup>
            </FormGroup>
          </FormControl>
        </div>
      </StyledGrid>
    </ResizePanel>
  );
};

export default SeoEntryPageSideBar;
