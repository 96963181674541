import { useCallback, useState, memo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useParams, Redirect } from "react-router-dom";
import { useQuery } from "features/Common/hooks";
import { useAuthentication } from "features/Auth/AuthProvider";

// Component
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "components/Form/TextField/TextField";
import { Button } from "components";

import dcLogo from "features/Common/img/logo.png";
import { passwordResetCreate, passwordReset } from "../user.service";
import StyledComponent from "./style";

const validationSchemaEmail = yup.object().shape({
  email: yup.string().email().required(),
});

const validationSchemaReset = yup.object().shape({
  password: yup.string().min(8).required(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const FormEmail = ({ defaultValues, onSubmit, requestLoading }) => {
  const { t } = useTranslation();

  const { handleSubmit, register, errors } = useForm({
    validationSchema: validationSchemaEmail,
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            name="email"
            label={t("labels.EMAIL")}
            placeholder={t("labels.EMAIL_ADDRESS")}
            variant="outlined"
            error={errors.email?.message}
            ref={register}
            fullWidth
          />
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" loading={requestLoading}>
            {t("buttons.SEND_EMAIL_REST_MAIL")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const FormToken = ({ defaultValues, onSubmit, requestLoading }) => {
  const { t } = useTranslation();

  const { handleSubmit, register, errors } = useForm({
    validationSchema: validationSchemaReset,
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            name="password"
            label={t("labels.PASSWORD")}
            variant="outlined"
            error={errors.password?.message}
            ref={register}
            type="password"
            fullWidth
          />
        </Grid>

        <Grid item>
          <TextField
            name="password_confirmation"
            label={t("labels.PASSWORD_CONFIRMATION")}
            variant="outlined"
            error={errors.password_confirmation?.message}
            ref={register}
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" loading={requestLoading}>
            {t("buttons.EDIT_PASSWORD")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const ForgetPassword = () => {
  const { t } = useTranslation();
  const [requestLoading, setRequestLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useParams();
  const query = useQuery();
  const { login, isAuthenticated } = useAuthentication();

  const onSubmitEmail = useCallback(
    (value) => {
      setRequestLoading(true);
      passwordResetCreate(value.email)
        .then((res) => {
          setRequestLoading(false);
          enqueueSnackbar(res?.data?.message, {
            variant: "success",
          });
        })
        .catch(() => setRequestLoading(false));
    },
    [enqueueSnackbar]
  );

  const onSubmitReset = useCallback(
    (value) => {
      setRequestLoading(true);
      passwordReset({
        ...value,
        token,
        email: query.get("email"),
      })
        .then((res) => {
          setRequestLoading(false);
          enqueueSnackbar(res?.data?.message, {
            variant: "success",
          });
          login(query.get("email"), value.password);
        })
        .catch(() => setRequestLoading(false));
    },
    [enqueueSnackbar, login, query, token]
  );

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <StyledComponent>
      <Helmet>
        <title>
          {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -{" "}
          {t("titles.REQUEST_PASSWORD")}
        </title>
      </Helmet>

      <Card className="card">
        <CardContent>
          <div className="centerContainer logoContainer">
            <img src={dcLogo} alt="DutchChannels Logo" width="150" />
          </div>

          {token ? (
            <FormToken
              onSubmit={onSubmitReset}
              requestLoading={requestLoading}
            />
          ) : (
            <>
              <div className="inputContainer">
                <Typography color="textSecondary" />

                <div className="centerContainer">
                  <Typography variant="h5" color="textSecondary">
                    {t("titles.REQUEST_PASSWORD")}
                  </Typography>
                </div>
              </div>
              <FormEmail
                onSubmit={onSubmitEmail}
                requestLoading={requestLoading}
              />
            </>
          )}
        </CardContent>
      </Card>
    </StyledComponent>
  );
};

export default memo(ForgetPassword);
