export const permissions = {
  actions: {
    VIEW: "view",
    CREATE: "create",
    UPDATE: "update",
    DELETE: "delete",
    EXPORT_ALL: "export-all",
    EXPORT_AXINOM: "export-axinom",
    CHANGE_STATUS: "change-status",
    CHECK_FULL_IDENTIFIER: "check-full-identifier",
    PUBLISH: "publish",
    UNPUBLISH: "unpublish",
  },
  subjects: {
    APP_SETTINGS: "settings",
    APP_SETTINGS_SCREENS: "settings",
    APP_SETTINGS_SCREENS_DETAIL: "settings",
    CHANNEL_SETTINGS: "channel-settings",
    CHANNEL_SETTINGS_SCREENS: "channel-settings",
    CHANNEL: "channel",
    INBOX: "inbox",
    VIDEO: "movie",
    SERIES: "series",
    ASSET: "asset",
    FRAGMENT: "asset-fragment",
    SEO: "seo-entry",
    SEO_ENTRY: "seo-entry",
    TOOLS: "tools",
    TRANSLATE: "asset-fragment-translate",
    FRAGMENT_NOT_FOUND: "asset-fragment",
  },
};
