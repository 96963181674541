import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useHistory } from "react-router-dom";

// Components
import { Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/Button";
import TextField from "components/Form/TextField";
import CharacterCount from "components/CharacterCount";
import { MdEditorWithControl } from "components/Form/MdEditor";

import Utils from "features/Common/utils";
import { useCurrentFragmentUrl } from "../hooks";

// Style
import StyledForm from "./style";

const IdentifierChangeDialog = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
      <DialogTitle>{t("labels.SURE_CHANGE_IDENTIFIER")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("text.SURE_CHANGE_IDENTIFIER")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          {t("buttons.OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const IDENTIFIER_SEPARATOR_LENGTH = 1;
const IDENTIFIER_ASSET_AND_FRAGMENT_MAX_LENGTH = 39;
const SHORT_DESCRIPTION_MAX_LENGTH = 180;

const IdentifierInput = ({
  errors,
  setValue,
  register,
  watch,
  assetIdentifier,
}) => {
  const { t } = useTranslation();
  const [identifierDialogOpen, setIdentifierDialogOpen] = useState(false);
  const [
    identifierWarningAlreadyShowed,
    setIdentifierWarningAlreadyShowed,
  ] = useState(false);

  const fragmentIdentifier = watch("identifier");

  const totalIdentifierLength = useMemo(() => {
    const assetIdentifierLength = assetIdentifier?.length || 0;
    const fragmentIdentifierLength = fragmentIdentifier?.length || 0;
    return (
      assetIdentifierLength +
      fragmentIdentifierLength +
      IDENTIFIER_SEPARATOR_LENGTH
    );
  }, [assetIdentifier, fragmentIdentifier]);

  const showChangeIdentifierWarning = () => {
    // Don't prompt if new asset
    if (identifierWarningAlreadyShowed) {
      return;
    }

    setIdentifierDialogOpen(true);
    setIdentifierWarningAlreadyShowed(true);
  };

  const onChangeHandler = (event) =>
    setValue("identifier", Utils.Assets.changeIdentifier(event.target.value));

  return (
    <>
      <TextField
        name="identifier"
        label={t("labels.IDENTIFIER")}
        variant="outlined"
        error={errors?.identifier?.message}
        onChange={onChangeHandler}
        ref={register}
        startAdornment={
          <InputAdornment position="end">{assetIdentifier}</InputAdornment>
        }
        endAdornment={
          <InputAdornment
            position="end"
            className={
              totalIdentifierLength > IDENTIFIER_ASSET_AND_FRAGMENT_MAX_LENGTH
                ? "error"
                : "success"
            }
          >
            {totalIdentifierLength || 0} /{" "}
            {IDENTIFIER_ASSET_AND_FRAGMENT_MAX_LENGTH}
          </InputAdornment>
        }
        onFocus={showChangeIdentifierWarning}
        fullWidth
      />
      <IdentifierChangeDialog
        open={identifierDialogOpen}
        onClose={() => setIdentifierDialogOpen(false)}
      />
    </>
  );
};

const FragmentInformationEdit = ({ asset, fragment, onSubmit }) => {
  const { id, name } = fragment;

  const { t } = useTranslation();
  const closeUrl = useCurrentFragmentUrl({ fragmentId: id });
  const history = useHistory();

  // Selectors
  const networkState = useSelector(
    (state) => state.fragment?.networkState?.videoInformation
  );

  const validationSchema = useMemo(() => {
    // subtract 1 from total length for - separator dash between fragment and
    // asset identifiers
    const maxIdentifierLength =
      IDENTIFIER_ASSET_AND_FRAGMENT_MAX_LENGTH -
        asset?.identifier?.length -
        IDENTIFIER_SEPARATOR_LENGTH || 0;

    return yup.object().shape({
      identifier: yup
        .string()
        .nullable()
        .max(
          maxIdentifierLength,
          t("error.FRAGMENT_IDENTIFIER_MAX", {
            max: IDENTIFIER_ASSET_AND_FRAGMENT_MAX_LENGTH,
          })
        ),
      name: yup.string().required(),
      short_description: yup
        .string()
        .nullable()
        .max(
          SHORT_DESCRIPTION_MAX_LENGTH + 10,
          t("error.FRAGMENT_SHORT_DESCRIPTION_MAX")
        ),
      description: yup.string().nullable(),
    });
  }, [t, asset]);

  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
    formState,
    reset,
  } = useForm({
    validationSchema,
    defaultValues: fragment,
  });

  const onSubmitHandler = (formData) => {
    const newFormData = Utils.Form.getDirtyValues({
      formData,
      dirtyFields: formState.dirtyFields,
    });

    onSubmit(newFormData);
  };

  // Invoke formState dirty values so that the proxy is
  // trigger/activated. Otherwise formState.isDirty doesn't work
  useEffect(() => {
    if (!formState) return;
    formState.dirtyFields.values();
    // eslint-disable-next-line
  }, []);

  // Reset default values on fragment change
  useEffect(() => {
    reset(fragment);
  }, [fragment, reset]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <IdentifierInput
            assetIdentifier={`${asset?.identifier}-`}
            watch={watch}
            register={register}
            errors={errors}
            setValue={setValue}
          />
        </Grid>
        <Grid item>
          <TextField
            name="name"
            label={t("labels.NAME")}
            variant="outlined"
            error={errors.name?.message}
            ref={register}
            fullWidth
          />
        </Grid>
        <Grid item>
          <MdEditorWithControl
            name="short_description"
            label={t("labels.SHORT_DESCRIPTION")}
            headerAdditionalInfo={
              <CharacterCount
                length={watch("short_description")?.length}
                graceLength={SHORT_DESCRIPTION_MAX_LENGTH - 10}
                maxLength={SHORT_DESCRIPTION_MAX_LENGTH}
              />
            }
            error={errors.short_description?.message}
            control={control}
            style={{ height: "300px" }}
          />
        </Grid>
        <Grid item>
          <MdEditorWithControl
            name="description"
            label={t("labels.DESCRIPTION")}
            error={errors.description?.message}
            control={control}
            style={{ height: "300px" }}
          />
        </Grid>

        <Grid item>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6}>
              <Button
                color="default"
                trackName="Fragment Information Edit Cancel"
                trackDetails={{ fragmentId: id, fragmentName: name }}
                onClick={() => history.push(closeUrl)}
              >
                {t("buttons.CANCEL")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                name="fragment.information.save"
                color="primary"
                type="submit"
                disabled={!formState.dirty}
                trackName="Fragment Information Edit Save"
                trackDetails={{ fragmentId: id, fragmentName: name }}
                loading={networkState === "updating"}
              >
                {t("buttons.SAVE")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledForm>
  );
};

FragmentInformationEdit.propTypes = {
  defaultValues: PropTypes.shape({
    identifier: PropTypes.string,
    name: PropTypes.string,
    short_description: PropTypes.string,
    description: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  asset: PropTypes.shape({}),
  fragment: PropTypes.shape({}),
};

export default FragmentInformationEdit;
