import { useState, useEffect } from "react";
import { TwitterPicker } from "react-color";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

// Icon
import DeleteIcon from "@material-ui/icons/Delete";

// Components
import IconButton from "@material-ui/core/IconButton";
import PosterLabel from "components/PosterLabel";
import Popover from "@material-ui/core/Popover";
import TextField from "components/Form/TextField/TextField";

// Hooks
import { usePrevious } from "features/Common/hooks";

// Utils
import Utils from "features/Common/utils";

// Local Components
import ColorCircle from "./ColorCircle";

// Style
import StyledComponent from "./style";
import { Typography } from "@material-ui/core";

const COLOR_PICKER_MODES = {
  BACKGROUND: "backgroundColor",
  TEXT: "textColor",
};

const COLORS = {
  [COLOR_PICKER_MODES.BACKGROUND]: [
    "#d80625",
    "#e1260d",
    "#e1270f",
    "#FFD700",
    "#FF8C00",
    "#000",
    "#FFFFFF",
  ],
  [COLOR_PICKER_MODES.TEXT]: ["#FFF", "#000"],
};

const LabelEditor = (props) => {
  const {
    value,
    defaultValues,
    placeholder,
    error,
    onChange,
    onDelete,
  } = props;
  const { id, text, textColor, backgroundColor } = value || defaultValues || {};

  const { t } = useTranslation();

  // State
  const prevBackgroundColor = usePrevious(backgroundColor);
  const [textColorChangedManually, setTextColorChangedManually] = useState(
    false
  );
  const [colorPickerMode, setColorPickerMode] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  // Popover
  const open = Boolean(anchorEl);
  const popoverId = open ? "color-picker-popover" : undefined;

  // Derived State
  const currentColor = (value || {})[colorPickerMode];
  const editorBackgroundColor =
    backgroundColor?.toLowerCase() === "#ffffff" ? "#f5f5f5" : "#FFFFFF";

  // Listeners
  const onOpenColorPicker = (event) => {
    const { name } = event.currentTarget;
    setColorPickerMode(name);
    setAnchorEl(event.currentTarget);
  };

  const onPopoverClose = () => {
    setAnchorEl(null);
  };

  const onTextChange = (event) => {
    const { value } = event.currentTarget;
    onChange({
      ...(!isNaN(id) && { id }),
      text: value,
      backgroundColor,
      textColor,
    });
  };

  const onColorChange = (color) => {
    const { hex } = color;

    if (colorPickerMode === COLOR_PICKER_MODES.TEXT) {
      setTextColorChangedManually(true);
    }

    onChange({
      ...(!isNaN(id) && { id }),
      text,
      backgroundColor,
      textColor,
      [colorPickerMode]: hex,
    });
  };

  // Change textColor based on background color
  useEffect(() => {
    if (
      !prevBackgroundColor ||
      textColorChangedManually ||
      prevBackgroundColor === backgroundColor
    ) {
      return;
    }

    const contrastTextColor = Utils.Color.getContrastTextColor(backgroundColor);

    onChange({
      ...(!isNaN(id) && { id }),
      text,
      backgroundColor,
      textColor: contrastTextColor,
    });
  }, [
    prevBackgroundColor,
    backgroundColor,
    textColorChangedManually,
    id,
    text,
    textColor,
    onChange,
  ]);

  return (
    <StyledComponent backgroundColor={editorBackgroundColor}>
      <header>
        <PosterLabel
          className="poster-label"
          text={text}
          textColor={textColor}
          placeholder={placeholder}
          backgroundColor={backgroundColor}
        />
        <aside>
          <IconButton aria-label="delete" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </aside>
      </header>

      <section className="editor">
        <TextField
          name="text"
          label="Text"
          size="small"
          type="text"
          defaultValue={text}
          fullWidth
          onChange={onTextChange}
          error={error?.text?.message}
        />
        <div className="color-buttons">
          <button
            className={classNames(["color-circle-button"], {
              hasError: Boolean(error?.textColor),
            })}
            name={COLOR_PICKER_MODES.TEXT}
            type="button"
            onClick={onOpenColorPicker}
          >
            <ColorCircle
              hasError={Boolean(error?.textColor)}
              color={textColor}
            />
            <Typography variant="h3">{t("labels.LABEL_TEXT_COLOR")}</Typography>
          </button>
          <button
            className={classNames(["color-circle-button"], {
              hasError: Boolean(error?.backgroundColor),
            })}
            name={COLOR_PICKER_MODES.BACKGROUND}
            type="button"
            onClick={onOpenColorPicker}
          >
            <ColorCircle
              hasError={Boolean(error?.backgroundColor)}
              color={backgroundColor}
            />
            <Typography variant="h3">
              {t("labels.LABEL_BACKGROUND_COLOR")}
            </Typography>
          </button>
        </div>
        {(error?.textColor || error?.backgroundColor) && (
          <ul className="color-errors">
            {error?.textColor && (
              <li>
                <Typography variant="body1">
                  {error?.textColor?.message}
                </Typography>
              </li>
            )}
            {error?.backgroundColor && (
              <li>
                <Typography variant="body1">
                  {error?.backgroundColor?.message}
                </Typography>
              </li>
            )}
          </ul>
        )}
      </section>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="color-picker-container">
          <TwitterPicker
            colors={COLORS[colorPickerMode]}
            color={currentColor}
            triangle="hide"
            onChange={onColorChange}
          />
        </div>
      </Popover>
    </StyledComponent>
  );
};

LabelEditor.defaultValues = {
  onChange: () => {},
  onDelete: () => {},
  placeholder: "text",
  value: {
    text: "",
    textColor: "#FFFFFF",
    backgroundColor: "#000000",
  },
};

LabelEditor.propTypes = {
  value: PropTypes.shape({
    text: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
  defaultValues: PropTypes.shape({
    text: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  error: PropTypes.shape({
    text: PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.string,
    }),
    textColor: PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.string,
    }),
    backgroundColor: PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default LabelEditor;
