import PropTypes from "prop-types";

// Components
import PaginationItem from "@material-ui/lab/PaginationItem";

const getButtonType = (key) => {
  if (key === "next" || key === "previous") return key;
  if (typeof key === "string" && key.indexOf("ellipsis") > -1)
    return "start-ellipsis";
  return "page";
};

const PaginationBox = ({ items, toggleItem }) => (
  <div>
    {items?.map(({ key, page, active }) => {
      const type = getButtonType(key);

      return (
        <PaginationItem
          key={key}
          selected={active}
          shape="rounded"
          page={page}
          type={type}
          onClick={() => toggleItem(key)}
        />
      );
    })}
  </div>
);

PaginationBox.defaultProps = {
  toggleItem: () => null,
};

PaginationBox.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  toggleItem: PropTypes.func,
};

export default PaginationBox;
