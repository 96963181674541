import { AllInApi } from "../../Common/services/api.service";
import * as Constants from "../../Common/constants";

const api = AllInApi.getApi();

export const getFragment = (channelId, fragmentId, lang) => {
  const langParameter = lang ? `?lang=${lang}` : "";

  return api.get(
    Constants.API_ENDPOINT_FRAGMENT.replace(":id", channelId).replace(
      ":fragment",
      fragmentId
    ) + langParameter
  );
};

export const updateState = (channelId, fragmentId, state) =>
  api.post(
    Constants.API_ENDPOINT_FRAGMENT_STATE.replace(":id", channelId).replace(
      ":fragment",
      fragmentId
    ),
    state
  );

export const getToBeTranslated = (channelId) =>
  api.get(Constants.API_ENDPOINT_FRAGMENT_TRANSLATE.replace(":id", channelId));

export const saveFragment = (channelId, fragment) =>
  api.put(
    Constants.API_ENDPOINT_FRAGMENT.replace(":id", channelId).replace(
      ":fragment",
      fragment.id
    ),
    { ...fragment }
  );

export const lockFragment = (channelId, fragment) =>
  api.get(
    Constants.API_ENDPOINT_FRAGMENT_LOCK.replace(":id", channelId).replace(
      ":fragment",
      fragment
    )
  );

export const unlockFragment = (channelId, fragment) =>
  api.get(
    Constants.API_ENDPOINT_FRAGMENT_UNLOCK.replace(":id", channelId).replace(
      ":fragment",
      fragment
    )
  );

export const nextFragment = (channelId) =>
  api.get(Constants.API_ENDPOINT_FRAGMENT_NEXT.replace(":id", channelId));
