import styled from "styled-components";

export default styled.div`
  --border-width: ${({ borderWidth }) => borderWidth}px;
  --border-color: ${({ borderColor }) => borderColor};
  --success: ${({ successColor }) => successColor};
  --success-background: ${({ successBackground }) => successBackground};
  --success-border-color: ${({ successBorderColor }) => successBorderColor};
  --loader-size: ${({ size }) => size}px;
  --check-height: ${({ size }) => size / 2.5}px;
  --check-width: ${({ size }) => size / 4.5}px;
  --check-thickness: ${({ checkThickness }) => checkThickness}px;
  --check-left: ${({ size }) => size / 6}px;
  --check-color: var(--success);
  --background: ${({ backgroundColor }) => backgroundColor};

  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  flex-shrink: 0;
  border: var(--border-width) solid var(--border-color);
  border-left-color: var(--check-color);
  animation: loader-spin 1.2s infinite linear;
  width: var(--loader-size);
  height: var(--loader-size);
  background: var(--background) !important;

  &.loading-complete {
    -webkit-animation: none;
    animation: none;
    border-color: var(--success-border-color);
    opacity: 0;
    transition: opacity ease-out 0.2s;
    transition-delay: 2s;
    background: var(--success-background) !important;
  }

  &.loading-complete .check-mark {
    display: flex;
  }

  .check-mark {
    display: none;
    opacity: 1;
    transform: translate(50%, 50%);
    margin-left: -${({ size }) => size / 2}px;
    margin-top: ${({ size }) => size / 2}px;

    &.draw:after {
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-name: check-mark;
      transform: scaleX(-1) rotate(135deg);
    }

    &:after {
      opacity: 1;
      height: var(--check-height);
      width: var(--check-width);
      transform-origin: left top;
      border-right: var(--check-thickness) solid var(--check-color);
      border-top: var(--check-thickness) solid var(--check-color);
      content: "";
      position: absolute;
    }
  }

  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes check-mark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: var(--check-width);
      opacity: 1;
    }
    40% {
      height: var(--check-height);
      width: var(--check-width);
      opacity: 1;
    }
    100% {
      height: var(--check-height);
      width: var(--check-width);
      opacity: 1;
    }
  }
`;
