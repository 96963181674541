import styled from "styled-components";

export default styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 320px;
  align-items: center;

  & .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .button {
    width: auto;
  }
`;
