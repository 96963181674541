import { put, call } from "redux-saga/effects";
import * as channelActions from "./Channel.actions";

export function* setConfig(channelApi, { channelId }) {
  if (!channelId) return null;

  try {
    const configResponse = yield call(channelApi.getChannel, channelId);

    if (configResponse.status !== 200) {
      return null;
    }

    return yield put(channelActions.setConfig(configResponse.data));
  } catch (error) {
    return null;
  }
}
