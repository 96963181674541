import styled from "styled-components";
import TableRow from "@material-ui/core/TableRow";

export default styled(TableRow)`
  cursor: pointer;
  transition: background-color ease-out 300ms;

  & .MuiTableCell-root {
    position: relative;
    padding: 6px 8px !important;
  }

  & .MuiTypography-root {
    font-weight: bold;
  }

  & .tree-lines {
    position: absolute;
    border-left: 1px dotted ${({ theme }) => theme.palette.grey[500]};

    top: 0;
    left: 32px;
    bottom: 1px;
  }

  & .align--center {
    text-align: center;
  }

  /* ==== Custom Cell ==== */

  & .cell--name {
    /* min-width: 250px; */
    padding-left: 72px !important;

    & > div {
      display: flex;
      align-items: center;
    }
  }

  & .cell--drm {
    width: 80px;
  }
`;
