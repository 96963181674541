import * as types from "./Log.types";

export const find = (searchParams) => ({
  type: types.FIND,
  searchParams,
});

export const findSuccess = (data) => ({ type: types.FIND_SUCCESS, data });

export const findFailure = () => ({ type: types.FIND_FAILURE });

export const reset = () => ({ type: types.RESET });
