import { forwardRef } from "react";
import PropTypes from "prop-types";

import FormHelperText from "@material-ui/core/FormHelperText";
import { Typography } from "@material-ui/core";
import MdEditorLite from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";

import FormControlStyled from "./style";

const MdEditor = forwardRef(
  (
    {
      error,
      name,
      value,
      placeholder,
      className,
      onChange,
      label,
      headerAdditionalInfo,
      plugins,
      inputFormatter,
      style,
    },
    ref
  ) => (
    <FormControlStyled
      className={className}
      error={!!error}
      style={{ width: "100%" }}
    >
      <header>
        <Typography variant="h5" color={error ? "error" : "textPrimary"}>
          {label}
        </Typography>
        {headerAdditionalInfo && <div>{headerAdditionalInfo}</div>}
      </header>
      <MdEditorLite
        value={inputFormatter(value)}
        onChange={onChange}
        ref={ref}
        className={className}
        placeholder={placeholder}
        name={name}
        plugins={plugins}
        style={style}
        config={{
          view: { menu: true, md: true, html: false },
        }}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControlStyled>
  )
);

MdEditor.displayName = "MdEditor";

MdEditor.defaultProps = {
  plugins: ["fonts", "header", "link", "full-screen"],
  inputFormatter: (value) => value,
};

MdEditor.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  plugins: PropTypes.arrayOf(PropTypes.string),
  inputFormatter: PropTypes.func,
  headerAdditionalInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default MdEditor;
