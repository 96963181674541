import { memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import cls from "classnames";

// Utils
import Utils from "features/Common/utils";

// Icons
import GetAppIcon from "@material-ui/icons/GetApp";

// Components
import StatusLabel from "components/StatusLabel";
import DownloadLink from "components/DownloadLink";
import NationFlag from "components/NationFlag";
import Typography from "@material-ui/core/Typography";

const Subtitle = ({
  name,
  locale,
  createdAt,
  path,
  fragmentName,
  status,
  flag,
}) => {
  const { t } = useTranslation();
  const url = Utils.Fragments.getAssetStoragePath(path?.path);
  const createDateFrom = moment.unix(createdAt).fromNow();

  return (
    <DownloadLink
      className={cls({
        "download-button": true,
        flag: flag != null,
      })}
      href={url}
      name={name}
      trackName="external.subtitle.download.subtitle"
      trackDetails={{
        url,
        name,
        fragmentName,
        path,
        locale,
        type: "external.subtitle.download.subtitle",
        status,
      }}
    >
      {flag && <div className="flag">{flag}</div>}
      <div className="link-info">
        <NationFlag countryCode={locale} />
        <div className="details">
          <Typography variant="h6">{name}</Typography>
          <div className="created">
            <Typography variant="body1">{createDateFrom} </Typography>
          </div>
        </div>
      </div>
      <div className="link-info">
        <StatusLabel
          status={status}
          label={t(`labels.${status.toUpperCase()}`)}
        />
        <GetAppIcon className="download-icon" size="small" />
      </div>
    </DownloadLink>
  );
};

export default memo(Subtitle);
