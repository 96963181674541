import { INITIAL_STATE } from "./Fragment.state";

const networkStateResolver = {
  // Get network state for image files
  image: ({ state, fragmentId, type, typeId, networkState, errors }) => ({
    ...state.networkState[fragmentId],
    [type]: {
      [typeId]: {
        state: networkState,
        errors,
      },
    },
  }),
  // Get network state for subtitle files
  subtitle: ({ state, fragmentId, type, locale, networkState, errors }) => ({
    ...state.networkState[fragmentId],
    [type]: {
      [locale]: {
        state: networkState,
        errors,
      },
    },
  }),
};

const getNewNetworkState = (networkStateParams) => {
  const { type } = networkStateParams;
  const resolver = networkStateResolver[type];
  return resolver ? resolver(networkStateParams) : {};
};

export const find = (state, { assetId, fragmentId }) =>
  state.merge({
    loading: true,
    assetId,
    fragmentId,
  });

export const findSuccess = (state, { data }) =>
  state.merge({
    data,
    loading: false,
  });

export const findFailure = (state) => state;

export const reset = () => INITIAL_STATE;

export const findTranslate = (state) =>
  state.merge({
    translate: {
      loading: true,
    },
  });

export const findTranslateSuccess = (state, { data }) =>
  state.merge({
    translate: {
      loading: false,
      data,
    },
  });

export const findTranslateFailure = (state) => state;

export const setFadeIn = (state, { fadeInTime }) =>
  state.merge({
    data: {
      ...state.data,
      fade_in: fadeInTime,
    },
  });

export const setFadeOut = (state, { fadeOutTime }) =>
  state.merge({
    data: {
      ...state.data,
      fade_out: fadeOutTime,
    },
  });

export const setSnapshot = (state, { snapshotTime }) =>
  state.merge({
    data: {
      ...state.data,
      snapshot_time: snapshotTime,
    },
  });

export const setFile = (state, { file }) =>
  state.merge({
    data: {
      ...state.data,
      file,
    },
  });

export const loadFragment = (state, { payload }) => {
  const { showLoader = true } = payload;
  state.selected.loading = showLoader;
};

export const deleteFragment = (state) => {
  state.selected.deleting = true;
  state.selected.deleteError = null;
};

export const deleteFragmentSuccess = (state) => {
  state.selected.deleting = false;
};

export const deleteFragmentFailed = (state, { payload }) => {
  const { error } = payload;
  state.selected.deleting = false;
  state.selected.deleteError = error;
};

export const loadFragmentSuccess = (state, { payload }) => {
  const { files, fragment, meta, labels } = payload;

  state.selected.loading = false;

  // Remove fragment files because because it's deprecated
  delete fragment.files;
  // Remove fragment values because it's deprecated
  delete fragment.values;

  const newMeta = meta && Array.isArray(meta) ? [...meta] : [];

  state.selected.data = {
    ...state.selected.data,
    ...fragment,
    meta: newMeta,
    labels,
  };

  // Update files
  if (files) {
    state.selected.files = files;
  }
};

export const loadFragmentFailed = (state) => {
  state.selected.loading = false;
};

export const resetFragment = (state) => {
  state.selected = {
    loading: false,
    data: null,
  };
};

export const loadTranslatableFragments = (state) => {
  state.translatable.loading = true;
};

export const loadTranslatableFragmentsSuccess = (state, { payload }) => {
  state.translatable.data = payload;
  state.translatable.loading = false;
};

export const loadTranslatableFragmentsFailed = (state) => {
  state.translatable.loading = false;
};

export const uploadFile = (state, { payload }) => {
  const { fragmentId, typeId, type, locale } = payload;
  state.networkState[fragmentId] = getNewNetworkState({
    state,
    fragmentId,
    type,
    typeId,
    locale,
    networkState: "loading",
  });
};

export const uploadFileSuccess = (state, { payload }) => {
  const { fragmentId, typeId, newFile, type, locale } = payload;
  const newState = {
    ...state,
  };

  // Set loading state
  newState.networkState[fragmentId] = getNewNetworkState({
    state,
    fragmentId,
    type,
    typeId,
    locale,
    networkState: "completed",
  });

  // Update files array in fragments.files
  const newFileType = newFile.type.type;
  const files = [...newState.selected?.files];
  let index = -1;

  if (newFileType === "image") {
    index = files.findIndex((file) => file.type.id === newFile.type.id);
  }
  if (newFileType === "subtitle") {
    index = files
      .filter((file) => file.type.identifier === "subtitle")
      .findIndex((file) => file.locale === newFile.locale);
  }

  if (index !== -1) {
    // update file
    files[index] = newFile;
  } else {
    // new file
    files.push(newFile);
  }

  state.selected.files = files;
};

export const uploadFileFailed = (state, { payload }) => {
  const { fragmentId, typeId, error, type, locale } = payload;
  const { errors } = error?.response?.data || {};

  state.networkState[fragmentId] = getNewNetworkState({
    state,
    fragmentId,
    type,
    typeId,
    locale,
    errors,
    networkState: "error",
  });
};

export const deleteFile = (state, { payload }) => {
  const { fragmentId, typeId, type, locale } = payload;
  state.networkState[fragmentId] = getNewNetworkState({
    state,
    fragmentId,
    type,
    typeId,
    locale,
    networkState: "deleting",
  });
};

export const deleteFileSuccess = (state, { payload }) => {
  const { fragmentId, typeId, type, locale } = payload;
  const newState = {
    ...state,
  };
  // Set loading state
  newState.networkState[fragmentId] = getNewNetworkState({
    state,
    fragmentId,
    type,
    typeId,
    locale,
    networkState: "deleted",
  });

  // Update file arrays
  let newFiles = [];
  if (type === "image") {
    newFiles = newState.selected?.files?.filter(
      (file) => file.type.id !== typeId
    );
  }
  if (type === "subtitle") {
    newFiles = newState.selected?.files?.filter(
      (file) => file.locale !== locale
    );
  }

  state.selected.files = [...newFiles];
};

export const deleteFileFailed = (state, { payload }) => {
  const { fragmentId, typeId, type, locale, error } = payload;
  const { errors } = error?.response?.data || {};
  state.networkState[fragmentId] = getNewNetworkState({
    state,
    fragmentId,
    type,
    typeId,
    locale,
    errors,
    networkState: "error",
  });
};

export const updateSource = (state, { payload }) => {
  state.selected.data.file = payload;
  state.selected.data.fade_in = null;
  state.selected.data.fade_out = null;
  state.selected.data.snapshot_time = null;
  state.selected.data.intro_in = null;
  state.selected.data.intro_out = null;
  state.selected.data.duration = null;
};

export const updateState = (state, { payload }) => {
  state.selected.data.state = payload?.state;
};

export const updateStateFailed = (state, { payload }) => {
  state.selected.data.state = payload?.state;
};

export const updateVideoCuePoints = (state, { payload }) => {
  const { type, value, videoDuration } = payload;
  state.selected.data[type] = value;

  // Recalculate duration
  const { selected } = state;
  const { data } = selected;
  const fadeIn = data.fade_in;
  const fadeOut = data.fade_out;
  const duration = (fadeOut || videoDuration) - (fadeIn || 0);
  state.selected.data.duration = duration;
};

export const patchFragment = (state) => {
  state.networkState = {
    ...state.networkState,
    videoInformation: "updating",
  };
};

export const patchFragmentSuccess = (state, { payload }) => {
  state.networkState = {
    ...state.networkState,
    videoInformation: "idle",
  };

  state.selected.data = { ...state.selected.data, ...payload };
};

export const patchFragmentFailed = (state) => {
  state.networkState = {
    ...state.networkState,
    videoInformation: "idle",
  };
};

export const patchFragmentField = (state, { payload }) => {
  const { patchFieldKey } = payload;
  const newValue = payload?.fields[patchFieldKey];

  const backupKey = `${patchFieldKey}_prev`;
  const backupValue = state.selected.data[patchFieldKey];

  state.networkState = {
    ...state.networkState,
    [patchFieldKey]: "updating",
  };

  state.selected.data = {
    ...state.selected.data,
    [patchFieldKey]: newValue,
    [backupKey]: backupValue,
  };
};

export const patchFragmentFieldSuccess = (state, { payload }) => {
  const { patchFieldKey } = payload;
  const newValue = payload[patchFieldKey];

  state.networkState = {
    ...state.networkState,
    [patchFieldKey]: "idle",
  };
  state.selected.data = {
    ...state.selected.data,
    [patchFieldKey]: newValue,
  };

  // Delete backup
  delete state.selected.data[`${patchFieldKey}_prev`];
};

export const patchFragmentFieldFailed = (state, { payload }) => {
  const { patchFieldKey } = payload;
  const backupKey = `${patchFieldKey}_prev`;

  state.networkState = {
    ...state.networkState,
    [patchFieldKey]: "idle",
  };

  // Restore backup
  state.selected.data[patchFieldKey] = state.selected.data[backupKey];
};

export const patchFragmentLabels = (state) => {
  state.networkState = {
    ...state.networkState,
    labels: "updating",
  };
};

export const patchFragmentLabelsSuccess = (state, { payload }) => {
  const { labels } = payload;
  state.networkState = {
    ...state.networkState,
    labels: "idle",
  };
  state.selected.data.labels = labels;
};

export const patchFragmentLabelsFailed = (state) => {
  state.networkState = {
    ...state.networkState,
    labels: "error",
  };
};

export const patchFragmentMetaValues = (state) => {
  state.networkState = {
    ...state.networkState,
    meta: "updating",
  };
};

export const patchFragmentMetaValuesSuccess = (state, { payload }) => {
  state.networkState = {
    ...state.networkState,
    meta: "idle",
  };

  state.selected.data.meta = [...payload];
};

export const patchFragmentMetaValuesFailed = (state) => {
  state.networkState = {
    ...state.networkState,
    meta: "error",
  };
};

export const createFragment = (state) => {
  state.networkState = {
    ...state.networkState,
    createFragment: "creating",
  };
};

export const openFragmentCreateDialog = (state) => {
  state.ui.fragmentCreateDialogOpen = true;
};

export const closeFragmentCreateDialog = (state) => {
  state.ui.fragmentCreateDialogOpen = false;
};
