import grey from "@material-ui/core/colors/grey";

const style = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    width: "100%",
    flexGrow: 1,
  },

  topHeader: {
    display: "flex",
    alignItems: "center",
    padding: "5px 15px",
    backgroundColor: "rgba(0 , 0, 0, .02)",
    borderBottom: `1px solid ${grey[300]}`,
  },

  title: {
    flex: 1,
  },

  row: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  typography: {
    padding: theme.spacing(),
  },

  formControl: {
    flex: 1,
    margin: theme.spacing(),
  },

  paper: {
    width: "20%",
  },
});

export default style;
