import grey from "@material-ui/core/colors/grey";

const style = ({ spacing }) => ({
  layoutFill: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    width: "100%",
    flexGrow: 1,
  },
  topHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 15,
    backgroundColor: "rgba(0 , 0, 0, .02)",
    borderBottom: `1px solid ${grey[300]}`,
    height: 56,
  },

  titleHeader: {
    flex: 1,
    display: "flex",
  },

  input: {
    marginLeft: spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },

  searchContainer: {
    flex: 1,
    display: "flex",
    backgroundColor: " rgba(255,255,255,0.6)",
    borderRadius: 4,
    marginRight: spacing(1),
  },
  rootPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    background: "none",
    boxShadow: "none",
  },
  searchHeader: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },

  totalsHeader: {
    display: "flex",
    flex: "none",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default style;
