import { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

import MaskedInput from "react-text-mask";

import * as Constants from "../../../features/Common/constants";
import Utils from "../../../features/Common/utils";

import styles from "./style";

function TextMaskCustom(props) {
  // eslint-disable-next-line react/prop-types
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      showMask
    />
  );
}

class TextFieldComponent extends Component {
  render() {
    const {
      classes,
      field: { name, value, ...field },
      form: { touched, errors },
      label,
      required,
      millisecondsMask,
      maxLength,
      inputProps,
      InputProps,
      helperText,
      errorText,
      ...props
    } = this.props;

    const error =
      Boolean(Utils.Common.flattenObject(errors)[name]) &&
      Utils.Common.flattenObject(touched)[name];

    let InputMask;
    let mask;
    if (millisecondsMask === true) {
      InputMask = TextMaskCustom;
      mask = Constants.MILLISECONDS_MASK;
    }

    let maxLengthProps;
    if (maxLength > 0) {
      maxLengthProps = {
        helperText: `${value ? value.length : 0} / ${maxLength}`,
        FormHelperTextProps: {
          classes: {
            root: classes.helperText,
          },
        },
      };
    }

    return (
      <TextField
        label={label}
        id={name}
        margin="normal"
        InputLabelProps={{
          shrink: Boolean(value),
        }}
        {...field}
        {...props}
        required={required}
        value={value === null ? "" : value}
        error={error}
        helperText={error ? errorText : helperText}
        InputProps={{
          inputComponent: InputMask,
          inputProps: {
            mask,
            maxLength,
            ...inputProps,
          },
          ...InputProps,
        }}
        {...maxLengthProps}
      />
    );
  }
}

TextFieldComponent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }),
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  millisecondsMask: PropTypes.bool,
  maxLength: PropTypes.number,
  helperText: PropTypes.string,
  errorText: PropTypes.string,
  inputProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
};

export default compose(withStyles(styles))(TextFieldComponent);
