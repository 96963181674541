import styled from "styled-components";

const StyledComponent = styled.div`
  & .MuiGrid-item {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
  .MuiTypography-root {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.grey[600]};
    font-size: 0.875rem;
  }
  .MuiTypography-h5 {
    color: ${({ theme }) => theme.palette.grey[700]};
    font-weight: 700;

    .MuiSvgIcon-root {
      margin-right: 2px;
    }
  }
  .MuiTypography-h6 {
    margin-bottom: 4px;
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 1rem;
  }

  .drop-zone {
    & > div {
      background: white;
    }

    & .text {
      color: ${({ theme }) => theme.palette.grey[400]};
    }
  }

  & .download-link {
    display: flex;
    margin-top: ${({ theme }) => theme.spacing(0.5)}px;
    padding: 0 4px;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.grey[900]};

    & .MuiSvgIcon-root {
      font-size: 1.25rem;
      margin-left: 4px;
    }

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }
`;

export default StyledComponent;
