import { call, put } from "redux-saga/effects";
import * as logActions from "./Log.actions";

export function* find(logApi, { searchParams }) {
  try {
    const logResponse = yield call(logApi.getLog, searchParams);

    if (!logResponse.status === 200) {
      return yield put(logActions.findFailure());
    }

    return yield put(logActions.findSuccess(logResponse.data));
  } catch (error) {
    yield put(logActions.findFailure());
  }

  return false;
}
