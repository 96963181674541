/* eslint-disable */
import { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import CustomTableContainer from "../../../components/CustomTable";
import CircularProgressCenter from "../../../components/CircularProgressCenter";
import Label from "../../../components/Label";

class InboxOverviewView extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        dataKey: "name",
        label: "Naam",
        disablePadding: true,
        width: 1,
        flexGrow: 1,
      },
      {
        dataKey: "licenses",
        label: "labels.LICENSE",
        disablePadding: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: this.renderLicenses,
      },
      {
        dataKey: "state",
        label: "Status",
        rightAlign: true,
        disablePadding: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: this.renderState,
      },
    ];
  }

  renderLicenses({ rowData }) {
    const { license } = rowData;

    return <div>{license.name}</div>;
  }

  renderState({ rowData }) {
    const { uploaded } = rowData;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {uploaded &&
          uploaded.object &&
          uploaded.object.format &&
          uploaded.object.format.size && (
            <Label value={uploaded.object.format.size} type="file_size" />
          )}
        {uploaded &&
          uploaded.object &&
          uploaded.object.format &&
          uploaded.object.format.duration && (
            <Label value={uploaded.object.format.duration} type="duration" />
          )}
        {uploaded && uploaded.object.streams && (
          <Label value={uploaded.object.streams} type="resolution" />
        )}
        {uploaded && uploaded.created_at && (
          <Label
            value={moment
              .unix(uploaded.created_at)
              .format("DD/MM/YYYY H:mm:ss")}
          />
        )}
      </div>
    );
  }

  render() {
    const { inbox, handleListClick } = this.props;

    if (inbox.loading) return <CircularProgressCenter />;

    return (
      <div style={{ flex: "1 1 auto" }}>
        <CustomTableContainer
          items={inbox.data}
          columns={this.columns}
          onRowClick={handleListClick}
        />
      </div>
    );
  }
}

InboxOverviewView.propTypes = {
  inbox: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default InboxOverviewView;
