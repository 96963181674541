import { AllInApi } from "../Common/services/api.service";

const api = AllInApi.getApi();

export const getInbox = (query) =>
  api.get(`${AllInApi.getApiChannelPath()}/inbox`, {
    params: { query },
  });

export const updateInbox = (data) =>
  api.post(`${AllInApi.getApiChannelPath()}/inbox`, data);

export const deleteInbox = (file) =>
  api.delete(`${AllInApi.getApiChannelPath()}/inbox/${file}`);
