/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, cloneElement, memo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import pretty from "prettysize";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Link from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";

import SpeedDial from "@material-ui/lab/SpeedDial";
import FilterListIcon from "@material-ui/icons/FilterList";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SyncIcon from "@material-ui/icons/Sync";
import PhotoIcon from "@material-ui/icons/Photo";
import PublishIcon from "@material-ui/icons/Publish";
import StorageIcon from "@material-ui/icons/Storage";

import { actionToColor } from "../utils";
import { useJob } from "./JobProvider";

const actions = [
  {
    name: "status.jobs.SYNC",
    command_id: 1,
    icon: <SyncIcon />,
  },
  {
    name: "status.jobs.ENCODE",
    command_id: 2,
    icon: <StorageIcon />,
  },
  {
    name: "labels.SNAPSHOT",
    command_id: 6,
    icon: <PhotoIcon />,
  },
  {
    name: "status.jobs.UPLOAD",
    command_id: 5,
    icon: <PublishIcon />,
  },
];

const renderValue = (key, value) => {
  switch (key) {
    case "file_size":
      return pretty(value);
    case "size":
      return pretty(value);
    case "bit_rate":
      return `${pretty(value)}/s`;
    case "date":
      return moment.unix(value).format("DD-MM-YYYY H:mm:ss");
    case "duration":
      return `${Math.round(value / 60)} min`;
    default:
      return JSON.stringify(value).replace('"', "").replace('"', "");
  }
};

const TimelineItem = ({ log }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [showStreams, setShowStreams] = useState(false);

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Chip
            className="statusButton"
            size="small"
            style={{ backgroundColor: actionToColor(log.action) }}
            label={log.action}
          />
        </ListItemAvatar>
        <ListItemText
          primary={`${
            log.preset
              ? `${log?.command?.name} ${log.preset}`
              : log?.command?.name
          } #${log.jobId}`}
          secondary={`${moment
            .unix(log.updated_at)
            .format("DD MMM YYYY H:mm:ss")} ${t("text.BY")} ${
            log?.user?.name || "system"
          }`}
        />
        {log.object && (
          <ListItemSecondaryAction onClick={() => setOpen(!open)}>
            <IconButton edge="end" aria-label="delete">
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {log.object && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem>
              <ul className="timelineObject">
                {Object.keys(log.object).map((key) => {
                  if (
                    [
                      "format",
                      "streams",
                      "config",
                      "file",
                      "encode",
                      "sprite",
                    ].indexOf(key) > -1
                  )
                    return null;

                  return (
                    <li key={key}>
                      {key === "command" ? (
                        <code>{log.object[key]}</code>
                      ) : (
                        <span>
                          <strong>{key}</strong>:{" "}
                          {renderValue(key, log.object[key])}
                        </span>
                      )}
                    </li>
                  );
                })}

                {log.object.encode && (
                  <li>
                    <span>
                      <strong>{t("status.jobs.ENCODE")}</strong>
                    </span>
                    <ul>
                      {Object.keys(log.object.encode).map(
                        (key) =>
                          log.object.encode[key] && (
                            <li key={key}>
                              <strong>{key}</strong>:{" "}
                              {renderValue(key, log.object.encode[key])}
                            </li>
                          )
                      )}
                    </ul>
                  </li>
                )}

                {log.object.sprite && (
                  <li>
                    <span>
                      <strong>{t("labels.SPRITE")}</strong>
                    </span>
                    <ul>
                      {Object.keys(log.object.sprite).map(
                        (key) =>
                          log.object.sprite[key] && (
                            <li key={key}>
                              <strong>{key}</strong>:{" "}
                              {renderValue(key, log.object.sprite[key])}
                            </li>
                          )
                      )}
                    </ul>
                  </li>
                )}

                {log.object.file && (
                  <li>
                    <span>
                      <strong>{t("labels.FILE")}</strong>
                    </span>
                    <ul>
                      {Object.keys(log.object.file).map(
                        (key) =>
                          log.object.file[key] && (
                            <li key={key}>
                              <strong>{key}</strong>:{" "}
                              {renderValue(key, log.object.file[key])}
                            </li>
                          )
                      )}
                    </ul>
                  </li>
                )}

                {log.object.format &&
                  Object.keys(log.object.format).map(
                    (key) =>
                      log.object.format[key] && (
                        <li key={key}>
                          {key !== "tags" ? (
                            <span>
                              <strong>{key}</strong>:{" "}
                              {renderValue(key, log.object.format[key])}
                            </span>
                          ) : (
                            <>
                              <strong>{t("labels.TAGS")}</strong>{" "}
                              <Link
                                button
                                onClick={() => setShowTags(!showTags)}
                              >
                                {showTags ? t("text.HIDE") : t("text.SHOW")}
                              </Link>
                              {showTags && (
                                <ul>
                                  {Object.keys(log.object.format[key]).map(
                                    (key2) =>
                                      log.object.format[key][key2] && (
                                        <li>
                                          <strong>{key2}</strong>:{" "}
                                          {renderValue(
                                            key2,
                                            log.object.format[key][key2]
                                          )}
                                        </li>
                                      )
                                  )}
                                </ul>
                              )}
                            </>
                          )}
                        </li>
                      )
                  )}

                {log.object.config && (
                  <li>
                    <strong>{t("labels.CONFIG")}</strong>{" "}
                    <Link onClick={() => setShowConfig(!showConfig)}>
                      {showConfig ? t("text.HIDE") : t("text.SHOW")}
                    </Link>
                    {showConfig && (
                      <ul>
                        {Object.keys(log.object.config).map(
                          (key) =>
                            log.object.config[key] && (
                              <li key={key}>
                                <span>
                                  <strong>{key}</strong>:{" "}
                                  {JSON.stringify(log.object.config[key])}
                                </span>
                              </li>
                            )
                        )}
                      </ul>
                    )}
                  </li>
                )}

                {log.object.streams && (
                  <li>
                    <strong>{t("labels.STREAMS")}</strong>{" "}
                    <Link onClick={() => setShowStreams(!showStreams)}>
                      {showStreams ? t("text.HIDE") : t("text.SHOW")}
                    </Link>
                    {showStreams &&
                      log.object.streams.map(
                        (stream) =>
                          stream && (
                            <ul key={stream.index}>
                              {Object.keys(stream).map((streamKey) => (
                                <li key={stream.index + streamKey}>
                                  <strong>{streamKey}</strong>:{" "}
                                  {renderValue(streamKey, stream[streamKey])}
                                </li>
                              ))}
                            </ul>
                          )
                      )}
                  </li>
                )}
              </ul>
            </ListItem>
          </List>
        </Collapse>
      )}
    </>
  );
};

const JobTimeline = () => {
  const { t } = useTranslation();
  const { jobs, timelineFilter, changeTimelineFilter } = useJob();
  const [open, setOpen] = useState(false);
  const [logs, setLogs] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const newLogs = [];

    if (jobs) {
      jobs.forEach((job) => {
        job.logs.forEach((log) => {
          if (timelineFilter.length > 0) {
            if (timelineFilter.indexOf(job.command.id) > -1) {
              newLogs.push({
                ...log,
                command: job.command,
                jobId: job.id,
                preset: job.encode ? job.encode.preset.name : null,
              });
            }
          } else {
            newLogs.push({
              ...log,
              command: job.command,
              jobId: job.id,
              preset: job.encode ? job.encode.preset.name : null,
            });
          }
        });
      });

      setLogs(newLogs.sort((a) => a.updated_at).reverse());
    }
  }, [jobs, timelineFilter]);

  return (
    <>
      <List className="timelineList" dense>
        {logs.map((log) => (
          <TimelineItem key={log.id} log={log} />
        ))}
      </List>
      <SpeedDial
        ariaLabel="Filter speedDial"
        icon={<FilterListIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
        className="speedDial"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={cloneElement(action.icon, {
              color:
                timelineFilter.indexOf(action.command_id) === -1
                  ? "inherit"
                  : "primary",
            })}
            tooltipTitle={t(action.name)}
            onClick={() => {
              changeTimelineFilter(action.command_id);
              handleClose();
            }}
          />
        ))}
      </SpeedDial>
    </>
  );
};

export default memo(JobTimeline);
