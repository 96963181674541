import { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Show a fallback component when a CPU heavy component
 * takes a while to mount.
 *
 * @param children CPU heavy component
 * @param fallback Simple placeholder
 */
const AsyncComponentLoader = ({ children, fallback }) => {
  const [component, setComponent] = useState(null);

  useEffect(() => {
    // Render component on next render cycle
    setTimeout(() => {
      setComponent(children);
    });
  }, [children]);

  return component || fallback;
};

AsyncComponentLoader.defaultProps = {
  children: null,
  fallback: null,
};

AsyncComponentLoader.propTypes = {
  fallback: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default AsyncComponentLoader;
