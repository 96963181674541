import Immutable from "seamless-immutable";

export const ASSET = {
  id: null,
  currentAsset: null,
  currentSeason: null,
  index: "",
  type: null,
  name: "",
  identifier: "",
  quality: null,
  qualityCheck: true,
  license: null,
  publicationDate: null,
  notes: "",
  labels: [],
};

export const INITIAL_STATE = Immutable({
  data: [],
  query: null,
  links: null,
  meta: null,
  loading: true,
  selectedType: "",
  selected: {
    id: null,
    data: ASSET,
    loading: true,
  },
  sortField: "state",
  sortDirection: "asc",
  networkState: {},
});
