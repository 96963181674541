/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "external",
  initialState: {
    channel: {},
    loading: false,
    uploaded: {},
    upload: {
      uploading: false,
      error: null,
      success: false,
    },
    approve: {
      loading: false,
      error: null,
      success: false,
    },
  },
  reducers: {
    loadExternalSubtitle: (state) => {
      state.loading = true;
    },
    loadExternalSubtitleSuccess: (state, { payload }) => {
      state.loading = false;
      state.asset = payload.asset;
      state.channel = payload.channel;
      state.fragment = payload.fragment;
      state.tokenInfo = payload.token;
      state.video = payload.video;
      state.uploaded = payload.uploaded;
    },
    loadExternalSubtitleFailed: (state) => {
      state.loading = false;
      state.error = true;
    },
    uploadFile: (state) => {
      state.upload.uploading = true;
      state.upload.error = null;
      state.upload.success = false;
    },
    uploadFileSuccess: (state) => {
      state.upload.uploading = false;
      state.upload.success = true;
    },
    uploadFileFailed: (state, { payload }) => {
      const { errorMessage } = payload;
      state.upload.success = false;
      state.upload.uploading = false;
      state.upload.error = errorMessage;
    },
    approveFile: (state) => {
      state.approve.loading = true;
      state.approve.error = null;
      state.approve.success = false;
    },
    approveFileSuccess: (state) => {
      state.approve.loading = false;
      state.approve.success = true;
    },
    approveFileFailed: (state) => {
      state.approve.success = false;
      state.approve.loading = false;
      state.approve.error = "oops";
    },
  },
});

const { actions, reducer } = reducerSlice;

export const {
  loadExternalSubtitle,
  loadExternalSubtitleSuccess,
  loadExternalSubtitleFailed,
  uploadFile,
  uploadFileSuccess,
  uploadFileFailed,
  approveFile,
  approveFileSuccess,
  approveFileFailed,
} = actions;

export default reducer;
