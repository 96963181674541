import { useTranslation } from "react-i18next";

// Components
import Typography from "@material-ui/core/Typography";
import NationFlag from "components/NationFlag";

// Style
import StyledComponent from "./NavMenuLink.style";

const NavMenuLink = ({
  id,
  className,
  leftView,
  name,
  locale,
  identifier,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <StyledComponent key={id} button className={className} onClick={onClick}>
      <div className="item">
        {leftView}
        {name}
        {!name && (
          <div className="no-title">
            <div>
              {t("labels.NO_TITLE_IN_CURRENT_LANGUAGE")}
              <NationFlag countryCode={locale} />
            </div>
            <Typography variant="caption">{identifier}</Typography>
          </div>
        )}
      </div>
    </StyledComponent>
  );
};

export default NavMenuLink;
