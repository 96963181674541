// Components
import { SearchkitComponent } from "searchkit";

/**
 * Make Search kit functionalities like: loading, error, filterCount, etc..
 * available through render children. So that we can use it in functional
 * components and we don't have to use class components with extends
 * SearchkitComponent.
 *
 * @example ```
   <SearchKitWrapper>
      {({ loading, error, filterCount }) => (
        //....children
      )}
    </SearchKitWrapper>
 * ```
 */
class SearchKitWrapper extends SearchkitComponent {
  getSubFilterCount = (filter) => {
    // Single filter applied
    if (filter?.nested) return 1;
    // Multiple filters in multiple categories
    if (filter?.bool?.should) return filter.bool.should.length;
    return 0;
  };

  getFilterCount() {
    const { query } = this.searchkit.query;
    const { post_filter: postFilter } = query;

    // No filters applied
    if (!postFilter) return 0;
    // Single filter applied
    if (postFilter.nested) return 1;
    // Multiple filters of same category
    if (postFilter.bool?.should) return postFilter.bool.should.length;
    // Multiple filters in multiple categories
    if (postFilter?.bool?.must) {
      return postFilter.bool.must.reduce((total, filter) => {
        const count = this.getSubFilterCount(filter);
        return total + count;
      }, 0);
    }

    return 0;
  }

  getTotalCount() {
    return this.searchkit.results?.hits?.total?.value;
  }

  render() {
    const { error, loading, queryProcessor, query } = this.searchkit;
    const filterCount = this.getFilterCount();
    const totalCount = this.getTotalCount();
    return (
      <>
        {this.props.children({
          error,
          loading,
          filterCount,
          totalCount,
          queryProcessor,
          query,
        })}
      </>
    );
  }
}

export default SearchKitWrapper;
