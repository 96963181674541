import { useEffect, useState } from "react";

const getStorage = (persistent = true) =>
  persistent ? window.localStorage : window.sessionStorage;

export const getItem = (key, persistent) => {
  const keyWithPrefix = `allin.${key}`;
  return getStorage(persistent).getItem(keyWithPrefix);
};

export const setItem = (key, value, persistent) => {
  const keyWithPrefix = `allin.${key}`;
  return getStorage(persistent).setItem(keyWithPrefix, value);
};

/**
 * Store state in local storage
 */
const usePersistentState = (
  defaultValue,
  key,
  options = { persistent: true }
) => {
  const { persistent } = options;
  const [value, setValue] = useState(() => {
    const stateValue = getItem(key, persistent);
    return stateValue !== null ? JSON.parse(stateValue) : defaultValue;
  });

  useEffect(() => {
    setItem(key, JSON.stringify(value), persistent);
  }, [key, value, persistent]);

  return [value, setValue];
};

export default usePersistentState;
