import { AllInApi } from "features/Common/services/api.service";
import { call, put } from "redux-saga/effects";

import * as userActions from "./User.actions";

export function* getUser(userApi) {
  try {
    const userResponse = yield call(userApi.getUser);
    yield put(userActions.setUser(userResponse.data));
  } catch (error) {
    yield put(userActions.loginSetError("Fail to get user"));
  }
}

export function* setLanguage(userApi, { language }) {
  const userResponse = yield call(userApi.setLanguage, language);

  if (userResponse.status !== 200) {
    return null;
  }

  AllInApi.setLocale(userResponse.data.default_language?.code);

  return yield put(
    userActions.setUserLanguageSuccess(
      userResponse.data.default_language,
      userResponse.data.default_country
    )
  );
}

export function* setCountry(userApi, { country }) {
  const userResponse = yield call(userApi.setCountry, country);

  if (userResponse.status !== 200) {
    return null;
  }

  AllInApi.setLocale(userResponse.data.default_language?.code);

  return yield put(
    userActions.setUserCountrySuccess(
      userResponse.data.default_country,
      userResponse.data.default_language
    )
  );
}

export function* setTranslateLanguage(userApi, { language }) {
  const userResponse = yield call(userApi.setTranslateLanguage, language);

  if (userResponse.status !== 200) {
    return null;
  }

  return yield put(
    userActions.setUserTranslateLanguageSuccess(
      userResponse.data.translate_language
    )
  );
}

export function* logout(api, userApi) {
  yield call(userApi.logout);
  api.setAuthenticated(false);

  window.location.href = "/login";

  return yield put(userActions.logoutSuccess());
}
