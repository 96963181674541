/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const countryReducer = createSlice({
  name: "country",
  initialState: {
    loading: false,
    data: [],
    error: false,
  },
  reducers: {
    find: (state) => {
      state.loading = false;
    },

    findSuccess: (state, { payload }) => {
      state.data = payload;
      state.error = false;
    },

    findError: (state) => {
      state.error = true;
    },
  },
});

const { actions, reducer } = countryReducer;

export const { find, findSuccess, findError } = actions;
export default reducer;
