import { useMemo } from "react";

const getBreadcrumbs = (asset, breadcrumbs = []) => {
  const { parent } = asset || {};
  let newBreadcrumbs = [...breadcrumbs];
  if (asset) newBreadcrumbs = [asset, ...breadcrumbs];
  return parent ? getBreadcrumbs(parent, newBreadcrumbs) : newBreadcrumbs;
};

const useBreadcrumbs = ({ asset }) => {
  const trail = useMemo(() => getBreadcrumbs(asset), [asset]);
  return trail;
};

export default useBreadcrumbs;
