import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

// Components
import Typography from "@material-ui/core/Typography";
import NationFlag from "components/NationFlag";

// Style
import StyledComponent from "./NavMenuLink.style";

const NavMenuLink = ({
  id,
  className,
  leftView,
  channelId,
  name,
  locale,
  identifier,
  onClick,
}) => {
  const { t } = useTranslation();
  const url = `/channel/${channelId}/series/${id}`;

  return (
    <StyledComponent key={id} button className={className} onClick={onClick}>
      <NavLink to={url}>
        {leftView}
        {name}
        {!name && (
          <div className="no-title">
            <div>
              {t("labels.NO_TITLE_IN_CURRENT_LANGUAGE")}
              <NationFlag countryCode={locale} />
            </div>
            <Typography variant="caption">{identifier}</Typography>
          </div>
        )}
      </NavLink>
    </StyledComponent>
  );
};

export default NavMenuLink;
