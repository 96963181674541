import { takeLatest, all } from "redux-saga/effects";
import * as LanguageTypes from "./Language.types";
import * as LanguageSagas from "./Language.sagas";
import * as languageApi from "./language.service";

function* sagas() {
  return yield all([
    takeLatest(LanguageTypes.FIND, LanguageSagas.loadLanguages, languageApi),
  ]);
}

export default sagas;
