import { Component } from "react";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

import * as inboxActions from "../Inbox.actions";
import ListComponent from "./InboxOverviewView";
import InboxDialog from "../InboxDialog";
import InboxListHeaderComponent from "./InboxOverviewHeader";
import styles from "./InboxOverviewView.styles";

class ChannelListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      dialogItem: null,
      searchInput: null,
    };
  }

  componentDidMount() {
    const { loadInbox } = this.props;
    loadInbox();
  }

  componentDidUpdate(prevProps) {
    const { channel, loadInbox } = this.props;
    const { channel: prevChannel } = prevProps;

    if (channel.selected !== prevChannel.selected) {
      loadInbox();
    }
  }

  getChannelName = () => {
    const { channels, match } = this.props;
    const channelName = _.filter(
      channels,
      (o) => parseInt(match.params.channelId, 10) === o.id
    ).map((item) => item.name)[0];

    return channelName;
  };

  handleListClick = (item) => {
    this.setState({ dialogOpen: true, dialogItem: item.rowData });
  };

  handleDialogClose = (reload = false) => {
    const { loadInbox } = this.props;
    const { searchInput } = this.state;

    if (reload) {
      loadInbox(searchInput);
    }

    this.setState({ dialogOpen: false, dialogItem: null });
  };

  handleSearch = (event) => {
    const { loadInbox } = this.props;
    const searchInput = event.target.value;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.setState({ searchInput });
      loadInbox(searchInput);
    }, 200);
  };

  render() {
    const { t, classes, inbox } = this.props;
    const { dialogOpen, dialogItem } = this.state;

    return (
      <div className={classes.layoutFill}>
        <Helmet>
          <title>
            {t("titles.APP", { name: process.env.REACT_APP_TITLE })} -{" "}
            {this.getChannelName()} -{t("titles.INBOX")}
          </title>
        </Helmet>
        <InboxListHeaderComponent
          handleSearch={this.handleSearch}
          inbox={inbox}
        />
        <ListComponent
          {...this.props}
          handleListClick={(item) => this.handleListClick(item)}
        />
        <InboxDialog
          open={dialogOpen}
          item={dialogItem}
          onClose={this.handleDialogClose}
        />
      </div>
    );
  }
}

ChannelListContainer.propTypes = {
  t: PropTypes.func.isRequired,
  loadInbox: PropTypes.func.isRequired,
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  channels: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

const mapStateToProps = (state) => ({
  channel: state.channel,
  channels: state.user.user.channels,
  inbox: state.inbox,
});

const mapDispatchToProps = (dispatch) => ({
  loadInbox: (query) => dispatch(inboxActions.find(query)),
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ChannelListContainer);
