import grey from "@material-ui/core/colors/grey";

const style = {
  root: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },

  menuRoot: {
    width: "auto",
  },

  menuList: {
    padding: 0,
    borderRight: `1px solid ${grey[300]}`,
    background: grey[200],
  },

  settingSelected: {
    background: grey[300],
  },

  link: {
    textDecoration: "none",
    color: "black",
    display: "flex",
    flexGrow: 1,
    padding: 5,
  },

  settingContent: {
    flex: 1,
  },
};

export default style;
