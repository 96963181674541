import { PropTypes } from "prop-types";

// Style
import StyledComponent from "./style";

const CheckMarkLoader = ({
  state,
  size,
  borderWidth,
  borderColor,
  checkThickness,
  backgroundColor,
  successColor,
  successBackground,
  successBorderColor,
}) => {
  if (state !== "loading" && state !== "completed") return null;
  let classNames = "check-mark-root";
  if (state === "completed") {
    classNames += " loading-complete";
  }

  return (
    <StyledComponent
      className={classNames}
      size={size}
      borderWidth={borderWidth}
      borderColor={borderColor}
      checkThickness={checkThickness}
      backgroundColor={backgroundColor}
      successColor={successColor}
      successBackground={successBackground}
      successBorderColor={successBorderColor}
    >
      <div className="check-mark draw" />
    </StyledComponent>
  );
};

CheckMarkLoader.defaultProps = {
  size: 40,
  borderColor: "#0003",
  borderWidth: 2,
  checkThickness: 3,
  backgroundColor: "transparent",
  successColor: "green",
  successBackground: "darkgreen",
  successBorderColor: "darkgreen",
};

CheckMarkLoader.propTypes = {
  state: PropTypes.oneOf(["idle", "completed", "loading", "error"]),
  size: PropTypes.number,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  checkThickness: PropTypes.number,
  backgroundColor: PropTypes.string,
  successColor: PropTypes.string,
  successBackground: PropTypes.string,
  successBorderColor: PropTypes.string,
};

export default CheckMarkLoader;
