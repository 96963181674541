import React, { useEffect, useState } from "react";
import { get } from "lodash";
import {
  Badge,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { Grid } from "@material-ui/core";
import NationFlag from "components/NationFlag";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { hasLocale } from "./FragmentSocialPost";
import { ErrorOutline, PublishOutlined } from "@material-ui/icons";

const Sidebar = ({
  form,
  list,
  timeline,
  languages,
  selectedType,
  selectedLanguage,
  onSelect,
}) => {
  const [open, setOpen] = useState([]);
  const [selected, setSelected] = useState(null);
  const [publishedCount, setPublishedCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);

  const toggleOpen = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const toggleSelected = (type, language) => {
    setSelected(`${type.type}-${language.code}`);

    onSelect(type, language);
  };

  const toggleTimeline = (e) => {
    setSelected(null);

    onSelect(null, null);
  };

  useEffect(() => {
    setOpen(languages.map((language) => language.id));
  }, [languages]);

  useEffect(() => {
    if (selectedType && selectedLanguage) {
      setSelected(`${selectedType.type}-${selectedLanguage.code}`);
    } else {
      setSelected(null);
    }
  }, [selectedType, selectedLanguage]);

  useEffect(() => {
    setPublishedCount(
      timeline?.filter((item) => ["published"].includes(item.state))?.length ||
        0
    );
    setInProgressCount(
      timeline?.filter((item) =>
        ["pending", "in_progress"].includes(item.state)
      )?.length || 0
    );
  }, [timeline]);

  return (
    <>
      <Typography variant="body2" color="primary" sx={{ fontWeight: "bold" }}>
        Publish
      </Typography>
      <div style={{ margin: "12px -16px 0 -16px" }}>
        <ListItemButton onClick={toggleTimeline} selected={selected === null}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <ListItemText primary="Timeline" />
                </Grid>
                <Grid item>
                  <Chip
                    label={publishedCount || 0}
                    size="small"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>

            {inProgressCount > 0 && (
              <Grid item>
                <Badge badgeContent={inProgressCount} color="primary">
                  <PublishOutlined color="action" />
                </Badge>
              </Grid>
            )}
          </Grid>
        </ListItemButton>
      </div>

      <Divider sx={{ margin: "16px -8px" }} />

      <Typography variant="body2" color="primary" sx={{ fontWeight: "bold" }}>
        Compose
      </Typography>
      <div style={{ margin: "12px -16px 0 -16px" }}>
        {languages
          .filter((language) =>
            language.countries.some((country) =>
              hasLocale(list, country, language)
            )
          )
          .map((language, index) => (
            <div key={language.id}>
              <ListItemButton onClick={() => toggleOpen(language.id)}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs>
                    <ListItemText primary={language.name} />
                  </Grid>
                  <Grid item>
                    {open.includes(language.id) ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Grid>
                </Grid>
              </ListItemButton>

              <Collapse
                in={open.includes(language.id)}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" dense sx={{ pt: 0 }}>
                  {list.map((item) => {
                    const countries = language.countries.filter((country) =>
                      item.countries.some(
                        (listCountry) =>
                          listCountry.code === country.code &&
                          listCountry.languages.some(
                            (listLanguage) =>
                              listLanguage.code === language.code
                          )
                      )
                    );

                    const isLoading = countries.some((country) =>
                      item.voices.some(
                        (voice) =>
                          get(
                            form.values,
                            `${item.type}.locales.${language.code}-${country.code}.voices.${voice}.loading`
                          ) === true
                      )
                    );
                    const isChecked = countries.some((country) =>
                      item.voices.some(
                        (voice) =>
                          get(
                            form.values,
                            `${item.type}.locales.${language.code}-${country.code}.voices.${voice}.checked`
                          ) === true
                      )
                    );
                    const isCheckedAll = countries.every((country) =>
                      item.voices.some(
                        (voice) =>
                          get(
                            form.values,
                            `${item.type}.locales.${language.code}-${country.code}.voices.${voice}.checked`
                          ) === true
                      )
                    );
                    const hasErrors = countries.some(
                      (country) =>
                        get(
                          form.values,
                          `${item.type}.locales.${language.code}-${country.code}.errors`
                        )?.length > 0
                    );

                    const isOpen = selected === `${item.type}-${language.code}`;

                    return (
                      <ListItem
                        sx={{ pl: 2, pr: 1, py: 0 }}
                        key={item.type}
                        secondaryAction={
                          <>
                            {isLoading && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "10px",
                                  width: "22px",
                                  height: "22px",
                                }}
                              >
                                <CircularProgress size={22} />
                              </div>
                            )}

                            {hasErrors && <ErrorOutline color="error" />}

                            {!hasErrors && (
                              <Checkbox
                                color="info"
                                edge="end"
                                checked={isChecked}
                                indeterminate={isChecked && !isCheckedAll}
                                disabled={isLoading}
                                sx={{
                                  opacity: isLoading
                                    ? 0.3
                                    : isChecked
                                    ? 1
                                    : 0.7,
                                }}
                              />
                            )}
                          </>
                        }
                      >
                        <ListItemButton
                          sx={{
                            pl: 1,
                            pr: 1,
                            py: 1,
                            borderRadius: "4px",
                          }}
                          style={{
                            backgroundColor: hasErrors
                              ? "rgba(239,83,80,0.08)"
                              : undefined,
                          }}
                          selected={isOpen}
                          onClick={() => toggleSelected(item, language)}
                        >
                          <ListItemIcon sx={{ minWidth: "32px" }}>
                            <item.icon
                              style={{
                                color: hasErrors
                                  ? "rgb(239,83,80)"
                                  : isOpen
                                  ? item.color
                                  : "#aaa",
                                display: "block",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.name}
                            style={{
                              color: hasErrors
                                ? "rgb(239,83,80)"
                                : isOpen
                                ? item.color
                                : "#666",
                            }}
                          />
                          <ListItemIcon
                            style={{
                              marginLeft: "8px",
                            }}
                          >
                            <Grid container spacing={1}>
                              {countries.map((country) => (
                                <Tooltip key={country.id} title={country.name}>
                                  <Grid item>
                                    <NationFlag
                                      countryCode={
                                        country.code === "intl"
                                          ? "xx"
                                          : country.code
                                      }
                                    />
                                  </Grid>
                                </Tooltip>
                              ))}
                            </Grid>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </div>
          ))}
      </div>
    </>
  );
};

export default Sidebar;
