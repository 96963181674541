import { memo } from "react";
import PropTypes from "prop-types";

// Components
import TableCell from "@material-ui/core/TableCell";

const ImageCell = ({ images }) => (
  <TableCell className="cell-images align--center" scope="row" component="td">
    <div>{images?.length || 0} / 4</div>
  </TableCell>
);

ImageCell.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.oneOf(["active", "inactive", "pending", "draft"]),
      type: PropTypes.shape({
        id: PropTypes.number,
        identifier: PropTypes.string,
        type: PropTypes.string,
      }),
    })
  ),
};

export default memo(ImageCell);
