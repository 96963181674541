import styled from "styled-components";
import Menu from "@material-ui/core/Menu";

export const StyledMenu = styled(Menu)`
  .MuiListItemIcon-root {
    min-width: 32px;
  }
`;

const StyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .MuiAppBar-root {
    background: ${({ theme }) => theme.palette.grey[800]};

    .MuiTab-root {
      min-width: 0;
    }
  }

  .timeLineBox {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .jobSelectBar {
      background: ${({ theme }) => theme.palette.grey[700]};
      border-bottom: ${({ theme }) => `1px solid ${theme.palette.grey[800]}`};
      padding: ${({ theme }) => theme.spacing()}px;
      display: flex;
      justify-content: space-between;

      .jobSelect {
        margin-right: ${({ theme }) => theme.spacing()}px;
      }

      .subJobSelect {
        flex: 1;
      }
    }
  }

  .boxPadding {
    padding: ${({ theme }) => theme.spacing(4)}px;

    .jobButton {
      margin-bottom: ${({ theme }) => theme.spacing()}px;
    }

    .actionButtons {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
      display: flex;
      flex-direction: row;
      flex: 1;

      > div {
        margin-right: ${({ theme }) => theme.spacing()}px;
      }
    }
  }

  .timelineList {
    overflow-y: scroll;
    overflow-x: none;

    .timelineObject {
      color: #696969;
      padding-left: 0;
      list-style-type: none;

      li {
        margin: 0 1em;
        list-style-position: inside;
        text-indent: -1em;
        font-size: 12px;
        list-style: none;

        &:before {
          content: "–";
          margin-right: 0.5em;
        }

        strong: {
          color: black;
        }
      }
    }
  }

  .activeJobContainer {
    padding: ${({ theme }) =>
      `0 ${theme.spacing()}px ${theme.spacing()}px ${theme.spacing()}px`};

    &.activeJobBg {
      padding-top: ${({ theme }) => theme.spacing()}px;
      background: ${({ theme }) => theme.palette.grey[800]};
      border-bottom: ${({ theme }) => `1px solid ${theme.palette.grey[800]}`};

      .paper {
        background: white;

        .MuiTypography-body2 {
          font-weight: normal;
        }
      }
    }

    .paper {
      padding: ${({ theme }) => theme.spacing()}px;
      background-color: #eeeeee;
      display: flex;
      flex-direction: column;

      .MuiTypography-body2 {
        font-weight: bold;
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .flex {
        display: flex;
        align-items: center;
      }

      .MuiTypography-caption {
        margin-top: -4px;
        display: block;
      }

      .restartButton {
        border-radius: 4px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.65rem;
      }

      .prioButton {
        background: ${({ theme }) => theme.palette.error.main};
        margin-top: ${({ theme }) => theme.spacing()}px;
      }

      .prioButtonColor {
        color: ${({ theme }) => theme.palette.error.main};
      }

      .moreSettings {
        margin-left: ${({ theme }) => theme.spacing()}px;
      }
    }
  }

  .statusButton {
    margin-right: ${({ theme }) => theme.spacing()}px;
    color: rgba(255, 255, 255, 0.8);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 70px;
    text-transform: capitalize;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.65rem;
  }

  .status-indicator {
    margin-right: ${({ theme }) => theme.spacing()}px;
  }

  .fileSettings {
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
    .MuiFormControl-root {
      margin: ${({ theme }) => theme.spacing()}px 0px;
    }
  }

  .optionsTabBar {
    z-index: 1 !important;
  }

  .speedDial {
    position: absolute;

    &.MuiSpeedDial-directionUp,
    &.MuiSpeedDial-directionLeft {
      bottom: ${({ theme }) => theme.spacing(2)}px;
      right: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`;

export default StyledComponent;
