import { Alert } from "@material-ui/lab";
import { useSeoDetail } from "features/Seo/Hooks/useSeoDetail";

const SeoDetailError = ({ isSubmitted, failed }) => {
  const { languages, responseErrors } = useSeoDetail();

  const mapError = (error) => {
    return error.replace(
      new RegExp(`\\.(${languages.map((lang) => `${lang.code}`).join("|")})`),
      (x, y) => ` (${y.toUpperCase()})`
    );
  };

  return (
    <>
      {isSubmitted && failed && Object.values(responseErrors).length > 0 && (
        <div className="alert">
          <Alert severity="error">
            <strong>
              Not saved: {Object.values(responseErrors).length} fields with
              errors:
            </strong>
            <ul>
              {Object.values(responseErrors).map((errors) =>
                errors.map((error) => <li key={error}>{mapError(error)}</li>)
              )}
            </ul>
          </Alert>
        </div>
      )}
      {isSubmitted && !failed && Object.values(responseErrors).length === 0 && (
        <div className="alert">
          <Alert severity="success">
            <strong>Saved!</strong>
          </Alert>
        </div>
      )}
    </>
  );
};

export default SeoDetailError;
