import { memo } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Icons
import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

// Component
import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Typography,
} from "@material-ui/core";

// Hooks
import { useQuery } from "features/Common/hooks";

// Utils
import Utils from "../../Common/utils";

import { useCurrentFragmentUrl } from "../hooks";

// Style
import StyledComponent, { StyledPreviewImage } from "./style";

const PreviewImage = ({ paths, title, description, width, height }) => {
  const { t } = useTranslation();
  const thumbnail = Utils.Common.getFilePathByIdentifier(paths, "thumbnail");
  const raw = Utils.Common.getFilePathByIdentifier(paths, "raw");
  const path = thumbnail || raw;
  const source = Utils.Fragments.getAssetStoragePath(`/${path?.path}`);
  // Dimensions
  const imageWidth = raw?.image?.width;
  const imageHeight = raw?.image?.height;

  return (
    <StyledPreviewImage width={width} height={height}>
      <Typography variant="h4">{description}</Typography>
      <img src={source} alt={title} />
      <Typography variant="h4">{title}</Typography>
      <Typography variant="h4">
        {t("labels.IMAGE_DIMENSIONS", {
          minWidth: imageWidth,
          minHeight: imageHeight,
        })}
      </Typography>
    </StyledPreviewImage>
  );
};

const Placeholder = ({ description, width, height }) => (
  <StyledPreviewImage width={width} height={height}>
    <Typography variant="h4">{description}</Typography>
    <div className="placeholder">
      <NotInterestedIcon />
    </div>
  </StyledPreviewImage>
);

const IMAGE_HEIGHT = 150;
const PANEL_NAME = "images";

const FragmentImages = ({ fragment, fragmentFiles, imageTypes }) => {
  const { t } = useTranslation();

  const url = useCurrentFragmentUrl({ fragmentId: fragment?.id });
  const panelUrl = `${url}/?panel=${PANEL_NAME}`;

  const imagesLength = fragmentFiles?.filter(
    (file) => file?.type?.type === "image"
  ).length;

  // Current Page
  const query = useQuery();
  const panel = query.get("panel");
  const isActive = () => panel === PANEL_NAME;

  return (
    <StyledComponent>
      <NavLink
        to={panelUrl}
        exact
        activeClassName="activeEdit"
        isActive={isActive}
      >
        <Card className="image-card">
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <EditIcon />
              </IconButton>
            }
            title={t("labels.IMAGES")}
            subheader={`${imagesLength} / ${imageTypes.length} ${t(
              "labels.IMAGE_TYPES"
            )}`}
          />
          <CardContent>
            {imageTypes.map(({ id, identifier, image }) => {
              // Image dimensions
              const aspectRatio = image.aspect_ratio;
              const ratio = Utils.Common.calculateRatio(aspectRatio);

              // Actual image data
              const imageFile =
                fragmentFiles.find((file) => file?.type?.id === id) || {};
              const { paths, name } = imageFile;
              if (!paths) {
                return (
                  <Placeholder
                    key={id}
                    description={`${identifier} ${aspectRatio}`}
                    height={IMAGE_HEIGHT}
                    width={IMAGE_HEIGHT * ratio}
                  />
                );
              }

              return (
                <PreviewImage
                  key={`item-image-${id}`}
                  description={`${identifier} ${aspectRatio}`}
                  paths={paths}
                  title={name}
                  height={IMAGE_HEIGHT}
                  width={IMAGE_HEIGHT * ratio}
                />
              );
            })}
          </CardContent>
        </Card>
      </NavLink>
    </StyledComponent>
  );
};

export default memo(FragmentImages);
