import { memo } from "react";

// Style
import StyledComponent from "./style";

const StatusBadge = ({ status, count }) => (
  <StyledComponent className="status-circle" status={status}>
    <span>{count}</span>
  </StyledComponent>
);

export default memo(StatusBadge);
