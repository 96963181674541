const style = () => ({
  listbox: {
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export default style;
