import { useState } from "react";

// Components
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import TimeLineIcon from "@material-ui/icons/Timeline";
import SettingsBackIcon from "@material-ui/icons/SettingsBackupRestore";
import SettingsIcon from "@material-ui/icons/Settings";
import MusicVideoIcon from "@material-ui/icons/MusicVideo";
import CircularProgressCenter from "components/CircularProgressCenter";

import ActiveJob from "./ActiveJob";
import StartJob from "./StartJob";
import JobTimeline from "./JobTimeline";
import StreamSettings from "./StreamSettings";
import FileSettings from "./FileSettings";
import { useJob } from "./JobProvider";

// Style
import StyledDiv from "./style";

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    style={{ flex: 1, overflowY: "auto" }}
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box style={{ height: "100%" }}>{children}</Box>}
  </div>
);

const FragmentJobs = () => {
  const { jobs, jobsLoading } = useJob();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (jobsLoading) return <CircularProgressCenter type="inline" />;

  return (
    <StyledDiv>
      {jobs
        .filter((job) => job.status !== "success")
        .map((job) => (
          <ActiveJob key={job.id} job={job} />
        ))}
      <AppBar position="static" className="optionsTabBar">
        <Tabs
          indicatorColor="primary"
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
        >
          <Tab label={<TimeLineIcon />} />
          <Tab label={<SettingsBackIcon />} />
          <Tab label={<MusicVideoIcon />} />
          <Tab label={<SettingsIcon />} />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <div className="timeLineBox">
          <JobTimeline />
        </div>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <StartJob />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <StreamSettings />
      </TabPanel>
      <TabPanel value={selectedTab} index={3} className="boxPadding">
        <FileSettings />
      </TabPanel>
    </StyledDiv>
  );
};

FragmentJobs.propTypes = {};

export default FragmentJobs;
