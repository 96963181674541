import styled from "styled-components";

export default styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  width: 100%;

  & .sk-layout {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }

  & .sk-panel__header {
    position: sticky;
    top: 8px;
    background: #fafafa;
    z-index: 10;
    padding: 6px 12px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.palette.grey[900]};
    font-weight: bold;
  }

  & .sk-layout__body {
    display: flex;
    flex: 1;
  }

  @media (min-width: 800px) {
    .sk-layout__body {
      display: flex;
      flex: 1;
      overflow-x: hidden;
    }
  }

  & .sk-layout__filters {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    box-sizing: border-box;
    padding: 12px 16px;
    background: #fafafa;
    overflow-x: auto;
    overflow-y: auto;
    box-shadow: 0px 1px 5px #00000063;

    &.active {
      padding-top: 0;
    }
    &.inactive {
      display: none;
    }

    & header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 12px;
      padding: 12px 0;
      position: sticky;
      top: 0;
      background: #fafafa;
      z-index: 10;
    }

    & header .MuiButton-outlined {
      border: none;
    }

    & .MuiChip-root {
      height: auto;
      white-space: unset;
    }

    & .MuiChip-label {
      white-space: unset;
    }
  }

  @media (min-width: 500px) {
    .sk-layout__filters {
      left: unset;
      width: 300px;
    }
  }

  @media (min-width: 1200px) {
    .sk-layout__filters {
      position: relative;
      box-shadow: none;
      overflow-y: initial;

      & header {
        display: none;
      }
    }
  }

  & .sk-panel {
    margin-top: 12px;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 14px;
  }

  & .sk-refinement-list__view-more-action {
    text-align: left;
    padding: 8px 12px;
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.grey[700]};
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & .sk-layout__results {
    flex: 1;
    overflow-x: auto;
  }

  & .sk-pagination-navigation {
    display: flex;
    justify-content: center;
    padding: 16px;

    & > div {
      display: flex;
      align-items: center;
    }
  }
`;
