const style = (theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
  },
  helperText: {
    flex: 1,
    textAlign: "end",
  },
  selectItem: {
    paddingLeft: 3 * theme.spacing(),
  },
  dialogActions: {
    padding: theme.spacing(),
    backgroundColor: "rgba(0,0,0,.02)",
    borderTop: `1px solid ${theme.palette.divider}`,
    justifyContent: "center",
  },
});

export default style;
