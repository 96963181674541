import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";

export default styled(FormControl)`
  .MuiTypography-h6 {
    margin-bottom: 8px;
    font-size: 0.875rem;
  }

  & .rc-md-editor:focus {
    outline: 1px solid red;
  }

  .rc-md-editor .input:focus {
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
  }

  header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }
`;
