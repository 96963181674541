import { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Utils from "features/Common/utils";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";

// Component
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import {
  DialogActions,
  Typography,
  Toolbar,
  AppBar,
  Tooltip,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { DatePickerWithControl } from "components/Form/DatePicker";
import { Button } from "components";

import StyledDialog from "./style";
import { getcountryGroups, updateSchedule } from "../services";
import { findById } from "../Asset.actions";

const validationSchema = yup.object().shape({});

const ScheduleDetailsDialog = ({ onClose, open, asset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fixCountryGroups, setFixCountryGroups] = useState([]);

  const defaultValues = {
    ...asset,
  };

  const getDate = (countries) => {
    for (const country in countries) {
      for (const schedule in asset.schedule) {
        if (asset.schedule[schedule].country.id === countries[country].id) {
          return schedule;
        }
      }
    }
  };

  const updateCountryDate = (groupId, startOrEnd, value) => {
    for (const countryGroup in fixCountryGroups) {
      if (fixCountryGroups[countryGroup].id === groupId) {
        const updateGroup = fixCountryGroups[countryGroup];
        for (const assetSchedule in asset.schedule) {
          for (const updateGroupCountry in updateGroup.countries) {
            if (
              asset.schedule[assetSchedule].country.id ===
              updateGroup.countries[updateGroupCountry].id
            ) {
              setValue("schedule[" + assetSchedule + "]." + startOrEnd, value);
            }
          }
        }
      }
    }
  };

  const { handleSubmit, errors, control, setValue, reset } = useForm({
    validationSchema,
    defaultValues,
  });

  const onSubmit = async (data) => {
    const schedule = defaultValues.schedule.map((item, i) => ({
      ...item,
      ...data.schedule[i],
    }));

    const scheduleResponse = await updateSchedule(asset.id, { schedule });

    if (scheduleResponse.status === 200) {
      dispatch(findById(asset.id));
      onClose();
    }
  };

  useEffect(() => {
    reset(asset);
  }, [asset, reset]);

  useEffect(() => {
    getcountryGroups(asset.id).then((res) => {
      for (const group in res.data) {
        let countriesList = [];
        for (const country in res.data[group].countries) {
          for (const schedule in asset.schedule) {
            if (
              res.data[group].countries[country].id ===
              asset.schedule[schedule].country.id
            ) {
              countriesList.push(asset.schedule[schedule]);
            }
          }
        }
        const countriesList2 = countriesList.map((x) => x.schedule_start);
        if (countriesList2.every((val, i, arr) => val === arr[0])) {
          res.data[group]["schedule_start"] = countriesList[0].schedule_start;
          res.data[group]["schedule_end"] = countriesList[0].schedule_end;
        }
        res.data[group]["minDate"] = countriesList[0].contract_start;
        res.data[group]["maxDate"] = countriesList[0].contract_end;
      }
      setFixCountryGroups(res.data);
    });
  }, [asset]);

  return (
    <StyledDialog onClose={onClose} open={open} fullWidth>
      <AppBar position="sticky">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
            {t("labels.PUBLICATION_DATE")} {t("buttons.CHANGE")}
          </Typography>
          <Tooltip title={t("labels.CLOSE")}>
            <IconButton color="inherit" aria-label="Close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-box">
          <div className="form-item border">
            <div className="header">
              <Typography component="h6" className="title">
                {t("titles.COUNTRY_GROUPS")}
              </Typography>
            </div>
            <div>
              {fixCountryGroups.map((group, index) => (
                <div key={group.id}>
                  <ListItem className="listItem">
                    <ListItemText className="listItemText">
                      <div className="content">
                        <strong>{group.name}</strong>
                        <div>
                          <DatePickerWithControl
                            name={`schedule[${getDate(
                              group.countries
                            )}].schedule_start`}
                            label={t("labels.FROM")}
                            variant="outlined"
                            className="inputField"
                            onChange={(value) =>
                              updateCountryDate(
                                group.id,
                                "schedule_start",
                                value
                              )
                            }
                            selected={fixCountryGroups[index].schedule_start}
                            minDate={fixCountryGroups[index].minDate}
                            maxDate={fixCountryGroups[index].maxDate}
                            control={control}
                            timestamp
                            fullWidth
                          />
                          <DatePickerWithControl
                            name={`schedule[${getDate(
                              group.countries
                            )}].schedule_end`}
                            label={t("labels.TO")}
                            variant="outlined"
                            className="inputField"
                            onChange={(value) =>
                              updateCountryDate(group.id, "schedule_end", value)
                            }
                            selected={fixCountryGroups[index].schedule_start}
                            minDate={fixCountryGroups[index].minDate}
                            maxDate={fixCountryGroups[index].maxDate}
                            control={control}
                            timestamp
                            fullWidth
                          />
                        </div>
                      </div>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </div>
          </div>
          <div className="form-item">
            <div className="header">
              <Typography component="h6" className="title">
                {t("titles.NATIONS")}
              </Typography>
            </div>
            {asset?.schedule &&
              asset.schedule.map((schedule, index) => (
                <div key={schedule.country.id}>
                  <ListItem className="listItem">
                    <ListItemText className="listItemText">
                      <div className="content">
                        <span
                          className={`fi fi-${Utils.Common.flagCode(
                            schedule.country.code.toLocaleLowerCase()
                          )}`}
                        />
                        <div>
                          <DatePickerWithControl
                            name={`schedule[${index}].schedule_start`}
                            label={t("labels.FROM")}
                            variant="outlined"
                            className="inputField"
                            error={
                              errors[`schedule[${index}].schedule_start`]
                                ?.message
                            }
                            control={control}
                            minDate={asset.schedule[index].contract_start}
                            maxDate={asset.schedule[index].contract_end}
                            timestamp
                            fullWidth
                          />
                          <DatePickerWithControl
                            name={`schedule[${index}].schedule_end`}
                            label={t("labels.TO")}
                            variant="outlined"
                            className="inputField"
                            error={
                              errors[`schedule[${index}].schedule_end`]?.message
                            }
                            control={control}
                            minDate={asset.schedule[index].contract_start}
                            maxDate={asset.schedule[index].contract_end}
                            timestamp
                            fullWidth
                          />
                        </div>
                      </div>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </div>
              ))}
          </div>
        </div>
        <DialogActions className="dialogActions">
          <Button type="submit" color="primary">
            {t("buttons.CHANGE")}
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};

ScheduleDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  asset: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default memo(ScheduleDetailsDialog);
