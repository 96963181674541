import styled from "styled-components";

const StyledComponent = styled.div`
  .collectionForm {
    padding-left: 40px;
    padding-top: 20px;
  }

  .test {
    width: 320px;
  }

  .button {
    width: 320px;
    align-self: flex-start;
  }

  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  flex-grow: 1;
`;

export default StyledComponent;
