import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Hooks
import { useAuthentication } from "features/Auth/AuthProvider";

// Utils
import { Can } from "features/Auth/AccessControl";
import Utils from "features/Common/utils";

// Components
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import LockIcon from "@material-ui/icons/Lock";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import SelectContentLanguage from "./SelectContentLanguage";
import SelectChannel from "./SelectChannel";
import NavBar from "../NavBar";

/* Styles */
import AppBarStyled from "./Header.style";
import TwoFactorDialog from "features/Auth/TwoFactorDialog";

const Header = ({ routes }) => {
  const { t } = useTranslation();
  const [
    displayEnableTwoFactorDialog,
    setDisplayEnableTwoFactorDialog,
  ] = useState(false);

  const { logout } = useAuthentication();

  // Redux
  const user = useSelector((state) => state.user.user);
  const channel = useSelector((state) => state.channel.data);
  const [anchorEl, setAnchorEl] = useState(null);

  // Channel appearance
  const DEFAULT_COLOR = "#15b1d7";
  const channelColor = channel?.color || DEFAULT_COLOR;
  const channelColorDark = Utils.Channels.shadeColor(channelColor, -40);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    logout();
  };

  return (
    <AppBarStyled
      position="sticky"
      style={{ backgroundColor: channelColorDark }}
    >
      <Toolbar>
        <div className="grow">
          <SelectChannel />
          <NavBar routes={routes} channel={channel} />
        </div>
        <div className="right-side">
          <SelectContentLanguage />
          <Can I="view" a="settings">
            <Link to="/settings">
              <Button>
                <SettingsIcon htmlColor="white" />
              </Button>
            </Link>
          </Can>
          <Button
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            color="inherit"
            onClick={handleClick}
            id="button-user"
          >
            {user.name}
            {!user.hasTwoFactor && (
              <ErrorOutlineIcon htmlColor="#FF8121" style={{ marginLeft: 5 }} />
            )}
            {anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {!user.hasTwoFactor && (
              <MenuItem
                onClick={() => setDisplayEnableTwoFactorDialog("enable")}
              >
                <LockIcon style={{ marginRight: 5 }} />
                {t("labels.ENABLE_TWO_FACTOR")}
                <ErrorOutlineIcon
                  htmlColor="#FF8121"
                  style={{ marginLeft: 5 }}
                />
              </MenuItem>
            )}
            {user.hasTwoFactor && (
              <MenuItem
                onClick={() => setDisplayEnableTwoFactorDialog("disable")}
              >
                <LockIcon style={{ marginRight: 5 }} />
                {t("labels.DISABLE_TWO_FACTOR")}
              </MenuItem>
            )}
            <MenuItem onClick={handleLogOut}>
              <ExitToAppIcon style={{ marginRight: 5 }} />
              {t("labels.LOGOUT")}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>

      <TwoFactorDialog
        open={displayEnableTwoFactorDialog}
        onClose={() => setDisplayEnableTwoFactorDialog(false)}
      />
    </AppBarStyled>
  );
};

export default memo(Header);
