const style = (theme) => ({
  root: {
    padding: 0,
  },

  loader: {
    display: "flex",
    height: 60,
    justifyContent: "center",
    alignItems: "center",
  },

  listItem: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(0,0,0,.02)",
    },
    paddingTop: theme.spacing() * 2,
    paddingBottom: theme.spacing() * 2,
  },

  ipAddress: {
    textAlign: "right",
  },
});

export default style;
