import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { compose } from "redux";
import { Breadcrumbs as ReactBreadcrumbs, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Component, Fragment } from "..";

import styles from "./style";

class Breadcrumbs extends Component {
  renderLink(link, isLast) {
    const { t, classes } = this.props;

    if (isLast) {
      return (
        <Typography key={link.to} color="textPrimary">
          {t(link.name)}
        </Typography>
      );
    }

    return (
      <Link key={link.to} to={link.to} className={classes.link}>
        {t(link.name)}
      </Link>
    );
  }

  renderLinks(append) {
    const links = [...this.getBreadcrumbs()];
    const crumbs = [];

    if (append) links.push(append);

    links.forEach((link, i) => {
      crumbs.push(this.renderLink(link, i === links.length - 1));
    });

    return crumbs;
  }

  render() {
    const { classes, append } = this.props;

    return (
      <Fragment>
        <Box mb={2} className={`${classes.container} breadcrumbs`}>
          <ReactBreadcrumbs aria-label="breadcrumb">
            {this.renderLinks(append)}
          </ReactBreadcrumbs>
        </Box>
      </Fragment>
    );
  }
}

Breadcrumbs.defaultProps = {
  links: [],
};

Breadcrumbs.propTypes = {};

export default compose(withStyles(styles), withTranslation())(Breadcrumbs);
