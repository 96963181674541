/* Components */
import Layout from "../../components/Layout";
import TranslationOverview from "../../features/Translation/TranslationOverview";

const Page = ({ routes, ...props }) => (
  <Layout routes={routes}>
    <TranslationOverview {...props} />
  </Layout>
);

export default Page;
