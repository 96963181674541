import { memo } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

// Components
import CircularProgressCenter from "components/CircularProgressCenter";
import SeriesSidebar from "features/Asset/AssetDetailSeriesSidebar";
import { Can } from "features/Auth/AccessControl";

// Utils
import { usePersistentState } from "features/Common/hooks";

// Local Components
import Labels from "./Labels";
import AssetDetails from "./AssetDetails";
import ScheduleDetails from "./ScheduleDetails";
import QualityChecks from "./QualityChecks";
import Logs from "../AssetDetailLogs";

// Style
import StyledGrid, { StyledResizePanel } from "./style";

const DEFAULT_WIDTH = 240;
const AssetSidebar = ({ asset, channel, loading }) => {
  const { t } = useTranslation();

  const [sideBarWidth, setSidebarWidth] = usePersistentState(
    DEFAULT_WIDTH,
    "asset.sidebar.width"
  );

  const onDragStop = ({ size }) => {
    setSidebarWidth(size);
  };

  return (
    <StyledResizePanel
      name="asset.sidebar"
      direction="e"
      onDragStop={onDragStop}
      initialSize={sideBarWidth}
      minimumSize={46}
      maximumSize={500}
      overFlowCorrection={false}
    >
      <StyledGrid container direction="column">
        <SeriesSidebar />
        {loading && <CircularProgressCenter type="inline" />}
        {!loading && (
          <>
            <div className="groups">
              <AssetDetails t={t} asset={asset} />
              <QualityChecks channel={channel} asset={asset} />
              <Can I="view" an="asset-schedule">
                <ScheduleDetails asset={asset} />
              </Can>
              <Labels
                assetId={asset?.id}
                assetName={asset?.name}
                labels={asset?.labels}
              />
            </div>
            <Logs asset={asset} />
          </>
        )}
      </StyledGrid>
    </StyledResizePanel>
  );
};

AssetSidebar.defaultProps = {
  loading: false,
};

AssetSidebar.propTypes = {
  asset: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
};

export default memo(AssetSidebar);
