import { useState, memo } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

// Icons
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import EditIcon from "@material-ui/icons/Edit";

// Components
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// Local Components
import SidebarGroup from "features/Asset/AssetDetailSidebarGroup";
import AssetEditDialog from "features/Asset/AssetEdit";

// Constants
import { FORM_MODES } from "features/Asset/AssetEdit/constants";
import Alert from "@material-ui/lab/Alert";

const headerIcon = <InfoOutlinedIcon />;

const AssetDetails = ({ asset }) => {
  const { t } = useTranslation();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const { licenses = [] } = asset;
  const allLicenses = licenses
    .map((license) => license.name)
    .join(", ")
    .replace(/, ([^,]*)$/, " en $1");

  const onEditDialogOpen = () => {
    setEditDialogOpen(true);
  };
  const onEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const MenuActions = () => (
    <>
      <IconButton
        className="button"
        aria-label="Edit"
        onClick={onEditDialogOpen}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      {asset?.archived && (
        <Alert severity="warning" style={{ margin: "12px 12px 0 12px" }}>
          {t("labels.ARCHIVED")}
        </Alert>
      )}

      <SidebarGroup
        name="information"
        title={t("labels.INFORMATION")}
        headerIcon={headerIcon}
        menuActions={<MenuActions />}
      >
        <div className="content">
          <List disablePadding className="list">
            <ListItem>
              <ListItemText>
                <strong>{t("labels.NAME")}</strong> {asset.name}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <strong>{t("labels.IDENTIFIER")}</strong> {asset.identifier}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <strong>{t("labels.LICENSE")}</strong> {allLicenses}
              </ListItemText>
            </ListItem>
            {/* <ListItem>
              <ListItemText>
                <strong>{t("labels.CONTRACT")}</strong>{" "}
                {contracts.length > 0 ? (
                  contracts.map((contract, index) => (
                    <span key={contract.id}>
                      {contract.name}
                      {contracts.length !== index + 1 ? ", " : null}
                    </span>
                  ))
                ) : (
                  <span>{t("text.NO_CONTRACT")}</span>
                )}
              </ListItemText>
            </ListItem> */}
            <ListItem>
              <ListItemText>
                <strong>{t("labels.SEARCHABLE_SHORT")}</strong>{" "}
                {asset.indexable ? t("text.YES") : t("text.NO")}
              </ListItemText>
            </ListItem>
            {asset.notes && (
              <ListItem>
                <ListItemText>
                  <strong>{t("labels.NOTES")}</strong> {asset.notes}
                </ListItemText>
              </ListItem>
            )}
          </List>
        </div>
        <AssetEditDialog
          open={editDialogOpen}
          onClose={onEditDialogClose}
          asset={asset}
          mode={FORM_MODES.edit}
        />
      </SidebarGroup>
    </>
  );
};

AssetDetails.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default memo(AssetDetails);
