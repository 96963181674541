import { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "styled-components";
import {
  createTheme as createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "material-ui-pickers";

// Components
import RoutesSwitch from "components/RoutesSwitch";
import { EventTracker } from "features/Analytics";
import NotFound from "./NotFound";

// Utils
import { SnackbarUtilsConfigurator } from "./utils/snackbar.utils";
import { routes } from "../../routes";

// Style
import { theme } from "./theme";
import TwoFactorDialog from "features/Auth/TwoFactorDialog";

// Status colors
theme.palette.status = {
  active: {
    contrastText: "white",
    main: "#4caf50",
    dark: "#357a38",
    light: "rgb(111, 191, 115)",
  },
  approved: {
    contrastText: "white",
    main: "#1270b7",
    dark: "#0c548b",
    light: "#218ddf",
  },
  draft: {
    contrastText: "white",
    dark: "#f57c00",
    light: "#ffb74d",
    main: "#ff9800",
  },
  pending: {
    contrastText: "white",
    dark: "#780a9c",
    light: "#9e05d0",
    main: "#ce3dfd",
  },
  inactive: {
    contrastText: "white",
    dark: "#868686",
    light: "#a2a2a2",
    main: "#a2a2a2",
  },
  rejected: {
    contrastText: "white",
    main: "#E12610",
    dark: "#be1704",
    light: "#fc3a24",
  },
};

class Root extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dialog: false,
    };
  }

  componentDidUpdate() {
    const { user } = this.props;

    if (user && user.userDataLoaded) {
      this.removeLoadingScreen();
    }
  }

  getTheme = () => {
    const appTheme = theme;

    return createMuiTheme(appTheme);
  };

  twoFactorCheck(user) {
    // if (!user.user.hasTwoFactor) {
    //   this.setState({ dialog: "enable" });
    // }
  }

  removeLoadingScreen() {
    const ele = document.getElementById("ipl-progress-indicator");
    const { isLoading } = this.state;

    if (ele && isLoading) {
      ele.classList.add("available");
      this.setState({ isLoading: false });

      const { user } = this.props;
      this.twoFactorCheck(user);

      ele.outerHTML = "";
    }
  }

  render() {
    const { dialog } = this.state;

    return (
      <MuiThemeProvider theme={this.getTheme()}>
        <ThemeProvider theme={this.getTheme()}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <SnackbarUtilsConfigurator />
              <TwoFactorDialog
                open={dialog}
                onClose={() => this.setState({ dialog: false })}
              />
              <Router>
                <RoutesSwitch routes={routes}>
                  <Route path="*" component={NotFound} />
                </RoutesSwitch>
                <EventTracker />
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }
}

Root.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const mapStateToProps = (state) => ({
  channel: state.channel,
  user: state.user,
});

export default connect(mapStateToProps)(Root);
