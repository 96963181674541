import { cloneElement, createContext, useContext } from "react";

// Components
import Toolbar from "@material-ui/core/Toolbar";

// Style
import { StyledAppBar, StyledContent } from "./style";

const DrawerContext = createContext();

const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error("Wrap component inside <Drawer> provider");
  }

  return context;
};

const CloseButton = ({ children: child }) => {
  const { onClose } = useDrawer();

  const onClick = () => {
    onClose();
  };

  return cloneElement(child, {
    onClick,
  });
};

const Header = ({ children }) => {
  return (
    <StyledAppBar position="static">
      <Toolbar>{children}</Toolbar>
    </StyledAppBar>
  );
};

const Content = ({ children }) => {
  return <StyledContent>{children}</StyledContent>;
};

const Drawer = ({ children: child, anchor = "right", open, setOpen }) => {
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  const drawer = cloneElement(child, {
    anchor,
    open,
    onClose,
  });

  return (
    <DrawerContext.Provider value={{ open, onClose, onOpen }}>
      {drawer}
    </DrawerContext.Provider>
  );
};

Drawer.CloseButton = CloseButton;
Drawer.Header = Header;
Drawer.Content = Content;

export { useDrawer };
export default Drawer;
