/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { Component } from "react";
import PropTypes from "prop-types";
import CustomTableContainer from "../../../components/CustomTable";
import Label from "../../../components/Label";
import dcLogo from "../../Common/img/favicon-32x32.png";

class ChannelList extends Component {
  constructor() {
    super();

    this.columns = [
      {
        dataKey: "name",
        label: "Naam",
        disablePadding: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: this.renderName,
      },
      {
        dataKey: "acceptance",
        label: "",
        rightAlign: true,
        disablePadding: true,
        width: 1,
        flexGrow: 1,
        cellContentRenderer: this.renderEnvironment,
      },
    ];
  }

  onRowClick = ({ rowData }) => {
    const { history } = this.props;
    return history.push(`channel/${rowData.id}/video`);
  };

  renderName({ rowData }) {
    const { name, icon_url } = rowData;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={icon_url || dcLogo}
          alt={name}
          style={{ height: 24, marginRight: 8 }}
        />
        <div>{name}</div>
      </div>
    );
  }

  renderEnvironment({ rowData }) {
    const { acceptance } = rowData;

    if (acceptance)
      return <Label style={{ marginLeft: 10 }} value="Acceptatie" />;

    return <Label style={{ marginLeft: 10 }} color="blue" value="Productie" />;
  }

  render() {
    const { channels } = this.props;

    return (
      <div style={{ flex: "1 1 auto" }}>
        <CustomTableContainer
          items={channels}
          columns={this.columns}
          onRowClick={this.onRowClick}
        />
      </div>
    );
  }
}

ChannelList.propTypes = {
  channels: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default ChannelList;
