import { useCallback } from "react";
import PropTypes from "prop-types";

// Components
import Typography from "@material-ui/core/Typography";
import CheckBox from "components/Form/Checkbox";
import NationFlag from "components/NationFlag";

// Styles
import StyledButton from "./style";

const CountryFilterOption = ({
  label,
  trackName,
  trackDetails,
  onClick,
  active,
  count,
}) => {
  const dispatchTrackingClick = useCallback(() => {
    if (!trackName) return;
    const trackingEvent = new CustomEvent("button-click", {
      detail: {
        trackName,
        trackDetails: { trackName, ...trackDetails },
      },
    });
    dispatchEvent(trackingEvent);
  }, [trackName, trackDetails]);

  const onClickHandler = useCallback(
    (event) => {
      onClick(event);
      dispatchTrackingClick();
    },
    [onClick, dispatchTrackingClick]
  );

  return (
    <StyledButton color="default" variant="text" onClick={onClickHandler}>
      <div className="column-left">
        <CheckBox name={label} checked={active} size="small" />
        <NationFlag countryCode={label} />
        <Typography variant="body1">{label}</Typography>
      </div>
      {count}
    </StyledButton>
  );
};

CountryFilterOption.defaultProps = {
  active: false,
  count: 0,
  onClick: () => null,
};

CountryFilterOption.propTypes = {
  active: PropTypes.bool,
  count: PropTypes.number,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  trackDetails: PropTypes.shape({}),
  trackName: PropTypes.string,
};

export default CountryFilterOption;
