import { PureComponent } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { AutoSizer, Column, Table } from "react-virtualized";
import { withTranslation } from "react-i18next";

import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "./CustomTableView.style";

class CustomTableView extends PureComponent {
  getRowClassName = ({ index }) => {
    const { classes } = this.props;

    return classNames(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1,
    });
  };

  cellRenderer = ({ cellData, columnIndex = null }) => {
    const {
      columns,
      classes,
      rowHeight,
      onRowClick,
      showCheckbox,
    } = this.props;

    const index =
      showCheckbox && columnIndex !== null ? columnIndex : columnIndex;
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columns[index] && columns[index].rightAlign) || false
            ? "right"
            : "left"
        }
      >
        {cellData}
      </TableCell>
    );
  };

  cellCheckboxRenderer = ({ cellData }) => {
    const { classes, rowHeight, isSelected, handleSelect } = this.props;

    const check = isSelected(cellData);

    return (
      <TableCell
        padding="checkbox"
        component="div"
        className={classNames(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="body"
        style={{ height: rowHeight }}
        onClick={(event) => event.stopPropagation()}
      >
        <Checkbox
          color="primary"
          onClick={(event) => handleSelect(event, cellData)}
          checked={check}
        />
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex, dataKey }) => {
    const {
      headerHeight,
      columns,
      classes,
      t,
      handleSort,
      sort,
      sortDirection,
    } = this.props;

    const possibleSortDirection =
      sortDirection === "asc" && sort === columns[columnIndex].dataKey
        ? "DESC"
        : "ASC";

    const inner =
      !columns[columnIndex].disableSort && sort != null ? (
        <Tooltip
          title={t(`text.SORT_${possibleSortDirection}`)}
          placement={columns.rightAlign ? "bottom-end" : "bottom-start"}
          enterDelay={300}
        >
          <TableSortLabel
            active={dataKey === sort}
            direction={sortDirection}
            onClick={() => handleSort(columns[columnIndex])}
          >
            {t(`${label}`)}
          </TableSortLabel>
        </Tooltip>
      ) : (
        <div>{t(`${label}`)}</div>
      );

    return (
      <TableCell
        component="div"
        className={classNames(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight, overflowY: "visible !important" }}
        align={columns[columnIndex].rightAlign || false ? "right" : "left"}
      >
        {inner}
      </TableCell>
    );
  };

  headerCheckboxRenderer = () => {
    const {
      headerHeight,
      classes,
      items,
      numSelected,
      handleSelectAllClick,
    } = this.props;

    return (
      <TableCell
        padding="checkbox"
        component="div"
        className={classNames(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
      >
        <Checkbox
          color="primary"
          indeterminate={numSelected > 0 && numSelected < items.length}
          checked={numSelected === items.length}
          onChange={(event) => handleSelectAllClick(event)}
        />
      </TableCell>
    );
  };

  noRowsRenderer = () => {
    const { t, classes } = this.props;

    return (
      <div className={classNames(classes.noResults)}>
        {t("text.NO_RESULTS")}
      </div>
    );
  };

  render() {
    const {
      classes,
      columns,
      rowHeight,
      headerHeight,
      items,
      showCheckbox,
      onRowClick,
      onRowsRendered,
      registerChild,
    } = this.props;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            ref={registerChild}
            className={classes.table}
            height={height}
            width={width}
            rowCount={Array.isArray(items) && items.length ? items.length : 0}
            noRowsRenderer={this.noRowsRenderer}
            rowGetter={({ index }) => items[index]}
            onRowClick={onRowClick}
            onRowsRendered={onRowsRendered}
            rowClassName={this.getRowClassName}
            rowHeight={rowHeight}
            headerHeight={headerHeight}
          >
            {showCheckbox && (
              <Column
                className={classNames(classes.flexContainer)}
                headerRenderer={(headerProps) =>
                  this.headerCheckboxRenderer({
                    ...headerProps,
                  })
                }
                cellRenderer={this.cellCheckboxRenderer}
                dataKey="id"
                width={50}
              />
            )}

            {columns.map(
              ({ cellContentRenderer = null, dataKey, ...other }, index) => {
                let renderer;
                if (cellContentRenderer != null) {
                  renderer = (cellRendererProps) =>
                    this.cellRenderer({
                      cellData: cellContentRenderer(cellRendererProps),
                      columnIndex: index,
                    });
                } else {
                  renderer = this.cellRenderer;
                }

                return (
                  <Column
                    key={dataKey}
                    headerRenderer={(headerProps) =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    className={classNames(classes.flexContainer)}
                    cellRenderer={renderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              }
            )}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

CustomTableView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      disablePadding: PropTypes.bool,
      disableSort: PropTypes.bool,
      rightAlign: PropTypes.bool,
      flexGrow: PropTypes.number,
      sortFn: PropTypes.func,
      cellContentRenderer: PropTypes.func,
    })
  ).isRequired,
  registerChild: PropTypes.func,
  showCheckbox: PropTypes.bool,
  handleSelectAllClick: PropTypes.func,
  handleSelect: PropTypes.func,
  numSelected: PropTypes.number.isRequired,
  isSelected: PropTypes.func,
  handleSort: PropTypes.func,
  sort: PropTypes.string,
  sortDirection: PropTypes.string,
  onRowClick: PropTypes.func,
  onRowsRendered: PropTypes.func,
  onChange: PropTypes.func,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  classes: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

CustomTableView.defaultProps = {
  headerHeight: 56,
  rowHeight: 56,
  showCheckbox: false,
};

export default compose(withTranslation(), withStyles(styles))(CustomTableView);
