import styled from "styled-components";

const StyledComponent = styled.form`
  width: 100%;

  .MuiTypography-root {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.grey[600]};
    font-size: 0.875rem;
  }
  .MuiTypography-h5 {
    color: ${({ theme }) => theme.palette.grey[700]};
    font-weight: 700;
    margin-bottom: 8px;
  }

  .MuiInputBase-root {
    background: white;
  }

  .MuiInputAdornment-positionEnd.error {
    color: ${({ theme }) => theme.palette.error.main};
  }

  .MuiInputAdornment-positionEnd {
    font-size: 0.75rem;
  }

  .rc-md-editor.full {
    z-index: 99999;
  }
`;

export default StyledComponent;
