const style = {
  fill: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    width: "100%",
    flexGrow: 1,
    boxSizing: "border-box",
    padding: "0 10px 10px 10px",
    "& .breadcrumbs": {
      paddingLeft: 0,
    },
  },
};

export default style;
