import styled from "styled-components";

const StyledComponent = styled.span`
  &.warning {
    color: ${({ theme }) => theme.palette.warning.dark};
  }

  &.error {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export default StyledComponent;
