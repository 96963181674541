import { call, put } from "redux-saga/effects";
import * as languageActions from "./Language.actions";

export function* loadLanguages(languageApi) {
  try {
    const languageResponse = yield call(languageApi.getLanguages);

    if (!languageResponse.status === 200) {
      return yield put(languageActions.findFailure());
    }

    return yield put(languageActions.findSuccess(languageResponse.data));
  } catch (error) {
    yield put(languageActions.findFailure());
  }

  return false;
}
