import PropTypes from "prop-types";

import { withTranslation } from "react-i18next";
import Snackbar from "@material-ui/core/Snackbar";

const CanNot = ({ t }) => (
  <Snackbar open message={<span>{t("text.NO_ACCESS")}</span>} />
);

CanNot.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(CanNot);
