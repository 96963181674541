// TODO: use colors from server
export const STATE_COLORS = {
  published: {
    main: "#2a9d8f",
    contrastText: "#ffffffe6",
  },
  changed: {
    main: "#bc6c25",
    contrastText: "#ffffffe6",
  },
  draft: {
    main: "#219ebc",
    contrastText: "#ffffffe6",
  },
};
