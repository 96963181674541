import { useState, useEffect, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// Icons
import AccessTimeIcon from "@material-ui/icons/AccessTime";

// Components
import { Tooltip, Typography, TextField, IconButton } from "@material-ui/core";
import MaskedInput from "react-text-mask";

// Utils
import { convertSecondsToHuman, convertHumanToSeconds } from "../utils";

// Style
import StyledTrack, { StyledSlider } from "./Track.style";

// 01:20:30.999
// hour : minutes: secondes . milliseconds
const TIME_STAMP_MASK = [
  /\d/,
  /\d/,
  ":",
  /\d/,
  /\d/,
  ":",
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
];

const MaskedTextField = (props) => {
  const { inputRef, onFocus, ...other } = props;

  return (
    <MaskedInput
      {...other}
      onFocus={onFocus}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={TIME_STAMP_MASK}
      placeholderChar="0"
      showMask
    />
  );
};

const valueFormatted = (value, duration) => {
  const newValue = duration * (value / 100);
  return convertSecondsToHuman(newValue);
};

const Track = ({
  id,
  name,
  title,
  icon,
  type,
  active,
  value,
  duration,
  realValues,
  currentTimePercentage,
  onTrackButtonClick,
  onUseCurrentTime,
  onChange,
  onChangeCommitted,
}) => {
  const [titleStart, titleEnd] = title;
  const [iconStart, iconEnd] = icon;
  const [nameStart, nameEnd] = name;

  const { t } = useTranslation();
  const [valueStart, valueEnd] = realValues;
  const [trackPercentages, setTrackPercentages] = useState(value);

  const [activeSliderIndex, setActiveSliderIndex] = useState();

  useEffect(() => {
    setTrackPercentages(value);
  }, [value]);

  const handle = useCallback(
    (event, newValue, callBack) => {
      const sliderIndex = event.target.attributes["data-index"]?.value;
      // // Set active/focused element
      if (sliderIndex !== undefined) {
        setActiveSliderIndex(sliderIndex);
      }
      if (activeSliderIndex === undefined) return;

      const activeType = name[activeSliderIndex];

      callBack({
        type: activeType,
        value: duration * (newValue[activeSliderIndex] / 100),
      });
    },
    [name, duration, setActiveSliderIndex, activeSliderIndex]
  );

  const handleChange = useCallback(
    (event, newVal) => {
      handle(event, newVal, onChange);
      setTrackPercentages(newVal);
    },
    [handle, onChange]
  );

  const handleCommit = useCallback(
    (event, newVal) => {
      handle(event, newVal, onChangeCommitted);
    },
    [handle, onChangeCommitted]
  );

  const onTextHandle = useCallback((event, callBack) => {
    const activeName = event.currentTarget.name;
    const timeStamp = event.currentTarget.value;
    const milliseconds = convertHumanToSeconds(timeStamp);

    const changes = {
      type: activeName,
      value: milliseconds,
    };
    callBack(changes);
  }, []);

  const onTextFocus = useCallback(
    (event) => {
      onTextHandle(event, onChange);
    },
    [onTextHandle, onChange]
  );

  const onTextChange = useCallback(
    (event) => {
      onTextHandle(event, onChangeCommitted);
    },
    [onTextHandle, onChangeCommitted]
  );

  return (
    <StyledTrack>
      <div className="info">
        <button
          type="button"
          className={active === nameStart ? "active" : null}
          name={nameStart}
          onClick={onTrackButtonClick}
        >
          {iconStart}
          <Typography variant="h6">{titleStart}</Typography>
        </button>
      </div>
      <div className="track">
        <div
          className="time-indicator"
          style={{ left: `${currentTimePercentage}%` }}
        />
        <StyledSlider
          name={id}
          track={type}
          value={trackPercentages}
          onChange={handleChange}
          onChangeCommitted={handleCommit}
          step={1 / 25}
          valueLabelDisplay="auto"
          aria-labelledby={id}
          getAriaValueText={(textValue) => valueFormatted(textValue, duration)}
          valueLabelFormat={(textValue) => valueFormatted(textValue, duration)}
        />
        <div className="input-area">
          <div className="input-container">
            <TextField
              name={nameStart}
              value={convertSecondsToHuman(valueStart)}
              onBlur={onTextChange}
              onFocus={onTextFocus}
              InputProps={{
                inputComponent: MaskedTextField,
              }}
            />
            <Tooltip title={t("labels.USE_CURRENT_VIDEO_PLAYER_TIME")}>
              <IconButton
                name={nameStart}
                size="small"
                onClick={onUseCurrentTime}
              >
                <AccessTimeIcon />
              </IconButton>
            </Tooltip>
          </div>

          {valueEnd != null && (
            <div className="input-container">
              <Tooltip title={t("labels.USE_CURRENT_VIDEO_PLAYER_TIME")}>
                <IconButton
                  name={nameEnd}
                  size="small"
                  onClick={onUseCurrentTime}
                >
                  <AccessTimeIcon />
                </IconButton>
              </Tooltip>
              <TextField
                name={nameEnd}
                className="text-right"
                onBlur={onTextChange}
                onFocus={onTextFocus}
                value={convertSecondsToHuman(valueEnd)}
                InputProps={{
                  inputComponent: MaskedTextField,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="info">
        <button
          type="button"
          className={active === nameEnd ? "active" : null}
          name={nameEnd}
          onClick={onTrackButtonClick}
        >
          {iconEnd}
          <Typography variant="h6">{titleEnd}</Typography>
        </button>
      </div>
    </StyledTrack>
  );
};

Track.defaultProps = {
  name: [],
  title: [],
  icon: [],
  type: null,
  active: null,
  duration: 0,
  value: [],
  realValues: [],
  currentTimePercentage: 0,

  onTrackButtonClick: () => {},
  onUseCurrentTime: () => {},
  onChange: () => {},
  onChangeCommitted: () => {},
};

Track.propTypes = {
  name: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.arrayOf(PropTypes.node),
  type: PropTypes.oneOf(["inverted", null]),
  active: PropTypes.string,
  duration: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.number),
  realValues: PropTypes.arrayOf(PropTypes.number),
  currentTimePercentage: PropTypes.number,
  onTrackButtonClick: PropTypes.func,
  onUseCurrentTime: PropTypes.func,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
};

export default memo(Track);
