import PropTypes from "prop-types";
import "flag-icons/css/flag-icons.min.css";
import Utils from "../../features/Common/utils";
import StyledComponent from "./style";

const NationFlag = ({ countryCode, className, style }) => {
  const flagCode = Utils.Common.flagCode(countryCode.toLocaleLowerCase());

  if (flagCode === "xx")
    return (
      <span
        className={`fi ${className}`}
        title={countryCode}
        style={{
          color: "white",
          textAlign: "center",
          fontWeight: "bold",
          letterSpacing: "-1px",
          position: "relative",
          display: "inline-block",
          width: "1.33333333em",
          lineHeight: "1em",
          backgroundColor: "#1976d2",
        }}
      ></span>
    );

  return (
    <StyledComponent
      className={`fi fi-${flagCode} ${className}`}
      style={style}
      title={countryCode}
    />
  );
};

NationFlag.defaultProps = {
  className: "",
};

NationFlag.propTypes = {
  countryCode: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default NationFlag;
