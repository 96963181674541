import { useState, memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";

// Icons

import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

// Components
import { ListItem, ListItemText } from "@material-ui/core";
import NationFlag from "components/NationFlag/NationFlag";

// Local Components
import ScheduleDetailsDialog from "../../AssetDetailScheduleDetailsDialog";
import SidebarGroup from "../../AssetDetailSidebarGroup";

// Style
import StyledComponent from "./style";

const headerIcon = <EventOutlinedIcon />;

const ScheduleDetails = ({ asset }) => {
  const [dialogDisplay, setDialogDisplay] = useState(false);
  const { t } = useTranslation();
  const title = t("labels.PUBLICATION_DATE");

  const MenuActions = () => (
    <>
      <IconButton
        className="button"
        aria-label="Edit"
        onClick={() => setDialogDisplay(true)}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <SidebarGroup
        name="schedule-details"
        title={title}
        headerIcon={headerIcon}
        menuActions={<MenuActions />}
      >
        <StyledComponent>
          {asset?.schedule &&
            asset.schedule.length > 0 &&
            asset.schedule.map((schedule) => (
              <div key={schedule.country.id}>
                <ListItem className="listItem">
                  <ListItemText className="listItemText">
                    <NationFlag countryCode={schedule.country.code} />
                    {schedule.axinom_start ? (
                      <>
                        {moment
                          .unix(schedule.axinom_start)
                          .format("DD MMMM YYYY")}{" "}
                        -{" "}
                        {moment
                          .unix(schedule.axinom_end)
                          .format("DD MMMM YYYY")}
                      </>
                    ) : (
                      t("text.NO_PUBLICATION_DATE")
                    )}
                  </ListItemText>
                </ListItem>
              </div>
            ))}
        </StyledComponent>
      </SidebarGroup>
      <ScheduleDetailsDialog
        open={dialogDisplay}
        onClose={() => setDialogDisplay(false)}
        asset={asset}
      />
    </>
  );
};

ScheduleDetails.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default memo(ScheduleDetails);
