import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// Utils
import Utils from "features/Common/utils";
import { useAnalytics } from "features/Analytics";

// Icons
import MovieCreationOutlinedIcon from "@material-ui/icons/MovieCreationOutlined";
import MovieFilterIcon from "@material-ui/icons/MovieFilter";
import WarningIcon from "@material-ui/icons/Warning";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import DownloadIcon from "@material-ui/icons/GetApp";

// Form
import { useForm } from "react-hook-form";
import * as yup from "yup";

// Actions
import { closeFragmentCreateDialog } from "features/Fragment/reducer";
import { fragmentCreated } from "features/Asset/Asset.actions";

// Components
import Alert from "@material-ui/lab/Alert";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "components/Button";
import TextField from "components/Form/TextField";
import CircularProgressCenter from "components/CircularProgressCenter";
import { CheckboxWithControl } from "components/Form/Checkbox";
import { SelectWithControl } from "components/Form/Select";

// Service
import { createFragment as createFragmentRequest } from "../services";
import { useCurrentFragmentUrl } from "../hooks";

// Style
import StyledComponent from "./style";

const IDENTIFIER_FRAGMENT_MAX_LENGTH = 39;
const IDENTIFIER_SEPARATOR_LENGTH = 1;

const IdentifierInput = ({ errors, setValue, register }) => {
  const { t } = useTranslation();

  const onChangeHandler = (event) => {
    setValue("identifier", Utils.Assets.changeIdentifier(event.target.value));
  };

  return (
    <TextField
      name="identifier"
      label={t("labels.IDENTIFIER")}
      variant="outlined"
      error={errors?.identifier?.message}
      onChange={onChangeHandler}
      ref={register}
      fullWidth
    />
  );
};

const parseError = (error) => {
  if (!error?.response) return error;
  const serverErrors = error?.response?.data?.errors;
  return serverErrors?.identifier;
};

const FragmentCreate = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { track } = useAnalytics();

  const { t } = useTranslation();
  const history = useHistory();
  const fragmentBasePath = useCurrentFragmentUrl();

  // State
  const [creating, setCreating] = useState(false);
  const [createError, setCreateError] = useState(null);

  // Selectors
  const asset = useSelector((state) => state.asset.selected);
  const { loading, data } = asset;
  const { files } = data || {};

  // subtract 1 from total length for - separator dash between fragment and
  // asset identifiers
  const maxIdentifierLength =
    IDENTIFIER_FRAGMENT_MAX_LENGTH -
      asset?.data?.identifier?.length -
      IDENTIFIER_SEPARATOR_LENGTH || 0;

  // Form
  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required(`${t("labels.FIELD_IS_REQUIRED")}`),
        identifier: yup
          .string()
          .nullable()
          .max(
            maxIdentifierLength,
            `${t("labels.FRAGMENT_IDENTIFIER_MAX", {
              max: IDENTIFIER_FRAGMENT_MAX_LENGTH,
            })}`
          ),
        file: yup.number(),
        trailer: yup.boolean().required(`${t("labels.FIELD_IS_REQUIRED")}`),
        drm: yup.boolean().required(`${t("labels.FIELD_IS_REQUIRED")}`),
      }),
    [t, maxIdentifierLength]
  );

  const defaultValues = {
    name: "",
    identifier: "",
    drm: false,
    file: -1,
    trailer: false,
    downloadable: false,
  };

  const {
    handleSubmit,
    register,
    control,
    errors,
    formState,
    reset,
    setValue,
    watch,
  } = useForm({
    validationSchema,
    defaultValues,
  });

  const drm = watch("drm");
  const trailer = watch("trailer");
  const downloadable = watch("downloadable");

  const createFragment = (newFragment) => {
    setCreating(true);
    setCreateError(null);

    createFragmentRequest(newFragment)
      .then((response) => {
        const fragment = response?.data || {};
        const { id } = fragment;
        history.push(`${fragmentBasePath}/${id}`);

        track("Fragment Create Success", { fragment });
        dispatch(fragmentCreated(fragment));
        dispatch(closeFragmentCreateDialog());
      })
      .catch((error) => {
        const serverErrors = parseError(error);
        track("Fragment Create Failed ", { error: serverErrors });
        setCreateError(serverErrors);
      })
      .finally(() => {
        setCreating(false);
      });
  };

  const onSubmitHandler = (formData) => {
    const selectedFile = files?.find(
      (videoFile) => videoFile.id === formData?.file
    );

    const fields = {
      asset: { id: asset?.id },
      name: formData.name,
      identifier: formData.identifier,
      trailer: Number(formData.trailer),
      downloadable: Number(formData.downloadable),
      drm: Number(formData.drm),
    };

    if (formData.identifier) {
      fields.identifier = formData.identifier;
    }
    if (selectedFile) {
      fields.file = selectedFile;
    }

    onSubmit(fields);
    createFragment(fields);
  };

  // Set default values
  useEffect(() => {
    if (!files?.length) return;

    reset({
      file: files[0]?.id,
    });
  }, [reset, files]);

  // Invoke formState dirty values so that the proxy is
  // trigger/activated. Otherwise formState.isDirty doesn't work
  useEffect(() => {
    if (!formState) return;
    formState.dirtyFields.values();
  }, [formState]);

  if (loading) return <CircularProgressCenter type="inline" />;
  return (
    <StyledComponent>
      <form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TextField
              name="name"
              className="input"
              autoFocus
              required
              label={t("labels.NAME")}
              variant="outlined"
              error={errors.name?.message}
              ref={register}
              fullWidth
            />
          </Grid>
          <Grid item>
            <IdentifierInput
              register={register}
              errors={errors}
              setValue={setValue}
              fullWidth
            />
          </Grid>
          <Grid item>
            <div className="video-selector">
              <SelectWithControl
                name="file"
                label={t("labels.VIDEO_FILE")}
                control={control}
              >
                <MenuItem value={-1} disabled>
                  <span style={{ color: "#0000008a" }}>
                    {t("labels.NO_VIDEO_SOURCE_SELECTED")}
                  </span>
                </MenuItem>
                {files?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
                {!files?.length && (
                  <MenuItem disabled>
                    <WarningIcon />
                    {t("text.NO_VIDEO_FILES")}
                  </MenuItem>
                )}
              </SelectWithControl>
            </div>
          </Grid>
          <Grid item>
            <FormLabel component="legend">{t("labels.VIDEO_TYPE")}</FormLabel>
            <CheckboxWithControl
              name="trailer"
              color="primary"
              label={trailer ? t("labels.TRAILER") : t("labels.VIDEO")}
              icon={<MovieCreationOutlinedIcon />}
              iconChecked={<MovieFilterIcon />}
              error={errors?.trailer?.message}
              control={control}
            />
          </Grid>
          <Grid item>
            <FormLabel component="legend">Downloadable</FormLabel>
            <CheckboxWithControl
              name="downloadable"
              color="primary"
              label={
                downloadable
                  ? t("labels.OFFLINE_AVAILABLE")
                  : t("labels.ONLY_ONLINE")
              }
              icon={<DownloadIcon />}
              iconChecked={<DownloadIcon color={"blue"} />}
              error={errors?.downloadable?.message}
              control={control}
            />
          </Grid>
          <Grid item>
            <FormLabel component="legend">{t("labels.DRM")}</FormLabel>
            <CheckboxWithControl
              name="drm"
              color="primary"
              className="input"
              label={drm ? t("labels.DRM_ENABLED") : t("labels.DRM_DISABLED")}
              icon={<VerifiedUserOutlinedIcon />}
              iconChecked={<VerifiedUserIcon />}
              labelPlacement="end"
              control={control}
            />
            <Typography variant="body2" className="drm-info">
              {t("text.DRM_INFO")}
            </Typography>
          </Grid>
          {createError && <Alert severity="error">{createError}</Alert>}
          <div className="actions">
            <Button
              type="submit"
              color="primary"
              className="input"
              trackName="fragment.create.save"
              trackDetails={{
                assetId: asset?.id,
                assetName: asset?.name,
              }}
              loading={creating}
              disabled={!formState.dirty}
            >
              {t("buttons.CREATE")}
            </Button>
          </div>
        </Grid>
      </form>
    </StyledComponent>
  );
};

FragmentCreate.defaultProps = {
  onSubmit: () => {},
};

FragmentCreate.propTypes = {
  onSubmit: PropTypes.func,
};

export default FragmentCreate;
