import PropTypes from "prop-types";

// Components
import HeaderBasic from "components/HeaderBasic";

// Styles
import StyledComponent from "./style";

const PlainLayout = ({ children, channel }) => (
  <StyledComponent>
    <section>
      <HeaderBasic channel={channel} />
      <main>{children}</main>
    </section>
  </StyledComponent>
);

PlainLayout.defaultProps = {
  children: null,
  channel: {},
};

PlainLayout.propTypes = {
  channel: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    url: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default PlainLayout;
