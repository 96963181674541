import PropTypes from "prop-types";

const TabPanel = ({ children, tabKey, active }) => {
  if (tabKey !== active) return null;
  return <div>{children}</div>;
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  tabKey: PropTypes.string.isRequired,
  active: PropTypes.string.isRequired,
};

export default TabPanel;
