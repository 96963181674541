import React, { useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { FormLabel } from "@material-ui/core";
import { Can } from "features/Auth/AccessControl";
import { permissions } from "features/Auth/permissions.constants";
import { STATE_COLORS } from "features/Seo/SeoEntryPage/constants";

export default function SeoDetailPublishButton({
  entry,
  publish,
  unpublish,
  isDirty,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  if (!entry || !entry?.id || !entry?.state) {
    return null;
  }

  return (
    <Can I={permissions.actions.PUBLISH} a={permissions.subjects.SEO_ENTRY}>
      <FormLabel>Publish / Unpublish</FormLabel>

      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} className="sideBarButtons">
          <div className="stateButton">
            <ButtonGroup
              disabled={isDirty}
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
              fullWidth
            >
              <Button
                style={{
                  backgroundColor: isDirty
                    ? undefined
                    : STATE_COLORS[entry.state].main,
                  color: isDirty
                    ? undefined
                    : STATE_COLORS[entry.state].contrastText,
                }}
              >
                {entry.state}
              </Button>
              <Button
                color="primary"
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
                style={{
                  width: "auto",
                  backgroundColor: isDirty ? undefined : "#114f5e",
                }}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {entry.state !== "published" && (
                          <MenuItem onClick={publish}>Publish</MenuItem>
                        )}
                        {entry.state !== "draft" && (
                          <MenuItem onClick={unpublish}>Unpublish</MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Grid>
      </Grid>
    </Can>
  );
}
