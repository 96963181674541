import PropTypes from "prop-types";

// Components
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";

const FormGroupWithTitle = ({ title, description, children }) => {
  return (
    <FormGroup>
      <FormLabel component="legend">{title}</FormLabel>
      {description && <Typography variant="body1">{description}</Typography>}
      {children}
    </FormGroup>
  );
};

FormGroupWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default FormGroupWithTitle;
