import styled from "styled-components";

const StyledComponent = styled.div`
  padding: 16px;

  & header {
    display: flex;
    align-items: center;
  }

  & header .MuiTypography-h5 {
    font-size: 0.875rem;
    margin-left: 8px;
  }

  & header .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  .country-info {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 4px;
    margin-bottom: 8px;

    &.active header .MuiTypography-h5 {
      font-weight: bold;
    }
  }

  .country-info header {
    display: flex;

    & .fi {
      margin-right: 8px;
    }

    & .MuiTypography-h5 {
      font-size: 0.75rem;
    }
  }

  & .fi {
    border: 5px solid ${({ theme }) => theme.palette.grey[300]};
    border-radius: 4px;
  }
`;

export default StyledComponent;
