import PropTypes from "prop-types";
import classNames from "classnames";

// Style
import StyledComponent from "./ColorCircle.style";

const ColorCircle = ({ color, hasError }) => {
  return (
    <StyledComponent
      className={classNames({
        hasError,
      })}
      backgroundColor={color}
    />
  );
};

ColorCircle.defaultProps = {
  color: "black",
  hasError: false,
};

ColorCircle.propTypes = {
  color: PropTypes.string,
  hasError: PropTypes.bool,
};

export default ColorCircle;
