import { CloseOutlined } from "@material-ui/icons";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { first, get } from "lodash";
import { useMemo, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  borderWidth: "2px",
  borderRadius: "4px",
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fff",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const thumb = {
  width: "100%",
  display: "inline-flex",
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  borderRadius: "4px",
  height: "auto",
};

const getKey = (type, language, country) =>
  `${type.type}.locales.${language.code}-${country.code}.media`;

const ComposeDropZone = ({
  form,
  selectedType,
  selectedLanguage,
  selectedCountry,
}) => {
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles.length > 0 ? first(acceptedFiles) : null;

      setFile(file);
      setPreview(file ? URL.createObjectURL(file) : null);

      selectedLanguage.countries.forEach((country) => {
        const current = get(
          form.values,
          `${getKey(selectedType, selectedLanguage, country)}`
        );

        if (current === null || country.id === selectedCountry.id) {
          form.setFieldValue(
            getKey(selectedType, selectedLanguage, country),
            file
          );
        }
      });
    },
    [form, selectedType, selectedLanguage, selectedCountry]
  );

  const useForAllLanguages = () => {
    const locales = get(form.values, `${selectedType.type}.locales`, {});

    Object.keys(locales).forEach((locale) => {
      const current = get(
        form.values,
        `${selectedType.type}.locales.${locale}`
      );

      if (current?.media === null) {
        form.setFieldValue(
          `${selectedType.type}.locales.${locale}.media`,
          file
        );
      }
    });
  };

  useEffect(() => {
    const file =
      (selectedType &&
        selectedLanguage &&
        selectedCountry &&
        get(
          form.values,
          `${getKey(selectedType, selectedLanguage, selectedCountry)}`
        )) ||
      null;
    setFile(file);

    setPreview(file ? URL.createObjectURL(file) : null);
  }, [form.values, selectedType, selectedLanguage, selectedCountry]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      multiple: false,
      accept: {
        "image/jpeg": [".jpg"],
        "video/mp4": [".mp4"],
      },
      maxSize: 500000000,
      onDrop,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs>
          <Typography noWrap variant="h5" style={{ height: "36px" }}>
            Media
          </Typography>
        </Grid>

        {file && (
          <Grid item>
            <Tooltip title="Remove media">
              <IconButton aria-label="Remove media" onClick={() => onDrop([])}>
                <CloseOutlined style={{ height: "20px", width: "20px" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <Box {...getRootProps({ style })} sx={{ marginTop: "24px" }}>
        <input {...getInputProps()} />
        {!file && (
          <Box sx={{ padding: "48px 16px" }}>
            <Typography
              variant="body1"
              style={{ display: "block", color: "#999" }}
            >
              Drag and drop media here...
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                display: "block",
                color: "#aaa",
                marginTop: "2px",
                fontSize: "12px",
              }}
            >
              Only JPG and MP4 files are allowed.
            </Typography>
          </Box>
        )}

        {file && preview && (
          <aside style={thumbsContainer}>
            <div style={thumb}>
              <div style={thumbInner}>
                {file.type.includes("video/") && (
                  <video
                    autoPlay
                    loop
                    muted
                    alt={file.name}
                    src={preview}
                    style={img}
                    onLoadedData={() => {
                      URL.revokeObjectURL(preview);
                    }}
                  />
                )}
                {file.type.includes("image/") && (
                  <img
                    alt={file.name}
                    src={preview}
                    style={img}
                    onLoadedData={() => {
                      URL.revokeObjectURL(preview);
                    }}
                  />
                )}
              </div>
            </div>
          </aside>
        )}
      </Box>

      {file && preview && (
        <Button
          onClick={useForAllLanguages}
          variant="outlined"
          size="small"
          sx={{ marginTop: "12px" }}
        >
          Use for all languages
        </Button>
      )}
    </>
  );
};

export default ComposeDropZone;
