/* eslint-disable camelcase */
/* eslint-disable react/display-name */
import { useState, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Icons
import GetAppIcon from "@material-ui/icons/GetApp";
import CropSquareIcon from "@material-ui/icons/CropSquare";
import CropPortraitIcon from "@material-ui/icons/CropPortrait";
import CropLandscapeIcon from "@material-ui/icons/CropLandscape";

// Components
import { Grid, Typography } from "@material-ui/core";
import { DropZone } from "components";
import DownloadLink from "components/DownloadLink";

// Redux
import { uploadFile, deleteFile } from "../reducer";
import { getImageTypes } from "../../File/selectors";

// Utils
import Utils from "../../Common/utils";

// Style
import StyledComponent from "./style";

// 50MB
const MAX_SIZE_IN_BYTES = 50000000;
const IMAGE_HEIGHT = 150;
const ICONS = {
  portrait: () => <CropPortraitIcon fontSize="small" />,
  landscape: () => <CropLandscapeIcon fontSize="small" />,
  landscape_clean: () => <CropLandscapeIcon fontSize="small" />,
  thumbnail: () => <CropSquareIcon fontSize="small" />,
};

const Errors = ({ errors }) => {
  if (!errors) {
    return null;
  }

  const errorEntries = Object.entries(errors);
  return (
    <>
      {errorEntries.map(([key, value]) => (
        <p key={key}>{value}</p>
      ))}
    </>
  );
};

const ImageUpload = ({
  id,
  typeId,
  type,
  fileId,
  path,
  rawFile,
  name,
  identifier,
  description,
  isPlaceHolder,
  accept,
  minWidth,
  minHeight,
  ratio,
  maxSize,
  errors,
  disabled,
  onDropAccepted,
  networkState,
  onDelete,
  placeholderText,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const dropZoneRef = useRef();

  const url = Utils.Fragments.getAssetStoragePath(path);
  const rawUrl = Utils.Fragments.getAssetStoragePath(rawFile?.path);

  const Icon = ICONS[identifier];
  const previewFiles = useMemo(() => (path ? [{ preview: url }] : null), [
    path,
    url,
  ]);

  useEffect(() => {
    if (!errors) {
      setError(null);
    } else {
      setError(<Errors errors={errors} />);
    }
  }, [errors]);

  const onDropAcceptedHandle = (acceptedFiles) => {
    const isNew = isPlaceHolder;
    onDropAccepted({
      id,
      typeId,
      fileId,
      acceptedFiles,
      isNew,
      type,
    });
  };
  const onDeleteHandle = () => {
    onDelete({
      id,
      typeId,
      fileId,
      type,
    });
  };

  return (
    <div>
      <Typography variant="h5">
        <Icon />
        {description}
      </Typography>
      <Typography variant="h6">
        {t("labels.IMAGE_MIN_DIMENSIONS", { minWidth, minHeight })}
      </Typography>
      <DropZone
        ref={dropZoneRef}
        className="drop-zone"
        defaultFiles={previewFiles}
        width={IMAGE_HEIGHT * ratio}
        height={IMAGE_HEIGHT}
        maxSize={maxSize}
        error={error}
        accept={accept}
        t={t}
        placeholderText={placeholderText}
        state={networkState}
        onDropAccepted={onDropAcceptedHandle}
        onDelete={onDeleteHandle}
        checkMarkConfig={{
          size: 32,
          borderColor: "#0003",
          borderWidth: 2,
          checkThickness: 3,
          backgroundColor: "#000000a6",
          successColor: "white",
          successBackground: "#1E6D1E",
          successBorderColor: "#1E6D1E",
        }}
        disabled={disabled}
      />
      {path && rawUrl && (
        <DownloadLink
          className="download-link"
          href={rawUrl}
          name={name}
          trackName="Download image file"
          trackDetails={{ url: rawUrl, fragmentId: id }}
        >
          {name}
          <GetAppIcon />
        </DownloadLink>
      )}
    </div>
  );
};

const FragmentImagesEdit = ({ fragment, fragmentFiles }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const networkState = useSelector((state) => state.fragment?.networkState);
  const imageTypes = useSelector(getImageTypes);

  if (!fragment) return null;

  const { id: fragmentId } = fragment;
  const fragmentNetworkState = networkState[fragmentId] || {};

  const onDropAccepted = ({
    id,
    typeId,
    fileId,
    acceptedFiles,
    isNew,
    type,
  }) => {
    dispatch(
      uploadFile({
        fragmentId: id,
        fileId,
        typeId,
        acceptedFiles,
        isNew,
        type,
      })
    );
  };

  const onDelete = ({ id, typeId, fileId, type }) => {
    dispatch(
      deleteFile({
        fragmentId: id,
        typeId,
        fileId,
        type,
      })
    );
  };

  return (
    <StyledComponent>
      <Grid container direction="column" spacing={3}>
        {imageTypes.map(
          ({ id, identifier, image, mimes, max_filesize, type }) => {
            // Network state
            const networkStates = fragmentNetworkState[type] || {};
            const typeNetworkState = networkStates[id] || {};
            const state = typeNetworkState?.state || "idle";
            const errors = typeNetworkState?.errors;

            // Image dimensions
            const aspectRatioString = image.aspect_ratio;
            const ratio = Utils.Common.calculateRatio(aspectRatioString);
            const minWidth = image.min_width;
            const minHeight = image.min_height;

            // Actual image data
            const imageFile =
              fragmentFiles.find((file) => file?.type?.id === id) || {};
            const { paths, name } = imageFile;
            const thumbnail = Utils.Common.getFilePathByIdentifier(
              paths,
              "thumbnail"
            );
            const raw = Utils.Common.getFilePathByIdentifier(paths, "raw");
            const pathObject = thumbnail || raw;
            const path = paths?.length > 0 ? `/${pathObject?.path}` : null;
            const isPlaceHolder = !paths || paths.length === 0;

            return (
              <Grid item key={id}>
                <ImageUpload
                  id={fragmentId}
                  typeId={id}
                  fileId={imageFile?.id}
                  path={path}
                  rawFile={raw}
                  name={name}
                  type={type}
                  accept={Utils.Fragments.formatMimes(mimes)}
                  isPlaceHolder={isPlaceHolder}
                  networkState={state}
                  minWidth={minWidth}
                  minHeight={minHeight}
                  identifier={identifier}
                  errors={errors}
                  maxSize={max_filesize || MAX_SIZE_IN_BYTES}
                  ratio={ratio}
                  placeholderText={t("text.DROP_FILE_HERE")}
                  onDropAccepted={onDropAccepted}
                  onDelete={onDelete}
                  description={`${identifier} ${aspectRatioString}`}
                />
              </Grid>
            );
          }
        )}
      </Grid>
    </StyledComponent>
  );
};

FragmentImagesEdit.propTypes = {};

export default FragmentImagesEdit;
