import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { InfiniteLoader } from "react-virtualized";

import CustomTableContainer from "../../../components/CustomTable";
import Label from "../../../components/Label";

class JobListComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = props.columns;

    const nameIndex = this.columns.findIndex((x) => x.dataKey === "name");
    this.columns[nameIndex].cellContentRenderer = this.nameCellRenderer;

    const licenseIndex = this.columns.findIndex((x) => x.dataKey === "license");
    this.columns[
      licenseIndex
    ].cellContentRenderer = this.licenseNameCellRenderer;

    const stateIndex = this.columns.findIndex((x) => x.dataKey === "state");
    this.columns[stateIndex].cellContentRenderer = this.stateCellRenderer;
  }

  nameCellRenderer = ({ rowData }) => {
    const { fragment, file, encode } = rowData;

    return (
      <div>
        {fragment.asset.name}:<strong>{fragment.name}</strong>
        {file && (
          <small>
            (<strong>d{file.name}</strong>)
          </small>
        )}
        {encode && (
          <small>
            (<strong>{encode.name}</strong>)
          </small>
        )}
      </div>
    );
  };

  licenseNameCellRenderer = ({ rowData }) => {
    const { fragment } = rowData;

    const allLicenses = fragment.asset.licenses
      .map((license) => license.name)
      .join(", ")
      .replace(/, ([^,]*)$/, " en $1");

    return (
      <div>
        {fragment && fragment.asset.licenses && <span>{allLicenses}</span>}
      </div>
    );
  };

  stateCellRenderer = ({ rowData }) => {
    const { client, command, encode, status, priority } = rowData;

    return (
      <div style={{ display: "flex" }}>
        {client && <Label value={client.name} />}
        {command && (
          <Label
            value={
              <span>
                {command.name}
                {encode && encode.preset && (
                  <span>
                    : <strong>{encode.preset.name}</strong>
                  </span>
                )}
              </span>
            }
            color="blue"
          />
        )}

        {rowData && <Label value={rowData} color={status} type="job_status" />}
        {priority === 1 && <Label value={<strong>P</strong>} color="blue" />}
        {encode && encode.version && (
          <Label value={<strong>{encode.version}</strong>} color="blue" />
        )}
      </div>
    );
  };

  isRowLoaded = ({ index }) => {
    const { jobs } = this.props;
    return !!jobs[index];
  };

  render() {
    const {
      jobs,
      remoteRowCount,
      loadMoreRows,
      onTableChange,
      onRowClick,
    } = this.props;

    return (
      <div style={{ flex: "1 1 auto" }}>
        <InfiniteLoader
          isRowLoaded={this.isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={remoteRowCount}
        >
          {({ onRowsRendered, registerChild }) => (
            <CustomTableContainer
              items={jobs}
              columns={this.columns}
              onRowsRendered={onRowsRendered}
              registerChild={registerChild}
              rowCount={remoteRowCount}
              onChange={onTableChange}
              onRowClick={onRowClick}
              showCheckbox
            />
          )}
        </InfiniteLoader>
      </div>
    );
  }
}

JobListComponent.propTypes = {
  jobs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remoteRowCount: PropTypes.number.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default withTranslation()(JobListComponent);
