import { compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

// creates the store
const storeCreator = (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  /* ------------- Saga Middleware ------------- */

  const sagaMiddleware = createSagaMiddleware();

  middleware.push(sagaMiddleware);

  enhancers.push(applyMiddleware(...middleware));

  /* ------------- Assemble Middleware ------------- */

  let composeEnhancers = compose;

  /* eslint-disable no-underscore-dangle */
  if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function") {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    });
  }

  const store = createStore(rootReducer, composeEnhancers(...enhancers));

  sagaMiddleware.run(rootSaga);

  return store;
};

export default storeCreator;
