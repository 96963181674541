import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.grey[300]};
  padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
  margin: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
  border-radius: 4px;

  header {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    align-items: center;
    margin: ${({ theme }) => `${theme.spacing(0.5)}px 0`};

    & > h6 {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.palette.grey[600]};
    }

    .MuiIconButton-root {
      padding: 0;

      &:hover {
        background: none;
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  main {
    & h6 {
      font-size: 0.75rem;
      line-height: 1;
    }

    & span {
      font-size: 0.75rem;
    }

    .MuiAvatar-root {
      margin-right: ${({ theme }) => theme.spacing(2)}px;
      width: ${({ theme }) => theme.spacing(3.5)}px;
      height: ${({ theme }) => theme.spacing(3.5)}px;
      font-size: 12px;
    }
  }
`;
