import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withSnackbar } from "notistack";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Create";

import { ListSubheader, ListItemIcon, Button } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";

import {
  getUser,
  updateUser,
  deleteChannel,
  addChannel,
  deleteRole,
  addRole,
  deleteCountry,
  addCountry,
  deleteUser,
  restoreUser,
} from "../../../Auth/user.service";

import styles from "./View.styles";
import DeleteButton from "../../../../components/Form/DeleteButton";
import { getChannels } from "../../../Channel/channel.service";
import { getRoles, sendActivation } from "../role.service";
import { getCountries } from "../../../Common/country.service";
import { Component, Fragment } from "../../../../components";
import { getUserRole } from "../../../Auth/roles.utils";
import { AutoCompleteList } from "../../../../components/List/AutoCompleteList";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import AllInDialog from "../../../../components/Dialog/AllInDialog";
import UserEditForm from "../Form/Form";

class ViewContainer extends Component {
  constructor(props) {
    super(props);

    this.initState({
      undo: {
        deleting: false,
      },
      user: {
        channels: [],
        roles: [],
        countries: [],
        deleted: null,
      },
      channels: [],
      roles: [],
      countries: [],
      editDialogOpen: false,
    });
  }

  componentDidMount() {
    this.preload(
      this.getUser(),
      this.getChannels(),
      this.getRoles(),
      this.getCountries()
    );
  }

  // Resolves
  getUser() {
    const { match } = this.props;

    return getUser(match.params.id).then((response) => {
      this.setState({
        user: response.data.data,
      });
    });
  }

  getChannels() {
    return getChannels().then((response) => {
      this.setState({
        channels: response.data,
      });
    });
  }

  getRoles() {
    return getRoles().then((response) => {
      this.setState({
        roles: response.data.data,
      });
    });
  }

  getCountries() {
    return getCountries().then((response) => {
      this.setState({
        countries: response.data,
      });
    });
  }

  // Events
  onDeleteUser = () => {
    const { user, undo } = this.state;

    deleteUser(user.id).then((response) => {
      if (response.status === 200) {
        undo.deleting = true;

        this.setState({ user: response.data.data, undo });
      }
    });
  };

  onEditUser = () => {
    this.setState({ editDialogOpen: true });
  };

  onEditUserClose = () => {
    this.setState({ editDialogOpen: false });
  };

  onEditUserSave = (updatedUser) => {
    updateUser(updatedUser).then((response) => {
      this.setState({ editDialogOpen: false, user: response.data.data });
    });
  };

  onRestoreUser = () => {
    const { user, undo } = this.state;

    restoreUser(user.id).then((response) => {
      undo.deleting = false;

      this.setState({ user: response.data.data, undo });
    });
  };

  onChannelDelete = (channel) => {
    const { user } = this.state;

    deleteChannel(user.id, channel.id).then((response) => {
      this.setState({ user: response.data.data });
    });
  };

  onChannelAdd = (channel) => {
    const { user } = this.state;

    addChannel(user.id, channel.id).then((response) => {
      this.setState({ user: response.data.data });
    });
  };

  onRoleDelete = (role) => {
    const { user } = this.state;

    deleteRole(user.id, role.id).then((response) => {
      this.setState({ user: response.data.data });
    });
  };

  onRoleAdd = (role) => {
    const { user } = this.state;

    addRole(user.id, role.id).then((response) => {
      this.setState({ user: response.data.data });
    });
  };

  onCountryDelete = (country) => {
    const { user } = this.state;

    deleteCountry(user.id, country.id).then((response) => {
      this.setState({ user: response.data.data });
    });
  };

  onCountryAdd = (country) => {
    const { user } = this.state;

    addCountry(user.id, country.id).then((response) => {
      this.setState({ user: response.data.data });
    });
  };

  // Getters
  getFilteredChannels() {
    const { user, channels } = this.state;

    let channelsFiltered = [];

    if (channels.length > 0) {
      channelsFiltered = channels.filter(
        (channel) => !user.channels.some((e) => e.id === channel.id)
      );
    }

    return channelsFiltered;
  }

  getFilteredRoles() {
    const { user, roles } = this.state;

    let rolesFiltered = [];

    if (roles.length > 0) {
      rolesFiltered = roles.filter(
        (role) => !user.roles.some((r) => r.id === role.id)
      );
    }

    return rolesFiltered;
  }

  getFilteredCountries() {
    const { user, countries } = this.state;

    let countriesFiltered = [];

    if (countries.length > 0) {
      countriesFiltered = countries.filter(
        (country) => !user.countries.some((c) => c.id === country.id)
      );
    }

    return countriesFiltered;
  }

  isDeleted = () => {
    const { user } = this.state;

    return !!(user.deleted_at && user.deleted_at !== null);
  };

  handleActivationClick = () => {
    const { t, enqueueSnackbar } = this.props;
    const { user } = this.state;

    sendActivation(user.id).then(() =>
      enqueueSnackbar(t("text.ACTIVATION_MAIL_SEND"), { variant: "success" })
    );
  };

  renderRestoreButton() {
    const { t } = this.props;

    return (
      <Button variant="contained" color="default" onClick={this.onRestoreUser}>
        {t("buttons.RESTORE")}
      </Button>
    );
  }

  render() {
    const { loading } = this.state;

    if (loading) return this.loading();

    const { t, classes } = this.props;
    const { undo, user, editDialogOpen } = this.state;

    const userRole = getUserRole(t, user);

    return (
      <Fragment fill>
        <Breadcrumbs
          append={{ to: `/settings/users/${user.id}`, name: user.name }}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <List
                subheader={
                  <ListSubheader component="div" className={classes.subHeader}>
                    {t("labels.USER_INFORMATION")}
                  </ListSubheader>
                }
              >
                <ListItem>
                  <ListItemIcon>
                    <span>{t("labels.NAME")}</span>
                  </ListItemIcon>

                  <ListItemText primary={user.name} />
                  <Button
                    onClick={this.handleActivationClick}
                    style={{ float: "right" }}
                    variant="contained"
                    color="primary"
                    startIcon={<EmailIcon />}
                    disabled={user.activated}
                  >
                    {t("buttons.SEND_ACTIVATION_EMAIL")}
                  </Button>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <span>{t("labels.EMAIL")}</span>
                  </ListItemIcon>
                  <ListItemText primary={user.email} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <span>{t("labels.ROLE")}</span>
                  </ListItemIcon>
                  <ListItemText primary={userRole} />
                </ListItem>
              </List>

              <Grid
                container
                direction="row"
                justify="space-between"
                style={{ paddingRight: "17px" }}
              >
                <Button
                  onClick={this.onEditUser}
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                >
                  {t("buttons.CHANGE")}
                </Button>

                <AllInDialog
                  title={t("buttons.CHANGE_RESOURCE", { resource: user.name })}
                  open={editDialogOpen}
                  model={user}
                  onClose={this.onEditUserClose}
                  onSave={this.onEditUserSave}
                >
                  <UserEditForm />
                </AllInDialog>

                {this.isDeleted() && !undo.deleting ? (
                  this.renderRestoreButton()
                ) : (
                  <DeleteButton
                    resource={t("labels.USER")}
                    getUndoState={undo.deleting}
                    onClick={this.onDeleteUser}
                    onUndo={this.onRestoreUser}
                  />
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <AutoCompleteList
                header={t("labels.CHANNELS")}
                optionKey="name"
                options={this.getFilteredChannels()}
                selected={user.channels}
                onAdd={this.onChannelAdd}
                onDelete={this.onChannelDelete}
                disabled={this.isDeleted()}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <AutoCompleteList
                header={t("labels.ROLES")}
                optionKey="name"
                options={this.getFilteredRoles()}
                selected={user.roles}
                onAdd={this.onRoleAdd}
                onDelete={this.onRoleDelete}
                disabled={this.isDeleted()}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <AutoCompleteList
                header={t("labels.COUNTRIES")}
                optionKey="name"
                options={this.getFilteredCountries()}
                selected={user.countries}
                onAdd={this.onCountryAdd}
                onDelete={this.onCountryDelete}
                disabled={this.isDeleted()}
              />
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

ViewContainer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect()
)(withSnackbar(ViewContainer));
